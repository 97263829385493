import React, { useState } from "react";

const IncentivesCollapsable = (props) => {
  console.log("IncentivesCollapsable", props);
  const { incentives } = props;
  const [showIncentives, setShowIncentives] = useState(false);

  if (!incentives || incentives.length === 0) return null;

  return (
    <div style={{ width: "150px" }} className="mb-3">
      <button
        className="btn btn-muted btn-sm mb-2"
        onClick={(e) => {
          e.preventDefault();
          setShowIncentives(!showIncentives);
        }}
      >
        {incentives.length} incentives
        {showIncentives ? (
          <i className="ms-3 bi bi-chevron-up" />
        ) : (
          <i className="ms-3 bi bi-chevron-down" />
        )}
      </button>

      {showIncentives &&
        incentives.map((incentive, index) => {
          return (
            <a href={`/admin/incentives/${incentive.id}/edit`} target="_blank">
              <span
                className="me-1 badge rounded-pill bg-dark"
                key={`incentive-${index}`}
              >
                {incentive.name}
              </span>
            </a>
          );
        })}
    </div>
  );
};

export default IncentivesCollapsable;
