import React from 'react';

import FileViewer from 'react-file-viewer';
import TiffPreview from './drivers/tiff-preview'
import ImagePreview from './drivers/image-preview'
import PdfPreview from './drivers/pdf-preview'
import DocxPreview from './drivers/docx-preview'
import TxtPreview from './drivers/txt-preview'
import CsvPreview from './drivers/csv-preview'
import XlsxPreview from './drivers/xlsx-preview'
import EmptyResourceType from './drivers/empty-resource'

const FilePreview = ({ fileType, filePath }) => {
  function renderFilePreview() {
    if(!fileType) return null;
    switch(fileType.toLowerCase()) {
      case 'jpeg':
      case 'bmp':
      case 'png':
      case 'jpg':
      case 'gif':
        return <ImagePreview url={filePath} />
      case 'tif':
        return <TiffPreview url={filePath} />
      case 'pdf':
        return <PdfPreview url={filePath} />
      case 'docx':
        return <DocxPreview url={filePath} />
      case 'txt':
        return <TxtPreview url={filePath} />
      case 'csv':
        return <CsvPreview url={filePath} />
      case 'xlsx':
        return <XlsxPreview url={filePath} />
      default:
        return <EmptyResourceType url={filePath} />
    }
  }

  return (
    <div className='file-preview'>
      { renderFilePreview() }
    </div>
  )
}

export default FilePreview;
