import React, { useState, useEffect, useRef } from "react";

const CompanyPurchases = (props) => {
  const [showCompanyPurchase, setShowCompanyPurchase] = useState(false);
  if (!props.companyEquipments || props.companyEquipments.length === 0) return null;

  const displayCompanyPurchases = () => {
    return(
      <ul>
        { props.companyEquipments.map((equipment, index) => {
          return (
            <li key={`purchase-${index}`}>
              <a
                key={`company_purchase_${equipment.id}`}
                className="d-flex"
                name={`company_purchase_${equipment.id}`}
                href={equipment.url || "#"}
                target="_blank"
              >
                {equipment.name.slice(0, 30)}
                {equipment.name.length > 30 ? "..." : null}
              </a>
              (Price: ${equipment.total_paid})
            </li>
          );
        })}
      </ul>
    )
  }

  if(showCompanyPurchase) {
    return (
      <div className='mb-3'>
        <button className='btn btn-muted btn-sm mb-2' onClick={(e) => {
          e.preventDefault();
          setShowCompanyPurchase(!showCompanyPurchase)
        }}>
          { props.companyEquipments.length } item(s) 
          <i className="ms-3 bi bi-chevron-up"></i>
        </button>

        { displayCompanyPurchases() }
      </div>
    )
  } else {
    return (
      <div>
        { props.companyEquipments.length === 1 ? displayCompanyPurchases() :
          <button className='btn btn-muted btn-sm' onClick={(e) => {
            e.preventDefault();
            setShowCompanyPurchase(!showCompanyPurchase)
          }}>
            { props.companyEquipments.length } item(s)
            <i className="ms-3 bi bi-chevron-down"></i>
          </button>
        }
      </div>
    )
  }
}

export default CompanyPurchases;
