import React, { useState } from "react";
import NaicsCodeSelector from "../common/naics-code-selector";
import { flashSuccess, flashError } from "lib/flash";
import { createCompany } from "modules/onboarding";

const CompanyProfile = (props) => {
  const company = props.company || {};

  const [name, setName] = useState(company.name || "");
  const [website, setWebsite] = useState(company.website || "");
  const [naicsCodeIds, setNaicsCodeIds] = useState(
    company.naics_code_ids || []
  );

  function handleSubmit(e) {
    e.preventDefault();
    createCompany({
      company: {
        name: name,
        website: website,
        naics_code_ids: naicsCodeIds,
      },
      step_key: props.step,
      section_key: props.section,
    })
      .then((response) => {
        window.location = props.nextRoute;
      })
      .catch((error) => {
        flashError(error.errors[0]);
      });
  }

  function handleNaicsCodeSelect(ids) {
    setNaicsCodeIds(ids);
  }

  return (
    <form
      className="form my-form onboarding-form-group"
      onSubmit={handleSubmit}
    >
      <div className="row mt-2">
        <div className="col">
          <div className="form-group my-form-group">
            <label>
              <strong>Company Name</strong>
              <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              required
              placeholder="Company Name"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group my-form-group">
            <label>
              <strong>Website</strong>
            </label>
            <input
              type="text"
              placeholder="Website"
              className="form-control"
              value={website}
              id={"company-website"}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row mt-3 mb-6">
        <div className="col-md-6 my-form-group">
          <label>
            <strong>NAICS Code (optional)</strong>
          </label>
          <p className="text-muted small">
            Not sure about your NAICS Code? No worries! You can search by either
            industry name or NAICS code number.
          </p>
          <NaicsCodeSelector
            naicsCodes={company.naics_codes}
            onNaicsCodeSelect={handleNaicsCodeSelect}
            customStyles={{
              control: (provided) => ({
                ...provided,
                height: "55px",
                minHeight: "50px",
                backgroundColor: "transparent",
                borderRadius: "8px",
              }),
              valueContainer: (provided) => ({
                ...provided,
                height: "55px",
                padding: "0 8px",
              }),
            }}
          />
        </div>
      </div>
      <hr />
      <div className="form-group mt-2 d-flex justify-content-between">
        {props.previousRoute && (
          <a
            href={props.previousRoute}
            className="btn btn-light btn-large my-3 mb-4 px-4 me-2"
          >
            <i
              className="bi bi-chevron-left icon-back-chevron"
              style={{ marginRight: "4px" }}
            ></i>
            <i
              className="bi bi-arrow-left icon-back-arrow"
              style={{ marginRight: "4px" }}
            ></i>
            Back
          </a>
        )}
        <button
          className="btn btn-large btn-action my-3 mb-4 px-4 next-button"
          type="submit"
        >
          Next
          <i
            className="bi bi-chevron-right icon-next-chevron"
            style={{ marginLeft: "4px" }}
          ></i>
          <i
            className="bi bi-arrow-right icon-next-arrow"
            style={{ marginLeft: "4px" }}
          ></i>
        </button>
      </div>
    </form>
  );
};

export default CompanyProfile;
