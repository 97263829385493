import { flatten, isEmpty } from "lodash";

const getQueryGroup = (condition, children) => {
  return `  <strong>
                (
              </strong>
                ${children.join(
                  `<strong>
                    ${condition.toUpperCase()}
                  </strong>`
                )}
              <strong>
                )
              </strong>
            `;
};

const getQueryOperator = (obj) => {
  return QueryMapping[obj.operatorKey][obj.fieldType];
};

const getQueryRule = (obj) => {
  if(isEmpty(obj.field) || isEmpty(obj.fieldType)) {
    return null;
  }

  let field = obj.field.toUpperCase();
  let operator = getQueryOperator(obj);
  let value = setQueryValue(obj);

  if (["all", "none", "some"].includes(obj.operator))
    return ` ${operator} (${field}.${value})`;

  if (obj.fieldType === "object") return ` ${field}.${value} `;

  return `${field} ${operator} ${value} `;
};

const setQueryValue = (obj) => {
  if (obj.fieldType === "object")
    return getQueryRule({
      ...obj,
      field: obj.subField,
      fieldType: obj.subFieldType,
    });

  if (["all", "none", "some"].includes(obj.operator))
    return getQueryRule(obj.value);

  if (obj.fieldType === "date") {
    return flatten([obj.value])
      .map((d) => formatDate(d))
      .join(", ");
  }

  return obj.value;
};

const formatDate = (date) => {
  const formattedDate = new Date(date).toLocaleDateString("en-US");
  return formattedDate === "Invalid Date" ? "" : formattedDate;
};

const formatRuleDate = (date) => {
  const formattedDate = new Date(date).toDateString();
  return formattedDate === "Invalid Date" ? "" : formattedDate;
};

const getRule = (obj) => {
  if(isEmpty(obj.field) || isEmpty(obj.fieldType) || 
    (obj.fieldType === 'object' && isEmpty(obj.subFieldType))) {
    return null;
  }

  let rule = {};
  let value = getValue(obj);
  let operator = getOperator(obj);
  let field = getFieldName(obj);

  if (["all", "none", "some"].includes(operator)) {
    return { [operator]: [{ var: field }, getRule(value)] };
  }

  if (obj.operator === "between") {
    rule = { [operator]: [value[0], { var: field }, value[1]] };
  } else {
    rule = { [operator]: [{ var: field }, value] };
  }

  if (obj.operatorKey.includes("not")) {
    rule = { "!": [rule] };
  }

  return rule;
};

const getFieldName = (obj) => {
  if (obj.fieldType === "object") return `${obj.field}.${obj.subField}`;
  else return obj.field;
};

const getValue = (obj) => {
  if (obj.operator === "in") return obj.value.split(",");

  if (obj.fieldType === "boolean") return obj.value === "true" ? true : false;

  if (["null", "nnull"].includes(obj.operatorKey)) return null;

  if (obj.fieldType === "date" || (obj.fieldType === "object" && obj.subFieldType === "date"))
    if (obj.operator === "between")
      return flatten([obj.value]).map((d) => formatRuleDate(d));
    else return formatRuleDate(obj.value);

  return obj.value;
};

const getOperator = (obj) => {
  const fieldType =
    obj.fieldType === "object" ? obj.subFieldType : obj.fieldType;

  return ruleOperatorMapper[fieldType][obj.operatorKey];
};

const ruleOperatorMapper = {
  date: {
    eq: "date_equals_to",
    neq: "date_not_equals_to",
    lt: "date_less_than",
    lte: "date_less_or_equal",
    gt: "date_greater_than",
    gte: "date_greater_or_equal",
    bt: "date_between",
    notbt: "date_between",
  },
  string: {
    eq: "==",
    neq: "!=",
    mt: "in",
    notmt: "in",
    null: "==",
    nnull: "!=",
    in: "in",
    notin: "in",
  },
  object: {
    eq: "==",
    neq: "!=",
    mt: "in",
    notmt: "in",
    null: "==",
    nnull: "!=",
    in: "in",
    notin: "in",
  },
  boolean: {
    eq: "==",
    neq: "!=",
  },
  array: {
    all: "all",
    some: "some",
    none: "none",
  },
  numeric: {
    eq: "==",
    neq: "!=",
    in: "in",
    notin: "in",
    null: "==",
    nnull: "!=",
    gt: ">",
    gte: ">=",
    lt: "<",
    lte: "<=",
    bt: "<",
    notbt: "<",
  },
};

const QueryMapping = {
  eq: {
    date: "=",
    boolean: "=",
    string: "=",
    numeric: "=",
    array: "=",
    object: "=",
  },
  neq: {
    date: "!=",
    boolean: "!=",
    string: "!=",
    numeric: "!=",
    array: "!=",
    object: "!=",
  },
  in: {
    date: "in",
    boolean: "",
    string: "in",
    numeric: "in",
    array: "in",
    object: "in",
  },
  notin: {
    date: "not in",
    boolean: "",
    string: "not in",
    numeric: "not in",
    array: "not in",
    object: "not in",
  },
  mt: {
    date: "",
    boolean: "",
    string: "matches",
    numeric: "in",
    array: "in",
    object: "in",
  },
  notmt: {
    date: "",
    boolean: "",
    string: "not matches",
    numeric: "not in",
    array: "not in",
    object: "not in",
  },
  lt: {
    date: "<",
    boolean: "",
    string: "<",
    numeric: "<",
    array: "<",
    object: "<",
  },
  lte: {
    date: "<=",
    boolean: "",
    string: "<=",
    numeric: "<=",
    array: "<=",
    object: "<=",
  },
  gt: {
    date: ">",
    boolean: "",
    string: ">",
    numeric: ">",
    array: ">",
    object: ">",
  },
  gte: {
    date: ">=",
    boolean: "",
    string: ">=",
    numeric: ">=",
    array: ">=",
    object: ">=",
  },
  bt: {
    date: "between",
    boolean: "",
    string: "between",
    numeric: "between",
    array: "between",
    object: "between",
  },
  notbt: {
    date: "not between",
    boolean: "=",
    string: "not between",
    numeric: "not between",
    array: "not between",
    object: "not between",
  },
  null: {
    date: "is",
    boolean: "",
    string: "is",
    numeric: "is",
    array: "is",
    object: "is",
  },
  nnull: {
    date: "is not",
    boolean: "",
    string: "is not",
    numeric: "is not",
    array: "is not",
    object: "is not",
  },
  all: {
    date: "all",
    boolean: "",
    string: "all",
    numeric: "all",
    array: "all",
    object: "all",
  },
  some: {
    date: "some",
    boolean: "",
    string: "some",
    numeric: "some",
    array: "some",
    object: "some",
  },
  none: {
    date: "none",
    boolean: "",
    string: "none",
    numeric: "none",
    array: "none",
    object: "none",
  },
};

export { getRule, getQueryRule, getQueryOperator, getQueryGroup };
