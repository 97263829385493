import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import {
  updateCompanyEstimateLineItem,
  updateCompanyEstimateCategory,
  createCompanyEstimateTemplateItem,
  updateCompanyEstimateTemplateItem,
  updateCompanyEstimateTemplate,
  fetchCompanyEstimateLineItems,
} from "modules/companies";
import {
  getCompanyEstimateCategories,
  createEstimateCategory,
} from "modules/company-estimate-category";
import ReactQuill from "react-quill";
import { flashSuccess, flashError } from "lib/flash";

const ProjectTemplateDetailEditor = ({
  currentTemplateId,
  currentTemplateLineItemCategoryId,
  company,
  categories,
  lineItems,
  categoryEdit,
  toggleCategoryEditMode,
  currentTemplate,
  setCurrentTemplate,
  deleteCategory,
  setEditorContents,
  editorContents,
  refreshCurrentTemplate,
  currentTemplateName,
  currentTemplateDescription,
  onTemplateChange,
}) => {
  const [lineItemEdit, setLineItemEdit] = useState({});
  const [newCategoryInt, setNewCategoryInt] = useState(0);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [isEditingName, setIsEditingName] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [newTemplateDescription, setNewTemplateDescription] = useState("");
  const [updatedTemplateName, setUpdatedTemplateName] = useState("");
  const [updatedTemplateDescription, setUpdatedTemplateDescription] =
    useState("");
  const categoryOptions = availableCategories.map((category) => ({
    label: category.name,
    value: category.id,
  }));
  const [existingLineItems, setExistingLineItems] = useState([]); //TODO: Better variable names
  const [selectedLineItemId, setSelectedLineItemId] = useState("");

  const toggleLineItemEditMode = (lineItemId) => {
    setLineItemEdit({
      ...lineItemEdit,
      [lineItemId]: !lineItemEdit[lineItemId],
    });
  };

  const saveCategory = async (id) => {
    const newName =
      editorContents[id] ||
      categories.find((cat) => cat.company_estimate_category_id === id)?.name;

    const newUnit = document.querySelector(`#unit-${id}`).value;

    await updateCompanyEstimateCategory(company.id, id, {
      name: newName,
      unit: newUnit,
    });

    const updatedCategories = categories.map((category) => {
      if (category.company_estimate_category_id === id) {
        return {
          ...category,
          name: newName,
          category_unit: newUnit,
        };
      }
      return category;
    });

    const updatedTemplate = currentTemplate.map((item) => {
      if (
        item.company_estimate_category_id === id &&
        item.item_type === "CompanyEstimateCategory"
      ) {
        return {
          ...item,
          name: newName,
          category_unit: newUnit,
        };
      }
      return item;
    });

    setCurrentTemplate(updatedTemplate);

    toggleCategoryEditMode(id);
    flashSuccess("Template detail updated successfully!");
  };

  const createNewCategory = async (
    companyId,
    currentTemplateId,
    newCategoryInt
  ) => {
    if (typeof newCategoryInt !== "number") {
      flashError("Invalid category integer");
      return;
    }

    const newCategoryData = {
      someIntField: newCategoryInt,
    };

    try {
      const newTemplateItem = {
        company_estimate_template_id: currentTemplateId,
        item_type: "CompanyEstimateCategory",
        item_id: newCategoryInt,
      };

      await createCompanyEstimateTemplateItem(
        companyId,
        currentTemplateId,
        newTemplateItem
      );

      refreshCurrentTemplate();
      onTemplateChange();

      flashSuccess("New category data added successfully!");
    } catch (error) {
      flashError(`Error adding new category data: ${error.message}`);
    }
  };

  const createNewItem = async (companyId, lineItemId) => {
    if (!companyId || !currentTemplateId || !lineItemId) {
      console.log(
        "Missing company ID, template ID, or line item ID. Aborting."
      );
      return;
    }

    try {
      const newTemplateItem = {
        company_estimate_template_id: currentTemplateId,
        item_type: "CompanyEstimateLineItem",
        item_id: lineItemId,
      };

      await createCompanyEstimateTemplateItem(
        companyId,
        currentTemplateId,
        newTemplateItem
      );

      const updatedLineItemsData = await fetchCompanyEstimateLineItems(
        companyId
      );
      setCurrentTemplate(updatedLineItemsData.line_items);
      refreshCurrentTemplate();

      flashSuccess("New item added successfully!");
    } catch (error) {
      flashError(`Error adding new item: ${error.message}`);
    }
  };

  const saveLineItem = async (item) => {
    const templateItemId = item.template_item_id;
    const templateId = item.id;

    const newQuantityElem = document.querySelector(
      `#quantity-${templateItemId}`
    );
    if (!newQuantityElem) {
      console.log("Quantity element not found. Aborting save operation.");
      return;
    }

    const newQuantity = newQuantityElem.value;
    if (isNaN(newQuantity) || newQuantity < 0) {
      console.log("Invalid quantity. Aborting save operation.");
      return;
    }

    const updatedData = {
      quantity: parseFloat(newQuantity),
    };

    await updateCompanyEstimateTemplateItem(
      company.id,
      templateId,
      templateItemId,
      updatedData
    );

    const updatedLineItems = lineItems.map((existingItem) => {
      if (existingItem.templateItemId === templateItemId) {
        return { ...existingItem, quantity: newQuantity };
      }
      return existingItem;
    });

    setCurrentTemplate(updatedLineItems);
    toggleLineItemEditMode(templateItemId);
    refreshCurrentTemplate();
    onTemplateChange();
    flashSuccess("Template detail updated successfully!");
  };

  const handleEditNameClick = () => {
    setNewTemplateName(updatedTemplateName);
    setNewTemplateDescription(updatedTemplateDescription);
    setIsEditingName(true);
  };

  const handleCloseEdit = () => {
    setIsEditingName(false);
  };

  const handleSaveNameClick = () => {
    const companyId = company.id;
    const templateId = currentTemplateId;

    updateCompanyEstimateTemplate(
      companyId,
      templateId,
      newTemplateName,
      newTemplateDescription
    )
      .then((data) => {
        setUpdatedTemplateName(newTemplateName);
        setUpdatedTemplateDescription(newTemplateDescription);
        setIsEditingName(false);

        if (typeof refreshCurrentTemplate === "function") {
          refreshCurrentTemplate();
          onTemplateChange();
        }
      })
      .catch((error) => {
        console.error("There was an error updating the template:", error);
      });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getCompanyEstimateCategories(company.id);
        setAvailableCategories(data);
      } catch (error) {
        flashError(`Error fetching categories: ${error.message}`);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    setUpdatedTemplateName(currentTemplateName);
    setUpdatedTemplateDescription(currentTemplateDescription);
  }, [currentTemplateName, currentTemplateDescription]);

  useEffect(() => {
    if (company) {
      fetchCompanyEstimateLineItems(company.id)
        .then((response) => {
          setExistingLineItems(response.line_items);
        })
        .catch((error) => {
          console.error("Error fetching line items:", error);
        });
    }
  }, [company]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {isEditingName ? (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                className="inline-edit me-2"
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.target.value)}
                style={{ width: "300px" }}
              />
              <textarea
                value={newTemplateDescription}
                className="inline-edit me-2"
                onChange={(e) => setNewTemplateDescription(e.target.value)}
                style={{ width: "300px", height: "100px" }}
              />
              <button
                className="btn me-2 ms-2"
                style={{ minWidth: "75px" }}
                onClick={handleSaveNameClick}
              >
                Save
              </button>
              <button
                className="btn btn-dark me-2 ms-2"
                style={{ minWidth: "75px" }}
                onClick={handleCloseEdit}
              >
                Close
              </button>
            </div>
          </>
        ) : (
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h3>
                <strong>{updatedTemplateName || currentTemplateName}</strong>
              </h3>
              <button className="btn ms-2" onClick={handleEditNameClick}>
                <i className="bi bi-pencil-square"></i>
              </button>
            </div>
            <p>{updatedTemplateDescription || currentTemplateDescription}</p>
          </div>
        )}

        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip className="cashflow-tooltip">
              A <strong>Line-Item</strong> represents a single element, like a
              task or a product, within a template. Line-items are specific to
              the template they reside in and are not reusable in other
              templates. <br /> A <strong>Category</strong> is a collection of
              Line-Items and even other Categories. Unlike Line-Items,
              Categories can be reused across different templates, allowing for
              efficient template management.
            </Tooltip>
          }
        >
          <button className="btn btn-outline-secondary">
            How does this work?
          </button>
        </OverlayTrigger>
      </div>

      <hr />

      <div className="mt-3">
        <h4>
          <strong>Categories:</strong>
        </h4>
        <div className="mt-3">
          <strong>Add Category to Template:</strong>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Select
              options={categoryOptions}
              onChange={(selectedOption) => {
                setNewCategoryInt(selectedOption.value);
              }}
              style={{ width: "600px", flexShrink: 0 }}
            />

            <button
              onClick={() =>
                createNewCategory(company.id, currentTemplateId, newCategoryInt)
              }
              className="btn-attach float-end"
            >
              Add Category
            </button>
          </div>
        </div>

        {categories.length > 0 ? (
          <table className="table settings-table mt-2">
            <thead>
              <tr>
                <th>Name</th>
                <th>Quantity</th>
                <th>Unit</th>
                <th style={{ width: "7%" }}></th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => (
                <tr key={category.company_estimate_category_id}>
                  <td>
                    {categoryEdit[category.company_estimate_category_id] ? (
                      <ReactQuill
                        defaultValue={category.name}
                        onChange={(content) => {
                          setEditorContents({
                            ...editorContents,
                            [category.company_estimate_category_id]: content,
                          });
                        }}
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            [{ list: "ordered" }, { list: "bullet" }],
                          ],
                        }}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: category.name,
                        }}
                      ></div>
                    )}
                  </td>
                  <td>
                    {categoryEdit[category.company_estimate_category_id] ? (
                      <input
                        id={`quantity-${category.company_estimate_category_id}`}
                        type="text"
                        className="inline-edit"
                        defaultValue={category.quantity || "N/A"}
                      />
                    ) : (
                      category.quantity || "N/A"
                    )}
                  </td>
                  <td>
                    {categoryEdit[category.company_estimate_category_id] ? (
                      <input
                        id={`unit-${category.company_estimate_category_id}`}
                        type="text"
                        className="inline-edit"
                        defaultValue={category.category_unit || "N/A"}
                      />
                    ) : (
                      category.category_unit || "N/A"
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        if (
                          categoryEdit[category.company_estimate_category_id]
                        ) {
                          saveCategory(category.company_estimate_category_id);
                        } else {
                          toggleCategoryEditMode(
                            category.company_estimate_category_id
                          );
                        }
                      }}
                      className="btn btn-outline-primary"
                    >
                      {categoryEdit[category.company_estimate_category_id] ? (
                        <i className="bi bi-check"></i>
                      ) : (
                        "Edit"
                      )}
                    </button>

                    {categoryEdit[category.company_estimate_category_id] && (
                      <>
                        <button
                          onClick={() =>
                            deleteCategory(category.template_item_id)
                          }
                          className="btn btn-sm btn-light me-2"
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                        <button
                          onClick={() =>
                            toggleCategoryEditMode(
                              category.company_estimate_category_id,
                              false
                            )
                          }
                          className="btn btn-outline-secondary"
                        >
                          <i className="bi bi-x"></i>
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="mt-3">No categories in this template.</p>
        )}
      </div>

      <div className="mt-3">
        <h4>
          <strong>Items:</strong>
        </h4>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <select
            value={selectedLineItemId}
            onChange={(e) => setSelectedLineItemId(e.target.value)}
            className="form-control inline-dropdown"
          >
            <option value="">Select Line Item</option>
            {existingLineItems.map((item) => (
              <option key={item.id} value={item.id}>
                {item.description} - {item.unit}
              </option>
            ))}
          </select>

          <button
            onClick={() => createNewItem(company.id, selectedLineItemId)}
            className="btn-attach float-end mb-2"
            disabled={!selectedLineItemId}
          >
            Add Selected Item
          </button>
        </div>
        {lineItems.length > 0 ? (
          <table className="table settings-table mt-2">
            <thead>
              <tr>
                <th>Description</th>
                <th>Preferred Vendor</th>
                <th>Quantity</th>
                <th>Unit</th>
                <th>Unit Amount</th>
                <th style={{ width: "7%" }}></th>
              </tr>
            </thead>
            <tbody>
              {lineItems.map((item) => {
                const index = item.template_item_id;
                return (
                  <tr
                    key={index}
                    style={{
                      backgroundColor:
                        parseFloat(item.quantity) === 0.0
                          ? "#E2F5FF"
                          : "inherit",
                    }}
                  >
                    <td>{item.description || "N/A"}</td>
                    <td>{item.vendor || "N/A"}</td>
                    <td>
                      {lineItemEdit[index] ? (
                        <input
                          id={`quantity-${item.template_item_id}`}
                          type="text"
                          className="inline-edit"
                          defaultValue={item.quantity || "N/A"}
                        />
                      ) : (
                        item.quantity || "0"
                      )}
                    </td>
                    <td>{item.line_item_unit || "N/A"}</td>
                    <td>{item.unit_amount || "N/A"}</td>
                    <td>
                      <button
                        onClick={() => {
                          if (lineItemEdit[index]) {
                            saveLineItem(item);
                          } else {
                            toggleLineItemEditMode(index);
                          }
                        }}
                        className="btn btn-outline-primary"
                      >
                        {lineItemEdit[index] ? (
                          <i className="bi bi-check"></i>
                        ) : (
                          "Edit"
                        )}
                      </button>
                      {lineItemEdit[index] && (
                        <>
                          <button
                            onClick={() =>
                              deleteCategory(item.template_item_id)
                            }
                            className="btn btn-outline-action"
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                          <button
                            onClick={() => toggleLineItemEditMode(index, false)}
                            className="btn btn-outline-secondary"
                          >
                            <i className="bi bi-x"></i>
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>No individual items in this template.</p>
        )}
      </div>
    </>
  );
};

export default ProjectTemplateDetailEditor;
