import React from "react";

import {
  withUINavTabs,
  UINavPanel,
  UINavSection,
  UINavHorizontalInternal,
} from "components/ui";

import BasicInfo from "./basic-info";
import Demographics from "./demographics";
import Locations from "./locations";
import Users from "./users";
import Incentives from "./incentives";
import Invoices from "components/invoices";

import EditCompanyContextProvider from "components/common/companies/contexts/admin-edit-company-context";
import CompanyFileResources from "components/common/companies/file-resources";
import Narrative from "components/common/companies/edit/narrative";

import Meetings from "./meetings";
import FinancialDataOverview from "../../../companies/edit/financial-data-overview";
import Referrals from "../../../common/referrals";

import {
  getCompanyReceivableInvoices,
  getCompanyPayableInvoices,
} from "modules/admin/company-invoices";
import QualifyingQuestions from "./qualifying-questions";

const EditCompany = ({
  company,
  businessEntities,
  roles,
  meetings,
  financialData,
  onboarding,
}) => {
  return (
    <EditCompanyContextProvider company={company}>
      <div className="ps-4 pe-4 pb-4 admin-company-form">
        <div className="row">
          <div className="col-md-12">
            <h3>{company.name}</h3>
            <UINavHorizontalInternal
              style="tabs"
              tabs={[
                {
                  name: "Basic Info",
                  link: "/basic-info",
                },
                {
                  name: "Payables",
                  link: "/payables",
                  nestedLink: "/payables/*",
                },
                {
                  name: "Receivables",
                  link: "/receivables",
                  nestedLink: "/receivables/*",
                },
                {
                  name: "Demographics",
                  link: "/demographics",
                },
                {
                  name: "Locations",
                  link: "/locations",
                },
                {
                  name: "Users",
                  link: "/users",
                },
                {
                  name: "Files",
                  link: "/file_resources",
                },
                {
                  name: "Narrative",
                  link: "/narrative",
                },
                {
                  name: "Questions",
                  link: "/qualifying-questions",
                },
                {
                  name: "Incentives",
                  link: "/incentives",
                },
                {
                  name: "Notes",
                  link: "/meetings",
                },

                {
                  name: "Referrals",
                  link: "/referrals",
                },
              ]}
            />
          </div>
          <div className="col-md-12">
            <UINavPanel>
              <UINavSection tab="/basic-info">
                <BasicInfo
                  businessEntities={businessEntities}
                  onboarding={onboarding}
                />
              </UINavSection>
              <UINavSection tab="/financial-data-overview">
                <FinancialDataOverview financialData={financialData} />
              </UINavSection>
              {/* TODO: Decide what we plan to do with payables. Maybe hide this based on permissions */}
              <UINavSection tab="/payables/*">
                <Invoices
                  getInvoices={getCompanyPayableInvoices}
                  company={company}
                  endpoint="payables"
                  key="payables"
                />
              </UINavSection>
              <UINavSection tab="/receivables/*">
                <Invoices
                  getInvoices={getCompanyReceivableInvoices}
                  company={company}
                  endpoint="receivables"
                  key="receivables"
                />
              </UINavSection>
              <UINavSection tab="/demographics">
                <Demographics />
              </UINavSection>
              <UINavSection tab="/locations">
                <Locations />
              </UINavSection>
              <UINavSection tab="/users">
                <Users roles={roles} />
              </UINavSection>
              <UINavSection tab="/file_resources">
                <CompanyFileResources
                  company={company}
                  paginationData={{ total: company.file_resources_count }}
                  isAdmin={true}
                />
              </UINavSection>
              <UINavSection tab="/narrative">
                <Narrative isAdmin={true} />
              </UINavSection>
              <UINavSection tab="/qualifying-questions">
                <QualifyingQuestions company={company} />
              </UINavSection>
              <UINavSection tab="/incentives">
                <Incentives />
              </UINavSection>
              <UINavSection tab="/meetings">
                <Meetings
                  company={company}
                  meetings={meetings}
                  partnerType="Company"
                />
              </UINavSection>
              <UINavSection tab="/referrals">
                <Referrals referrable={company} baseUrl="/admin/companies/" />
              </UINavSection>
            </UINavPanel>
          </div>
        </div>
      </div>
    </EditCompanyContextProvider>
  );
};

export default withUINavTabs(EditCompany);
