export function titleCase(string = '') {
  return string?.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : " " + d.toUpperCase()
  );
}

export function prettyDisplayPrice(value, condense = false, showCents = false) {
  if (isNaN(value) || value === null || value === undefined) {
    return "N/A";
  }
  
  const floatValue = parseFloat(value);

  let displayValue;
  if (showCents && Math.abs(floatValue) < 10) {
    displayValue = Math.abs(floatValue).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else if (condense && floatValue > 1000) {
    displayValue =
      (Math.abs(floatValue) / 1000).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }) + "K";
  } else {
    displayValue = Math.abs(floatValue).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  let currency;
  if (floatValue < 0) {
    currency = "-$";
  } else {
    currency = "$";
  }

  return currency + displayValue;
}

export function displayPrice(value) {
  const rounded = parseFloat(parseFloat(value || 0).toFixed(2));
  return rounded.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function displayPercent(value, decimals = 2) {
  const percent = value * 100;
  const rounded = percent.toFixed(decimals)

  return `${rounded}%`
}

export function prettyDisplayPercent(value, decimals = 2) {
  value = parseFloat(value);
  if (isNaN(value)) {
    return "-";
  }

  const percent = value * 100;
  const rounded = percent.toFixed(decimals)

  return `${rounded}%`
}

export function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}

export function match(value, filterValue) {
  if (!filterValue || filterValue === "") return true;
  if (!value || value === "") return false;

  const lowerCaseFilterValue = filterValue.toLowerCase();
  const words = value.split(" ").map((s) => s.toLowerCase());
  const lowerCaseValue = words.join(" ");

  if (
    lowerCaseFilterValue === lowerCaseValue ||
    lowerCaseValue.startsWith(lowerCaseFilterValue) ||
    lowerCaseValue.match(lowerCaseFilterValue)
  ) {
    return true;
  }

  for (var i = 0; i < words.length; i++) {
    const word = words[i];

    if (word.startsWith(lowerCaseFilterValue)) {
      return true;
    }
  }

  return false;
}

export function present(string) {
  return string !== null && string !== undefined && string !== "";
}

export function isInt(value) {
  return (
    !isNaN(value) &&
    parseInt(Number(value)) == value &&
    !isNaN(parseInt(value, 10))
  );
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export function parseAddress(address) {
  return (
    address.address_line_1 +
    (address.address_line_2 ? ", " + address.address_line_2 : "") +
    ", " +
    address.city +
    ", " +
    address.state +
    " " +
    address.postal_code
  );
}

export function searchArrayField(items, query, field) {
  if(query === "" || !query) return items;

  return items.filter((item) => {
    return item[field].toLowerCase().includes(query.toLowerCase());
  })
}

export function toBoolean(input) {
  switch(typeof input) {
    case "string":
      return input.toLowerCase() === "true";
    case "boolean":
      return input;
    default:
      // Return a default value if the input is neither a string nor a boolean
      return false;
  }
}

export function toCamelCase(str) {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
}