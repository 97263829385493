import React from "react";
import { prettyDisplayPercent, prettyDisplayPrice } from "lib/string";

const EstimateTotalRow = ({ estimates }) => {
  console.log("EstimateTotalRow - estimates", estimates);
  // TODO: This needs a serious refactor. This type of aggregation is done all over the place.
  const parseValue = (value, defaultValue = "0") =>
    parseFloat(value || defaultValue);

  const computeLineItemTotals = (estimates) => {
    return estimates.map(({ estimate_line_items, id }) => {
      const total = estimate_line_items.reduce(
        (acc, { total_amount }) => acc + parseValue(total_amount),
        0
      );

      const markupTotal = estimate_line_items.reduce((acc, lineItem) => {
        const markupMultiplier = 1 + parseValue(lineItem.markup) / 100;
        return acc + parseValue(lineItem.total_amount) * markupMultiplier;
      }, 0);

      return { id, total_amount: total, markupTotal };
    });
  };

  const aggregateEstimateTotals = (estimates, totals) => {
    let estimateCostTotal = 0;
    let estimateMarkupTotal = 0;

    estimates.forEach(({ children, quantity, id }) => {
      const parentTotal = totals.find((total) => total.id === id);

      if (children && children.length > 0) {
        let childrenTotalAmount = 0;
        let childrenMarkupTotal = 0;

        children.forEach((childId) => {
          const childTotal = totals.find((total) => total.id === childId);
          if (childTotal) {
            childrenTotalAmount += parseValue(childTotal.total_amount);
            childrenMarkupTotal += parseValue(childTotal.markupTotal);

            childTotal.total_amount = 0;
            childTotal.markupTotal = 0;
          }
        });

        const quantityMultiplier = parseValue(quantity, "1");
        parentTotal.total_amount += childrenTotalAmount * quantityMultiplier;
        parentTotal.markupTotal += childrenMarkupTotal * quantityMultiplier;
      }
    });

    totals.forEach((total) => {
      estimateCostTotal += parseValue(total.total_amount);
      estimateMarkupTotal += parseValue(total.markupTotal);
    });

    return { estimateCostTotal, estimateMarkupTotal };
  };

  const totals = computeLineItemTotals(estimates);
  const { estimateCostTotal, estimateMarkupTotal } = aggregateEstimateTotals(
    estimates,
    totals
  );
  const marginPercentage =
    (estimateMarkupTotal - estimateCostTotal) / estimateMarkupTotal;

  const renderTD = (content, paddingLeft) => (
    <td className="fw-semibold" style={{ paddingLeft }}>
      {content}
    </td>
  );

  return (
    <tr
      style={{ backgroundColor: "#f6f8fa" }}
      className="align-middle row-with-shadow"
    >
      <td className="py-2 px-2">
        <div
          className="d-inline-block align-middle"
          style={{ paddingLeft: "35px" }}
        >
          <h5 className="m-0 p-0">Total</h5>
        </div>
      </td>
      <div className="fw-semibold" style={{ paddingLeft: "16px" }}>
        {prettyDisplayPrice(estimateCostTotal)}
      </div>
      {renderTD(prettyDisplayPercent(marginPercentage), "16px")}
      <td>
        <div className="fw-semibold" style={{ paddingLeft: "22px" }}>
          {prettyDisplayPrice(estimateMarkupTotal, false, true)}
        </div>
      </td>
    </tr>
  );
};

export default EstimateTotalRow;
