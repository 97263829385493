import React from 'react';

import { UIBigNumberCard } from "components/ui";

const Header = ({ name, eligible, claimed }) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <h1 className="mt-3">{name}</h1>
      </div>

      <div className="col-md-3">
        <UIBigNumberCard prefix="$" title="Eligible" number={eligible} />
      </div>
      <div className="col-md-3">
        <UIBigNumberCard prefix="$" title="Claimed" number={claimed} />
      </div>
    </div>
  )
}

export default Header;
