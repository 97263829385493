import React, { useState } from "react";
import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";

const UIAccordionCompleted = (props) => {
  const defaultShow = props.completed ? false : true;
  const [showSection, setShowSection] = useState(defaultShow);

  function handleClick(e) {
    e.preventDefault();
    setShowSection(!showSection);
  }

  return (
    <div className="ui-accordion-toggle">
      <div className="title" onClick={handleClick}>
        <div className="row">
          <div className="col-md-10">
            <h5>
              <span className="me-1">
                {props.isLoading ? (
                  <Spinner animation="border" size="sm" className="me-1" />
                ) : props.completed ? (
                  props.acceptable !== undefined ? (
                    props.acceptable ? (
                      <i className="bi bi-check-circle-fill text-primary"></i>
                    ) : (
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    )
                  ) : (
                    <i className="bi bi-check-circle-fill text-primary"></i>
                  )
                ) : (
                  <i className="bi bi-circle text-muted"></i>
                )}
              </span>
              {props.title}
              {props.context && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className="cashflow-tooltip">
                      {props.context}
                    </Tooltip>
                  }
                >
                  <i
                    className="bi bi-info-circle"
                    style={{ marginLeft: "8px" }}
                  ></i>
                </OverlayTrigger>
              )}
            </h5>
          </div>
          <div className="col-md-2">
            <button className="float-end btn btn-muted chevron-button-white-bg">
              {showSection ? (
                <i className="bi bi-chevron-down"></i>
              ) : (
                <i className="bi bi-chevron-up"></i>
              )}
            </button>
          </div>
        </div>

        <hr />
      </div>

      {showSection ? props.children : null}
    </div>
  );
};

export default UIAccordionCompleted;
