import React, { useState, useEffect, useRef } from "react";

const IncentiveIssues = (props) => {
  const { incentive } = props;
  const [showIssues, setShowIssues] = useState(false);

  const issues = incentive.broken_issues;

  if (!issues || issues.length === 0) return null;

  if(showIssues) {
    return (
      <div style={{'width': '120px'}} className='mb-3'>
        <button className='btn btn-muted btn-sm mb-2' onClick={(e) => {
          e.preventDefault();
          setShowIssues(!showIssues)
        }}>
          { issues.length } issues
          <i className="ms-3 bi bi-chevron-up"></i>
        </button>

        { issues.map((issue, index) => {
          return (
            <span className="me-1 badge rounded-pill bg-danger" key={`issue-${index}`}>
              { issue }
            </span>
          )
        })}
      </div>
    )
  } else {
    return (
      <div style={{'width': '120px'}}>
        <button className='btn btn-muted btn-sm' onClick={(e) => {
          e.preventDefault();
          setShowIssues(!showIssues)
        }}>
          { issues.length } issues
          <i className="ms-3 bi bi-chevron-down"></i>
        </button>
      </div>
    )
  }
}

export default IncentiveIssues;