import React from "react";
import { prettyDisplayPrice } from "lib/string";

const AdminStatsUsersTable = ({ metricsByPrimaryState }) => {
  return (
    <div className="mt-0">
      <table className="table admin-table mt-4">
        <thead>
          <tr>
            <th>Company HQ State</th>
            <th>Active Companies</th>
            <th>Users Invited</th>
            <th>Incentives Qualified</th>
            <th>Application Dollars</th>
          </tr>
        </thead>
        <tbody>
          {metricsByPrimaryState.length === 0 ? (
            <tr>
              <td colSpan="6">Loading...</td>
            </tr>
          ) : (
            metricsByPrimaryState.map((item, index) => (
              <tr key={index}>
                <td>{item.state}</td>
                <td>{item.active_company_count}</td>
                <td>{item.users_invited}</td>
                <td>{item.incentive_qualification_matches}</td>
                <td>{prettyDisplayPrice(item.application_dollars)}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminStatsUsersTable;
