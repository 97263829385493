import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import DocumentUploadPanel from "./document-upload-panel";
import DocumentRow from "./document-row";

import { flashSuccessLater } from "lib/flash";
import { UploadingInProgress } from "components/ui";
import { deleteCompanyFileResource } from "modules/companies";

const DocumentManagerModal = (props) => {
  const [canUploadDocs, setCanUploadDocs] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [showUploadingInProgress, setShowUploadingInProgress] = useState(false);

  useEffect(() => {
    // Don't allow uploading docs if not unlimited and we hit limit
    if (props.count > 0 && props.files && props.files.length >= props.count) {
      setCanUploadDocs(false);
    } else {
      setCanUploadDocs(true);
    }
  }, []);

  if (!props.show) {
    return null;
  }

  function uploadFileResource(files) {
    setAttachedFiles(files);
    setShowUploadingInProgress(true);
  }

  function deleteFile(file) {
    if (!confirm("Are you sure you want to delete this file?")) {
      return true;
    }

    const params = {
      resource_id: file.id,
      id: props.company.id,
    };

    deleteCompanyFileResource(params).then((data) => {
      flashSuccessLater("File Deleted.");
      window.location.reload(true);
    });
  }

  function renderCountLimit() {
    if (props.count === 0) {
      return (
        <p>
          You can upload unlimited {props.category} files for this application
        </p>
      );
    } else {
      return (
        <p>
          You can upload up to {props.count} {props.category} file
          {props.count === 1 ? "" : "s"} for this application
        </p>
      );
    }
  }

  function renderUploadPanel() {
    return <DocumentUploadPanel uploadFileResource={uploadFileResource} />;
  }

  function renderUploadingInProgress() {
    if (!showUploadingInProgress) return null;

    return attachedFiles.map((file, index) => (
      <UploadingInProgress
        file={file}
        index={index}
        company={props.company}
        filesLength={attachedFiles.length}
        fileCategoryId={props.file_category_id}
        onUploadResponse={(a) => {
          flashSuccessLater("File Added.");
          window.location.reload(true);
        }}
        isAdmin={false}
      />
    ));
  }

  function renderUploadedDocuments() {
    const { files } = props;
    if (!files) return null;

    return files.map((file, index) => {
      return (
        <DocumentRow
          {...file}
          canDelete={props.canDelete}
          key={`document-row-${index}-${file.name}`}
          handleDelete={(e) => {
            e.preventDefault();
            deleteFile(file);
          }}
        />
      );
    });
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title>Upload and attach</Modal.Title>
          <p className="mt-0 text-muted">
            Upload and attach {props.category} to the application (
            {props.application_context})
          </p>
          {renderCountLimit()}
          {canUploadDocs ? renderUploadPanel() : null}
          {renderUploadingInProgress()}
          {renderUploadedDocuments()}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DocumentManagerModal;
