import React from "react";
import AsyncSelect from "react-select/async";
import { fetchAdminQualifications } from "modules/qualifications";

const NewQualificationCard = ({ addQualification, setNewQualification }) => {
  const onOptionsLoad = (value, cb) => {
    fetchAdminQualifications({ query: value }).then((res) => {
      cb(
        res.qualifications.map((row) => ({
          value: row.id,
          label: `${row.name}, (${row.category} [Level: ${row.level}])`,
        }))
      );
    });
  };

  return (
    <div className="card p-3">
      <div className="row">
        <div className="col-6">
          <AsyncSelect
            value={null}
            loadOptions={onOptionsLoad}
            placeholder="Find Qualifications..."
            onChange={addQualification}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
          />
        </div>
        <div className="col">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-danger"
              style={{ borderRadius: "50%" }}
              onClick={() => setNewQualification(false)}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewQualificationCard;
