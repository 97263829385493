import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import AmountCards from "./amount-cards";
import LineItemsTable from "./line-items-table";
import BillingAndShippingDetails from "./billing-and-shipping-details";
import ProjectAllocator from "./project-allocator";
import Tags from "./tags";
import InvoiceDetails from "./invoice-details";
import Attachment from "./attachment";

import { UITextareaLabelInput } from "components/ui";
import { getCompanyAccountsCharts } from "modules/company_accounts_charts";
import {
  getAdminCompanyProjects,
  getAllocatedLineItems,
} from "modules/project";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    className="btn btn-sm btn-muted float-end"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
  >
    {children}
  </a>
));

const Form = (props) => {
  const {
    company,
    setEndpoint,
    endpoint,
    invoice,
    setInvoice,
    tags,
    addTag,
    updateTag,
    removeTag,
    items,
    addItem,
    updateItem,
    removeItem,
    handleSave,
    handleSendToCodat,
    isNew,
  } = props;

  const [showAmountToggles, setShowAmountToggles] = useState(true);
  const [showBillingDetails, setShowBillingDetails] = useState(false);
  const [billingShippingEqual, setBillingShippingEqual] = useState(true);
  const [projects, setProjects] = useState([]);
  const [showTags, setShowTags] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const [amountState, setAmountState] = useState({
    salesTax: true,
    laborAndDelivery: false,
    fee: false,
    discount: false,
    paymentsAndDeposits: false,
  });

  const [invoiceAllocations, setInvoiceAllocations] = useState([]);
  const [lineItemAllocations, setLineItemAllocations] = useState([]);

  useEffect(() => {
    if (invoice.labor_and_delivery_amount) {
      amountState.laborAndDelivery = true;
    }

    if (invoice.payments_and_deposits_amount) {
      amountState.paymentsAndDeposits = true;
    }

    if (invoice.fee_amount) {
      amountState.fee = true;
    }

    if (invoice.sales_tax_amount) {
      amountState.salesTax = true;
    }

    if (invoice.discount_amount) {
      amountState.discount = true;
    }

    setAmountState(amountState);
    fetchCompanyProjects();
    fetchAllocatedProjectsForInvoice();
    fetchAllocatedProjectsForLineitems();
    setIsLoaded(true);
  }, []);

  function fetchCompanyProjects() {
    const params = {
      company_id: company.id,
      per_page: -1,
    };

    getAdminCompanyProjects(params).then((result) => {
      setProjects(result.projects);
    });
  }

  function fetchAllocatedProjectsForInvoice() {
    const params = {
      allocation_id: invoice.id,
      allocation_type:
        props.endpoint === "payables"
          ? "CompanyPayableInvoice"
          : "CompanyReceivableInvoice",
    };

    getAllocatedLineItems(params).then((result) => {
      setInvoiceAllocations(result || []);
    });
  }

  function fetchAllocatedProjectsForLineitems() {
    const params = {
      allocation_id: items.map((item) => item.id),
      allocation_type:
        props.endpoint === "payables"
          ? "CompanyPurchase"
          : "CompanyReceivableLineitem",
    };

    getAllocatedLineItems(params).then((result) => {
      setLineItemAllocations(result || []);
    });
  }

  if (!isLoaded) return null;

  return (
    <form
      className="form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <BillingAndShippingDetails
        invoice={invoice}
        setInvoice={setInvoice}
        showBillingDetails={showBillingDetails}
        setShowBillingDetails={setShowBillingDetails}
        billingShippingEqual={billingShippingEqual}
        setBillingShippingEqual={setBillingShippingEqual}
      />
      {invoice.notes && (
        <p style={{ backgroundColor: "#f0f0f0" }}>{invoice.notes}</p>
      )}
      <InvoiceDetails
        company={company}
        invoice={invoice}
        setInvoice={setInvoice}
        setEndpoint={setEndpoint}
      />

      {(invoiceAllocations.length > 0 || lineItemAllocations.length == 0) && (
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <label>
                  <strong>Projects</strong>
                </label>
                <ProjectAllocator
                  isNew={isNew}
                  item={{
                    id: invoice.id,
                    description: [
                      invoice.invoice_number,
                      invoice.vendor_name,
                      invoice.customer_name,
                    ]
                      .filter((s) => !!s)
                      .join(": "),
                    acquisition_date: invoice.due_date,
                    unit_cost: invoice.total_amount,
                    quantity: 1,
                    allocation_type:
                      props.endpoint === "payables"
                        ? "CompanyPayableInvoice"
                        : "CompanyReceivableInvoice",
                  }}
                  projects={projects}
                  allocatedProjects={invoiceAllocations}
                  fetchAllocatedProjects={fetchAllocatedProjectsForInvoice}
                  company_id={company.id}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <Tags
        invoice={invoice}
        setInvoice={setInvoice}
        showTags={showTags}
        setShowTags={setShowTags}
        tags={tags || []}
        addTag={addTag}
        updateTag={updateTag}
        removeTag={removeTag}
      />

      <div className="row mt-3">
        <div className="col-md-12">
          <AmountCards
            setAmountState={setAmountState}
            amountState={amountState}
            showAmountToggles={showAmountToggles}
            setShowAmountToggles={setShowAmountToggles}
          />
          <LineItemsTable
            isNew={isNew}
            company={company}
            endpoint={endpoint}
            amountState={amountState}
            invoice={invoice}
            setInvoice={setInvoice}
            editAmounts={showAmountToggles}
            items={items}
            addItem={addItem}
            updateItem={updateItem}
            removeItem={removeItem}
            projects={projects}
            canAllocate={
              invoiceAllocations.length == 0 || lineItemAllocations.length > 0
            }
            allocatedProjects={lineItemAllocations}
            fetchAllocatedProjects={fetchAllocatedProjectsForLineitems}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12">
          <UITextareaLabelInput
            label="Notes"
            required={false}
            name="notes"
            description="Use this space to add additional notes for this invoice."
            handleChange={(value) => {
              setInvoice({ ...invoice, notes: value });
            }}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12">
          <Attachment
            company={company}
            invoice={invoice}
            setInvoice={setInvoice}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12">
          <div className="float-end">
            <a href="#/payables" className="btn btn-outline-secondary me-2">
              Cancel
            </a>

            {isNew ? (
              <a href="#/" className="btn btn-action" onClick={handleSave}>
                Save Invoice
              </a>
            ) : (
              <Dropdown as={ButtonGroup}>
                <a href="#/" className="btn btn-action" onClick={handleSave}>
                  Save Invoice
                </a>

                <Dropdown.Toggle
                  bsPrefix="dropdown-toggle dropdown-toggle-split btn btn-action"
                  split
                  id="dropdown-split-basic"
                />

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleSendToCodat} href="#">
                    Send To Codat
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
