import axios from "lib/axios";

export const submitEmail = (params = {}) => {
  const url = `/email-lists/`

  return axios.post(url, params)
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response.data)
    })
}