import React from "react";
import { prettyDisplayPrice } from "lib/string";

const generateReferralLink = (type, id) => {
  let baseUrl = "/admin/";
  switch (type) {
    case "User":
      baseUrl += `users/${id}/edit#/referrals`;
      break;
    case "Organization":
      baseUrl += `organizations/${id}/edit#/referrals`;
      break;
    case "Company":
      baseUrl += `companies/${id}/edit#/referrals`;
      break;
    default:
      baseUrl = "#";
      break;
  }
  return baseUrl;
};

const AdminStatsCompanyTable = ({ metricsByReferralSource }) => {
  return (
    <div className="mt-0">
      <table className="table admin-table mt-4">
        <thead>
          <tr>
            <th>Referral Source</th>
            <th>Total Companies</th>
            <th>Active Companies</th>
            <th>Incentive Reports</th>
            <th>Incentives Qualified</th>
            <th>Discovery Meetings</th>
            <th>Users Invited</th>
            <th>Application Dollars</th>
          </tr>
        </thead>
        <tbody>
          {metricsByReferralSource.length === 0 ? (
            <tr>
              <td colSpan="7">Loading...</td>
            </tr>
          ) : (
            metricsByReferralSource.map((item, index) => (
              <tr key={index}>
                <td>
                  <a
                    href={generateReferralLink(
                      item.referrable_type,
                      item.referrable_id
                    )}
                  >
                    {item.referral_source}
                  </a>
                </td>
                <td>{item.total_company_count}</td>
                <td>{item.active_company_count}</td>
                <td>{item.incentive_reports_generated}</td>
                <td>{item.incentive_qualification_matches}</td>
                <td>{item.discovery_meetings}</td>
                <td>{item.users_invited}</td>
                <td>{prettyDisplayPrice(item.application_dollars)}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminStatsCompanyTable;
