import React from 'react';

const IncentiveUrlRow = (props) => {
  const { url } = props;

  return (
    <tr>
      <td>
        <span>
          { url.favicon ?
            <img
              className='me-3'
              style={{'width': '20px'}}
              src={`data:image/jpeg;base64,${url.favicon}`}
            /> :
            <i className="bi bi-globe me-2"></i>
          }
        </span>

        <a href={url.url} target="_blank" style={{'fontSize': '20px'}}>{url.label || url.url}</a>
      </td>
    </tr>
  );
};

const IncentiveUrls = (props) => {
  const { urls } = props;

  const renderUrls = () => {
    return urls.map((url, index) => {

      if(!url.is_broken) {
        return (
          <IncentiveUrlRow
            index={index}
            key={`incentive-url-row-${index}`}
            url={url}
          />
        );
      }
    });
  };

  return (
    <table className='table table-borderless table-sm'>
      <tbody>
        {renderUrls()}
        <tr>
          <td colSpan={3}>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default IncentiveUrls;
