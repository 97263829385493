import React, { useState } from "react";

import {
  withUINavTabs,
  UINavPanel,
  UINavSection,
  UINavVertical,
} from "components/ui";
import EditDetails from "./edit-details";
import ChangePassword from "./change-password";

const Settings = ({ user }) => {
  const [currentTab, setCurrentTab] = useState("Account");

  return (
    <div className="panel-body">
      <div className="row pt-4">
        <div className="col-md-3">
          <div className="profile-summary pb-3 me-4 d-flex">
            <h4 className="">Settings</h4>
          </div>

          <UINavVertical
            changeTab={setCurrentTab}
            current={currentTab}
            tabs={[
              {
                name: "Account",
                link: "/account",
              },
              {
                name: "Password",
                link: "/password",
              },
            ]}
          />
        </div>
        <div className="col-md-8">
          <UINavPanel current={currentTab}>
            <UINavSection tab="/account">
              <EditDetails user={user} />
            </UINavSection>
            <UINavSection tab="/password">
              <ChangePassword />
            </UINavSection>
          </UINavPanel>
        </div>
      </div>
    </div>
  );
};

export default withUINavTabs(Settings);
