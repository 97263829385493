import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const QuestionCard = ({
  question,
  handleAnswerClick,
  handleTextChange,
  handleTextSave,
  getCompanyAnswerText,
  isAnswerSelected,
  userDetails,
  activeInput,
  tempAnswerText,
}) => {
  return (
    <li>
      <div className="question-card">
        <strong>Question: </strong>
        {question.question_title}
        {question.qualification_context && (
          <span className="ms-2">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className="cashflow-tooltip">
                  {question.qualification_context}
                </Tooltip>
              }
            >
              <i
                className="bi bi-question-circle-fill"
                title={question.qualification_context}
              ></i>
            </OverlayTrigger>
          </span>
        )}
        <div className="secondary-text mt-1 mb-0">
          {userDetails && (userDetails.user_name || userDetails.user_email) && (
            <div>
              <p className="mb-0 mt-0">
                <strong>Answered by:</strong>{" "}
                {userDetails.user_name ? userDetails.user_name : "Unknown User"}
                {userDetails.user_email ? ` (${userDetails.user_email})` : ""}
              </p>
            </div>
          )}
        </div>
      </div>
      <div>
        {question.answer_type === "multiple_choice" ? (
          <ul style={{ listStyleType: "none" }}>
            {question.answer_options.map((option) => (
              <li
                key={option.id}
                onClick={() => handleAnswerClick(question.id, option.id)}
                className={`my-1 py-2 px-3 card read-only-card text-dark d-flex justify-content-between flex-row align-items-center ${
                  isAnswerSelected(option.id) ? "selected-answer" : "bg-light"
                }`}
                style={{ maxWidth: "400px", cursor: "pointer" }}
              >
                {option.option_text}
              </li>
            ))}
          </ul>
        ) : (
          <div
            style={{
              maxWidth: "400px",
              marginLeft: "34px",
            }}
          >
            <input
              type="text"
              placeholder="Your answer..."
              onChange={(e) => handleTextChange(question.id, e)}
              value={
                activeInput === question.id
                  ? tempAnswerText[question.id]
                  : getCompanyAnswerText(question.id) || ""
              }
              className="form-control inline-edit"
              style={{ maxWidth: "400px", height: "50px" }}
            />
            <button
              className="btn btn-primary mt-2"
              onClick={() => handleTextSave(question.id)}
            >
              <i className="bi bi-check"></i> Save
            </button>
          </div>
        )}
      </div>
    </li>
  );
};

export default QuestionCard;
