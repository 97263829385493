import React from 'react';

const UITable = (props) => {
  return (
    <div className="panel-table">
      <table className="table">
        { props.children }
      </table>
    </div>
  )
}

export default UITable;
