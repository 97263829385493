import React from 'react';

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-textmate";

const HtmlEditor = ({onChange, value}) => {
  return (
    <AceEditor
      mode="html"
      theme="textmate"
      width="100%"
      fontSize={16}
      tabSize={2}
      wrapEnabled={true}
      onChange={onChange}
      name="html-editor"
      value={value}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true
      }}
    />
  )
}

export default HtmlEditor;
