import React, { useEffect, useRef } from "react";
import { pick, values, isEmpty } from "lodash";
import { useSetState, useBoolean } from "react-hanger";
import RuleFields from "./rule-fields";
import { findByValue, Operators, OperatorMapping } from "./rule-utils";
import { getDate } from "lib/utils";

const SingleRule = ({ rule, fieldOptions, removeRule, updateRuleJSON }) => {
  const setDateValue = (value) => {
    if (rule.operator === "between") {
      return [getDate(value[0]), getDate(value[1])]
    }
    return getDate(value)
  }

  function setValue(rule) {
    if(rule.fieldType === "date" || rule.subFieldType === "date") {
      return setDateValue(rule.value)
    }
    return rule.value 
  }

  const { state: variables, setState: setVariables } = useSetState({
    field: rule.field,
    subField: rule.subField,
    subFieldType: rule.subFieldType,
    operator: rule.operator,
    value: setValue(rule), 
    operatorKey: rule.operatorKey,
    fieldType: rule.fieldType,
  });

  const showDeleteIcon = useBoolean(false)
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    resetRule();
  }, [variables.field, variables.subField]);

  const resetRule = (operator=null) => {
    let defaultOperator = operator || OperatorMapping[variables.fieldType].default;
    let defaultValue = Operators[defaultOperator].default[variables.fieldType]

    if(variables.fieldType === 'object') {
      defaultValue = defaultValue[variables.subFieldType]
    }

    onFieldChange("operatorKey", defaultOperator);
    onFieldChange("operator", Operators[defaultOperator].value);
    onFieldChange("value", defaultValue);
  };

  const onFieldChange = (key, value) => {
    setVariables({ [key]: value });
    updateRuleJSON(rule.id, key, value);
  };

  const onOperatorChange = (operator) => resetRule(operator)

  const onVariableChange = (value) => {
    if (value == "") return
    const fieldObj = JSON.parse(value);

    onFieldChange("fieldType", fieldObj.type);
    onFieldChange("field", fieldObj.label);
    onFieldChange("value", "");
  };

  const onSubFieldChange = (value) => {
    if (value == "")
      return
    const fieldObj = JSON.parse(value);

    onFieldChange("subFieldType", fieldObj.type);
    onFieldChange("subField", fieldObj.label);
    onFieldChange("value", "");
  };

  const operatorOptions = () => {
    const fieldType = variables.fieldType === "object" ? variables.subFieldType : variables.fieldType

    return values(
      pick(Operators, OperatorMapping[fieldType].operators)
    );
  };

  const isFieldSet = () => {
    if (variables.fieldType === "object")
      return !isEmpty(variables.field) && !isEmpty(variables.subField)
    else
      return !isEmpty(variables.field)
  }

  return (
    <div
      className={`border rounded single-rule p-3 ${showDeleteIcon.value && "bg-light"}`}
      onMouseEnter={() => showDeleteIcon.setTrue()}
      onMouseLeave={() => showDeleteIcon.setFalse()}
    >
      <div className="row">
        <div className="col-md-3">
          <select
            className="form-control"
            name='Search Field'
            placeholder="Select field"
            value={JSON.stringify({
              label: variables.field,
              type: variables.fieldType,
            })}
            onChange={(e) => onVariableChange(e.target.value)}
          >
            <option value="">Select field</option>
            {fieldOptions.map((field) => {
              return (
                <option
                  key={field.value}
                  value={JSON.stringify({
                    label: field.value,
                    type: field.type,
                  })}
                >
                  {field.label}
                </option>
              );
            })}
          </select>
        </div>

        {variables.fieldType === "object" &&
          <div className="col-md-2">
            <select
              className="form-control"
              placeholder="Select field"
              value={JSON.stringify({
                label: variables.subField,
                type: variables.subFieldType,
              })}
              onChange={(e) => onSubFieldChange(e.target.value)}
            >
              <option value="">Select field</option>
              {findByValue(fieldOptions, variables.field).sub_fields.map((field) => {
                return (
                  <option
                    key={field.value}
                    value={JSON.stringify({
                      label: field.value,
                      type: field.field_type,
                    })}
                  >
                    {field.label}
                  </option>
                );
              })}
            </select>
          </div>}

        <div className="col-md-3">
          {isFieldSet() &&
            <>
              <select
                className="form-control"
                name='Select Operator'
                value={variables.operatorKey}
                onChange={(e) => onOperatorChange(e.target.value)}
            >
              {operatorOptions().map((field) => {
                return (
                  <option key={field.label} value={field.key}>
                    {field.label}
                  </option>
                );
              })}
              </select>
            </>}
        </div>

        <div className={`${variables.fieldType === "object" ? "col-md-2" : "col-md-4" }`}>
          {isFieldSet() &&
            <RuleFields
              variables={variables}
              onFieldChange={onFieldChange}
              fieldOptions={fieldOptions}
            />}
        </div>

        <div className="col-md-2 d-flex justify-content-start align-items-start">
          {showDeleteIcon.value &&
            <div className='ms-auto'>
              <button className="btn btn-danger" style={{'borderRadius': '50%'}} onClick={() => removeRule(rule)}>
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default SingleRule;
