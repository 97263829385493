import React, { useState } from "react";
import { UILabelInput, UIFormLabel } from "../../../ui";
import { flashError, flashSuccess } from "lib/flash";
import StyleGuideLink from "./../../incentives/common/style-guide-link";

import {
  updateIncentiveFormSchemaComponent,
  softDeleteIncentiveFormSchemaComponent,
} from "modules/incentive-form-schema-components";

// https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md
import AceEditor from "react-ace";

// Keeping things light - only importing mode/theme/worker needed for this JSON editor
// https://github.com/securingsincity/react-ace/issues/725#issuecomment-543109155
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import jsonWorkerUrl from "file-loader!ace-builds/src-noconflict/worker-json";
ace.config.setModuleUrl("ace/mode/json_worker", jsonWorkerUrl);

// Enables Cmd + F search
import "brace/ext/searchbox";

const Edit = ({ component }) => {
  const [name, setName] = useState(component.name);
  const [schema, setSchema] = useState(
    JSON.stringify(component.schema, null, 2)
  );

  const handleSave = () => {
    const snakeCaseRegex = /^[a-z]+(?:_[a-z]+)*$/;

    if (!snakeCaseRegex.test(name)) {
      alert("The component name must be in snake_case format.");
      return;
    }

    // TODO: Warn or better handle name changes as it could cause some issues.

    const versionParts = component.version.split(".").map(Number);
    if (versionParts.length !== 3) {
      console.error("Invalid version format");
      return;
    }

    // TODO: Evaluate what should be done with version control here
    const changeType = prompt(
      "Was this a small, medium, or large change? Enter 'small', 'medium', or 'large'."
    );

    switch (changeType) {
      case "small":
        versionParts[2]++; // Patch
        break;
      case "medium":
        versionParts[1]++; // Minor
        versionParts[2] = 0; // Reset patch
        break;
      case "large":
        versionParts[0]++; // Major
        versionParts[1] = 0; // Reset minor
        versionParts[2] = 0; // Reset patch
        break;
      default:
        alert(
          "Invalid change type. Please enter 'small', 'medium', or 'large'."
        );
        return;
    }

    // Combine the parts back into a version string
    const newVersion = versionParts.join(".");

    const params = {
      id: component.id,
      name: name,
      schema: JSON.parse(schema),
      version: newVersion,
    };

    updateIncentiveFormSchemaComponent(params)
      .then((response) => {
        flashSuccess(`Updated component successfully.`);
        window.location.href = "/admin/incentive_form_schema_components";
      })
      .catch((error) => {
        // TODO: This is atrocious. Do not know why there is an error that gets thrown every time, despite the entry updating.
        // flashError(`There was an error: ${error.message} updating the schema`);
        console.error("Error updating component", error);
        window.location.href = "/admin/incentive_form_schema_components";
      });
  };

  const handleSoftDelete = () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this component?"
    );
    if (confirmDelete) {
      softDeleteIncentiveFormSchemaComponent(component.id)
        .then(() => {
          flashSuccess("Component soft deleted successfully.");
          window.location.href = "/admin/incentive_form_schema_components";
        })
        .catch((error) => {
          flashError("Error soft deleting component.");
          console.error("Error soft deleting component", error);
        });
    }
  };

  return (
    <div className="panel-body admin-company-form">
      <div className="row">
        <div className="col-lg-5">
          <h3 className="my-0">Edit Component Schema</h3>

          <div className="mb-3">
            <UILabelInput
              value={name}
              label="Component Name"
              onChange={(newValue) => setName(newValue)}
            />
          </div>
          <div className="mb-3">
            <UIFormLabel label="Editor" />
            <AceEditor
              mode="json"
              theme="github"
              name={`schema_editor_${component.id}`}
              width="100%"
              placeholder="Enter JSON schema here..."
              wrapEnabled={true}
              value={schema}
              onChange={setSchema}
            />
          </div>
        </div>
      </div>
      <StyleGuideLink guideType="intakeSchema" />
      <div className="mb-5 btn-group float-end">
        <a
          className="btn btn-light"
          href="/admin/incentive_form_schema_components"
        >
          Back
        </a>

        <button className="btn btn-primary" onClick={handleSave}>
          Save Component
        </button>
        <button className="btn btn-action" onClick={handleSoftDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default Edit;
