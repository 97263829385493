import React, { useState, useEffect } from "react";
import { UITable, withUIPaginate } from "components/ui";
import TableFilters from "./components/table-filters";
import TableHeader from "./components/table-header";
import TableBody from "./components/table-body";
import TableFooter from "./components/table-footer";

import { getCompanyProjects, newProjects } from "modules/project";

import { UIBigNumberCard } from "components/ui";

const Projects = (props) => {
  const [projects, setProjects] = useState(newProjects(props.projects));
  const [aggregateDetails, setAggregateDetails] = useState(
    props.aggregateDetails
  );
  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className="row mt-4 px-3">
        <div className="col-md-6">
          <h2>Projects</h2>
          <p className="mb-0">
            View all your projects and summary of key accounting metrics.
          </p>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-around">
            <div className="col-md-3">
              <UIBigNumberCard
                prefix="$"
                suffix="K"
                title="Revenue"
                number={aggregateDetails.revenue / 1000}
              />
            </div>
            <div className="col-md-3">
              <UIBigNumberCard
                prefix="$"
                suffix="K"
                title="Costs"
                number={aggregateDetails.costs / 1000}
              />
            </div>
            <div className="col-md-3">
              <UIBigNumberCard
                suffix="%"
                title="Gross Margin"
                number={100 * aggregateDetails.gross_margin}
              />
            </div>
            <div className="col-md-3">
              <UIBigNumberCard
                suffix="%"
                title="Net Profit"
                number={100 * aggregateDetails.net_profit}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="panel table admin-table mt-4 p-3">
        <TableFilters
          setAggregateDetails={setAggregateDetails}
          setProjects={setProjects}
          getCompanyProjects={getCompanyProjects}
          pagination={props.pagination}
          updatePagination={props.updatePagination}
          handleModalOpen={handleModalOpen}
        />
        <UITable>
          <TableHeader />
          <TableBody
            projects={projects}
            setProjects={setProjects}
            company={props.currentCompany}
          />
          <TableFooter
            pagination={props.pagination}
            updatePagination={props.updatePagination}
          />
        </UITable>
      </div>
    </>
  );
};

export default withUIPaginate(Projects);
