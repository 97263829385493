import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";

// Short text question component
const ShortTextQuestion = ({ question, answer }) => (
  <div className="form-group">
    <label htmlFor={`question_${question.id}`}>{question.question_title}</label>
    <input
      type="text"
      className="form-control"
      id={`question_${question.id}`}
      defaultValue={answer ? answer.text : ""}
      required
    />
  </div>
);

// Multiple choice question component
const MultipleChoiceQuestion = ({ question, answer }) => (
  <div className="form-group">
    <label>{question.question_title}</label>
    {question.answer_options.length > 5 ? (
      <select
        className="form-control"
        defaultValue={answer ? answer.choiceId : ""}
        required
      >
        <option value="" disabled>
          -- Please select --
        </option>
        {question.answer_options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.option_text}
          </option>
        ))}
      </select>
    ) : (
      <ul className="mt-4" style={{ listStyleType: "none" }}>
        {question.answer_options.map((option) => (
          <li
            className="py-1 px-4 text-dark d-flex align-items-center"
            style={{ maxWidth: "600px", width: "100%", cursor: "pointer" }}
            key={option.id}
          >
            <input
              className="form-check-input"
              type="radio"
              name={`question_${question.id}`}
              id={`choice_${option.id}`}
              value={option.id}
              defaultChecked={answer ? answer.choiceId === option.id : false}
              required
            />
            <label
              className="form-check-label"
              style={{ cursor: "pointer", marginLeft: "8px" }}
              htmlFor={`choice_${option.id}`}
            >
              {option.option_text}
            </label>
          </li>
        ))}
      </ul>
    )}
  </div>
);

// QuestionAnswer component that renders the appropriate question type
const QuestionAnswer = ({ question, answer }) => {
  if (!question) {
    console.error("Question is undefined");
    return null;
  }

  if (!answer) {
    console.warn("Answer is undefined for question:", question);
  }

  switch (question.answer_type) {
    case "short_text":
      return <ShortTextQuestion question={question} answer={answer} />;
    case "multiple_choice":
      return <MultipleChoiceQuestion question={question} answer={answer} />;
    default:
      return null;
  }
};

// OnboardingQuestion component that handles form submission and loading state
const OnboardingQuestion = ({ company, handleClick, question, answer }) => {
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    handleClick(company).finally(() => {
      setLoading(false);
    });
  };

  console.log("question");

  return (
    <div>
      {loading && (
        <div className="overlay">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      <form onSubmit={handleFormSubmit} className="mt-2">
        <div className="row">
          <div className="col">
            <div className="form-group mb-6">
              <QuestionAnswer question={question} answer={answer} />
            </div>
          </div>
        </div>
        <div className="form-group d-flex justify-content-end">
          <div className="row mt-3">
            <div className="col">
              <button
                className="btn btn-large btn-action my-3 mb-4 px-4 next-button"
                type="submit"
              >
                Next <span className="bi bi-caret-right-fill icon-next"></span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OnboardingQuestion;
