import React, { useEffect, useState } from 'react';
import { useArray } from "react-hanger";

import { flashSuccess, flashError } from 'lib/flash'

import Form from './components/form';

import { 
  createCompanyPayableInvoice, 
  createCompanyReceivableInvoice,
  newTags,
  newTag,
  newItems,
  newItem,
  newInvoice,
}  from 'modules/admin/company-invoices'

const New = (props) => {
  const { company, endpoint } = props;

  function setEndpoint(a, b) {
    return endpoint === 'payables' ? a : b
  }

  const createCompanyInvoice = setEndpoint(createCompanyPayableInvoice, createCompanyReceivableInvoice)

  const items = useArray(newItems());
  const tags = useArray(newTags());
  const [ invoice, setInvoice ] = useState(newInvoice(company, setEndpoint))

  function addTag() {
    tags.push(newTag())
  }

  function updateTag(tag, field, value) {
    tags.modifyById(tag.id, { ...tag, [field]: value })
  }

  function removeTag(id) {
    tags.removeById(id)
  }

  function addItem() {
    items.push(newItem(props.company.id))
  }

  function updateItem(item, field, value) {
    items.modifyById(item.id, { ...item, [field]: value })
  }

  function removeItem(item) {
    items.removeById(item.id)
  }

  function handleSave(e) {
    e.preventDefault()

    const itemsAttributes = (setEndpoint(() => {
      return items.value.map((item) => {
        // Used for backwards compatability with CAMAN.
        const updatedItem = {
          ...item,
          acquisition_date: invoice.issue_date,
          purchase_order: invoice.purchase_order_number,
          status: 0,
          total_paid: item.quantity * item.unit_cost,
          purchaser: invoice.customer_details,
          seller: invoice.vendor_details,
        }

        delete updatedItem._new;
        delete updatedItem.id;
        return updatedItem
      })
    }, () => {
      return items.value.map((item) => {
        const updatedItem = {
          ...item,
          unit_amount: item.unit_cost,
        }

        delete updatedItem.unit_cost;
        delete updatedItem._new;
        delete updatedItem.id;
        return updatedItem
      })
    }))()

    delete invoice.attachment_file_name 

    const params = (setEndpoint(() => {
      return {
        company_payable_invoice: {
          company_purchases_attributes: itemsAttributes,
          company_id: company.id,
          ...invoice,
          tags: tags.value,
          vendor_name: invoice.vendor_details.name, 
        }
      }
    }, () => {
      return {
        company_receivable_invoice: {
          company_receivable_lineitems_attributes: itemsAttributes,
          ...invoice,
          tags: tags.value,
          customer_name: invoice.customer_details.name, 
        }  
      }
    }))()

    createCompanyInvoice(params)
      .then((response) => {
        flashSuccess('Invoice created successfuly!')
        window.location = window.location.pathname + setEndpoint("#/payables", "#/receivables")
      })
      .catch((res) => {
        res.errors.map((error) => {
          flashError(error)
        })
      })
  }

  return (
    <div>
      <div className='row'>
        <div className='col-md-8'>
          <h4><strong>Add New Invoice</strong></h4>
        </div>
        <div className='col-md-4'>
          <a className="btn btn-light float-end" href="#/payables">
            Back
          </a>
        </div>
      </div>
      <hr/>

      <Form
        isNew={true}
        setEndpoint={setEndpoint}
        company={company}
        invoice={invoice}
        setInvoice={setInvoice}
        handleSave={handleSave}
        tags={tags.value}
        addTag={addTag}
        updateTag={updateTag}
        removeTag={removeTag}
        items={items.value}
        addItem={addItem}
        updateItem={updateItem}
        removeItem={removeItem}
      />
    </div>
  )
}

export default New;
