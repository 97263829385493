import React, { useState } from 'react'
import { useArray } from 'react-hanger'
import { v4 as uuidv4 } from "uuid"
import SingleRule from './single-rule'
import { isEmpty, filter } from "lodash"
import { emptyGroup, emptyRule } from "./rule-utils"

const RuleGroup = ({ rule, fieldOptions, updateRuleJSON, removeRule: removeRuleSuper, addRuleJSON, removeRuleJSON, clearAll }) => {
  const rules = useArray(rule.children)
  const [condition, setCondition] = useState(rule.condition)

  const addNewGroup = () => {
    let newGroup = emptyGroup()
    rules.push(newGroup)
    addRuleJSON(rule.id, newGroup)
  }

  const addNewRule = () => {
    let newRule = emptyRule()
    rules.push(newRule)
    addRuleJSON(rule.id, newRule)
   }

  const removeRule = (removabaleRule) => {
    rules.removeById(removabaleRule.id)
    removeRuleJSON(rule.id, removabaleRule.id)
  }

  const conditionClass = (type) => {
    if (type === condition) {
      return "bg-dark text-white";
    }
   };

  const getRules = () => filter(rules.value, (r) => r.type === "rule")
  const getGroups = () => filter(rules.value, (r) => r.type === "group")


  return (
    <div>
      <div className="rule-group p-3 border rounded">
        <div className="d-flex justify-content-between align-items-center">
          <div className="btn-group btn-group-sm">
            { !isEmpty(rule) && rule.children.length > 1 &&
              <>
                <label
                  className={`btn bg-light ${conditionClass("and")}`}
                  htlmfor={`radio-and-${rule.id}`}
                  onClick={() => {
                    setCondition("and")
                    updateRuleJSON(rule.id, "condition", "and")
                  }}
                >
                  AND
                </label>

                <label
                  className={`btn bg-light ${conditionClass("or")}`}
                  htlmfor={`radio-or-${rule.id}`}
                  onClick={() => {
                    setCondition("or")
                    updateRuleJSON(rule.id, "condition", "or")
                  }}
                >
                  OR
                </label>
              </>
            }
            {
              rule.children.length === 0 && clearAll &&
                <>
                  <h5 className='m-0 text-black-50'>No Rules</h5>
                </>
            }
          </div>
          <div className={`${clearAll ? "mb-0" : "mb-1"}`}>
            <button className="btn btn-sm btn-light text-primary me-2" onClick={addNewRule}>
              Add Rule
            </button>
            <button className="btn btn-sm btn-light text-primary me-2" onClick={addNewGroup}>
              Add Group
            </button>

            {clearAll ?
              (rule.children.length === 0 ? null :
                <button className="btn btn-sm btn-light text-danger" onClick={clearAll}>
                  Clear all
                </button> )
              : <button className="btn btn-sm btn-light text-danger" onClick={() => removeRuleSuper(rule)}>
                  Remove Group
                </button>}
          </div>
        </div>
        {getRules().map((rule) => (
          <SingleRule
            key={rule.id}
            rule={rule}
            fieldOptions={fieldOptions}
            removeRule={removeRule}
            updateRuleJSON={updateRuleJSON}
          />
        ))}

        {getGroups().map((rule) => (
          <RuleGroup
            key={rule.id}
            rule={rule}
            fieldOptions={fieldOptions}
            updateRuleJSON={updateRuleJSON}
            removeRule={removeRule}
            addRuleJSON={addRuleJSON}
            removeRuleJSON={removeRuleJSON}
          />
        ))}
      </div>
    </div>
  )
}

export default RuleGroup
