import React from 'react';

const VideoLibrary = (props) => {
  const videos = [{
    uid: 'mLDc7JqV_gk',
    title: 'Incentives 101',
    description: ''
  }, {
    uid: 'GzSjYFZRkF0',
    title: 'What is CalCompetes?',
    description: ''
  }, {
    uid: 'iSGidNECzK8',
    title: 'Economic Development Rate Discount Program',
    description: ''
  }, {
    uid: 'BT13bxiTXpQ',
    title: 'Partial Sales Tax Exemption',
    description: ''
  }]

  let lastVideoIndex = window.sessionStorage.getItem('dashboardVideoIndex')
  lastVideoIndex = parseInt(lastVideoIndex)

  if (isNaN(lastVideoIndex)) {
    lastVideoIndex = 0
  } else {
    lastVideoIndex += 1
    if (lastVideoIndex > videos.length - 1) {
      lastVideoIndex = 0
    }
  }

  window.sessionStorage.setItem('dashboardVideoIndex', lastVideoIndex)
  const video = videos[lastVideoIndex]

  return (
    <div className='mt-5'>
      <iframe width="100%" height="200px" 
        src={`https://www.youtube.com/embed/${video.uid}`} 
        title={video.title} frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      >
      </iframe> 
      <h2>{video.title}</h2>
      <p>
        { video.description }         
      </p>
    </div>
  )
}

export default VideoLibrary;