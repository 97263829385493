import React, { Component } from "react";
import CurrencyInput from "react-currency-input-field";
import UIFormLabel from "../ui-form-label";
import UIValidationError from "../ui-validation-error";

import { useField } from "formik";

const UIMoneyLabelInputFormik = (props) => {
  const [field, meta, helpers] = useField({ name: props.name });

  return (
    <div className="ui-label-input">
      {!!props.label && (
        <UIFormLabel
          label={props.label}
          required={props.required}
          tooltip={props.tooltip}
        />
      )}
      {!!props.description && <p>{props.description}</p>}
      <CurrencyInput
        id={`${props.name}-money-input`}
        className="form-control"
        placeholder="$0"
        prefix="$"
        defaultValue={meta.value}
        decimalsLimit={2}
        onValueChange={(value) => {
          helpers.setValue(value);
        }}
      />
      {meta.error && meta.touched && (
        <UIValidationError message={meta.error.replace(props.name, "This")} />
      )}
    </div>
  );
};

export default UIMoneyLabelInputFormik;
