import React from 'react';

const UIValidationError = ({message}) => {
  return (
    <span className="d-block text-danger error mt-2">
      {message}
    </span>
  );
}

export default UIValidationError;
