import React, { useState, useEffect } from "react";
import { updateAdminIncentive } from "modules/incentives";
import { flashSuccessLater } from "../../../../lib/flash";
import TextareaAutosize from "react-textarea-autosize";
import { UIFormLabel } from "components/ui";

const Economics = ({ incentive }) => {
  const [economics, setEconomics] = useState(incentive.economics);

  useEffect(() => {
    setEconomics(incentive.economics);
  }, [incentive]);

  const onUpdate = () => {
    updateAdminIncentive({ incentive: { economics }, id: incentive.id })
      .then(() => {
        flashSuccessLater("incentive updated");
        location.reload(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="col-md-12 mb-4">
      <div className="mb-3">
        <UIFormLabel label="Economics" />
        <TextareaAutosize
          type="text"
          name='Economics'
          className="form-control"
          placeholder="Enter description here..."
          value={economics || ""}
          onChange={(e) => {
            e.preventDefault();
            setEconomics(e.target.value);
          }}
        />
      </div>
      <div className="float-end mt-3 btn-group">
        <button className="btn btn-dark" onClick={onUpdate}>
          Update
        </button>
      </div>
    </div>
  );
};

export default Economics;
