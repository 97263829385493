import React from 'react';

import { hasTouchedError } from 'lib/utils'


const ApplicationTabs = ({
  properties,
  currentTab,
  setCurrentTab,
  setCurrentPropertyIndex,
  errors,
  touched
}) => {
  return (
    <ul className='application-tabs'>
      { properties.map((property, index) => {
          const name = property[0];
          const schema = property[1];
          const className = name === currentTab ? 'active' : ''
          const touchedError = hasTouchedError(touched[name], errors[name]);

          return (
            <li key={`app-tab-${index}`} className={className} onClick={(e) => {
              e.preventDefault();
              setCurrentPropertyIndex(index)
              setCurrentTab(name);
            }}>
              {touchedError && <i style={{ color: 'red'}} className="bi bi-exclamation-circle"/>}
              {touchedError ? " " + schema.label : schema.label}
            </li>
          )
        })
      }
    </ul>
  )
}

export default ApplicationTabs;
