import axios from "lib/axios";

export const createAdminAnswerOption = (params = {}) => {
  const url = `/admin/answer_options/`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateAdminAnswerOption = (params = {}) => {
  const url = `/admin/answer_options/${params.id}/`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const deleteAdminAnswerOption = (params = {}) => {
  const url = `/admin/answer_options/${params.id}/`;

  return axios
    .delete(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
