import React from "react";

import {
  withUINavTabs,
  UINavPanel,
  UINavSection,
  UINavHorizontalInternal,
} from "components/ui";

import { Route } from "react-router-dom";

import {
  fetchReceivableInvoices,
  fetchPayableInvoices,
} from "modules/company_invoices";

import Invoices from "components/invoices";

const Index = (props) => {
  const { company } = props;

  return (
    <div className="p-4 panel mt-3">
      <div className="row">
        <div className="col-md-12">
          <UINavHorizontalInternal
            style="pill"
            tabs={[
              {
                name: "Payables",
                link: "/payables",
                nestedLink: "/payables/*",
              },
              {
                name: "Receivables",
                link: "/receivables",
                nestedLink: "/receivables/*",
              },
            ]}
          />
        </div>
        <div className="col-md-12">
          <UINavPanel>
            <UINavSection tab="/payables/*">
              <Invoices
                company={company}
                getInvoices={fetchPayableInvoices}
                endpoint="payables"
                key="payables"
              />
            </UINavSection>
            <UINavSection tab="/receivables/*">
              <Invoices
                company={company}
                getInvoices={fetchReceivableInvoices}
                endpoint="receivables"
                key="receivables"
              />
            </UINavSection>
          </UINavPanel>
        </div>
      </div>
    </div>
  );
};

export default withUINavTabs(Index);
