import axios from "lib/axios";
import { encodeParams } from 'lib/utils';

export const fetchAdminCompanyIncentiveEligibility = (params = {}) => {
  const url = `/admin/company_incentive_eligibility/${params.id}`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchAdminCompanies = (params = {}) => {
  const url = `/admin/companies`;
  params = encodeParams(params);

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchCompanyAggregateMetrics = () => {
  const url = `/admin/companies/fetch_company_aggregate_metrics`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchMetricsByReferralSource = () => {
  const url = `/admin/companies/fetch_metrics_by_referral_source`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchMetricsByPrimaryState = () => {
  const url = `/admin/companies/fetch_metrics_by_primary_state`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchMetricsByQualifiedIncentive = () => {
  const url = `/admin/companies/fetch_metrics_by_qualified_incentive`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchMetricsByAppliedIncentive = () => {
  const url = `/admin/companies/fetch_metrics_by_applied_incentive`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchCompanyMatchedIncentives = (companyId, params = {}) => {
  const url = `/admin/companies/${companyId}/fetch_matched_incentives`;
  params = encodeParams(params);

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const qualifyCompanyForIncentivesService = (companyId) => {
  const url = `/admin/companies/${companyId}/qualify_company_for_incentives`;

  return axios
    .post(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const qualifyCompanyForIncentive = (companyId, incentiveId, notes, manualOverride) => {
  const url = `/admin/companies/${companyId}/incentive_company_qualifications`;
  return axios
    .post(url, {
      incentive_id: incentiveId,
      notes: notes,
      manual_override: manualOverride
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const removeCompanyIncentiveQualification = (companyId, incentiveId) => {
  const url = `/admin/companies/${companyId}/incentive_company_qualifications/remove`;
  return axios
    .delete(url, {
      data: {
        incentive_id: incentiveId
      }
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};


export const disqualifyCompanyForIncentive = (companyId, incentiveId, notes, manualOverride) => {
  const url = `/admin/companies/${companyId}/disqualified_companies`;

  return axios
    .post(url, {
      incentive_id: incentiveId,
      notes: notes,
      manual_override: manualOverride
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const removeCompanyIncentiveDisqualification = (companyId, incentiveId) => {
  const url = `/admin/companies/${companyId}/disqualified_companies/remove`;
  return axios
    .delete(url, {
      data: {
        incentive_id: incentiveId
      }
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const deleteCompanyAddress = (params = {}) => {
  const url = `/admin/company_addresses/${params.address.id}/`;

  return axios
    .delete(url, { data: params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const createCompanyAddress = (params = {}) => {
  const url = `/admin/company_addresses/`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateCompanyAddress = (params = {}) => {
  const url = `/admin/company_addresses/${params.address.id}/`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const createAdminCompany = (params = {}) => {
  const url = `/admin/companies`;

  return axios
    .post(url, { company: params })
    .then((response) => {
      return Promise.resolve(response.data.id);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateAdminCompany = (params = {}) => {
  const url = `/admin/companies/${params.id}`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const importAdminCompanies = (file) => {
  const url = `/admin/companies/import`;
  const formData = new FormData();
  formData.append('file', file);

  return axios
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const removeUserCompany = (params = {}) => {
  const url = `/admin/user_companies/${params.user_company.id}/`;

  return axios
    .delete(url, { data: params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const addUserCompany = (params = {}) => {
  const url = `/admin/user_companies/`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateUserCompany = (params = {}) => {
  const url = `/admin/user_companies/${params.user_company.id}`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateUserCompanyNewRole = (params = {}) => {
  const url = `/admin/user_companies/${params.user_company.id}/update_role`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const uploadCompanyFileResource = (params = {}, options, isAdmin) => {
  let url = null;

  if (isAdmin) {
    url = `/admin/companies/${params.id}/upload_file_resource`;
  }
  else {
    url = `/company/upload_file_resource`;
  }

  let formData = new FormData();

  for ( var key in params ) {
    if(key === 'company') {
      for (let fileKey in params.company) {
        formData.append(`company[${fileKey}]`, params.company[fileKey]);
      }
    }
    else {
      formData.append(`[${key}]`, params[key]);
    }
  }

  return axios
    .post(url, formData, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const deleteCompanyFileResource = (params = {}) => {
  const url = `/admin/companies/${params.id}/destroy_file_resource`;

  return axios
    .delete(url, { data: params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const searchCompanyFileResource = (params = {}) => {
  const url = `/admin/companies/${params.id}/search_file_resource`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateCompanyFileResource = (params = {}) => {
  const url = `/admin/companies/${params.id}/update_file_resource`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const disconnectCodat = (params = {}) => {
  let url = `/integrations/codat/disconnect`;

  if(params.company_id) {
    url += '?company_id=' + params.company_id;
  }

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const createCompanyAnswer = (companyId, params = {}) => {
  const url = `/admin/companies/${companyId}/company_answers`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
      
    })
    .catch((error) => {
      console.log("Server responded with an error: ", error.response.data);
      return Promise.reject(error.response.data);
    });
};

export const destroyCompanyAnswer = (companyId, companyAnswerId) => {
  const url = `/admin/companies/${companyId}/company_answers/${companyAnswerId}`;

  return axios
    .delete(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.log("Server responded with an error: ", error.response.data);
      return Promise.reject(error.response.data);
    });
};

export const createCompanyEstimateTemplate = (companyId, name) => {
  const url = `/companies/${companyId}/company_estimate_templates`;
  const payload = {
    name: name,
    company_id: companyId,
  };

  return axios
    .post(url, payload)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateCompanyEstimateTemplate = (companyId, templateId, name, description) => {
  const url = `/companies/${companyId}/company_estimate_templates/${templateId}`;
  const payload = {
    name: name,
    description: description,
  };

  return axios
    .put(url, payload)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const getCompanyEstimateTemplates = (companyId) => {
  const url = `/companies/${companyId}/company_estimate_templates`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const getCompanyEstimateTemplateById = (companyId, templateId) => {
  const url = `/companies/${companyId}/company_estimate_templates/${templateId}`;
  
  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const deleteCompanyEstimateTemplate = (companyId, templateId) => {
  const url = `/companies/${companyId}/company_estimate_templates/${templateId}`;

  return axios
    .delete(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateCompanyEstimateCategory = (companyId, categoryId, updatedData) => {
  const url = `/companies/${companyId}/company_estimate_categories/${categoryId}`;
  
  return axios
    .put(url, updatedData)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchCompanyEstimateLineItems = (companyId) => {
  const url = `/companies/${companyId}/company_estimate_line_items`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const createCompanyEstimateLineItem = (companyId, newData, categoryId = null) => {
  const url = `/companies/${companyId}/company_estimate_line_items`;
  
  let payload = {
    ...newData,
    company_id: companyId
  };

  if (categoryId) {
    payload = { ...payload, company_estimate_category_id: categoryId };
  }
  
  return axios
    .post(url, payload)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateCompanyEstimateLineItem = (companyId, lineItemId, updatedData) => {
  const url = `/companies/${companyId}/company_estimate_line_items/${lineItemId}`;
  
  return axios
    .put(url, updatedData)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const deleteCompanyEstimateLineItem = (companyId, lineItemId) => {
  const url = `/companies/${companyId}/company_estimate_line_items/${lineItemId}`;

  return axios
    .delete(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};


export const deleteCompanyEstimateTemplateItem = (companyId, templateItemId) => {
  const url = `/companies/${companyId}/company_estimate_template_items/${templateItemId}`;
  
  return axios
    .delete(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const createCompanyEstimateTemplateItem = (companyId, templateId, itemParams) => {
  const url = `/companies/${companyId}/company_estimate_templates/${templateId}/company_estimate_template_items`;
  
  return axios
    .post(url, {
      company_estimate_template_item: {
        item_type: itemParams.item_type,
        item_id: itemParams.item_id,
        ordering: itemParams.ordering,
        quantity: itemParams.quantity
      }
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateCompanyEstimateTemplateItem = (companyId, templateId, itemId, updateParams) => {
  const url = `/companies/${companyId}/company_estimate_templates/${templateId}/company_estimate_template_items/${itemId}`;
  
  return axios
    .patch(url, {
      company_estimate_template_item: {
        item_type: updateParams.item_type,
        item_id: updateParams.item_id,
        ordering: updateParams.ordering,
        quantity: updateParams.quantity
      }
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchLocationFlags = () => {
  const url = `/admin/company_addresses/location_flags`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data.location_flags);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};






