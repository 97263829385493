import { Country, State } from "country-state-city";

// Converts Google Maps API result to our address schema.
export const parseGoogleMapsApiResult = (result) => {
  const parsedAddress = {};

  result["address_components"].forEach((addressComponent, i) => {
    if (addressComponent["types"].indexOf("street_number") > -1) {
      parsedAddress.address_line_1 = addressComponent["long_name"];
    }

    if (addressComponent["types"].indexOf("route") > -1) {
      parsedAddress.address_line_1 =
        parsedAddress.address_line_1 + " " + addressComponent["long_name"];
    }

    if (addressComponent["types"].indexOf("locality") > -1) {
      parsedAddress.city = addressComponent["long_name"];
    }

    if (addressComponent["types"].indexOf("administrative_area_level_2") > -1) {
      parsedAddress.county = addressComponent["long_name"];
    }

    if (addressComponent["types"].indexOf("country") > -1) {
      parsedAddress.country = addressComponent["long_name"];
    }

    if (addressComponent["types"].indexOf("administrative_area_level_1") > -1) {
      const countryCode = Country.getCountryByCode(parsedAddress.country) || "US"; 
      const stateResult = State.getStateByCodeAndCountry(addressComponent["short_name"], countryCode);

      if(stateResult) {
        parsedAddress.state = stateResult.name;
      } else {
        parsedAddress.state = addressComponent["short_name"]
      }
    }

    if (addressComponent["types"].indexOf("postal_code") > -1) {
      parsedAddress.postal_code = addressComponent["short_name"];
    }

    if (addressComponent["types"].indexOf("postal_code_suffix") > -1) {
      parsedAddress.postal_code =
        parsedAddress.postal_code + "-" + addressComponent["short_name"]; // Adds suffix to existing zip code
    }
  });

  return parsedAddress;
}
