// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import 'jquery'
import('css/onboarding.scss');
import("css/logged_out_pages/book.scss");

import("swiper/swiper.min.css");
import("swiper/modules/navigation/navigation.min.css");
import("swiper/modules/pagination/pagination.min.css");

import("swiper/swiper.min.css");
import("swiper/modules/navigation/navigation.min.css");
import("swiper/modules/pagination/pagination.min.css");

Rails.start()

require.context('../images', true)
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://21878760048a4abaa7b7d38d7b5349f9@o1018017.ingest.sentry.io/6034338",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.RAILS_ENV,
});
