import React, { useState } from 'react';

import logo from 'images/plaid-logo'
import PlaidLinkHandler from 'components/integrations/plaid-link-handler'
import Loading from 'components/common/loading'

const BankingIntegration = (props) => {
  const [ isLoading, setIsLoading ] = useState(false)

  function nextRoute() {
    setIsLoading(false)
    window.location = props.nextRoute
  }

  function nextStep(e) {
    e.preventDefault()
    nextRoute()
  }

  function renderLoading() {
    return (
      <div className='btn btn-action px-5' style={{'height': '42px', 'lineHeight': '28px'}}>
        <Loading loadingHeight={30} height={30} color={'#fff'} />
      </div>
    )
  }

  function renderNav() {
    return (
      <>
        {props.previousRoute && (
          <a href={props.previousRoute} className="btn btn-light me-2">
            Back
          </a>
        )}
        <button className="btn btn-action me-2" type="submit" onClick={nextStep}>
          Next
        </button>
        <a href={props.nextRoute} className="btn btn-light">
          Skip
        </a>
      </>
    )
  }

  return (
    <div>
      <div className='row mt-3'>
        <div className='col-md-4'>
          <div className='integration-card' style={{'height': 'inherit'}}>
            <PlaidLinkHandler 
              setLocation={nextRoute} 
              company={props.company}
              handleClick={() => { setIsLoading(true)}}
              handleExit={() => { setIsLoading(false)}}
            > 
              <img src={logo} style={{'width': '100%'}} />
            </PlaidLinkHandler>
          </div>
        </div>
      </div>
      <div className="form-group mt-4">
        <div className="float-end">
          { isLoading ? renderLoading() : renderNav() }
        </div> 
      </div>
    </div>
  )
}

export default BankingIntegration;
