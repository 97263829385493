import React, { useState, useEffect } from "react";

import BasicInfo from "./basic-info";
import OnlineResources from "./online-resources";
import IncentiveFileResources from "./incentive-file-resources";
import Eligibility from "./eligibility";
import Qualifications from "./qualifications";
import Economics from "./economics";
import Compliance from "./compliance";
import Tasks from "./tasks";
import LaunchDetails from "./launch-details";
import FAQ from "./faq/faq-index";
import Summary from "./summary";
import ApplicationFiles from "./application-files";
import IntakeAdmin from "./intake";
import QualifiedCompanies from "./qualified-companies";

import {
  withUINavTabs,
  UINavPanel,
  UINavSection,
  UINavHorizontalInternal,
} from "components/ui";

import IncentiveSelector from "../common/incentive-selector";

const EditIncentive = ({ incentive }) => {
  const [currentIncentive, setCurrentIncentive] = useState(incentive);

  useEffect(() => {
    setCurrentIncentive(incentive);
  }, [incentive]);

  return (
    <div className="panel-body">
      <div className="row">
        <div className="col-md-9">
          <h3 className="mb-0">{currentIncentive.name}</h3>
        </div>
        <div className="col-md-3">
          <div className="ms-auto d-flex justify-content-end">
            <a
              target="_blank"
              href={`/admin/incentives/${currentIncentive.slug}`}
              className="btn btn-outline-primary me-3"
            >
              Preview
            </a>
            <IncentiveSelector />
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-12">
          <UINavHorizontalInternal
            style="tabs"
            tabs={[
              {
                name: "Basic Info",
                link: "/basic-info",
              },
              {
                name: "Urls",
                link: "/resources",
              },
              {
                name: "Application Forms",
                link: "/incentive-file-resources",
              },
              {
                name: "Eligibility",
                link: "/eligibility",
              },
              {
                name: "Qualifications",
                link: "/qualifications",
              },
              {
                name: "FAQ",
                link: "/faq",
              },
              {
                name: "Launch Details",
                link: "/launch-details",
              },
              {
                name: "Substantiation Files",
                link: "/application-files",
              },
              {
                name: "Intake",
                link: "/intake",
              },
              {
                name: "Qualified Companies",
                link: "/qualified-companies",
              },
            ]}
          />
        </div>
        <div className="col-md-12">
          <UINavPanel>
            <UINavSection tab="/basic-info">
              <BasicInfo incentive={currentIncentive} />
            </UINavSection>
            <UINavSection tab="/resources">
              <OnlineResources incentive={currentIncentive} />
            </UINavSection>
            <UINavSection tab="/incentive-file-resources">
              <IncentiveFileResources incentive={currentIncentive} />
            </UINavSection>
            <UINavSection tab="/eligibility">
              <Eligibility incentive={currentIncentive} />
            </UINavSection>
            <UINavSection tab="/qualifications">
              <Qualifications incentive={currentIncentive} />
            </UINavSection>
            <UINavSection tab="/launch-details">
              <LaunchDetails incentive={currentIncentive} />
            </UINavSection>
            <UINavSection tab="/faq">
              <FAQ incentive={currentIncentive} />
            </UINavSection>
            <UINavSection tab="/application-files">
              <ApplicationFiles incentive={currentIncentive} />
            </UINavSection>
            <UINavSection tab="/intake">
              <IntakeAdmin incentive={currentIncentive} />
            </UINavSection>
            <UINavSection tab="/qualified-companies">
              <QualifiedCompanies incentive={currentIncentive} />
            </UINavSection>
          </UINavPanel>
        </div>
      </div>
    </div>
  );
};

export default withUINavTabs(EditIncentive);
