import React, { useState } from "react";
import { updateCompanyProject } from "modules/project";
import { UILabelInput } from "components/ui";
import { flashError, flashSuccess, flashSuccessLater } from "lib/flash";
import { titleCase } from "lib/string";
import { renderStatus, renderPriority } from "modules/project";

const ProjectMetadata = (props) => {
  const [newLinkName, setNewLinkName] = useState("");
  const [newLinkUrl, setNewLinkUrl] = useState("");
  const [showLinkInputs, setShowLinkInputs] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [updatedLinks, setUpdatedLinks] = useState([
    ...props.project.project_document_links,
  ]);

  const addLink = async () => {
    if (newLinkName && newLinkUrl) {
      try {
        let linkUrl = newLinkUrl;
        if (!linkUrl.startsWith("http://") && !linkUrl.startsWith("https://")) {
          linkUrl = "https://" + linkUrl;
        }
        const link = { name: newLinkName, url: linkUrl };
        const updatedLinks = [...props.project.project_document_links, link];
        const params = {
          id: props.project.id,
          company_project: {
            project_document_links: updatedLinks,
          },
        };
        await updateCompanyProject(params);
        setNewLinkName("");
        setNewLinkUrl("");
        setShowLinkInputs(false);
        flashSuccess("Link added to project!");
        setUpdatedLinks(updatedLinks);
        window.location.reload();
        //TODO: refetch links properly
      } catch (error) {
        console.error("Failed to add link:", error);
        flashError("Something went wrong. Link not added.");
      }
    }
  };

  const deleteLink = async (index) => {
    try {
      const updatedLinks = [...props.project.project_document_links];
      updatedLinks.splice(index, 1);
      const params = {
        id: props.project.id,
        company_project: {
          project_document_links: updatedLinks,
        },
      };
      await updateCompanyProject(params);
      flashSuccessLater("Link deleted from project!");
      window.location.reload(); //TODO: refetch links
      // Refresh data here
    } catch (error) {
      console.error("Failed to delete link:", error);
      flashError("Something went wrong. Link not deleted.");
    }
  };

  const saveLink = async (index) => {
    try {
      const updatedLinksCopy = [...updatedLinks];
      updatedLinksCopy[index].name = newLinkName;
      const params = {
        id: props.project.id,
        company_project: {
          project_document_links: updatedLinksCopy,
        },
      };
      await updateCompanyProject(params);
      setEditingIndex(-1);
      flashSuccess("Link updated!");
      // Refresh data here
    } catch (error) {
      console.error("Failed to update link:", error);
      flashError("Something went wrong. Link not updated.");
    }
  };

  const cancelEdit = () => {
    setEditingIndex(-1);
    setNewLinkName("");
    setNewLinkUrl("");
  };

  return (
    <div className="row mt-0">
      <div className="mb-3 p-3">
        <div className="mb-0">
          <strong>Details</strong>
        </div>
        <div className="mb-0">
          <span style={{ color: "#777", fontSize: "0.9rem" }}>
            Customer: &nbsp;
          </span>
          <a
            href={`/projects?query=${encodeURIComponent(
              props.project.customer
            )}&sort_column=name&sort_direction=ASC&status=&state=&per_page=50&page=1`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.project.customer}
          </a>
        </div>
        <div>
          <span style={{ color: "#777", fontSize: "0.9rem" }}>
            Priority: &nbsp;
          </span>
          <small>{renderPriority(props.project.priority)}</small>
        </div>
        <div className="mb-0">
          <span style={{ color: "#777", fontSize: "0.9rem" }}>
            Project Manager: &nbsp;
          </span>
          {props.project.project_manager}
        </div>
        <div className="mb-0">
          <span style={{ color: "#777", fontSize: "0.9rem" }}>
            Status: &nbsp;
          </span>
          <small>{renderStatus(props.project.status)}</small>
        </div>
      </div>
      <div className="mb-3 p-3">
        <div className="mb-0">
          <strong>Project Links</strong>
        </div>

        {props.project.project_document_links.map((link, index) => (
          <div key={index} className="mb-0 link-container">
            {editingIndex === index ? (
              <>
                <input
                  value={newLinkName || link.name}
                  onChange={(e) => setNewLinkName(e.target.value)}
                  className="inline-edit"
                />
                <span>
                  <button
                    className="btn btn-sm btn-light"
                    onClick={() => saveLink(index)}
                  >
                    <i className="bi bi-check"></i>
                  </button>
                  <button className="btn btn-sm btn-light" onClick={cancelEdit}>
                    <i className="bi bi-x"></i>
                  </button>
                  <button
                    className="btn btn-sm btn-light"
                    onClick={() => deleteLink(index)}
                  >
                    <i className="bi bi-trash2"></i>
                  </button>
                </span>
              </>
            ) : (
              <>
                <a
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  {link.name}
                </a>
                {editingIndex === -1 && (
                  <span
                    className="edit-link"
                    onClick={() => setEditingIndex(index)}
                  >
                    <i
                      className="secondary-text bi bi-pencil pencil-button-stable"
                      style={{ paddingLeft: "10px" }}
                    ></i>{" "}
                    <span className="secondary-text">Edit</span>
                  </span>
                )}
              </>
            )}
          </div>
        ))}
        {!showLinkInputs && editingIndex === -1 && (
          <>
            <button
              className="btn btn-small"
              onClick={() => setShowLinkInputs(true)}
            >
              Add Link...
            </button>
            <br />
          </>
        )}
        {showLinkInputs && editingIndex === -1 && (
          <>
            <UILabelInput
              label="Link Name"
              value={newLinkName}
              onChange={setNewLinkName}
              placeholder="Link name"
            />
            <UILabelInput
              label="Link"
              value={newLinkUrl}
              onChange={setNewLinkUrl}
              placeholder="Link URL"
            />
            <button className="btn btn-primary" onClick={addLink}>
              Save Link
            </button>
            <button
              className="btn btn-secondary-outline"
              onClick={() => setShowLinkInputs(false)}
            >
              Cancel
            </button>
          </>
        )}
      </div>
      <div className="mb-3 p-3">
        <div className="mb-0">
          <strong>Description</strong>
        </div>
        <div className="mb-0">{props.project.project_notes}</div>
      </div>
    </div>
  );
};

export default ProjectMetadata;
