import React from 'react';
import Dropzone from "react-dropzone";

const DocumentUploadPanel = ({ uploadFileResource }) => {
  return (
    <Dropzone 
      onDrop={
        acceptedFiles => uploadFileResource(acceptedFiles)
      }
    >
      {({getRootProps, getInputProps}) => (
        <section className="documents-dropzone">
          <div className="dropzone-button" {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="col-md-12">
              <i className="bi bi-cloud-arrow-up-fill"></i>
              <label 
                htmlFor="uploadButton" 
                id="upload_file_resource" 
                className="btn btn-muted btn-sm"
              >
                <u>Click to upload</u> or drag and drop
              </label>
              <input
                type="file"
                id="uploadButton"
                name="upload_file_resource"
                className="form-control d-none"
                onChange={(e) => {
                  e.preventDefault();
                  uploadFileResource(e.target.files);
                }}
              />
            </div>
          </div>
        </section>
      )}
    </Dropzone>
  )
}

export default DocumentUploadPanel;