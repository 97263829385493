import React from "react";
import PropTypes from "prop-types";
import AddressInput from "./address-input";

const Address = ({ onFieldChange }) => {
  return (
    <section className="card p-3 mt-3 mb-3 z-depth-2">
      <h3 className="panel-title">Primary Address</h3>
      <div className="row">
        <div className="col-md-6">
          <AddressInput onFieldChange={onFieldChange} />
        </div>
      </div>
    </section>
  );
};

Address.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
};

export default Address;
