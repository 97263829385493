import React, { useEffect, useState } from "react";
import PlaidLinkHandler from './plaid-link-handler'

const PlaidLinkButton = (props) => {
  function setLocation() {
    window.location.reload(true)
  }

  return (
    <PlaidLinkHandler setLocation={setLocation}>
      <button
        className="btn btn-primary"
        style={{width: "350px"}}
        disabled={!props.ready}
        onClick={(e) => { e.preventDefault() }}
      >
        { props.label }
      </button>
    </PlaidLinkHandler>
  );
};

export default PlaidLinkButton;
