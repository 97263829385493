import React, { createContext } from "react";
import { useArray, useSetState } from "react-hanger";

import { updateAdminUser } from "modules/users";

import {
  removeUserCompany,
  updateUserCompanyNewRole,
  addUserCompany,
  updateUserCompany,
} from "modules/companies";

import { flashSuccess, flashWarning, flashError } from "lib/flash";

export const EditUserContext = createContext({
  onAddUserCompany: null,
  onRemoveUserCompany: null,
  toggleUserAdmin: null,
  basicInfo: null,
  onUpdateUser: null,
  setBasicInfo: null,
  companies: null,
});

const EditUserContextProvider = ({ children, user }) => {
  const companies = useArray(user.companies);

  const { state: basicInfo, setState: setBasicInfo } = useSetState({
    id: user.id,
    first_name: user.first_name,
    last_name: user.last_name,
    is_superuser: user.is_superuser,
    phone_number: user.phone_number,
    is_locked: user.is_locked,
    system_permission_id: user.system_permission_id,
    email: user.email,
  });

  const onUpdateUser = () => {
    return updateAdminUser(basicInfo)
      .then((res) => {
        flashSuccess("Info saved");
        return Promise.resolve();
      })
      .catch((res) => {
        res.error.map((error) => {
          flashError(error);
        });
        return Promise.resolve();
      });
  };

  const onRemoveUserCompany = (user_company) => {
    removeUserCompany({
      user_company: { id: user_company.id },
      company_id: user_company.company_id,
    }).then(() => {
      companies.removeById(user_company.id);
      flashWarning("Company removed");
    });
  };

  const onAddUserCompany = (companyId) => {
    addUserCompany({
      user_company: { user_id: basicInfo.id, role_id: 2 },
      company_id: companyId,
    }).then((resp) => {
      companies.push(resp);
      flashSuccess("Company Added");
    });
  };

  const updateUserCompanyRole = (user_company, role) => {
    updateUserCompanyNewRole({
      user_company: {
        ...user_company,
      },
      role_id: role.id,
      company_id: user_company.company_id,
    })
      .then((resp) => {
        companies.modifyById(user_company.id, {
          ...user_company,
          role: role.name,
          role_id: role.id,
        });
        flashSuccess("User role updated");
      })
      .catch((resp) => {
        resp.errors.map((error) => {
          flashError(error);
        });
        return Promise.resolve();
      });
  };

  const toggleUserAdmin = (user_company) => {
    updateUserCompany({
      user_company: {
        ...user_company,
        role_id: user.is_admin ? 2 : 1,
      },
      company_id: user_company.company_id,
    }).then((resp) => {
      companies.modifyById(user_company.id, {
        ...user_company,
        is_admin: !user_company.is_admin,
      });

      if (user_company.is_admin) {
        flashSuccess("User unmarked Admin");
      } else {
        flashSuccess("User marked Admin");
      }
    });
  };

  const context = {
    onAddUserCompany,
    updateUserCompanyRole,
    onRemoveUserCompany,
    onUpdateUser,
    toggleUserAdmin,
    companies,
    basicInfo,
    setBasicInfo,
  };

  return (
    <EditUserContext.Provider value={context}>
      {children}
    </EditUserContext.Provider>
  );
};

export default EditUserContextProvider;
