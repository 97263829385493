import React, { useState } from "react";
import { UILabelInput, UIFormLabel } from "../../../ui";
import { flashError, flashSuccess } from "lib/flash";

import { createIncentiveFormSchemaComponent } from "modules/incentive-form-schema-components";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import jsonWorkerUrl from "file-loader!ace-builds/src-noconflict/worker-json";

ace.config.setModuleUrl("ace/mode/json_worker", jsonWorkerUrl);
import "brace/ext/searchbox";

const Create = () => {
  const [name, setName] = useState("");
  const [schema, setSchema] = useState("{}");

  const handleCreate = () => {
    const snakeCaseRegex = /^[a-z]+(?:_[a-z]+)*$/;

    if (!snakeCaseRegex.test(name)) {
      alert("The component name must be in snake_case format.");
      return;
    }

    const params = {
      incentive_form_schema_component: {
        name: name,
        schema: JSON.parse(schema),
      },
    };

    createIncentiveFormSchemaComponent(params)
      .then((response) => {
        flashSuccess("Component created successfully.");
        window.location.href = "/admin/incentive_form_schema_components";
      })
      .catch((error) => {
        flashError("There was an error creating the schema");
        console.error("Error creating component", error);
      });
  };

  return (
    <div className="panel-body admin-company-form">
      <div className="row">
        <div className="col-lg-5">
          <h3 className="my-0">Create New Component Schema</h3>
          <div className="mb-3">
            <UILabelInput
              value={name}
              label="Component Name"
              tooltip="Name must be snake_case"
              onChange={(newValue) => setName(newValue)}
            />
          </div>
          <div className="mb-3">
            <UIFormLabel label="Schema Editor" />
            <AceEditor
              mode="json"
              theme="github"
              width="100%"
              placeholder="Enter JSON schema here..."
              wrapEnabled={true}
              value={schema}
              onChange={(newValue) => setSchema(newValue)}
            />
          </div>
        </div>
      </div>
      <div className="mb-5 btn-group float-end">
        <a
          className="btn btn-light"
          href="/admin/incentive_form_schema_components"
        >
          Back
        </a>
        <button className="btn btn-dark" onClick={handleCreate}>
          Create Component
        </button>
      </div>
    </div>
  );
};

export default Create;
