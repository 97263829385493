import React from "react";

const UINavHorizontalExternal = ({
  tabs,
  style = "tabs",
  logo,
  rightButtons,
  backgroundColor,
  excludeNavLinks,
  containerClassName = "container",
  navbarClassName = "navbar navbar-expand-md justify-content-center",
}) => {
  const renderDropdown = (tabItem, index) => {
    const { items } = tabItem;

    return (
      <li className="nav-item dropdown" key={`nav-tab-ui-${index}`}>
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-bs-toggle="dropdown"
          id={`${tabItem.name.toLowerCase()}Dropdown`}
          role="button"
          aria-expanded="false"
        >
          {tabItem.name}
        </a>

        <ul
          className="dropdown-menu"
          aria-labelledby={`${tabItem.name.toLowerCase()}Dropdown`}
        >
          {items.map((item, jindex) => (
            <li key={`dropdown-tab-item-${jindex}-${index}`}>
              <a className="dropdown-item" href={item.link}>
                {item.icon && (
                  <i
                    className={`${item.icon} nav-item-icon`}
                    style={{ marginRight: "8px" }}
                  ></i>
                )}
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </li>
    );
  };

  const renderNavItem = (tabItem, index) => {
    if (tabItem.type === "dropdown") {
      return renderDropdown(tabItem, index);
    }

    let navLinkClassName = "nav-link";

    if (style === "pill") {
      navLinkClassName += " btn";
    }

    return (
      <li key={`nav-tab-ui-${index}`} className="nav-item">
        <a className={navLinkClassName} href={tabItem.link}>
          {tabItem.name}
        </a>
      </li>
    );
  };

  const renderNavContent = () => {
    if (excludeNavLinks) return null;

    return (
      <ul className="navbar-nav justify-content-center">
        {tabs.map((tabItem, index) =>
          tabItem.type === "dropdown"
            ? renderDropdown(tabItem, index)
            : renderNavItem(tabItem, index)
        )}
      </ul>
    );
  };

  const renderRightButtons = () => {
    if (!rightButtons) return null;

    return (
      <ul className="navbar-nav w-100 justify-content-end">
        {rightButtons.map((button, index) => (
          <li key={`right-button-${index}`} className="nav-item">
            <a
              href={button.link}
              className={button.className || "nav-link"}
              style={button.style}
            >
              {button.text}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  if (style === "navbar") {
    return (
      <nav className={navbarClassName} style={{ backgroundColor }}>
        <div className={containerClassName}>
          {logo && (
            <a
              href={logo.link}
              className="navbar-brand d-flex w-50 me-auto py-0 my-0"
            >
              <img
                src={logo.src}
                height={logo.height}
                width={logo.width}
                alt={logo.alt}
              />
            </a>
          )}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navLinks"
            aria-controls="navLinks"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navLinks">
            {renderNavContent()}
            {renderRightButtons()}
          </div>
        </div>
      </nav>
    );
  }

  let navStyleClassName = style === "pill" ? "nav-pills" : "nav-tabs";

  return (
    <div className="mb-4">
      <ul className={`nav ${navStyleClassName}`}>
        {tabs.map((tabItem, index) =>
          tabItem.type === "dropdown"
            ? renderDropdown(tabItem, index)
            : renderNavItem(tabItem, index)
        )}
      </ul>
    </div>
  );
};

export default UINavHorizontalExternal;
