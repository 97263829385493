import React, { useState } from "react";

const UITooltipDescription = ({ description, hideTooltip }) => {
  const [showMore, setShowMore] = useState(false);

  if (showMore)
    return (
      <>
        {description}
        &nbsp;
        <span
          className="ui-ellipsis-button"
          onClick={() => {
            setShowMore(false);
            hideTooltip && setTimeout(hideTooltip, 300);
          }}
        >
          show less
        </span>
      </>
    );

  return (
    <>
      {description.substring(0, 100)}
      {description.length > 100 && (
        <>
          ...&nbsp;
          <span
            className="ui-ellipsis-button"
            onClick={() => setShowMore(true)}
          >
            show more
          </span>
        </>
      )}
    </>
  );
};

export default UITooltipDescription;
