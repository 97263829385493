import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useArray } from "react-hanger";

import { displayPrice, displayPercent, searchArrayField } from "lib/string";

import { filter, findIndex, debounce } from "lodash";

import {
  createAllocatedLineItem,
  updateAllocatedLineItem,
  deleteAllocatedLineItem,
} from "modules/project";

import UnallocatedProjectRow from "./unallocated-project-row";
import AllocatedProjectRow from "./allocated-project-row";

const ProjectAllocator = (props) => {
  const { item, company_id } = props;

  const [projectFilter, setProjectFilter] = useState("")

  const [isOpen, setIsOpen] = useState(false);
  const [allocatedProjects, setAllocatedProjects] = useState(
    props.allocatedProjects
  );
  const [projects, setProjects] = useState(props.projects || []);
  const [filteredProjects, setFilteredProjects] = useState(
    filterProjects() || []
  );

  const [totalFractionAllocated, setTotalFractionAllocated] = useState(0.0);
  const [totalDollarAllocated, setTotalDollarAllocated] = useState(0.0);

  function updateAllocation(lineItem, fraction, transaction_type) {
    const params = {
      fraction: fraction,
      quantity: (lineItem.quantity / lineItem.fraction) * fraction,
      total_amount: (lineItem.total_amount / lineItem.fraction) * fraction,
      transaction_type: transaction_type,
      allocation_id: lineItem.allocation_id,
      allocation_type: lineItem.allocation_type,
    };

    updateAllocatedLineItem(lineItem.id, params).then(() => {
      props.fetchAllocatedProjects();
    });
  }

  function unallocateProject(id) {
    deleteAllocatedLineItem(id).then(() => {
      props.fetchAllocatedProjects();
    });
  }

  function allocateProject(project, fraction, transaction_type) {
    const params = {
      allocated_line_item: {
        company_project_id: project.id,
        allocation_type: item.allocation_type,
        allocation_id: item.id,
        description: item.description,
        transaction_type: transaction_type,
        fraction: fraction,
        quantity: item.quantity * fraction,
        total_amount: item.unit_cost * item.quantity * fraction,
        unit_cost: item.unit_cost,
      },
    };

    createAllocatedLineItem(params)
      .then((result) => {
        props.fetchAllocatedProjects();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleProjectFilerChange(e) {
    e.preventDefault();
    setProjectFilter((e.target.value))
  }

  useEffect(() => {
    setAllocatedProjects(props.allocatedProjects);
  }, [props.allocatedProjects]);

  useEffect(() => {
    updateTotals();
    setFilteredProjects(filterProjects());
  }, [allocatedProjects]);

  function updateTotals() {
    let total = 0.0;
    let totalPercent = 0.0;

    allocatedProjects.forEach((allocatedLineItem) => {
      total += allocatedLineItem.total_amount;
      totalPercent += parseFloat(allocatedLineItem.fraction);
    });

    setTotalDollarAllocated(total);
    setTotalFractionAllocated(totalPercent);
  }

  useEffect(() => {
    if (isOpen) {
      props.fetchAllocatedProjects();
    }
  }, [isOpen]);

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      setFilteredProjects(filterProjects())
    }, 350);
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [projectFilter])

  useEffect(() => {
    setProjects(props.projects);
    setFilteredProjects(filterProjects());
  }, [props.projects]);

  function filterProjects() {
    const filtered = filter(props.projects, (project) => {
      const index = findIndex(allocatedProjects, (allocatedProject) => {
        return allocatedProject.company_project_id === project.id;
      });

      return index === -1;
    });

    return searchArrayField(filtered, projectFilter, "name");
  }

  function openAllocation(e) {
    e.preventDefault();
    setIsOpen(true);
  }

  function renderLineItem() {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Description</th>
            <th>Date</th>
            <th>Unit Cost</th>
            <th>Quantity</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{item.description}</td>
            <td>{moment(item.acquisition_date).format("MM/DD/YYYY")}</td>
            <td>{displayPrice(parseFloat(item.unit_cost))}</td>
            <td>{parseFloat(item.quantity)}</td>
            <td>{displayPrice(item.unit_cost * item.quantity)}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  function renderProjects() {
    if (!projects.length) {
      return null;
    }

    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Id</th>
            <th style={{ width: "50%" }}>Name</th>
            <th style={{ width: "10%" }}>Status</th>
            <th style={{ width: "20%" }}>Transaction</th>
            <th style={{ width: "10%" }}>Allocation</th>
            <th style={{ width: "10%" }}>Total</th>
            <th style={{ width: "10%" }}></th>
          </tr>
        </thead>
        <tbody>
          {allocatedProjects.map((allocatedLineItem, index) => {
            return (
              <AllocatedProjectRow
                key={`allocated-project-${index}-${allocatedLineItem.id}`}
                project={
                  projects.filter(
                    (project) =>
                      project.id == allocatedLineItem.company_project_id
                  )[0]
                }
                fraction={allocatedLineItem.fraction}
                updateAllocation={updateAllocation}
                unallocateProject={unallocateProject}
                lineItem={allocatedLineItem}
              />
            );
          })}

          {filteredProjects.map((project, index) => {
            return (
              <UnallocatedProjectRow
                key={`project-${project.id}`}
                allocateProject={allocateProject}
                project={project}
                item={item}
              />
            );
          })}
          <tr>
            <td colSpan={2}></td>
            <td>
              <strong>{displayPercent(totalFractionAllocated)}</strong>
            </td>
            <td>
              <strong>{displayPrice(totalDollarAllocated)}</strong>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    );
  }

  function renderModal() {
    if (!isOpen) return null;

    return (
      <Modal
        centered
        show={isOpen}
        onHide={() => setIsOpen(false)}
        dialogClassName="modal-xl"
      >
        <Modal.Header closeButton>
          <h3>
            <strong>Allocate To Projects</strong>
          </h3>
        </Modal.Header>
        <Modal.Body>
          <h5>
            <strong>Line Item</strong>
          </h5>
          {renderLineItem()}

          <h5 className="mt-3">
            <strong>Projects</strong>
          </h5>

          <div className="input-group mb-3">
            <input type="text" placeholder="Filter" className="form-control" value={projectFilter} onChange={handleProjectFilerChange} />
          </div>

          {renderProjects()}
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <div>
      {renderModal()}
      <button className="btn btn-light" onClick={openAllocation}>
        Edit
      </button>
    </div>
  );
};

export default ProjectAllocator;
