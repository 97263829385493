import axios from "lib/axios";

export const createOnboardingAttachments = (params = {}, options) => {
  const url = `/onboarding/upload_files`;

  let formData = new FormData();

  for ( var key in params ) {
    if(key === 'attachment_attributes') {
      for (let fileKey in params.attachment_attributes) {
        formData.append(`attachment_attributes[${fileKey}]`, params.attachment_attributes[fileKey]);
      }
    }
    else {
      formData.append(`[${key}]`, params[key]);
    }
  }

  return axios
    .post(url, formData, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
