import React, { useState } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { exportToPdf as exportPdfUtil } from "lib/utils";

import ProjectQuoteDetails from "./../components/project-quote-details";
import ProjectQuoteHeader from "./../components/project-quote-header";
import ProjectQuoteFooter from "./../components/project-quote-footer";
import ProjectQuoteLogo from "./../components/project-quote-logo";

const ViewQuoteModal = ({ show, handleClose, exportToPdf, project }) => {
  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <>
        <Modal.Body id="modal-body">
          <ProjectQuoteLogo
            external_project_id={project.id}
            company_project_id={project.id}
            project={project}
          />
          <ProjectQuoteHeader company_project_id={project.id} />
          <ProjectQuoteDetails
            company_estimate_categories={project.company_estimate_categories}
            estimates={project.estimates}
            updateEstimateCategory={project.updateEstimateCategory}
            company_project_id={project.id}
          />
          <ProjectQuoteFooter company_project_id={project.id} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="action" onClick={exportToPdf}>
            Export to PDF <i className="bi bi-file-earmark-arrow-down"></i>
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};

export default ViewQuoteModal;
