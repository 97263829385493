import React, { useState, useEffect } from "react";

import { sumBy, isEmpty } from "lodash";

import { useArray } from "react-hanger";
import { displayPrice, prettyDisplayPrice } from "lib/string";

import { newEstimateLineItem } from "modules/project";
import EstimateLineItemRow from "./estimate-line-item-row";

import { withCategories, EstimateCategoryManager } from "./estimate-categories";

import {
  addEstimateLineItemGroups,
  deleteEstimateLineItemGroup,
  createEstimateLineItem,
  updateEstimateLineItem,
  deleteEstimateLineItem,
} from "modules/project";

import { updateProjectEstimateCategory } from "modules/company-estimate-category";

import { flashError, flashSuccessLater, flashSuccess } from "lib/flash";

import Dropdown from "react-bootstrap/Dropdown";
import * as PropTypes from "prop-types";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    className="btn btn-sm btn-muted float-end"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

import CategoryDescriptionTooltip from "./category-description-tooltip";

const EstimateCategoryGroup = (props) => {
  const category = props.estimate.company_estimate_category;

  const items = useArray(props.estimate.estimate_line_items);
  const [isCollapsed, setIsCollapsed] = useState(
    props.estimate.collapsed || props.hidden
  );
  const [isEditing, setIsEditing] = useState(false);

  const [groupTotal, setGroupTotal] = useState(0.0);
  const [total, setTotal] = useState({});

  const [markupGroupTotal, setMarkupGroupTotal] = useState(0.0);
  const [markupTotal, setMarkupTotal] = useState({});

  const [categories, setCategories] = useState([]);
  const [showCategoryPicker, setShowCategoryPicker] = useState(false);
  const [addedLineItems, setAddedLineItems] = useState(false);
  const [description, setDescription] = useState(props.estimate.description);
  const [quantity, setQuantity] = useState(props.estimate.quantity);
  const [features, setFeatures] = useState(props.estimate.features || {});
  const [updateCategories, setUpdateCategories] = useState(false);

  let marginLeftAmount = props.level * 12;

  function updateCollapsed(collapsed = null) {
    if (collapsed === null) {
      collapsed = isCollapsed;
    }

    const params = {
      id: props.estimate.id,
      project_estimate_category: {
        collapsed,
      },
    };

    updateProjectEstimateCategory(params);
  }

  function updateCategoryTotal() {
    const params = {
      id: props.estimate.id,
      project_estimate_category: {
        unit_amount: props.applyMarkup({
          total: sumTotal(),
          markup: sumMarkupTotal(),
        }),
      },
    };

    updateProjectEstimateCategory(params);
  }

  function updateCategory(collapsed = null) {
    const params = {
      id: props.estimate.id,
      project_estimate_category: {
        description,
        quantity,
        features,
        unit_amount: props.applyMarkup({
          total: sumTotal(),
          markup: sumMarkupTotal(),
        }),
      },
    };

    updateProjectEstimateCategory(params)
      .then(() => {
        flashSuccess("Category updated successfully!");
        setIsEditing(false);
        props.fetchEstimates();
      })
      .catch((error) => {
        flashError("Error updating category!");
        console.error(error);
      });
  }

  function saveCategories(parent_id = null) {
    if (!categories || categories.length === 0) return;

    const params = {
      parent_id,
      categories: categories.map((c) => c.company_estimate_category_id),
    };

    addEstimateLineItemGroups(props.project.id, params)
      .then(() => {
        setShowCategoryPicker(false);
        flashSuccess("Estimate categories added to Project.");
        props.fetchEstimates();
      })
      .catch((error) => {
        flashError(error);
      });
  }

  useEffect(() => {
    if (updateCategories) {
      updateCategoryTotal();
    }
  }, [total]);

  useEffect(() => {
    const subtotal = sumBy(props.estimate.estimate_line_items, (item) => {
      return parseFloat(item.total_amount);
    });

    if (!!props.calculateTotal) {
      props.calculateTotal((quantity || 1) * subtotal, props.estimate.id);
    }

    setUpdateCategories(true);
  }, []);

  useEffect(() => {
    const subtotal = sumBy(items.value, (item) => {
      return parseFloat(item.total_amount);
    });
    setGroupTotal(subtotal);
    calculateTotal(subtotal, props.estimate.id);

    if (props.project.is_markup_active) {
      let markup = 0;
      if (props.markupMode === "total") {
        markup = sumBy(items.value, (item) => {
          return parseFloat(item.markup || 0);
        });
      } else {
        const markupSubtotal = sumBy(items.value, (item) => {
          return (
            (parseFloat(item.markup || 0) / 100 + 1) *
            parseFloat(item.total_amount)
          );
        });

        if (markupSubtotal === 0) {
          markup = 0;
        } else {
          markup = ((markupSubtotal - subtotal) / subtotal) * 100;
        }
      }

      setMarkupGroupTotal(markup);
      calculateMarkupTotal(markup, props.estimate.id);
    }
  }, [items.value]);

  useEffect(() => {
    if (props.level > 0) {
      for (var k in total) {
        props.calculateTotal((quantity || 1) * total[k], k);
      }
    } else {
      props.setGrandTotal((prev) => {
        return {
          ...prev,
          [props.estimate.id]: props.applyMarkup({
            total: sumTotal(),
            markup: sumMarkupTotal(),
          }),
        };
      });
    }
  }, [total, quantity]);

  useEffect(() => {
    if (props.level > 0) {
      for (var k in markupTotal) {
        props.calculateMarkupTotal(markupTotal[k], k);
      }
    }
  }, [markupTotal]);

  useEffect(() => {
    const category_name = props.estimate.company_estimate_category.name;
    const parent_id = props.estimate.parent_id;
    const children = props.estimate.children;
    const estimate_line_items = props.estimate.estimate_line_items;
    const markupTotal = props.applyMarkup({
      markup: sumMarkupTotal(),
      total: sumTotal(),
    });

    props.updateEstimateCategory(
      props.estimate.id,
      isCollapsed,
      category_name,
      description,
      parent_id,
      children,
      estimate_line_items,
      markupTotal,
      quantity,
      features
    );
  }, [props.estimate.description, isCollapsed, props.estimate.parent_id]);

  function calculateMarkupTotal(markupSubtotal, key) {
    setMarkupTotal((previousState) => {
      return {
        ...previousState,
        [key]: markupSubtotal,
      };
    });
  }

  function calculateTotal(subtotal, key) {
    if (total[key] !== subtotal) {
      setTotal((previousState) => {
        return {
          ...previousState,
          [key]: subtotal,
        };
      });
    }
  }

  function sumTotal(quantity) {
    let subtotal = 0;
    for (var k in total) {
      subtotal += total[k];
    }

    return (quantity || 1) * subtotal;
  }

  function sumMarkupTotalPercent() {
    let markupSubtotal = 0;
    let subtotal = 0;

    for (var k in total) {
      subtotal += total[k];
      if (markupTotal[k]) {
        markupSubtotal += (markupTotal[k] / 100 + 1) * total[k];
      } else {
        markupSubtotal += total[k];
      }
    }

    if (markupSubtotal === 0) {
      return 0;
    } else {
      return ((markupSubtotal - subtotal) / subtotal) * 100;
    }
  }

  function sumMarkupTotalDollar() {
    let subtotal = 0;
    for (var k in markupTotal) {
      subtotal += markupTotal[k];
    }

    return subtotal;
  }

  function sumMarkupTotal(field) {
    return props.markupMode === "total"
      ? sumMarkupTotalDollar()
      : sumMarkupTotalPercent();
  }

  function removeCategory(e) {
    e.preventDefault();
    let confirmDeletion = true;

    if (props.estimate.children && props.estimate.children.length > 0) {
      confirmDeletion = window.confirm(
        "Are you sure you want to delete this category? Deleting this category will remove all nested categories and items."
      );
    }

    if (confirmDeletion) {
      deleteEstimateLineItemGroup(props.project.id, props.estimate.id)
        .then((response) => {
          flashSuccess("Category deleted.");
          props.fetchEstimates();
          calculateTotal(total[props.id], props.id);
          if (props.project.is_markup_active) {
            calculateMarkupTotal(markupTotal[props.id], props.id);
          }
        })
        .catch((error) => {
          flashError(error);
        });
    } else {
      flashError("Category deletion was canceled.");
    }
  }

  function addItem(e) {
    e.preventDefault();
    items.unshift(newEstimateLineItem());
  }

  function updateItem(item, field, value, additionalValues = {}) {
    let unit_amount = field === "unit_amount" ? value : item.unit_amount;
    let quantity = field === "quantity" ? value : item.quantity;

    items.modifyById(item.id, {
      ...item,
      ...additionalValues,
      [field]: value,
      total_amount: unit_amount * quantity,
    });
  }

  function saveItem(item) {
    const params = {
      estimate_line_item: {
        description: item.description,
        quantity: item.quantity,
        unit: item.unit,
        markup: item.markup,
        total_amount: item.total_amount,
        unit_amount: item.unit_amount,
        quantity: item.quantity,
        company_project_id: props.project.id,
        project_estimate_category_id: props.estimate.id,
        features: item.features,
      },
    };

    if (item._new) {
      createEstimateLineItem(params)
        .then((newItem) => {
          updateCategoryTotal();
          updateItem(item, "id", newItem.id, { _editing: false, _new: false });
          props.fetchEstimates();
          flashSuccess(`Estimate line item added to ${category.name}`);
        })
        .catch((error) => {
          console.error(error);
          flashError(error);
        });
    } else {
      updateEstimateLineItem(item.id, params)
        .then(() => {
          updateCategoryTotal();
          updateItem(item, "_editing", false);
          props.fetchEstimates();
          flashSuccess("Estimate line item updated.");
        })
        .catch((error) => {
          console.error(error);
          flashError(error);
        });
    }
  }

  function removeItem(item) {
    if (item._new) {
      items.removeById(item.id);
    } else {
      deleteEstimateLineItem(item.id)
        .then(() => {
          flashSuccess("Estimate line item deleted.");
          items.removeById(item.id);
          props.fetchEstimates();
        })
        .catch((error) => {
          console.error(error);
          flashError(error);
        });
    }
  }

  function renderExpandedCategoryGroupHead() {
    if (props.project.is_markup_active) {
      return (
        <thead>
          <tr>
            <th style={{ width: "35%" }}>Description</th>
            <th style={{ width: "10%" }}>Quantity</th>
            <th style={{ width: "10%" }}>Unit</th>
            <th style={{ width: "10%" }}>Unit Cost</th>
            <th style={{ width: "8%" }}>Subtotal</th>
            <th style={{ width: "8%" }}>Markup</th>
            <th style={{ width: "8%" }}>Total</th>
            <th style={{ width: "10%" }}></th>
          </tr>
        </thead>
      );
    } else {
      return (
        <thead>
          <tr>
            <th style={{ width: "45%" }}>Description</th>
            <th style={{ width: "10%" }}>Quantity</th>
            <th style={{ width: "15%" }}>Unit</th>
            <th style={{ width: "10%" }}>Unit Cost</th>
            <th style={{ width: "8%" }}>Total</th>
            <th style={{ width: "12%" }}></th>
          </tr>
        </thead>
      );
    }
  }

  function renderExpandedItems() {
    return (
      <table
        className="table table-hover my-0"
        key={`estimate-expanded-items-${props.estimate.id}-${props.level}`}
      >
        {renderBlankHeaders()}
        <tbody>
          <>
            {items.value.map((item, index) => {
              return (
                <EstimateLineItemRow
                  key={`estimate-item-${item.id}-${category.id}`}
                  item={item}
                  isMarkupActive={props.project.is_markup_active}
                  applyMarkup={props.applyMarkup}
                  updateItem={updateItem}
                  removeItem={removeItem}
                  saveItem={saveItem}
                  renderMarkupValue={renderMarkupValue}
                  marginLeftAmount={marginLeftAmount}
                  level={props.level}
                />
              );
            })}
            <tr>
              <td colSpan={4}></td>
              <td>
                <div className="fw-semibold">
                  {prettyDisplayPrice(groupTotal, false, true)}
                </div>
              </td>
              {props.project.is_markup_active ? (
                <td>
                  <div className="fw-semibold">
                    {renderMarkupValue(markupGroupTotal)}
                  </div>
                </td>
              ) : null}

              {props.project.is_markup_active ? (
                <td>
                  <div className="fw-semibold">
                    {prettyDisplayPrice(
                      props.applyMarkup({
                        markup: parseFloat(markupGroupTotal),
                        total: parseFloat(groupTotal),
                      }),
                      false,
                      true
                    )}
                  </div>
                </td>
              ) : null}
              <td></td>
            </tr>
          </>
        </tbody>
      </table>
    );
  }

  function renderSubGroups() {
    return (
      props.estimate.children &&
      props.estimate.children.map((child_id, index) => {
        let child = props.estimates.find(
          (estimate) => estimate["id"] == child_id
        );

        if (!child) {
          return;
        }

        let key = props.keyPrefix + `-child-${index}`;

        return (
          <EstimateCategoryGroup
            {...props}
            calculateTotal={calculateTotal}
            calculateMarkupTotal={calculateMarkupTotal}
            key={key}
            level={props.level + 1}
            keyPrefix={key}
            estimate={child}
            hidden={isCollapsed}
          />
        );
      })
    );
  }

  function renderItems() {
    let rows = [];

    if (
      !isCollapsed &&
      (!isEmpty(items.value) || !isEmpty(props.estimate.children)) &&
      props.level === 0
    ) {
      rows.push(
        <table
          className="table table-hover my-0"
          key={`estimate-expanded-items-${props.estimate.id}-${props.level}-item`}
        >
          {props.level === 0
            ? renderExpandedCategoryGroupHead()
            : renderBlankHeaders()}
        </table>
      );
    }

    if (!isCollapsed && !isEmpty(items.value)) {
      rows.push(renderExpandedItems());
    }

    if (!isEmpty(props.estimate.children)) {
      rows.push(renderSubGroups());
    }

    if (
      !isCollapsed &&
      (props.level === 0 ||
        (!isEmpty(items.value) && !isEmpty(props.estimate.children)))
    ) {
      rows.push(renderTotal());
    }

    return rows;
  }

  function renderBlankHeaders() {
    if (props.project.is_markup_active) {
      return (
        <thead>
          <tr>
            <th className="py-0" style={{ width: "35%" }}>
              {""}
            </th>
            <th className="py-0" style={{ width: "10%" }}>
              {""}
            </th>
            <th className="py-0" style={{ width: "10%" }}>
              {""}
            </th>
            <th className="py-0" style={{ width: "10%" }}>
              {""}
            </th>
            <th className="py-0" style={{ width: "8%" }}>
              {""}
            </th>
            <th className="py-0" style={{ width: "8%" }}>
              {""}
            </th>
            <th className="py-0" style={{ width: "8%" }}>
              {""}
            </th>
            <th className="py-0" style={{ width: "10%" }}></th>
          </tr>
        </thead>
      );
    } else {
      return (
        <thead>
          <tr>
            <th className="py-0" style={{ width: "45%" }}>
              {""}
            </th>
            <th className="py-0" style={{ width: "10%" }}>
              {""}
            </th>
            <th className="py-0" style={{ width: "15%" }}>
              {""}
            </th>
            <th className="py-0" style={{ width: "10%" }}>
              {""}
            </th>
            <th className="py-0" style={{ width: "8%" }}>
              {""}
            </th>
            <th className="py-0" style={{ width: "12%" }}></th>
          </tr>
        </thead>
      );
    }
  }

  function renderCategoryHeader() {
    return (
      <tbody>
        <tr
          style={{ backgroundColor: backgroundColor }}
          className={trClassName}
        >
          <td className={paddingClass}>
            <div style={{ marginLeft: `${marginLeftAmount}px` }}>
              <div
                style={{ width: "35px" }}
                className="d-inline-block align-middle"
              >
                <button
                  className="btn btn-sm btn-muted chevron-button"
                  onClick={toggleDisplay}
                >
                  {isCollapsed ? (
                    <i className="bi bi-chevron-right"></i>
                  ) : (
                    <i className="bi bi-chevron-down"></i>
                  )}
                </button>
              </div>
              <div className="d-inline-block align-middle">
                <h5 className="m-0 p-0">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: category.name,
                    }}
                  ></div>
                </h5>
              </div>
              <div
                className="d-inline-block align-middle"
                style={{ paddingLeft: "10px" }}
              >
                {renderCategoryDescription()}
              </div>
            </div>
          </td>
          {renderCategoryDetails()}
          {renderCategoryMenu()}
        </tr>
        {renderCategoryFeatures()}
      </tbody>
    );
  }

  function renderCategoryFeatures() {
    if (!isEditing) {
      return null;
    }

    return (
      <tr style={{ backgroundColor: backgroundColor }} className={trClassName}>
        <td colSpan={42} style={{ paddingLeft: "35px" }}>
          <input
            type="checkbox"
            id={`taxable-checkbox-${props.estimate.id}`}
            className="company-admin-checkbox me-2"
            checked={!!features.taxable}
            onChange={(e) => {
              setFeatures({ ...features, taxable: !features.taxable });
            }}
          />
          <label className="form-label mb-1" style={{ paddingRight: "15px" }}>
            Taxable
          </label>
          {props.level !== 0 && (
            <>
              <input
                type="checkbox"
                id="quote-item-checkbox"
                className="company-admin-checkbox me-2"
                checked={!!features.quote}
                onChange={(e) => {
                  setFeatures({ ...features, quote: !features.quote });
                }}
              />
              <label
                className="form-label mb-1"
                style={{ paddingRight: "15px" }}
              >
                Include in Quote
              </label>
            </>
          )}
          <>
            <input
              type="checkbox"
              id="quote-quantity-checkbox"
              className="company-admin-checkbox me-2"
              checked={!!features.quoteQuantity}
              onChange={(e) => {
                setFeatures({
                  ...features,
                  quoteQuantity: !features.quoteQuantity,
                });
              }}
            />
            <label className="form-label mb-1" style={{ paddingRight: "15px" }}>
              Show quantity in quote
            </label>
          </>
          {props.level !== 0 && (
            <>
              <input
                type="checkbox"
                id="quote-price-checkbox"
                className="company-admin-checkbox me-2"
                checked={!!features.quotePrice}
                onChange={(e) => {
                  setFeatures({
                    ...features,
                    quotePrice: !features.quotePrice,
                  });
                }}
              />
              <label className="form-label mb-1">Quote Price</label>
            </>
          )}
        </td>
      </tr>
    );
  }

  function renderCategoryDescription() {
    return isEditing ? (
      <input
        type="text"
        className="form-control"
        value={description}
        onChange={(e) => {
          e.preventDefault();
          setDescription(e.target.value);
        }}
        placeholder="Enter description..."
      />
    ) : (
      <CategoryDescriptionTooltip description={description} />
    );
  }

  function renderCategoryDetails() {
    return (
      <>
        <td className={paddingClass}>
          {isEditing ? (
            <input
              type="text"
              className="form-control"
              value={quantity}
              onChange={(e) => {
                e.preventDefault();
                setQuantity(e.target.value);
              }}
              placeholder="Quantity..."
            />
          ) : (
            <div className="fw-semibold">{quantity}</div>
          )}
        </td>
        <td className={paddingClass}>
          {isCollapsed ? (
            <div className="fw-semibold">{category.unit}</div>
          ) : null}
        </td>
        <td className={paddingClass}>
          {isCollapsed && quantity ? (
            <div className="fw-semibold">
              {prettyDisplayPrice(sumTotal(), false, true)}
            </div>
          ) : null}
        </td>
        {props.project.is_markup_active ? (
          <td className={paddingClass}>
            {isCollapsed ? (
              <div className="fw-semibold">
                {prettyDisplayPrice(sumTotal(quantity), false, true)}
              </div>
            ) : null}
          </td>
        ) : null}
        {props.project.is_markup_active ? (
          <td className={paddingClass}>
            {isCollapsed ? (
              <div className="fw-semibold">
                {renderMarkupValue(sumMarkupTotal())}
              </div>
            ) : null}
          </td>
        ) : null}
        <td className={paddingClass}>
          {isCollapsed ? (
            <div className="fw-semibold">
              {props.project.is_markup_active
                ? prettyDisplayPrice(
                    props.applyMarkup({
                      markup: sumMarkupTotal(),
                      total: sumTotal(quantity),
                    }),
                    false,
                    true
                  )
                : prettyDisplayPrice(sumTotal(quantity), false, true)}
            </div>
          ) : null}
        </td>
      </>
    );
  }

  function renderCategoryMenu() {
    return (
      <>
        <td className={paddingClass}>
          {isEditing ? (
            <>
              <button
                className="btn btn-sm btn-muted"
                style={{ float: "right" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditing(false);
                }}
              >
                <i className="bi bi-x"></i>
              </button>
              <button
                className="btn btn-sm btn-primary me-2"
                style={{ float: "right" }}
                onClick={(e) => {
                  e.preventDefault();
                  updateCategory();
                }}
              >
                <i className="bi bi-check"></i>
              </button>
            </>
          ) : (
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-toggle">
                <i className="bi bi-three-dots chevron-button"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#" onClick={addItem}>
                  New Item
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    setShowCategoryPicker(true);
                  }}
                >
                  Add Subcategory
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  Edit Category
                </Dropdown.Item>
                <Dropdown.Item href="#" onClick={removeCategory}>
                  Remove
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </td>
      </>
    );
  }

  function renderNewCategoryPicker() {
    if (isCollapsed || !showCategoryPicker) return null;

    return (
      <table className="table">
        {renderBlankHeaders()}
        <tbody>
          <tr className="table-light mb-3">
            <td colSpan={props.project.is_markup_active ? 8 : 6}>
              <div className="p-4">
                <div className="row">
                  <div className="col-md-5">
                    <EstimateCategoryManager
                      key={`estimate-manager-${props.estimate.id}-${props.level}`}
                      categories={categories}
                      updateCategories={setCategories}
                      company_project_id={props.project.id}
                      handleCancel={() => {
                        setCategories([]);
                        setShowCategoryPicker(false);
                      }}
                      handleSave={() => {
                        saveCategories(props.estimate.id);
                      }}
                    />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  function renderMarkupValue(value) {
    if (props.markupMode === "percent") {
      return `${Number(value || 0).toFixed(1)}%`;
    } else {
      return prettyDisplayPrice(value, false, true);
    }
  }

  function renderTotal() {
    let style = {};
    if (props.level === 0) {
      style = {
        borderBottom: "calc(var(--bs-border-width) * 2) solid currentcolor",
      };
    }

    if (props.project.is_markup_active) {
      return (
        <table
          className="table"
          key={`${props.keyPrefix}-totals-markup-${props.estimate.id}`}
        >
          {renderBlankHeaders()}
          <tbody>
            <tr className="table-group-divider" style={style}>
              <td>
                <div style={{ marginLeft: `${marginLeftAmount}px` }}>
                  <strong>{category.name} Total</strong>
                </div>
              </td>
              {isEditing ? (
                <input
                  type="text"
                  className="form-control"
                  value={quantity}
                  onChange={(e) => {
                    e.preventDefault();
                    setQuantity(e.target.value);
                  }}
                  placeholder="Enter quantity..."
                />
              ) : (
                <td>
                  <strong>{quantity}</strong>
                </td>
              )}
              <td>{quantity ? <strong>{category.unit}</strong> : null}</td>
              <td>
                {quantity ? (
                  <strong>{displayPrice(sumTotal(), false, true)}</strong>
                ) : null}
              </td>
              <td>
                <strong>
                  {prettyDisplayPrice(sumTotal(quantity), false, true)}
                </strong>
              </td>
              <td>
                <strong>{renderMarkupValue(sumMarkupTotal())}</strong>
              </td>
              <td>
                <strong>
                  {prettyDisplayPrice(
                    props.applyMarkup({
                      markup: sumMarkupTotal(),
                      total: sumTotal(quantity),
                    }),
                    false,
                    true
                  )}
                </strong>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      );
    } else {
      return (
        <table
          className="table"
          key={`${props.keyPrefix}-totals-${props.estimate.id}`}
        >
          {renderBlankHeaders()}
          <tbody>
            <tr className="table-group-divider" style={style}>
              <td>
                <div style={{ marginLeft: `${marginLeftAmount}px` }}>
                  <strong>{category.name} Total</strong>
                </div>
              </td>
              <td>
                <strong>{quantity}</strong>
              </td>
              <td>{quantity ? <strong>{category.unit}</strong> : null}</td>
              <td>
                {quantity ? (
                  <strong>{prettyDisplayPrice(sumTotal(), false, true)}</strong>
                ) : null}
              </td>
              <td>
                <strong>
                  {prettyDisplayPrice(sumTotal(quantity), false, true)}
                </strong>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      );
    }
  }

  function renderEmpty() {
    if (isCollapsed || showCategoryPicker) return null;

    return (
      <table className="table">
        {renderBlankHeaders()}
        <tbody>
          <tr className="table-light mb-3">
            <td colSpan={props.project.is_markup_active ? 8 : 6}>
              <div className="p-4 text-center">
                <h6 className="mb-3">
                  <strong>Start by adding a line item.</strong>
                </h6>
                <p>
                  Adding line items to this estimate category makes it easier to
                  compare your invoices and bills for this project.
                </p>
                <button className="btn btn-action" onClick={addItem}>
                  Add Line Item
                </button>
                <button
                  className="btn btn-action ms-3"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCategoryPicker(true);
                  }}
                >
                  Add Category
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  function toggleDisplay(e) {
    e.preventDefault();
    setIsCollapsed(!isCollapsed);
    updateCollapsed(!isCollapsed);
  }

  const backgroundColor = props.level === 0 ? "#E2F5FF" : "#FFFFFF";

  let trClassName = "align-middle row-with-shadow";
  if (isCollapsed && props.level > 0) {
    // trClassName += ' table-group-divider'
  }

  const paddingClass = props.level === 0 ? "py-2 px-2" : "py-2 px-2";

  if (props.hidden) {
    return renderSubGroups();
  }

  return (
    <div className="estimate-category-group">
      <table className="table table-borderless my-0">
        {renderBlankHeaders()}
        {renderCategoryHeader()}
      </table>
      <div>
        {renderNewCategoryPicker()}
        {isEmpty(items.value) && isEmpty(props.estimate.children)
          ? renderEmpty()
          : renderItems()}
      </div>
    </div>
  );
};

export default EstimateCategoryGroup;
