import axios from "lib/axios";
import { serialize } from 'object-to-formdata';

export const fetchAllEquipments = (params = {}) => {
  const url = `/company/company_purchase`;

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const createCompanyPurchase = (params = {}) => {
  const url = `/company/company_purchase`;
  
  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const updateCompanyPurchase = (params = {}) => {
  const url = `/company/company_purchase/${params.id}`;
  const formData = serialize(params, {nullsAsUndefineds: true});

  return axios
    .put(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const deleteCompanyPurchase = (params = {}) => {
  const url = `/company/company_purchase/${params.id}`;

  return axios
    .delete(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const uploadCompanyPurchaseCSV = (params = {}) => {
  const url = `/company/company_purchase/upload_csv`;
  const formData = serialize(params, {nullsAsUndefineds: true});

  return axios
    .post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const claimCompanyPurchase = (params = {}) => {
  const url = `/company/company_purchase/${params.id}/claim`;
  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const rejectCompanyPurchase = (id) => {
  const url = `/company/company_purchase/${id}/reject`;

  return axios
    .put(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const unrejectCompanyPurchase = (id) => {
  const url = `/company/company_purchase/${id}/unreject`;

  return axios
    .put(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
