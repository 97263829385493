import React from 'react';
import logo from 'images/subcity-icon-white.png'
import StepItem from './step-item';

const StepsNav = (props) => {
  let steps = props.steps.map((step, index) => {
    return (
      <StepItem
        index={index}
        isComplete={props.currentStep > index}
        isActive={props.currentStep === index}
        step={step}
        key={`step-item-${index}`}
      />
    )
  }) 

  return (
    <nav className='steps-nav'>
      <ul className='step-items-list'>
        { steps }
      </ul>
    </nav>
  )
}

export default StepsNav;
