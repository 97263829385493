import React, { useState, useEffect, useRef } from "react";
import { useSetState } from "react-hanger";
import { fetchAdminMetaEligibilityFields } from "modules/meta-eligibility-fields";
import moment from "moment";
import { KeywordSearch } from "components/ui";
import AdminPanelHeader from "../../common/admin-panel-header";

const MetaEligibilityFields = ({ meta_eligibility_fields }) => {
  const [metaEligibilityFields, setMetaEligibilityFields] = useState(
    meta_eligibility_fields
  );

  const { state, setState } = useSetState({
    query: "",
  });

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    fetchAdminMetaEligibility();
  }, [state]);

  const fetchAdminMetaEligibility = () => {
    const params = {
      ...state,
    };

    fetchAdminMetaEligibilityFields(params).then((data) =>
      setMetaEligibilityFields(data)
    );
  };

  return (
    <>
      <AdminPanelHeader
        title="Eligibility Fields"
        singularTitle="Field"
        addNewUrl="/admin/meta_eligibility_fields/new"
      />
      <div className="px-3">
        <div className="row mb-3">
          <div className="col-md-6">
            <KeywordSearch
              state={state}
              setState={setState}
              updatePagination={undefined}
            />
          </div>
        </div>

        <div className="panel-table">
          <table className="table admin-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Label</th>
                <th>Value</th>
                <th>Field Type</th>
                <th>Is Active?</th>
                <th>Updated</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {metaEligibilityFields.map((field, index) => {
                return (
                  <tr key={`user-row-${index}`}>
                    <td>{field.id}</td>
                    <td>
                      <div className="user-info">{field.label}</div>
                    </td>
                    <td>{field.value}</td>
                    <td>{field.type}</td>
                    <td>{field.is_active ? "True" : "False"}</td>
                    <td>
                      {field.updated_at
                        ? moment(field.updated_at).format("MM/DD/YYYY")
                        : null}
                    </td>
                    <td>
                      <a
                        href={`/admin/meta_eligibility_fields/${field.id}/edit`}
                        className="btn btn-sm btn-outline-secondary action-buttons"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MetaEligibilityFields;
