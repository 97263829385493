import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

import { prettyDisplayPrice } from "lib/string";
import { getAllUnitTypes } from "modules/project";

const EstimateLineItemRow = ({
  item,
  updateItem,
  saveItem,
  removeItem,
  marginLeftAmount,
  isMarkupActive,
  applyMarkup,
  renderMarkupValue,
  level,
}) => {
  const [itemHistory, setItemHistory] = useState(item);
  const [unitTypes, setUnitTypes] = useState([]);

  const fetchUnitTypes = () => {
    getAllUnitTypes()
      .then((types) => {
        setUnitTypes(types);
      })
      .catch((error) => {
        console.error("Failed to fetch unit types:", error);
      });
  };

  useEffect(() => {
    if (item._editing === true && unitTypes.length === 0) {
      fetchUnitTypes();
    }
  }, [item._editing]);

  if (item._editing) {
    return (
      <>
        <tr
          style={{ borderLeft: "5px solid #00A2E9" }}
          className="table-active"
        >
          <td>
            <div style={{ marginLeft: `${marginLeftAmount}px` }}>
              <input
                type="text"
                className="form-control"
                value={item.description}
                onChange={(e) => {
                  e.preventDefault();
                  updateItem(item, "description", e.target.value);
                }}
              />
            </div>
          </td>
          <td>
            <input
              type="text"
              className="form-control"
              value={item.quantity}
              onChange={(e) => {
                e.preventDefault();
                updateItem(item, "quantity", e.target.value);
              }}
            />
          </td>
          <td>
            <Typeahead
              id="unit-typeahead"
              options={unitTypes}
              selected={item && item.unit ? [item.unit] : []}
              onChange={(selected) => {
                if (typeof selected[0] === "object") {
                  updateItem(item, "unit", selected[0]?.label || "");
                } else {
                  updateItem(item, "unit", selected[0] || "");
                }
              }}
              allowNew
            />
          </td>
          <td>
            <input
              type="text"
              className="form-control"
              value={item.unit_amount}
              onChange={(e) => {
                e.preventDefault();
                updateItem(item, "unit_amount", e.target.value);
              }}
            />
          </td>
          <td>{prettyDisplayPrice(item.total_amount)}</td>
          {isMarkupActive ? (
            <td>
              <input
                type="text"
                className="form-control"
                value={item.markup || 0}
                onChange={(e) => {
                  e.preventDefault();
                  updateItem(item, "markup", e.target.value);
                }}
              />
            </td>
          ) : null}
          {isMarkupActive ? (
            <td>
              {prettyDisplayPrice(
                applyMarkup({
                  markup: parseFloat(item.markup || 0),
                  total: parseFloat(item.total_amount),
                })
              )}
            </td>
          ) : null}
          <td>
            <div className="float-end">
              <button
                className="btn btn-sm btn-light me-2"
                onClick={(e) => {
                  e.preventDefault();
                  removeItem(item);
                }}
              >
                <i className="bi bi-trash2"></i>
              </button>

              <button
                className="btn btn-sm btn-primary me-2"
                onClick={(e) => {
                  e.preventDefault();
                  saveItem(item);
                }}
              >
                <i className="bi bi-check"></i>
              </button>
              <button
                className="btn btn-sm btn-muted"
                onClick={(e) => {
                  e.preventDefault();
                  updateItem(item, "_editing", false);
                }}
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr
          style={{ borderLeft: "5px solid #00A2E9" }}
          className="table-active"
        >
          <td colSpan={42} style={{ paddingLeft: "35px" }}>
            <input
              type="checkbox"
              id="quote-item-checkbox"
              className="company-admin-checkbox me-2"
              checked={!!item?.features?.quote}
              onChange={(e) => {
                updateItem(item, "features", {
                  ...item.features,
                  quote: !item.features?.quote,
                });
              }}
            />
            <label className="form-label mb-1" style={{ paddingRight: "15px" }}>
              Include in Quote
            </label>
            <input
              type="checkbox"
              id="quote-quantity-checkbox"
              className="company-admin-checkbox me-2"
              checked={!!item?.features?.quoteQuantity}
              onChange={(e) => {
                updateItem(item, "features", {
                  ...item.features,
                  quoteQuantity: !item.features?.quoteQuantity,
                });
              }}
            />
            <label className="form-label mb-1" style={{ paddingRight: "15px" }}>
              Show quantity in quote
            </label>
            <input
              type="checkbox"
              id="quote-price-checkbox"
              className="company-admin-checkbox me-2"
              checked={!!item?.features?.quotePrice}
              onChange={(e) => {
                updateItem(item, "features", {
                  ...item.features,
                  quotePrice: !item.features?.quotePrice,
                });
              }}
            />
            <label className="form-label mb-1">Quote Price</label>
          </td>
        </tr>
      </>
    );
  } else {
    return (
      <tr
        style={{ borderLeft: "5px solid #fff" }}
        onDoubleClick={() => updateItem(item, "_editing", true)}
      >
        {/* TODO: This probably not the ideal pattern here.  */}
        <td>
          <div style={{ marginLeft: `${marginLeftAmount}px` }}>
            {item.description}
          </div>
        </td>
        <td>{item.quantity}</td>
        <td>{item.unit}</td>
        <td>{prettyDisplayPrice(item.unit_amount, false, true)}</td>
        <td>{prettyDisplayPrice(item.total_amount, false, true)}</td>
        {isMarkupActive ? <td>{renderMarkupValue(item.markup || 0)}</td> : null}
        {isMarkupActive ? (
          <td>
            {prettyDisplayPrice(
              applyMarkup({
                markup: parseFloat(item.markup || 0),
                total: parseFloat(item.total_amount),
              }),
              false,
              true
            )}
          </td>
        ) : null}
        <td>
          <button
            className="btn btn-sm btn-muted float-end"
            onClick={(e) => {
              e.preventDefault();
              updateItem(item, "_editing", true);
            }}
          >
            <i className="bi bi-pencil-square pencil-button-stable"></i>
          </button>
        </td>
      </tr>
    );
  }
};

export default EstimateLineItemRow;
