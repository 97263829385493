import React, { useState } from 'react';
import AddressInput from "components/common/address-input";

import { updateCompany } from 'modules/user/companies'
import { flashSuccess, flashError } from "lib/flash";
import { createCompanyAddress, updateCompanyAddress } from 'modules/user/companies'

const AddressEditor = (props) => {
  const addressProps = props.address || {};
  const [address, setAddress] = useState({
    id: addressProps.id,
    is_primary: true,
    address_line_1: addressProps.address_line_1,
    address_line_2: addressProps.address_line_2,
    city: addressProps.city,
    state: addressProps.state,
    postal_code: addressProps.postal_code
  }); 
  
  function handleChange(address) {
    setAddress(address)
  }

  function handleSubmit(e) {
    e.preventDefault();
    let actionFn = null;

    if(address.id) {
      actionFn = updateCompanyAddress; 
    } else {
      actionFn = createCompanyAddress;
    }

    actionFn({ address: address })
      .then((response) => {
        flashSuccess('Address saved succesfully!')
        props.handleUpdateSuccess()
      })
      .catch((error) => {
        console.error(error);
      })
  }

  return (
    <div className='row'>
      <div className='col-md-6'>
        <form onSubmit={handleSubmit} >
          <AddressInput
            strong={false}
            address={address}
            onFieldChange={handleChange}
            onFieldUpdate={handleChange}
          />
          <button className='btn btn-outline-primary float-end' type="submit">Save</button>
        </form>
      </div>
    </div>
  )
}

export default AddressEditor;
