import React, { useContext } from "react";

import {
  withUINavTabs,
  UINavPanel,
  UINavSection,
  UINavHorizontalInternal,
} from "components/ui";

import BasicInfo from "./basic-info";
import OrganizationContacts from "./organization-contacts";
import EditOrganizationContextProvider from "./edit-organization-context";
import EditOrganizationContext from "./edit-organization-context";
import OrganizationIncentives from "./organization-incentives";
import Meetings from "../../../../components/admin/companies/edit/meetings";
import Referrals from "../../../common/referrals";

const EditOrganization = ({ organization, entities, meetings }) => {
  return (
    <EditOrganizationContextProvider organization={organization}>
      <div className="ps-4 pe-4 pb-4 admin-company-form">
        <div className="row">
          <div className="col-md-12">
            <h4 className="">{organization.name}</h4>
            <UINavHorizontalInternal
              tabs={[
                {
                  name: "Basic Info",
                  link: "/basic-info",
                },
                {
                  name: "Contacts",
                  link: "/contacts",
                },
                {
                  name: "Notes",
                  link: "/notes",
                },
                {
                  name: "Programs",
                  link: "/organization-incentives",
                },
                {
                  name: "Referrals",
                  link: "/referrals",
                },
              ]}
            />
          </div>
          <div className="col-md-12">
            <UINavPanel>
              <UINavSection tab="/basic-info">
                <BasicInfo entities={entities} />
              </UINavSection>
              <UINavSection tab="/contacts">
                <OrganizationContacts />
              </UINavSection>
              <UINavSection tab="/notes">
                <Meetings
                  company={organization}
                  meetings={meetings}
                  partnerType="Organization"
                />
              </UINavSection>
              <UINavSection tab="/organization-incentives">
                <OrganizationIncentives />
              </UINavSection>
              <UINavSection tab="/referrals">
                <Referrals
                  referrable={organization}
                  baseUrl="/admin/organizations/"
                />
              </UINavSection>
            </UINavPanel>
          </div>
        </div>
      </div>
    </EditOrganizationContextProvider>
  );
};

export default withUINavTabs(EditOrganization);
