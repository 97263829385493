import React from 'react';

const UISelect = (props) => {
  function handleChange(e) {
    e.preventDefault();
    props.handleChange(e.target.value)
  }

  return (
    <select
      id={props.name}
      name={props.name}
      className="form-control"
      value={props.currentOption || ""}
      onChange={handleChange}
    >
      <option disabled value={''}>
        &nbsp;-- select an option --&nbsp;
      </option>

      { props.options.map((option, index) => {
        return (
          <option key={`option-${option.name}-${index}`} value={option.value}>
            { option.name }
          </option>
        )
      })}
    </select>
  )
}

export default UISelect;
