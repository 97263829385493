import React from "react";
import AnimatedNumber from "animated-number-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const UIBigNumberCard = (props) => {
  const formatNumber = (n) => {
    let string = "";
    if (props.prefix) {
      string += props.prefix;
    }

    string += parseInt(n).toLocaleString("en");

    if (props.suffix) {
      string += props.suffix;
    }

    return string;
  };

  const formatContextNumber = () => {
    if (props.contextNumber === undefined || isNaN(props.contextNumber)) {
      return "";
    }

    const precision =
      props.contextNumberPrecision !== undefined
        ? props.contextNumberPrecision
        : 0;

    let string = "";

    if (props.contextPrefix) {
      string += props.contextPrefix;
    }

    string += parseFloat(props.contextNumber).toFixed(precision);

    if (props.contextSuffix) {
      string += props.contextSuffix;
    }

    return string;
  };

  const cardStyle = props.isActive
    ? {
        backgroundColor: "white",
        borderTop: "4px solid #00A2E9",
        borderRight: "1px solid #e6e6e6",
        cursor: props.onClick ? "pointer" : "default",
        ...props.customStyle,
      }
    : {
        borderRight: "1px solid #e6e6e6",
        cursor: props.onClick ? "pointer" : "default",
        ...props.customStyle,
      };

  const renderTooltip = (props) => (
    <Tooltip className="cashflow-tooltip">{props.tooltip}</Tooltip>
  );

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <>
      {props.tooltip ? (
        <OverlayTrigger placement="top" overlay={renderTooltip(props)}>
          <div
            className="big-number-card p-3 mb-2"
            style={cardStyle}
            onClick={handleClick}
          >
            {cardContent(props)}
          </div>
        </OverlayTrigger>
      ) : (
        <div
          className="big-number-card p-3 mb-2"
          style={cardStyle}
          onClick={handleClick}
        >
          {cardContent(props)}
        </div>
      )}
    </>
  );

  function cardContent(props) {
    return (
      <>
        <p className="mb-0" style={{ color: "#6A7894" }}>
          {props.title}
        </p>
        <span className="number">
          <AnimatedNumber
            value={props.number}
            duration={600}
            formatValue={formatNumber}
          />
        </span>
        {props.contextNumber !== undefined && (
          <div className="finance-context mt-2">{formatContextNumber()}</div>
        )}
      </>
    );
  }
};

export default UIBigNumberCard;
