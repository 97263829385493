import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { useArray } from "react-hanger";

import { v4 as uuidv4 } from "uuid";
function newTag() {
  return {
    id: uuidv4(),
    key: '',
    value: ''
  }
}

const TagList = ({ tags }) => {
  return tags.map((tag, index) => {
    return (
      <span 
        key={`tag-preview-${index}-${tag.id}`} 
        className="badge rounded-pill text-bg-light me-1"
      >
        {tag.key}:{tag.value}
      </span>
    )
  })
}

const TagModalEditor = ({ title, isEditing, closeEditing, tags, removeTag, addTag, updateTag }) => {
  const modalTitle = title ? `Editing Tags for: ${title}` : 'Editing Tags'
  return (
    <Modal 
      centered 
      show={isEditing} 
      onHide={() => closeEditing()}
      dialogClassName="modal-xl"
    >
      <div className="modal-header">
        <div className="modal-title h4">
          { modalTitle }
        </div>
        <button type="button" className="btn btn-muted btn-close" aria-label="Close" onClick={(e) => {
          e.preventDefault()
          closeEditing()
        }}>
        </button>
      </div>

      <Modal.Body>
        <table className='table table-borderless '>
          <tbody>
            {
              tags.map((tag, index) => {
                return (
                  <tr key={`tag-edit-${index}`} className='align-middle'>
                    <td style={{'width': '5%'}}>
                      {""}<br/>
                      <button className='btn btn-sm btn-muted' 
                        onClick={(e) => {
                          e.preventDefault()
                          removeTag(tag.id)
                        }}
                      >
                        <i className="bi bi-x-lg"></i>
                      </button>
                    </td>
                    <td>
                      <label>Key</label>
                      <input 
                        type="text" 
                        placeholder="Key" 
                        className='form-control'
                        value={tag.key || ""}
                        onChange={(e) => {
                          e.preventDefault();
                          updateTag(tag, 'key', e.target.value)
                        }}/>
                    </td>
                    <td>
                      <label>Value</label>
                      <input 
                        type="text" 
                        placeholder="Value" 
                        className='form-control'
                        value={tag.value || ""}
                        onChange={(e) => {
                          e.preventDefault();
                          updateTag(tag, 'value', e.target.value)
                        }}/>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3}>
                <button className='btn btn-sm btn-outline-dark' onClick={addTag}>
                  Add Field
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </Modal.Body>
    </Modal>
  )  
}

const TagEditor = (props) => {
  const [isEditing, setIsEditing] =  useState(false)
  const tags = useArray([])

  useEffect(() => {
    if(props.tags && props.tags.length > 0) {
      tags.push(props.tags)
    }
  }, [])

  function setEditTags(e) {
    e.preventDefault();
    setIsEditing(true)
  }

  function closeEditing() {
    setIsEditing(false)
    props.onChange(tags.value)
  }

  function addTag() {
    tags.push(newTag())
  }

  function updateTag(tag, field, value) {
    tags.modifyById(tag.id, { ...tag, [field]: value })
  }

  function removeTag(id) {
    tags.removeById(id)
  }

  if(isEditing) {
    return (
      <div>
        <TagModalEditor
          title={props.title}
          tags={tags.value}
          addTag={addTag}
          removeTag={removeTag}
          updateTag={updateTag}
          isEditing={isEditing}
          closeEditing={closeEditing}
        />
        <TagList tags={tags.value} />
        <button className='btn btn-sm btn-muted' onClick={setEditTags}>
          <i className="bi bi-pencil-square"></i>
        </button>
      </div>
    );
  } else {
    return (
      <div>
        <TagList tags={tags.value} />
        <button className='btn btn-sm btn-muted' onClick={setEditTags}>
          <i className="bi bi-pencil-square"></i>
        </button>
      </div>
    )
  }
}

export default TagEditor;