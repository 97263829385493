import React, { useEffect } from "react";
import { useArray } from "react-hanger";

import { v4 as uuidv4 } from "uuid";
import { UILabelInput } from "../../../ui";

const MultipleChoiceAnswers = (props) => {
  const answerOptions = useArray(props.answerOptions || []);

  useEffect(() => {
    props.setAnswerOptions(answerOptions.value);
  }, [answerOptions]);

  const emptyOption = () => ({
    id: uuidv4(),
    option_text: "",
  });

  const addNewOption = () => {
    answerOptions.push(emptyOption());
  };

  const removeOption = (id) => {
    answerOptions.removeById(id);
  };

  const onOptionChange = (id, value) => {
    answerOptions.modifyById(id, {
      option_text: value,
    });
  };

  return (
    <div>
      <label className="mb-1">
        <strong>Answer Options</strong>
      </label>
      {answerOptions.value.map((answerOption, index) => (
        <div className="row" key={index}>
          <div className="col-9">
            <UILabelInput
              label="Option Text"
              className="form-control"
              value={answerOption.option_text}
              onChange={(value) => onOptionChange(answerOption.id, value)}
            />
          </div>
          <div className="col-3 align-self-end">
            <div className="btn-group ">
              <button
                className="btn btn-light"
                onClick={() => removeOption(answerOption.id)}
              >
                <i className="px-1 bi bi-trash"></i>
              </button>
            </div>
          </div>
        </div>
      ))}
      <div className="mt-2">
        <button className="btn btn-dark" onClick={addNewOption}>
          Add option
        </button>
      </div>
    </div>
  );
};

export default MultipleChoiceAnswers;
