import React, { useState } from "react";
import Select from "react-select";
import { UIMultiSelect } from "../ui";
import { KeywordSearch } from "components/ui";
import StateSelector from "components/common/state-selector";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    fontSize: "1rem",
    backgroundColor: state.isFocused ? "#fff" : "#fafbfc",
    transition:
      "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out",
    borderColor: state.isFocused ? "#00A2E9" : "#e0e0e0",
    boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,162,233,.25)" : null,
    outline: "0",
    marginRight: "0.5rem",
  }),
};

const AdminPanelIndexControls = ({
  keywordSearchConfig,
  selectConfigs,
  multiSelectConfigs,
  buttonGroupConfigs,
  stateSelectorConfig,
}) => {
  const [showFilters, setShowFilters] = useState(false);

  return (
    <div className="px-3">
      <div className="row mb-3 align-items-center">
        <div className="col-md-6">
          {keywordSearchConfig && (
            <KeywordSearch
              state={keywordSearchConfig.state}
              setState={keywordSearchConfig.setState}
              updatePagination={keywordSearchConfig.updatePagination}
            />
          )}
        </div>
        <div
          className={`col-md-6 d-flex align-items-center ${
            buttonGroupConfigs
              ? "justify-content-between"
              : "justify-content-end"
          }`}
        >
          {buttonGroupConfigs && (
            <div className="btn-group">
              {buttonGroupConfigs.map((config, index) => (
                <a key={index} className="btn btn-control" {...config}>
                  {config.label}
                </a>
              ))}
            </div>
          )}
          {(selectConfigs || multiSelectConfigs || stateSelectorConfig) && (
            <button
              className="btn-subdued"
              style={{ border: "1px solid #e0e0e0" }}
              onClick={() => setShowFilters(!showFilters)}
            >
              <i className="bi bi-filter"></i> Filters
            </button>
          )}
        </div>
      </div>

      {showFilters && (
        <div className="row mt-3 mb-3">
          <div className="col-12 d-flex flex-column flex-md-row align-items-start align-items-md-center">
            {selectConfigs &&
              selectConfigs.map((config, index) => (
                <div key={index}>
                  <Select {...config} styles={customStyles} />
                </div>
              ))}
            {multiSelectConfigs &&
              multiSelectConfigs.map((config, index) => (
                <div key={index}>
                  <UIMultiSelect {...config} styles={customStyles} />
                </div>
              ))}
            {stateSelectorConfig && (
              <div>
                <StateSelector {...stateSelectorConfig} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanelIndexControls;
