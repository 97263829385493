import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import moment from 'moment'
import { displayPrice } from 'lib/string'

import { getEstimateLineItemGroups, updateAllocatedLineItem } from 'modules/project'

const MatchLineItemModal = ({ project, show, setShow, closeModal, allocatedLineItem}) => {
  if(!show || !allocatedLineItem) return null

  const [ estimateGroups, setEstimateGroups ] = useState([])
  const [ query, setQuery ] = useState("")

  useEffect(() => {
    fetchLineItemGroups()
  }, [])

  useEffect(() => {
    fetchLineItemGroups()
  }, [query])

  function fetchLineItemGroups() {
    getEstimateLineItemGroups(project.id, { query })
      .then((groups) => {
        setEstimateGroups(groups)
      })
  }

  function matchGroup(group, line_item) {
    console.log(line_item, group)
    const params = {
      allocated_line_item: {
        allocation_id: allocatedLineItem.allocation_id,
        allocation_type: allocatedLineItem.allocation_type,
        estimate_line_item_id: line_item.id,
        project_estimate_category_id: group.id
      }
    }

    updateAllocatedLineItem(allocatedLineItem.id, params)
      .then(( ) => {
        closeModal()
      })
      .catch((error) => {
        console.error(error)
      })
  }
  return (
    <Modal
      centered
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-xl"
    >
      <Modal.Header closeButton>
        <h3><strong>Match to Estimate Line Item</strong></h3>
      </Modal.Header>
      <Modal.Body className="p-3">
        <h5><strong>Line Item</strong></h5>
        <table className='table table-bordered'>
          <tbody>
            <tr>
              <td>{allocatedLineItem.description}</td>
              <td>{/* TODO moment(allocatedLineItem.acquisition_date).format("MM/DD/YYYY")*/}</td>
              <td>{displayPrice(parseFloat(allocatedLineItem.unit_cost))}</td>
              <td>{allocatedLineItem.quantity}</td>
              <td colSpan={2}>{displayPrice(allocatedLineItem.total_amount)}</td>
            </tr>
          </tbody>
        </table>
        <h5><strong>Estimates</strong></h5>
        <form className='form'>
          <input type="text" className='form-control' value={query} placeholder="Filter" onChange={(e) => {
            e.preventDefault()
            setQuery(e.target.value)
          }} />
        </form>

        <table className='table table-bordered table-hover mt-3'>
          <tbody>
            {
              estimateGroups.map((group, index) => {
                let rows = group.estimate_line_items.map((line_item, jindex) => {
                  return (
                    <tr className='icon-link-hover' key={`line-item-${jindex}`} onClick={(e) => {
                      e.preventDefault()
                      matchGroup(group, line_item)
                    }}>
                      <td><div className='ps-4'>
                        {line_item.description}
                      </div></td>
                      <td>{line_item.unit}</td>
                      <td>{displayPrice(line_item.unit_amount)}</td>
                      <td>{line_item.quantity}</td>
                      <td>{displayPrice(line_item.total_amount)}</td>
                      <td>
                        <button className='btn btn-sm btn-action' onClick={(e) => {
                          e.preventDefault()
                          matchGroup(group, line_item)
                        }}>
                          Match
                        </button>
                      </td>
                    </tr>
                  )
                })

                rows.unshift((
                  <tr key={`category-${index}`} className='table-active'>
                    <td colSpan={6}>
                      {group.name}
                    </td>
                  </tr>
                ))

                return rows;
              })
            }
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  )
}

export default MatchLineItemModal;
