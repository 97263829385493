import React, { useState, useEffect } from "react";
import AddressInput from "components/common/address-input";
import { createAddress } from "modules/onboarding";

const Address = (props) => {
  const addressProps = props.address || {};

  const [address, setAddress] = useState({
    is_primary: true,
    address_line_1: addressProps.address_line_1,
    address_line_2: addressProps.address_line_2,
    city: addressProps.city,
    state: addressProps.state,
    postal_code: addressProps.postal_code,
    location_flags: addressProps.location_flags || {},
  });

  function handleChange(address) {
    setAddress(address);
  }

  function handleSubmit(e) {
    e.preventDefault();
    createAddress({
      address,
      step_key: props.step,
      section_key: props.section,
    }).then((response) => {
      window.location = props.nextRoute;
    });
  }

  return (
    <div className="onboarding-form-group">
      <form onSubmit={handleSubmit}>
        <AddressInput
          strong={false}
          address={address}
          onFieldChange={handleChange}
          onFieldUpdate={handleChange}
        />

        <div className="form-group mt-2 d-flex justify-content-between">
          {props.previousRoute && (
            <a
              href={props.previousRoute}
              className="btn btn-light btn-large my-3 mb-4 px-4 me-2"
            >
              <i
                className="bi bi-chevron-left icon-back-chevron"
                style={{ marginRight: "4px" }}
              ></i>
              <i
                className="bi bi-arrow-left icon-back-arrow"
                style={{ marginRight: "4px" }}
              ></i>
              Back
            </a>
          )}
          <button
            className="btn btn-large btn-action my-3 mb-4 px-4 next-button"
            type="submit"
            onSubmit={handleSubmit}
          >
            Next
            <i
              className="bi bi-chevron-right icon-next-chevron"
              style={{ marginLeft: "4px" }}
            ></i>
            <i
              className="bi bi-arrow-right icon-next-arrow"
              style={{ marginLeft: "4px" }}
            ></i>
          </button>

          {props.skipRoute ? (
            <a
              href={props.nextRoute}
              className="btn btn-light btn-large my-3 mb-4 px-4 me-2"
            >
              Skip
            </a>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default Address;
