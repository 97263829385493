import React, { useEffect, useState } from "react";
import UIFormLabel from "../ui-form-label";
import Select from "react-select";
import { getCompanyProjects } from "modules/project";
import {
  getApplicationProjectResources,
  createApplicationProjectResources,
  deleteApplicationProjectResources,
} from "modules/applications";
import { flashError, flashSuccess } from "../../../lib/flash";

const UIProjectSelectInput = ({
  label,
  required,
  description,
  tooltip,
  initialSelectedProjects = [],
  application,
}) => {
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState(
    initialSelectedProjects
  );

  useEffect(() => {
    getCompanyProjects()
      .then((data) => {
        const formattedOptions = data.projects.map((project) => ({
          value: project.id,
          label: project.name,
        }));
        setProjectOptions(formattedOptions);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  }, []);

  useEffect(() => {
    if (application) {
      getApplicationProjectResources(application)
        .then((projectResources) => {
          console.log(
            "Fetched project resources for application ID:",
            application,
            projectResources
          );

          const selectedProjectOptions = projectResources.map(
            (projectResource) => ({
              value: projectResource.id,
              label: projectResource.name,
            })
          );

          setSelectedProjects(selectedProjectOptions);
        })
        .catch((error) => {
          console.error(
            "Error fetching project resources for application ID:",
            application,
            error
          );
        });
    }
  }, [application]);

  const handleChange = (selectedOptions) => {
    const newProjectIds = selectedOptions.map((option) => option.value);
    const oldProjectIds = selectedProjects.map((option) => option.value);

    // Determine which projects to add and which to remove
    const projectsToAdd = newProjectIds.filter(
      (id) => !oldProjectIds.includes(id)
    );
    const projectsToRemove = oldProjectIds.filter(
      (id) => !newProjectIds.includes(id)
    );

    // Add new projects
    if (projectsToAdd.length > 0) {
      createApplicationProjectResources(application, projectsToAdd)
        .then((response) => {
          console.log("Added projects:", response);
          const addedProjectNames = selectedOptions
            .filter((option) => projectsToAdd.includes(option.value))
            .map((option) => option.label)
            .join(", ");
          flashSuccess(`Added project(s): ${addedProjectNames}`);
        })
        .catch((error) => {
          console.error("Error adding projects:", error);
          flashError(`Error adding project(s): ${error.message || error}`);
        });
    }

    // Remove projects
    if (projectsToRemove.length > 0) {
      deleteApplicationProjectResources(application, projectsToRemove)
        .then((response) => {
          console.log("Removed projects:", response);
          const removedProjectNames = selectedProjects
            .filter((option) => projectsToRemove.includes(option.value))
            .map((option) => option.label)
            .join(", ");
          flashSuccess(`Removed project(s): ${removedProjectNames}`);
        })
        .catch((error) => {
          console.error("Error removing projects:", error);
          flashError(`Error removing project(s): ${error.message || error}`);
        });
    }

    setSelectedProjects(selectedOptions);
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      border: "1px solid #e0e0e0",
    }),
  };

  return (
    <div className="row">
      <div className="col">
        <UIFormLabel label={label} required={required} tooltip={tooltip} />
        {!!description && <p>{description}</p>}
        {/* TODO: Error handling can be added here if needed */}
      </div>
      <div className="col">
        <Select
          styles={customStyles}
          placeholder="Select a project..."
          options={projectOptions}
          isMulti
          value={selectedProjects}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default UIProjectSelectInput;
