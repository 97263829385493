import React, { useState, useEffect } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { UILabelInput, UIDateInput, UIFormLabel } from "components/ui";
import { flashSuccess, flashError } from "lib/flash";

import {
  fetchCompanyEstimateLineItems,
  createCompanyEstimateLineItem,
  updateCompanyEstimateLineItem,
  deleteCompanyEstimateLineItem,
} from "modules/companies";

const ProjectLineItemEditor = ({ company }) => {
  const [lineItems, setLineItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState({});
  const [editableValues, setEditableValues] = useState({});
  const [formData, setFormData] = useState({
    description: "",
    vendor: "",
    unit: "",
    unit_amount: "",
  });

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateLineItem = () => {
    createCompanyEstimateLineItem(company.id, formData)
      .then((createdItem) => {
        setLineItems((prevLineItems) => [...prevLineItems, createdItem]);
        flashSuccess("Line item created successfully.");
        setShowModal(false);
        // Reset form data after successful creation
        setFormData({
          description: "",
          vendor: "",
          unit: "",
          unit_amount: "",
        });
      })
      .catch((error) => {
        console.error("Error creating line item:", error);
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while creating the line item.";
        flashError(errorMessage);
      });
  };

  const deleteEstimateLineItem = (companyId, lineItemId) => {
    if (window.confirm("Are you sure you want to delete this line item?")) {
      deleteCompanyEstimateLineItem(companyId, lineItemId)
        .then(() => {
          setLineItems((currentLineItems) =>
            currentLineItems.filter((item) => item.id !== lineItemId)
          );
          flashSuccess("Line item deleted successfully.");
        })
        .catch((error) => {
          console.error("Complete Error Object:", error);
          console.error("Error Response:", error.response);
          const errorMessage =
            error.response?.data?.error ||
            "An error occurred while deleting the line item. This is probably because this item is part of an existing estimate.";
          flashError(errorMessage);
        });
    }
  };

  const handleUpdateLineItem = (lineItemId) => {
    const updatedItem = {
      company_estimate_line_item: editableValues[lineItemId],
    };

    updateCompanyEstimateLineItem(company.id, lineItemId, updatedItem)
      .then(() => fetchCompanyEstimateLineItems(company.id))
      .then((response) => {
        setLineItems(response.line_items);
        setEditMode((prev) => ({ ...prev, [lineItemId]: false }));
        flashSuccess("Line item updated successfully.");
      })
      .catch((error) => {
        flashError("Error updating line item.");
      });
  };

  const toggleEditMode = (lineItemId) => {
    const currentItem = lineItems.find((item) => item.id === lineItemId);
    if (!editMode[lineItemId] && currentItem) {
      setEditableValues({
        ...editableValues,
        [lineItemId]: {
          description: currentItem.description,
          vendor: currentItem.vendor,
          unit: currentItem.unit,
          unit_amount: currentItem.unit_amount,
        },
      });
    }

    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [lineItemId]: !prevEditMode[lineItemId],
    }));
  };

  useEffect(() => {
    if (company) {
      fetchCompanyEstimateLineItems(company.id)
        .then((response) => {
          setLineItems(response.line_items);
        })
        .catch((error) => {
          console.error("Error fetching line items:", error);
        });
    }
  }, [company]);

  return (
    <>
      <h3 className="panel-title mb-2 mt-4">Manage project line items</h3>
      <div
        className="template-header"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p className="panel-context">
          Line items can be materials or labor items.
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip className="cashflow-tooltip">
                Line-items are re-usable items to be used in project templates
                above.
              </Tooltip>
            }
          >
            <i className="bi bi-info-circle" style={{ marginLeft: "4px" }} />
          </OverlayTrigger>
        </p>
        <button
          className="btn-attach"
          style={{ width: "auto", padding: "6px 12px" }}
          onClick={() => setShowModal(true)}
        >
          Add New Line-Item
        </button>
      </div>
      <div className="mt-2">
        <table className="settings-table">
          <thead>
            <tr>
              <th>Description</th>
              <th>Vendor</th>
              <th>Unit</th>
              <th>Unit Amount</th>
              <th>Last Updated</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {lineItems.length > 0 ? (
              lineItems.map((item) => (
                <tr key={item.id}>
                  <td>
                    {editMode[item.id] ? (
                      <input
                        type="text"
                        className="inline-edit"
                        value={editableValues[item.id]?.description || ""}
                        onChange={(e) =>
                          setEditableValues({
                            ...editableValues,
                            [item.id]: {
                              ...editableValues[item.id],
                              description: e.target.value,
                            },
                          })
                        }
                      />
                    ) : (
                      item.description
                    )}
                  </td>
                  <td>
                    {" "}
                    {editMode[item.id] ? (
                      <input
                        type="text"
                        className="inline-edit"
                        value={editableValues[item.id]?.vendor || ""}
                        onChange={(e) =>
                          setEditableValues({
                            ...editableValues,
                            [item.id]: {
                              ...editableValues[item.id],
                              vendor: e.target.value,
                            },
                          })
                        }
                      />
                    ) : (
                      item.vendor
                    )}
                  </td>
                  <td>
                    {" "}
                    {editMode[item.id] ? (
                      <input
                        type="text"
                        className="inline-edit"
                        value={editableValues[item.id]?.unit || ""}
                        onChange={(e) =>
                          setEditableValues({
                            ...editableValues,
                            [item.id]: {
                              ...editableValues[item.id],
                              unit: e.target.value,
                            },
                          })
                        }
                      />
                    ) : (
                      item.unit
                    )}
                  </td>
                  <td>
                    {editMode[item.id] ? (
                      <input
                        type="text"
                        className="inline-edit"
                        value={editableValues[item.id]?.unit_amount || ""}
                        onChange={(e) =>
                          setEditableValues({
                            ...editableValues,
                            [item.id]: {
                              ...editableValues[item.id],
                              unit_amount: e.target.value,
                            },
                          })
                        }
                      />
                    ) : (
                      <>
                        {parseFloat(item.unit_amount) === 0.0 ? (
                          <span className="unit-amount-zero-indicator">
                            {item.unit_amount}
                          </span>
                        ) : (
                          item.unit_amount
                        )}
                      </>
                    )}
                  </td>

                  <td>{new Date(item.last_updated).toLocaleDateString()}</td>
                  <td>
                    <button
                      className="btn btn-sm"
                      onClick={() => {
                        if (editMode[item.id]) {
                          handleUpdateLineItem(item.id);
                        } else {
                          toggleEditMode(item.id);
                        }
                      }}
                    >
                      {editMode[item.id] ? "Save" : "Edit"}
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-action btn-sm"
                      onClick={() =>
                        deleteEstimateLineItem(company.id, item.id)
                      }
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No line items found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Line Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleCreateLineItem}>
            <UILabelInput
              id="description"
              label="Description"
              value={formData.description}
              onChange={(value) => handleInputChange("description", value)}
              required
            />
            <UILabelInput
              id="vendor"
              label="Vendor"
              value={formData.vendor}
              onChange={(value) => handleInputChange("vendor", value)}
              required={false}
            />
            <UILabelInput
              id="unit"
              label="Unit"
              value={formData.unit}
              onChange={(value) => handleInputChange("unit", value)}
              required={false}
            />
            <UILabelInput
              id="unit_amount"
              label="Unit Amount"
              value={formData.unit_amount}
              onChange={(value) => handleInputChange("unit_amount", value)}
              required={false}
            />
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ProjectLineItemEditor;
