import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { InlineWidget } from "react-calendly";
import { UIJumbotron } from "../../ui/ui-jumbotron";
import CuratedShelves from "../../partner-landing-pages/curated-shelves";
import {
  withUIPaginate,
  UIPaginationTotalSelector,
  UIPagination,
} from "components/ui";
import axios from "lib/axios";

import VideoLibrary from "./video-library";
import Tasks from "./tasks";
import Incentives from "./incentives";
import Applications from "./applications";

import phoneCall from "images/phone-call-icon.png";

const Home = ({ current_user, incentives, tasks, applications }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <div className="home-dashboard">
      <div className="info-banner">
        <div className="content">
          <span className="card-text">
            Book a call to see how our experts can help you maximize your
            incentives!
          </span>
          <button className="btn btn-primary btn-sm py-1" onClick={handleShow}>
            Schedule now
          </button>
        </div>
      </div>

      <div className="welcome-section">
        <h3 className="admin-gradient-text">Hi, {current_user.first_name}.</h3>
      </div>

      <h4>Recommended incentives</h4>
      <CuratedShelves
        ctaLink="/incentives"
        ctaText="Click to browse our entire database of incentives and see if you qualify"
        featuredIncentives={incentives}
      />

      <Modal size="lg" show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InlineWidget url="https://calendly.com/alexanderswhite" />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withUIPaginate(Home);
