import React, { useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { prettyDisplayPrice } from "../../../lib/string";
import { DATETIME_LONG_FORMAT } from "lib/utils";

const LICENSE = "AM5C363689376";
const RED = "#E22947";
const GREEN = "#7CB61D";
const BLUE = "#1D3360";

// TODO: Eventually this needs to be general as it currently just serves one particular graph, not all line graphs.

const LineGraph = ({
  data,
  annotation_data,
  receivables_annotation_data,
  threshold,
  width,
  height,
}) => {
  const [dateISO, setDateISO] = useState(null); // date in ISO format of the mouse-overed x-axis

  useLayoutEffect(() => {
    am5.addLicense(LICENSE);

    let root = am5.Root.new("chartdiv");
    root.numberFormatter.set("numberFormat", "$#,##a");

    root.setThemes([am5themes_Responsive.new(root)]);

    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      })
    );

    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );

    cursor.lineY.set("visible", false);

    cursor.events.on("cursormoved", function (ev) {
      let x = ev.target.getPrivate("positionX");
      let y = ev.target.getPrivate("positionY");

      // To get index of the closest data point to the cursor: dateIndex = Math.ceil(xAxis.toAxisPosition(x) * data.length);

      let xDate = new Date(
        Number(xAxis.positionToValue(xAxis.toAxisPosition(x)))
      )
        .toISOString()
        .split("T")[0];
      setDateISO(xDate);
      // let valueY = yAxis.positionToValue(xAxis.toAxisPosition(y));
    });

    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        start: 0,
        end: 0.333,

        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: "day",
          count: 1,
        },

        renderer: am5xy.AxisRendererX.new(root, {}),

        startLocation: 0,
        endLocation: 1,

        fill: am5.color(BLUE),
        stroke: am5.color(RED),
      })
    );

    // let tooltip = am5.Tooltip.new(root, {
    //   getFillFromSprite: false,
    //   tooltipText: "[#fff][fontFamily: Raleway]{valueY.value}[/]",
    // });

    // tooltip.get("background").setAll({
    //   fill: am5.color(BLUE),
    // });

    // xAxis.set("tooltip", tooltip);

    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/#label-format
    const xRenderer = xAxis.get("renderer");
    xRenderer.labels.template.setAll({
      fontFamily: "Raleway",
      fill: am5.color(BLUE),
      fontWeight: "400",
    });

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    const yRenderer = yAxis.get("renderer");
    yRenderer.labels.template.setAll({
      fontFamily: "Raleway",
      fill: am5.color(BLUE),
      fontWeight: "400",
    });

    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series = chart.series.push(
      am5xy.SmoothedXYLineSeries.new(root, {
        // name: "Projected Cash Balance",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",

        fill: am5.color(BLUE),
        stroke: am5.color(RED),

        tension: 0.9,
        interpolationDuration: 2000,
        interpolationEasing: am5.ease.inOut(am5.ease.elastic),
        // tooltip: am5.Tooltip.new(root, {
        //   labelText: "[fontFamily: Raleway]{valueY}",
        // }),
      })
    );

    series.strokes.template.setAll({
      strokeWidth: 3,
    });

    series.set();

    // // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    var scrollbarX = am5.Scrollbar.new(root, {
      orientation: "horizontal",
      start: 0,
      end: 0.333 * 0.25, // 90 days
    });

    chart.set("scrollbarX", scrollbarX);
    chart.bottomAxesContainer.children.push(scrollbarX);

    chart.zoomOutButton.set("forceHidden", true);

    // Find max and min cash balance
    let maxCashBalanceData = data.reduce(
      (max, cur) => (cur.value > max.value ? cur : max),
      data[0]
    );
    let minCashBalanceData = data.reduce(
      (min, cur) => (cur.value < min.value ? cur : min),
      data[0]
    );

    series.data.setAll(data);

    // add series range
    var seriesRangeDataItem = yAxis.makeDataItem({
      value: threshold,
      endValue: Number.MIN_SAFE_INTEGER,
    });

    var seriesRange = series.createAxisRange(seriesRangeDataItem);
    seriesRange.fills.template.setAll({
      visible: true,
      opacity: 0.6,
    });

    seriesRange.fills.template.set("fill", am5.color(RED));
    seriesRange.strokes.template.set("stroke", am5.color(RED));

    // add series range
    var seriesRangeDataItemTreshold = yAxis.makeDataItem({
      value: threshold,
      endValue: Number.MAX_SAFE_INTEGER,
    });

    var seriesRangeThreshold = series.createAxisRange(
      seriesRangeDataItemTreshold
    );
    seriesRangeThreshold.fills.template.setAll({
      visible: true,
      opacity: 0.6,
    });

    seriesRangeThreshold.fills.template.set("fill", am5.color(RED));
    seriesRangeThreshold.strokes.template.set("stroke", am5.color(RED));

    seriesRangeDataItem.get("grid").setAll({
      strokeOpacity: 1,
      visible: true,
      stroke: am5.color(BLUE),
      strokeDasharray: [3, 3],
    });

    seriesRangeDataItem.get("label").setAll({
      location: 0,
      visible: true,
      // text: "Threshold",
      inside: true,
      centerX: 0,
      centerY: am5.p100,
    });

    // https://www.amcharts.com/docs/v5/concepts/animations/

    return () => {
      root.dispose();
    };
  }, [data]);

  function getAnnotations() {
    if (!dateISO) {
      return null;
    }

    let dateData = data.filter((d) => d.date_iso === dateISO)[0];
    if (dateData === null || dateData === undefined) {
      return null;
    }

    let vendors = [];
    let annotations_filtered_by_date = annotation_data.filter(
      (d) => d.date_iso === dateISO && d.payable_rank !== 0
    );

    let customers = [];
    let receivables_annotations_filtered_by_date = annotation_data.filter(
      (d) => d.date_iso === dateISO && d.receivable_rank !== 0
    );

    if (annotations_filtered_by_date.length > 0 && dateData["money_out"] > 0) {
      annotations_filtered_by_date.map((x, i) => {
        vendors.push(x["vendor_name"]);
      });
    }

    if (
      receivables_annotations_filtered_by_date.length > 0 &&
      dateData["money_in"] > 0
    ) {
      receivables_annotations_filtered_by_date.map((x, i) => {
        customers.push(x["customer_name"]);
      });
    }

    return (
      <div className="admin-metadata">
        <>
          {/* TODO: Use a lib method for the date format */}
          {DATETIME_LONG_FORMAT.format(new Date(dateISO))}:{" "}
          <b>{prettyDisplayPrice(dateData["value"])}</b>
          <br />
          Money in: <b>{prettyDisplayPrice(dateData["money_in"])}</b>
          {customers.length > 1 &&
            ` from ${[...new Set(customers)].join(", ")}`}
          {customers.length === 1 && ` from ${[...new Set(vendors)]}`}
          <br />
          Money out:
          <b> {prettyDisplayPrice(dateData["money_out"])}</b>
          {vendors.length > 1 && ` for ${[...new Set(vendors)].join(", ")}`}
          {vendors.length === 1 && ` for ${[...new Set(vendors)]}`}
        </>
      </div>
    );
  }

  return (
    <>
      <div id="chartdiv" style={{ width, height }}></div>
      {getAnnotations()}
    </>
  );
};

export default LineGraph;
