import axios from "lib/axios";

export const sendVendorLetter = (application_id) => {
  const url = `/applications/send_vendor_letter/${application_id}`;

  return axios
    .put(url, null, { headers: { "Content-Type": "multipart/form-data" } })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const submitIncentiveApplicationErrors = (params = {}, slug) => {
  const url = `/applications/${slug}/${params.id}/error`;

  return axios
  .put(url, params)
  .then((response) => {
    return Promise.resolve(response.data);
  })
  .catch((error) => {
    return Promise.reject(error.response.data);
  });
};

export const submitIncentiveApplication = (params = {}, slug) => {
  const url = `/applications/${slug}/${params.id}/submit`;

  return axios
  .put(url, params)
  .then((response) => {
    return Promise.resolve(response.data);
  })
  .catch((error) => {
    return Promise.reject(error.response.data);
  });
};

export const updateIncentiveApplication = (params = {}, slug) => {
  const url = `/applications/${slug}/${params.id}/update`;

  return axios
  .put(url, params)
  .then((response) => {
    return Promise.resolve(response.data);
  })
  .catch((error) => {
    return Promise.reject(error);
  });
};

export const getIncentiveApplication = (slug, id) => {
  const url = `/applications/${slug}/${id}/details`;

  return axios
  .get(url)
  .then((response) => {
    return Promise.resolve(response.data);
  })
  .catch((error) => {
    return Promise.reject(error.response.data);
  });
};

export const getIncentiveApplicationDetails = (slug, id) => {
  const url = `/applications/${slug}/${id}/details`;

  return axios
  .get(url)
  .then((response) => {
    return Promise.resolve(response.data);
  })
  .catch((error) => {
    return Promise.reject(error.response.data);
  });
};

export const prequalifyIncentiveApplication = (slug) => {
  const url = `/applications/${slug}/prequalify`;

  return axios
    .post(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getApplicationProjectResources = (applicationId) => {
  const url = `/applications/${applicationId}/project_resources`;
  return axios.get(url)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};


export const createApplicationProjectResources = (applicationId, projectIds) => {
  const url = `/applications/${applicationId}/project_resources`;
  return axios.post(url, { project_ids: projectIds })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};

export const deleteApplicationProjectResources = (applicationId, projectIds) => {
  const url = `/applications/${applicationId}/project_resources`;
  return axios.delete(url, { data: { project_ids: projectIds } })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};

export const getApplicationWorkerResources = (applicationId) => {
  const url = `/applications/${applicationId}/worker_resources`;
  return axios.get(url)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};

export const createApplicationWorkerResources = (applicationId, workerIds) => {
  const url = `/applications/${applicationId}/worker_resources`;
  return axios.post(url, { worker_ids: workerIds })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};

export const deleteApplicationWorkerResources = (applicationId, workerIds) => {
  const url = `/applications/${applicationId}/worker_resources`;
  return axios.delete(url, { data: { worker_ids: workerIds } })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};



