import React, { useState } from 'react';
import { flashSuccess, flashError } from "lib/flash";
import { titleCase } from 'lib/string'
import { updateCompany } from 'modules/user/companies'

const CompanyFieldEditor = (props) => {
  const { company, eligibility } = props;
  const [ value, setValue ] = useState(company[eligibility.column]);

  const title = titleCase(eligibility.column)

  function handleSubmit(e) {
    e.preventDefault();

    updateCompany({ [eligibility.column]: value })
      .then((response) => {
        flashSuccess(`${title} saved succesfully!`)
        props.handleUpdateSuccess()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <div className='row'>
      <div className='col-md-4'>
        <div className="input-group mb-3">
          <input 
            type="text" 
            className='form-control' 
            value={value} 
            onChange={(e) => {
              e.preventDefault()
              setValue(e.target.value);
            }} 
          />
          <button 
            className="btn btn-outline-primary" 
            type="button"
            onClick={handleSubmit}
          >
            Save 
          </button>
        </div>
      </div>
    </div>
  )
}

export default CompanyFieldEditor;