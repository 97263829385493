import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";

import { flashError, flashSuccess } from "lib/flash";

import {
  UIDateInput,
  UIFormLabel,
  UILabelInput,
  UIMoneyLabelInput,
  UIRadioCard,
  UITextareaLabelInput,
} from "components/ui";
import {
  createPayableInvoice,
  createPayableInvoices,
  createReceivableInvoice,
  createReceivableInvoices,
  deletePayableInvoice,
  deleteReceivableInvoice,
  updatePayableInvoice,
  updateReceivableInvoice,
} from "modules/company_invoices";
import { fetchChartOfAccounts } from "modules/company_accounts_chart";

const MAX_PROJECTIONS = 20;

// TODO: generic chart of accounts component
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: "#E5E5E5",
  }),
};

const ChartOfAccountsInput = (props) => {
  let [accounts, setAccounts] = useState([]);

  useEffect(() => {
    // TODO: filter by expense/income
    fetchChartOfAccounts(props.params).then((response) => {
      setAccounts(
        response.accounts.map((account, index) => {
          return {
            value: account.id,
            label: account.name,
          };
        })
      );
    });
  }, [props.params]);

  return (
    <div className="ui-label-input">
      {!!props.label && (
        <UIFormLabel label={props.label} required={props.required} />
      )}
      <Select
        styles={customStyles}
        value={accounts.find((account) => {
          return account.value === props.value;
        })}
        options={accounts}
        onChange={(selectedOption) => {
          props.onChange(selectedOption.value);
        }}
      />
    </div>
  );
};

const EditInvoice = (props) => {
  let [isPayable, setIsPayable] = useState(
    props.invoices.value.length > 0
      ? props.invoices.value[0].cash_balance_status == "money out"
      : false
  );

  useEffect(() => {}, [props.invoices]);

  useEffect(() => {
    setIsPayable(
      props.invoices.value.length > 0
        ? props.invoices.value[0].cash_balance_status == "money out"
        : false
    );
  }, [props.invoices]);

  useEffect(() => {
    if (!props.show) {
      props.invoices.clear();
    } else if (!!props.show && props.invoices.value.length == 0) {
      props.invoices.push(getNewProjectedInvoice());
    }
  }, [props.show]);

  function getNewProjectedInvoice() {
    return {
      id: `NEW-${props.invoices.value.length}`,
      due_date: moment(),
      customer_details: {},
      cash_balance_status:
        props.invoices.value.length > 0
          ? props.invoices.value[0].cash_balance_status
          : "money in",
    };
  }

  function updateCustomerName(invoice, value) {
    props.invoices.modifyById(invoice.id, {
      ...invoice,
      customer_name: value,
      customer_details: {
        ...invoice.customer_details,
        name: value,
      },
    });
  }

  function updateExpenseAccount(invoice, value) {
    props.invoices.modifyById(invoice.id, {
      ...invoice,
      expense_account_id: value,
    });
  }

  function updateTotalAmount(invoice, value) {
    props.invoices.modifyById(invoice.id, { ...invoice, total_amount: value });
  }

  function updateDueDate(invoice, value) {
    props.invoices.modifyById(invoice.id, { ...invoice, due_date: value });
  }

  function updateNotes(invoice, value) {
    props.invoices.modifyById(invoice.id, {
      ...invoice,
      risk_notes: value,
      at_risk: {
        ...invoice.at_risk,
        notes: value,
        probability: value ? 1 : 0,
      },
    });
  }

  function updateVendorName(invoice, value) {
    props.invoices.modifyById(invoice.id, { ...invoice, vendor_name: value });
  }

  function updateInvoiceAtRisk(invoice, value) {
    const updatedAtRisk = {
      ...invoice.at_risk,
      probability: value ? 1 : 0,
      notes: invoice.risk_notes || "",
    };

    props.invoices.modifyById(invoice.id, {
      ...invoice,
      at_risk: updatedAtRisk,
    });
  }

  function updateProjectNumber(invoice, value) {
    props.invoices.modifyById(invoice.id, {
      ...invoice,
      customer_details: {
        ...invoice.customer_details,
        project_number: value,
      },
    });
  }

  function getModalTitle() {
    return (
      getModalAction() +
      " " +
      getModalStatus() +
      " " +
      (isPayable ? "Payable" : "Receivable") +
      (props.invoices.value.length > 1 ? "s" : "")
    );
  }

  function getModalAction() {
    if (String(props.invoices.value[0].id).startsWith("NEW")) {
      return "Add";
    } else {
      return "Update";
    }
  }

  function getModalStatus() {
    if (!props.invoices.value[0].status) {
      return "Projected";
    } else {
      return props.invoices.value[0].status;
    }
  }

  function getAccountInput(invoice) {
    if (isPayable) {
      return (
        <ChartOfAccountsInput
          label="Expense Account"
          params={{ status: "Active", account_type: "Expense" }}
          value={invoice.expense_account_id}
          onChange={updateExpenseAccount.bind(null, invoice)}
        />
      );
    } else {
      return (
        <ChartOfAccountsInput
          label="Income Account"
          params={{ status: "Active", account_type: "Income" }}
          value={invoice.expense_account_id}
          onChange={updateExpenseAccount.bind(null, invoice)}
        />
      );
    }
  }

  function getCounterpartyInput(invoice) {
    if (isPayable) {
      return (
        <div style={{ width: "48%", marginRight: "2%" }}>
          <div className="row mb-4">
            <UILabelInput
              label="Vendor Name"
              value={invoice.vendor_name}
              onChange={updateVendorName.bind(null, invoice)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ width: "23%", marginRight: "2%" }}>
          <div className="row mb-4">
            <UILabelInput
              label="Customer Name"
              value={invoice.customer_name}
              onChange={updateCustomerName.bind(null, invoice)}
            />
          </div>
        </div>
      );
    }
  }

  function deleteInvoice() {
    if (isPayable) {
      deletePayableInvoice(props.invoices.value[0].id)
        .then(() => {
          props.onSuccess();
          props.setShow(false);
          flashSuccess("Successfully deleted invoice!");
        })
        .catch((error) => {
          console.error(error);
          flashError("Failed to delete invoice!");
        });
    } else {
      deleteReceivableInvoice(props.invoices.value[0].id)
        .then(() => {
          props.onSuccess();
          props.setShow(false);
          flashSuccess("Successfully deleted invoice!");
        })
        .catch((error) => {
          console.error(error);
          flashError("Failed to delete invoice!");
        });
    }
  }

  function submitInvoice() {
    let params = {
      total_amount: props.invoices.value[0].total_amount,
      amount_due: props.invoices.value[0].total_amount,
      company_preferred_payment_date: props.invoices.value[0].due_date,
      status: props.invoices.value[0].status || "Projected",
    };

    if (isPayable) {
      params.expense_account_id = props.invoices.value[0].expense_account_id;
      params.vendor_name = props.invoices.value[0].vendor_name;
      params.at_risk = {
        probability: props.invoices.value[0].at_risk
          ? props.invoices.value[0].at_risk.probability
          : 0,
        notes: props.invoices.value[0].at_risk
          ? props.invoices.value[0].at_risk.notes
          : "",
      };

      if (getModalAction() == "Add") {
        createPayableInvoice(params)
          .then(() => {
            props.onSuccess();
            props.setShow(false);
            flashSuccess("Successfully added invoice!");
          })
          .catch((error) => {
            console.error(error);
            flashError("Failed to add invoice!");
          });
      } else {
        updatePayableInvoice(props.invoices.value[0].id, params)
          .then(() => {
            props.onSuccess();
            props.setShow(false);
            flashSuccess("Successfully updated invoice!");
          })
          .catch((error) => {
            console.error(error);
            flashError("Failed to update invoice!");
          });
      }
    } else {
      params.company_accounts_chart_id =
        props.invoices.value[0].expense_account_id;
      params.customer_name = props.invoices.value[0].customer_name;
      params.customer_details = props.invoices.value[0].customer_details;
      params.at_risk = {
        probability: props.invoices.value[0].at_risk
          ? props.invoices.value[0].at_risk.probability
          : 0,
        notes: props.invoices.value[0].at_risk
          ? props.invoices.value[0].at_risk.notes
          : "",
      };

      if (getModalAction() == "Add") {
        console.log("Receivable params:", params);

        createReceivableInvoice(params)
          .then(() => {
            props.onSuccess();
            props.setShow(false);
            flashSuccess("Successfully added invoice!");
          })
          .catch((error) => {
            console.error(error);
            flashError("Failed to add invoice!");
          });
      } else {
        console.log("Receivable params:", params);

        updateReceivableInvoice(props.invoices.value[0].id, params)
          .then(() => {
            props.onSuccess();
            props.setShow(false);
            flashSuccess("Successfully updated invoice!");
          })
          .catch((error) => {
            console.error(error);
            flashError("Failed to update invoice!");
          });
      }
    }
  }

  function submitInvoices() {
    let params = {
      invoices: props.invoices.value.map((invoice) => {
        return {
          ...invoice,
          amount_due: invoice.total_amount,
          status: invoice.status || "Projected",
          id: undefined,
          at_risk: {
            probability: invoice.at_risk ? invoice.at_risk.probability : 0,
            notes: invoice.at_risk ? invoice.at_risk.notes : "",
          },
          risk_notes: invoice.risk_notes || null,
        };
      }),
    };

    if (isPayable) {
      if (getModalAction() == "Add") {
        createPayableInvoices(params)
          .then(() => {
            props.onSuccess();
            props.setShow(false);
            flashSuccess("Successfully added invoices!");
          })
          .catch((error) => {
            console.error(error);
            flashError("Failed to add invoices!");
          });
      } else {
        flashError("Cannot update multiple invoices at once!");
      }
    } else {
      if (getModalAction() == "Add") {
        createReceivableInvoices(params)
          .then(() => {
            props.onSuccess();
            props.setShow(false);
            flashSuccess("Successfully added invoices!");
          })
          .catch((error) => {
            console.error(error);
            flashError("Failed to add invoices!");
          });
      } else {
        flashError("Cannot update multiple invoices at once!");
      }
    }
  }

  if (props.show && props.invoices.value.length) {
    return (
      <Modal
        centered
        show={props.show}
        onHide={() => props.setShow(false)}
        dialogClassName="modal-xl"
      >
        <Modal.Header>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-5">
          {getModalAction() === "Add" && (
            <div className="row">
              <div className="col">
                <div id="invoice-type" className="col">
                  <UIFormLabel label="Invoice Type" />
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col-md-auto mb-3">
                      <UIRadioCard
                        name="invoice-type-receivable"
                        id="invoice-type-receivable"
                        label="Receivable"
                        checked={isPayable === false}
                        handleClick={() => {
                          setIsPayable(false);

                          let invoice = props.invoices.value[0];
                          props.invoices.modifyById(invoice.id, {
                            ...invoice,
                            cash_balance_status: "money in",
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-auto mb-3">
                      <UIRadioCard
                        name="invoice-type-payable"
                        id="invoice-type-payable"
                        label="Payable"
                        checked={isPayable === true}
                        handleClick={() => {
                          setIsPayable(true);

                          let invoice = props.invoices.value[0];
                          props.invoices.modifyById(invoice.id, {
                            ...invoice,
                            cash_balance_status: "money out",
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {props.invoices.value.map((invoice, index) => {
            return (
              <div
                className="d-flex align-items-start my-3"
                key={`invoice-fields-${invoice.id}`}
              >
                {props.invoices.value.length > 1 && index > 0 && (
                  <div className="d-flex align-items-center mr-3">
                    <button
                      className="btn btn-sm btn-muted"
                      onClick={(e) => {
                        e.preventDefault();
                        props.invoices.removeIndex(index);
                      }}
                    >
                      <i className="bi bi-x-lg"></i>
                    </button>
                  </div>
                )}

                <div className="d-flex flex-wrap align-items-start justify-content-between w-100">
                  {getCounterpartyInput(invoice)}

                  {!isPayable && (
                    <div
                      className="mb-4"
                      style={{ width: "23%", marginRight: "2%" }}
                    >
                      <UILabelInput
                        label="Project Number"
                        value={invoice.customer_details?.project_number}
                        onChange={updateProjectNumber.bind(null, invoice)}
                      />
                    </div>
                  )}

                  <div
                    className="mb-4"
                    style={{ width: "23%", marginLeft: "2%" }}
                  >
                    <UIMoneyLabelInput
                      label="Total Amount"
                      defaultValue={invoice.total_amount}
                      onValueChange={updateTotalAmount.bind(null, invoice)}
                      context={invoice.notes || ""}
                      required
                    />
                  </div>

                  <div
                    className="mb-4"
                    style={{ width: "23%", marginLeft: "2%" }}
                  >
                    <UIDateInput
                      label="Due Date"
                      date={invoice.due_date}
                      handleChange={updateDueDate.bind(null, invoice)}
                      required
                    />
                  </div>

                  <div style={{ width: "48%", marginRight: "2%" }}>
                    {getAccountInput(invoice)}
                    <div
                      className="alert alert-warning"
                      style={{ marginTop: "80px" }}
                      role="alert"
                    >
                      <strong>Remember:</strong> Updates made here will only
                      reflect on your Subcity dashboard and will{" "}
                      <strong>not</strong> sync back to your accounting
                      software.
                    </div>
                  </div>

                  <div
                    className="mb-6"
                    style={{ width: "48%", marginLeft: "2%" }}
                  >
                    <div>
                      <UITextareaLabelInput
                        name="risk_notes"
                        label="Add Note"
                        value={invoice.risk_notes || ""}
                        handleChange={updateNotes.bind(null, invoice)}
                        description="Use this space to add additional notes for this item."
                      />
                    </div>

                    {getModalAction() !== "Add" && (
                      <div className="d-flex align-items-center mb-4">
                        <button
                          className="btn btn-muted btn-sm mr-2"
                          style={{ paddingLeft: 0 }}
                          onClick={() => {
                            if (
                              invoice.at_risk === true ||
                              invoice.at_risk.probability === 1 //TODO: Go back and remove one of these
                            ) {
                              updateInvoiceAtRisk(invoice, false);
                            } else {
                              updateInvoiceAtRisk(invoice, true);
                            }
                          }}
                          title={
                            invoice.at_risk === true ||
                            invoice.at_risk.probability === 1
                              ? "Unflag as at-risk"
                              : "Flag as at-risk"
                          }
                        >
                          {invoice.at_risk === true ||
                          invoice.at_risk.probability === 1 ? (
                            <i
                              className="bi bi-flag-fill"
                              style={{ color: "red" }}
                            ></i>
                          ) : (
                            <i className="bi bi-flag"></i>
                          )}
                        </button>
                        <p style={{ margin: 0 }}>
                          {invoice.at_risk === true ||
                          invoice.at_risk.probability === 1
                            ? "Invoices is flagged. Click to unflag this invoice."
                            : "Click to flag this invoice."}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}

          <div className="row mt-5" key="invoice-modal-buttons">
            <div className="col-md-12">
              <div className="btn-group float-start">
                {getModalAction() === "Add" && (
                  <button
                    className="btn btn-outline-dark"
                    disabled={props.invoices.value.length >= MAX_PROJECTIONS}
                    onClick={(e) => {
                      e.preventDefault();

                      props.invoices.push(getNewProjectedInvoice());
                    }}
                  >
                    Add Additional Invoice
                  </button>
                )}
                {getModalAction() === "Update" &&
                  getModalStatus() == "Projected" && (
                    <button
                      className="btn btn-danger"
                      onClick={(e) => {
                        e.preventDefault();

                        deleteInvoice();
                      }}
                    >
                      Delete
                    </button>
                  )}
              </div>
              <div className="btn-group float-end">
                <button
                  className="btn btn-light"
                  style={{ width: "100px" }}
                  onClick={() => props.setShow(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  style={{ width: "100px" }}
                  onClick={(e) => {
                    e.preventDefault();

                    for (const index in props.invoices.value) {
                      const invoice = props.invoices.value[index];

                      if (!invoice.due_date) {
                        flashError("Must provide Due Date!");
                        return;
                      } else if (!invoice.total_amount) {
                        flashError("Must provide Total Amount!");
                        return;
                      }
                    }

                    if (props.invoices.value.length > 1) {
                      submitInvoices();
                    } else {
                      submitInvoice();
                    }
                  }}
                >
                  {getModalAction()}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  } else {
    return null;
  }
};

export default EditInvoice;
