import React, { useState, useContext, useEffect } from "react";
import { useArray, useSetState, useBoolean } from "react-hanger";
import { EditOrganizationContext } from "./edit-organization-context";
import { createAdminIncentive } from "../../../../modules/incentives";
import { UILabelInput } from "components/ui";
import axios from "lib/axios";
import { UIFormLabel } from "../../../ui";
import { flashError, flashSuccess } from "lib/flash";
import IncentiveTypeSelector from "../../incentives/common/incentive-type-selector";

const OrganizationIncentives = () => {
  const { basicInfo } = useContext(EditOrganizationContext);

  const EmptyIncentive = {
    name: "",
    status: "draft",
    incentive_urls: [
      {
        url: "",
        label: "Information",
      },
    ],
    sponsoring_organization_id: basicInfo.id,
    incentive_type_id: null,
  };

  const {
    state: variables,
    setState: setVariables,
    resetState: resetVariables,
  } = useSetState(null);

  const [incentives, setIncentives] = useState([]);

  useEffect(() => {
    fetchOrganizationIncentives();
  }, []);

  const fetchOrganizationIncentives = () => {
    axios
      .get(`/admin/organizations/${basicInfo.id}/incentives`)
      .then((response) => {
        console.log("Received Incentives:", response.data);
        setIncentives(response.data.organization_incentives);
      })
      .catch((error) => {
        console.error("Error fetching incentives:", error);
      });
  };

  const setCreateIncentive = () => {
    setVariables(EmptyIncentive);
  };

  const addIncentive = () => {
    createAdminIncentive({
      incentive: variables,
    })
      .then((response) => {
        flashSuccess("Incentive added");
        fetchSponsoredIncentives();
        resetVariables();
      })
      .catch((resp) => {
        resp.errors.map((error) => {
          flashError(error);
        });
        return Promise.resolve();
      });
  };

  return (
    <div>
      <div className="panel-header py-0 ps-0 pe-1 pt-0">
        <div className="row">
          <h3 className="pe-2">
            Incentives Sponsored by {basicInfo.name}
            <hr />
          </h3>
        </div>
      </div>
      <h3></h3>
      {variables !== null && (
        <div className="card px-4 py-3 mb-3">
          <div className="row">
            <div className="col-md-3">
              <UILabelInput
                label="Name"
                value={variables.name}
                onChange={(value) => setVariables({ name: value })}
              />
            </div>
            <div className="col-md-3">
              <UILabelInput
                label="Url"
                value={variables.incentive_urls[0].url}
                onChange={(value) =>
                  setVariables({
                    incentive_urls: [
                      {
                        url: value,
                        label: "Information",
                      },
                    ],
                  })
                }
              />
            </div>
            <div className="col-6 mb-3">
              <UIFormLabel label="Incentive Type" />
              <IncentiveTypeSelector
                incentiveType={variables.incentive_type_id}
                selectIncentiveType={(option) => {
                  setVariables({ incentive_type_id: option });
                }}
              />
            </div>
          </div>
          <div className="clearfix">
            <div className="mt-3 btn-group float-end">
              <a className="btn btn-light" onClick={resetVariables}>
                Cancel
              </a>
              <button className="btn btn-dark" onClick={addIncentive}>
                Create
              </button>
            </div>
          </div>
        </div>
      )}
      {variables === null && (
        <div className="d-flex justify-content-end pe-1 pb-3">
          <button className="btn-attach" onClick={setCreateIncentive}>
            New Incentive
          </button>
        </div>
      )}
      <div className="table panel-table">
        <table className="table admin-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {incentives.map((incentive, index) => {
              return (
                <tr key={index}>
                  <td>
                    <a href={`/admin/incentives/${incentive.id}/edit`}>
                      {" "}
                      {incentive.name}{" "}
                    </a>
                  </td>
                  <td>
                    <span className="badge rounded-pill bg-primary">
                      {incentive.status}
                    </span>
                  </td>
                  <td>
                    {incentive.incentive_type ? (
                      <span className="badge rounded-pill bg-light text-dark">
                        {" "}
                        {incentive.incentive_type.name}{" "}
                      </span>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrganizationIncentives;
