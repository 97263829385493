import React, { useState, useContext } from "react";

import { UIFieldGroup } from "components/ui";
import { EditCompanyContext } from "../contexts/edit-company-context";
import { EditCompanyContext as AdminEditCompanyContext } from "../contexts/admin-edit-company-context";
import { buildInitialValuesForSchema, deleteUnansweredValues } from 'lib/utils'

import { Formik, Form } from 'formik';

import {
  isCompanyUpdateValid
} from 'validators/company'

const narrativeSchema = {
  "type": "object",
  "label": "Company Narrative",
  "numCols": 1,
  "properties": {
    "history": {
      "row": 1,
      "type": "string",
      "label": "Company History",
      "required": true,
      "inputType": "textarea",
      "description": "In a few paragraphs, what is the history of your company? What are you most proud of? What positive attributes about California and your region make you proud to be in business here?"
    },
    "youth_outreach": {
      "row": 2,
      "type": "string",
      "label": "Youth Outreach",
      "required": true,
      "inputType": "textarea",
      "description": "Does your company interact with local schools or youth organizations? If so, how?"
    },
    "employee_morale": {
      "row": 3,
      "type": "string",
      "label": "Employee Morale",
      "required": true,
      "inputType": "textarea",
      "description": "How do you help build positive morale for your employees?"
    },
    "community_outreach": {
      "row": 4,
      "type": "string",
      "label": "Community Outreach",
      "required": true,
      "inputType": "textarea",
      "description": "What does your company do to give back to the community? Do your employees volunteer in the community?"
    },
    "employee_recognition": {
      "row": 5,
      "type": "string",
      "label": "Employee Recognition",
      "required": true,
      "inputType": "textarea",
      "description": "How do you recognize employees who go above and beyond in the workplace?"
    },
    "employee_satisfaction": {
      "row": 6,
      "type": "string",
      "label": "Employee Satisfaction",
      "required": true,
      "inputType": "textarea",
      "description": "Why do employees enjoy working at your company?"
    },
    "diversity_and_inclusion": {
      "row": 7,
      "type": "string",
      "label": "Diversity & Inclusion",
      "required": true,
      "inputType": "textarea",
      "description": "How do you promote diversity and inclusion in the workplace?"
    },
    "cctc_impact": {
      "row": 8,
      "type": "string",
      "label": "California Competes Award Impact",
      "required": true,
      "inputType": "textarea",
      "description": "What specific initiative or investment would the California Competes award enable your company to pursue?"
    },
    "instate_hiring": {
      "row": 9,
      "type": "string",
      "label": "California Competes Hiring Impact",
      "required": true,
      "inputType": "textarea",
      "description": "How would this program affect your ability and/or willingess to create new full-time jobs in California that may not otherwise be created by your company?"
    },
  }
};

const Narrative = ({isAdmin = false}) => {
  let context;
  if(isAdmin) {
    context = AdminEditCompanyContext;
  } else {
    context = EditCompanyContext;
  }
  const { onUpdateCompany, basicInfo, setBasicInfo } =
    useContext(context);

  const [companyName, setCompanyName] = useState(basicInfo.name);
  const initialNarrative = buildInitialValuesForSchema(narrativeSchema, basicInfo.narrative || {});

  const updateCompany = (basicInfo) => {
    onUpdateCompany(basicInfo).then(() => {
      setCompanyName(basicInfo.name);
    });
  };

  function renderHeader() {
    return (
      <div className="panel-header pb-3 ps-0 pt-0">
        <div className="row">
          <h1>Narrative for {companyName}</h1>
          <hr />
        </div>
      </div>
    )
  }

  return (
    <Formik
      initialValues={{company_narrative: { ...initialNarrative }}}
    >
      {({ 
        values,
      }) => {
        return (
          <div className="col-md-12">
            <Form onSubmit={ e => {
              e.preventDefault();

              updateCompany({
                ...basicInfo, 
                narrative: {
                  ...basicInfo.narrative,
                  ...deleteUnansweredValues(values.company_narrative)
                }
              });
            }}>
              <div className="card p-3 mt-1 mb-3 z-depth-2">
                <UIFieldGroup
                  prefix="company_narrative"
                  schema={narrativeSchema}
                />
              </div>

              <div className="row mb-4">
                <div className="col-md-10 mb-4">
                  <div className="mb-5 btn-group float-end">
                    <a className="btn btn-light" href="/">
                      Back
                    </a>
                    <button className="btn btn-dark" 
                      disabled={!isCompanyUpdateValid(basicInfo)}
                      type="submit"
                      formNoValidate
                    >
                      Save Company
                    </button>
                  </div>
                  {(isCompanyUpdateValid(basicInfo))}
                </div>
              </div>
            </Form>
          </div>
        )
      }}
    </Formik>
  );
};

export default Narrative;
