import React, { useEffect, useState } from "react";
import { useArray, useSetState, useBoolean } from "react-hanger";
import OptionCard from "./option-card";
import OptionForm from "./option-form";
import { flashError, flashSuccess, flashWarning } from "../../../../lib/flash";
import { map } from "lodash-es";
import {
  createAdminAnswerOption,
  deleteAdminAnswerOption,
  updateAdminAnswerOption,
} from "../../../../modules/answer-options";

const MultipleChoiceAnswers = (props) => {
  const answerOptions = useArray(props.answerOptions || []);
  const newOption = useBoolean(false);
  const {
    state: editAnswerOption,
    setState: setEditAnswerOption,
    resetState: resetEditAnswerOption,
  } = useSetState(null);

  useEffect(() => {
    props.setAnswerOptions(answerOptions.value);
  }, [answerOptions]);

  const emptyOption = () => ({
    option_text: "",
  });

  const addNewOption = () => {
    newOption.setTrue();
    resetEditAnswerOption();
  };

  const removeOption = (answerOption) => {
    deleteAdminAnswerOption({ id: answerOption.id }).then((res) => {
      if (res.errors) map(res.errors, flashError);
      else {
        flashWarning("Option removed");
        answerOptions.removeById(answerOption.id);
      }
    });
    newOption.setFalse();
    resetEditAnswerOption();
  };

  const onOptionEdit = (answerOption) => {
    newOption.setFalse();
    setEditAnswerOption(answerOption);
  };

  const onOptionSave = (answerOption) => {
    if (answerOption.id) {
      updateAdminAnswerOption({
        ...answerOption,
        qualification_id: props.qualificationId,
        id: answerOption.id,
      }).then((res) => {
        if (res.errors) map(res.errors, flashError);
        else {
          flashSuccess("Option updated");
          answerOptions.modifyById(answerOption.id, answerOption);
        }
      });
    } else {
      createAdminAnswerOption({
        ...answerOption,
        qualification_id: props.qualificationId,
      }).then((res) => {
        if (res.errors) map(res.errors, flashError);
        else {
          flashSuccess("Option added");
          answerOptions.push(res);
        }
      });
    }
    newOption.setFalse();
    resetEditAnswerOption();
  };

  return (
    <div>
      <label className="mb-1">
        <strong>Answer Options</strong>
      </label>
      {answerOptions.value.map((answerOption) => (
        <div key={answerOption.id}>
          {editAnswerOption && answerOption.id === editAnswerOption.id ? (
            <OptionForm
              answerOption={editAnswerOption}
              onOptionSave={onOptionSave}
              removeOption={removeOption}
            />
          ) : (
            <OptionCard
              answerOption={answerOption}
              onOptionEdit={onOptionEdit}
            />
          )}
        </div>
      ))}
      {newOption.value && (
        <OptionForm
          answerOption={emptyOption()}
          onOptionSave={onOptionSave}
          removeOption={removeOption}
        />
      )}
      <div className="mt-2">
        <button className="btn-attach" onClick={addNewOption}>
          Add option
        </button>
      </div>
    </div>
  );
};

export default MultipleChoiceAnswers;
