import React, { useContext, useState, useEffect } from "react";

import axios from "lib/axios";

import { disconnectCodat } from "modules/companies";
import { flashError, flashSuccess } from "lib/flash";

const Integrations = (props) => {
  const { availableAccountingIntegrations, company } = props;
  const [authenticated, setAuthenticated] = useState(
    company.integrated_accounting_software
  );

  useEffect(() => {
    setAuthenticated(company.integrated_accounting_software);
  }, [company]);

  const params = {
    id: company.id,
    integration_path: location.pathname,
  };

  const onDisconnect = () => {
    disconnectCodat({ company_id: company.id })
      .then((res) => {
        flashSuccess("Successfully disconnected.");
        setAuthenticated(false);
      })
      .catch((res) => {
        flashError(res.errors);
      });
  };

  const nextStep = () => {
    axios
      .post("/onboarding/integrations")
      .then((response) => {
        window.location = props.nextRoute;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function handleClickTile(integration) {
    let url = "/integrations/codat/authenticate";
    url += "?codat_integration_key=" + integration.key;
    url += "&company_id=" + company.id;
    window.location = url;
  }

  return (
    <div className="mt-3">
      <div className="row">
        <div className="d-flex row home-dashboard">
          {!authenticated &&
            availableAccountingIntegrations["results"] &&
            availableAccountingIntegrations["results"].map(
              (integration, index) => {
                return (
                  <div className="col-md-4" key={`integration-${index}`}>
                    <div
                      className="card mb-4 integration-card"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClickTile(integration);
                      }}
                    >
                      <div
                        className="card-body d-flex"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <img
                        src={integration.logoUrl}
                        alt={integration.name}
                        width="200px"
                        unselectable="on"
                        className="align-middle"
                      /> */}
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          {
            <div className="ms-auto">
              {authenticated ? (
                <div>
                  You're connected via&nbsp;
                  {company.integrated_accounting_via}.
                  <br />
                  <br />
                  <button
                    className="btn btn-light"
                    rel="nofollow"
                    onClick={onDisconnect}
                  >
                    Click here to disconnect
                  </button>
                </div>
              ) : null}
            </div>
          }
        </div>
      </div>
      <div className="form-group mt-4">
        <div className="float-end">
          {props.previousRoute && (
            <a href={props.previousRoute} className="btn btn-light me-2">
              Back
            </a>
          )}

          <button
            className="btn btn-action me-2"
            type="submit"
            onClick={nextStep}
          >
            Next
          </button>
          <button className="btn btn-light" type="button" onClick={nextStep}>
            Skip
          </button>
        </div>
      </div>
    </div>
  );
};

export default Integrations;
