import React, { useEffect, useState } from 'react';
import AsyncSelect from "react-select/async";

import { loadCompanyAttachments } from 'modules/attachments'

const Attachment = ({ invoice, company, setInvoice }) => {
  const loader = loadCompanyAttachments({
    company_id: company.id, 
    type: ['Company', 'CopmanyPurchase', 'CompanyReceivableInvoice']
  })

  const defaultAttachment = { value: invoice.attachment_id, label: invoice.attachment_file_name }

  return (
    <div className='card text-bg-light'>
      <div className='card-body'>
        <div className='row'>
          <div className='col-md-6'>
            <label><strong>Attach File</strong></label>
            <AsyncSelect
              isMulti={false}
              loadOptions={loader}
              defaultValue={defaultAttachment}
              defaultOptions
              name={`attached-file`}
              onChange={(attachment) => {
                setInvoice({ ...invoice, attachment_id: attachment.value})
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Attachment;
