import React from "react";
import moment from "moment";
import { humanFileSize, titleCase } from "lib/string";
import Dropdown from "react-bootstrap/Dropdown";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    className="btn btn-muted"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const FileResourceRow = ({
  isAdmin,
  setPreviewFile,
  resource,
  index,
  setEditFile,
  setModalShow,
  deleteFileResource,
}) => {
  function fileTypeIcon(resource) {
    const type = resource.type.toLowerCase();
    switch (type) {
      case "tif":
        return "filetype-tiff";
      case "jpeg":
        return "filetype-jpg";
      case "rtf":
        return "file-richtext";
      case "docx":
      case "pdf":
      case "gif":
      case "jpg":
      case "png":
      case "csv":
      case "xls":
      case "xlsx":
      case "txt":
      case "zip":
        return `filetype-${type}`;
      default:
        return "file";
    }
  }

  return (
    <tr>
      <td style={{ wordWrap: "anywhere", width: "80px" }}>
        <div
          className="file-row-name"
          onClick={(e) => {
            e.preventDefault();
            setPreviewFile(resource, index);
          }}
        >
          <i className={`me-2 bi bi-${fileTypeIcon(resource)}`} />
          <span>{resource.name}</span>
        </div>
      </td>
      <td>
        <p className="text-muted fs-6">{humanFileSize(resource.size)}</p>
      </td>
      <td>{resource.owner}</td>
      <td>{moment(resource.updated_at).format("MM/DD/YYYY")}</td>
      <td>{titleCase(resource.status || "-")}</td>
      <td>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <i className="bi bi-three-dots"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {isAdmin ? (
              <Dropdown.Item
                href="#"
                onClick={() => {
                  setEditFile(resource);
                  setModalShow(true);
                }}
              >
                Edit
              </Dropdown.Item>
            ) : null}
            <Dropdown.Item href={resource.url}>Download</Dropdown.Item>
            <Dropdown.Item
              href="#"
              onClick={() => {
                deleteFileResource(resource.id);
              }}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

export default FileResourceRow;
