import React, { useEffect, useState } from 'react';
import { useArray } from "react-hanger";
import { flashSuccess, flashError } from 'lib/flash'

import { 
  getCompanyPayableInvoice,
  getCompanyReceivableInvoice,
  updateCompanyPayableInvoice,
  updateCompanyReceivableInvoice,  
  sendRecievableInvoiceToCodat,
  sendPayableInvoiceToCodat,
  newTags,
  newTag,
  newItems,
  newItem,
  newInvoice,
}  from 'modules/admin/company-invoices'

import Form from './components/form';

const Edit = (props) => {
  const { company, endpoint } = props;

  function setEndpoint(a, b) {
    return endpoint === 'payables' ? a : b
  }

  const getCompanyInvoice = setEndpoint(getCompanyPayableInvoice, getCompanyReceivableInvoice)
  const updateCompanyInvoice = setEndpoint(updateCompanyPayableInvoice, updateCompanyReceivableInvoice)
  const sendInvoiceToCodat = setEndpoint(sendPayableInvoiceToCodat, sendRecievableInvoiceToCodat)

  const [ invoice, setInvoice ] = useState(newInvoice(company, setEndpoint))
  const items = useArray(newItems());
  const tags = useArray([]);

  const [isLoaded, setIsLoaded] = useState(false);

  function addTag() {
    tags.push(newTag())
  }

  function updateTag(tag, field, value) {
    tags.modifyById(tag.id, { ...tag, [field]: value })
  }

  function removeTag(id) {
    tags.removeById(id)
  }

  function addItem() {
    items.push(newItem(props.company.id))
  }

  function updateItem(item, field, value) {
    items.modifyById(item.id, { ...item, [field]: value })
  }

  function removeItem(item) {
    if(item._new) {
      items.removeById(item.id)
    } else {
      items.modifyById(item.id, { ...item, _destroy: true })
    }
  }

  function prepareParams() {
    const itemAttributes = (setEndpoint(() => {
      return items.value.map((item) => {
        // Used for backwards compatability with CAMAN.
        const updatedItem = {
          ...item,
          acquisition_date: invoice.issue_date,
          purchase_order: invoice.purchase_order_number,
          status: 0,
          total_paid: item.quantity * item.unit_cost,
          purchaser: invoice.customer_details,
          seller: invoice.vendor_details,
        }

        if(item._new) {
          delete updatedItem.id;
        }

        delete updatedItem._new;
        return updatedItem
      })
    }, () => {
      return items.value.map((item) => {
        const updatedItem = {
          ...item,
          unit_amount: item.unit_cost,
          company_receivable_invoice_id: invoice.id,
        }

        if(item._new) {
          delete updatedItem.id;
        }

        delete updatedItem.unit_cost;
        delete updatedItem._new;
        return updatedItem
      })
    }))()

    delete invoice.expense_account_name
    delete invoice.attachment_file_name 

    const params = (setEndpoint(() => {
      return {
        id: invoice.id,
        company_payable_invoice: {
          company_purchases_attributes: itemAttributes,
          company_id: company.id,
          ...invoice,
          tags: tags.value,
          vendor_name: invoice.vendor_details.name, 
          company_purchases: undefined,
        } 
      }
    }, () => {
      return {
        id: invoice.id,
        company_receivable_invoice: {
          company_receivable_lineitems_attributes: itemAttributes,
          company_id: company.id,
          ...invoice,
          tags: tags.value,
          customer_name: invoice.customer_details.name, 
          company_receivable_lineitems: undefined,
        }
      }
    }))()

    return params;
  }

  function handleSendToCodat(e) {
    sendInvoiceToCodat(invoice.id)
      .then((response) => {
        flashSuccess('Invoice syncing to codat.')
      })
      .catch((errors) => {
        flashError(errors)
      })
  }

  function handleSave(e) {
    e.preventDefault()

    const params = prepareParams()

    updateCompanyInvoice(params)
      .then((response) => {
        flashSuccess('Invoice updated successfuly!')
      })
      .catch((errors) => {
        flashError(errors)
      })
  }

  useEffect(() => {
    const tokens = location.href.split("/");
    const id = tokens[tokens.length - 1];

    getCompanyInvoice(id)
      .then((data) => {
        (setEndpoint(() => {
          items.push(data.company_purchases)
        }, () => {
          items.push(data.company_receivable_lineitems)
        }))()
        
        tags.push(data.tags || [])
        setInvoice(data)
        setIsLoaded(true)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  return (
    <div>
      <div className='row'>
        <div className='col-md-8'>
          <h4><strong>Edit Invoice</strong></h4>
        </div>
        <div className='col-md-4'>
          <a className="btn btn-light float-end" href="#/payables">
            Back
          </a>
        </div>
      </div>
      <hr/>

      {
        isLoaded &&
          <Form
            isNew={false}
            endpoint={endpoint}
            company={company}
            invoice={invoice}
            setInvoice={setInvoice}
            handleSave={handleSave}
            handleSendToCodat={handleSendToCodat}
            setEndpoint={setEndpoint}
            tags={tags.value}
            addTag={addTag}
            updateTag={updateTag}
            removeTag={removeTag}
            items={items.value}
            addItem={addItem}
            updateItem={updateItem}
            removeItem={removeItem}
          />
      } 
    </div>
  )
}

export default Edit;
