import React, { useEffect } from "react";
import UIFormLabel from "../ui-form-label";
import UIValidationError from "../ui-validation-error";
import Select from "react-select";
import { useField, useFormikContext } from "formik";
import UIRadioCard from "../ui-radio-card";

const UIMultiSelectInputFormik = ({
  label,
  name,
  options,
  required,
  description,
  tooltip,
  isMulti = false,
}) => {
  const [field, meta, helpers] = useField(name);
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    if (
      isMulti &&
      (typeof values[name] === "undefined" || !Array.isArray(values[name]))
    ) {
      setFieldValue(name, []);
    }
  }, [isMulti, name, setFieldValue]);

  const transformedOptions = options.enum
    ? options.enum.map((option) => ({ value: option, label: option }))
    : options;

  const handleChange = (selectedOptions) => {
    setFieldValue(
      name,
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  };

  const handleRadioChange = (value) => {
    helpers.setValue(value);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const currentValues = field.value || [];
    if (checked) {
      setFieldValue(name, [...currentValues, value]);
    } else {
      setFieldValue(
        name,
        currentValues.filter((item) => item !== value)
      );
    }
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      border: "1px solid #e0e0e0",
    }),
  };

  const useRadio = transformedOptions && transformedOptions.length <= 5;

  return (
    <div className="row">
      <div id={name} className="col">
        <UIFormLabel
          label={`${label} ${isMulti ? "(Select all that apply)" : ""}`}
          required={required}
          tooltip={tooltip}
        />
        {!!description && <p>{description}</p>}
        {meta.error && meta.touched && (
          <UIValidationError message={meta.error.replace(name, "This")} />
        )}
      </div>
      <div className="col">
        {isMulti ? (
          // Render checkboxes for multi-select
          <div>
            {transformedOptions.map((option) => (
              <div className="row">
                <div className="col-12 col-md-6 mb-3" key={option.value}>
                  <label className="ui-radio-card">
                    <input
                      type="checkbox"
                      className="card-input-element"
                      name={name}
                      value={option.value}
                      checked={field.value.includes(option.value)}
                      onChange={handleCheckboxChange}
                    />
                    <div className="card card-input">
                      <div className="card-body py-2 px-4">{option.label}</div>
                    </div>
                  </label>
                </div>
              </div>
            ))}
          </div>
        ) : useRadio ? (
          transformedOptions.map((option) => (
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <UIRadioCard
                  key={option.value}
                  id={`radio-${option.value}`}
                  name={name}
                  label={option.label}
                  checked={field.value === option.value}
                  handleClick={() => handleRadioChange(option.value)}
                />
              </div>
            </div>
          ))
        ) : (
          <Select
            name={name}
            styles={customStyles}
            placeholder="Select all that apply..."
            options={
              Array.isArray(transformedOptions) ? transformedOptions : []
            }
            isMulti
            value={
              Array.isArray(transformedOptions)
                ? transformedOptions.filter((option) =>
                    field.value.includes(option.value)
                  )
                : []
            }
            onChange={handleChange}
          />
        )}
      </div>
    </div>
  );
};

export default UIMultiSelectInputFormik;
