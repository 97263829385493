import React, { Component, useEffect, useState } from "react";
import Select from "react-select";
import UIFormLabel from './ui-form-label'
import UIValidationError from './ui-validation-error';

import { useField } from 'formik';

const UISelectInput = (props) => {
  const [field, meta, helpers] = useField({ name: props.name });

  return (
    <div className='ui-label-input'>
      {!!props.label &&
        <UIFormLabel
          label={props.label}
          required={props.required}
        />
      }
      <Select
        value={props.options.find((entity) => { return entity.value == meta.value; })}
        options={props.options}
        onChange={(selectedOption) => {
          helpers.setValue(selectedOption.value);
        }}
      />
      {meta.error && meta.touched &&
        <UIValidationError message={meta.error.replace(props.name, "This")} />
      }
    </div>
  );
};

export default UISelectInput;
