import React from "react";

const UINavVertical = (props) => {
  const sections = props.tabs;

  return (
    <div className="vertical-nav-ui mb-4">
      {sections.map((section, sectionIndex) => (
        <div key={`section-${sectionIndex}`} className="mb-3">
          <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>{section.section}</span>
          </h6>
          <ul className="nav flex-column">
            {section.items.map((tabItem, index) => {
              let navLinkClassName = "nav-link";
              const isCurrentPath = window.location.pathname === tabItem.link;
              if (isCurrentPath) navLinkClassName += " active";

              return (
                <li
                  key={`nav-tab-ui-${sectionIndex}-${index}`}
                  className="nav-item"
                >
                  <a className={navLinkClassName} href={tabItem.link}>
                    <i className={`bi ${tabItem.icon} me-4`}></i>
                    {tabItem.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default UINavVertical;
