import React from "react";

const QualifiedIncentiveCard = ({
  incentive,
  user_signed_in,
  setIsLoading,
  isLoading,
}) => {
  const shouldShowReadMore = user_signed_in || incentive.status === "visible";

  return (
    <div className="mt-3">
      <div key={incentive.id} className="qualified-incentive-card">
        <div className="incentive-card-content">
          {incentive.sponsoring_organization &&
            incentive.sponsoring_organization.logo && (
              <img
                loading="lazy"
                className="incentive-card-logo"
                src={incentive.sponsoring_organization.logo}
                alt={`${incentive.sponsoring_organization.name} Logo`}
              />
            )}
          <div>
            <h2 className="incentive-card-title">
              <strong>{incentive.name}</strong>
            </h2>
            <div className="incentive-card-description">
              <p>{incentive.short_description}</p>
            </div>
          </div>
          {!user_signed_in ? (
            <button className="btn-attach" style={{ marginLeft: "auto" }}>
              <a
                href={
                  incentive.fulfillment_affiliate_link
                    ? incentive.fulfillment_affiliate_link
                    : incentive.has_intake_form
                    ? `/register?incentive_slug=${incentive.slug}`
                    : incentive.status === "visible"
                    ? `/incentive/${incentive.slug}`
                    : incentive.sponsoring_organization.website
                }
              >
                {incentive.fulfillment_affiliate_link
                  ? "Apply Now"
                  : incentive.has_intake_form
                  ? "Pre-Qualify"
                  : incentive.status === "visible"
                  ? "Read More"
                  : "Read More"}
              </a>
            </button>
          ) : (
            <button
              className="btn-attach"
              style={{ marginLeft: "auto" }}
              onClick={() => setIsLoading(true)}
            >
              {isLoading ? (
                <>
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    className="me-2"
                  ></Spinner>
                  <span className="sr-only">Loading Application</span>
                </>
              ) : (
                <a href={`/applications/${incentive.slug}#/pre-qualify`}>
                  Pre-Qualify
                </a>
              )}
            </button>
          )}
        </div>
        <div className="incentive-card-footer">
          <strong>Sponsored by:&nbsp;</strong>
          {incentive.sponsoring_organization.website ? (
            <a
              href={incentive.sponsoring_organization.website}
              target="_blank"
              rel="noopener noreferrer"
            >
              {incentive.sponsoring_organization.name}
            </a>
          ) : (
            incentive.sponsoring_organization.name
          )}
          <br />
          {incentive.economics}{" "}
          {shouldShowReadMore && (
            <a href={`/incentives/${incentive.slug}`}>Read more...</a>
          )}
        </div>
      </div>
    </div>
  );
};

export default QualifiedIncentiveCard;
