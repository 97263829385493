import React, { useState, useEffect, useRef } from "react";
import { UIBigNumberCard } from "components/ui";
import ProjectMetadata from "./components/project-metadata";
import { getAllocatedLineItemGroups } from "modules/project";
import { prettyDisplayPrice } from "lib/string";

const Overview = (props) => {
  const [transactions, setTransactions] = useState([]);
  const [aggregatedData, setAggregatedData] = useState({});
  const [hoursByName, setHoursByName] = useState({});

  function calculateTotalQuantity(transaction, transactions) {
    // Default quantity to 1 if not provided
    const quantity =
      parseFloat(transaction.project_estimate_category_quantity) || 1;

    // If there's no parent, return the quantity of the current transaction
    if (!transaction.parent_id) {
      return quantity;
    }

    // Find the parent transaction
    const parentTransaction = transactions.find(
      (t) => t.id === transaction.parent_id
    );

    // If the parent is not found (or no more parents), return the current quantity
    if (!parentTransaction) {
      return quantity;
    }

    // Recursively calculate the quantity of the parent and multiply
    return quantity * calculateTotalQuantity(parentTransaction, transactions);
  }

  function aggregateTransactionsByName(transactions) {
    console.log(transactions);
    const aggregationByName = {};
    let totalAggregatedEstimated = 0;
    let totalAggregatedActual = 0;
    let totalAggregatedDiff = 0;

    transactions.forEach((transaction) => {
      console.log(transaction);
      if (transaction.id === null || transaction.active === false) {
        return;
      }

      if (transaction.children == null) {
        const name = transaction.name;
        const parent_id = transaction.parent_id;
        const totalQuantity = calculateTotalQuantity(transaction, transactions);
        const aggregatedTotalEstimated =
          (parseFloat(transaction.aggregated_total_estimated) ||
            parseFloat(transaction.total_estimated)) * totalQuantity;
        const aggregatedTotal =
          parseFloat(transaction.aggregated_total) ||
          parseFloat(transaction.total);
        const aggregatedTotalDiff = aggregatedTotalEstimated - aggregatedTotal;

        if (aggregatedTotalEstimated !== 0 || aggregatedTotal !== 0) {
          if (!aggregationByName[name]) {
            aggregationByName[name] = {
              parent_id: parent_id,
              aggregated_total_estimated: aggregatedTotalEstimated,
              aggregated_total: aggregatedTotal,
              aggregated_total_diff: aggregatedTotalDiff,
            };
          } else {
            // Sum up the totals if the name already exists
            aggregationByName[name].aggregated_total_estimated +=
              aggregatedTotalEstimated;
            aggregationByName[name].aggregated_total += aggregatedTotal;
            aggregationByName[name].aggregated_total_diff +=
              aggregatedTotalDiff;
          }

          // Add to totals
          totalAggregatedEstimated += aggregatedTotalEstimated;
          totalAggregatedActual += aggregatedTotal;
          totalAggregatedDiff += aggregatedTotalDiff;
        }
      }
    });

    // Include the total in the result
    if (transactions.length > 0) {
      aggregationByName["Total"] = {
        parent_id: null, // No parent_id for the total row
        aggregated_total_estimated: totalAggregatedEstimated,
        aggregated_total: totalAggregatedActual,
        aggregated_total_diff: totalAggregatedDiff,
      };
    }
    return aggregationByName;
  }

  function calculateHoursByName(transactions) {
    const hoursByName = {};

    transactions.forEach((transaction) => {
      // Calculate the total quantity for each transaction
      const totalQuantity = calculateTotalQuantity(transaction, transactions);

      const name = transaction.name;
      let estimatedHours = 0;
      let actualHours = 0;

      // Calculate estimated hours
      (transaction.line_items || []).forEach((lineItem) => {
        const estimateLineItem = lineItem.estimate_line_item || {};
        if (estimateLineItem.unit === "Hours") {
          // Multiply estimated hours by the total quantity
          estimatedHours += estimateLineItem.quantity * totalQuantity;
        }

        // Calculate actual hours from lineItem.transactions
        (lineItem.transactions || []).forEach((trans) => {
          if (trans.allocation_type === "CompanyCompensation") {
            actualHours += trans.quantity;
          }
        });
      });

      if (!hoursByName[name]) {
        hoursByName[name] = { estimated: 0, actual: 0 };
      }
      hoursByName[name].estimated += estimatedHours;
      hoursByName[name].actual += actualHours;
    });

    return hoursByName;
  }

  const calculateTotals = () => {
    let totalEstimated = 0;
    let totalActual = 0;
    Object.values(hoursByName).forEach((hours) => {
      totalEstimated += hours.estimated;
      totalActual += hours.actual;
    });
    return { totalEstimated, totalActual };
  };

  const { totalEstimated, totalActual } = calculateTotals();
  const totalRemaining = totalEstimated - totalActual;

  function hasRelevantAggregatedData(data) {
    return Object.entries(data).some(
      ([name, data]) =>
        (data.aggregated_total !== 0 ||
          data.aggregated_total_estimated !== 0) &&
        name !== "Total"
    );
  }

  function fetchTransactions() {
    getAllocatedLineItemGroups(props.project.id)
      .then((result) => {
        setTransactions(result);
        setAggregatedData(aggregateTransactionsByName(result));
        setHoursByName(calculateHoursByName(result));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    fetchTransactions();
  }, []);

  return (
    <div className="row mt-3">
      <div className="col-md-9">
        <div className="row">
          <div className="col-md-3">
            <UIBigNumberCard
              prefix="$"
              suffix="K"
              title="Revenue"
              number={props.project.revenue / 1000}
              contextNumber={
                props.project.project_quote_subtotal === 0
                  ? "-"
                  : (props.project.revenue * 100) /
                    props.project.project_quote_subtotal
              }
              contextSuffix={
                props.project.project_quote_subtotal === 0
                  ? ""
                  : "% amount quoted"
              }
            />
          </div>
          <div className="col-md-3">
            <UIBigNumberCard
              prefix="$"
              suffix="K"
              title="Costs"
              number={props.project.direct_costs / 1000}
              contextNumber={
                aggregatedData.Total &&
                aggregatedData.Total.aggregated_total_estimated !== 0
                  ? (props.project.direct_costs * 100) /
                    aggregatedData.Total.aggregated_total_estimated
                  : "-"
              }
              contextSuffix={
                aggregatedData.Total &&
                aggregatedData.Total.aggregated_total_estimated !== 0
                  ? "% amount estimated"
                  : ""
              }
            />
          </div>
          <div className="col-md-3">
            <UIBigNumberCard
              suffix="%"
              title="Gross Margin"
              number={100 * props.project.gross_margin}
            />
          </div>
          <div className="col-md-3">
            <UIBigNumberCard
              suffix="%"
              title="Net Profit"
              number={100 * props.project.net_profit}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <p className="mb-0">
                  <strong>Estimate vs Actuals ($)</strong>
                </p>
                {transactions && transactions.length > 0 ? (
                  <table className="table admin-table mb-2 mt-3">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Estimated</th>
                        <th>Actual</th>
                        <th>Remaining</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(aggregatedData)
                        .sort((a, b) => a[1].parent_id - b[1].parent_id)
                        .filter(
                          ([name, data]) =>
                            (data.aggregated_total !== 0 ||
                              data.aggregated_total_estimated !== 0) &&
                            name !== "Total"
                        )
                        .map(([name, data]) => {
                          const diff = parseFloat(data.aggregated_total_diff);
                          return (
                            <tr key={name}>
                              <td>{name}</td>
                              <td>
                                {prettyDisplayPrice(
                                  data.aggregated_total_estimated
                                )}
                              </td>
                              <td>
                                {prettyDisplayPrice(data.aggregated_total)}
                              </td>
                              <td>
                                {diff < 0 ? (
                                  <span className="unit-amount-zero-indicator">
                                    {prettyDisplayPrice(diff)}
                                  </span>
                                ) : (
                                  prettyDisplayPrice(diff)
                                )}
                              </td>
                            </tr>
                          );
                        })}

                      {/* Total Row */}
                      {aggregatedData.Total && (
                        <tr>
                          <td>
                            <strong>Total ($)</strong>
                          </td>
                          <td>
                            <strong>
                              {prettyDisplayPrice(
                                aggregatedData.Total.aggregated_total_estimated
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {prettyDisplayPrice(
                                aggregatedData.Total.aggregated_total
                              )}
                            </strong>
                          </td>
                          <td>
                            <td>
                              {aggregatedData.Total.aggregated_total_diff <
                              0 ? (
                                <span className="unit-amount-zero-indicator">
                                  {prettyDisplayPrice(
                                    aggregatedData.Total.aggregated_total_diff
                                  )}
                                </span>
                              ) : (
                                <strong style={{ color: "#1C944C" }}>
                                  {prettyDisplayPrice(
                                    aggregatedData.Total.aggregated_total_diff
                                  )}
                                </strong>
                              )}
                            </td>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <p>
                    Create an estimate to show this report.{" "}
                    <a href={`/projects/${props.project.id}#/estimate`}>
                      Click here
                    </a>
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <p>
                  <strong>Estimate vs Actuals (Hours)</strong>
                </p>
                {hasRelevantAggregatedData(aggregatedData) ? (
                  <table className="table admin-table mb-2 mt-3">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Estimated</th>
                        <th>Actual</th>
                        <th>Remaining</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(hoursByName)
                        .filter(
                          ([name, hours]) =>
                            hours.estimated > 0 || hours.actual > 0
                        )
                        .map(([name, hours]) => {
                          const remaining = hours.estimated - hours.actual;
                          return (
                            <tr key={name}>
                              <td>{name}</td>
                              <td>{hours.estimated.toFixed(2)}</td>
                              <td>{hours.actual.toFixed(2)}</td>
                              <td>
                                {remaining < 0 ? (
                                  <span className="unit-amount-zero-indicator">
                                    {remaining.toFixed(2)}
                                  </span>
                                ) : (
                                  remaining.toFixed(2)
                                )}
                              </td>
                            </tr>
                          );
                        })}

                      {/* Total Row */}
                      <tr>
                        <td>
                          <strong>Total (Hours)</strong>
                        </td>
                        <td>
                          <strong>{totalEstimated.toFixed(2)}</strong>
                        </td>
                        <td>
                          <strong>{totalActual.toFixed(2)}</strong>
                        </td>
                        <td>
                          {totalRemaining < 0 ? (
                            <span className="unit-amount-zero-indicator">
                              {totalRemaining.toFixed(2)}
                            </span>
                          ) : (
                            <strong> {totalRemaining.toFixed(2)}</strong>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <p>
                    Create an estimate to show this report and connect{" "}
                    <a href="/company/edit#/time_integrations">
                      time-tracking software
                    </a>{" "}
                    to automate updates. <br />
                    <a href={`/projects/${props.project.id}#/estimate`}>
                      Click here
                    </a>{" "}
                    to create an estimate.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <ProjectMetadata project={props.project} />
      </div>
    </div>
  );
};

export default Overview;
