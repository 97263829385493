import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const StyleGuideModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button className="btn btn-outline-primary" onClick={handleShow}>
        Style Guide
      </button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Custom JSON Schema Style Guide</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              This style guide explains how to use the custom JSON schema to
              define application forms. Here are the key components you can use:
            </p>
            <ul>
              <li>
                <strong>String Fields:</strong> Use for text inputs. Can be
                configured as a textarea, datepicker, etc. For example:
                <pre>
                  <code>{`
{
  "type": "string",
  "label": "First Name",
  "required": true,
  "tooltip": "Enter your first name as it appears on official documents."
}
      `}</code>
                </pre>
                For a datepicker:
                <pre>
                  <code>{`
{
  "type": "string",
  "inputType": "datepicker",
  "label": "Date of Birth",
  "required": true,
  "tooltip": "Select your date of birth from the calendar.",
  "dateFormat": "MM/dd/yyyy"
}
      `}</code>
                </pre>
              </li>
              <li>
                <strong>Number Fields:</strong> Use for numeric inputs. Can be
                configured to accept money formats. For example:
                <pre>
                  <code>{`
{
  "type": "number",
  "label": "Annual Income",
  "inputType": "money",
  "required": true,
  "tooltip": "Enter your annual income in USD."
}
      `}</code>
                </pre>
              </li>
              <li>
                <strong>Boolean Fields:</strong> Use for true/false inputs.
                Represented as radio buttons. For example:
                <pre>
                  <code>{`
{
  "type": "boolean",
  "label": "Government Owned",
  "required": true,
  "tooltip": "Specify if this building is owned by a federal, state, or local government entity. This is important as it affects the eligibility and process for claiming the 179D deduction.",
  "trueLabel": "Yes",
  "falseLabel": "No",
  "description": "Is this building government-owned?"
}
      `}</code>
                </pre>
              </li>
              <li>
                <strong>Array Fields:</strong> Use for selecting multiple values
                from predefined options. Supports naics dropdown, multi-select,
                and project select. For a multi-select example:
                <pre>
                  <code>{`
{
  "type": "array",
  "label": "Services Offered",
  "inputType": "multiSelect",
  "items": [
    {"label": "Consulting", "value": "consulting"},
    {"label": "Development", "value": "development"}
  ],
  "required": true,
  "tooltip": "Select all services that your company offers."
}
      `}</code>
                </pre>
              </li>
            </ul>
            <p>
              Each field can include properties such as <code>label</code>,{" "}
              <code>required</code>, <code>description</code>,and{" "}
              <code>tooltip</code> for additional context or validation.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StyleGuideModal;
