import React, { useState, useEffect } from "react";

import {
  resetPasswordAdminUser,
  resetInvitationAdminUser,
  confirmUserEmail,
} from "modules/users";

const EditUserSecurity = ({ user }) => {
  const resendInvitation = (e) => {
    e.preventDefault();

    resetInvitationAdminUser(user.id)
      .then((response) => {
        const go_to = `/admin/users/${user.id}/security`;
        window.location = go_to;
      })
      .catch((error) => {});
  };

  const resetPassword = (e) => {
    e.preventDefault();

    resetPasswordAdminUser(user.id)
      .then((response) => {
        const go_to = `/admin/users/${user.id}/security`;
        window.location = go_to;
      })
      .catch((error) => {});
  };

  const confirmEmail = (e) => {
    e.preventDefault();

    confirmUserEmail(user.id)
      .then((response) => {
        const go_to = `/admin/users/${user.id}/security`;
        window.location = go_to;
      })
      .catch((error) => {});
  };

  const renderIpHistory = () => {
    if (user.is_invited) return null;

    return (
      <div className="mt-5">
        <h3>Login History</h3>
        <hr />

        <table className="table">
          <thead>
            <tr>
              <th>Sign-In Count</th>
              <th>Last Sign-In</th>
              <th>Current Sign-In</th>
              <th>Last Sign-In IP</th>
              <th>Current Sign-In IP</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{user.sign_in_count}</td>
              <td>{user.last_sign_in_at_formatted}</td>
              <td>{user.current_sign_in_at_formatted}</td>
              <td>
                <span className="badge rounded-pill bg-light text-dark">
                  {user.last_sign_in_ip}
                </span>
              </td>
              <td>
                <span className="badge rounded-pill bg-light text-dark">
                  {user.current_sign_in_ip}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <h3>{`${user.first_name} Security`}</h3>
      <hr />
      {!user.email_is_confirmed ? (
        <button className="btn btn-action" onClick={confirmEmail}>
          {`Manually confirm ${user.email} as ${user.first_name}'s correct email
          address`}
        </button>
      ) : null}
      <p />
      {user.is_invited ? (
        <button className="btn btn-action" onClick={resendInvitation}>
          {`${user.first_name} has not signed in yet. Email another invitation to ${user.email}`}
        </button>
      ) : (
        <button className="btn btn-action" onClick={resetPassword}>
          {`Send a password reset to ${user.email}`}
        </button>
      )}
      {renderIpHistory()}
    </div>
  );
};

export default EditUserSecurity;
