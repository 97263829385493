import React from "react";
import logo from "images/subcity-icon-white.png";
import SectionItem from "./section-item";

const SectionsNav = (props) => {
  let sections = props.sections.map((section, index) => {
    return (
      <SectionItem
        isComplete={props.currentSection > index}
        isActive={props.currentSection === index}
        section={section}
        key={`section-item-${index}`}
      />
    );
  });

  const today = new Date();

  return (
    <nav className="sections-nav position-relative">
      <a href="/">
        <img src={logo} className="logo-icon-tl" />
      </a>
      <div className="sections-container">
        <ul className="section-list-items mt-6">{sections}</ul>
      </div>

      <div className="sections-footer position-absolute bottom-0">
        <p className="mb-5 copyright">
          © {today.getFullYear()} Subcity, Inc. All rights reserved
        </p>
      </div>
    </nav>
  );
};

export default SectionsNav;
