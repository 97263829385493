import React, { useEffect, useState } from "react";
import { UILabelInput } from "components/ui";
import { State } from "country-state-city";

const AddressInput = ({ onAddressAdd, editAddress }) => {
  const [address, setAddress] = useState(editAddress);

  const [formErrors, setFormErrors] = useState({
    address_line_1: "",
    city: "",
    state: "",
    postal_code: "",
  });

  const submitForm = () => {
    if (validateForm()) {
      onAddressAdd(address);
    }
  };

  const validateForm = () => {
    let valid = true;
    let address_line_1 = "";
    let state = "";
    let city = "";
    let postal_code = "";
    if (address.address_line_1 === "") {
      valid = false;
      address_line_1 = "can't be blank";
    }
    if (address.state === "") {
      valid = false;
      state = "can't be blank";
    }
    if (address.city === "") {
      valid = false;
      city = "can't be blank";
    }
    if (address.postal_code === "") {
      valid = false;
      postal_code = "can't be blank";
    }
    setFormErrors({
      address_line_1,
      city,
      state,
      postal_code,
    });
    return valid;
  };

  useEffect(() => {
    setAddress(editAddress);
  }, [editAddress]);

  const onFieldChange = (value, type) => {
    setAddress({
      ...address,
      [type]: value,
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            label="Address Line 1"
            value={address.address_line_1}
            error={formErrors.address_line_1}
            onChange={(value) => {
              onFieldChange(value, "address_line_1");
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            label="Address Line 2"
            value={address.address_line_2}
            onChange={(value) => {
              onFieldChange(value, "address_line_2");
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            label="City"
            error={formErrors.city}
            value={address.city}
            onChange={(value) => {
              onFieldChange(value, "city");
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 mb-3">
          <strong>
            <label htmlFor="state" className="mb-1">
              State
            </label>
          </strong>
          <select
            name="state"
            className="form-control"
            value={address.state}
            onChange={(e) => {
              onFieldChange(e.target.value, "state");
            }}
          >
            {State.getStatesOfCountry("US").map((state) => (
              <option key={state.name} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
          {formErrors.state !== "" && (
            <span className="error">{formErrors.state}</span>
          )}
        </div>
        <div className="col-md-8 mb-3">
          <UILabelInput
            label="Postal Code"
            value={address.postal_code}
            error={formErrors.postal_code}
            onChange={(value) => {
              onFieldChange(value, "postal_code");
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-3">
          <button className="btn-attach" onClick={() => submitForm(address)}>
            Add Location
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddressInput;
