import React from 'react';

import { getLastFileResourceByName } from 'lib/utils'
import { parseAddress } from 'lib/string'
import Header from '../../common/header'

const Review = (props) => {
  const renderApplications = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="panel-table">
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th>Address</th>
                    <th>Potential 5-Year Savings</th>
                    <th>Status</th>
                    <th>Application Form</th>
                    <th>Information Form</th>
                    <th>Supporting Document(s)</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    props.claimedApplications.map((application, index) => {
                      !application.details ? application.details = {} : null // Deals with case of no JSONB data whatsoever

                      let application_form = getLastFileResourceByName(application, 'pge_edr_application.pdf');
                      application.application_form_url = application_form ? application_form.url : null;

                      let information_form = getLastFileResourceByName(application, 'pge_edr_information.pdf');
                      application.information_form_url = information_form ? information_form.url : null;

                      return (
                        <tr className="application-tr" key={`application-row-${index}`}>
                          <td>{parseAddress(application.company_facilities[0].address)}</td>
                          <td>{application.total}</td>
                          <td>
                            <span className="badge rounded-pill bg-primary">{application.status}</span>
                          </td>
                          <td>{application.application_form_url ?
                            <a 
                              className="btn btn-dark btn-sm btn-rounded"
                              name={`application_form_${application.id}`}
                              href={application.application_form_url}
                              target="_blank"
                            >
                              <i className="me-1 bi bi-file-earmark-pdf" style={{'fontSize': '20px'}}></i>
                              Download
                            </a>
                            : "In Progress"}
                          </td>
                          <td>{application.information_form_url ?
                            <a 
                              className="btn btn-dark btn-sm btn-rounded"
                              name={`application_form_${application.id}`}
                              href={application.information_form_url}
                              target="_blank"
                            >
                              <i className="me-1 bi bi-file-earmark-pdf" style={{'fontSize': '20px'}}></i>
                              Download
                            </a>
                            : "In Progress"}
                          </td>
                          <td> - </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Header name={props.incentive.name} eligible={props.totalEligible} claimed={props.totalClaimed} />
      <div className='panel mt-3'>
        <div className='row'>
          <div className='col-md-12'>
            <div className="col-md-8 p-4">
              <h2 className='mt-0 font-weight-boldest'>
                Your Applications
              </h2>

              <p className="my-3">
                Here’s a summary of the facilities for which you’ve completed
                the forms for PG&E's Economic Development Rate Discount. You
                can always view the status of your claims and access documents here.
              </p>
            </div>

            { renderApplications() }
          </div>
        </div>
      </div>
    </>
  )
}

export default Review;
