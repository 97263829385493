import React, { useState, useEffect, useRef } from "react";
import { useSetState } from "react-hanger";
import {
  deleteCompanyFileResource,
  searchCompanyFileResource,
  updateCompanyFileResource,
} from "modules/companies";
import { flashSuccess, flashError } from "lib/flash";
import Dropzone from "react-dropzone";
import { Modal } from "react-bootstrap";

import FileCategorySelector from "components/common/file-category-selector";
import FilePreviewModal from "./file-preview-modal";
import FileResourceRow from "./file-resource-row";

import {
  UploadingInProgress,
  UIFormLabel,
  withUIPaginate,
  UIPaginationTotalSelector,
  UIPagination,
  KeywordSearch,
} from "components/ui";

const CompanyFileResources = (props) => {
  const { pagination } = props;
  const [company, setCompany] = useState(props.company);
  const [companyFileResources, setCompanyFileResources] = useState(
    company.file_resources
  );

  const [attachedFiles, setAttachedFiles] = useState([]);
  const [showUploadFiles, setShowUploadFiles] = useState(false);
  const [showUploadingInProgress, setShowUploadingInProgress] = useState(false);
  const [showModal, setModalShow] = useState(false);
  const [editFile, setEditFile] = useState({});

  const [previewResourceIndex, setPreviewResourceIndex] = useState(0);
  const [previewResource, setPreviewResource] = useState({});
  const [showFilePreview, setShowFilePreview] = useState(false);

  function selectPrevFile(e) {
    e.preventDefault();
    const prevIndex = previewResourceIndex - 1;

    if (prevIndex < 0) {
      return;
    }

    setPreviewResourceIndex(prevIndex);
    setPreviewResource(companyFileResources[prevIndex]);
  }

  function selectNextFile(e) {
    e.preventDefault();

    const nextIndex = previewResourceIndex + 1;
    if (nextIndex >= companyFileResources.length) {
      return;
    }

    setPreviewResourceIndex(nextIndex);
    setPreviewResource(companyFileResources[nextIndex]);
  }

  function setPreviewFile(resource, index) {
    setPreviewResource(resource);
    setPreviewResourceIndex(index);
    setShowFilePreview(true);
  }

  function clearPreviewFile() {
    setPreviewResource({});
    setShowFilePreview(false);
    setPreviewResourceIndex(0);
  }

  const statuses = [
    { value: "processing", label: "Processing" },
    { value: "completed", label: "Completed" },
  ];

  let searchTimeOut = null;

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const { state, setState } = useSetState({
    query: "",
  });

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    fetchFileResource({ ...state, ...pagination });
  }, [state]);

  useEffect(() => {
    if (pagination.first_load) return;

    fetchFileResource({ ...state, ...pagination });
  }, [pagination]);

  const fetchFileResource = (params = {}) => {
    searchCompanyFileResource({ ...params, id: company.id }).then((data) => {
      props.updatePagination({
        total: data.pagination.total,
        first_load: true,
      });

      setCompanyFileResources(data.file_resources.file_resources);
    });
  };

  const uploadFileResource = (files) => {
    setAttachedFiles(files);
    setShowUploadFiles(false);
    setShowUploadingInProgress(true);
  };

  const deleteFileResource = (resourceId) => {
    if (!confirm("Are you sure you want to delete this file?")) {
      return true;
    }

    const params = {
      resource_id: resourceId,
      id: company.id,
    };

    deleteCompanyFileResource(params).then((data) => {
      flashSuccess("File Deleted.");
      props.updatePagination({
        total: data.pagination.total,
        first_load: true,
      });

      setCompanyFileResources(data.file_resources.file_resources);
    });
  };

  const updateFileResource = (resource, status) => {
    const params = {
      resource_id: resource.id,
      file_resource: {
        status: status,
      },
      id: company.id,
    };

    if (resource.file_category_id && parseInt(resource.file_category_id) > 0) {
      params.file_resource.file_category_id = resource.file_category_id;
    }

    updateCompanyFileResource(params).then((data) => {
      flashSuccess("File Updated.");

      props.updatePagination({
        total: data.pagination.total,
        first_load: true,
      });

      setCompanyFileResources(data.file_resources.file_resources);
    });
  };

  const UpdateFileResourceModal = () => {
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Update File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-10 mb-4">
            <UIFormLabel label="Status" />
            <select
              id="file_status"
              className="form-control"
              defaultValue={editFile.status}
            >
              {statuses.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>

            <UIFormLabel label="File Category" />
            <FileCategorySelector
              file_category={editFile.file_category_id}
              selectFileCategory={(o) => {
                setEditFile({
                  ...editFile,
                  file_category_id: o,
                });
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-between">
          <button className="float-start btn btn-light" onClick={handleClose}>
            Close
          </button>
          <button
            className="float-end btn btn-dark"
            onClick={() => {
              updateFileResource(editFile, file_status.value);
              handleClose();
            }}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const fileResourcesTable = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-8">
            <KeywordSearch
              state={state}
              setState={setState}
              updatePagination={props.updatePagination}
            />
          </div>
          <div className="col-md-2">
            <input
              id="backButton"
              name="file_resource_table"
              className="form-control d-none"
              onClick={(e) => {
                e.preventDefault();
                setShowUploadFiles(true);
                setShowUploadingInProgress(false);
              }}
            />
            <label
              htmlFor="backButton"
              id="file_resource_table"
              className="btn btn-primary"
            >
              Upload
            </label>
          </div>
        </div>

        <div className="table-responsive mt-3">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="col-md-6">Name</th>
                <th>Size</th>
                <th>Owner</th>
                <th>Modified</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {companyFileResources &&
                companyFileResources.map((resource, index) => {
                  return (
                    <FileResourceRow
                      key={`company-file-resources-row-${index}`}
                      resource={resource}
                      index={index}
                      setPreviewFile={setPreviewFile}
                      setEditFile={setEditFile}
                      setModalShow={setModalShow}
                      deleteFileResource={deleteFileResource}
                      isAdmin={props.isAdmin}
                    />
                  );
                })}
              <tr>
                <td colSpan={2}>
                  <div className="mt-2">
                    <UIPaginationTotalSelector
                      perPage={pagination.per_page}
                      changePerPage={(value) => {
                        props.updatePagination({
                          per_page: value,
                          first_load: false,
                        });
                      }}
                    />
                  </div>
                </td>

                <td colSpan={5}>
                  <span className="float-end mt-2">
                    {pagination.total > 0 ? (
                      <UIPagination
                        page={pagination.page}
                        perPage={pagination.per_page}
                        changePage={(page) => {
                          props.updatePagination({
                            page: page.selected + 1,
                            first_load: false,
                          });
                        }}
                        total={pagination.total}
                      />
                    ) : null}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <UpdateFileResourceModal />
      </div>
    );
  };

  const uploadFiles = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <h3>
              <strong>Upload Files</strong>
            </h3>
          </div>
          <div className="col-md-6">
            <input
              id="backButton"
              name="file_resource_table"
              className="form-control d-none"
              onClick={(e) => {
                e.preventDefault();
                setShowUploadFiles(false);
              }}
            />
            <label
              htmlFor="backButton"
              id="file_resource_table"
              className="btn btn-light float-end"
            >
              Back
            </label>
          </div>
        </div>

        <hr />

        <Dropzone onDrop={(acceptedFiles) => uploadFileResource(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section className="dropzone">
              <div className="dropzone-button" {...getRootProps()}>
                <input {...getInputProps()} />
                <h6>
                  <strong>
                    Drag your files (spreadsheets, PDFs, images, etc.) here to
                    begin uploading.
                  </strong>
                </h6>
                <div className="row">
                  <div className="col-md-5">
                    <hr />
                  </div>
                  <div className="col-md-2">OR</div>
                  <div className="col-md-5">
                    <hr />
                  </div>
                </div>
                <div className="col-md-12">
                  <input
                    type="file"
                    id="uploadButton"
                    name="upload_file_resource"
                    className="form-control d-none"
                    onChange={(e) => {
                      e.preventDefault();
                      uploadFileResource(e.target.files);
                    }}
                  />
                  <label
                    htmlFor="uploadButton"
                    id="upload_file_resource"
                    className="btn btn-danger"
                  >
                    Browse Files
                  </label>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  };

  const uploadingInProgressView = (files) => {
    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <h3>
              <strong>Upload Files</strong>
            </h3>
          </div>
          <div className="col-md-6">
            <input
              id="backButton"
              name="file_resource_table"
              className="form-control d-none"
              onClick={(e) => {
                e.preventDefault();
                setShowUploadFiles(false);
                setShowUploadingInProgress(false);
              }}
            />
            <label
              htmlFor="backButton"
              id="file_resource_table"
              className="btn btn-light float-end"
            >
              Back
            </label>
          </div>
        </div>

        <hr />

        {files &&
          files.map((file, index) => (
            <UploadingInProgress
              file={file}
              index={index}
              company={company}
              filesLength={files.length}
              onUploadResponse={setCompanyFileResources}
              isAdmin={props.isAdmin}
            />
          ))}
      </div>
    );
  };

  return (
    <div>
      {!showUploadFiles && !showUploadingInProgress && fileResourcesTable()}
      {showUploadFiles && uploadFiles()}
      {showUploadingInProgress && uploadingInProgressView(attachedFiles)}
      <FilePreviewModal
        resource={previewResource}
        show={showFilePreview}
        close={clearPreviewFile}
        selectPrevFile={selectPrevFile}
        selectNextFile={selectNextFile}
      />
    </div>
  );
};

export default withUIPaginate(CompanyFileResources);
