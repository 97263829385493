import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import moment from "moment";

import TextareaAutosize from "react-textarea-autosize";
import CurrencyInput from "react-currency-input-field";

import { UIColLabelInput } from "components/ui";
import FillableAddressSection from "./fillable-address-section";

const EquipmentForm = ({
  currentEquipment,
  setCurrentEquipment,
  onSubmit,
  buttons,
  constraints,
}) => {
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const DATE_FORMAT = "YYYY-MM-DD";
  const AVERAGE_CA_SALES_TAX = 0.0725;
  const REBATE_PROPORTION = 0.039375;

  // TODO: Consolidate the next two functions.

  const isMinSalesTaxClaimed = (equipment) => {
    const sales_tax_paid = parseFloat(equipment.sales_tax_paid || 0);
    const equipment_price = parseFloat(equipment.total_paid || 0);

    if (!submitAttempted && (sales_tax_paid == 0 || equipment_price == 0)) {
      return false;
    }

    return (
      sales_tax_paid / (equipment_price - sales_tax_paid) < AVERAGE_CA_SALES_TAX
    );
  };

  const isValidSalesTaxClaimed = (equipment) => {
    const sales_tax_paid = parseFloat(equipment.sales_tax_paid || 0);
    const equipment_price = parseFloat(equipment.total_paid || 0);
    if (sales_tax_paid === 0 || equipment_price === 0) return false;

    return (
      sales_tax_paid / (equipment_price - sales_tax_paid) > AVERAGE_CA_SALES_TAX
    );
  };

  const isValidEquipmentPrice = (equipment) => {
    const totalPaid = parseFloat(equipment.total_paid || 0);
    return totalPaid >= constraints.total_paid_min;
  };

  const handleSelect = (e) => {
    setCurrentEquipment({
      ...currentEquipment,
      qualified_use_code: e.target.value,
    });
  };

  const requiredFieldText = () => {
    if (submitAttempted)
      return <span className="text-danger">This is a required field.</span>;
  };

  const requiredQualifiedUseText = () => {
    if (submitAttempted)
      return (
        <span className="text-danger">
          The item needs to have been used for one of the four purposes.
        </span>
      );
  };

  const isFormValid = () => {
    return (
      currentEquipment.sales_tax_paid &&
      isValidSalesTaxClaimed(currentEquipment) &&
      isValidEquipmentPrice(currentEquipment) &&
      currentEquipment.acquisition_date &&
      currentEquipment.seller &&
      currentEquipment.seller.name &&
      currentEquipment.seller.address_line_1 &&
      currentEquipment.seller.city &&
      currentEquipment.seller.state &&
      currentEquipment.seller.postal_code &&
      currentEquipment.description &&
      currentEquipment.purchaser &&
      currentEquipment.purchaser.name &&
      currentEquipment.purchaser.address_line_1 &&
      currentEquipment.purchaser.city &&
      currentEquipment.purchaser.state &&
      currentEquipment.purchaser.postal_code &&
      currentEquipment.qualified_use_code &&
      currentEquipment.qualified_use_code !== "None of these." &&
      currentEquipment.capitalized
    );
  };

  const purchaser = currentEquipment.purchaser || {};
  const seller = currentEquipment.seller || {};

  let eligible_amount =
    (currentEquipment.total_paid - currentEquipment.sales_tax_paid) *
    REBATE_PROPORTION;

  currentEquipment.capitalized = currentEquipment.capitalized || false; // Don't need to useState as it's default is unchecked, which is true if nil or false.

  return (
    <div className="application-form-container px-1 py-3">
      <form
        noValidate
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitAttempted(true);
          if (isFormValid()) onSubmit();
        }}
      >
        <h3 className="panel-title mt-4 mb-4">Add Purchase</h3>

        <div className="row">
          <div className="col-md-6">
            <OverlayTrigger
              overlay={
                <Tooltip>
                  This field isn't required but may be a helpful reference for
                  you.
                </Tooltip>
              }
            >
              <div className="row mb-4">
                <UIColLabelInput
                  label="Invoice Number"
                  value={currentEquipment.purchase_order || ""}
                  onChange={(value) => {
                    setCurrentEquipment({
                      ...currentEquipment,
                      purchase_order: value,
                    });
                  }}
                />
              </div>
            </OverlayTrigger>
            <div className="row mb-4">
              <Form.Label htmlFor="date" className="col-sm-3 mb-1">
                <strong>Date</strong>
                <span className="panel-form-required">*</span>
              </Form.Label>
              <div className={`col-sm-9`}>
                <Form.Control
                  type="date"
                  id="date"
                  required={true}
                  value={
                    currentEquipment.acquisition_date
                      ? moment(currentEquipment.acquisition_date).format(
                          DATE_FORMAT
                        )
                      : ""
                  }
                  min={moment(constraints.acquisition_date_earliest).format(
                    DATE_FORMAT
                  )}
                  max={
                    moment().format(
                      DATE_FORMAT
                    ) /* No later than today's date. */
                  }
                  onChange={(e) => {
                    setCurrentEquipment({
                      ...currentEquipment,
                      acquisition_date: moment(e.target.value).format(
                        DATE_FORMAT
                      ),
                    });
                  }}
                ></Form.Control>
                {!currentEquipment.acquisition_date && requiredFieldText()}
              </div>
            </div>

            <OverlayTrigger
              overlay={
                <Tooltip>
                  This should be the total taxable amount paid, with the
                  addition of tax, for the eligible item only.
                </Tooltip>
              }
            >
              <div className="row mb-4">
                <label className="col-sm-3 pe-0">
                  <strong>Total Price (Including Tax)</strong>
                  <span className="panel-form-required">*</span>
                </label>

                <div className={`col-sm-9`}>
                  <CurrencyInput
                    id="total-paid-input"
                    className="form-control"
                    placeholder="0.00"
                    prefix="$"
                    defaultValue={currentEquipment.total_paid || 0}
                    decimalsLimit={2}
                    onValueChange={(value) => {
                      setCurrentEquipment({
                        ...currentEquipment,
                        total_paid: value,
                      });
                    }}
                  />
                  {submitAttempted &&
                    !isValidEquipmentPrice(currentEquipment) && (
                      <span className="text-danger error mt-2">
                        The price must be positive.
                      </span>
                    )}
                </div>
              </div>
            </OverlayTrigger>
          </div>

          <div className="col-md-6">
            <OverlayTrigger
              overlay={
                <Tooltip>
                  Please enter a description of the item and a few words about
                  its use.
                </Tooltip>
              }
            >
              <div className="row mb-4">
                <label className="col-sm-3 pe-0">
                  <strong>Description</strong>
                  <span className="panel-form-required">*</span>
                </label>

                <div className={`col-sm-9`}>
                  <TextareaAutosize
                    type="text"
                    name="Description"
                    className="form-control"
                    placeholder=""
                    defaultValue={currentEquipment.description || ""}
                    onChange={(e) => {
                      setCurrentEquipment({
                        ...currentEquipment,
                        description: e.target.value,
                      });
                    }}
                  />
                  {!currentEquipment.description && requiredFieldText()}
                </div>
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  What is this equipment used for? If it's not used for one of
                  these four purposes, it won't be eligible for this incentive.
                </Tooltip>
              }
            >
              <div className="row">
                <label className="col-sm-3 pe-0">
                  <strong>Qualified tangible usage</strong>
                  <span className="panel-form-required">*</span>
                </label>

                <div className={`col-sm-9`}>
                  <select
                    defaultValue="Select option"
                    className="form-select form-select"
                    aria-label=".form-select-lg example"
                    onChange={handleSelect}
                  >
                    <option value="Select option" disabled>
                      Select option
                    </option>
                    <option value="For manufacturing, processing, refining, fabricating, or recycling.">
                      For manufacturing, processing, refining, fabricating, or
                      recycling.
                    </option>
                    <option value="For research and development.">
                      For research and development.
                    </option>
                    <option value="To maintain, repair, measure, or test any property being used in the previous options.">
                      To maintain, repair, measure, or test any property being
                      used in the previous options.
                    </option>
                    <option value="As a special-purpose building and/or foundation.">
                      As a special-purpose building and/or foundation.
                    </option>
                    <option value="None of these.">None of these.</option>
                  </select>
                  {(!currentEquipment.qualified_use_code ||
                    currentEquipment.qualified_use_code === "None of these.") &&
                    requiredQualifiedUseText()}
                </div>
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  We need to know precisely how much sales tax was paid on this
                  item. It should be available on the invoice, or via the
                  vendor.
                </Tooltip>
              }
            >
              <div className="row mb-4">
                <label className="col-sm-3 pe-0">
                  <strong>Sales Tax Paid</strong>
                  <span className="panel-form-required">*</span>
                </label>

                <div className={`col-sm-9`}>
                  <CurrencyInput
                    id="sales-tax-input"
                    className="form-control"
                    placeholder="0.00"
                    prefix="$"
                    defaultValue={currentEquipment.sales_tax_paid || 0}
                    decimalsLimit={2}
                    onValueChange={(value) => {
                      setCurrentEquipment({
                        ...currentEquipment,
                        sales_tax_paid: value,
                      });
                    }}
                  />
                  {isMinSalesTaxClaimed(currentEquipment) && (
                    <span className="text-danger error mt-2">
                      Sales Tax Exemption already claimed.
                    </span>
                  )}
                  {eligible_amount > 0 &&
                    currentEquipment.total_paid > 0 &&
                    currentEquipment.sales_tax_paid > 0 &&
                    !isMinSalesTaxClaimed(currentEquipment) && (
                      <span>
                        Eligible for <b>${eligible_amount.toFixed(2)} </b>
                        exemption.
                      </span>
                    )}
                </div>
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={
                <Tooltip>
                  Items are only eligible for this incentive if they were
                  capitalized and have a useful life of more than one year. This
                  includes small items used as repair parts for eligible larger
                  items.
                </Tooltip>
              }
            >
              <div className="row mb-4">
                <div className={`col-sm-12`}>
                  <label htmlFor="capitalized">
                    <input
                      type="checkbox"
                      id="capitalized"
                      className="company-admin-checkbox me-2"
                      checked={currentEquipment.capitalized}
                      onChange={() => {
                        setCurrentEquipment({
                          ...currentEquipment,
                          capitalized: currentEquipment.capitalized
                            ? false
                            : true,
                        });
                      }}
                    />
                    This item was capitalized and depreciated on our state
                    income or franchise tax returns—and has a useful life of
                    more than one year.
                  </label>
                  {submitAttempted && !currentEquipment.capitalized && (
                    <span className="text-danger error mt-2">
                      <br />
                      The item must have been capitalized in order to be
                      eligible.
                    </span>
                  )}
                </div>
              </div>
            </OverlayTrigger>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-6">
            <div className="ui-label-input">
              <FillableAddressSection
                title="Purchaser"
                field="purchaser"
                currentEquipment={currentEquipment}
                setCurrentEquipment={setCurrentEquipment}
                requiredFieldText={requiredFieldText}
              />
            </div>
            <div className="row mb-4">
              <UIColLabelInput
                required
                label="Email"
                value={purchaser.email}
                error={!purchaser.email ? requiredFieldText() : ""}
                onChange={(value) => {
                  setCurrentEquipment({
                    ...currentEquipment,
                    purchaser: { ...purchaser, email: value },
                  });
                }}
              />
            </div>
            <div className="row mb-4">
              <UIColLabelInput
                required
                label="Phone Number"
                value={purchaser.phone_number}
                error={!purchaser.phone_number ? requiredFieldText() : ""}
                onChange={(value) => {
                  setCurrentEquipment({
                    ...currentEquipment,
                    purchaser: { ...purchaser, phone_number: value },
                  });
                }}
              />
            </div>
            <div className="row mb-4">
              <UIColLabelInput
                required
                label="Job Title"
                value={purchaser.title}
                error={!purchaser.title ? requiredFieldText() : ""}
                onChange={(value) => {
                  setCurrentEquipment({
                    ...currentEquipment,
                    purchaser: { ...purchaser, title: value },
                  });
                }}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="ui-label-input">
              <FillableAddressSection
                title="Vendor"
                field="seller"
                currentEquipment={currentEquipment}
                setCurrentEquipment={setCurrentEquipment}
                requiredFieldText={requiredFieldText}
              />
            </div>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  The vendor's email isn't required but may be helpful in
                  ensuring you receive your check.
                </Tooltip>
              }
            >
              <div className="row mb-4">
                <UIColLabelInput
                  label="Email"
                  value={seller.email || ""}
                  onChange={(value) => {
                    setCurrentEquipment({
                      ...currentEquipment,
                      seller: { ...seller, email: value },
                    });
                  }}
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-12">
            <div className="btn-group float-end">{buttons}</div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EquipmentForm;
