import React, { useState, useEffect } from "react";
import { getCompanyProjectQuote } from "modules/project";

const ProjectQuoteFooter = (props) => {
  const [companyProjectQuote, setCompanyProjectQuote] = useState(null);

  useEffect(() => {
    getCompanyProjectQuote(props.company_project_id).then((data) => {
      if (data) {
        setCompanyProjectQuote(data);
      }
    });
  }, [props.company_project_id]);

  const renderCompanyProjectQuoteParameter = () => {
    if (!companyProjectQuote) return null;

    const { company_project_quote_parameter } = companyProjectQuote;

    if (!company_project_quote_parameter) return null;

    return Object.entries(company_project_quote_parameter)
      .filter(
        ([key, value]) => key !== "primary_color" && key !== "accent_color"
      )
      .map(([key, value]) => {
        return value ? (
          <div key={key}>
            <p>{value}</p>
          </div>
        ) : null;
      });
  };

  const primaryColor =
    companyProjectQuote?.company_project_quote_parameter?.primary_color ||
    "#1D3360";
  const accentColor =
    companyProjectQuote?.company_project_quote_parameter?.accent_color ||
    "#E22947";

  return (
    <div style={{ color: primaryColor }}>
      <div className="row mt-6 mb-3">
        <div className="col-md-8">{renderCompanyProjectQuoteParameter()}</div>
      </div>
      <div className="signature-section">
        <span className="signature-label">Signature:</span>
        <div className="signature-line"></div>
        <span className="signature-label">Date:</span>
        <div className="signature-line"></div>
      </div>
    </div>
  );
};

export default ProjectQuoteFooter;
