import React, { useState, useEffect, PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const FinancialDataOverview = ({financialData }) => {

  let balanceSheetsData = []

  financialData[0].forEach((element, index) => {
    balanceSheetsData.push({
      name: financialData[0][index]["date"],
      assets: financialData[0][index]["assets"],
      liabilities: financialData[0][index]["liabilities"],
      equity: financialData[0][index]["equity"],
    });
  });

  return (
    <div>
      <div className="panel-header pb-3 ps-0 pt-0">
        <div className="row">
          <h1>Financial Overview</h1>
          <hr />
        </div>
      </div>

      <div>
        <div className="d-flex row home-dashboard">
          <h2>Balance Sheets</h2>
          <div>
            <BarChart
              // https://recharts.org/en-US/examples/MixBarChart
              width={500}
              height={300}
              data={balanceSheetsData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                label={{
                  value: "Date",
                  position: "bottom",
                  offset: -10,
                }}
              />
              <YAxis
                label={{
                  value: "Amount ($)",
                  angle: -90,
                  position: "left",
                  offset: 0,
                }}
              />
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
              <Bar dataKey="assets" fill="#2B71F4" name="Assets" />
              <Bar
                dataKey="liabilities"
                stackId="a"
                fill="#8884d8"
                name="Liabilities"
              />
              <Bar dataKey="equity" stackId="a" fill="#82ca9d" name="Equity" />
            </BarChart>
          </div>
          <br></br>
          <br></br>
          Latest data from {financialData[0][0]["date"]}
          <br></br>- Assets: $
          {parseFloat(financialData[0][0]["assets"]).toLocaleString("en-US")}
          <br></br>- Liabilities: $
          {parseFloat(financialData[0][0]["liabilities"]).toLocaleString(
            "en-US"
          )}
          <br></br>- Equity: $
          {parseFloat(financialData[0][0]["equity"]).toLocaleString("en-US")}
          <hr></hr>
          <h2>
            Latest Income Statement ({financialData[1][0]["start_date"]} -{" "}
            {financialData[1][0]["end_date"]})
          </h2>
          Gross Profit: $
          {parseFloat(financialData[1][0]["gross_profit"]).toLocaleString(
            "en-US"
          )}
          <br></br>
          Net Operating Profit: $
          {parseFloat(
            financialData[1][0]["net_operating_profit"]
          ).toLocaleString("en-US")}
          <br></br>
          Net Other Income: $
          {parseFloat(financialData[1][0]["net_other_income"]).toLocaleString(
            "en-US"
          )}
          <br></br>
          Net Profit: $
          {parseFloat(financialData[1][0]["net_profit"]).toLocaleString(
            "en-US"
          )}
          <hr></hr>
          <h2>
            Latest Cash Flow Statement ({financialData[2][0]["start_date"]} -{" "}
            {financialData[2][0]["end_date"]})
          </h2>
          Cash Receipts: $
          {parseFloat(financialData[2][0]["cash_receipts"]).toLocaleString(
            "en-US"
          )}
          <br></br>
          Cash Payments: $
          {parseFloat(financialData[2][0]["cash_payments"]).toLocaleString(
            "en-US"
          )}
        </div>
      </div>
    </div>
  );
};

export default FinancialDataOverview;