import React from "react";
import { useSetState } from "react-hanger";
import { flashError, flashSuccessLater } from "../../../../lib/flash";
import { updateAdminQualification } from "../../../../modules/qualifications";
import { UILabelInput, UIFormLabel } from "../../../ui";
import MultipleChoiceAnswers from "./multiple-choice-answers";

const EditQualification = ({ qualification }) => {
  const answerTypes = [
    { value: "multiple_choice", label: "Multiple Choice" },
    { value: "short_text", label: "Short Text" },
  ];

  const { state: variables, setState: setVariables } = useSetState({
    name: qualification.name,
    question_title: qualification.question_title,
    qualification_context: qualification.qualification_context,
    category: qualification.category,
    level: qualification.level,
    answer_type: qualification.answer_type,
    answer_options: qualification.answer_options,
  });

  const onSaveQualification = () => {
    updateAdminQualification({
      qualification: {
        ...variables,
      },
      id: qualification.id,
    }).then((res) => {
      if (res.errors) res.errors.map(flashError);
      else {
        flashSuccessLater("Qualification updated");
        window.location.href = "/admin/qualifications";
      }
    });
  };

  return (
    <div className="panel-body admin-company-form">
      <div className="row">
        <div className="col-lg-5">
          <h3 className="my-0">Edit Qualification</h3>
          <div className="mb-3">
            <UILabelInput
              value={variables.question_title}
              label="Question Copy"
              onChange={(value) => setVariables({ question_title: value })}
              tooltip="This should be structured as a question that would be directly asked in a conversation."
              charLimit={140}
            />
          </div>
          <div className="mb-3">
            <UILabelInput
              value={variables.name}
              label="Qualification Description"
              onChange={(value) => setVariables({ name: value })}
              tooltip="This is the description of the question as it may appear in a list of requirements."
            />
          </div>
          <div className="mb-3">
            <UILabelInput
              value={variables.category}
              label="Category"
              onChange={(value) => setVariables({ category: value })}
            />
          </div>
          <div className="mb-3">
            <label className="mb-1">
              <strong>Specificity Level</strong>
            </label>

            <select
              value={variables.level}
              className="form-control"
              onChange={(e) =>
                setVariables({ ...variables, level: e.target.value })
              }
            >
              <option value="1">1 - General</option>
              <option value="2">2 - Specific</option>
              <option value="3">3 - Highly Specific</option>
            </select>
          </div>
          <div className="mb-3">
            <UILabelInput
              value={variables.qualification_context}
              label="Qualification Context"
              tooltip="Text that will be placed alongside qualifications to guide the user."
              onChange={(value) =>
                setVariables({ qualification_context: value })
              }
              charLimit={280}
            />
          </div>
          <div className="mb-3">
            <label className="mb-1">
              <strong>Answer Type</strong>
            </label>

            <select
              value={variables.answer_type}
              className="form-control"
              onChange={(e) => setVariables({ answer_type: e.target.value })}
            >
              {answerTypes.map((answerType, index) => (
                <option key={index} value={answerType.value}>
                  {answerType.label}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            {variables.answer_type === "multiple_choice" && (
              <MultipleChoiceAnswers
                qualificationId={qualification.id}
                answerOptions={variables.answer_options}
                setAnswerOptions={(answerOptions) =>
                  setVariables({ answer_options: answerOptions })
                }
              />
            )}
          </div>

          <div className="mb-5 btn-group float-end">
            <a className="btn btn-light" href="/admin/qualifications">
              Back
            </a>

            <button className="btn btn-primary" onClick={onSaveQualification}>
              Save Qualification
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditQualification;
