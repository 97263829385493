import React, { useState, useRef } from "react";
import CurrencyInput from "react-currency-input-field";
import UIFormLabel from "./ui-form-label";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";

const UIMoneyLabelInput = (props) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <div className="ui-label-input">
      <div style={{ display: "flex", alignItems: "center" }}>
        <UIFormLabel label={props.label} required={props.required} />
        {props.context && (
          <i
            ref={target}
            onClick={() => setShow(!show)}
            className="bi bi-info-circle"
            style={{ cursor: "pointer", marginLeft: "4px" }}
          />
        )}
      </div>
      <CurrencyInput
        id={`${props.id}-money-input`}
        className="form-control"
        placeholder="$0"
        prefix="$"
        defaultValue={props.defaultValue}
        decimalsLimit={2}
        onValueChange={(value) => {
          props.onValueChange(value);
        }}
      />
      <Overlay target={target.current} show={show} placement="top">
        {(tooltipProps) => (
          <Tooltip
            id="overlay-example"
            {...tooltipProps}
            className="cashflow-tooltip"
          >
            {props.context}
          </Tooltip>
        )}
      </Overlay>
      {!!props.error && (
        <span className="d-block text-danger error mt-2">{props.error}</span>
      )}
    </div>
  );
};

export default UIMoneyLabelInput;
