import React, { Component } from "react";

import { createAdminIncentive } from "modules/incentives";
import { UIFormLabel, UITags } from "components/ui";
import IncentiveUrls from "./incentive-urls";
import OrganizationSelector from "../common/organization-selector";

import filter from "lodash/filter";
import TextareaAutosize from "react-textarea-autosize";
import { flashError, flashSuccessLater } from "lib/flash";

const testIncentive = {
  name: "Partial Sales Tax",
  description: "Test description",
  incentive_urls: [],
  tags: [],
  sponsoring_organization_id: null,
  approving_organization_id: null,
  status: "active",
};

const goToIncentivesPage = () => (window.location = "/admin/incentives");

export class NewIncentive extends Component {
  constructor(props) {
    super(props);

    this.setUrls = this.setUrls.bind(this);
    this.setTags = this.setTags.bind(this);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);

    this.state = {
      incentive: {
        name: "",
        description: "",
        incentive_urls: [],
        tags: [],
        sponsoring_organization_id: null,
        approving_organization_id: null,
        status: "active",
      },
    };
  }

  componentDidMount() {
    this.setState({
      incentive: {
        ...this.state.incentive,
        ...testIncentive,
      },
    });
  }

  handleChange(field, value) {
    this.setState({
      incentive: {
        ...this.state.incentive,
        [field]: value,
      },
    });
  }

  handleCancel(e) {
    e.preventDefault();
    window.location = `/admin/incentives`;
  }

  submit(e) {
    e.preventDefault();

    createAdminIncentive({
      incentive: this.state.incentive,
    })
      .then((response) => {
        flashSuccessLater("Incentive added");
        window.location = `/admin/incentives/${response.id}/edit`;
      })
      .catch((resp) => {
        resp.errors.map((error) => {
          flashError(error);
        });
        return Promise.resolve();
      });
  }

  setUrls(urls) {
    let incentiveUrls = filter(urls, (url) => url.url !== "");
    incentiveUrls = incentiveUrls.map((url) => ({
      url: url.url,
      label: url.label,
    }));

    this.setState({
      incentive: {
        ...this.state.incentive,
        incentive_urls: incentiveUrls,
      },
    });
  }

  setTags(tags) {
    this.setState({
      incentive: {
        ...this.state.incentive,
        tags: tags,
      },
    });
  }

  render() {
    const { incentive } = this.state;

    return (
      <div className="panel-body">
        <div className="row">
          <div className="col-md-12 mb-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex align-items-center">
                <i
                  className="bi bi-x"
                  onClick={goToIncentivesPage}
                  style={{
                    cursor: "pointer",
                    marginRight: "14px",
                    fontSize: "28px",
                  }}
                ></i>
                <h3>Create New Incentive</h3>
              </div>
            </div>
            <form className="form" onSubmit={this.submit}>
              <div className="card p-3 mt-1 mb-3 z-depth-2">
                <div className="row mt-1">
                  <h3 className="panel-title">Description</h3>
                </div>
                <div className="mb-3">
                  <UIFormLabel label="Name" />
                  <input
                    type="text"
                    name="Name"
                    className="form-control ui-label-input"
                    placeholder="CA Manufacturer Incentive"
                    value={incentive.name}
                    onChange={(e) => {
                      e.preventDefault();
                      this.handleChange("name", e.target.value);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <UIFormLabel label="Description" />
                  <TextareaAutosize
                    type="text"
                    name="Description"
                    className="form-control ui-label-input"
                    placeholder="Enter description here..."
                    value={incentive.description}
                    onChange={(e) => {
                      e.preventDefault();
                      this.handleChange("description", e.target.value);
                    }}
                  />
                </div>

                <div className="mb-3">
                  <UIFormLabel label="Short Description" />
                  <TextareaAutosize
                    type="text"
                    name="Short Description"
                    className="form-control"
                    placeholder="Short description for header content (255 char)..."
                    value={incentive.short_description}
                    onChange={(e) => {
                      e.preventDefault();
                      this.handleChange("short_description", e.target.value);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <UIFormLabel label="Tags" />
                  <UITags setTags={this.setTags} tags={incentive.tags} />
                </div>
              </div>
              <div className="card p-3 mt-1 mb-3 z-depth-2">
                <div className="row mt-1">
                  <h3 className="panel-title">Sponsors</h3>
                </div>
                <div className="mb-3">
                  <UIFormLabel label="URLs" />
                  <IncentiveUrls incentiveId={null} setUrls={this.setUrls} />
                </div>

                <div className="mb-3">
                  <UIFormLabel label="Sponsoring Organization" />
                  <OrganizationSelector
                    selectOrganization={(id) => {
                      this.handleChange("sponsoring_organization_id", id);
                    }}
                  />
                </div>

                <div className="mb-3">
                  <UIFormLabel label="Approving Organization" />
                  <OrganizationSelector
                    selectOrganization={(id) => {
                      this.handleChange("approving_organization_id", id);
                    }}
                  />
                </div>
              </div>

              <div className="float-end mt-3 btn-group">
                <button className="btn btn-light" onClick={this.handleCancel}>
                  Cancel
                </button>
                <button className="btn btn-dark">Continue</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default NewIncentive;
