import React, { useState, useEffect, useRef } from "react";
import { useSetState } from "react-hanger";
import { fetchAdminNotifications } from "modules/notifications";
import moment from "moment";
import { KeywordSearch } from "components/ui";
import AdminPanelHeader from "../../common/admin-panel-header";
import AdminPanelIndexControls from "../../common/admin-panel-index-controls";

const Notifications = (props) => {
  const [notifications, setNotifications] = useState(props.notifications);

  const { state, setState } = useSetState({
    query: "",
  });

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    fetchNotifications();
  }, [state]);

  const fetchNotifications = () => {
    const params = {
      ...state,
    };

    fetchAdminNotifications(params).then((data) => setNotifications(data));
  };

  return (
    <>
      <AdminPanelHeader
        title="Notifications"
        singularTitle="Notification"
        addNewUrl="/admin/notifications/new"
      />
      <AdminPanelIndexControls
        keywordSearchConfig={{
          state: state,
          setState: setState,
          updatePagination: undefined,
        }}
      />
      <div className="px-3">
        <div className="panel-table">
          <table className="table admin-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Status</th>
                <th>Updated</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {notifications.map((notification, index) => {
                return (
                  <tr key={`notification-row-${index}`}>
                    <td>{notification.id}</td>
                    <td>
                      <div className="user-info">
                        <strong>{notification.name}</strong>
                      </div>
                    </td>
                    <td>{notification.status}</td>
                    <td>
                      {notification.updated_at
                        ? moment(notification.updated_at).format("MM/DD/YYYY")
                        : null}
                    </td>
                    <td>
                      <a
                        href={`/admin/notifications/${notification.id}/edit`}
                        className="btn btn-sm btn-outline-secondary"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Notifications;
