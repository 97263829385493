import React from "react";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { displayPrice, displayPercent } from "lib/string";

const InvoiceRow = ({
  id,
  endpoint,
  customer_name,
  vendor_name,
  expense_account,
  company_accounts_chart_name,
  status,
  purchase_order_number,
  invoice_number,
  total_amount,
  amount_due,
  due_date,
  issue_date,
  terms,
  receivable_dollar_project_allocation,
  payable_dollar_project_allocation,
}) => {
  const allocationData =
    endpoint === "payables"
      ? payable_dollar_project_allocation
      : receivable_dollar_project_allocation;
  const totalAllocatedDollars = allocationData
    ? allocationData.reduce((sum, allocation) => {
        if (allocation && allocation.allocated_dollars) {
          return (
            sum +
            parseFloat(allocation.allocated_dollars.replace(/[^0-9.-]+/g, ""))
          );
        }
        return sum;
      }, 0)
    : 0;

  const projectNames = allocationData
    ? allocationData.map((project) => project.name).join(", ")
    : "";

  let statusClass;

  switch (status) {
    case "Projected":
      statusClass =
        "text-primary-emphasis bg-primary-subtle border border-primary-subtle";
      break;
    case "Paid":
      statusClass =
        "text-success-emphasis bg-success-subtle border border-success-subtle ";
      break;
    case "Open":
    case "Draft":
    case "Submitted":
      statusClass =
        "text-warning-emphasis bg-warning-subtle border border-warning-subtle ";
      break;
    default:
      statusClass =
        "text-light-emphasis bg-light-subtle border border-light-subtle";
  }

  return (
    <tr className="row-with-shadow">
      <td>
        {endpoint === "payables"
          ? expense_account
          : company_accounts_chart_name}
      </td>
      <td>{endpoint === "payables" ? vendor_name : customer_name}</td>
      <OverlayTrigger
        overlay={
          <Tooltip className="cashflow-tooltip">
            Projects: {projectNames}
          </Tooltip>
        }
      >
        <td>{displayPercent(totalAllocatedDollars / total_amount, 0)}</td>
      </OverlayTrigger>
      <td>{purchase_order_number}</td>
      <td>{invoice_number}</td>
      <td>
        <div className={`d-inline px-2 py-1 rounded-1 ${statusClass}`}>
          {status}
        </div>
      </td>
      <td>{displayPrice(total_amount)}</td>
      <td>{displayPrice(amount_due)}</td>
      <td>{issue_date ? moment(issue_date).format("MM/DD/YYYY") : ""}</td>
      <td>{due_date ? moment(due_date).format("MM/DD/YYYY") : ""}</td>
      <td>
        <a
          href={
            endpoint === "payables" ? `#/payables/${id}` : `#/receivables/${id}`
          }
          className="btn btn-muted"
        >
          Edit
        </a>
      </td>
    </tr>
  );
};

export default InvoiceRow;
