import React from "react";
import LineGraph from "components/ui/charts/line";
import CashSummary from "./cash-summary";
import { Dropdown, DropdownButton } from "react-bootstrap";

const CashBalanceProjection = (props) => {
  const startDate = new Date(props.data[0].date).toLocaleDateString();
  const endDate = new Date(props.data[91].date).toLocaleDateString();
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      className="btn btn-muted"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));
  let beginning_cash_balance = props.cash_balance;
  let total_receivables =
    props.incoming_receivables + props.projected_receivables;
  let total_payables = props.upcoming_payables + props.projected_payables;
  const ending_cash_balance =
    props.cash_balance + total_receivables - total_payables;

  let difference_percentage =
    ((ending_cash_balance - beginning_cash_balance) /
      Math.abs(beginning_cash_balance)) *
    100;

  let cash_trajectory;
  if (
    beginning_cash_balance < 0 &&
    ending_cash_balance < beginning_cash_balance
  ) {
    cash_trajectory = "Decrease";
  } else if (difference_percentage > 1.5) {
    cash_trajectory = "Increase";
  } else if (difference_percentage < -1.5) {
    cash_trajectory = "Decrease";
  } else {
    cash_trajectory = "Flat";
  }

  return (
    <div className="panel cash-balance-projection p-4">
      <div className="row align-items-center justify-content-between">
        <div className="col-auto">
          <h5 className="mb-2">
            Projected Cash Balance <strong>{cash_trajectory}</strong>
          </h5>
          <div>
            <p className="finance-context mb-0 mt-1">
              Note: ending cash balance assumes collection of past due A/R. View{" "}
              <a href="/invoices">invoices</a> to see more.
            </p>
          </div>
          {props.codat_last_synced && props.codat_provider && (
            <p className="finance-context">
              Updated via {props.codat_provider}:{" "}
              {moment(props.codat_last_synced).fromNow()}
            </p>
          )}
        </div>

        <div className="col-auto d-flex align-items-center">
          {props.actionButton}
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-toggle">
              <i className="bi bi-gear fs-5"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>{props.dropdownMenu}</Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <hr />

      <div className="row">
        <CashSummary
          cash_balance={props.cash_balance}
          incoming_receivables={props.incoming_receivables}
          projected_receivables={props.projected_receivables}
          upcoming_payables={props.upcoming_payables}
          projected_payables={props.projected_payables}
        />
      </div>
      <div className="row">
        <p className="secondary-text">
          Date range: {startDate} through {endDate}
        </p>
      </div>
      <div className="row">
        <div className="col-md-12">
          <LineGraph
            data={props.data}
            annotation_data={props.annotation_data}
            receivables_annotation_data={props.receivables_annotation_data}
            threshold={props.threshold}
            incoming_receivables={props.incoming_receivables}
            projected_receivables={props.projected_receivables}
            upcoming_payables={props.upcoming_payables}
            projected_payables={props.projected_payables}
            width={"100%"}
            height={"300px"}
          />
        </div>
      </div>
    </div>
  );
};

export default CashBalanceProjection;
