import React, { useEffect, useState } from "react";

import { useSetState } from "react-hanger";
import { find } from "lodash";
import ReactJson from "react-json-view";
import DatePicker from "react-datepicker";

import { UILabelInput } from "components/ui";
import { testEligibilityRule } from "modules/incentives";

import { flashError, flashSuccess } from "lib/flash";
import { isEmpty } from "lib/utils";

import { getRule, getQueryRule, getQueryGroup } from "./rule-testing-utils";

import { findByValue } from "./rule-utils";

import "react-datepicker/dist/react-datepicker.css";

const TestingRule = ({
  ruleBlock,
  fieldOptions,
  incentiveId,
  jsonRule,
  setJsonRule,
}) => {
  const [queryString, setQueryString] = useState("");
  const [fields, setFields] = useState([]);

  const { state: variables, setState: setVariables } = useSetState({
    employs_veterans: "true",
    employee_count: "0",
    primary_address_state: "",
    black_owned: "false",
    woman_owned: "false",
    date_established: "",
    naics_code: "",
    addresses: "",
    primary_address: "",
  });

  const getNestedRule = (object) => {
    if (!object || object.children == []) return null;

    if (object.type === "rule") {
      return getRule(object);
    }

    if (object.type === "group") {
      let children = object.children.map((rule) => getNestedRule(rule));
      return { [object.condition]: children };
    }
  };

  const getNestedQuery = (object) => {
    if (!object || object.children == []) return null;

    if (object.type === "rule") {
      return getQueryRule(object);
    }

    if (object.type === "group") {
      let children = object.children.map((rule) => getNestedQuery(rule));
      return getQueryGroup(object.condition, children);
    }
  };

  const onTestEligibilityRule = () => {
    testEligibilityRule({
      id: incentiveId,
      json_rule: jsonRule,
      variables: {
        ...variables,
        addresses:
          variables.addresses !== "" ? JSON.parse(variables.addresses) : "",
        primary_address:
          variables.primary_address !== ""
            ? JSON.parse(variables.primary_address)
            : "",
      },
    }).then((res) => {
      if (res.matched) flashSuccess("Rule matched");
      else flashError("Rule did not match");
    });
  };

  const getFields = (obj, fieldArray) => {
    if (obj.type === "rule")
      if (!find(fieldArray, { value: obj.field })) {
        fieldArray.push({ value: obj.field, type: obj.fieldType });
        return;
      }
    if (obj.type === "group") {
      obj.children.map((rule) => getFields(rule, fieldArray));
    }
  };

  const showFieldLabel = (value) => {
    const found = findByValue(fieldOptions, value);
    return found ? found.label : "Unknown Field Name";
  };

  useEffect(() => {
    var fieldArray = [];
    getFields(ruleBlock, fieldArray);
    setFields(fieldArray);
    try {
      setJsonRule(getNestedRule(ruleBlock));
      setQueryString(getNestedQuery(ruleBlock));
    } catch (err) {
      console.error(err);
    }
  }, [ruleBlock]);

  const renderInputFields = (field) => {
    if (isEmpty(field.type)) {
      console.error("Field type is empty for:", field);
      return null;
    }

    if (["string", "numeric"].includes(field.type))
      return (
        <div className="col-md-12 mb-4">
          <UILabelInput
            label={showFieldLabel(field.value)}
            value={variables[field.value]}
            onChange={(value) => {
              setVariables({ [field.value]: value });
            }}
          />
        </div>
      );

    if (field.type === "boolean")
      return (
        <div className="col-md-12 my-4">
          <label>
            <strong>{showFieldLabel(field.value)}</strong>
          </label>
          <select
            className="form-control"
            value={variables[field.value]}
            onChange={(e) => setVariables({ [field.value]: e.target.value })}
          >
            <option value="true">true</option>
            <option value="false">false</option>
          </select>
        </div>
      );

    if (["array", "object"].includes(field.type))
      return (
        <div className="col-md-12 mb-4">
          <label>
            <strong>{showFieldLabel(field.value)}</strong>
          </label>
          <textarea
            className="form-control"
            value={variables[field.value]}
            onChange={(e) => {
              setVariables({ [field.value]: e.target.value });
            }}
          />
        </div>
      );
    if (field.type === "date")
      return (
        <div className="col-md-12 mb-4">
          <label>
            <strong>{showFieldLabel(field.value)}</strong>
          </label>
          <DatePicker
            className="form-control"
            selected={variables[field.value]}
            scrollableYearDropdown={true}
            showYearDropdown={true}
            yearDropdownItemNumber={100}
            maxDate={new Date()}
            onChange={(date) => setVariables({ [field.value]: date })}
          />
        </div>
      );
  };

  if (!fields || fields.length === 0) {
    return null;
  }

  return (
    <div>
      <hr />
      <div className="mt-5">
        <strong>Query</strong>
        <div className="card text-white card-monokai">
          <div className="card-body">
            <div
              dangerouslySetInnerHTML={{ __html: queryString }}
              style={{ fontFamily: "monospace" }}
            />
          </div>
        </div>
      </div>
      <div className="my-5">
        <strong>JSON Rule</strong>
        <div className="card card-monokai">
          <div className="card-body">
            <ReactJson src={jsonRule} collapsed name={false} theme="monokai" />
          </div>
        </div>
      </div>
      <h3>Test Rule</h3>

      {fields.map((field) => {
        return <span key={field.value}>{renderInputFields(field)}</span>;
      })}

      {fields && fields.length > 0 && (
        <div className="d-flex justify-content-end">
          <div className="btn-group float-end">
            <button
              className="btn btn-secondary"
              onClick={onTestEligibilityRule}
            >
              Test Rule
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestingRule;
