import axios from "lib/axios";
import { serialize } from 'object-to-formdata';
import { v4 as uuidv4 } from "uuid";

export const getCompanyPayableInvoices = (params = {}) => {
  const url = `/admin/company_payable_invoices`;

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data) 
    })
}

export const getCompanyReceivableInvoices = (params = {}) => {
  const url = `/admin/company_receivable_invoices`

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data) 
    })
}

export const getCompanyPayableInvoice = (id) => {
  const url = `/admin/company_payable_invoices/${id}`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response.data)
    })
}

export const sendPayableInvoiceToCodat = (id) => {
  const url = `/admin/company_payable_invoices/${id}/send_to_codat`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response.data)
    })
} 

export const sendRecievableInvoiceToCodat = (id) => {
  const url = `/admin/company_receivable_invoices/${id}/send_to_codat`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response.data)
    })
} 

export const getCompanyReceivableInvoice = (id) => {
  const url = `/admin/company_receivable_invoices/${id}`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response.data)
    })
}

export const createCompanyPayableInvoice = (params = {}) => {
  const url = `/admin/company_payable_invoices`;
  
  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const createCompanyReceivableInvoice = (params = {}) => {
  const url = `/admin/company_receivable_invoices`;
  
  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const updateCompanyPayableInvoice = (params = {}) => {
  const url = `/admin/company_payable_invoices/${params.id}`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const updateCompanyReceivableInvoice = (params = {}) => {
  const url = `/admin/company_receivable_invoices/${params.id}`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export function newInvoice(company, setEndpoint) {
  let address = company.primary_address || {};

  return setEndpoint({
    company_id: company.id,
    customer_details: {
      name: company.name,
      address_line_1: address.address_line_1,
      address_line_2: address.address_line_2,
      city: address.city,
      state: address.state,
      postal_code: address.postal_code,
      country: address.country
    },
    shipping_details: {},
    vendor_details: {},
    tags: {}
  }, {
    company_id: company.id,
    vendor_details: {
      name: company.name,
      address_line_1: address.address_line_1,
      address_line_2: address.address_line_2,
      city: address.city,
      state: address.state,
      postal_code: address.postal_code,
      country: address.country
    },
    shipping_details: {},
    customer_details: {},
    tags: {}
  })
}

export function newItems() {
  return []
}

export function newItem(companyId) {
  return {
    _new: true,
    id: uuidv4(),
    company_id: companyId,
    description: "",
    quantity: 0,
    unit_cost: 0,
    tags: [],
  }
}

export function newTags() {
  return []
}

export function newTag() {
  return {
    id: uuidv4(),
    key: "",
    value: ""
  }
}

