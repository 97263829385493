import React, { useState, useEffect } from "react";
import { Modal, Button, Dropdown, DropdownButton } from "react-bootstrap";
import {
  fetchAdminIncentiveTypes,
  fetchAdminMissingFieldIncentives,
  updateAdminIncentive,
} from "../../../modules/incentives";
import {
  withUIPaginate,
  UIPaginationTotalSelector,
  UIPagination,
} from "components/ui";
import { fetchAdminOrganizations } from "modules/organizations";

import MissingFieldCard from "../../dashboard/home/missing-field-card";
import { flashError, flashSuccess } from "../../../lib/flash";

const BatchEdit = ({ pagination, updatePagination }) => {
  const [missingField, setMissingField] = useState("");
  const [incentiveTypes, setIncentiveTypes] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [incentives, setIncentives] = useState([]);

  const missingFieldsHash = {
    description: "Description",
    economics: "Economics",
    slug: "Slug",
    sponsoring_organization_id: "Sponsoring Org",
    incentive_type_id: "Incentive Type",
    urls: "Urls",
    eligibility_rule: "Eligibility",
    qualifications: "Qualifications",
  };

  const handleSelect = (e) => {
    let dropdown = e.split(", ");
    let key = dropdown[0];
    let value = dropdown[1];

    setMissingField(value);
  };

  const updateIncentive = (incentiveId, value) => {
    updateAdminIncentive({
      incentive: {
        [missingField]: value,
      },
      id: incentiveId,
    }).then((res) => {
      if (res.errors) {
        res.errors.map(flashError);
        return;
      }

      flashSuccess("Incentive updated Successfully");
      callFetchIncentives();
    });
  };

  useEffect(() => {
    callFetchIncentives();
  }, [missingField]);

  useEffect(() => {
    if (pagination.first_load) return;

    callFetchIncentives();
  }, [pagination]);

  const callFetchIncentives = () => {
    fetchAdminMissingFieldIncentives({
      field: missingField,
      ...pagination,
    }).then((data) => {
      setIncentives(data.incentives);

      updatePagination({
        total: data.pagination.total,
        first_load: true,
      });
    });
  };

  return (
    <div>
      <div className="panel p-4 pe-5">
        <div className="float-end">
          <DropdownButton
            id="dropdown-type-button"
            className="type-selector"
            title={
              missingField == ""
                ? "Missing Field"
                : missingFieldsHash[missingField]
            }
            variant="dark"
            onSelect={handleSelect}
          >
            <div>
              {Object.keys(missingFieldsHash).map((key, index) => {
                return (
                  <Dropdown.Item
                    eventKey={`type, ${key}`}
                    key={`type-${index}`}
                    className={missingField.toString() === key ? "active" : ""}
                  >
                    {missingFieldsHash[key]}
                  </Dropdown.Item>
                );
              })}
            </div>
          </DropdownButton>
        </div>
        <div className="row">
          <div className="ps-0">
            <div style={{ width: "500px" }}>
              <h3>Batch Edit Incentives</h3>
              {missingField !== "" && (
                <h5>
                  {pagination.total} incentives with missing{" "}
                  {missingFieldsHash[missingField]} field
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>

      {incentives.map((incentive) => (
        <MissingFieldCard
          key={incentive.id}
          incentive={incentive}
          missingField={missingField}
          updateIncentive={updateIncentive}
          incentiveTypes={incentiveTypes}
          organizations={organizations}
        />
      ))}

      {incentives.length > 0 && (
        <div className="panel px-4 py-2 d-flex justify-content-between align-items-center">
          <UIPaginationTotalSelector
            perPage={pagination.per_page}
            changePerPage={(value) => {
              updatePagination({
                per_page: value,
                first_load: false,
              });
            }}
          />

          <span className="float-end mt-2">
            {pagination.total > 0 ? (
              <UIPagination
                page={pagination.page}
                perPage={pagination.per_page}
                changePage={(page) => {
                  updatePagination({
                    page: page.selected + 1,
                    first_load: false,
                  });
                }}
                total={pagination.total}
              />
            ) : null}
          </span>
        </div>
      )}
    </div>
  );
};

export default withUIPaginate(BatchEdit);
