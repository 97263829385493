import React, { useEffect, useState, useMemo } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ProjectTemplateDetailEditor from "./project-template-detail-editor";
import ProjectLineItemEditor from "./project-line-item-editor";

import {
  deleteCompanyEstimateTemplate,
  deleteCompanyEstimateTemplateItem,
  getCompanyEstimateTemplateById,
  getCompanyEstimateTemplates,
  createCompanyEstimateTemplate,
} from "modules/companies";

const useFilteredItems = (data, type) => {
  return useMemo(
    () => (data ? data.filter((item) => item.item_type === type) : []),
    [data, type]
  );
};

const ProjectTemplateEditor = ({ company }) => {
  const [categoryEdit, setCategoryEdit] = useState(false);
  //TODO: Refactor and deal with all the state duplication around currentTemplate
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [currentTemplateId, setCurrentTemplateId] = useState(null);
  const [
    currentTemplateLineItemCategoryId,
    setCurrentTemplateLineItemCategoryId,
  ] = useState(null);
  const [currentTemplateName, setCurrentTemplateName] = useState("");
  const [currentTemplateDescription, setCurrentTemplateDescription] =
    useState("");
  const [defaultCategoryId, setDefaultCategoryId] = useState(null);
  const [editorContents, setEditorContents] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showNewTemplateForm, setShowNewTemplateForm] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [templates, setTemplates] = useState([]);
  const [templateChanged, setTemplateChanged] = useState(false);

  const categories = useFilteredItems(
    currentTemplate,
    "CompanyEstimateCategory"
  );
  const lineItems = useFilteredItems(
    currentTemplate,
    "CompanyEstimateLineItem"
  );

  const TooltipContent = ({ templateId }) => {
    const [data, setData] = useState(null);
    const categories = useFilteredItems(data, "CompanyEstimateCategory");
    const lineItems = useFilteredItems(data, "CompanyEstimateLineItem");

    useEffect(() => {
      const fetchData = async () => {
        const result = await fetchTooltipData(templateId);
        setData(result);
      };
      fetchData();
    }, [templateId]);

    return data ? (
      <div className="detail-tooltip">
        {categories.length > 0 && (
          <>
            <strong>Categories:</strong>
            <ul>
              {categories.map((cat, index) => (
                <li
                  key={index}
                  dangerouslySetInnerHTML={{ __html: cat.name }}
                ></li>
              ))}
            </ul>
          </>
        )}
        {lineItems.length > 0 && (
          <>
            <strong>Line Items:</strong>
            <ul>
              {lineItems.map((item, index) => (
                <li key={index}>{item.description}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    ) : (
      <div>Loading...</div>
    );
  };

  const toggleCategoryEditMode = (id) => {
    setCategoryEdit((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const fetchTooltipData = async (templateId) => {
    const result = await getCompanyEstimateTemplateById(company.id, templateId);
    return result;
  };

  const handleTemplateChange = () => {
    setTemplateChanged((prev) => !prev);
  };

  const handleCreateNewTemplate = async (e) => {
    e.preventDefault();
    try {
      await createCompanyEstimateTemplate(company.id, newTemplateName);
      setNewTemplateName("");
      setShowNewTemplateForm(false);
      const updatedTemplates = await getCompanyEstimateTemplates(company.id);
      setTemplates(updatedTemplates.templates);
    } catch (error) {
      console.error("Error creating new template:", error);
    }
  };

  const deleteCategory = async (templateItemId) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        await deleteCompanyEstimateTemplateItem(company.id, templateItemId);
        const updatedCategories = categories.filter(
          (category) => category.template_item_id !== templateItemId
        );
        setCurrentTemplate((prevTemplate) =>
          prevTemplate.filter(
            (item) => item.template_item_id !== templateItemId
          )
        );
      } catch (error) {
        console.error("Error deleting category:", error);
      }
    }
  };

  const fetchTemplateDetails = async (templateId) => {
    try {
      const data = await getCompanyEstimateTemplateById(company.id, templateId);
      setCurrentTemplate(data);
    } catch (error) {
      console.error("Error fetching template details:", error);
    }
  };

  const deleteTemplate = async (companyId, templateId) => {
    if (window.confirm("Are you sure you want to delete this template?")) {
      try {
        await deleteCompanyEstimateTemplate(companyId, templateId);
        setTemplates(
          templates.filter((template) => template.id !== templateId)
        );
      } catch (error) {
        console.error("Error deleting template:", error);
      }
    }
  };

  const handleEditClick = async (templateId) => {
    try {
      const clickedTemplate = templates.find(
        (template) => template.id === templateId
      );
      if (clickedTemplate) {
        setCurrentTemplateName(clickedTemplate.name);
        setCurrentTemplateDescription(clickedTemplate.description);
        setDefaultCategoryId(clickedTemplate.default_estimate_category_id);
      }
      await fetchTemplateDetails(templateId);
      setCurrentTemplateId(templateId);
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching template details:", error);
    }
  };

  const refreshCurrentTemplate = async () => {
    await fetchTemplateDetails(currentTemplateId);
  };

  useEffect(() => {
    getCompanyEstimateTemplates(company.id)
      .then((data) => {
        setTemplates(data.templates);
      })
      .catch((error) => {
        console.error("Error fetching Company Estimate Templates:", error);
      });
  }, [company.id, templateChanged]);

  useEffect(() => {
    if (currentTemplate) {
      setShowModal(true);
    }
  }, [currentTemplate]);

  return (
    <div className="card p-3 mt-3 mb-3 z-depth-2">
      <h3 className="panel-title mb-2">Manage project library</h3>
      <div
        className="template-header"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p className="panel-context">
          Use Project Templates to streamline your estimates with pre-set
          materials and labor tasks.
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip className="cashflow-tooltip">
                Project Templates allow you to pre-define sets of materials and
                labor tasks for use in estimates. Templates can also be nested
                within other templates, providing a way to structure more
                complex projects. This feature helps maintain consistency across
                estimates and reduces the time required for preparation.
              </Tooltip>
            }
          >
            <i className="bi bi-info-circle" style={{ marginLeft: "4px" }} />
          </OverlayTrigger>
        </p>
        <button
          className="btn-attach"
          style={{ width: "auto", padding: "6px 12px" }}
          onClick={() => setShowNewTemplateForm(!showNewTemplateForm)}
        >
          Add New Template
        </button>
      </div>
      {showNewTemplateForm && (
        <form
          onSubmit={handleCreateNewTemplate}
          style={{ marginTop: "15px", marginBottom: "15px" }}
        >
          <input
            type="text"
            className="inline-edit"
            placeholder="Enter new template name"
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
          />
          <button
            className="btn-attach"
            style={{ marginLeft: "10px", width: "auto", padding: "6px 12px" }}
            type="submit"
          >
            Create
          </button>
          <button
            className="btn btn-dark"
            style={{ marginLeft: "10px", width: "auto", padding: "6px 12px" }}
            onClick={() => setShowNewTemplateForm(false)}
          >
            Close
          </button>
        </form>
      )}

      <table className="settings-table">
        <thead>
          <tr>
            <th style={{ width: "26%" }}>Name</th>
            <th style={{ width: "50%" }}>Description</th>
            <th style={{ width: "7%" }}>Created</th>
            <th style={{ width: "7%" }}>Updated</th>
            <th style={{ width: "5%" }}>Edit</th>
            <th style={{ width: "5%" }}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {templates.map((template) => (
            <tr key={template.id}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="detail-tooltip">
                    <TooltipContent
                      className="detail-tooltip"
                      templateId={template.id}
                    />
                  </Tooltip>
                }
              >
                <td>{template.name}</td>
              </OverlayTrigger>
              <td>{template.description}</td>
              <td>{new Date(template.created_at).toLocaleDateString()}</td>
              <td>{new Date(template.updated_at).toLocaleDateString()}</td>
              <td>
                <button
                  className="btn btn-sm"
                  onClick={() => handleEditClick(template.id)}
                >
                  Edit
                </button>
              </td>
              <td>
                <button
                  className="btn btn-action btn-sm"
                  onClick={() => deleteTemplate(company.id, template.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ProjectLineItemEditor company={company} />
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-xl"
      >
        <Modal.Body>
          {currentTemplate ? (
            <ProjectTemplateDetailEditor
              currentTemplateId={currentTemplateId}
              currentTemplateLineItemCategoryId={
                defaultCategoryId ??
                (lineItems &&
                  lineItems.length > 0 &&
                  lineItems[0]
                    .company_estimate_line_item_company_estimate_category_id)
              }
              company={company}
              categories={categories}
              lineItems={lineItems}
              categoryEdit={categoryEdit}
              toggleCategoryEditMode={toggleCategoryEditMode}
              currentTemplate={currentTemplate}
              currentTemplateName={currentTemplateName}
              currentTemplateDescription={currentTemplateDescription}
              setCurrentTemplate={setCurrentTemplate}
              deleteCategory={deleteCategory}
              setEditorContents={setEditorContents}
              editorContents={editorContents}
              refreshCurrentTemplate={refreshCurrentTemplate}
              onTemplateChange={handleTemplateChange}
            />
          ) : (
            <p>No template data available.</p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn btn-dark btn-sm"
            onClick={() => setShowModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProjectTemplateEditor;
