import React, { useState } from 'react';
import Loading from 'components/common/loading'
import { Overlay, Popover, PopoverHeader, PopoverBody } from 'react-bootstrap';

const CreateEstimateCategoryPopover = ({ target, show, name, setName, setShow, createCategory }) => {
  const [ isCreating, setIsCreating ] = useState(false)

  function handleCancel(e) {
    e.preventDefault()
    setName('')
    setShow(false)
  }

  function handleSubmit(e) {
    e.preventDefault()
    setIsCreating(true)

    createCategory()
  }

  function renderLoading() {
    return (
      <Loading height={100} />
    )
  }

  function renderForm() {
    return (
      <div>
        <div className='form-group'>
          <label><strong>Name</strong></label>
          <input type="text" className='form-control' value={name} onChange={(e) => {
            e.preventDefault()
            setName(e.target.value)
          }} />
        </div>

        <div className='mt-3'>
          <button className='btn btn-sm btn-secondary me-2' onClick={handleCancel}>Cancel</button>
          <button type='submit' className='btn btn-sm btn-action' onClick={handleSubmit}>Create</button>
        </div>
      </div>
    )
  }

  return (
    <Overlay target={target.current} show={show} placement="bottom-start" rootClose>
      {({ style, outOfBoundaries, scheduleUpdate, ...props }) => (
        <Popover id='popover-positioned-bottom' {...props} style={{
          ...style,
          minWidth: '300px'
        }}>
          <PopoverHeader as="h3">New Category</PopoverHeader>
          <PopoverBody>
            { isCreating ? renderLoading() : renderForm() }
          </PopoverBody>
        </Popover>
      )}
    </Overlay>
  )
}

export default CreateEstimateCategoryPopover;