import axios from "lib/axios";

export const createPayableInvoice = (params = {}) => {
  const url = `/company_payable_invoices`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const createPayableInvoices = (params = {}) => {
  const url = `/company_payable_invoices/bulk`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const createReceivableInvoice = (params = {}) => {
  const url = `/company_receivable_invoices`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const createReceivableInvoices = (params = {}) => {
  const url = `/company_receivable_invoices/bulk`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const fetchPayableInvoices = (params = {}) => {
  const url = `/company_payable_invoices`;

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const fetchReceivableInvoices = (params = {}) => {
  const url = `/company_receivable_invoices`;

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const updatePayableInvoice = (id, params = {}) => {
  const url = `/company_payable_invoices/${id}`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const updateReceivableInvoice = (id, params = {}) => {
  const url = `/company_receivable_invoices/${id}`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const deletePayableInvoice = (id) => {
  const url = `/company_payable_invoices/${id}`;

  return axios
    .delete(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const deleteReceivableInvoice = (id) => {
  const url = `/company_receivable_invoices/${id}`;

  return axios
    .delete(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}
