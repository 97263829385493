import React from "react";
import FlashMessages from "../ui/flash_messages";

const Layout = (props) => {
  const ComponentToRender = ReactRailsUJS.getConstructor(props.child);
  return (
    <>
      <FlashMessages messages={props.messages} />
      <ComponentToRender {...props.props} />
    </>
  );
};

export default Layout;
