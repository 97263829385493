import React, { Component, useEffect, useState } from "react";
import { getBusinessEntities } from "modules/business_entities";
import UISelectInput from "../ui-select-input";

const BusinessEntityInputFormik = (props) => {
  let [businessEntities, setBusinessEntities] = useState([]);

  useEffect(() => {
    getBusinessEntities().then((data) => {
      setBusinessEntities(
        data.map((entity, index) => {
          return {
            value: entity.id,
            label: entity.name,
          };
        })
      );
    });
  }, []);

  return <UISelectInput {...props} options={businessEntities} />;
};

export default BusinessEntityInputFormik;
