import React, { useState, useEffect } from "react";
import { UIFormLabel } from "components/ui";
import {
  uploadIncentiveFileResource,
  deleteIncentiveFileResource,
} from "modules/incentives";
import { flashSuccess, flashWarning, flashSuccessLater } from "lib/flash";
import { humanFileSize } from "lib/string";

const IncentiveFileResources = (props) => {
  const [incentive, setIncentive] = useState(props.incentive);
  const [incentiveFileResources, setIncentiveFileResources] = useState(
    incentive.file_resources
  );

  const uploadFileResource = (files) => {
    const params = {
      incentive: {
        file_resource: files[0],
      },
      id: incentive.id,
    };

    uploadIncentiveFileResource(params).then((data) => {
      flashSuccess("File Added.");
      setIncentiveFileResources(data.incentive.file_resources);
    });
  };

  const deleteFileResource = (resourceId) => {
    const params = {
      resource_id: resourceId,
      id: incentive.id,
    };

    deleteIncentiveFileResource(params).then((data) => {
      flashSuccess("File Deleted.");
      setIncentiveFileResources(data.incentive.file_resources);
    });
  };

  return (
    <div>
      <div className="col-md-12">
        <input
          type="file"
          id="uploadButton"
          name="upload_file_resource"
          className="form-control d-none"
          onChange={(e) => {
            e.preventDefault();
            uploadFileResource(e.target.files);
          }}
        />
        <label
          htmlFor="uploadButton"
          id="upload_file_resource"
          className="btn-attach float-end"
        >
          Upload File
        </label>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>File</th>
            <th>Type</th>
            <th>Size</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {incentiveFileResources &&
            incentiveFileResources.map((resource, index) => {
              return (
                <tr key={`incentive-file-resources-row-${index}`}>
                  <td>{resource.id}</td>
                  <td>
                    <a href={resource.url} target="_blank">
                      {resource.name}
                    </a>
                  </td>
                  <td>{resource.type}</td>
                  <td>{humanFileSize(resource.size)}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-danger float-end"
                      onClick={() => deleteFileResource(resource.id)}
                    >
                      <i className="px-1 bi bi-trash"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default IncentiveFileResources;
