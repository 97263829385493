import React, { Component } from 'react';
import axios from 'lib/axios'

export class UIJumbotron extends Component {
  constructor(props) {
    super(props);

    this.dismiss = this.dismiss.bind(this)

    this.state = {
      hide: false
    }
  }

  dismiss(e) {
    e.preventDefault();
    axios.post(`/jumbotrons/${this.props.jumbotron.id}/dismiss`)
      .catch((error) => {})

    this.setState({hide: true})
  }

  render() {
    if(this.state.hide) return null;
    const { jumbotron } = this.props;

    let jumbotronStyle = {
      'borderRadius': '4px'
    }

    let ctaStyle = {}

    // if(jumbotron.cta_button_color_hex) {
    //   ctaStyle['backgroundColor'] = jumbotron.cta_button_color_hex
    //   ctaStyle['borderColor'] = jumbotron.cta_button_color_hex
    // } else {
    //   ctaStyle['backgroundColor'] = '#0d6efd'
    // }

    return (
      <div className='p-4 dark-panel ui-jumbotron' style={jumbotronStyle}>
        <div className='row'>
          <div className='col-md-8'>
            <h4 className='mb-0'>
              <strong>
                {jumbotron.title}
              </strong>
            </h4>
            <p className='mb-0'>{jumbotron.description}</p>
          </div>

          <div className='col-md-4'>
            <div className='float-end'>
              <a href={jumbotron.cta_uri} className='btn btn-action'>
                { jumbotron.cta_text }
              </a>
              <button className='ms-2 btn text-light btn-muted' onClick={this.dismiss}>
                { jumbotron.dismissal_text }
              </button>
            </div>
          </div>
        </div>
      </div> 
    );
  }
}

export default UIJumbotron;