import React, { useState } from "react";
import { useSetState } from "react-hanger";

import { UIFormLabel } from "components/ui";

import { createIncentiveUrl } from "modules/incentive-urls";

import { flashSuccess, flashWarning, flashError } from "lib/flash";

const AddNewIncentiveURL = (props) => {
  const { state, setState } = useSetState({ formVisible: false, error: null });

  const addNewIncentiveURL = (e) => {
    e.preventDefault();
    let label = e.target.elements.label.value;
    let url = e.target.elements.url.value;

    if (!label) {
      flashError("Label Can't Be Blank.");
      return;
    }

    if (!url) {
      flashError("URL Can't Be Blank.");
      return;
    }

    const params = {
      incentive_url: {
        label: label,
        url: url,
      },
      incentive_id: props.incentiveId,
    };

    createIncentiveUrl(params)
      .then((res) => {
        flashSuccess(`${url} added to incentive.`);
        props.addIncentiveURL({ incentive: res });
        setState({ error: null, formVisible: false });
      })
      .catch((err) => {
        flashError(`There was an error: ${err}`);
      });
  };

  const toggleForm = (e) => {
    e.preventDefault();
    setState({
      formVisible: !state.formVisible,
    });
  };

  const renderAlert = (e) => {
    if (state.error) {
      return <span>{state.error}</span>;
    }
  };

  return (
    <div className="d-block mb-3">
      {!state.formVisible && (
        <div className="clearfix">
          <button className="float-end btn-attach" onClick={toggleForm}>
            Add URL
          </button>
        </div>
      )}

      {state.formVisible ? (
        <div className="my-3">
          <form
            className="my-3 form d-flex flex-column"
            onSubmit={addNewIncentiveURL}
          >
            <h5>Add New Url for Incentive</h5>

            <div className="form-group">
              <UIFormLabel label="Label" />
              <input
                type="text"
                name="label"
                className="form-control"
                placeholder="Enter Label Here"
              />
            </div>

            <div className="mt-3">
              <UIFormLabel label="URL" />
              <input
                type="text"
                name="url"
                className="form-control"
                placeholder="Enter the URL Here"
              />
            </div>

            <div className="align-self-end mt-3">
              <button className="btn btn-light me-2" onClick={toggleForm}>
                Cancel
              </button>
              <button type="submit" className="btn btn-action">
                Create
              </button>
            </div>
          </form>
        </div>
      ) : null}
    </div>
  );
};

export default AddNewIncentiveURL;
