import React, { useState, useEffect } from "react";
import { useSetState } from "react-hanger";
import StyleGuideLink from "../common/style-guide-link";

import { UIFormLabel } from "components/ui";

// https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md
import AceEditor from "react-ace";

// Keeping things light - only importing mode/theme/worker needed for this JSON editor
// https://github.com/securingsincity/react-ace/issues/725#issuecomment-543109155
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import jsonWorkerUrl from "file-loader!ace-builds/src-noconflict/worker-json";
ace.config.setModuleUrl("ace/mode/json_worker", jsonWorkerUrl);

// Enables Cmd + F search
import "brace/ext/searchbox";

import { updateAdminIncentive } from "modules/incentives";
import { flashSuccessLater, flashError } from "lib/flash";

const IntakeAdmin = (props = {}) => {
  const { state: incentive, setState: setIncentiveState } = useSetState(
    props.incentive
  );
  const [sectionEditors, setSectionEditors] = useState([]);

  useEffect(() => {
    if (!props.incentive.intake_schema) {
      // Initialize with a blank section if intake_schema is null
      setSectionEditors([
        {
          key: `new_section_${Date.now()}`, // Unique key for the new section
          content: "",
          label: "New Section",
          collapsed: false,
        },
      ]);
    } else {
      const properties = props.incentive.intake_schema?.properties;
      if (typeof properties === "object" && properties !== null) {
        const initialSections = Object.keys(properties).map((key) => ({
          key,
          content: JSON.stringify(properties[key], null, 4),
          row: properties[key].row,
          label: properties[key].label,
          collapsed: true,
        }));

        initialSections.sort((a, b) => a.row - b.row);
        setSectionEditors(initialSections);
      }
    }
  }, [props.incentive]);

  const onUpdateSection = (sectionKey, newValue) => {
    const sectionIndex = sectionEditors.findIndex(
      (section) => section.key === sectionKey
    );
    if (sectionIndex !== -1) {
      try {
        const parsedContent = JSON.parse(newValue);
        const newRow = parsedContent.row; // Extract the new row value from updated content
        const newSectionEditors = [...sectionEditors];
        newSectionEditors[sectionIndex] = {
          ...newSectionEditors[sectionIndex],
          content: newValue,
          row: newRow, // Update the row state with the new value
        };
        setSectionEditors(newSectionEditors);
      } catch (error) {
        // TODO: Better handle error given custom usage
        console.error("Error parsing JSON content:", error);
      }
    }
  };

  const onUpdateIncentive = async (e) => {
    e.preventDefault();

    try {
      // Build new properties object from section editors

      const updatedProperties = sectionEditors.reduce(
        (acc, { key, content, row }) => {
          const parsedContent = JSON.parse(content);
          let keyToUse;
          let contentToUse = { ...parsedContent };

          if (parsedContent["$ref"]) {
            keyToUse = parsedContent["$ref"].split(":")[1];
            // If there's a row designation in the section editor, apply it to the reference
            if (typeof row === "number") {
              contentToUse = { ...contentToUse, row };
            }
          } else {
            // For regular sections, use the label as the key if not using $ref
            keyToUse = parsedContent.label || key;
          }

          if (!keyToUse) {
            console.error(
              "Could not determine key for section:",
              parsedContent
            );
            return acc;
          }

          acc[keyToUse] = contentToUse;
          return acc;
        },
        {}
      );

      // Create updated schema using only the updatedProperties, replacing the old properties entirely
      const updatedSchema = {
        type: "object",
        $schema: "http://json-schema.org/draft-07/schema#",
        title: props.incentive.name,
        properties: updatedProperties,
      };

      // Proceed with updating the incentive using the updated schema
      const updatedIncentiveData = await updateAdminIncentive({
        ...props.incentive,
        intake_schema: updatedSchema,
      });

      flashSuccessLater("Incentive intake form updated successfully!");
      setIncentiveState(updatedIncentiveData);
      window.location.reload(true);
      // TODO: Update the local state instead of reloading
    } catch (error) {
      console.error("Error updating incentive:", error);
      flashError(`Error updating incentive: ${error.message}`);
    }
  };

  const addNewSection = () => {
    const newSection = {
      key: `new_section_${Date.now()}`, // Generating a unique key
      content: JSON.stringify(
        {
          /* Could be default schema, empty for now */
        },
        null,
        4
      ),
      label: "New Section",
    };

    // Update sectionEditors state
    setSectionEditors([...sectionEditors, newSection]);
  };

  const removeSection = (sectionKey) => {
    if (
      window.confirm(
        "Are you sure you want to remove this section? (Note: You still need to update at the bottom of the form to make these changes permanent.)"
      )
    ) {
      const filteredSections = sectionEditors.filter(
        (section) => section.key !== sectionKey
      );
      setSectionEditors(filteredSections);
    }
  };

  const toggleCollapse = (sectionKey) => {
    setSectionEditors((currentSections) =>
      currentSections.map((section) =>
        section.key === sectionKey
          ? { ...section, collapsed: !section.collapsed }
          : section
      )
    );
  };

  return (
    <div>
      <div className="clearfix">
        <div className="mb-3 btn-group float-end">
          <StyleGuideLink guideType="intakeSchema" />
          <button onClick={addNewSection} className="btn-attach">
            Add Section
          </button>
        </div>
      </div>
      <form className="form" onSubmit={onUpdateIncentive}>
        {sectionEditors.map(({ key, content, label, collapsed }, index) => {
          let itemCount = "error parsing";
          let displayLabel = label;
          let isRef = false;
          try {
            const parsedContent = JSON.parse(content);
            if (parsedContent.$ref) {
              const refParts = parsedContent.$ref.split(":");
              if (refParts.length > 1) {
                displayLabel = refParts[1]; // Use the component name as the label
                isRef = true;
                itemCount = "";
              }
            } else {
              itemCount = `${
                Object.keys(parsedContent.properties).length
              } fields`; // Count keys if not a $ref
            }
          } catch (error) {
            console.error("Error parsing JSON content for item count", error);
            itemCount = "Error";
          }
          return (
            <div key={key} className="mb-3">
              <div
                style={{
                  backgroundColor: "rgba(246, 248, 250, 0.6)",
                  backdropFilter: "blur(5px)",
                  border: "1px solid rgba(246, 248, 250)",
                  borderRadius: "4px",
                  paddingLeft: "19px",
                  padding: "8px",
                }}
              >
                <UIFormLabel
                  label={
                    `${displayLabel || `Schema Section ${index + 1}`}` +
                    (isRef ? ` (Template) ` : ` (${itemCount})`)
                  }
                  tooltip={
                    !isRef
                      ? "To update the name of this section change the label property. To re-order this section change the row number."
                      : undefined
                  }
                />
                {isRef && (
                  <i
                    className="bi bi-clipboard2-check-fill"
                    title="Used from template"
                    style={{ marginLeft: "5px" }}
                  ></i>
                )}
                <button
                  type="button"
                  className="btn btn-muted chevron-button float-end"
                  style={{ marginLeft: "8px" }}
                  onClick={() => toggleCollapse(key)}
                >
                  {collapsed ? (
                    <i className="bi bi-chevron-down"></i>
                  ) : (
                    <i className="bi bi-chevron-up"></i>
                  )}
                </button>
                {props.incentive.intake_schema && (
                  <button
                    type="button"
                    className="btn btn-danger float-end"
                    onClick={() => removeSection(key)}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                )}
              </div>
              {!collapsed && (
                <AceEditor
                  mode="json"
                  theme="github"
                  name={`schema_editor_${key}`}
                  width="100%"
                  placeholder="Enter JSON schema here..."
                  wrapEnabled={true}
                  value={content}
                  onChange={(value) => onUpdateSection(key, value)}
                />
              )}
            </div>
          );
        })}
        <div className="clearfix">
          <div className="mt-3 btn-group float-end">
            <a className="btn btn-light" href="/admin/incentives">
              Cancel
            </a>
            <a
              className="btn btn-secondary"
              href={`/applications/${props.incentive.slug}#/apply`}
              target="_blank"
            >
              Preview
            </a>
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default IntakeAdmin;
