import React, { useEffect, useState } from "react";
import { State } from "country-state-city";
import { useSetState } from "react-hanger";
import { flashError, flashSuccess } from "lib/flash";

import {
  UILabelInput,
  UIFormLabel,
  UIAddressInputWithSuggestions,
} from "components/ui";

const blankAddress = {
  id: null,
  address_line_1: "",
  address_line_2: "",
  city: "",
  state: "",
  postal_code: "",
  is_primary: false,
  location_flags: {},
};

const AddressForm = ({
  address = blankAddress,
  onCancel,
  onSaveAddress,
  showPrimaryCheckbox = true,
  showButtons = true,
  locationFlags = [],
  showLocationFlags = false,
}) => {
  const { state: currentAddress, setState: setCurrentAddress } = useSetState({
    ...address,
    location_flags: address.location_flags || {},
  });

  const { state: formErrors, setState: setFormErrors } = useSetState({
    address_line_1: "",
    city: "",
    state: "",
    postal_code: "",
  });

  const handleFlagChange = (flag) => {
    setCurrentAddress((prevAddress) => ({
      ...prevAddress,
      location_flags: {
        ...prevAddress.location_flags,
        [flag]: !prevAddress.location_flags[flag],
      },
    }));
  };

  useEffect(() => {
    setCurrentAddress(address);
  }, [address]);

  const submitForm = () => {
    if (validateForm()) {
      flashSuccess("Address added to company. Looking up zones now.");
      onSaveAddress(currentAddress);
    }
  };

  const validateForm = () => {
    let valid = true;
    let address_line_1 = "";
    let state = "";
    let city = "";
    let postal_code = "";
    if (currentAddress.address_line_1 === "") {
      valid = false;
      address_line_1 = "can't be blank";
    }
    if (currentAddress.state === "") {
      valid = false;
      state = "can't be blank";
    }
    if (currentAddress.city === "") {
      valid = false;
      city = "can't be blank";
    }
    if (currentAddress.postal_code === "") {
      valid = false;
      postal_code = "can't be blank";
    }
    setFormErrors({
      address_line_1,
      city,
      state,
      postal_code,
    });
    return valid;
  };

  return (
    <div>
      {locationFlags && locationFlags.length > 0 && (
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="checkbox-container">
              <UIFormLabel label="Location Flags" />
              <div className="row">
                {locationFlags.map((flag) => (
                  <div key={flag} className="col-md-3 col-sm-6 mb-2">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="company-admin-checkbox"
                        id={`flag-${flag}`}
                        checked={currentAddress.location_flags?.[flag] || false}
                        onChange={() => handleFlagChange(flag)}
                      />
                      <label
                        className="company-admin-checkbox-label"
                        htmlFor={`flag-${flag}`}
                      >
                        {flag
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            label="Description"
            value={currentAddress.description || ""}
            onChange={(value) => {
              setCurrentAddress({ description: value });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UIFormLabel label="Address Line 1" />
          <UIAddressInputWithSuggestions
            value={currentAddress.address_line_1}
            onChange={(value) => {
              setCurrentAddress({ address_line_1: value });
            }}
            onSelectSuggestedAddress={(suggestedAddress) => {
              for (const field in address) {
                if (field in suggestedAddress) {
                  address[field] = suggestedAddress[field];
                }
              }
              setCurrentAddress({ ...address });
            }}
          />
          {formErrors.address_line_1 !== "" && (
            <span className="d-block text-danger error mt-2">
              {formErrors.address_line_1}
            </span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            label="Address Line 2"
            value={currentAddress.address_line_2}
            onChange={(value) => {
              setCurrentAddress({ address_line_2: value });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            label="City"
            error={formErrors.city}
            value={currentAddress.city}
            onChange={(value) => {
              setCurrentAddress({ city: value });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 mb-3">
          <strong>
            <label htmlFor="state" className="mb-1">
              State
            </label>
          </strong>
          <select
            name="state"
            id="state"
            className="form-control"
            value={currentAddress.state}
            onChange={(e) => {
              setCurrentAddress({ state: e.target.value });
            }}
          >
            {State.getStatesOfCountry("US").map((state) => (
              <option key={state.name} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
          {formErrors.state !== "" && (
            <span className="error">{formErrors.state}</span>
          )}
        </div>
        <div className="col-md-4 mb-3">
          <UILabelInput
            label="Postal Code"
            value={currentAddress.postal_code}
            error={formErrors.postal_code}
            onChange={(value) => {
              setCurrentAddress({ postal_code: value });
            }}
          />
        </div>
        {showPrimaryCheckbox && (
          <div>
            <label htmlFor={`is_primary-${currentAddress.id}`}>
              <input
                type="checkbox"
                id={`is_primary-${currentAddress.id}`}
                className="company-admin-checkbox"
                checked={currentAddress.is_primary}
                disabled={address.is_primary}
                onChange={(value) => {
                  setCurrentAddress({ is_primary: !currentAddress.is_primary });
                }}
              />
              Primary
            </label>
          </div>
        )}
      </div>
      <div className="row">
        {showButtons && (
          <div className="d-flex justify-content-end pt-5">
            <div className="btn-group">
              <button className="btn btn-light" onClick={onCancel}>
                Cancel
              </button>
              <button className="btn btn-primary mx-1" onClick={submitForm}>
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressForm;
