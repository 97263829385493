import React, { useCallback, useEffect, useState } from "react";

import { Formik, Form } from "formik";
import {
  updateIncentiveApplication,
  submitIncentiveApplication,
  submitIncentiveApplicationErrors,
} from "modules/applications";
import {
  buildInitialValuesForSchema,
  deleteUnansweredValues,
  setFormErrorsAsTouched,
} from "lib/utils";
import { UIAutoSave, UIFieldGroup } from "components/ui";
import { flashError, flashSuccess } from "lib/flash";

import ApplicationTabs from "./application-tabs";
import ApplicationNextBackButton from "./application-next-back-button";

const ApplicationForm = (props) => {
  const { isReadOnly, values, application, incentive } = props;
  const { intake_schema } = incentive;

  const [currentTab, setCurrentTab] = useState("");
  const [currentPropertyIndex, setCurrentPropertyIndex] = useState(0);
  const [properties, setProperties] = useState([]);
  const [submitted, setSubmitted] = useState(application.status !== "draft");
  const [allowTabChange, setAllowTabChange] = useState({});

  function sortProperties(properties) {
    return Object.entries(properties).sort((a, b) => {
      return a[1].row - b[1].row;
    });
  }

  useEffect(() => {
    const sortedProperties = sortProperties(props.intakeSchema.properties);
    const firstProperty = sortedProperties[0];
    const lastProperty = sortedProperties[sortedProperties.length - 1];

    setCurrentTab(firstProperty[0]);
    setProperties(sortedProperties);
  }, []);

  function handleFormSubmit(
    handleSubmit,
    validateForm,
    setFieldTouched,
    values
  ) {
    validateForm().then((errors) => {
      let entries = Object.entries(errors);

      if (entries.length === 0) {
        handleSubmit(values);
        setSubmitted(true);
      } else {
        setFormErrorsAsTouched(errors, setFieldTouched);
        flashError("Please review your input and resolve any errors.");

        const params = {
          errors: errors,
          id: application.id,
        };

        submitIncentiveApplicationErrors(params, incentive.slug);
      }
    });
  }

  function changeTab(offset) {
    const nextIndex = currentPropertyIndex + offset;
    const nextTab = properties[nextIndex];

    setCurrentTab(nextTab[0]);
    setCurrentPropertyIndex(nextIndex);
  }

  function handleTabChange(offset) {
    const name = currentTab;

    // If already seen this tab's errors or going backwards - don't validate
    if (allowTabChange[name] || offset < 0) {
      changeTab(offset);
      return;
    }

    props.validateForm().then((errors) => {
      let currentErrors = errors[currentTab];

      if (!currentErrors) {
        changeTab(offset);
      } else {
        setFormErrorsAsTouched(
          currentErrors,
          props.setFieldTouched,
          name + "."
        );
        flashError(
          "You can click 'Next' again to continue, but you'll need to to resolve these errors before submitting!"
        );

        allowTabChange[currentTab] = true;
        setAllowTabChange({ ...allowTabChange });
      }
    });
  }

  function renderCurrentTab() {
    if (!currentTab || properties.length === 0) return null;

    const name = currentTab;
    const schema = properties[currentPropertyIndex][1];

    return (
      <UIFieldGroup
        prefix={name}
        schema={schema}
        key={`${name}-inputs`}
        values={props.values[name]}
        application={props.application.id}
        applicationSlug={incentive.slug}
      />
    );
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleFormSubmit(
          props.handleSubmit,
          props.validateForm,
          props.setFieldTouched,
          props.values
        );
      }}
    >
      <div className="application-form-container panel pt-4">
        <div className="row px-4 mb-3">
          <div className="col-md-6">
            <h3>Apply</h3>
          </div>
          <div className="col-md-6">
            {!isReadOnly && (
              <UIAutoSave
                callback={({ params, slug }, handleStatus) => {
                  updateIncentiveApplication(params, slug)
                    .then(() => {
                      handleStatus(true);
                    })
                    .catch((e) => {
                      handleStatus(false);
                    });
                }}
                buildCallbackParams={(values) => {
                  return {
                    params: {
                      id: application.id,
                      details: values,
                    },
                    slug: incentive.slug,
                  };
                }}
              />
            )}
          </div>
        </div>
        {submitted && (
          <div className="row px-4 mb-3">
            <div className="col-md-12">
              <p>
                <b>
                  Thank you for submitting the {incentive.name}
                  &nbsp;form! Our experts are reviewing it and will be in touch
                  within 1 business day with more information.
                </b>
              </p>
              <div className="alert alert-warning" role="alert">
                <h5 className="alert-heading">Documents Required!</h5>
                <p className="mb-0">
                  <strong>
                    Please note that your application will not be reviewed until
                    all necessary&nbsp;
                    <a
                      href={`${window.location.origin}${window.location.pathname}#/documents`}
                      className="text-danger"
                    >
                      documents
                    </a>
                    &nbsp;are uploaded.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        )}
        {!!intake_schema.description && (
          <div className="row px-4 mb-3">
            <div className="col-md-12">
              <p>{intake_schema.description}</p>
            </div>
          </div>
        )}
        <ApplicationTabs
          properties={properties}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          setCurrentPropertyIndex={setCurrentPropertyIndex}
          errors={props.errors}
          touched={props.touched}
        />

        <div className="application-form px-5 py-5">
          <fieldset disabled={isReadOnly} className="py-3 px-4 mb-3">
            {renderCurrentTab()}
          </fieldset>
          <ApplicationNextBackButton
            currentPropertyIndex={currentPropertyIndex}
            properties={properties}
            isReadOnly={isReadOnly}
            handleTabChange={handleTabChange}
          />
        </div>
      </div>
    </Form>
  );
};

export default ApplicationForm;
