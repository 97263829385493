import axios from "lib/axios";
import { encodeParams } from 'lib/utils';

export const fetchActiveIncentives = (params = {}) => {
  let url = `/incentives`;
  
  // Filter out null and undefined values
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value != null)
  );

  const encodedParams = encodeParams(filteredParams);

  return axios
    .get(url, {
      params: encodedParams,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};

export const fetchIncentiveTypes = () => {
  let url = `/incentive_types`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}
