import axios from "lib/axios";

export const claimCompanyFacility = (params = {}) => {
  const url = `/company/company_facility/${params.id}/claim`;
  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateCompanyFacility= (params = {}) => {
  const url = `/company/company_facility/${params.id}`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}
