import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

import Loading from '../loading'
import CsvTable from './csv-table'

const XlsxPreview = (props) => {
  const [ isLoading, setIsLoading ] = useState(true)
  const [ state, setState ] = useState({})

  useEffect(() => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', props.url, true);
    xhr.responseType = "arraybuffer";

    xhr.onload = function(e) {
      const workbook = XLSX.read(e.target.response);
      const names = Object.keys(workbook.Sheets);
      const sheets = names.map(name => (
        // Hack to render with bootstrap table
        XLSX.utils.sheet_to_html(workbook.Sheets[name], {
          editable: false, id: 'newid" class="table table-bordered',
        })
      ));

      setState({ sheets, names, currentSheetIndex: 0 })
      setIsLoading(false)
    }

    xhr.send()
  }, [])

  function renderSheetNames() {
    const tabs = state.names.map((name, index) => {
      const active = index === state.currentSheetIndex ? 'active' : ''

      return (
        <li className="nav-item" key={name} onClick={(e) => {
          e.preventDefault()
          setState({ ...state, currentSheetIndex: index })
        }}>
          <a className={`nav-link ${active}`} aria-current="page">
            { name }
          </a>
        </li>
      )
    })

    return (
      <ul className="nav nav-tabs">
        { tabs }
      </ul>
    )
  }

  function renderSheetData(sheet) {
    return (
      <div id="xlsx-table-rendered" className="table-responsive mt-3">
        <div dangerouslySetInnerHTML={{__html: sheet}} />
      </div>
    )
  }

  function renderData() {
    return (
      <div className='csv-wrapper py-3'>
        { renderSheetNames() }
        { renderSheetData(state.sheets[state.currentSheetIndex || 0]) }
      </div>
    )
  }

  return (
    <div>
      { isLoading ? <Loading /> : renderData() } 
    </div>
  )
}

export default XlsxPreview;