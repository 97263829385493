import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import TextareaAutosize from "react-textarea-autosize";
import { NumericFormat } from "react-number-format";

import ProjectAllocator from "./project-allocator";
import TagEditor from "components/common/tag-editor";
import { displayPrice } from "lib/string";

const LineItemRow = (props) => {
  const { item, company_id, isNew } = props;
  const [editing, setEditing] = useState(false);

  if (editing) {
    return null;
  }

  return (
    <>
      <tr>
        <td>
          <button
            className="btn btn-sm btn-muted"
            onClick={(e) => {
              e.preventDefault();
              props.removeItem(item);
            }}
          >
            <i className="bi bi-x-lg"></i>
          </button>
        </td>
        <td>
          <TextareaAutosize
            minRows={1}
            name="description"
            className="form-control"
            value={item.description}
            onChange={(e) => {
              e.preventDefault();
              props.updateItem(item, "description", e.target.value);
            }}
          />
        </td>

        <td>
          <NumericFormat
            allowNegative={false}
            value={item.quantity}
            className="form-control"
            decimalScale={4}
            fixedDecimalScale={true} // Always count in decimals
            onValueChange={(values, sourceInfo) => {
              props.updateItem(item, "quantity", values.floatValue);
            }}
          />
        </td>
        <td>
          <CurrencyInput
            id="unit_cost"
            allowNegative={false}
            className="form-control"
            placeholder="0.00"
            prefix="$"
            defaultValue={item.unit_cost || 0}
            decimalsLimit={2}
            onValueChange={(value) => {
              props.updateItem(item, "unit_cost", parseFloat(value));
            }}
          />
        </td>
        <td>{displayPrice(item.unit_cost * item.quantity)}</td>
        {!isNew && props.canAllocate && (
          <td>
            <ProjectAllocator
              isNew={isNew}
              item={{
                ...item,
                allocation_type:
                  props.endpoint === "payables"
                    ? "CompanyPurchase"
                    : "CompanyReceivableLineitem",
              }}
              projects={props.projects}
              allocatedProjects={props.allocatedProjects}
              fetchAllocatedProjects={props.fetchAllocatedProjects}
              company_id={company_id}
            />
          </td>
        )}
      </tr>
      <tr>
        <td colSpan={5}>
          <TagEditor
            title={item.description}
            tags={item.tags}
            onChange={(tags) => {
              props.updateItem(item, "tags", tags);
            }}
          />
        </td>
      </tr>
    </>
  );
};

export default LineItemRow;
