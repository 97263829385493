import React, { useState, useContext } from "react";
import { useBoolean } from "react-hanger";

import OrganizationContactForm from "./organization-contact-form";
import { EditOrganizationContext } from "./edit-organization-context";

const OrganizationContacts = () => {
  const {
    organizationContacts,
    onDeleteOrganizationContact,
    onSaveOrganizationContact,
    basicInfo,
  } = useContext(EditOrganizationContext);
  const showContactForm = useBoolean(false);

  const [editOrganizationContact, setEditOrganizationContact] = useState(null);

  const makeOrganizationContact = (contact) => {
    const phoneNumber =
      contact.mobile_number && contact.mobile_number !== ""
        ? contact.mobile_number
        : contact.office_number || "";

    const titlePart = contact.title ? `, ${contact.title}` : "";

    const phonePart = phoneNumber ? ` - ${phoneNumber}` : "";

    return `${contact.first_name} ${contact.last_name}${titlePart} (${contact.email})${phonePart}`;
  };

  const afterContactSave = () => {
    setEditOrganizationContact(null);
    showContactForm.setFalse();
  };

  const saveContact = (organizationContact) => {
    onSaveOrganizationContact(organizationContact).then(afterContactSave);
  };

  const cancelEdit = () => {
    setEditOrganizationContact(null);
    showContactForm.setFalse();
  };

  return (
    <div>
      <div className="panel-header pb-3 ps-0 pt-0">
        <div className="row">
          <h3> {basicInfo.name} Contacts</h3>
          <hr />
        </div>
      </div>
      <div className="col-md-12">
        {organizationContacts.value.map((contact) => {
          return (
            <div key={contact.id} className="card p-3 mb-3">
              {editOrganizationContact !== null &&
              editOrganizationContact.id === contact.id ? (
                <OrganizationContactForm
                  organizationContact={editOrganizationContact}
                  onSaveOrganizationContact={saveContact}
                  onCancel={cancelEdit}
                />
              ) : (
                <div className="d-flex">
                  <div>
                    <span>{makeOrganizationContact(contact)}</span>
                  </div>
                  <div className="ms-auto d-flex align-self-start flex-wrap">
                    <div className="btn-group">
                      <button
                        className="btn btn-light"
                        onClick={() => setEditOrganizationContact(contact)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-dark mx-1"
                        onClick={() => onDeleteOrganizationContact(contact)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {showContactForm.value ? (
        <div className="col-md-12">
          <div className="card mb-3 p-3">
            <OrganizationContactForm
              onSaveOrganizationContact={saveContact}
              onCancel={cancelEdit}
            />
          </div>
        </div>
      ) : (
        <button
          className="btn-attach float-end mb-3"
          onClick={showContactForm.setTrue}
        >
          Add Contact
        </button>
      )}
    </div>
  );
};

export default OrganizationContacts;
