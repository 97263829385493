import React from 'react';
import TextTruncate from 'react-text-truncate';

const IncentiveRow = (props) => {
  const { incentive } = props;

  function handleClick(e) {
    e.preventDefault();
    window.location = props.location;
  }

  return (
    <tr onClick={handleClick}>
      <td>
        <img 
          src={incentive.sponsoring_organization.logo} 
          alt={incentive.sponsoring_organization_name} 
          width='80px'
          className='mb-3'
        />
      </td>
      <td>
        <h5>{ incentive.name }</h5>
        <TextTruncate
          line={2}
          element="span"
          truncateText="…"
          text={incentive.short_description}
        />
      </td>
    </tr>
  )
}

export default IncentiveRow;