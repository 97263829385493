import React from "react";
import { filter } from "lodash";

import UserAutocomplete from "../common/user-autocomplete";

const UserSection = ({ variables, onFieldChange }) => {
  const onAddUser = (user) => {
    onFieldChange(
      [
        ...variables.users,
        {
          full_name: user.full_name,
          user_id: user.id,
          is_admin: false,
          email: user.email,
        },
      ],
      "users"
    );
  };

  const toggleUserAdmin = (user) => {
    const newUsers = variables.users.map((u) => {
      if (user.user_id === u.user_id) {
        u.is_admin = !u.is_admin;
      }
      return u;
    });
    onFieldChange(newUsers, "users");
  };

  const onRemoveUser = (user) => {
    const newUsers = filter(variables.users, (o) => {
      return o.user_id != user.user_id;
    });
    onFieldChange(newUsers, "users");
  };

  return (
    <div className="card p-3 mt-1 mb-3 z-depth-2">
      <div className="row">
        <h3 className="panel-title">Attach Users to Company</h3>
        <div className="row">
          <div className="col-md-6">
            <UserAutocomplete
              onAddUser={onAddUser}
              currentUsers={variables.users}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div>
            {variables.users.map((user) => {
              return (
                <div key={user.user_id} className="card p-3 mt-1">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <button
                        className="btn btn-danger remove-user-btn btn-sm"
                        onClick={() => onRemoveUser(user)}
                      >
                        <i className="px-1 bi bi-trash"></i>
                      </button>
                      <div>{`${user.full_name} - ${user.email}`}</div>
                    </div>
                    <label htmlFor={`is_admin-${user.user_id}`}>
                      <input
                        type="checkbox"
                        id={`is_admin-${user.user_id}`}
                        className="company-admin-checkbox"
                        checked={user.is_admin}
                        onChange={() => {
                          toggleUserAdmin(user);
                        }}
                      />
                      Admin
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSection;
