import React, { Component, useEffect, useState, useCallback } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import UIBooleanRadioInputFormik from "./ui-boolean-radio-input-formik";
import UILabelInputFormik from "./ui-label-input-formik";
import UIMoneyLabelInputFormik from "./ui-money-label-input-formik";
import UITextareaLabelInputFormik from "./ui-textarea-label-input-formik";
import BusinessEntityInputFormik from "./ui-business-entity-input-formik";
import UIAsyncSelectInput from "../ui-async-select-input";
import UIDatetimeInputFormik from "./ui-datetime-input-formik";
import UIMultiSelectInputFormik from "./ui-multi-select-input-formik";
import UIProjectSelectInput from "./ui-project-select-input";
import UIProjectAdder from "./ui-project-adder";
import UIAdderEmployee from "./ui-adder-employee";

import { loadNaicsCodeOptions } from "modules/user/companies";

const getComponentFromSchema = (
  name,
  schema,
  prefix,
  application,
  applicationSlug
) => {
  // TODO: At what point will this methodology need to be refactored?
  if (schema.inputType == "whitespace" || schema.hidden === true) {
    return <div></div>;
  } else if (schema.type == "string") {
    if (schema.inputType == "textarea") {
      return (
        <div key={`${prefix}.${name}_input`} className="row mb-4">
          <UITextareaLabelInputFormik
            label={schema.label}
            tooltip={schema.tooltip}
            description={schema.description}
            name={`${prefix}.${name}`}
            required={schema.required}
          />
        </div>
      );
    } else if (schema.inputType == "entityTypeDropdown") {
      return (
        <div key={`${prefix}.${name}_input`} className="row mb-4">
          <BusinessEntityInputFormik
            label="Tax Entity Type"
            name={`${prefix}.${name}`}
            required={schema.required}
          />
        </div>
      );
    } else if (schema.inputType == "datepicker") {
      return (
        <div key={`${prefix}.${name}_input`} className="row mb-4">
          <UIDatetimeInputFormik
            label={schema.label}
            description={schema.description}
            name={`${prefix}.${name}`}
            required={schema.required}
            minDate={schema.minDate}
            maxDate={schema.maxDate}
            dateFormat={schema.dateFormat}
            showYearPicker={schema.showYearPicker}
            tooltip={schema.tooltip}
          />
        </div>
      );
    } else {
      return (
        <div key={`${prefix}.${name}_input`} className="row mb-4">
          <UILabelInputFormik
            label={schema.label}
            description={schema.description}
            tooltip={schema.tooltip}
            name={`${prefix}.${name}`}
            required={schema.required}
          />
        </div>
      );
    }
  } else if (schema.type == "number") {
    if (schema.inputType == "money") {
      return (
        <div key={`${prefix}.${name}_input`} className="row mb-4">
          <UIMoneyLabelInputFormik
            label={schema.label}
            description={schema.description}
            name={`${prefix}.${name}`}
            required={schema.required}
            tooltip={schema.tooltip}
          />
        </div>
      );
    } else {
      return (
        <div key={`${prefix}.${name}_input`} className="row mb-4">
          <UILabelInputFormik
            label={schema.label}
            description={schema.description}
            name={`${prefix}.${name}`}
            required={schema.required}
            tooltip={schema.tooltip}
          />
        </div>
      );
    }
  } else if (schema.type == "boolean") {
    return (
      <div key={`${prefix}.${name}_input`} className="row mb-4">
        <UIBooleanRadioInputFormik
          label={schema.label}
          description={schema.description}
          name={`${prefix}.${name}`}
          trueLabel={schema.trueLabel}
          falseLabel={schema.falseLabel}
          required={schema.required}
          tooltip={schema.tooltip}
        />
      </div>
    );
  } else if (schema.type == "action") {
    switch (schema.inputType) {
      case "projectAdder":
        return (
          <div key={`${prefix}.${name}_input`} className="row mb-4">
            <UIProjectAdder
              cta={schema.cta}
              application={application}
              description={schema.description}
              header={schema.header}
              tooltip={schema.tooltip}
              applicationSlug={applicationSlug}
            />
          </div>
        );
      case "addEmployee":
        return (
          <div key={`${prefix}.${name}_input`} className="row mb-4">
            <UIAdderEmployee
              cta={schema.cta}
              application={application}
              description={schema.description}
              header={schema.header}
              tooltip={schema.tooltip}
              applicationSlug={applicationSlug}
            />
          </div>
        );
      case "addEmployee":
        return (
          <div key={`${prefix}.${name}_input`} className="row mb-4">
            <UIAdderEmployee
              cta={schema.cta}
              application={application}
              description={schema.description}
              header={schema.header}
              tooltip={schema.tooltip}
            />
          </div>
        );
      default:
        return (
          <div key={`${prefix}.${name}_input`}>Error: Unknown action type</div>
        );
    }
  } else if (schema.type == "array") {
    if (schema.inputType == "naicsDropdown") {
      return (
        <div key={`${prefix}.${name}_input`} className="row mb-4">
          <UIAsyncSelectInput
            label={schema.label}
            description={schema.description}
            loadOptions={loadNaicsCodeOptions}
            tooltip="A NAICS code stands for North American Industry Classification System code, a six-digit number that categorizes North American businesses by their economic activities. It's used for statistical analysis, market research, and regulatory purposes, helping to identify trends and compare economic data across the U.S., Canada, and Mexico. This standardized system facilitates government reporting, economic studies, and business analysis. If you don't know yours, you can search by industry below."
            name={`${prefix}.${name}`}
            isMulti
            required={schema.required}
          />
        </div>
      );
    } else if (schema.inputType == "multiSelect") {
      return (
        <div key={`${prefix}.${name}_input`} className="row mb-4">
          <UIMultiSelectInputFormik
            name={`${prefix}.${name}`}
            label={schema.label}
            description={schema.description}
            options={schema.items}
            required={schema.required}
            tooltip={schema.tooltip}
            isMulti={schema.isMulti}
          />
        </div>
      );
    } else if (schema.inputType == "projectSelect") {
      return (
        <div key={`${prefix}.${name}_input`} className="row mb-4">
          <UIProjectSelectInput
            name={`${prefix}.${name}`}
            label={schema.label}
            options={schema.items}
            required={schema.required}
            tooltip={schema.tooltip}
            application={application}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  }
};

const sortProperties = (properties) => {
  let sortedProperties = {};

  Object.entries(properties).forEach((field) => {
    if (field[1].row in sortedProperties) {
      sortedProperties[field[1].row].push(field);
    } else {
      sortedProperties[field[1].row] = [field];
    }
  });

  Object.keys(sortedProperties).forEach((key) => {
    sortedProperties[key].sort((a, b) => {
      return a[1].col - b[1].col;
    });
  });

  return sortedProperties;
};

const updateConditionalProperties = (schema, values) => {
  let whenConditionals = schema.when;
  if (whenConditionals === undefined) {
    return schema;
  }

  Object.entries(whenConditionals).forEach((whenConditional) => {
    let name = whenConditional[0];
    let value = values[name];

    let whenProperties = whenConditional[1];
    let equalityValue = whenProperties.is;

    if (equalityValue !== undefined) {
      if (value == equalityValue) {
        schema = { ...schema, ...whenProperties.then };
      } else {
        schema = { ...schema, ...whenProperties.otherwise };
      }
    }
  }, values);

  return schema;
};

const buildFormFromSchema = (
  schema,
  prefix,
  values,
  application,
  applicationSlug,
  subsection = false
) => {
  let sortedProperties = sortProperties(schema.properties);

  return (
    <div className="row" key={`schema-${prefix}`}>
      {!subsection && !!schema.label && (
        <>
          <div className="panel-title-with-info">
            <h3>{schema.label}</h3>
            {schema.tooltip && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip className="detail-tooltip">{schema.tooltip}</Tooltip>
                }
              >
                <i
                  className="bi bi-info-circle info-icon"
                  style={{ textAlign: "top" }}
                ></i>
              </OverlayTrigger>
            )}
          </div>
        </>
      )}
      {subsection && !!schema.label && (
        <>
          <hr />{" "}
          <h5>
            <strong>{schema.label}</strong>
          </h5>
        </>
      )}
      {!!schema.description && (
        <p className="input-section-description">{schema.description}</p>
      )}

      {Object.entries(sortedProperties).map((row, index) => {
        return (
          <div className="row" key={`form-schema-${index}`}>
            {row[1].map((field, rowIndex) => {
              let name = field[0];
              let propertySchema = updateConditionalProperties(
                field[1],
                values
              );

              if (propertySchema.type == "object") {
                return buildFormFromSchema(
                  propertySchema,
                  prefix + "." + name,
                  values[name],
                  true
                );
              } else {
                return (
                  <div
                    className="col md-6"
                    key={`component-${rowIndex}-${name}`}
                  >
                    {getComponentFromSchema(
                      name,
                      propertySchema,
                      prefix,
                      application,
                      applicationSlug
                    )}
                  </div>
                );
              }
            })}
          </div>
        );
      })}
    </div>
  );
};

const UIFieldGroup = (props) => {
  return buildFormFromSchema(
    props.schema,
    props.prefix,
    props.values,
    props.application,
    props.applicationSlug
  );
};

export default UIFieldGroup;
