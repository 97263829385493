import React, { useState, useEffect } from "react";

const QualifiedCompanies = ({ incentive }) => {
  const [incentiveState, setIncentiveState] = useState(null);

  useEffect(() => {
    if (
      incentive &&
      Array.isArray(incentive.incentive_company_qualifications)
    ) {
      setIncentiveState(incentive);
    }
  }, [incentive]);

  if (!incentiveState) {
    return <div>Loading...</div>;
  }

  const { incentive_company_qualifications } = incentiveState;

  const sortedIncentiveQualifications = [
    ...incentive_company_qualifications,
  ].sort((a, b) => a.company_name.localeCompare(b.company_name));

  return (
    <div>
      <table className="table admin-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Company Name</th>
            <th>Primary Address</th>
            <th>Referred By</th>
            <th>Users</th>
          </tr>
        </thead>
        <tbody>
          {sortedIncentiveQualifications.map((qualification, index) => (
            <tr key={index}>
              <td className="admin-metadata">{qualification.company_id}</td>
              <td>
                <a href={`/admin/companies/${qualification.company_id}/edit`}>
                  {qualification.company_name}
                </a>
              </td>
              <td>{qualification.company_address}</td>
              <td>{qualification.company_referral_source}</td>
              <td>{qualification.company_user_count ?? "0"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QualifiedCompanies;
