import React from "react";
import { KeywordSearch } from "components/ui";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SearchBar = ({
  state,
  setState,
  updatePagination,
  onSearchActivate,
  placeholder,
  showFilters,
  setShowFilters,
  handleCopyUrl,
  isDashboard,
  background,
  size,
}) => {
  return (
    <div
      className={
        isDashboard
          ? "row justify-content-between"
          : "keyword-search-hero-container"
      }
    >
      <div className={isDashboard ? "col-md-8" : ""}>
        <KeywordSearch
          state={state}
          setState={setState}
          updatePagination={updatePagination}
          placeholder={placeholder}
          onSearchActivate={onSearchActivate}
          background={background}
          size={size}
        />
      </div>
      {isDashboard && (
        <div className="col-md-4 d-flex align-items-center justify-content-end">
          <button
            className="btn-subdued"
            style={{ border: "1px solid #e0e0e0" }}
            onClick={() => setShowFilters(!showFilters)}
          >
            <i className="bi bi-filter"></i> Filters
          </button>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className="cashflow-tooltip">
                Click here to copy a sharable link for this dashboard.
              </Tooltip>
            }
          >
            <button
              className="btn btn-outline-secondary"
              style={{
                border: "1px solid #e0e0e0",
                padding: "6px 12px",
                backgroundColor: "#fafbfc",
              }}
              onClick={handleCopyUrl}
            >
              <i className="bi bi-share"></i>
            </button>
          </OverlayTrigger>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
