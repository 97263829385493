import axios from "lib/axios";

export const getCashProjections = (params = {}) => {
  const url = `/cash_projections`;

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}
