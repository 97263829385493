import React from "react";
import UIFormLabel from "./ui-form-label";
import UIValidationError from "./ui-validation-error";

const UILabelInput = (props) => {
  const value = props.value ? String(props.value) : "";
  const isRequired = props.required === undefined ? true : props.required;
  const className = props.className
    ? "ui-label-input " + props.className
    : "ui-label-input";
  const charCount = value.length;
  const isOverLimit = props.charLimit && charCount > props.charLimit;

  return (
    <div className={className}>
      <UIFormLabel
        for={props.id}
        label={props.label}
        required={props.required}
        strong={props.strong}
        tooltip={props.tooltip}
      />
      <div className="input-group">
        {props.prepend && (
          <div className="input-group-prepend">
            <span className="input-group-text">{props.prepend}</span>
          </div>
        )}
        <input
          id={props.id}
          value={value}
          name={props.label}
          type={props.type || "text"}
          className="form-control"
          required={isRequired}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          placeholder={props.placeholder || props.label}
        />
      </div>
      {props.charLimit && (
        <span
          className="form-text"
          style={{ color: isOverLimit ? "red" : "inherit" }}
        >
          {`(${charCount} / ${props.charLimit})`}
        </span>
      )}
      {!!props.error && <UIValidationError message={props.error} />}
    </div>
  );
};

export default UILabelInput;
