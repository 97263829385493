import React from "react";
import { flashError, flashSuccessLater } from "../../../../lib/flash";
import { createAdminMetaField } from "../../../../modules/meta-eligibility-fields";

import MetaFieldForm from "../common/meta-field-form";

const New = ({ meta_eligibility_field, fieldTypes, tableSchema }) => {

  const onSaveField = (variables) => {
    createAdminMetaField({
      meta_eligibility_field: variables
    }).then((res) => {
      if (res.errors)
        res.errors.map((err) => flashError(err))
      else {
        flashSuccessLater("Meta Field Added")
        window.location = "/admin/meta_eligibility_fields"

      }
    })
  };

  return (
    <div className="col-md-12">
      <div>
        <div className="row">
          <h1 class="my-0">Create New Field</h1>
        </div>
      </div>
      <MetaFieldForm tableSchema={tableSchema} fieldTypes={fieldTypes} metaEligibilityField={meta_eligibility_field} onSaveField={onSaveField} />

    </div>
  );
};

export default New;
