import React, { useState, useEffect } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import AddWorkerModal from "../../companies/edit/add-worker-modal";
import {
  getApplicationWorkerResources,
  createApplicationWorkerResources,
  deleteApplicationWorkerResources,
} from "modules/applications";
import { flashError, flashSuccess } from "lib/flash";

const UIAdderEmployee = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [workerResources, setWorkerResources] = useState([]);

  const EmployeeUploadSection = ({ applicationSlug }) => (
    <p>
      Or, if you prefer, you can{" "}
      <strong>
        <a href={`/applications/${applicationSlug}#/documents`}>
          upload your employee list directly
        </a>{" "}
      </strong>
      as W-2s (preferred) or in a spreadsheet format.{" "}
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip className="detail-tooltip">
            W-2 forms are preferred as they provide a foundational basis for
            establishing nexus in the context of R&D tax credits. They offer
            precise employee location data, essential for substantiating where
            research activities occur, thereby streamlining the qualification
            process and optimizing your tax credit potential.
          </Tooltip>
        }
      >
        <i className="bi bi-info-circle" style={{ marginLeft: "10px" }}></i>
      </OverlayTrigger>
    </p>
  );

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const onWorkerAdded = (worker) => {
    console.log("Worker added:", worker);
    if (worker.id && props.application) {
      createApplicationWorkerResources(props.application, [worker.id])
        .then(() => {
          console.log("Worker linked to application successfully");
          fetchApplicationWorkerResources();
        })
        .catch((error) => {
          console.error("Error linking worker to application:", error);
        });
    } else {
      console.error("Missing worker id or application id");
    }
    setShowModal(false);
  };

  const handleDeleteWorkerResource = (workerId) => {
    if (props.application && workerId) {
      deleteApplicationWorkerResources(props.application, [workerId])
        .then(() => {
          console.log(`Worker resource ${workerId} deleted successfully`);
          flashSuccess(
            `Worker ${workerId} removed from application. To permanently delete this worker from your account go to the setting menu.`
          );
          fetchApplicationWorkerResources();
        })
        .catch((error) => {
          console.error(`Error deleting worker resource ${workerId}:`, error);
        });
    } else {
      console.error("Missing worker id or application id for deletion");
    }
  };

  const fetchApplicationWorkerResources = () => {
    if (props.application) {
      getApplicationWorkerResources(props.application)
        .then((data) => {
          setWorkerResources(data);
        })
        .catch((error) => {
          console.error("Error fetching application worker resources:", error);
        });
    }
  };

  useEffect(() => {
    fetchApplicationWorkerResources();
  }, [props.application]);

  return (
    <>
      {workerResources.length === 0 ? (
        <div
          className="col-md-12 mb-4"
          style={{
            borderRadius: "8px",
            border: "1px solid rgba(170, 170, 170, 0.5)",
            backgroundColor: "#F8FCFF",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            gap: "10px",
          }}
        >
          <h4 className="mt-4 mb-0">
            {props.header}
            {props.tooltip ? (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip className="detail-tooltip">{props.tooltip}</Tooltip>
                }
              >
                <i
                  className="bi bi-info-circle"
                  style={{ marginLeft: "10px" }}
                ></i>
              </OverlayTrigger>
            ) : null}
          </h4>

          <span className="description">{props.description}</span>
          <div className="mb-2 mt-2" style={{ alignSelf: "flex-start" }}>
            <button
              type="button"
              className="btn-attach mb-4"
              onClick={handleModalOpen}
            >
              <span className="btn-action-plus">
                <i className="bi bi-plus"></i>
              </span>
              {props.cta}
            </button>
            <EmployeeUploadSection applicationSlug={props.applicationSlug} />
          </div>
        </div>
      ) : (
        <>
          <div className="col-md-12 mb-4">
            <h3 className="mb-4">Employees associated with this application</h3>
            <br />
            <p>
              Here's where you can add, edit, or delete any of the employees
              associated with this application.
            </p>
          </div>
          <div className="col-md-12">
            <table className="table admin-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Department</th>
                  <th>Job Title</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {workerResources.map((resource, index) => (
                  <tr key={index}>
                    <td>{resource.name}</td>
                    <td>{resource.department_name}</td>
                    <td>{resource.job_title}</td>
                    <td style={{ width: "3%" }}>
                      {/* <button className="btn btn-muted">Edit</button> */}
                    </td>
                    <td style={{ width: "5%" }}>
                      <button
                        type="button"
                        className="btn btn-danger float-end"
                        onClick={() => handleDeleteWorkerResource(resource.id)}
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-md-12 text-right mb-4">
            <button className="btn-attach mb-4" onClick={handleModalOpen}>
              <span className="btn-action-plus">
                <i className="bi bi-plus"></i>
              </span>
              {props.cta}
            </button>
            <EmployeeUploadSection applicationSlug={props.applicationSlug} />
          </div>
        </>
      )}

      <AddWorkerModal
        show={showModal}
        onHide={handleModalClose}
        onWorkerAdded={onWorkerAdded}
      />
    </>
  );
};

export default UIAdderEmployee;
