import React from "react";

const StyleGuideLink = ({ CTA = "Style Guide", guideType }) => {
  const guideLinks = {
    intakeSchema:
      "https://docs.google.com/document/d/1AprXsTCWAwKhnthLoiWnep8qsEitvYvvZAhFivVtJC0/edit",
    microSite:
      "https://www.notion.so/subcity/Launching-marketing-product-microsites-22c707fe185142a6a9cc0925a733cd5c",
    createCompany:
      "https://www.notion.so/subcity/Create-Company-Profile-13949ecc37bf4a969159fa9c63a887b3",
    // Add more guide types and their corresponding URLs here
  };

  const guideUrl = guideLinks[guideType] || "#";

  return (
    <a
      href={guideUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="btn btn-outline-primary"
    >
      {CTA}
    </a>
  );
};

export default StyleGuideLink;
