import React, { useState, useEffect } from "react";
import { submitEmail } from 'modules/email-list'

const Total = (props) => {
  const answers = props.answers || {};
  const [displayState, setDisplayState] = useState('valid')
  const [errorMessage, setErrorMessage] = useState('')
  const [total, setTotal] = useState(0)
  const [email, setEmail] = useState('');
  const [emailFormState, setEmailFormState] = useState('')

  var nf = new Intl.NumberFormat();

  const validateAnswerOptions = () => {
    const options = Object.keys(answers);
    for(var i = 0; i < options.length; i++) {
      const option = options[i];
      const answer = answers[option];

      if(answer.validate) {
        if(!answer.acceptedValues.includes(answer.value)) {
          setDisplayState('invalid')
          setErrorMessage(answer.errorMessage)
          return false;
        } 
      }
    }

    setDisplayState('valid')
    setErrorMessage('')

    return true;
  }

  const calculateTotal = () => {
    setTotal(props.calculator.calculate(answers));
  }

  useEffect(() => {
    if(validateAnswerOptions()) {
      calculateTotal();
    }
  }, [answers])


  function handleSubmit(e) {
    e.preventDefault();

    submitEmail({
      email: email,
      source: 'General Calculator' 
    }).then((response) => {
      setEmailFormState('success')
    }).catch((e) => {
      setEmailFormState('error')
    })
  }

  const renderTotal = () => {
    return (
      <>
        <div className='d-lg-none'>
          <div className='col-md-12 mt-3'>
            <div className='input-group'>
              <div className='form-control big-number'>
                {`$${ nf.format(total.toFixed()) }`}
              </div>
              <a className="btn btn-action" href="/register">
                Register 
              </a>
            </div>
          </div>
        </div>
        <div className='d-none d-lg-block'>
          <div className='col-md-12'>
            <a className="btn btn-action float-end" href="/register">
              Claim your money 
            </a>
            <h3>Estimated Incentive Dollars Available</h3>
          </div>
          <div className='col-md-12 mt-3'>
            <div className='big-number'>
              {`$${ nf.format(total.toFixed()) }`}
            </div>
          </div>
        </div>
      </>
    )
  }

  function renderEmailCapture() {
    if(emailFormState === '' || emailFormState === 'error') {
      return (
        <div className='col-md-12 mt-3'>
          <form onSubmit={handleSubmit} className='form'>
            <p className='text-dark'>{ errorMessage }</p>
            <div className="input-group">
              <input type="email" className="form-control" onChange={(e) => {
                e.preventDefault();
                setEmail(e.target.value)
              }} value={email} placeholder="name@example.com" /> 
              <button className='btn btn-action'>Sign Up</button>
            </div>
            { emailFormState === 'error' ? 
              <p className='text-danger'>Something went wrong, try again</p> : ''
            }
          </form>
        </div>
      )
    } else if (emailFormState === 'success') {
      return (
        <div className='col-md-12 mt-3'>
          <p className='text-dark'>Success! Your email has been saved and we'll be in touch</p>
        </div>
      )
    }
  }

  return (
    <div className='total-footer'>
      <div className='container mt-3'>
        <div className='row'>
          <div className='col-md-6 offset-md-3'>
            <div className='row'>
              {
                displayState === 'valid' ?
                  renderTotal() : renderEmailCapture()
              }
              <div className='col-md-12'>
                <p className='mt-3 text-dark text-center' style={{'fontSize': '10px'}}>
                  *Disclaimer: The estimate provided is meant only to give you an idea of savings, actual savings may vary depending on the activities your company performs. Please contact our representative to better assess your situation and provide a more detailed estimate for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Total;
