import React, { useEffect, useState } from 'react';
import Papa from 'papaparse' 

const CsvTable = (props) => {
  const [ rows, setRows ] = useState([]);
  const [ headers, setHeaders ] = useState([])

  function importCSV(data) {
    const result = Papa.parse(data, { delimiter: "", newline: "", skipEmptyLines: 'greedy' })
    setHeaders(result.data[0])
    setRows(result.data.slice(1))
  }

  useEffect(() => {
    importCSV(props.data || [])
  }, [])

  useEffect(() => {
    importCSV(props.data)
  }, [props.data])

  return (
    <table className='table table-bordered'>
      <thead>
        <tr>
          { headers.map((header, index) => {
              return (
                <th key={`header-${index}`}>{ header }</th>
              )
          })}
        </tr>
      </thead>
      <tbody>
        { rows.map((row, i) => {
            return (
              <tr key={`row-${i}`}>
                { row.map((column, j) => {
                    return (
                      <td key={`row-${i}-col-${j}`}>
                        { column }
                      </td>
                    )
                })}
              </tr>
            )
        })}
      </tbody>
    </table>
  );
}

export default CsvTable;