import React, { useState, useEffect, useRef } from "react";
import { fetchAdminUsers } from "modules/users";
import {
  withUIPaginate,
  UIPaginationTotalSelector,
  UIPagination,
  UITableSortable,
} from "components/ui";
import AdminPanelHeader from "../../common/admin-panel-header";
import AdminPanelIndexControls from "../../common/admin-panel-index-controls";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { setURLParams, getUrlParams } from "lib/utils";

import { useSetState } from "react-hanger";
import { flashError } from "lib/flash";

const Users = (props) => {
  const superUserHash = {
    "": "All",
    true: "Super users only",
    false: "Non super users only",
  };
  const accessHash = { "": "All", true: "Granted", false: "Locked" };

  const url = new URL(window.location);

  const { state, setState } = useSetState(() => ({
    query: getUrlParams(url, "query") || "",
    sortColumn: getUrlParams(url, "sortColumn") || "first_name",
    sortDirection: getUrlParams(url, "sortDirection") || "ASC",
  }));

  const { pagination } = props;
  const [users, setUsers] = useState(props.users);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      sortDirection: prevState.sortDirection || "ASC",
    }));
  }, []);

  const handleSelect = (e) => {
    let dropdown = e.split(", ");
    let key = dropdown[0];
    let value = dropdown[1];

    if (value != "") {
      value = JSON.parse(value);
    }

    handleFieldChange(key, value);
  };

  const superUserOptions = Object.keys(superUserHash).map((key) => ({
    value: key,
    label: superUserHash[key],
  }));
  const accessOptions = Object.keys(accessHash).map((key) => ({
    value: key,
    label: accessHash[key],
  }));

  const handleFieldChange = (field, value) => {
    setState({
      [field]: value,
    });
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    fetchUsers();
  }, [state]);

  useEffect(() => {
    if (pagination.first_load) return;

    fetchUsers();
  }, [pagination]);

  const fetchUsers = () => {
    const params = {
      ...pagination,
      ...state,
      sort_direction: state.sortDirection,
      sort_column: state.sortColumn,
    };

    fetchAdminUsers(params).then((data) => {
      if (!data.success) {
        return flashError(data.message);
      }
      props.updatePagination({
        total: data.total,
        first_load: true,
      });

      setUsers(data.users);
    });
  };

  const onSortChange = (direction, column) => {
    setState((prevState) => ({
      ...prevState,
      sortDirection: direction,
      sortColumn: column,
    }));
  };

  return (
    <>
      <AdminPanelHeader
        title="Users"
        singularTitle="User"
        addNewUrl="/admin/users/new"
      />
      <AdminPanelIndexControls
        keywordSearchConfig={{
          state: state,
          setState: setState,
          updatePagination: props.updatePagination,
        }}
        selectConfigs={[
          {
            options: superUserOptions,
            value: state.super_user
              ? {
                  value: state.super_user,
                  label: superUserHash[state.super_user],
                }
              : null,
            onChange: (option) =>
              handleSelect(`super_user, ${option ? option.value : ""}`),
            className: "ui-multi-filter",
            placeholder: "Permission",
          },
          {
            options: accessOptions,
            value: state.access_granted
              ? {
                  value: state.access_granted,
                  label: accessHash[state.access_granted],
                }
              : null,
            onChange: (option) =>
              handleSelect(`access_granted, ${option ? option.value : ""}`),
            className: "ui-multi-filter",
            placeholder: "Access",
          },
        ]}
      />
      <div className="px-3">
        <div className="panel-table">
          <table className="table admin-table">
            <thead>
              <tr>
                <th>
                  <UITableSortable
                    title="Id"
                    sortColumn="id"
                    sortDirection={state.sortDirection}
                    currentSortColumn={state.sortColumn}
                    onSortChange={onSortChange}
                  />
                </th>
                <th>
                  <UITableSortable
                    title="Name"
                    sortColumn="first_name"
                    sortDirection={state.sortDirection}
                    currentSortColumn={state.sortColumn}
                    onSortChange={onSortChange}
                  />
                </th>
                <th>
                  {" "}
                  <UITableSortable
                    title="Sign-In Count"
                    sortColumn="sign_in_count"
                    sortDirection={state.sortDirection}
                    currentSortColumn={state.sortColumn}
                    onSortChange={onSortChange}
                  />
                </th>
                <th>Companies</th>
                <th>
                  {" "}
                  <UITableSortable
                    title="Latest Sign-In"
                    sortColumn="current_sign_in_at"
                    sortDirection={state.sortDirection}
                    currentSortColumn={state.sortColumn}
                    onSortChange={onSortChange}
                  />
                </th>
                <th>Permission</th>
                <th>Access</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => {
                return (
                  <tr className="row-with-shadow" key={`user-row-${index}`}>
                    <td className="admin-metadata">{user.id}</td>
                    <td>
                      <div className="user-info">
                        <p className="mb-0">{user.full_name}</p>
                        <p className="admin-metadata mb-0">{user.email}</p>
                      </div>
                    </td>
                    <td>{user.sign_in_count}</td>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip
                          id={`button-tooltip-${index}`}
                          className="cashflow-tooltip"
                        >
                          {user.companies_with_names.map(
                            (company, nameIndex) => (
                              <div key={`company-${nameIndex}`}>
                                {company.name}
                              </div>
                            )
                          )}
                        </Tooltip>
                      }
                    >
                      <td>{user.user_company_count}</td>
                    </OverlayTrigger>
                    <td>{user.current_sign_in_at_formatted}</td>
                    <td className="user-role">
                      {user.system_permission
                        ? user.system_permission.name
                        : "user"}
                      {user.is_superuser && (
                        <OverlayTrigger
                          key={`superuser-overlay-trigger-${user.id}`}
                          placement="bottom"
                          delay={{ show: 150, hide: 200 }}
                          overlay={
                            <Tooltip
                              id="button-tooltip"
                              className="cashflow-tooltip"
                            >
                              Super User
                            </Tooltip>
                          }
                        >
                          <i
                            className="bi bi-shield-fill-check"
                            style={{ marginLeft: "8px", color: "#00A2E9" }}
                          ></i>
                        </OverlayTrigger>
                      )}
                    </td>
                    <td>
                      <i
                        className={
                          user.is_locked ? "bi bi-lock-fill" : "bi bi-unlock"
                        }
                      ></i>
                    </td>
                    <td>
                      <a
                        href={`/admin/users/${user.id}/edit`}
                        className="btn btn-sm btn-outline-secondary action-buttons"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td colSpan={2}>
                  <div className="mt-2">
                    <UIPaginationTotalSelector
                      perPage={pagination.per_page}
                      changePerPage={(value) => {
                        props.updatePagination({
                          per_page: value,
                          first_load: false,
                        });
                      }}
                    />
                  </div>
                </td>

                <td colSpan={6}>
                  <span className="float-end mt-2">
                    {pagination.total > 0 ? (
                      <UIPagination
                        page={pagination.page}
                        perPage={pagination.per_page}
                        changePage={(page) => {
                          props.updatePagination({
                            page: page.selected + 1,
                            first_load: false,
                          });
                        }}
                        total={pagination.total}
                      />
                    ) : null}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default withUIPaginate(Users);
