import React, { useState } from "react";
import axios from "lib/axios";

import CashSummary from "./cash-summary";
import Accounts from "./accounts";
import Invoices from "./invoices";
import Offers from "./offers";
import IncentiveOffer from "./incentive-offer";
import CashBalanceProjection from "./cash-balance-projection";
import { UIJumbotron } from "components/ui/ui-jumbotron";
import { getCashProjections } from "modules/cash_projections";
import { parseWithOptions } from "date-fns/fp";
import { Dropdown, DropdownButton } from "react-bootstrap";
import moment from "moment";

const Finance = (props) => {
  const { jumbotron, offers } = props;

  const [showJumbotron, setShowJumbotron] = useState(true);

  const VALID_OFFERS = ["Employee Retention Tax Credit"];
  const MIN_DUE_DATE = moment()
    .subtract(1, "months")
    .format(moment.HTML5_FMT.DATE);

  const hideJumbotron = (e) => {
    e.preventDefault();
    axios.post(`/jumbotrons/${jumbotron.id}/dismiss`).catch((error) => {});
    setShowJumbotron(false);
  };

  function renderIncentiveOffer(offers) {
    if (!offers) return null;

    for (var i = 0; i < VALID_OFFERS.length; i++) {
      const key = VALID_OFFERS[i];
      if (offers.hasOwnProperty(key)) {
        return <IncentiveOffer offer={offers[key]} />;
      }
    }

    return null;
  }

  const [payableAmounts, setPayableAmounts] = useState({
    projected: 0,
    upcoming: 0,
  });
  const [receivableAmounts, setReceivableAmounts] = useState({
    projected: 0,
    upcoming: 0,
  });

  const setAmountsFromResponse = (total_amounts_by_status) => {
    let projectedPayables = 0;
    let upcomingPayables = 0;

    let projectedReceivables = 0;
    let upcomingReceivables = 0;

    Object.entries(total_amounts_by_status).forEach((total) => {
      let statuses = $.parseJSON(total[0]);
      let invoiceStatus = statuses[0];
      let cashBalanceStatus = statuses[1];
      let amount = total[1];

      if (cashBalanceStatus == "money out") {
        if (invoiceStatus == "Projected") {
          projectedPayables += parseFloat(amount);
        } else {
          upcomingPayables += parseFloat(amount);
        }
      } else {
        if (invoiceStatus == "Projected") {
          projectedReceivables += parseFloat(amount);
        } else {
          upcomingReceivables += parseFloat(amount);
        }
      }
    });

    setPayableAmounts({
      projected: projectedPayables,
      upcoming: upcomingPayables,
    });
    setReceivableAmounts({
      projected: projectedReceivables,
      upcoming: upcomingReceivables,
    });
  };

  const [cashBalanceAnnotationData, setCashBalanceAnnotationData] = useState(
    props.cash_balance_projection_annotation_data
  );
  const [cashBalanceProjectionData, setCashBalanceProjectionData] = useState(
    props.cash_balance_projection_data
  );

  function updateCashProjections() {
    getCashProjections()
      .then((response) => {
        setCashBalanceAnnotationData(response.annotations);
        setCashBalanceProjectionData(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch cash projections");
      });
  }

  const updateDashboard = (totals) => {
    setAmountsFromResponse(totals);
  };

  return (
    <div className="finance-dashboard">
      <div className="row mt-4">
        <div className="col-md-12">
          {showJumbotron && jumbotron !== null && (
            <div className="mb-4">
              <UIJumbotron jumbotron={jumbotron} />
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <Accounts
            cash_balance={props.cash_balance}
            last_synced={props.last_synced}
            accounts={props.financial_accounts}
            plaid_connected={props.plaid_connected}
            offers={offers}
          />
        </div>

        <div className="col-md-8">
          {props.show_graph && (
            <CashBalanceProjection
              data={cashBalanceProjectionData}
              annotation_data={cashBalanceAnnotationData}
              threshold={props.cash_balance_projection_threshold}
              cash_balance={props.cash_balance}
              incoming_receivables={receivableAmounts.upcoming}
              projected_receivables={receivableAmounts.projected}
              upcoming_payables={payableAmounts.upcoming}
              projected_payables={payableAmounts.projected}
              dropdownMenu={
                <>
                  <Dropdown.Item href="/download/payable_invoices.csv">
                    Download Payables as CSV
                  </Dropdown.Item>
                  <Dropdown.Item href="/download/receivable_invoices.csv">
                    Download Receivables as CSV
                  </Dropdown.Item>
                  <Dropdown.Item href="/company/edit#/cashflow/settings">
                    Add Recurring Payment
                  </Dropdown.Item>
                  <Dropdown.Item href="/company/edit#/cashflow/settings">
                    Manage Projection Categories
                  </Dropdown.Item>
                  <Dropdown.Item href="/company/edit#/cashflow/settings">
                    Set Cash Balance Threshold
                  </Dropdown.Item>
                </>
              }
            />
          )}

          {renderIncentiveOffer(offers)}

          <Invoices
            codat_connected={props.codat_connected}
            codat_data_synced={props.codat_data_synced}
            codat_last_synced={props.codat_last_synced}
            codat_provider={props.codat_provider}
            updateDashboard={updateDashboard}
            updateCashProjections={updateCashProjections}
            first_accounts_processing_done={
              props.first_accounts_processing_done
            }
            offers={offers}
            minDate={MIN_DUE_DATE}
          />

          {/*          <Offers
            incentives={props.incentives}
            applications={props.applications}
          />*/}
        </div>
      </div>
    </div>
  );
};

export default Finance;
