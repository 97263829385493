import React, { useState,  useEffect} from 'react';
import { UIColLabelInput, UIAddressInputWithSuggestions } from "components/ui";

const FillableAddressSection = (props) => {
  const { field, purchase, title, updatePurchase, requiredFieldText } = props;

  const blankAddress = {
    name: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    postal_code: "",
  }

  const [address, setAddress] = useState({...blankAddress, ...purchase[field]});

  useEffect(() => {
    setAddress({...blankAddress, ...purchase[field]});
  }, [purchase]);

  const handleSelectSuggestedAddress = (suggestedAddress) => {
    for (const addressField in suggestedAddress) {
      if (addressField in address) {
        address[addressField] = suggestedAddress[addressField];
      }
    }

    updatePurchase({ 
      ...purchase,  
      [field]: {...address}
    });
  }

  return (
    <>
      <h3 className='panel-title'>{ title }</h3>
      <div className='row mb-4'>
        <UIColLabelInput
          required
          error={ !address.name && requiredFieldText() }
          labelWidth={4}
          label="Name"
          value={address.name}
          onChange={(value) => {
            updatePurchase({ 
              ...purchase,  
              [field]: { ...address, name: value }
            });
          }}
        />
        
      </div>

      <div className='row mb-4'>
        <label className="col-sm-4 pe-0">
          <strong>Address Line 1</strong>
          <span className="panel-form-required">*</span>
        </label>

        <div className={`col-sm-8`}>
          <UIAddressInputWithSuggestions 
            value={address.address_line_1}
            onChange={(value) => {
              updatePurchase({ 
                ...purchase,  
                [field]: { ...address, address_line_1: value }
              });
            }}
            onSelectSuggestedAddress={handleSelectSuggestedAddress}
          />
          { !address.address_line_1 && requiredFieldText() }
        </div>
      </div>

      <div className='row mb-4'>
        <UIColLabelInput
          label="Address Line 2"
          labelWidth={4}
          value={address.address_line_2}
          onChange={(value) => {
            updatePurchase({ 
              ...purchase,  
              [field]: { ...address, address_line_2: value }
            });
          }}
        />
      </div> 

      <div className='row mb-4'>
        <UIColLabelInput
          required
          error={ !address.city && requiredFieldText() } 
          label="City"
          labelWidth={4}
          value={address.city}
          onChange={(value) => {
            updatePurchase({ 
              ...purchase,  
              [field]: { ...address, city: value }
            });
          }}
        />
      </div>

      <div className='row mb-4'>
        <UIColLabelInput
          required
          labelWidth={4}
          label="State"
          error={ !address.state && requiredFieldText() } 
          value={address.state}
          onChange={(value) => {
            updatePurchase({ 
              ...purchase,  
              [field]: { ...address, state: value }
            });
          }}
        />
        
      </div>

      <div className='row mb-4'>
        <UIColLabelInput
          required
          labelWidth={4}
          label="Postal Code"
          error={ !address.postal_code && requiredFieldText() }
          value={address.postal_code}
          onChange={(value) => {
            updatePurchase({ 
              ...purchase,  
              [field]: { ...address, postal_code: value }
            });
          }}
        />
      </div>

      <div className='row mb-4'>
        <UIColLabelInput
          required
          labelWidth={4}
          label="Country"
          error={ !address.country && requiredFieldText() }
          value={address.country}
          onChange={(value) => {
            updatePurchase({ 
              ...purchase,  
              [field]: { ...address, country: value }
            });
          }}
        />
      </div>
    </>
  )
}

export default FillableAddressSection;

