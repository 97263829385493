import React, { useContext } from "react";
import AsyncSelect from "react-select/async";
import { fetchAdminCompanies } from "modules/companies";
import { EditUserContext } from "./edit-user-context";
import { some } from "lodash";
import RoleSelector from "../../../common/role-selector";

const EditCompanies = ({ roles }) => {
  const {
    companies,
    onRemoveUserCompany,
    onAddUserCompany,
    updateUserCompanyRole,
    basicInfo,
  } = useContext(EditUserContext);

  const onOptionsLoad = (value, cb) => {
    fetchAdminCompanies({ query: value }).then((res) => {
      cb(
        res.companies.map((row) => ({
          value: row.id,
          label: row.name,
        }))
      );
    });
  };

  const filterOption = (option) =>
    !some(companies.value, (c) => c.company_id == option.value);

  const onCompanySelect = (option) => {
    onAddUserCompany(option.value);
  };

  return (
    <div>
      <h3>{`Update ${basicInfo.first_name}'s Assigned Companies`}</h3>
      <hr />

      <div className="row mb-4">
        <div className="col-md-8">
          <AsyncSelect
            value={null}
            loadOptions={onOptionsLoad}
            filterOption={filterOption}
            placeholder="Search Companies..."
            onChange={onCompanySelect}
          />
        </div>

        <div className="col-md-4">
          <button className="btn btn-attach ms-auto">
            Assign to Companies
          </button>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Company Name</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {companies &&
            companies.value &&
            companies.value.map((company) => (
              <tr key={company.id}>
                <td>{company.id}</td>
                <td>{company.company_name}</td>
                <td>
                  <RoleSelector
                    update={(role) => {
                      updateUserCompanyRole(company, role);
                    }}
                    selected={{
                      id: company.role_id,
                      name: company.role,
                    }}
                    roles={roles}
                  />
                </td>
                <td>
                  <button
                    className="btn btn-danger remove-user-btn btn-sm"
                    id={`remove_company-${company.id}`}
                    onClick={() => onRemoveUserCompany(company)}
                  >
                    <i className="px-1 bi bi-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default EditCompanies;
