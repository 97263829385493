import React from 'react';
import FilePreview from 'components/file-preview';
import { Modal } from "react-bootstrap";

const FilePreviewModal = (props) => {
  const { resource, selectNextFile, selectPrevFile } = props;

  if(!props.show) {
    return null; 
  }

  function handleClose(e) {
    e.preventDefault();
    props.close()
  }

  return (
    <>
      <Modal 
        show={props.show} 
        onHide={props.close}
        aria-labelledby="contained-modal-title-vcenter"
        size='xl'
        centered
      >
        <Modal.Header>
          <Modal.Title>{resource.name}</Modal.Title>

          <button className='btn btn-sm btn-muted float-end' onClick={handleClose}>
            <i className="bi bi-x-lg"></i>
          </button>
        </Modal.Header>

        <div className='file-preview-wrapper'>
          <div className='row align-items-center'>
            <div className='col-md-1'>
              <button className='btn btn-muted navigate-files-btn' onClick={selectPrevFile}>
                <i className="bi bi-chevron-left"></i>
              </button>
            </div>
            <div className='col-md-10'>
              <FilePreview
                fileType={resource.type}
                filePath={resource.url}
              />
            </div>
            <div className='col-md-1'>
              <button className='btn btn-muted navigate-files-btn float-end' onClick={selectNextFile}>
                <i className="bi bi-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default FilePreviewModal;