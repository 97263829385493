import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css"; // Tagify CSS

// Tagify settings object
const baseTagifySettings = {
  maxTags: 20,
  placeholder: "Add tags",
  dropdown: {
    enabled: 0, // a;ways show suggestions dropdown
  },
};

const UITags = (props) => {
  const tagifyRef = useRef();

  const handleChange = (e) => {
    const values = e.detail.tagify.getCleanValue();
    const tagArray = values.map((v) => v.value);

    props.setTags(tagArray);
  };

  return (
    <div className="ui-tags">
      <Tags
        defaultValue={props.tags}
        tagifyRef={tagifyRef}
        settings={baseTagifySettings}
        onChange={handleChange}
      />
    </div>
  );
};

export default UITags;
