import React, { useContext, useState } from "react";

import UserAutocomplete from "../common/user-autocomplete";
import { EditCompanyContext } from "components/common/companies/contexts/admin-edit-company-context";
import RoleSelector from "../../../common/role-selector";
import moment from "moment";

const Users = ({ roles }) => {
  const {
    users,
    onAddUser,
    updateUserCompanyRole,
    updateUserCompanyJobTitle,
    onRemoveUser,
    toggleUserAdmin,
    basicInfo,
  } = useContext(EditCompanyContext);

  const [editUserId, setEditUserId] = useState(null);
  const [newJobTitle, setNewJobTitle] = useState("");

  const handleEditClick = (user) => {
    setEditUserId(user.user_id);
    setNewJobTitle(user.job_title);
  };

  const handleSubmitClick = (user) => {
    const onSuccess = (updatedUserCompany) => {
      users.modifyById(user.user_id, { ...user, job_title: newJobTitle });
      setEditUserId(null);
    };

    updateUserCompanyJobTitle(user, newJobTitle, onSuccess);
  };

  return (
    <div className="row">
      <div className="row">
        <div className="col-md-8">
          <UserAutocomplete onAddUser={onAddUser} currentUsers={users.value} />
        </div>
        <div className="col-md-4">
          <button className="btn-attach">Add User</button>
        </div>
      </div>
      <div className="row mt-3">
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>Name</th>
              <th>Title</th>
              <th>Email</th>
              <th>Account Created</th>
              <th>Last Login</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {users.value.map((user) => (
              <tr key={user.user_id}>
                <td>
                  <button
                    className="btn btn-danger remove-user-btn btn-sm"
                    id={`remove_user-${user.user_id}`}
                    onClick={() => onRemoveUser(user)}
                  >
                    <i className="px-1 bi bi-trash"></i>
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => handleEditClick(user)}
                  >
                    <i className="bi bi-pencil"></i>
                  </button>
                  {editUserId === user.user_id ? (
                    <>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleSubmitClick(user)}
                      >
                        <i className="bi bi-check"></i>
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={() => setEditUserId(null)}
                      >
                        <i className="bi bi-x"></i>
                      </button>
                    </>
                  ) : null}
                </td>
                <td>{user.full_name}</td>
                <td>
                  {editUserId === user.user_id ? (
                    <input
                      type="text"
                      value={newJobTitle}
                      onChange={(e) => setNewJobTitle(e.target.value)}
                      className="form-control inline-edit"
                    />
                  ) : (
                    <>{user.job_title}</>
                  )}
                </td>
                <td className="admin-metadata">{user.email}</td>
                <td className="admin-metadata">
                  {" "}
                  {user.user_created_at
                    ? moment(user.user_created_at).format("MM/DD/YYYY")
                    : ""}
                </td>
                <td className="admin-metadata">
                  {user.current_sign_in_at
                    ? moment(user.current_sign_in_at).format("MM/DD/YYYY")
                    : ""}
                </td>
                <td>
                  <RoleSelector
                    update={(role) => {
                      updateUserCompanyRole(user, role);
                    }}
                    selected={{
                      id: user.role_id,
                      name: user.role,
                    }}
                    roles={roles}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
