import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';

import { flashError, flashSuccess, flashSuccessLater } from "lib/flash";

import { PlaidLinkButton } from "components/integrations";
import { UIHidableText, UILabelInput, UIMoneyLabelInput } from "components/ui";
import { prettyDisplayPrice } from 'lib/string';
import { deleteCompanyFinancialAccount, updateCompanyFinancialAccount } from "modules/company_financial_accounts";
import { disconnectPlaidLink } from "modules/integrations";


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    className='btn btn-sm btn-muted float-end'
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

export const BankAccountTile = ({account, editableAccountId, setEditableAccountId}) => {
  const [ accountName, setAccountName] = useState(account.name || "");
  const [ accountNumber, setAccountNumber ] = useState(account.details.account_number || "");
  const [ routingNumber, setRoutingNumber ] = useState(account.details.routing_number || "");
  const [ availableBalance, setAvailableBalance ] = useState(account.balances.available|| "");
  const [ currentBalance, setCurrentBalance ] = useState(account.balances.current || "");
  const [ currentLimit, setCurrentLimit ] = useState(account.balances.limit || "");

  function updateAccountName(value) {
    setAccountName(value);
  };

  function updateAccountNumber(value) {
    setAccountNumber(value);
  };

  function updateRoutingNumber(value) {
    setRoutingNumber(value);
  };

  function updateAvailableBalance(value) {
    setAvailableBalance(value);
  };

  function updateCurrentBalance(value) {
    setCurrentBalance(value);
  };

  function updateCurrentLimit(value) {
    setCurrentLimit(value);
  };

  function getAccountIcon(type) {
    if (type === "depository") {
      return "cash";
    } else if (type === "credit") {
      return "credit-card";
    } else if (type === "loan") {
      return "coin";
    } else if (type == "investment") {
      return "graph-up";
    }
  };

  function getAccountTileName() {
    if(account.id == editableAccountId) {
      return (
        <input
          value={accountName || ''}
          type="text"
          className="form-control"
          style={{fontWeight: "bold"}}
          onChange={(e) => {
            updateAccountName(e.target.value);
          }}
        />
      );
    } else {
      return (
        <>
          <i className={`bi bi-${getAccountIcon(account.account_type)} me-2`}></i>
          { accountName }
        </>
      );
    }
  }

  function getAccountTileBodyInputs() {
    if(account.account_type === "credit") {
      return (
        <>
          <div className="row" key="account-details-numbers">
            <div className="col mb-4">
              <UILabelInput
                label="Account Number"
                value={accountNumber || ''}
                onChange={updateAccountNumber}
              />
            </div>
            <div className="col mb-4">
            </div>
          </div>
          <div className="row" key="account-details-balances">
            <div className="col mb-4">
              <UIMoneyLabelInput
                label="Available Credit"
                defaultValue={availableBalance || ''}
                onValueChange={updateAvailableBalance}
              />
            </div>
            <div className="col mb-4">
              <UIMoneyLabelInput
                label="Current Balance"
                defaultValue={currentBalance || ''}
                onValueChange={updateCurrentBalance}
              />
            </div>
            <div className="col mb-4">
              <UIMoneyLabelInput
                label="Credit Limit"
                defaultValue={currentLimit || ''}
                onValueChange={updateCurrentLimit}
              />
            </div>
          </div>
        </>
      );
    } else if(account.account_type === "loan") {
      return (
        <>
          <div className="row" key="account-details-numbers">
            <div className="col mb-4">
              <UILabelInput
                label="Account Number"
                value={accountNumber || ''}
                onChange={updateAccountNumber}
              />
            </div>
            <div className="col mb-4">
            </div>
          </div>
          <div className="row" key="account-details-balances">
            <div className="col mb-4">
              <UIMoneyLabelInput
                label="Remaining Principle"
                defaultValue={currentBalance || ''}
                onValueChange={updateCurrentBalance}
              />
            </div>
            <div className="col mb-4">
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="row" key="account-details-numbers">
            <div className="col mb-4">
              <UILabelInput
                label="Account Number"
                value={accountNumber || ''}
                onChange={updateAccountNumber}
              />
            </div>
            <div className="col mb-4">
              <UILabelInput
                label="Routing Number"
                value={routingNumber || ''}
                onChange={updateRoutingNumber}
              />
            </div>
          </div>
          <div className="row" key="account-details-balances">
            <div className="col mb-4">
              <UIMoneyLabelInput
                label="Available Balance"
                defaultValue={availableBalance || ''}
                onValueChange={updateAvailableBalance}
              />
            </div>
            <div className="col mb-4">
              <UIMoneyLabelInput
                label="Current Balance"
                defaultValue={currentBalance || ''}
                onValueChange={updateCurrentBalance}
              />
            </div>
          </div>
        </>
      );
    }
  }

  function getAccountTileBody() {
    if(account.id == editableAccountId) {
      return (
        <>
          { getAccountTileBodyInputs() }
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="btn-group float-end">
                <button
                  className="btn btn-secondary"
                  style={{width: "100px"}}
                  onClick={
                    (e) => {
                      e.preventDefault();

                      setEditableAccountId(null);
                      setAccountName(account.name);
                      setAccountNumber(account.details.account_number);
                      setRoutingNumber(account.details.routing_number);
                      setAvailableBalance(account.balances.available);
                      setCurrentBalance(account.balances.current);
                      setCurrentLimit(account.balances.limit);
                    }
                  }
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  style={{width: "100px"}}
                  onClick={
                    (e) => {
                      e.preventDefault();

                      handleUpdateAccountClicked();
                    }
                  }
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="row" key="account-details-numbers">
            {!!accountNumber ? (
              <div className="col mb-4">
                <UIHidableText
                  label="Account Number"
                  text={accountNumber}
                  hiddenText={`\u25CF\u25CF\u25CF\u25CF${!!account.details.mask ? account.details.mask : ""}`}
                />
              </div> 
            ) : (
              <div className="col mb-4">
                <label className="form-label mb-1"><strong>Account Number</strong></label>
                <label className="form-label mb-1" style={{ width: "100%" }}>-</label>
              </div>
            )}
            {!!routingNumber &&
              <div className="col mb-4">
                <label className="form-label mb-1"><strong>Routing Number</strong></label>
                <label className="form-label mb-1" style={{ width: "100%" }}>{routingNumber}</label>
              </div>
            }
          </div>
          <div className="row" key="account-details-balances">
            {account.account_type === "depository" &&
              <div className="col mb-4">
                <label className="form-label mb-1"><strong>Available Balance</strong></label>
                <label className="form-label mb-1" style={{ width: "100%" }}>{!!availableBalance ? prettyDisplayPrice(availableBalance) : "-"}</label>
              </div>
            }
            {account.account_type === "credit" &&
              <div className="col mb-4">
                <label className="form-label mb-1"><strong>Available Credit</strong></label>
                <label className="form-label mb-1" style={{ width: "100%" }}>{!!availableBalance ? prettyDisplayPrice(availableBalance) : "-"}</label>
              </div>
            }
            {["depository", "credit"].includes(account.account_type) &&
              <div className="col mb-4">
                <label className="form-label mb-1"><strong>Current Balance</strong></label>
                <label className="form-label mb-1" style={{ width: "100%" }}>{!!currentBalance ? prettyDisplayPrice(currentBalance) : "-"}</label>
              </div>
            }
            {account.account_type == "loan" &&
              <div className="col mb-4">
                <label className="form-label mb-1"><strong>Remaining Principal</strong></label>
                <label className="form-label mb-1" style={{ width: "100%" }}>{!!currentBalance ? prettyDisplayPrice(currentBalance) : "-"}</label>
              </div>
            }
            {account.account_type == "investment" &&
              <div className="col mb-4">
                <label className="form-label mb-1"><strong>Current Value</strong></label>
                <label className="form-label mb-1" style={{ width: "100%" }}>{!!currentBalance ? prettyDisplayPrice(currentBalance) : "-"}</label>
              </div>
            }
            {account.account_type === "credit" &&
              <div className="col mb-4">
                <label className="form-label mb-1"><strong>Credit Limit</strong></label>
                <label className="form-label mb-1" style={{ width: "100%" }}>{!!currentLimit ? prettyDisplayPrice(currentLimit) : "-"}</label>
              </div>
            }
          </div>
        </>
      );
    }
  };

  function handleUpdateAccountClicked() {
    let params = {
      "account_details": {},
      "balances": {}
    };

    if (!!accountName) {
      params.account_details.display_name = accountName;
    }
    if (!!accountNumber) {
      params.account_details.account_number = accountNumber;
    }
    if (!!routingNumber) {
      params.account_details.routing_number = routingNumber;
    }
    if (!!availableBalance) {
      params.balances.available = availableBalance;
    }
    if (!!currentBalance) {
      params.balances.current = currentBalance;
    }
    if (!!currentLimit) {
      params.balances.limit = currentLimit;
    }
 
    updateCompanyFinancialAccount(account.id, params)
      .then(() => {
        flashSuccess("Successfully updated bank account!");
        setEditableAccountId(null);
      }).catch((error) => {
        flashError("Failed to updated bank account!");
      });
  }

  function handleDeleteClicked() {
    deleteCompanyFinancialAccount(account.id)
      .then(() => { 
        location.reload(true);
        flashSuccessLater("Successfully deleted account.");
      })
      .catch((error) => {
        flashError("Failed to delete account.");
      });
  };

  return (
    <div className="row  p-3 mt-1 z-depth-2" key={`account-details-${account.external_id}`}>
      <div className ="col lg">
        <h3 className="panel-subtitle">
          { getAccountTileName() }
        </h3>
      </div>
      <div className="col sm">
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-toggle">
            <i className="bi bi-three-dots"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#" onClick={() => { setEditableAccountId(account.id); } }>
              Edit
            </Dropdown.Item>
            <Dropdown.Item href="#" onClick={() => { handleDeleteClicked(); } }>
              Remove
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      { getAccountTileBody() }
    </div>
  );
};

export const BankTile = ({institution, editableAccountId, setEditableAccountId}) => {
  function handleDisconnectClicked(id) {
    disconnectPlaidLink({ "id" : id })
      .then(() => { 
        location.reload(true);
        flashSuccessLater("Successfully disconnected.");
      })
      .catch((error) => {
        flashError("An error occurred. Could not disconnect.");
      });
  };

  return (
    <div className="card p-3 mt-1 mb-3 z-depth-2">
      <h3 className="panel-title">{`${institution.name || 'Other'} Account` + (institution.accounts.length > 1 ? "s" : "")}</h3>
      {
        institution.accounts.map((account, accountIndex) => {
          return (
            <BankAccountTile
              account={account}
              editableAccountId={editableAccountId}
              setEditableAccountId={setEditableAccountId}
            />
          );
        })
      }
    </div>
  );
};
