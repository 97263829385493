import axios from "lib/axios";

export const storePassword = (params = {}) => {
  const url = `/onboarding/store_password`

  return axios.post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const createCompany = (params = {}) => {
  const url = `/onboarding/create_company`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const createAddress = (params = {}) => {
  const url = `/onboarding/create_address`
  console.log("Creating address with params:", params);
  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}