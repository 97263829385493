import React, { useState } from "react";
import { useArray } from "react-hanger";
import {
  createAdminIncentiveQualification,
  removeAdminIncentiveQualification,
  setAdminIncentiveQualificationPosition,
  updateAdminIncentiveQualification,
} from "modules/incentive-qualifications";

import { isEmpty } from "lodash";
import { flashError, flashSuccess, flashWarning } from "lib/flash";
import QualificationCard from "./qualification-builder/qualification-card";
import { UIListSortable } from "../../../ui";
import NewQualificationCard from "./qualification-builder/new-qualification-card";

const Qualifications = ({ incentive }) => {
  const [newQualification, setNewQualification] = useState(false);
  const qualifications = useArray(incentive.incentive_qualifications);

  const addNewQualification = () => {
    setNewQualification(true);
  };

  const addQualification = (option) => {
    createAdminIncentiveQualification({
      incentive_qualification: {
        qualification_id: option.value,
        incentive_id: incentive.id,
      },
    }).then((res) => {
      if (res.errors) res.errors.map(flashError);
      else {
        qualifications.push(res);
        flashSuccess("Qualification added to incentive.");
      }
    });
    setNewQualification(false);
  };

  const updateIncentiveQualification = (incentiveQualification, params) => {
    updateAdminIncentiveQualification({
      id: incentiveQualification.id,
      incentive_qualification: {
        ...params,
      },
    }).then((res) => {
      if (res.errors) res.errors.map(flashError);
      else {
        qualifications.modifyById(incentiveQualification.id, {
          ...params,
        });

        flashSuccess("Qualification updated");
      }
    });
  };

  const removeQualification = (incentiveQualificationId) => {
    removeAdminIncentiveQualification({
      id: incentiveQualificationId,
    }).then((res) => {
      if (res.errors) res.errors.map(flashError);
      else {
        qualifications.removeById(incentiveQualificationId);
        flashWarning("Qualification removed from incentive");
      }
    });
  };

  const setQualificationOrder = (e) => {
    const qualificationId = qualifications.value[e.oldIndex].id;

    setAdminIncentiveQualificationPosition({
      incentive_qualification: {
        position: e.newIndex,
      },
      id: qualificationId,
    }).then((res) => {
      if (res.errors) res.errors.map(flashError);
      else {
        qualifications.move(e.oldIndex, e.newIndex);
        flashSuccess("Qualification position updated");
      }
    });
  };

  const updateAnswerOptions = (incentiveQualificationId, newOptions) => {
    updateAdminIncentiveQualification({
      incentive_qualification: {
        acceptable_answer_option_ids: newOptions,
      },
      id: incentiveQualificationId,
    }).then((res) => {
      if (res.errors) res.errors.map(flashError);
      else {
        qualifications.modifyById(incentiveQualificationId, {
          acceptable_answer_option_ids: newOptions,
        });
        flashSuccess("Qualification answer option updated");
      }
    });
  };

  return (
    <div>
      <h3>Qualifications Builder</h3>
      <div className="">
        <div className="row">
          <div className="col-12">
            {!isEmpty(qualifications.value) && (
              <UIListSortable
                list={qualifications.value}
                handle=".qualification-handle"
                onEnd={setQualificationOrder}
              >
                {qualifications.value.map((incentive_qualification) => (
                  <QualificationCard
                    key={incentive_qualification.id}
                    incentive_qualification={incentive_qualification}
                    removeQualification={removeQualification}
                    updateAnswerOptions={updateAnswerOptions}
                    updateIncentiveQualification={updateIncentiveQualification}
                  />
                ))}
              </UIListSortable>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {newQualification ? (
              <NewQualificationCard
                addQualification={addQualification}
                setNewQualification={setNewQualification}
              />
            ) : (
              <>
                {isEmpty(qualifications.value) && (
                  <div className="card p-3 mb-3">
                    <h5 className="m-0 text-black-50">No Qualifications</h5>
                  </div>
                )}
                <button
                  className="btn btn-light mt-3 mb-3 me-2"
                  onClick={addNewQualification}
                >
                  Add Qualification
                </button>
                <button
                  className="btn-attach mt-3 mb-3"
                  onClick={() =>
                    window.open("/admin/qualifications/new", "_blank")
                  }
                >
                  Add New Question
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Qualifications;
