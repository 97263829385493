import React, { useState, useEffect } from "react";

import { displayPrice, displayPercent } from "lib/string";
import { NumericFormat } from "react-number-format";
import { DropdownButton, Dropdown, ButtonGroup, Button } from "react-bootstrap";

import TransactionTypeSelect from "./transaction-type-select";

const AllocatedProjectRow = (props) => {
  const {
    project,
    lineItem,
    totalDollarAllocated,
    totalFractionAllocated,
    setTotalDollarAllocated,
    setTotalFractionAllocated,
  } = props;

  const [fraction, setFraction] = useState(props.fraction);
  const [total, setTotal] = useState(lineItem.total_amount);
  const [transactionType, setTransactionType] = useState(
    lineItem.transaction_type
  );

  function updateAllocation() {
    props.updateAllocation(lineItem, fraction, transactionType);
  }

  function unAllocateProject() {
    props.unallocateProject(lineItem.id);
  }

  // useEffect(() => {
  //   if(!fraction && fraction !== 0) return;

  //   if(props.fraction !== fraction) {
  //     updateTotals(total * fraction, fraction, project.id)
  //   }
  // }, [fraction])

  return (
    <tr className="table-warning">
      <td>{project.external_project_id}</td>
      <td>{project.name}</td>
      <td>{project.status}</td>
      <td>
        <TransactionTypeSelect
          transactionType={transactionType}
          setTransactionType={setTransactionType}
        />
      </td>
      <td>
        <NumericFormat
          allowNegative={false}
          suffix="%"
          value={fraction * 100}
          className="form-control"
          onValueChange={(values, sourceInfo) => {
            const newFraction = values.floatValue / 100;
            setTotal((lineItem.total_amount / lineItem.fraction) * newFraction);
            setFraction(newFraction);
          }}
          isAllowed={(values) => {
            const { floatValue } = values;
            return (
              floatValue === undefined || (floatValue <= 100 && floatValue >= 0)
            );
          }}
        />
      </td>
      <td>{displayPrice(total)}</td>
      <td>
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle variant="muted" size="sm" id="dropdown-select">
            <i className="bi bi-three-dots"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={updateAllocation}>Update</Dropdown.Item>
            <Dropdown.Item onClick={unAllocateProject}>
              Unallocate
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

export default AllocatedProjectRow;
