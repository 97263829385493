import React, { useState, useEffect, useContext } from 'react';

import { EditCompanyContext } from "components/common/companies/contexts/edit-company-context";
import { fetchDemographics, toggleCompanyDemographic } from 'modules/demographics'
import { flashSuccess, flashError, flashWarning } from "lib/flash";

const Demographics = () => {
  const { onUpdateCompany, basicInfo, setBasicInfo } =
    useContext(EditCompanyContext);

  const [demographics, setDemographics] = useState([]);

  const updateCompany = () => {
    onUpdateCompany(basicInfo);
  };

  const toggleDemographic = (demographic, companyId, index) => {
    toggleCompanyDemographic(demographic, basicInfo.id)
      .then((is_checked) => {
        let new_demographics = [ ...demographics ]
        new_demographics[index] = { ...demographic, is_checked }
        setDemographics(new_demographics)

        if(is_checked) {
          flashSuccess(`${demographic.name} is toggled ${is_checked ? 'on' : 'off'}.`);
        } else {
          flashWarning(`${demographic.name} is toggled ${is_checked ? 'on' : 'off'}.`);
        }
      })
      .catch((res) => {
        res.errors.map((error) => {
          flashError(error)
        })
      })
  }

  useEffect(() => {
    fetchDemographics()
      .then((data) => {
        setDemographics(data)
      })
  }, [])

  return (
    <div>
      {
        demographics.map((demographic, index) => {
          return (
            <div className='mb-4' key={`demographics-${index}`}>
              <div className='row ms-1'>
                <div className='col-md-6 card p-0'>
                  <label className={`card-body demographics-card ${demographic.is_checked ? 'lightgray-card' : ''}`}>
                    <input
                      type='checkbox'
                      className='demographics'
                      checked={demographic.is_checked || false}
                      onChange={(value) => {
                        toggleDemographic(demographic, basicInfo.id, index) 
                      }}
                    />
                    <span className='ms-2'>{ demographic.name }</span>
                  </label>
                </div>
              </div>
            </div>
          )
        }) 
      }
    </div>

  );
};

export default Demographics;
