import React from "react";
import UIFormLabel from './ui-form-label';
import UIValidationError from './ui-validation-error';

import { useField } from 'formik';

const UIYesNoInput= (props) => {
  const [_field, meta, helpers] = useField({name: props.name});

  return (
    <div className="row">
      <div id={props.name} className="col">
        <UIFormLabel label={props.label} required={props.required}/>
        {meta.error && meta.touched && 
          <UIValidationError message={meta.error.replace(props.name, "This")} />
        }
      </div>
      <div className="col">
        <div role="group" aria-labelledby={props.name}>
          <label className="me-4">
            Yes
            <input type="radio"
                   className="ms-2"
                   checked={meta.value === true} 
                   onChange={(e) => {
                     if (e.target.checked) {
                      helpers.setValue(true);
                     }
                   } }/>
          </label>
          <label>
            No
            <input type="radio" 
                   className="ms-2"
                   checked={meta.value === false} 
                   onChange={(e) => {
                     if (e.target.checked) {
                      helpers.setValue(false);
                     }
                   } }/>
          </label>
        </div>
      </div>
    </div>
  )
};

export default UIYesNoInput
