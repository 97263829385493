class CalculatorTree {
  constructor(tree, calculateFn) {
    this.tree = tree;
    this.defaultState = {}
    this.buildDefaultState(this.tree);

    this.calculate = calculateFn;
  }

  buildDefaultState(tree) {
    for(var i = 0; i < tree.length; i++) {
      const leaf = tree[i];
      const value = (leaf.component === 'Question') ?
        leaf.defaultAnswer : leaf.defaultValue;

      this.defaultState[leaf.key] = {
        value: value,
        validate: leaf.validate || false,
        errorMessage: leaf.errorMessage || "",
        acceptedValues: leaf.acceptedValues,
      }

      if(leaf.children && leaf.children.length > 0) {
        this.buildDefaultState(leaf.children)
      }
    }
  }
}

export default CalculatorTree; 
