import React from "react";

const SectionItem = ({ section, isComplete, isActive }) => {
  const iconClass = isComplete ? "bi-check-lg" : section.icon;
  const itemClass = isActive ? "active" : isComplete ? "completed" : "";

  return (
    <li className={`section-item ${itemClass}`}>
      <div className="row">
        <div className="col-md-10">
          <div className="mt-2">
            <h5 className="mb-0">{section.name}</h5>
            <p className="mt-0 description">{section.description}</p>
          </div>
        </div>
        <div className="col-md-2">
          <div className={`section-icon ${itemClass}`}>
            <i
              className={`bi ${iconClass} position-absolute top-50 start-50 translate-middle`}
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default SectionItem;
