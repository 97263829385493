import React from "react";
import { isEmpty } from "lodash-es";
import { useSetState } from "react-hanger";
import { flashErrorLater, flashSuccessLater } from "lib/flash";
import { settingsUser } from "modules/users";

const EditDetails = ({ user }) => {
  const { state: basicInfo, setState: setBasicInfo } = useSetState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone_number: user.phone_number,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    settingsUser({ user: basicInfo }).then((res) => {
      if (isEmpty(res.errors)) {
        flashSuccessLater("User Settings Updated");
        location.reload(true);
      } else res.errors.map((error) => flashErrorLater(error));
    });
  };

  return (
    <div className="card p-3 mt-1 mb-3 z-depth-2">
      <div className="col-md-6">
        <h3 class="panel-title">Account Settings</h3>
      </div>
      <div className="row">
        <form onSubmit={handleSubmit}>
          <div className="col-md-6">
            <div className="mb-3">
              <label>
                <strong>First Name</strong>
              </label>
              <input
                type="text"
                className="form-control"
                value={basicInfo.first_name}
                onChange={(e) => {
                  e.preventDefault();
                  setBasicInfo({
                    first_name: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <label>
                <strong>Last Name</strong>
              </label>
              <input
                type="text"
                className="form-control"
                value={basicInfo.last_name}
                onChange={(e) => {
                  e.preventDefault();
                  setBasicInfo({
                    last_name: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <label>
                <strong>Email</strong>
              </label>
              <input
                type="text"
                className="form-control"
                value={basicInfo.email}
                onChange={(e) => {
                  e.preventDefault();
                  setBasicInfo({
                    email: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <label>
                <strong>Phone Number</strong>
              </label>
              <input
                type="tel"
                className="form-control"
                value={basicInfo.phone_number}
                onChange={(e) => {
                  e.preventDefault();
                  setBasicInfo({
                    phone_number: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-5 btn-group float-end">
              <button className="btn btn-primary" type="submit">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDetails;
