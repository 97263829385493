import React from "react";
import { prettyDisplayPrice } from "lib/string";

const AdminStatsIncentiveTable = ({ metricsByQualifiedIncentive }) => {
  return (
    <div className="mt-0">
      <table className="table admin-table mt-4">
        <thead>
          <tr>
            <th>Incentive Name</th>
            <th>Company Matches</th>
            <th>Applications</th>
            <th>Application Dollars</th>
          </tr>
        </thead>
        <tbody>
          {metricsByQualifiedIncentive.length === 0 ? (
            <tr>
              <td colSpan="6">Loading...</td>
            </tr>
          ) : (
            metricsByQualifiedIncentive.map((item, index) => (
              <tr key={index}>
                <td>
                  <a
                    href={`/admin/incentives/${item.incentive_id}/edit#/qualified-companies`}
                  >
                    {item.name}
                  </a>
                </td>
                <td>{item.matches}</td>
                <td>{item.companies_applied}</td>
                <td>
                  {prettyDisplayPrice(parseFloat(item.application_dollars))}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminStatsIncentiveTable;
