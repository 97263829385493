import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import BasicInfo from "./basic-info";
import Address from "./address";
import UserSection from "./users-section";

import { createCompany } from "modules/user/companies";
import { flashError, flashSuccessLater } from "../../../lib/flash";

const NewCompany = ({ businessEntities }) => {
  const blankAddress = () => ({
    id: uuidv4(),
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "California",
    postal_code: "",
    is_primary: false,
  });

  const [variables, setVariables] = useState({
    name: "",
    date_established: "",
    naics_code_id: "",
    description: "",
    ein: "",
    duns_number: "",
    is_active: true,
    company_category: "",
    users: [],
    addresses: [],
    business_entity_id: ""
  });

  const [editAddress, setEditAddress] = useState(blankAddress());

  const onCreateCompany = () =>
    createCompany(variables)
      .then((res) => {
        flashSuccessLater("Company added")
        goToCompaniesPage();
      })
      .catch((res) => {
        res.errors.map((error) => {
          flashError(error)
        })
      });

  const onFieldChange = (value, type) => {
    setVariables({
      ...variables,
      [type]: value,
    });
  };

  const onNaicsCodeSelect = (value, type) => {
    if (value) {
      let naicsCodeIds = value.map(function (naics) { return naics.value; });
      setVariables({
        ...variables,
        [type]: naicsCodeIds,
      });
    }
  };


  const goToCompaniesPage = () => (window.location = "/");

  return (
    <div className="panel-body admin-company-form">
      <div className='row'>
        <div className="col-lg-8">
          <h1 className='mb-4 mt-4'>Create New Company</h1>
          <BasicInfo
            variables={variables}
            onFieldChange={onFieldChange}
            businessEntities={businessEntities}
            onNaicsCodeSelect={onNaicsCodeSelect}
          />
          <Address
            variables={variables}
            onFieldChange={onFieldChange}
            editAddress={editAddress}
            setEditAddress={setEditAddress}
            blankAddress={blankAddress}
          />
          <div className="d-flex justify-content-end pt-5">
            <div className='btn-group'>
              <button className="btn btn-light" onClick={goToCompaniesPage}>
                Cancel
              </button>
              <button className="btn btn-dark mx-1" onClick={onCreateCompany}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCompany;
