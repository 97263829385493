import React, { useState } from "react";
import MeetingNotes from "../../../common/meeting-notes";

const Meetings = ({ company, meetings, partnerType }) => {
  const [showNewMeeting, setShowNewMeeting] = useState(false);
  const emptyMeeting = {
    id: null,
    partner_id: company.id,
    partner_type: partnerType,
    name: "",
    attendees: [],
    company_action_items: [],
    meeting_date_time: Date(),
    meeting_notes: "",
    meeting_type: "Discovery",
    subcity_action_items: [],
  };

  return (
    <div>
      <div className="row g-0">
        <div className="col">
          {showNewMeeting ? null : (
            <button
              className="btn-attach float-end"
              onClick={() => {
                setShowNewMeeting(true);
              }}
            >
              New Note
            </button>
          )}
          {showNewMeeting ? (
            <MeetingNotes
              key={`meeting-new`}
              meeting={emptyMeeting}
              isNewNote={true}
              setShowNewMeeting={setShowNewMeeting}
            />
          ) : null}
        </div>
      </div>

      <div className="row mt-3">
        {meetings.map((meeting) => (
          <div key={`meeting-${meeting.id}`} className="col-md-12">
            <MeetingNotes meeting={meeting} isNewNote={false} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Meetings;
