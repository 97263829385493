import React, { useState, useEffect } from "react";
import { useArray } from "react-hanger";
import { fetchAdminFileCategories } from "modules/files";
import { filter } from "lodash";

import IncentiveFileCategoryRow from "./file-categories/incentive-file-category-row";
import NewIncentiveFileCategory from "./file-categories/new-incentive-file-category";

const ApplicationFiles = ({ incentive }) => {
  const [fileCategories, setFileCategories] = useState([]);
  const [filteredFileCategories, setFilteredFileCategories] = useState([]);

  function filterCategories(rawCategories, incentiveFileCategories) {
    let categories = rawCategories;
    for (var i = 0; i < incentiveFileCategories.length; i++) {
      const incentiveFileCategory = incentiveFileCategories[i];
      categories = filter(
        categories,
        (o) => o.id !== incentiveFileCategory.file_category_id
      );
    }

    setFileCategories(rawCategories);
    setFilteredFileCategories(categories);
  }

  useEffect(() => {
    fetchAdminFileCategories({ flat: true }).then((response) => {
      filterCategories(response, incentive.incentive_file_categories);
    });
  }, []);

  return (
    <div>
      <NewIncentiveFileCategory
        incentive={incentive}
        fileCategories={filteredFileCategories}
      />
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>File Type</th>
              <th>Application Context</th>
              <th>Limit</th>
              <th>Required</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {incentive.incentive_file_categories.map((ifcrow, index) => {
              return (
                <IncentiveFileCategoryRow
                  {...ifcrow}
                  key={`incentive-file-category-row-${index}`}
                  fileCategories={filteredFileCategories}
                  incentive={incentive}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ApplicationFiles;
