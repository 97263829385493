import React, { Component } from "react";
import TextareaAutosize from "react-textarea-autosize";

import { useField } from "formik";

import UIFormLabel from "./ui-form-label";
import UIValidationError from "./ui-validation-error";

const UITextareaLabelInput = (props) => {
  return (
    <div className="ui-label-input">
      <UIFormLabel label={props.label} required={props.required} />
      <TextareaAutosize
        label={props.label}
        name={props.name}
        type="text"
        className="form-control"
        value={props.value}
        minRows={3}
        onChange={(e) => {
          if (props.passEvent) {
            props.handleChange(e);
          } else {
            props.handleChange(e.target.value);
          }
        }}
      />
      {!!props.description && (
        <p style={{ fontSize: "0.8em", color: "#888", margin: "0.2em 0" }}>
          {props.description}
        </p>
      )}
    </div>
  );
};

export default UITextareaLabelInput;
