import React, { useState, useEffect } from 'react';
import Loading from '../loading'
import CsvTable from './csv-table'

const CsvPreview = (props) => {
  const [ isLoading, setIsLoading ] = useState(true)
  const [ state, setState ] = useState({})

  useEffect(() => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', props.url, true);
    xhr.onload = function(e) {
      setState({ csv: e.target.response })
      setIsLoading(false)
    }
    xhr.send()
  }, [])

  function renderData() {
    return (
      <div className='csv-wrapper'>
        <CsvTable data={state.csv} />
      </div>
    ) 
  }

  return (
    <div>
      { isLoading ? <Loading /> : renderData() } 
    </div>
  )
}

export default CsvPreview;
