import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";

import { displayPrice } from "lib/string";
import LineItemRow from "./line-item-row";

const LineItemsTable = (props) => {
  const { company, endpoint, invoice, setInvoice, amountState, items, isNew } =
    props;

  function addItem(e) {
    e.preventDefault();
    props.addItem();
  }

  function setFieldValue(value, field) {
    const numeric = value ? parseFloat(value) : 0;
    if (numeric < 0) return;

    setInvoice({
      ...invoice,
      [field]: numeric,
    });
  }

  useEffect(() => {
    let subtotal_amount = 0;
    for (var i = 0; i < items.length; i++) {
      const item = items[i];
      if (item._destroy) continue;

      if (item.quantity === null || item.quantity === "") {
        continue;
      }

      subtotal_amount += parseFloat(item.quantity) * parseFloat(item.unit_cost);
    }

    setInvoice({
      ...invoice,
      subtotal_amount,
    });
  }, [items]);

  useEffect(() => {
    let total_amount = invoice.subtotal_amount || 0;

    if (amountState.laborAndDelivery) {
      total_amount += invoice.labor_and_delivery_amount || 0;
    }

    if (amountState.salesTax) {
      total_amount += invoice.sales_tax_amount || 0;
    }

    if (amountState.discount) {
      total_amount -= invoice.discount_amount || 0;
    }

    if (amountState.fee) {
      total_amount += invoice.fee_amount || 0;
    }

    let amount_due = total_amount;

    if (amountState.paymentsAndDeposits) {
      amount_due -= invoice.payments_and_deposits_amount || 0;
    }

    setInvoice({ ...invoice, total_amount, amount_due });
  }, [
    invoice.subtotal_amount,
    invoice.labor_and_delivery_amount,
    invoice.sales_tax_amount,
    invoice.discount_amount,
    invoice.fee_amount,
    invoice.payments_and_deposits_amount,
  ]);

  return (
    <table className="table table-borderless">
      <thead className="table-light">
        <tr>
          <th style={{ width: "2%" }}> </th>
          <th style={{ width: "44%" }}>Description</th>
          <th>Quantity</th>
          <th>Unit Price</th>
          <th style={{ width: "12%" }}>Amount</th>
          <th>{isNew || !props.canAllocate ? "" : "Projects"}</th>
        </tr>
      </thead>

      <tbody>
        {items.map((item, index) => {
          if (item._destroy) return null;

          return (
            <LineItemRow
              isNew={isNew}
              company_id={company.id}
              projects={props.projects}
              endpoint={endpoint}
              key={`company-item-row-${index}`}
              item={item}
              updateItem={props.updateItem}
              removeItem={props.removeItem}
              canAllocate={props.canAllocate}
              allocatedProjects={props.allocatedProjects.filter(
                (a) => a.allocation_id == item.id
              )}
              fetchAllocatedProjects={props.fetchAllocatedProjects}
            />
          );
        })}
      </tbody>

      <tfoot>
        <tr>
          <td colSpan={3}>
            <button className="btn btn-sm btn-outline-dark" onClick={addItem}>
              Add Item
            </button>
          </td>

          <td colSpan={1}>
            <div className="float-end">Subtotal</div>
          </td>

          <td colSpan={1}>{displayPrice(invoice.subtotal_amount)}</td>
        </tr>
        {amountState.laborAndDelivery && (
          <tr>
            <td colSpan={4}>
              <div className="float-end">Labor & Delivery</div>
            </td>
            <td>
              {props.editAmounts ? (
                <CurrencyInput
                  id="labor-and-delivery"
                  allowNegative={false}
                  className="form-control"
                  placeholder="0.00"
                  prefix="$"
                  defaultValue={invoice.labor_and_delivery_amount || 0}
                  decimalsLimit={2}
                  onValueChange={(value) => {
                    setFieldValue(value, "labor_and_delivery_amount");
                  }}
                />
              ) : (
                displayPrice(invoice.labor_and_delivery_amount)
              )}
            </td>
          </tr>
        )}
        {amountState.fee && (
          <tr>
            <td colSpan={4}>
              <div className="float-end">Fee</div>
            </td>
            <td>
              {props.editAmounts ? (
                <CurrencyInput
                  id="fee"
                  allowNegative={false}
                  className="form-control"
                  placeholder="0.00"
                  prefix="$"
                  defaultValue={invoice.fee_amount || 0}
                  decimalsLimit={2}
                  onValueChange={(value) => {
                    setFieldValue(value, "fee_amount");
                  }}
                />
              ) : (
                displayPrice(invoice.fee_amount)
              )}
            </td>
          </tr>
        )}
        {amountState.discount && (
          <tr>
            <td colSpan={4}>
              <div className="float-end">Discount</div>
            </td>
            <td>
              {props.editAmounts ? (
                <CurrencyInput
                  id="discount"
                  allowNegative={false}
                  className="form-control"
                  placeholder="0.00"
                  prefix="$"
                  defaultValue={invoice.discount_amount || 0}
                  decimalsLimit={2}
                  onValueChange={(value) => {
                    setFieldValue(value, "discount_amount");
                  }}
                />
              ) : (
                displayPrice(invoice.discount_amount)
              )}
            </td>
          </tr>
        )}
        {amountState.salesTax && (
          <tr>
            <td colSpan={4}>
              <div className="float-end">Sales Tax</div>
            </td>
            <td>
              {props.editAmounts ? (
                <CurrencyInput
                  id="sales-tax"
                  allowNegative={false}
                  className="form-control"
                  placeholder="0.00"
                  prefix="$"
                  defaultValue={invoice.sales_tax_amount || 0}
                  decimalsLimit={2}
                  onValueChange={(value) => {
                    setFieldValue(value, "sales_tax_amount");
                  }}
                />
              ) : (
                displayPrice(invoice.sales_tax_amount)
              )}
            </td>
          </tr>
        )}
        <tr>
          <td colSpan={4}>
            <div className="float-end">Total</div>
          </td>
          <td>{displayPrice(invoice.total_amount)}</td>
        </tr>
        {amountState.paymentsAndDeposits && (
          <tr>
            <td colSpan={4}>
              <div className="float-end">Payments & Deposits</div>
            </td>
            <td>
              {props.editAmounts ? (
                <CurrencyInput
                  allowNegative={false}
                  id="payments_and_deposits_amount"
                  className="form-control"
                  placeholder="0.00"
                  prefix="$"
                  defaultValue={invoice.payments_and_deposits_amount || 0}
                  decimalsLimit={2}
                  onValueChange={(value) => {
                    setFieldValue(value, "payments_and_deposits_amount");
                  }}
                />
              ) : (
                displayPrice(invoice.payments_and_deposits_amount)
              )}
            </td>
          </tr>
        )}
        <tr>
          <td colSpan={4}>
            <div className="float-end">
              <strong>Payment Due Amount</strong>
            </div>
          </td>
          <td>
            <strong>{displayPrice(invoice.amount_due)}</strong>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default LineItemsTable;
