import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import EquipmentForm from "./equipment-form";

import { updateCompanyPurchase, claimCompanyPurchase, rejectCompanyPurchase } from "modules/company_purchases";

import { present } from 'lib/string'
import { flashError } from "lib/flash";
import { useDebouncedEffect } from 'lib/utils'

const ReviewPurchase = ({ purchase, user, incentive, constraints }) => {
  const AUTOSAVE_TIMEOUT = 500;

  const [ currentPurchase, setCurrentPurchase ] = useState(purchase);

  useDebouncedEffect(() => {
    const params = constructRequestParams(currentPurchase);
    // This update is best-effort, hence no success or failure notification.
    updateCompanyPurchase(params)  
  }, [currentPurchase], AUTOSAVE_TIMEOUT);

  useEffect(() => {
    const { purchaser } = currentPurchase;
    setCurrentPurchase({
      ...currentPurchase,
      purchaser: {
        ...currentPurchase.purchaser,
        name: present(purchaser.name) ? purchaser.name : user.name,
        email: present(purchaser.email) ? purchaser.email : user.email,
        phone_number: present(purchaser.phone_number) ? purchaser.phone_number : user.phone_number,
        title: present(purchaser.title) ? purchaser.title : user.title
      }
    })
  }, [])

  // For request to claim or update (i.e. save) company purchase.
  const constructRequestParams = (companyPurchase) => {
    return { 'company_purchase': companyPurchase,
             id: companyPurchase.id,
             incentive_id: incentive.id,
             seller_name: companyPurchase.seller.name,
             company_id: companyPurchase.company_id
           }
  }

  const onClaim = (e) => {
    const params = constructRequestParams(currentPurchase);

    claimCompanyPurchase(params).then((res) => {
      location.reload();
    }).catch((errors) => {
      errors.map((err) => {
        flashError(`There was an error: ${err}`);
      });
    });
  };

  const onReject = (id) => {
    rejectCompanyPurchase(id).then((res) => {
      location.reload();
    }).catch((errors) => {
      errors.map((err) => {
        flashError(`There was an error: ${err}`);
      });
    });
  }

  const buttons = (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip>
            This item will be removed from the review page for this incentive, 
            though will still potentially be eligible for other incentives.
          </Tooltip>}
      >
        <button
          className="btn btn-light"
          onClick={(e) => {
            e.preventDefault();

            onReject(currentPurchase.id);
          }}
        >
          Reject
        </button>
      </OverlayTrigger>
      <OverlayTrigger
        overlay={
          <Tooltip>Click here once you've filled in the information above. 
            We'll then generate CDTFA forms and letters to the vendor for you to verify. 
            Don't worry; we won't send or file anything without your permission!
          </Tooltip>}
      >
        <button
          className="btn btn-action" type="submit"
        >
          Claim
        </button>
      </OverlayTrigger>
    </>
  )

  return (
    <EquipmentForm 
      currentEquipment={currentPurchase}
      setCurrentEquipment={setCurrentPurchase}
      constraints={constraints}
      onSubmit={onClaim}
      buttons={buttons}/>
  );
};

export default ReviewPurchase;
