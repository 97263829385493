import React, { useState } from "react";

const KeywordSearch = ({
  state,
  setState,
  updatePagination,
  placeholder = "Search",
  onSearchActivate,
  background = "gray",
  size = "normal",
}) => {
  const [localQuery, setLocalQuery] = useState(state.query || "");
  let searchTimeOut = null;

  const handleChange = (value) => {
    setLocalQuery(value);
    clearTimeout(searchTimeOut);

    searchTimeOut = setTimeout(() => {
      if (value.length >= 2 || value === "") {
        setPropsState("query", value);
        if (onSearchActivate) {
          onSearchActivate(value);
        }
      }
    }, 300);
  };

  const setPropsState = (field, value) => {
    if (updatePagination !== undefined) {
      updatePagination({ page: 1, first_load: true });
    }

    setState({
      ...state,
      [field]: value,
    });
  };

  const containerClass = `keyword-search ${background}-background ${size}`;

  return (
    <div className={containerClass}>
      <i className="bi bi-search" />
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        value={localQuery}
        onChange={(e) => {
          e.preventDefault();
          handleChange(e.target.value);
        }}
        style={{ paddingLeft: "50px" }}
      />
    </div>
  );
};

export default KeywordSearch;
