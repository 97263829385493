import React, { useState, useEffect } from "react";
import { HashRouter, useLocation } from "react-router-dom";
import { capitalize, words } from "lodash-es";

const TabRouter = ({ children }) => {
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState();

  const getTabName = () => {
    let route = window.location.hash;
    return words(route.slice(2)).map(capitalize).join(" ");
  };

  useEffect(() => {
    setCurrentTab(getTabName());
  }, [location]);

  return <>{React.cloneElement(children, { currentTab })}</>;
};

function withUiNavTabs(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <HashRouter>
          <TabRouter>
            <WrappedComponent {...this.props} {...this.state} />
          </TabRouter>
        </HashRouter>
      );
    }
  };
}

export default withUiNavTabs;
