import axios from "lib/axios";
import { encodeParams } from 'lib/utils';

export const fetchAdminMetaEligibilityFields = (params = {}) => {
  const url = `/admin/meta_eligibility_fields`;
  params = encodeParams(params);

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchAdminActiveMetaFields = (params = {}) => {
  const url = `/admin/meta_eligibility_fields/active_fields`;

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};


export const createAdminMetaField = (params = {}) => {
  const url = `/admin/meta_eligibility_fields`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateAdminMetaField = (params = {}) => {
  const url = `/admin/meta_eligibility_fields/${params.id}`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const removeAdminMetaField = (params = {}) => {
  const url = `/admin/meta_eligibility_fields/${params.id}`;

  return axios
    .delete(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
