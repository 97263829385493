import React, { useState } from "react";
import Dropzone from "react-dropzone";
import UploadingInProgress from "./uploading-in-progress";
import { humanFileSize } from "lib/string";

const UploadFiles = (props) => {

  const [attachments, setAttachments] = useState(
    props.attachments.file_resources || []
  );
  const [showUploadFiles, setShowUploadFiles] = useState(true);
  const [showUploadingInProgress, setShowUploadingInProgress] = useState(false);
  const [reuploadFile, setReuploadFile] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);

  const uploadFileResource = (files) => {
    setAttachedFiles(files);
    setShowUploadFiles(false);
    setShowUploadingInProgress(true);
  };

  const shouldShowNext = () => {
    return props.skipRoute || (attachments && attachments.length > 0);
  };

  const nextStep = () => {
    window.location = props.nextRoute;
  };

  const uploadingInProgressView = (files) => {
    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <h3>
              <strong>Upload Files</strong>
            </h3>
          </div>

          <div className="row">
            <div className="col-md-12">
              {props.description && (
                <p>
                  <div
                    className="description"
                    style={{
                      marginBottom: "20px",
                      lineHeight: "1.5",
                      color: "#1D3360",
                    }}
                  >
                    {props.description}{" "}
                  </div>
                </p>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <input
              id="backButton"
              name="file_resource_table"
              className="form-control d-none"
              onClick={(e) => {
                e.preventDefault();
                setShowUploadFiles(true);
                setShowUploadingInProgress(false);
              }}
            />
            <label
              htmlFor="backButton"
              id="file_resource_table"
              className="btn btn-light float-end"
            >
              Upload More
            </label>
          </div>
        </div>

        <hr />

        {files &&
          files.map((file, index) => (
            <UploadingInProgress
              file={file}
              fileTypes={props.fileTypes}
              index={index}
              filesLength={files.length}
              section_key={props.section}
              step_key={props.step}
              reuploadFile={(value) => setReuploadFile(value)}
              attachments={(files) => setAttachments(files)}
            />
          ))}
      </div>
    );
  };

  const uploadFiles = () => {
    return (
      <div>
        {props.description && (
          <h5>
            <div
              className="description"
              style={{
                marginBottom: "20px",
                lineHeight: "1.5",
                color: "#1D3360",
              }}
            >
              {" "}
              <h3>
                <strong> Please upload the following documents</strong>
              </h3>
              <p> {props.description} </p>
            </div>
          </h5>
        )}
        <Dropzone onDrop={(acceptedFiles) => uploadFileResource(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section className="dropzone">
              <div className="dropzone-button" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="row">
                  <i
                    className="bi bi-cloud-arrow-up"
                    style={{ fontSize: "96px", color: "#6A7894" }}
                  ></i>
                </div>
                <h5>
                  <strong>
                    {props.dragInstructions
                      ? props.dragInstructions
                      : "Drag and drop your files here or browse to upload."}
                  </strong>
                </h5>
                <div className="row">
                  <div className="col-md-12">
                    Use a pdf or even a picture saved as JPG or PNG.
                  </div>
                </div>
                <div className="col-md-12">
                  <input
                    type="file"
                    id="uploadButton"
                    name="upload_file_resource"
                    className="form-control d-none"
                    onChange={(e) => {
                      e.preventDefault();
                      uploadFileResource(e.target.files);
                    }}
                  />
                  <label
                    htmlFor="uploadButton"
                    id="upload_file_resource"
                    className="btn btn-large btn-action my-3 mb-4 px-4"
                  >
                    Browse Files
                  </label>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  };

  const showAttachments = () => {
    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>File Size</th>
            </tr>
          </thead>
          <tbody>
            {attachments.map((resource, index) => {
              return (
                <tr key={`company-file-resources-row-${index}`}>
                  <td>
                    <i className={`bi bi-filetype-${resource.type}`} />
                    <a href={resource.url} target="_blank">
                      {resource.name}
                    </a>
                  </td>
                  <td>
                    <p className="text-muted fs-6">
                      {humanFileSize(resource.size)}
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      {attachments.length > 0 && showAttachments()}
      {showUploadingInProgress && uploadingInProgressView(attachedFiles)}
      {showUploadFiles && uploadFiles()}

      <div className="form-group mt-4">
        <div className="float-end">
          {props.previousRoute && (
            <a
              href={props.previousRoute}
              className="btn btn-light btn-large my-3 mb-4 px-4 me-2"
            >
              <i
                className="bi bi-chevron-left icon-back-chevron"
                style={{ marginRight: "4px" }}
              ></i>
              <i
                className="bi bi-arrow-left icon-back-arrow"
                style={{ marginRight: "4px" }}
              ></i>
              Back
            </a>
          )}
          {/* TODO: Properly implement skipRoute or shouldShowNext conditions */}
          <button
            className="btn btn-large btn-action my-3 mb-4 px-4 next-button"
            type="submit"
            onClick={nextStep}
          >
            {props.nextRoute === "/onboarding/complete" ? "Complete" : "Next"}
            <i
              className="bi bi-chevron-right icon-next-chevron"
              style={{ marginLeft: "4px" }}
            ></i>
            <i
              className="bi bi-arrow-right icon-next-arrow"
              style={{ marginLeft: "4px" }}
            ></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadFiles;
