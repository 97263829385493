import React, { useEffect, useState } from "react";

import { PlaidLinkHandler } from "components/integrations";
import AddBankAccount from "./add-bank-account";
import { BankTile } from "./bank-tile";


const BankAccounts = ({ company, bankAccounts, plaidProps }) => {
  const [ bankAccountsByInstitution, setBankAccountsByInstitution] = useState({});
  const [ accountsSorted, setAccountsSorted ] = useState(false);
  const [ editableAccountId, setEditableAccountId ] = useState(null);

  useEffect(() => {
    let bankAccountsByInstitution = {}

    bankAccounts.forEach((bankAccount) => {
      let institutionName = bankAccount.details.institution_name; 

      if(bankAccountsByInstitution[institutionName] !== undefined) {
        bankAccountsByInstitution[institutionName].accounts.push(bankAccount);
      } else {
        bankAccountsByInstitution[institutionName] = {
          name: institutionName,
          item_id: bankAccount.item_id, // TODO: could be problem if link multiple times with same institution
          accounts: [bankAccount]
        };
      }
    });

    let sortedBankAccountsByInstitution = Object.keys(bankAccountsByInstitution).sort().map(
      (institutionName) => {
        return bankAccountsByInstitution[institutionName];
      }
    );

    setBankAccountsByInstitution(sortedBankAccountsByInstitution);
    setAccountsSorted(true);
  }, [bankAccounts]);

  if (!accountsSorted) {
    return null;
  }

  return (
    <div>
      <div>
        <div className="d-flex row home-dashboard">
          <div className="card p-3 mt-1 mb-3 z-depth-2">
            <h3 className="panel-title">Add Bank Account</h3>
            <div className="row">
              <div className="col text-center">

                <PlaidLinkHandler {...plaidProps} setLocation={() => {
                  window.location.reload(true)
                }}>
                  <button className='btn btn-primary' style={{width: "350px"}} >
                    Connect Bank Account
                  </button>
                </PlaidLinkHandler>

              </div>
              <div className="col text-center">
                <AddBankAccount/>
              </div>
            </div>
          </div>
          {
            bankAccountsByInstitution.map((institution) => {
              return (
                <BankTile
                  key={`connected-tile-${institution.name}`}
                  institution={institution}
                  editableAccountId={editableAccountId}
                  setEditableAccountId={setEditableAccountId}
                />
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default BankAccounts;
