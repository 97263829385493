import React, { useState, useEffect } from "react";
import { useSetState } from "react-hanger";

import { UIFormLabel, UITags } from "components/ui";

import OrganizationSelector from "../common/organization-selector";
import IncentiveTypeSelector from "../common/incentive-type-selector";

import TextareaAutosize from "react-textarea-autosize";

import { updateAdminIncentive } from "modules/incentives";
import { flashSuccessLater, flashError } from "lib/flash";
import { words, capitalize } from "lodash";

import StateSelector from "components/common/state-selector";

const BasicInfo = (props = {}) => {
  const [charCount, setCharCount] = useState(
    props.incentive.short_description
      ? props.incentive.short_description.length
      : 0
  );
  const { state: incentiveType, setState: setIncentiveTypeState } = useSetState(
    {
      value: null,
      label: "",
    }
  );

  const { state: incentive, setState: setIncentiveState } = useSetState(
    props.incentive
  );

  useEffect(() => {
    if (props.incentive && props.incentive.incentive_type) {
      const { incentive_type } = props.incentive;
      setIncentiveTypeState({
        value: incentive_type.id,
        label: incentive_type.name,
      });
    }
  }, []);

  useEffect(() => {
    setIncentiveState(props.incentive);
  }, [props.incentive]);

  const onUpdateIncentive = (e) => {
    e.preventDefault();

    updateAdminIncentive(incentive)
      .then(() => {
        flashSuccessLater("incentive updated");
        location.reload(true);
      })
      .catch((resp) => {
        resp.errors.map((error) => {
          flashError(error);
        });
        return Promise.resolve();
      });
  };
  const handleFieldChange = (field, value) => {
    setIncentiveState({
      [field]: value,
    });
  };

  const toTitleCase = (str) => {
    return words(str).map(capitalize).join(" ");
  };

  const setTags = (tags) => {
    handleFieldChange("tags", tags);
  };

  const setOrganization = (organizationId, organization) =>
    organizationId ? { id: organizationId, ...organization } : null;

  const onShortDescriptionChange = (e) => {
    const value = e.target.value;
    setCharCount(value.length);
    handleFieldChange("short_description", value);
  };

  const countStyle = {
    color: charCount > 255 ? "red" : "#888",
    marginTop: "4px",
  };

  return (
    <div>
      <form className="form" onSubmit={onUpdateIncentive}>
        <div className="card p-3 mt-1 mb-3 z-depth-2">
          <h3 className="panel-title">Basic Info</h3>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3 ui-label-input">
                <UIFormLabel label="Name" />
                <input
                  type="text"
                  name="Name"
                  className="form-control"
                  placeholder="CA Manufacturer Incentive"
                  value={incentive.name}
                  onChange={(e) => {
                    e.preventDefault();
                    handleFieldChange("name", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 ui-label-input">
                <UIFormLabel
                  label="Slug"
                  tooltip="The text used for any public facing URL. This value must start with a letter."
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={incentive.slug}
                  onChange={(e) => {
                    e.preventDefault();
                    const value = e.target.value;
                    if (value.match(/^\d/)) {
                      alert("The slug cannot start with a number.");
                    } else {
                      handleFieldChange("slug", value);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mb-3 ui-label-input">
            <UIFormLabel label="Description" />

            <TextareaAutosize
              type="text"
              name="Description"
              className="form-control"
              placeholder="Enter description here..."
              value={incentive.description || ""}
              onChange={(e) => {
                e.preventDefault();
                handleFieldChange("description", e.target.value);
              }}
            />
          </div>
          <div className="mb-3 ui-label-input">
            <UIFormLabel label="Short Description" />
            <TextareaAutosize
              type="text"
              name="Short Description"
              className="form-control"
              placeholder="Short description to be used in header content (255 char max)..."
              value={incentive.short_description || ""}
              onChange={onShortDescriptionChange}
            />
            <div style={countStyle}>({charCount}/255)</div>
          </div>
        </div>
        <div className="card p-3 mt-1 mb-3 z-depth-2">
          <h3 className="panel-title">Economic & Eligiblity Descriptions</h3>
          <div className="mb-3 ui-label-input">
            <UIFormLabel
              label="Eligibility Description"
              tooltip="This should be a short text description of the rules from the eligibility tab."
            />
            <TextareaAutosize
              type="text"
              name="Description"
              className="form-control"
              placeholder="Enter eligibility description"
              value={incentive.eligibility_description || ""}
              onChange={(e) => {
                e.preventDefault();
                handleFieldChange("eligibility_description", e.target.value);
              }}
            />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3 ui-label-input">
                <UIFormLabel label="Economics" />
                <TextareaAutosize
                  type="text"
                  name="Economics"
                  className="form-control"
                  placeholder="Enter description here..."
                  value={incentive.economics || ""}
                  onChange={(e) => {
                    e.preventDefault();
                    handleFieldChange("economics", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 ui-label-input">
                <UIFormLabel label="Default Estimate" />
                <TextareaAutosize
                  type="text"
                  name="Default Estimate"
                  className="form-control"
                  placeholder="Enter a dollar figure estimate for incentive"
                  value={incentive.estimate_default || ""}
                  onChange={(e) => {
                    e.preventDefault();
                    handleFieldChange("estimate_default", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card p-3 mt-1 mb-3 z-depth-2">
          <h3 className="panel-title">Tags</h3>
          <div className="mb-3 ui-label-input">
            <UIFormLabel label="Region Type" />
            <select
              className="form-control"
              value={incentive.region_type}
              onChange={(e) => {
                e.preventDefault();
                handleFieldChange("region_type", e.target.value);
              }}
            >
              <option
                value=""
                label="Select Region Type"
                disabled
                selected={incentive.region_type === null}
              >
                Select Region Type
              </option>
              <option value="city">City</option>
              <option value="county">County</option>
              <option value="state">State</option>
              <option value="federal">Federal</option>
            </select>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3 ui-label-input">
                <UIFormLabel label="City" />
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={incentive.city}
                  onChange={(e) => {
                    e.preventDefault();
                    handleFieldChange("city", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <UIFormLabel label="State" />
                <StateSelector
                  onChange={(newState) => {
                    handleFieldChange("state", newState);
                  }}
                  state={incentive.state}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 ui-label-input">
              {" "}
              <UIFormLabel label="Incentive Type" />
              <IncentiveTypeSelector
                incentiveType={incentive.incentive_type_id}
                selectIncentiveType={(option) => {
                  handleFieldChange("incentive_type_id", option);
                }}
              />
            </div>
            <div className="col-md-6 ui-label-input">
              <div className="mb-3">
                <UIFormLabel label="Tags" />
                <UITags setTags={setTags} tags={incentive.tags} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 ui-label-input">
                <UIFormLabel label="Description Video URL" />
                <input
                  type="text"
                  name="Description Video URL"
                  className="form-control"
                  placeholder="A YouTube or other video link..."
                  value={incentive.video_description_url}
                  onChange={(e) => {
                    e.preventDefault();
                    handleFieldChange("video_description_url", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 ui-label-input">
                <UIFormLabel
                  label="Keywords"
                  tooltip="These are only to enhance search results and will not be visible to users."
                />
                <input
                  type="text"
                  name="Keywords"
                  className="form-control"
                  placeholder="Keywords for full text search"
                  value={incentive.keywords}
                  onChange={(e) => {
                    e.preventDefault();
                    handleFieldChange("keywords", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card p-3 mt-1 mb-3 z-depth-2">
          <h3 className="panel-title">Organizations</h3>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <UIFormLabel label="Sponsoring Organization" />
                <OrganizationSelector
                  currentOrganizationId={incentive.sponsoring_organization_id}
                  currentOrganization={setOrganization(
                    incentive.sponsoring_organization_id,
                    incentive.sponsoring_organization
                  )}
                  selectOrganization={(id) => {
                    handleFieldChange("sponsoring_organization_id", id);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <UIFormLabel label="Approving Organization" />
                <OrganizationSelector
                  currentOrganizationId={incentive.approving_organization_id}
                  currentOrganization={setOrganization(
                    incentive.approving_organization_id,
                    incentive.approving_organization
                  )}
                  selectOrganization={(id) => {
                    handleFieldChange("approving_organization_id", id);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="clearfix mb-3">
          <div className="mt-3 btn-group float-end">
            <a className="btn btn-light" href="/admin/incentives">
              Cancel
            </a>
            <button className="btn btn-primary">Update</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BasicInfo;
