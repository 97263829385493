import React from "react";
import UIFormLabel from "../ui-form-label";
import UIValidationError from "../ui-validation-error";

import { useField } from "formik";

const UILabelInputFormik = (props) => {
  const [field, meta] = useField(props);

  return (
    <div className="ui-label-input">
      <UIFormLabel
        label={props.label}
        required={props.required}
        tooltip={props.tooltip}
      />
      {!!props.description && <p>{props.description}</p>}
      <input
        {...field}
        label={props.label}
        name={props.name}
        className="form-control"
      />
      {meta.error && meta.touched && (
        <UIValidationError
          message={meta.error.replace(`${props.name}`, "This")}
        />
      )}
    </div>
  );
};

export default UILabelInputFormik;
