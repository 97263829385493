import React from 'react';

const Applications = ({ applications }) => {
  const renderEmpty = () => {
    return (
      <div className='application-card card'>
        <div className='card-body'>
          <div className='d-flex align-items-center justify-content-between'>
            <h3 className='mb-0'>
              You have no active applications.
            </h3>
          </div>
        </div>
      </div>
    )
  }

  const renderApps = () => {
    return applications.map((application, index) => {
      return (
        <div className='application-card card mb-3' onClick={(e) => {
          e.preventDefault()
          window.location = `/applications/${application.slug}/#apply`
        }}>
          <div className='card-body'>
            <div className='d-flex align-items-center'>
              <img 
                width="100px"
                src={application && application.sponsoring_organization ?
                    application.sponsoring_organization.logo : ''}
              />
              <h3 className='ms-3 mb-0'>
                { application.name } 
              </h3>
            </div>
          </div>
        </div>
      )          
    })
  }
  return (
    <div className='mb-3'>
      <h2>Applications</h2>
      { applications.length > 0 ? renderApps() : renderEmpty() }
    </div>
  )
}

export default Applications;
