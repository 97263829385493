import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CsvUploadModal from "./csv-upload-modal";
import { flashError, flashSuccess } from "lib/flash";

//TODO: This should be more generic
import { importAdminCompanies } from "modules/companies";

const AdminPanelHeader = ({
  title,
  singularTitle,
  description,
  addNewUrl,
  exportCsvUrl,
  importCsvUrl,
}) => {
  const [showImportModal, setShowImportModal] = useState(false);

  const handleShowImportModal = () => setShowImportModal(true);
  const handleCloseImportModal = () => setShowImportModal(false);

  //TODO: This should be more generic
  const handleImport = async (file) => {
    try {
      const result = await importAdminCompanies(file);
      flashSuccess("Companies uploaded successfully.");
      handleCloseImportModal();
    } catch (error) {
      alert(error.error || "An error occurred while importing the file.");
      console.error("Import error:", error);
    }
  };

  return (
    <>
      <header className="panel-header">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h3>{title}</h3>
          </div>
          <div className="col-md-6 text-end">
            {importCsvUrl && (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 150, hide: 200 }}
                overlay={
                  <Tooltip id="button-tooltip" className="cashflow-tooltip">
                    Warning: The headers of your csv need to match database
                    columns exactly and the first column must be a row number.
                  </Tooltip>
                }
              >
                <button
                  onClick={handleShowImportModal}
                  className="btn-export d-none d-sm-inline-block"
                >
                  <i className="bi bi-upload"></i> CSV Import
                </button>
              </OverlayTrigger>
            )}
            {exportCsvUrl && (
              <a
                href={exportCsvUrl}
                className="btn-export me-3 d-none d-sm-inline-block"
              >
                <i className="bi bi-download"></i> Export CSV
              </a>
            )}
            {addNewUrl &&
              (description ? (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 150, hide: 200 }}
                  overlay={
                    <Tooltip id="button-tooltip" className="cashflow-tooltip">
                      {description}
                    </Tooltip>
                  }
                >
                  <a href={addNewUrl} className="btn-gradient">
                    + Create {singularTitle || title}
                  </a>
                </OverlayTrigger>
              ) : (
                <a href={addNewUrl} className="btn-gradient">
                  + Create {singularTitle || title}
                </a>
              ))}
          </div>
        </div>
      </header>
      {importCsvUrl && (
        <CsvUploadModal
          show={showImportModal}
          handleClose={handleCloseImportModal}
          handleUpload={handleImport}
          title={`Import ${title}`}
        />
      )}
    </>
  );
};

export default AdminPanelHeader;
