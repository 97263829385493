import React from 'react';

const UIOptsNested = (props) => {
  const prepareOptions = (types, level = 0) => {
    if (!types) return null;
    let options = [];

    for (var i = 0; i < types.length; i++) {
      const type = types[i];

      if (type.children && type.children.length > 0) {
        const childOptions = prepareOptions(type.children, level + 1);

        if (level === 0) {
          options.push(
            <optgroup label={type.name} key={`opt-group-${type.name}-${i}`}>
              <option value={type.id}>{type.name}</option>

              {childOptions}
            </optgroup>
          );
        } else {
          options = options.concat(childOptions);
        }
      } else {
        options.push(
          <option key={`option-${type.name}-${i}`} value={type.id}>
            {type.name}
          </option>
        );
      }
    }

    return options;
  };

  return (
    <>
      { prepareOptions(props.opts) }
    </>
  )
}

export default UIOptsNested;