import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Loading from '../loading'

const PdfPreview = (props) => {
  const [ isLoading, setIsLoading ] = useState(true)
  const [ numPages, setNumPages ] = useState(1);
  const [ pageNumber, setPageNumber ] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setIsLoading(false)
     setNumPages(numPages);
   }

   function previousPage(e) {
    e.preventDefault()
    setPageNumber(pageNumber - 1)
   }

   function nextPage(e) {
    e.preventDefault()
    setPageNumber(pageNumber + 1)
   }

  function renderToolbar() {
    return (
      <div className='pdf-wrapper'>
        <div className='row justify-content-center'>
          <div className='col-4 my-3'>
            <button
              className='btn btn-muted me-3'
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              <i className="bi bi-chevron-left"></i>
            </button>
            <p className='d-inline-block'>
              Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </p>
            <button
              className='ms-3 btn btn-muted'
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              <i className="bi bi-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    )
  }

   return (
      <div>
        { isLoading ? <Loading /> : null }
        <div className='pdf-wrapper'>
          <Document file={props.url} onLoadSuccess={onDocumentLoadSuccess} loading={null} >
            <Page pageNumber={pageNumber} width={900}/>
          </Document>
        </div>
        { isLoading ? null : renderToolbar() }
     </div>
   );
}

export default PdfPreview;