import React, { useState } from "react";

import {
  withUINavTabs,
  UINavHorizontalInternal,
  UINavPanel,
  UINavSection,
} from "components/ui";

import BasicInfo from "./basic-info";
import Demographics from "./demographics";
import EditCompanyContextProvider from "components/common/companies/contexts/edit-company-context";
import Locations from "./locations";
import Users from "./users";
import CompanyWorkers from "./company-workers";
import CompanyFileResources from "components/common/companies/file-resources";
import Integrations from "./integrations";
import TimeIntegrations from "./time_integrations";
import Narrative from "components/common/companies/edit/narrative";
import BankAccounts from "./bank-accounts";
import CashFlowSettings from "../../cashflow/cashflow-settings";
import Notifications from "./notifications";
import ProjectTemplateEditor from "components/projects/project-template-editor";

const EditCompany = ({
  company,
  businessEntities,
  roles,
  availableAccountingIntegrations,
  bankAccounts,
  financialData,
  plaidProps,
}) => {
  const [currentTab, setCurrentTab] = useState("Basic Info");

  return (
    <EditCompanyContextProvider company={company}>
      <div className="panel-body pb-4 admin-company-form pt-4">
        <div className="row">
          <div className="col-md-12">
            <h1>{company.name}</h1>
            <UINavHorizontalInternal
              style="tabs"
              tabs={[
                {
                  name: "Basic Info",
                  link: "/basic-info",
                },
                {
                  name: "Demographics",
                  link: "/demographics",
                },
                {
                  name: "Locations",
                  link: "/locations",
                },
                {
                  name: "Users",
                  link: "/users",
                },
                {
                  name: "Employees",
                  link: "/company_workers",
                },
                {
                  name: "Files",
                  link: "/file_resources",
                },
                {
                  name: "Accounting Integrations",
                  link: "/integrations",
                  // TODO: Turn into accounting_integrations
                },
                {
                  name: "Labor Integrations",
                  link: "/time_integrations",
                },
                {
                  name: "Bank Accounts",
                  link: "/bank_accounts",
                },
                {
                  name: "Cash Flow",
                  link: "/cashflow/settings",
                },
                {
                  name: "Narrative",
                  link: "/narrative",
                },
                {
                  name: "Notifications",
                  link: "/notifications",
                },
                {
                  name: "Project Library",
                  link: "/project-templates",
                },
              ]}
            />
          </div>
          <div className="col-md-12">
            <UINavPanel current={currentTab}>
              <UINavSection tab="/basic-info">
                <BasicInfo businessEntities={businessEntities} />
              </UINavSection>
              <UINavSection tab="/demographics">
                <Demographics />
              </UINavSection>
              <UINavSection tab="/locations">
                <Locations />
              </UINavSection>
              <UINavSection tab="/users">
                <Users roles={roles} />
              </UINavSection>
              <UINavSection tab="/company_workers">
                <CompanyWorkers />
              </UINavSection>
              <UINavSection tab="/file_resources">
                <CompanyFileResources
                  company={company}
                  paginationData={{ total: company.file_resources_count }}
                />
              </UINavSection>
              <UINavSection tab="/integrations">
                <Integrations
                  company={company}
                  availableAccountingIntegrations={
                    availableAccountingIntegrations
                  }
                />
              </UINavSection>
              <UINavSection tab="/time_integrations">
                <TimeIntegrations company={company} />
              </UINavSection>
              <UINavSection tab="/bank_accounts">
                <BankAccounts
                  bankAccounts={bankAccounts}
                  company={company}
                  plaidProps={plaidProps}
                />
              </UINavSection>
              <UINavSection tab="/cashflow/settings">
                <CashFlowSettings
                  company={company}
                  cashThreshold={company.threshold}
                />
              </UINavSection>
              <UINavSection tab="/narrative">
                <Narrative />
              </UINavSection>
              <UINavSection tab="/notifications">
                <Notifications />
              </UINavSection>
              <UINavSection tab="/project-templates">
                <ProjectTemplateEditor company={company} />
              </UINavSection>
            </UINavPanel>
          </div>
        </div>
      </div>
    </EditCompanyContextProvider>
  );
};

export default withUINavTabs(EditCompany);
