import React from "react";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";
import "react-datepicker/dist/react-datepicker.css";

import { UILabelInput, UIFormLabel } from "components/ui";
import { fetchNaicsCode } from "modules/naics-code";
import ReferralSourceSelector from "../common/referral-source-selector";

const BasicInfo = ({
  onboarding,
  variables,
  onFieldChange,
  businessEntities,
  onNaicsCodeSelect,
}) => {
  const onOptionsLoad = (value, cb) => {
    fetchNaicsCode({ query: value }).then((res) => {
      cb(
        res.map((row) => ({
          value: row.id,
          label: `${row.code} (${row.title})`,
        }))
      );
    });
  };

  const handleReferralSourceSelect = (selectedOption) => {
    onFieldChange(selectedOption?.value, "referral_source_id");
    onFieldChange(selectedOption?.label, "referral_source_name");
  };

  return (
    <>
      <div className="card p-3 mt-1 mb-3 z-depth-2">
        <div className="row mt-1">
          <h3 className="panel-title">Company Information</h3>
        </div>
        <div className="row">
          <div className="col-md-6">
            <UILabelInput
              label="Business Name"
              required
              value={variables.name}
              onChange={(value) => {
                onFieldChange(value, "name");
              }}
            />
          </div>
          <div className="col-md-6">
            <UILabelInput
              label="Website"
              value={variables.website}
              onChange={(value) => {
                onFieldChange(value, "website");
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <UILabelInput
              label="Number of Employees"
              tooltip="A rough estimate of employees is fine"
              value={variables.number_of_employees}
              onChange={(value) => {
                onFieldChange(value, "number_of_employees");
              }}
            />
          </div>
          <div className="col-md-6">
            <UILabelInput
              label="Gross Annual Revenue"
              tooltip="A rough estimate of revenue is fine"
              value={variables.annual_revenue}
              onChange={(value) => {
                onFieldChange(value, "annual_revenue");
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <strong>
              <label className="mb-1">NAICS Code</label>
            </strong>
            <div className="ui-label-input">
              <AsyncSelect
                isMulti
                loadOptions={onOptionsLoad}
                onChange={(object) => {
                  onNaicsCodeSelect(object, "naics_code_ids");
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <strong>
              <label htmlFor="legal_structure" className="mb-1">
                Legal Structure
              </label>
            </strong>
            <div className="ui-label-input">
              <select
                name="legal_structure"
                style={{ width: "100%" }}
                className="form-control"
                id="legal-structure"
                value={variables.business_entity_id}
                onChange={(e) => {
                  onFieldChange(e.target.value, "business_entity_id");
                }}
              >
                <option value="" disabled>
                  Select Legal Structure
                </option>
                {businessEntities.map((entity, index) => {
                  return (
                    <option key={`${entity.id}-${index}`} value={entity.id}>
                      {entity.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <strong>
              <label className="mb-1">Founded On</label>
            </strong>
            <div className="ui-label-input">
              <DatePicker
                className="form-control"
                name="Founded On"
                selected={variables.date_established}
                scrollableYearDropdown={true}
                showYearDropdown={true}
                yearDropdownItemNumber={100}
                maxDate={new Date()}
                onChange={(date) => onFieldChange(date, "date_established")}
              />
            </div>
          </div>
          <div className="col-md-6">
            <strong>
              <label htmlFor="onboarding-flow" className="mb-1">
                Onboarding Flow
              </label>
            </strong>
            <div>
              <div className="ui-label-input">
                <select
                  name="onboarding_flow"
                  id="onboarding-flow"
                  className="form-control"
                  value={variables.onboarding_flow_id}
                  onChange={(e) => {
                    onFieldChange(e.target.value, "onboarding_flow_id");
                  }}
                >
                  <option value="" disabled>
                    Select Onboarding Flow
                  </option>
                  {onboarding.map((flow, index) => {
                    return (
                      <option key={`${flow.id}-${index}`} value={flow.id}>
                        {flow.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="ui-label-input">
              <UIFormLabel label="Referral Source" />
              <ReferralSourceSelector
                value={variables.referral_source_id}
                label={variables.referral_source_name}
                onSelect={handleReferralSourceSelect}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicInfo;
