import React from "react";

const UIMoreSelectedBadge = ({ items }) => {
  if (items.length === 0) return null;

  return (
    <div className="badge bg-dark mx-2 d-flex align-items-center">
      <i className="bi bi-plus mt-1 fs-6"></i>
      <h6 className="mb-0">{items.length}</h6>
    </div>
  );
};

export default UIMoreSelectedBadge;
