import React, { useState, useEffect } from "react";

import { disconnectCodat } from "modules/companies";
import { flashError, flashSuccess } from "lib/flash";

// TODO: RENAME THIS TO Accounting Integrations

const Integrations = ({ company, availableAccountingIntegrations }) => {
  const [authenticated, setAuthenticated] = useState(
    company.integrated_accounting_software
  );

  useEffect(() => {
    setAuthenticated(company.integrated_accounting_software);
  }, [company]);

  const onDisconnect = () => {
    disconnectCodat()
      .then((res) => {
        flashSuccess("Successfully disconnected.");
        setAuthenticated(false);
      })
      .catch((res) => {
        flashError(res.error);
      });
  };

  return (
    <div>
        <div className="d-flex row home-dashboard">
          {!authenticated && availableAccountingIntegrations["results"] &&
            availableAccountingIntegrations["results"].map((integration, index) => {
              return (
                <div className="col-md-4" key={`integration-${index}`}>
                  <div
                    className="card mb-4 integration-card"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location = "/integrations/codat/authenticate?codat_integration_key=" + integration["key"];
                    }}
                  >
                  <div className="card-body d-flex" 
                      style={{'justifyContent': 'center', 'alignItems': 'center'}}
                    >
                      <img
                        src={integration.logoUrl}
                        alt={integration.name}
                        width="200px"
                        unselectable="on"
                        className="align-middle"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          {
            <div className="ms-auto">
              {authenticated ? (
                <div>
                  You're connected via&nbsp;
                  {company.integrated_accounting_via}.
                  <br />
                  <br />
                  <button
                    className="btn btn-light"
                    rel="nofollow"
                    onClick={onDisconnect}
                  >
                    Click here to disconnect
                  </button>
                </div>
              ) : null}
            </div>
          }
        </div>
    </div>
  );
};

export default Integrations;
