import React from 'react';
import TextTruncate from 'react-text-truncate';

const IncentiveCard = ({ incentive, location }) => {
  const statusClass = (incentive) => {
    if(incentive.is_qualified) {
      return 'qualified'
    }

    if (incentive.is_eligible) {
      return 'eligible'
    }

    return ''
  }

  return (
    <div> 
      <div className={`card mb-4 incentive-card ${statusClass(incentive)}`} onClick={(e) => {
        e.preventDefault()
        window.location = location
      }}>
        <div className='card-body'>
          <img 
            src={incentive.sponsoring_organization.logo} 
            alt={incentive.sponsoring_organization_name} 
            width='80px'
            className='mb-3'
          />
          <h5>{ incentive.name }</h5>
          <TextTruncate
            line={2}
            element="span"
            truncateText="…"
            text={incentive.short_description}
          />
        </div>
        {
          incentive.estimate && (
            <div className='card-footer'>
              Claim up to { incentive.estimate } 
            </div>
          )
        }
      </div> 
    </div>
  )
}

export default IncentiveCard;
