import React, { useState, useEffect } from "react";
import { fetchAdminFunnels } from "modules/funnels";
import moment from "moment";
import AdminPanelHeader from "../../common/admin-panel-header";
import AdminPanelIndexControls from "../../common/admin-panel-index-controls";

const Funnels = (props) => {
  const [funnels, setFunnels] = useState(props.funnels || []);
  const [query, setQuery] = useState("");

  useEffect(() => {
    fetchAdminFunnels({ query }).then((data) => {
      setFunnels(data);
    });
  }, [query]);

  return (
    <>
      <AdminPanelHeader
        title="Funnels"
        singularTitle="Funnel"
        addNewUrl="/admin/funnels/new"
      />
      <AdminPanelIndexControls
        keywordSearchConfig={{
          state: query,
          setState: (value) => setQuery(value.query),
        }}
      />
      <div className="px-3">
        <div className="panel-table">
          <table className="table admin-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Title</th>
                <th>Onboarding</th>
                <th>Layout</th>
                <th>Status</th>
                <th>Posted</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {funnels.map((funnel, index) => {
                return (
                  <tr className="row-with-shadow" key={`user-row-${index}`}>
                    <td className="admin-metadata">{funnel.id}</td>
                    <td>
                      {funnel.status === "published" ? (
                        <a href={`/${funnel.permalink}`} target="_blank">
                          {funnel.title}
                        </a>
                      ) : (
                        funnel.title
                      )}
                    </td>
                    <td>{funnel.onboarding_flow}</td>
                    <td>{funnel.layout}</td>
                    <td>{funnel.status}</td>
                    <td>
                      {funnel.posted_at
                        ? moment(funnel.posted_at).format("MM/DD/YYYY")
                        : null}
                    </td>
                    <td>
                      <a
                        href={`/admin/funnels/${funnel.id}/edit`}
                        className="btn btn-sm btn-outline-secondary"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Funnels;
