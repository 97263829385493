import React, { useState, useEffect } from "react";
import { fetchAdminIps } from "modules/ip-blocklist";
import moment from "moment";
import AdminPanelHeader from "../../common/admin-panel-header";
import AdminPanelIndexControls from "../../common/admin-panel-index-controls";

const IPBlocklist = (props) => {
  const [ips, setIps] = useState(props.ips || []);
  const [query, setQuery] = useState("");

  useEffect(() => {
    fetchAdminIps({ query }).then((data) => {
      setIps(data);
    });
  }, [query]);

  return (
    <>
      <AdminPanelHeader
        title="IP Blocklist"
        singularTitle="IP Block"
        description="IP addresses that are currently blocked from accessing the platform."
        addNewUrl="/admin/ip_blocklist/new"
      />
      <AdminPanelIndexControls
        keywordSearchConfig={{
          state: query,
          setState: (value) => setQuery(value.query),
        }}
      />
      <div className="px-3">
        <div className="panel-table">
          <table className="table admin-table">
            <thead>
              <tr>
                <th>IP Address</th>
                <th>Address</th>
                <th>Blocked</th>
                <th>Updated</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {ips.map((ip, index) => {
                return (
                  <tr key={`user-row-${index}`}>
                    <td>
                      <div className="user-info">
                        <h6 className="name mb-0">{ip.ip_address}</h6>
                      </div>
                    </td>
                    <td>{ip.address_string}</td>
                    <td>{ip.blocked ? "Blocked" : "Accessible"}</td>
                    <td>
                      {ip.updated_at
                        ? moment(ip.updated_at).format("MM/DD/YYYY")
                        : null}
                    </td>
                    <td>
                      <a
                        href={`/admin/ip_blocklist/${ip.id}/edit`}
                        className="btn btn-sm btn-outline-secondary"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default IPBlocklist;
