import React from 'react';
import ReactLoading from 'react-loading'

const Loading = (props) => {
  const height = props.height ? `${props.height}px` : '300px';
  const color = props.color ? props.color : '#00A2E9';
  const loadingHeight = props.loadingHeight ? props.loadingHeight : 50;

  return (
    <div className="d-flex align-items-center justify-content-center" style={{'height': height}}>
      <div className='loading-file'>
        <ReactLoading type={'spin'} color={color} height={loadingHeight} width={loadingHeight} />
      </div>
    </div>
  )
}

export default Loading;
