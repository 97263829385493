import React from 'react';
import { Dropdown } from 'react-bootstrap'

const RoleSelector = (props) => {
  const { selected, roles } = props;

  return (
    <div>
      { selected.name == "owner" ? <div className="pe-4">{selected.name}</div> :
        <Dropdown onSelect={(event) => {
          const parsedEvent = JSON.parse(event);
          if (parsedEvent.name == "owner") {
            if (window.confirm("Do you really want to make this user an owner?")) {
              props.update(parsedEvent)
            }
          } else { props.update(parsedEvent) }
        }}>
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            { selected.name }
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {
              roles.map((role, index) => {
                return (
                  <Dropdown.Item
                    key={`role-${index}-${role.id}`}
                    eventKey={JSON.stringify(role)}
                    active={selected.id === role.id}
                  >
                    { role.name }
                  </Dropdown.Item>
                )
              })
            }
          </Dropdown.Menu>
        </Dropdown>
      }
    </div>
  )
}

export default RoleSelector;
