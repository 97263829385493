import React, { useState, useEffect, useContext, useRef } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import axios from "axios";

import {
  UIFieldGroup,
  UIFormLabel,
  UILabelInput,
  UIValidationError,
} from "components/ui";
import RecurringPaymentRow from "./dashboard/components/recurring-payment-row";
import { flashError, flashSuccess, flashSuccessLater } from "lib/flash";
import { EditCompanyContext } from "../common/companies/contexts/edit-company-context.jsx";
import { fetchChartOfAccounts } from "modules/company_accounts_chart";
import { prettyDisplayPrice } from "lib/string";

const CashFlowSettings = ({ company }) => {
  const [originalThresholdValue, setOriginalThresholdValue] = useState(
    company.threshold
  );
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [recurringPayments, setRecurringPayments] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [tooltipVisibleRecurring, setTooltipVisibleRecurring] = useState(false);
  const [tooltipVisibleThreshold, setTooltipVisibleThreshold] = useState(false);
  const [tooltipVisibleAccounts, setTooltipVisibleAccounts] = useState(false);
  const targetRecurring = useRef(null);
  const targetThreshold = useRef(null);
  const targetAccounts = useRef(null);

  const { basicInfo, setBasicInfo, onUpdateCompany } =
    useContext(EditCompanyContext);

  const handleSave = async () => {
    try {
      await onUpdateCompany(basicInfo);
      setIsValueChanged(false);
    } catch (error) {
      console.error("Error updating threshold:", error);
      flashError(error);
    }
  };

  const toggleTooltipRecurring = () =>
    setTooltipVisibleRecurring(!tooltipVisibleRecurring);
  const toggleTooltipThreshold = () =>
    setTooltipVisibleThreshold(!tooltipVisibleThreshold);
  const toggleTooltipAccounts = () =>
    setTooltipVisibleAccounts(!tooltipVisibleAccounts);

  const displayThreshold =
    basicInfo.threshold !== null ? basicInfo.threshold : "50,000";

  const handleThresholdChange = (newValue) => {
    setBasicInfo((prevBasicInfo) => ({
      ...prevBasicInfo,
      threshold: newValue,
    }));
    setIsValueChanged(newValue !== originalThresholdValue);
  };

  useEffect(() => {
    setBasicInfo((prevBasicInfo) => ({
      ...prevBasicInfo,
      threshold: basicInfo.threshold,
    }));
  }, [basicInfo.threshold]);

  const handleSwitchChange = (account) => {
    const projectionAlgo = account.projectionAlgo === null ? "triple" : null;

    fetch(`/update_account_projection/${account.accountId}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ projection_algo: projectionAlgo }),
    })
      .then((response) => response.json())
      .then((data) => {
        const newAccounts = accounts.map((acc) => {
          if (acc.accountId === account.accountId) {
            return { ...acc, projectionAlgo: projectionAlgo };
          } else {
            return acc;
          }
        });
        setAccounts(newAccounts);
        flashSuccess("Your forecast has been updated!");
      })
      .catch((error) => console.error("Error:", error));
  };

  const fetchRecurringPayments = async () => {
    try {
      const response = await axios.get("/cashflow/settings/recurring_payments");
      const recurringPayments = response.data;
      setRecurringPayments(recurringPayments);
    } catch (error) {
      console.error("Error fetching recurring payments:", error);
    }
  };

  useEffect(() => {
    fetchRecurringPayments();
  }, []);

  useEffect(() => {
    const fetchAvgMonthlySpend = axios.get(
      "/company_accounts_charts/average_monthly_spend"
    );
    const fetchAvgMonthlyIncome = axios.get(
      "/company_accounts_charts/average_monthly_income"
    );

    Promise.all([
      fetchChartOfAccounts(),
      fetchAvgMonthlySpend,
      fetchAvgMonthlyIncome,
    ]).then((responses) => {
      const [
        accountsResponse,
        avgMonthlySpendResponse,
        avgMonthlyIncomeResponse,
      ] = responses;
      const avgMonthlySpend = avgMonthlySpendResponse.data;
      const avgMonthlyIncome = avgMonthlyIncomeResponse.data;

      setAccounts(
        accountsResponse.accounts.map((account) => {
          return {
            accountId: account.id,
            accountName: account.name,
            externalId: account.external_id,
            projectionAlgo: account.projection_algo,
            accountType: account.account_type,
            status: account.status,
            avgMonthlySpend: avgMonthlySpend[account.id] || null,
            avgMonthlyIncome: avgMonthlyIncome[account.id] || null,
          };
        })
      );
    });
  }, []);

  const addRecurringPayment = () => {
    const newRecurringPayment = {
      account_id: "",
      payment_name: "",
      amount: null,
      recurrence_type: "weekly",
      day_of_week: "",
      first_date: "",
      second_date: "",
      day_of_month: "",
      start_date: "",
      isNew: true,
    };

    setRecurringPayments((prevPayments) => [
      ...prevPayments,
      newRecurringPayment,
    ]);
  };

  const createRecurringPayment = async (newPayment) => {
    try {
      const response = await axios.post(
        "/cashflow/settings/create_recurring_payment",
        { recurring_payment: newPayment }
      );
      if (response.status === 200) {
        flashSuccess("Recurring payment created successfully!");
        fetchRecurringPayments();
      }
    } catch (error) {
      console.error("Error creating recurring payment:", error);
    }
  };

  const updateRecurringPayment = async (paymentId, updatedPayment) => {
    try {
      const response = await axios.post(
        `/cashflow/settings/update_recurring_payment/${paymentId}`,
        { recurring_payment: updatedPayment }
      );
      if (response.status === 200) {
        flashSuccess("Recurring payment updated successfully!");
        fetchRecurringPayments();
      }
    } catch (error) {
      console.error("Error updating recurring payment:", error);
    }
  };

  const deleteRecurringPayment = async (paymentId) => {
    try {
      const response = await axios.delete(
        `/cashflow/settings/delete_recurring_payment/${paymentId}`
      );
      if (response.status === 200) {
        flashSuccess("Payment deleted successfully!");
        fetchRecurringPayments();
      }
    } catch (error) {
      console.error("Error deleting recurring payment:", error);
    }
  };

  return (
    <>
      <div className="card p-3 mt-3 mb-3 z-depth-2">
        <h3 className="panel-title mb-2">Manage regular cash outflows</h3>
        <p className="panel-context">
          Regular cash outflows like rent, payroll, etc. are payments that occur
          on a consistent schedule.
          <i
            ref={targetRecurring}
            onClick={toggleTooltipRecurring}
            className="bi bi-info-circle"
            style={{ cursor: "pointer", marginLeft: "4px" }}
          />
          <Overlay
            show={tooltipVisibleRecurring}
            target={targetRecurring.current}
            placement="right"
            rootClose={true}
            onHide={toggleTooltipRecurring}
          >
            <Tooltip id="tooltip-right" className="cashflow-tooltip">
              Regularly updating and adding these payments enhances the accuracy
              and reliability of your cash flow forecasts. If a payment varies
              in amount but occurs at consistent intervals, feel free to leave
              the amount field blank. Our system will analyze your past data and
              predict the amount for you.
            </Tooltip>
          </Overlay>
        </p>
        <table className="settings-table">
          <thead>
            <tr style={{ backgroundColor: "#F8FCFF" }}>
              <th>Expense Category</th>
              <th>Payment Description</th>
              <th>Payment Amount</th>
              <th>Payment Frequency</th>
              <th>Payment Start Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {recurringPayments.map((payment, index) => (
              <RecurringPaymentRow
                key={payment.id || `tempKey_${index}`}
                payment={payment}
                updateRecurringPayment={(paymentId, updatedPayment) =>
                  updateRecurringPayment(paymentId, updatedPayment)
                } // Pass the ID as the first argument
                deleteRecurringPayment={(paymentId) =>
                  deleteRecurringPayment(paymentId)
                }
                createRecurringPayment={createRecurringPayment}
              />
            ))}
            <tr>
              <td colSpan="6" style={{ textAlign: "right" }}>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={addRecurringPayment}
                >
                  + Add recurring payment
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="card p-3 mt-1 mb-3 z-depth-2">
        <h3 className="panel-title mb-2">Monitor cash flow</h3>
        <p className="panel-context">
          The cash balance alert threshold is the minimum balance you aim to
          maintain in your account.
          <i
            ref={targetThreshold}
            onClick={toggleTooltipThreshold}
            className="bi bi-info-circle"
            style={{ cursor: "pointer", marginLeft: "4px" }}
          />
          <Overlay
            show={tooltipVisibleThreshold}
            target={targetThreshold.current}
            placement="right"
            rootClose={true}
            onHide={toggleTooltipThreshold}
          >
            <Tooltip id="tooltip-right" className="cashflow-tooltip">
              By setting an alert threshold, our system will notify you in
              advance of any potential shortfall, helping you avoid dipping
              below your preferred cash balance.
            </Tooltip>
          </Overlay>
        </p>
        <div className="col-md-4 mb-4">
          <UILabelInput
            label="Set Your Cash Balance Alert Threshold"
            value={displayThreshold}
            onChange={handleThresholdChange}
            prepend="$"
          />
        </div>
        <div style={{ textAlign: "right" }}>
          <button
            className={`btn btn-primary btn-sm ${
              isValueChanged ? "" : "disabled"
            }`}
            onClick={handleSave}
            style={{ width: "190px", height: "38px" }}
          >
            Save
          </button>
        </div>
      </div>

      <div className="card p-3 mt-1 mb-3 z-depth-2">
        <h3 className="panel-title mb-2">Customize forecast</h3>
        <p className="panel-context">
          Customize your cash flow projection by choosing which accounts to
          include in the forecast.
          <i
            ref={targetAccounts}
            onClick={toggleTooltipAccounts}
            className="bi bi-info-circle"
            style={{ cursor: "pointer", marginLeft: "4px" }}
          />
          <Overlay
            show={tooltipVisibleAccounts}
            target={targetAccounts.current}
            placement="right"
            rootClose={true}
            onHide={toggleTooltipAccounts}
          >
            <Tooltip id="tooltip-right">
              Your cash flow forecasts are generated from a historical analysis
              of your profit and loss statements, on a per-account basis.
              Including or excluding specific accounts allows you to refine your
              projections according to your business needs.
            </Tooltip>
          </Overlay>
        </p>
        <h4 className="panel-title mb-2">Expense Accounts</h4>
        <table className="settings-table">
          <thead>
            <tr>
              <th scope="col" style={{ width: "5%" }}>
                #
              </th>
              <th scope="col" style={{ width: "85%" }}>
                Account Name
              </th>
              <th scope="col" style={{ width: "10%" }}>
                Include in Forecast
              </th>
            </tr>
          </thead>
          <tbody>
            {accounts
              .filter(
                (account) =>
                  account.accountType === "Expense" &&
                  account.status === "Active"
              )
              .map((account) => (
                <tr key={account.accountId}>
                  <th scope="row">{account.externalId}</th>
                  <td>
                    {account.accountName}
                    {account.avgMonthlySpend !== null && (
                      <span className="context">
                        ({prettyDisplayPrice(account.avgMonthlySpend) || "N/A"}{" "}
                        monthly average)
                      </span>
                    )}
                  </td>

                  <td>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={account.projectionAlgo !== null}
                        onChange={() => handleSwitchChange(account)}
                        // disabled
                      />
                      <span className="slider round"></span>
                    </label>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <h4 className="panel-title mb-2">Income Accounts</h4>
        <table className="settings-table">
          <thead>
            <tr>
              <th scope="col" style={{ width: "5%" }}>
                #
              </th>
              <th scope="col" style={{ width: "85%" }}>
                Name
              </th>
              <th scope="col" style={{ width: "10%" }}>
                Include in Forecast
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.values(accounts)
              .filter(
                (account) =>
                  account.accountType === "Income" &&
                  account.status === "Active"
              )
              .map((account, index) => (
                <tr key={account.accountId}>
                  <th scope="row">{account.externalId}</th>
                  <td>
                    {account.accountName}
                    {account.avgMonthlyIncome !== null && (
                      <span className="context">
                        ({prettyDisplayPrice(account.avgMonthlyIncome) || "N/A"}{" "}
                        monthly average)
                      </span>
                    )}
                  </td>
                  <td>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={account.projectionAlgo !== null}
                        onChange={() => handleSwitchChange(account)}
                        // disabled
                      />
                      <span className="slider round"></span>
                    </label>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CashFlowSettings;
