import React, { useCallback, useEffect, useRef, useState } from "react";

import { useFormikContext } from 'formik';

const DEFAULT_DEBOUNCE_MS = 3000;


const UIAutoSave = (props) => {
  // https://stackoverflow.com/questions/58979309/checking-if-a-component-is-unmounted-using-react-hooks/64379060#64379060
  const isMountedRef = useRef(true);
  const isMounted = useCallback(() => isMountedRef.current, []);

  useEffect(() => {
    return () => { isMountedRef.current = false; };
  }, []);

  const [saveStatus, setSaveStatus] = useState("");
  function safeSetSaveStatus(value) {
    if (!isMounted()) {
      return;
    }

    setSaveStatus(value);
  }

  const [debounceMs, setDebounceMs] = useState(props.debounceMs || DEFAULT_DEBOUNCE_MS);
  const [updateMs, setUpdateMs] = useState(debounceMs / 3);

  const callback = props.callback;

  const debouncedSubmit = useCallback(
    _.debounce((values) => {
      safeSetSaveStatus("Saving...");

      callback(
        props.buildCallbackParams(values),
        (success) => {
          setTimeout(() => {
            safeSetSaveStatus(success ? "Saved" : "Error Saving");
          }, updateMs);

          setTimeout(() => {
            safeSetSaveStatus("");
          }, debounceMs);
        }
      );
    }, debounceMs),
    [debounceMs]
  );

  const { values } = useFormikContext();
  useEffect(() => {
    debouncedSubmit(values);
  }, [debouncedSubmit, values]);

  return (
    <div className="float-end" key={`status-${saveStatus}`}>
      <b>{saveStatus}</b>
    </div>
  );
};

export default UIAutoSave;
