import React, { Component, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useArray } from "react-hanger";

import InvoiceWeekRow from "./components/invoice-week-row";
import InvoiceItem from "./components/invoice-item";
import InvoiceListRollup from "./invoice-list-rollup";
import { UITableSortable } from "components/ui";

import {
  updatePayableInvoice,
  updateReceivableInvoice,
} from "modules/company_invoices";

import { fetchAggregatedInvoices } from "modules/cashflow";

import { flashSuccess, flashError } from "lib/flash";
import { weeklyDisplayMap, mergeRowsByWeek } from "lib/utils";

const Invoices = (props) => {
  const [showModal, setShowModal] = useState(false);
  const editableInvoices = useArray([]);
  const [invoices, setInvoices] = useState([]);
  const [invoice, setInvoice] = useState({});
  const [sortColumn, setSortColumn] = useState("due_date");
  const [sortDirection, setSortDirection] = useState("ASC");
  const [displayMap, setDisplayMap] = useState({});
  const [displayAllWeeks, setDisplayAllWeeks] = useState(false);

  function onSortChange(direction, column) {
    setSortColumn(column);
    setSortDirection(direction);
  }

  function updateInvoiceTags(invoice, tags) {
    if (invoice.cash_balance_status == "money out") {
      updatePayableInvoice(invoice.id, {
        company_payable_invoice: { tags },
      })
        .then((response) => {
          flashSuccess("Tags updated successfully for invoice");
        })
        .catch((errors) => {
          flashError(errors);
        });
    } else {
      updateReceivableInvoice(invoice.id, {
        company_receivable_invoice: { tags },
      })
        .then((response) => {
          flashSuccess("Tags updated successfully for invoice");
        })
        .catch((errors) => {
          flashError(errors);
        });
    }
  }

  function fetchInvoices(params, updateDashboard, updateCashProjections) {
    return fetchAggregatedInvoices(params)
      .then((response) => {
        if (updateDashboard) {
          props.updateDashboard(response.total_amounts_by_status);
        }

        if (updateCashProjections) {
          props.updateCashProjections();
        }

        const mergedItems = mergeRowsByWeek(
          response.total_amounts_by_week,
          response.invoices,
          "due_date"
        );

        setInvoices(mergedItems);

        return Promise.resolve({
          success: response.success,
          total: response.total,
          message: response.message,
          items: mergedItems,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  function renderInvoiceRow(invoice, index) {
    return (
      <InvoiceItem
        key={`invoice-item-${index}`}
        index={index}
        row={invoice}
        updateInvoiceTags={updateInvoiceTags}
        setInvoice={setInvoice}
        setShowModal={setShowModal}
        editableInvoices={editableInvoices}
        offers={{}}
      />
    );
  }

  function renderItem(row, index) {
    let rows = [];
    const week = row[0];
    const total = row[1];
    const invoices = row[2];

    const hasNotes = invoices.some((invoice) => invoice.risk_notes != null);

    const projectedMoneyInForWeek = invoices
      .filter((invoice) => invoice.cash_balance_status === "money in")
      .reduce((total, invoice) => total + Number(invoice.amount_due), 0);

    const projectedMoneyOutForWeek = invoices
      .filter((invoice) => invoice.cash_balance_status === "money out")
      .reduce((total, invoice) => total + Number(invoice.amount_due), 0);

    const actualBalancesInForWeek = invoices
      .filter(
        (invoice) =>
          invoice.cash_balance_status === "money in" &&
          invoice.status !== "Projected"
      )
      .reduce((total, invoice) => total + Number(invoice.amount_due), 0);

    const actualBalancesOutForWeek = invoices
      .filter(
        (invoice) =>
          invoice.cash_balance_status === "money out" &&
          invoice.status !== "Projected"
      )
      .reduce((total, invoice) => total + Number(invoice.amount_due), 0);

    if (!displayAllWeeks && index >= 4) {
      // Only calculate the values for the first 4 weeks
      return rows;
    }

    rows.push(
      <InvoiceWeekRow
        key={`invoice-bill-week-${week}`}
        index={index}
        week={week}
        total={total}
        displayMap={displayMap}
        setDisplayMap={setDisplayMap}
        hasNotes={hasNotes}
        projectedMoneyInForWeek={projectedMoneyInForWeek}
        projectedMoneyOutForWeek={projectedMoneyOutForWeek}
        actualBalancesInForWeek={actualBalancesInForWeek}
        actualBalancesOutForWeek={actualBalancesOutForWeek}
      />
    );

    if (displayMap[week]) {
      for (let i = 0; i < invoices.length; i++) {
        const invoice = invoices[i];
        rows.push(renderInvoiceRow(invoice, i));
      }
    }

    return rows;
  }

  return (
    <InvoiceListRollup
      per_page={500}
      title="Upcoming cash flows"
      fetchItems={fetchInvoices}
      codat_connected={props.codat_connected}
      codat_data_synced={props.codat_data_synced}
      first_accounts_processing_done={props.first_accounts_processing_done}
      maxDate={props.maxDate}
      minDate={props.minDate}
      codat_last_synced={props.codat_last_synced}
      codat_provider={props.codat_provider}
      renderItem={renderItem}
      invoices={invoices}
      editableInvoices={editableInvoices}
      showModal={showModal}
      setShowModal={setShowModal}
      invoice={invoice}
      displayAllWeeks={displayAllWeeks}
      setDisplayAllWeeks={setDisplayAllWeeks}
      actionButton={
        <button
          className="btn btn-outline-secondary"
          onClick={() => {
            setShowModal(true);
          }}
        >
          Add New
        </button>
      }
      dropdownMenu={
        <>
          <Dropdown.Item href="/download/payable_invoices.csv">
            Download Payables as CSV
          </Dropdown.Item>
          <Dropdown.Item href="/download/receivable_invoices.csv">
            Download Receivables as CSV
          </Dropdown.Item>
          <Dropdown.Item href="/company/edit#/cashflow/settings">
            Add Recurring Payment
          </Dropdown.Item>
          <Dropdown.Item href="/company/edit#/cashflow/settings">
            Manage Projection Categories
          </Dropdown.Item>
        </>
      }
      sortColumn={sortColumn}
      onSortChange={onSortChange}
      sortDirection={sortDirection}
    />
  );
};

export default Invoices;
