import React from "react";
import DocumentTile from "./documents/document-tile";

const Documents = (props) => {
  const tiles = props.fileCategories.map((category) => {
    return (
      <div className="col-md-3 mt-3">
        <DocumentTile
          {...category}
          company={props.company}
          key={`file-category-tile-${category.id}`}
        />
      </div>
    );
  });

  return (
    <div className="panel p-4">
      <h3>Documents</h3>
      <p>
        Upload any required or optional documents for the application. You can
        view them anytime in this dashboard if you need to access the documents
        at a later date.
      </p>
      <div className="documents p-4">
        <div className="row align-items-end">{tiles}</div>
      </div>
    </div>
  );
};

export default Documents;
