import React from "react";
import Form from "react-bootstrap/Form";
import moment from "moment";

import UIFormLabel from "./ui-form-label";

const DATE_FORMAT = "YYYY-MM-DD";

const UIDateInput = (props) => {
  const date = props.date ? moment(props.date).format(DATE_FORMAT) : "";

  function handleChange(e) {
    const value = moment(e.target.value).format(DATE_FORMAT);
    props.handleChange(value);
  }

  return (
    <div className="ui-label-input">
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.label && (
          <UIFormLabel
            for={props.id}
            label={props.label}
            required={props.required}
            strong={props.strong}
          />
        )}
      </div>
      <Form.Control
        type="date"
        id="date"
        required={props.required}
        value={date}
        onChange={handleChange}
      />
    </div>
  );
};

export default UIDateInput;
