import React, { useState, useEffect } from "react";
import {
  UILabelInput,
  UIAddressInputWithSuggestions,
  UIFormLabel,
} from "components/ui";
import { State } from "country-state-city";
import { flashSuccess, flashError, flashWarning } from "lib/flash";
import { Typeahead } from "react-bootstrap-typeahead";

import {
  getCompanyProjectQuote,
  updateCompanyProjectQuote,
  createCompanyProjectQuote,
  createProjectQuoteCategories,
  getProjectQuoteCategories,
  deleteProjectQuoteCategory,
  getCompanyProjectQuoteDeliveryMethods,
  getCompanyProjectQuotePaymentTerms,
} from "modules/project";

const AddressForm = ({ address, setAddress }) => {
  const handleFieldChange = (field, value) => {
    setAddress({ ...address, [field]: value });
  };

  const handleSuggestedAddress = (parsedAddress) => {
    setAddress({
      ...address,
      address_line_1: parsedAddress.address_line_1,
      address_line_2: parsedAddress.address_line_2,
      city: parsedAddress.city,
      state: parsedAddress.state,
      postal_code: parsedAddress.postal_code,
    });
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12">
          <UILabelInput
            label="Description"
            value={address.description || ""}
            required={false}
            onChange={(value) => handleFieldChange("description", value)}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <UIFormLabel label="Address Line 1" />
          <UIAddressInputWithSuggestions
            value={address.address_line_1}
            required={false}
            onChange={(value) => handleFieldChange("address_line_1", value)}
            onSelectSuggestedAddress={handleSuggestedAddress}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <UILabelInput
            label="Address Line 2"
            value={address.address_line_2}
            required={false}
            onChange={(value) => handleFieldChange("address_line_2", value)}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <UILabelInput
            label="City"
            value={address.city}
            required={false}
            onChange={(value) => handleFieldChange("city", value)}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-8">
          <div className="ui-label-input">
            <UIFormLabel label="State" />
            <div className="input-group">
              <select
                name="state_select"
                id="state_select"
                className="form-control"
                value={address.state}
                onChange={(event) =>
                  handleFieldChange("state", event.target.value)
                }
              >
                <option value="">Select a state</option>
                {State.getStatesOfCountry("US").map((state) => (
                  <option key={state.name} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <UILabelInput
            label="Postal Code"
            value={address.postal_code}
            required={false}
            onChange={(value) => handleFieldChange("postal_code", value)}
          />
        </div>
      </div>
    </>
  );
};

const ProjectQuoteMetadata = (props) => {
  const [companyProjectQuote, setCompanyProjectQuote] = useState(null);
  const subtotal = props.quoteCategories.reduce(
    (total, category) => total + category.markupTotal,
    0
  );
  const [form, setForm] = useState({
    project_description: "",
    requested_by: {
      name: "",
      email: "",
      phone: "",
    },
    salesperson: "",
    payment_terms: "",
    delivery_method: "",
  });

  const blankAddress = {
    id: null,
    description: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    postal_code: "",
    is_primary: false,
  };

  const [quoteCategories, setQuoteCategories] = useState([]);
  const [billToAddress, setBillToAddress] = useState(blankAddress);
  const [installInfoAddress, setInstallInfoAddress] = useState(blankAddress);
  const [editDeliveryMethod, setEditDeliveryMethod] = useState(false);
  const [deliveryMethodOptions, setDeliveryMethodOptions] = useState([]);
  const [paymentTermsOptions, setPaymentTermsOptions] = useState([]);
  const [sameAsBillToAddress, setSameAsBillToAddress] = useState(false);

  useEffect(() => {
    getCompanyProjectQuote(props.company_project_id).then((data) => {
      if (data) {
        setCompanyProjectQuote(data);
        setForm((prevForm) => ({ ...prevForm, ...data }));
        setBillToAddress(data.bill_to_address || blankAddress);
        setInstallInfoAddress(data.install_info_address || blankAddress);
      }
    });

    if (props.quoteCategories) {
      setQuoteCategories(props.quoteCategories);
    }
  }, []);

  useEffect(() => {
    getCompanyProjectQuoteDeliveryMethods().then((data) => {
      setDeliveryMethodOptions(data.deliveryMethods);
    });

    getCompanyProjectQuotePaymentTerms().then((data) => {
      setPaymentTermsOptions(data.paymentTerms);
    });
  }, []);

  const Header = ({ text }) => (
    <h5
      style={{
        background: "linear-gradient(90deg, #e2f5ff 0%, #cfe8ff 100%)",
        padding: "12px 16px",
        color: "#1D3360",
        borderRadius: "6px",
        fontSize: "18px",
        fontWeight: "500",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        transition: "background 0.3s ease-in-out",
      }}
    >
      {text}
    </h5>
  );

  const handleChange = (field, subField, currentObj, e) => {
    const value = e.target ? e.target.value : e;

    if (field === "bill_to_address" || field === "install_info_address") {
      const setAddress =
        field === "bill_to_address" ? setBillToAddress : setInstallInfoAddress;

      setAddress((prevAddress) => ({ ...prevAddress, [subField]: value }));

      // Update the corresponding address in the form state
      const addressField =
        field === "bill_to_address"
          ? "bill_to_address"
          : "install_info_address";

      setForm((prevForm) => ({
        ...prevForm,
        [addressField]: { ...currentObj, [subField]: value },
      }));
    } else if (subField) {
      setForm((prevForm) => {
        const updatedForm = {
          ...prevForm,
          [field]: {
            ...currentObj,
            [subField]: value,
          },
        };
        return updatedForm;
      });
    } else {
      setForm((prevForm) => {
        const updatedForm = {
          ...prevForm,
          [field]: value,
        };
        return updatedForm;
      });
    }
  };

  const handleSameAsBillToAddressChange = (e) => {
    const isChecked = e.target.checked;
    setSameAsBillToAddress(isChecked);
    if (isChecked) {
      setInstallInfoAddress(billToAddress);
    } else {
      setInstallInfoAddress(blankAddress);
    }
  };

  const isParentCollapsed = (category) => {
    // If there's no parent and the category itself is collapsed, consider it as collapsed
    if (!category.parent_id && category.isCollapsed) {
      return true;
    }

    const parentCategory = quoteCategories.find(
      (cat) => cat.id === category.parent_id
    );

    // If there's no parent and the category itself is not collapsed, consider it as not collapsed
    if (!parentCategory) {
      return false;
    }

    if (parentCategory.isCollapsed) {
      return true; // If parent is collapsed
    }

    return isParentCollapsed(parentCategory);
  };

  const prepareData = () => {
    return {
      ...form,
      bill_to_address: { ...billToAddress },
      install_info_address: { ...installInfoAddress },
    };
  };

  const getAction = () => {
    return companyProjectQuote
      ? updateCompanyProjectQuote
      : createCompanyProjectQuote;
  };

  const deleteAllCategories = (categories) => {
    const deletionPromises = categories.map((category) =>
      deleteProjectQuoteCategory(props.company_project_id, category.id)
    );
    return Promise.all(deletionPromises);
  };

  const createQuoteCategories = () => {
    console.log("Initial quoteCategories:", quoteCategories); // Log the initial state
    quoteCategories.forEach((category) => {
      const parentCollapsed = isParentCollapsed(category);
      console.log("Parent collapsed:", parentCollapsed); // Log the result of isParentCollapsed
      console.log("Category features:", category.features);

      if (category.parent_id === null || category.features.quote) {
        console.log("Creating quote category for:", category);
        createProjectQuoteCategories(props.company_project_id, {
          project_estimate_category_id: category.id,
          name: category.category_name,
          description: category.description,
          parent_id: category.parent_id,
          quantity: category.quantity,
          unit_price: category.markupTotal,
          total_amount:
            parseFloat(category.quantity || 1) * category.markupTotal,
          active: true,
        })
          .then((response) => {
            // handle success
            //flashSuccess("Project quote created successfully");
          })
          .catch((error) => {
            // handle error
            flashError(
              "An error occurred while creating project quote:",
              error
            );
          });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const subtotal = props.quoteCategories.reduce(
      (total, category) =>
        total + parseFloat(category.quantity || 1) * category.markupTotal,
      0
    );

    const companyProjectQuoteData = {
      ...prepareData(),
      subtotal: subtotal,
    };

    const action = getAction();

    getProjectQuoteCategories(props.company_project_id)
      .then(deleteAllCategories)
      .then(() => {
        return action(props.company_project_id, companyProjectQuoteData);
      })
      .then((data) => {
        setCompanyProjectQuote(data);

        createQuoteCategories();

        if (props.onSuccess) {
          props.onSuccess(data);
        }
        props.fetchEstimates().then(() => {
          // TODO: Should not need a hard refresh
          window.location.href = `/projects/${props.company_project_id}#/estimate`;
          window.location.reload();
        });
      })
      .catch((error) => {
        if (props.onError) {
          props.onError(error);
        }
      });
  };

  const getDeliveryMethod = () => {
    if (!form.delivery_method || editDeliveryMethod) {
      return (
        <div
          style={{
            background: "linear-gradient(90deg, #e2f5ff 0%, #cfe8ff 100%)",
            padding: "4px 16px",
            color: "#1D3360",
            borderRadius: "4px",
            fontSize: "18px",
            fontWeight: "500",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            transition: "background 0.3s ease-in-out",
            display: "flex",
          }}
        >
          <Typeahead
            id="delivery-method-typeahead"
            options={deliveryMethodOptions.map((dm) => ({
              deliveryMethod: dm,
            }))}
            selected={form.delivery_method ? [form.delivery_method] : []}
            onChange={(selected) => {
              if (selected.length) {
                setForm((prevForm) => ({
                  ...prevForm,
                  delivery_method: selected[0].deliveryMethod,
                }));
                setEditDeliveryMethod(false);
              }
            }}
            onInputChange={(text) => {
              setForm((prevForm) => ({ ...prevForm, delivery_method: text }));
              if (!editDeliveryMethod) {
                setEditDeliveryMethod(true);
              }
            }}
            placeholder="Select delivery method"
            allowNew={(results) => true}
            labelKey="deliveryMethod"
          />
          <button
            className="btn btn-sm btn-muted float-end"
            style={{ position: "absolute", right: "25px" }}
            onClick={(e) => {
              e.preventDefault();
              setEditDeliveryMethod(!editDeliveryMethod);
            }}
          >
            <i className="bi bi-check"></i>
          </button>
        </div>
      );
    } else {
      return (
        <Header
          text={
            <>
              {form.delivery_method || "Installation"}
              <button
                className="btn btn-sm btn-muted float-end"
                onClick={(e) => {
                  e.preventDefault();
                  setEditDeliveryMethod(!editDeliveryMethod);
                }}
              >
                <i className="bi bi-pencil-square pencil-button-stable"></i>
              </button>
            </>
          }
        />
      );
    }
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-6">
            <Header text="Job" />
          </div>
          <div className="col-md-6">
            <Header text="Requested by" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <UILabelInput
              value={form.project_description ? form.project_description : ""}
              label="Description"
              onChange={(e) =>
                handleChange("project_description", null, form, e)
              }
              required={true}
            />
          </div>
          <div className="col-md-6">
            <UILabelInput
              value={form.requested_by ? form.requested_by.name : ""}
              label="Requested By"
              onChange={(e) =>
                handleChange("requested_by", "name", form.requested_by, e)
              }
              required={false}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <UILabelInput
              value={form.salesperson}
              label="Salesperson"
              onChange={(e) => handleChange("salesperson", null, form, e)}
              required={false}
            />
          </div>
          <div className="col-md-6">
            <UILabelInput
              value={form.requested_by ? form.requested_by.email : ""}
              label="Requested By Email"
              onChange={(e) =>
                handleChange("requested_by", "email", form.requested_by, e)
              }
              required={false}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label mb-1">
              <strong>Payment Terms</strong>
            </label>
            <Typeahead
              id="payment-terms-typeahead"
              options={paymentTermsOptions.map((pt) => ({ paymentTerms: pt }))}
              selected={form.payment_terms ? [form.payment_terms] : []}
              onChange={(selected) => {
                if (selected.length) {
                  setForm((prevForm) => ({
                    ...prevForm,
                    payment_terms: selected[0].paymentTerms,
                  }));
                }
              }}
              onInputChange={(text) => {
                setForm((prevForm) => ({ ...prevForm, payment_terms: text }));
              }}
              placeholder="Select payment terms"
              allowNew={(results) => true}
              labelKey="paymentTerms"
            />
          </div>
          <div className="col-md-6">
            <UILabelInput
              value={form.requested_by ? form.requested_by.phone : ""}
              label="Requested By Phone"
              onChange={(e) =>
                handleChange("requested_by", "phone", form.requested_by, e)
              }
              required={false}
            />
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-md-6">
            <Header text="Bill To" />
            <div className="form-check mb-2" style={{ visibility: "hidden" }}>
              {/* Dummy checkbox to keep alignment */}
            </div>
            <AddressForm
              address={billToAddress}
              setAddress={setBillToAddress}
            />
          </div>
          <div className="col-md-6">
            {getDeliveryMethod()}

            <div className="form-check mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                checked={sameAsBillToAddress}
                onChange={handleSameAsBillToAddressChange}
              />
              <label className="form-check-label" htmlFor="sameAsBillToAddress">
                Same as bill to
              </label>
            </div>
            <AddressForm
              address={installInfoAddress}
              setAddress={setInstallInfoAddress}
            />
          </div>
        </div>

        <hr />
        <button type="submit" className="btn btn-action float-end">
          {companyProjectQuote ? "Update Quote" : "Create Quote"}
        </button>
      </form>
    </>
  );
};

export default ProjectQuoteMetadata;
