import React, { useState } from "react";
import { storePassword } from "modules/onboarding";

const PasswordMatchListItem = ({ title, valid }) => {
  return (
    <li>
      {valid ? (
        <i className="me-2 bi bi-check-circle-fill text-success"></i>
      ) : (
        <span
          className="me-2 d-inline-block"
          style={{ width: "16px", height: "18.5px" }}
        />
      )}
      <span>{title}</span>
    </li>
  );
};

const Password = (props) => {
  const uppercaseRegexp = new RegExp(/[A-Z]/);
  const lowercaseRegexp = new RegExp(/[a-z]/);
  const numericRegexp = new RegExp(/\d+/);
  const specialRegexp = new RegExp(/[\(\!\$\#\?\%\&\)]/);

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    if (!validPassword()) return;

    storePassword({
      user: {
        password: password,
        password_confirmation: passwordConfirmation,
      },
    }).then((response) => {
      window.location = props.nextRoute;
    });
  }

  function validateLength() {
    return password.length >= 8;
  }

  function validateContainsUppercase() {
    return uppercaseRegexp.test(password);
  }

  function validateContainsLowercase() {
    return lowercaseRegexp.test(password);
  }

  function validateContainsNumeric() {
    return numericRegexp.test(password);
  }

  function validateContainsSpecialCharacter() {
    return specialRegexp.test(password);
  }

  function validateMatchingPasswordAndConfirmation() {
    return password === passwordConfirmation && password !== "";
  }

  function validPassword() {
    return (
      validateLength() &&
      validateContainsUppercase() &&
      validateContainsLowercase() &&
      validateContainsNumeric() &&
      validateContainsSpecialCharacter() &&
      validateMatchingPasswordAndConfirmation()
    );
  }

  return (
    <div className="onboarding-form-group">
      <form className="form" onSubmit={handleSubmit}>
        <div className="card bg-light my-3">
          <div className="card-body">
            <strong>Password must contain:</strong>
            <ul className="list-unstyled m-0">
              <PasswordMatchListItem
                title="8 or more characters"
                valid={validateLength()}
              />
              <PasswordMatchListItem
                title="an uppercase letter"
                valid={validateContainsUppercase()}
              />
              <PasswordMatchListItem
                title="a lowercase letter"
                valid={validateContainsLowercase()}
              />
              <PasswordMatchListItem
                title="a number"
                valid={validateContainsNumeric()}
              />
              <PasswordMatchListItem
                title="a special character (! $ # ? % &)"
                valid={validateContainsSpecialCharacter()}
              />
              <PasswordMatchListItem
                title="matches confirmation"
                valid={validateMatchingPasswordAndConfirmation()}
              />
            </ul>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <div className="form-group my-form-group">
              <label>
                <strong>Password</strong>
                <span className="text-danger fs-4 fw-bolder">*</span>
              </label>
              <input
                type="password"
                className="form-control"
                id="user_password"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  e.preventDefault();
                  setPassword(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="col">
            <div className="form-group my-form-group">
              <label>
                <strong>Password Confirmation</strong>
                <span className="text-danger fs-4 fw-bolder">*</span>
              </label>
              <input
                type="password"
                className="form-control"
                id="user_password"
                placeholder="Password Confirmation"
                value={passwordConfirmation}
                onChange={(e) => {
                  e.preventDefault();
                  setPasswordConfirmation(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="form-group mt-2 d-flex justify-content-between">
          {props.previousRoute && (
            <a
              href={props.previousRoute}
              className="btn btn-light btn-large my-3 mb-4 px-4 me-2"
            >
              <i
                className="bi bi-chevron-left icon-back-chevron"
                style={{ marginRight: "4px" }}
              ></i>
              Back
            </a>
          )}

          <div>
            {props.skipRoute && (
              <a
                href={props.nextRoute}
                className="btn btn-light btn-large my-3 mb-4 px-4 me-2"
              >
                Skip
              </a>
            )}

            <button
              className="btn btn-large btn-action my-3 mb-4 px-4 next-button"
              type="submit"
              disabled={!validPassword()}
            >
              Next
              <i
                className="bi bi-chevron-right icon-next-chevron"
                style={{ marginLeft: "4px" }}
              ></i>
              <i
                className="bi bi-arrow-right icon-next-arrow"
                style={{ marginLeft: "4px" }}
              ></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Password;
