import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const UIFormLabel = (props) => {
  const { label, required, tooltip } = props;

  return (
    <label className="form-label mb-1" htmlFor={props.for}>
      <strong>{label}</strong>
      {required && <span className="panel-form-required">*</span>}
      {tooltip && (
        <OverlayTrigger
          placement="top"
          delay={{ show: 150, hide: 200 }}
          overlay={
            <Tooltip id={`tooltip-${label}`} className="detail-tooltip">
              {tooltip}
            </Tooltip>
          }
        >
          <i
            className="bi bi-question-circle ml-1"
            style={{ paddingLeft: "5px" }}
          ></i>
        </OverlayTrigger>
      )}
    </label>
  );
};

export default UIFormLabel;
