import axios from "lib/axios";
import { encodeParams } from 'lib/utils';

export const answerQualification = (params = {}) => {
  const url = `/incentives/${params.incentive_id}/company_answers/answer`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const fetchAdminQualifications = (params = {}) => {
  const url = `/admin/qualifications`;
  params = encodeParams(params);

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const createAdminQualification = (params = {}) => {
  const url = `/admin/qualifications/`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateAdminQualification = (params = {}) => {
  const url = `/admin/qualifications/${params.id}/`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
