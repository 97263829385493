import React, { useState, useEffect } from "react";
import Form from "./form";
import StyleGuideLink from "../incentives/common/style-guide-link";

import { flashSuccess, flashError } from "lib/flash";
import { updateFunnel, formatFunnelIncentive } from "modules/funnels";

const Edit = (props) => {
  const [onboarding, setOnboarding] = useState([]);
  const [funnel, setFunnel] = useState({
    ...props.funnel,
    funnel_incentives_attributes: formatFunnelIncentives(props.funnel),
  });

  function formatFunnelIncentives(funnel) {
    if (!funnel && !funnel.incentives) return [];
    return funnel.incentives.map((incentive) =>
      formatFunnelIncentive(incentive)
    );
  }

  function setFunnelField(field, value) {
    setFunnel({
      ...funnel,
      [field]: value,
    });
  }

  useEffect(() => {
    setOnboarding(
      props.onboarding.map((flow) => {
        return {
          value: flow.id,
          name: flow.name,
        };
      })
    );
  }, []);

  function handleUpdateFunnel(e) {
    e.preventDefault();
    updateFunnel({ id: funnel.id, funnel })
      .then((response) => {
        flashSuccess("Funnel updated");
      })
      .catch((error) => {
        flashError(error);
        console.error(error);
      });
  }

  return (
    <div className="panel-body">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Edit Funnel</h3>
        <StyleGuideLink CTA="How to" guideType="microSite" />
      </div>

      <div className="row">
        <div className="col-lg-12">
          <Form
            funnel={funnel}
            updateFunnel={setFunnelField}
            setFunnel={setFunnel}
            handleSubmit={handleUpdateFunnel}
            layouts={props.layouts}
            onboarding={onboarding}
            incentives={props.incentives}
            saveText="Update"
            isNew={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Edit;
