import React, { useState, useEffect } from "react";
import ProjectForm from "../../projects/components/project-form";
import {
  getApplicationProjectResources,
  deleteApplicationProjectResources,
} from "modules/applications";

import { softDeleteCompanyProject } from "modules/project";
import { flashError, flashSuccess } from "lib/flash";

import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";

const UIProjectAdder = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [projectResources, setProjectResources] = useState([]);

  const ProjectUploadAlternative = ({ applicationSlug }) => (
    <p>
      Or, if you prefer, you can{" "}
      <strong>
        <a href={`/applications/${applicationSlug}#/documents`}>
          upload your project related documents directly
        </a>{" "}
      </strong>
      and Subcity will parse them for you.{" "}
    </p>
  );

  useEffect(() => {
    if (props.application) {
      getApplicationProjectResources(props.application)
        .then((data) => {
          setProjectResources(data);
        })
        .catch((error) => {
          console.error("Error fetching application project resources:", error);
        });
    }
  }, [props.application]);

  const refreshProjectResources = () => {
    if (props.application) {
      getApplicationProjectResources(props.application)
        .then((data) => {
          setProjectResources(data);
        })
        .catch((error) => {
          console.error("Error fetching application project resources:", error);
        });
    }
  };

  const handleDeleteProjectResource = (projectId) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      softDeleteCompanyProject(projectId)
        .then(() => {
          deleteApplicationProjectResources(props.application, [projectId])
            .then(() => {
              refreshProjectResources();
              flashSuccess("Project and its resources successfully deleted.");
            })
            .catch((error) => {
              console.error(
                "Error deleting application project resources:",
                error
              );
              flashError(
                "Failed to delete the project resources. Please try again."
              );
            });
        })
        .catch((error) => {
          console.error("Error deleting company project:", error);
          flashError("Failed to delete the project. Please try again.");
        });
    }
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div>
      {projectResources.length === 0 && (
        <div
          className="col-md-12 mb-4"
          style={{
            borderRadius: "8px",
            border: "1px solid rgba(170, 170, 170, 0.5)",
            backgroundColor: "#F8FCFF",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            gap: "10px",
          }}
        >
          <h4 className="mt-4 mb-0">
            {props.header}
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className="detail-tooltip">{props.tooltip}</Tooltip>
              }
            >
              <i
                className="bi bi-info-circle"
                style={{ marginLeft: "10px" }}
              ></i>
            </OverlayTrigger>
          </h4>

          <span className="description">{props.description}</span>
          <div className="mb-2 mt-2" style={{ alignSelf: "flex-start" }}>
            <button className="btn-attach mb-4" onClick={handleModalOpen}>
              <span className="btn-action-plus">
                <i className="bi bi-plus"></i>
              </span>
              {props.cta}
            </button>
            <ProjectUploadAlternative applicationSlug={props.applicationSlug} />
          </div>
        </div>
      )}

      {projectResources.length > 0 && (
        <>
          <div className="col-md-12 mb-4">
            <h3 className="mb-4">Projects associated with this application</h3>
            <br />
            <p>
              Here's where you can add, edit, or delete any of your projects.
            </p>
          </div>
          <div className="col-md-12">
            <table className="table admin-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {projectResources.map((resource, index) => (
                  <tr key={index}>
                    <td>{resource.name}</td>
                    <td>{resource.description}</td>
                    <td>{resource.type}</td>
                    <td style={{ width: "3%" }}>
                      {/* <button className="btn btn-muted">Edit</button> */}
                    </td>
                    <td style={{ width: "5%" }}>
                      <button
                        type="button"
                        className="btn btn-danger float-end"
                        onClick={() => handleDeleteProjectResource(resource.id)}
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-md-12 text-right mb-4">
            <button className="btn-attach mb-4" onClick={handleModalOpen}>
              <span className="btn-action-plus">
                <i className="bi bi-plus"></i>
              </span>
              {props.cta}
            </button>
            <ProjectUploadAlternative applicationSlug={props.applicationSlug} />
          </div>
        </>
      )}

      <Modal size="lg" show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.cta}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectForm
            new
            saveText="Create"
            application={props.application}
            setShowModal={setShowModal}
            onProjectCreated={refreshProjectResources}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UIProjectAdder;
