import React, { useCallback, useEffect, useState } from "react";
import { Formik, Form } from "formik";

import {
  getIncentiveApplication,
  submitIncentiveApplication,
  submitIncentiveApplicationErrors,
} from "modules/applications";

import { flashError, flashSuccess } from "lib/flash";
import {
  buildInitialValuesForSchema,
  deleteUnansweredValues,
  setFormErrorsAsTouched,
} from "lib/utils";
const { buildYup } = require("schema-to-yup");
import ApplicationForm from "./apply/application-form";

const Apply = (props) => {
  const [isReadOnly, setIsReadOnly] = useState(props.isReadOnly);
  const intake_schema = props.incentive.intake_schema;
  const [updatedIntakeSchema, setUpdatedIntakeSchema] = useState();

  const replaceYearPlaceholders = (schema) => {
    const currentYear = new Date().getFullYear();
    let updatedSchemaString = JSON.stringify(schema);

    updatedSchemaString = updatedSchemaString.replace(
      /\$\{currentYear\}/g,
      currentYear
    );
    updatedSchemaString = updatedSchemaString.replace(
      /\$\{nextYear\}/g,
      currentYear + 1
    );
    updatedSchemaString = updatedSchemaString.replace(
      /\$\{priorYear\}/g,
      currentYear - 1
    );
    updatedSchemaString = updatedSchemaString.replace(
      /\$\{previousYear\}/g,
      currentYear - 1
    );

    // Loop from -5 to +5 (excluding 0 since we already handled currentYear)
    for (let offset = -5; offset <= 5; offset++) {
      if (offset === 0) continue; // Skip current year, already handled
      const year = currentYear + offset;
      const placeholder = new RegExp(
        `\\$\\{currentYear\\s*([+-])\\s*${Math.abs(offset)}\\}`,
        "g"
      );
      updatedSchemaString = updatedSchemaString.replace(placeholder, year);
    }

    return JSON.parse(updatedSchemaString);
  };

  const [yupSchema, setYupSchema] = useState();
  useEffect(() => {
    const updatedSchema = replaceYearPlaceholders(intake_schema);
    setYupSchema(buildYup(updatedSchema));
    setUpdatedIntakeSchema(updatedSchema);
  }, [intake_schema]);

  function handleFormikSubmit(values) {
    setIsReadOnly(true);

    submitIncentiveApplication(
      { id: props.application.id, details: values },
      props.incentive.slug
    )
      .then(() => {
        window.scrollTo(0, 0);
        flashSuccess("Success!");
      })
      .catch((error) => {
        setIsReadOnly(false);

        if (Array.isArray(error)) {
          error = error.join("\n\n");
        }
        flashError("Error:\n" + error); // TODO
      });
  }

  const [application, setApplication] = useState(props.application);
  const [applicationFetched, setApplicationFetched] = useState(false);
  useEffect(() => {
    getIncentiveApplication(props.incentive.slug, props.application.id).then(
      (response_data) => {
        setApplication({ ...response_data });
        setApplicationFetched(true);
      }
    );
  }, []);
  const initialValues = buildInitialValuesForSchema(
    intake_schema,
    application.details
  );

  if (applicationFetched) {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={yupSchema}
        onSubmit={handleFormikSubmit}
      >
        {({
          handleSubmit,
          setFieldTouched,
          validateForm,
          values,
          errors,
          touched,
        }) => {
          return (
            <ApplicationForm
              isReadOnly={isReadOnly || application.status != "draft"}
              handleSubmit={handleSubmit}
              setFieldTouched={setFieldTouched}
              validateForm={validateForm}
              values={values}
              application={application}
              incentive={props.incentive}
              errors={errors}
              touched={touched}
              intakeSchema={updatedIntakeSchema}
            />
          );
        }}
      </Formik>
    );
  } else {
    return <div></div>;
  }
};

export default Apply;
