import React, { useState, useEffect } from 'react';

import { flashSuccess, flashError } from "lib/flash";
import { addEstimateLineItemGroups } from 'modules/project'

function withCategories(WrappedComponent) {
  const NewComponent = (props) => {
    const [ categories, setCategories ] = useState([])
    const [ showCategoryPicker, setShowCategoryPicker ] = useState(false)
    const [ addedLineItems, setAddedLineItems ] = useState(false)

    function saveCategories(parent_id = null) {
      if(!categories || categories.length === 0) return;

      const params = {
        parent_id,
        categories: categories.map((c) => c.company_estimate_category_id )
      }

      addEstimateLineItemGroups(props.project.id, params)
        .then(() => {
          setShowCategoryPicker(false)
          flashSuccess("Estimate categories added to Project.")
          setAddedLineItems(true)
        })
        .catch((error) => {
          flashError(error)
        })
    }

    return (
      <WrappedComponent
        {...props}
        addedLineItems={addedLineItems}
        setAddedLineItems={setAddedLineItems}

        showCategoryPicker={showCategoryPicker}
        setShowCategoryPicker={setShowCategoryPicker}

        categories={categories}
        setCategories={setCategories}
        saveCategories={saveCategories}
      /> 
    )
  }

  return NewComponent
}

export default withCategories;