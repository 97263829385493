import React, { useState } from "react";
import { answerQualification } from "modules/qualifications";
import { flashSuccess, flashError } from "lib/flash";

const ShortTextQuestion = (props) => {
  const [answer, setAnswer] = useState(props.answer || "");

  function handleSubmit(e) {
    e.preventDefault();
    if (!answer || answer === "") return;

    const params = {
      incentive_id: props.incentive.slug,
      qualification_id: props.id,
      answer: answer,
    };

    answerQualification(params)
      .then(() => {
        flashSuccess("Succesfully updated answer");
        props.handleUpdateSuccess();
        window.location.reload(true);
        //TODO: Update banner properly using props
      })
      .catch((error) => {
        flashError(error[0]);
      });
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            value={answer}
            onChange={(e) => {
              e.preventDefault();
              setAnswer(e.target.value);
            }}
          />
          <button
            className="btn btn-outline-primary"
            type="button"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShortTextQuestion;
