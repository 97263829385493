import React, { useState, useEffect } from "react";
import { fetchAdminArticles } from "modules/articles";
import moment from "moment";
import AdminPanelHeader from "../../common/admin-panel-header";
import AdminPanelIndexControls from "../../common/admin-panel-index-controls";

const Articles = (props) => {
  const [articles, setArticles] = useState(props.articles || []);
  const [query, setQuery] = useState("");

  useEffect(() => {
    fetchAdminArticles({ query }).then((data) => {
      setArticles(data);
    });
  }, [query]);

  return (
    <>
      <AdminPanelHeader
        title="Articles"
        singularTitle="Article"
        addNewUrl="/admin/articles/new"
      />
      <AdminPanelIndexControls
        keywordSearchConfig={{
          state: query,
          setState: (value) => setQuery(value.query),
        }}
      />
      <div className="px-3">
        <div className="panel-table">
          <table className="table admin-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Title</th>
                <th>Author</th>
                <th>Status</th>
                <th>Posted</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {articles.map((article, index) => {
                return (
                  <tr className="row-with-shadow" key={`user-row-${index}`}>
                    <td className="admin-metadata">{article.id}</td>
                    <td>
                      {article.status === "published" ? (
                        <a
                          href={`/resources/${article.permalink}`}
                          target="_blank"
                        >
                          {article.title}
                        </a>
                      ) : (
                        article.title
                      )}
                    </td>
                    <td>{article.author}</td>
                    <td>{article.status}</td>
                    <td>
                      {article.posted_at
                        ? moment(article.posted_at).format("MM/DD/YYYY")
                        : null}
                    </td>
                    <td>
                      <a
                        href={`/admin/articles/${article.id}/edit`}
                        className="btn btn-sm btn-outline-secondary"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Articles;
