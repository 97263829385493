import axios from 'lib/axios'

export const createIncentiveFaqEntry = (params = {}) => {
  const url = `/admin/incentives/${params.incentive_id}/incentive_faq_entries`

  return axios.post(url, params)
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response.data)
    })
}

export const deleteIncentiveFaqEntry = (params = {}) => {
  const url = `/admin/incentives/${params.incentive_id}/incentive_faq_entries/${params.id}`

  return axios.delete(url)
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response.data)
    })
}

export const updateIncentiveFaqEntry = (params = {}) => {
  const url = `/admin/incentives/${params.incentive_id}/incentive_faq_entries/${params.incentive_faq_entry.id}`

  return axios.put(url, params)
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response.data)
    })
}