import axios from "lib/axios";

export const fetchCompanyWorkers = async (page, perPage) => {
    try {
      const response = await axios.get('/company_workers', {
        params: {
          page: page,
          per_page: perPage,
          sort_column: 'name',
          sort_direction: 'ASC',
          query: ''
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching company workers:', error);
      throw error;
    }
  };
  
export const createCompanyWorker = (params = {}) => {
    const url = `/company_workers`;
    console.log("In createCompanyWorker", params);
  
    return axios
        
      .post(url, { company_worker: params })
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
  };

  export const updateCompanyWorker = async (workerDetails) => {
    const url = `/company_workers/${workerDetails.id}`;
    return axios.put(url, workerDetails)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };
  
  export const deleteCompanyWorker = (id) => {
    const url = `/company_workers/${id}`;
  
    return axios
      .delete(url)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
  };
  