import React from "react";

import { prettyDisplayPrice } from "lib/string";
import ProgressNumericCurrency from "./progress-numeric";
import AnimatedNumber from "animated-number-react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const CashSummary = (props) => {
  let total_receivables =
    props.incoming_receivables + props.projected_receivables;
  let percent_receivables_from_ar =
    total_receivables === 0
      ? 0
      : ((100 * props.incoming_receivables) / total_receivables).toFixed(1);
  let total_payables = props.upcoming_payables + props.projected_payables;
  let percent_payables_from_ap =
    total_payables === 0
      ? 0
      : ((100 * props.upcoming_payables) / total_payables).toFixed(1);

  const formatNumber = (n) => {
    let string = "$";
    string += parseInt(n).toLocaleString("en");
    return string;
  };

  const wrapNumbersWithStrong = (text) => {
    return text.split(/(\d+(?:\.\d+)?%?)/).map((part, i) => {
      if (/\d+(?:\.\d+)?%?/.test(part)) {
        return <strong key={i}>{part}</strong>;
      } else {
        return part;
      }
    });
  };

  const renderMetric = (title, value, context, tooltip = "") => {
    const tooltipComponent = (
      <Tooltip
        id={`tooltip-${title.replace(/\s+/g, "-")}`}
        className="cashflow-tooltip"
      >
        {tooltip}
      </Tooltip>
    );

    const metricContent = (
      <div className="metric" style={{ borderRight: "1px solid #e6e6e6" }}>
        <p className="mb-0">{title}</p>
        <div className="big-number">
          <AnimatedNumber
            value={value}
            duration={600}
            formatValue={formatNumber}
          />
        </div>
        <div className="finance-context mt-2 px-1">
          <p>{wrapNumbersWithStrong(context)}</p>
        </div>
        {tooltip && <i className="fa fa-info-circle" aria-hidden="true" />}
      </div>
    );

    return tooltip ? (
      <OverlayTrigger placement="bottom" overlay={tooltipComponent}>
        {metricContent}
      </OverlayTrigger>
    ) : (
      metricContent
    );
  };

  const ending_cash_balance =
    props.cash_balance + total_receivables - total_payables;
  const percentage_balance_change =
    props.cash_balance === 0
      ? 0
      : (
          ((ending_cash_balance - props.cash_balance) / props.cash_balance) *
          100
        ).toFixed(1);
  const balance_change_word =
    percentage_balance_change > 0 ? "increase" : "decrease";

  const tooltipText = `Our projections start with recurring payments. We then analyze historical payables and receivables data from your profit and loss statements to forecast amounts for the current month. As actual balances come in, we adjust our projections accordingly.`;

  return (
    <div className="cash-summary">
      {renderMetric(
        "Current Cash Balance",
        props.cash_balance,
        "from imported balances"
      )}
      {renderMetric(
        "Projected Money In",
        props.incoming_receivables + props.projected_receivables,
        percent_receivables_from_ar + "% from imported A/R",
        tooltipText
      )}
      {renderMetric(
        "Projected Money Out",
        props.upcoming_payables + props.projected_payables,
        percent_payables_from_ap + "% from imported A/P",
        tooltipText
      )}
      {renderMetric(
        "Ending Cash Balance",
        ending_cash_balance,
        `${percentage_balance_change}% projected ${balance_change_word}`
      )}
    </div>
  );
};

export default CashSummary;
