import React from 'react';
import { UIRadioCard } from 'components/ui'

const AmountCards = ({ amountState, setAmountState, showAmountToggles, setShowAmountToggles }) => {
  function toggleAmountState(field) {
    setAmountState({ ...amountState, [field]: !amountState[field] })
  }

  function toggleShowAmounts(e) {
    e.preventDefault();
    setShowAmountToggles(!showAmountToggles)
  }

  if(!showAmountToggles) {
    return (
      <div className='float-end mb-3'>
        <button className='btn btn-sm btn-muted' onClick={toggleShowAmounts}>
          <i className="bi bi-gear-fill"></i>
        </button>
      </div>
    )
  }

  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className='float-end'>
          <button className='btn btn-sm btn-muted' onClick={toggleShowAmounts}>
            <i className="bi bi-gear-fill"></i>
          </button>
        </div>

        <div className='row gx-3'>
          <div className='col-md-auto'>
            <UIRadioCard
              checked={amountState.salesTax}
              label='Sales Tax'
              id='sales-tax'
              name='Sales Tax'
              handleClick={() => {
                toggleAmountState('salesTax')
              }}
            />
          </div>
          <div className='col-md-auto'>
            <UIRadioCard
              checked={amountState.laborAndDelivery}
              label='Labor & Delivery'
              id='labor-and-delivery'
              name='Labor and Delivery'
              handleClick={() => {
                toggleAmountState('laborAndDelivery')
              }}
            />
          </div>
          <div className='col-md-auto'>
            <UIRadioCard
              checked={amountState.fee}
              label='Fee'
              id='fee'
              name='Fee'
              handleClick={() => {
                toggleAmountState('fee')
              }}
            />
          </div>
          <div className='col-md-auto'>
            <UIRadioCard
              checked={amountState.discount}
              label='Discount'
              id='discount'
              name='Discount'
              handleClick={() => {
                toggleAmountState('discount')
              }}
            />
          </div>
          <div className='col-md-auto'>
            <UIRadioCard
              checked={amountState.paymentsAndDeposits}
              label='Payments & Deposits'
              id='payments-and-deposits'
              name='Payments and Deposits'
              handleClick={() => {
                toggleAmountState('paymentsAndDeposits')
              }}
            />
          </div>
        </div>

      </div>
    </div>
  )
}

export default AmountCards;