import axios from "lib/axios";

export const createCompanyFinancialAccount = (params = {}) => {
  const url = `/company_financial_accounts`;
  
  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const deleteCompanyFinancialAccount = (id) => {
  const url = `/company_financial_accounts/${id}`;
  
  return axios
    .delete(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const updateCompanyFinancialAccount = (id, params = {}) => {
  const url = `/company_financial_accounts/${id}`;
  
  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
