import React, { useState, useEffect } from "react";
import AddWorkerModal from "./add-worker-modal";
import { flashSuccess, flashError } from "lib/flash";
import UIPagination from "../../ui/ui-pagination";

import {
  fetchCompanyWorkers,
  updateCompanyWorker,
  deleteCompanyWorker,
} from "modules/company_workers";

const CompanyWorkers = () => {
  const [workers, setWorkers] = useState([]);
  const [newWorker, setNewWorker] = useState({
    name: "",
    employment_type: "",
    job_title: "",
    department_name: "",
  });
  const [editedWorker, setEditedWorker] = useState({
    id: null,
    name: "",
    employment_type: "",
    job_title: "",
    department_name: "",
  });
  const [editingWorkerId, setEditingWorkerId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [workersPerPage] = useState(50);
  const [totalWorkers, setTotalWorkers] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const loadWorkers = async (page) => {
    try {
      const fetchedWorkers = await fetchCompanyWorkers(page, workersPerPage);
      setWorkers(fetchedWorkers.workers);
      setTotalWorkers(fetchedWorkers.total);
    } catch (error) {
      console.error("Error fetching workers:", error);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    loadWorkers(pageNumber);
  };

  useEffect(() => {
    loadWorkers(currentPage);
  }, [currentPage]);

  const onWorkerAdded = (newWorker) => {
    const updatedWorkers = [...workers, newWorker].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    setWorkers(updatedWorkers);

    setNewWorker({
      name: "",
      employment_type: "",
      job_title: "",
      department_name: "",
    });

    flashSuccess(`${newWorker.name} added to employee list.`);
  };

  const startEdit = (workerId) => {
    const workerToEdit = workers.find((worker) => worker.id === workerId);
    setEditedWorker(workerToEdit || {});
    setEditingWorkerId(workerId);
  };

  const cancelEdit = () => {
    setEditingWorkerId(null);
    setEditedWorker({
      id: null,
      name: "",
      employment_type: "",
      job_title: "",
      department_name: "",
    });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditedWorker((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const saveEditedWorker = async () => {
    try {
      const updatedWorker = await updateCompanyWorker(editedWorker);
      const updatedWorkers = workers.map((worker) => {
        if (worker.id === updatedWorker.id) {
          return updatedWorker;
        }
        return worker;
      });
      setWorkers(updatedWorkers);
      flashSuccess(`${updatedWorker.name} updated successfully.`);
      cancelEdit();
    } catch (error) {
      console.error("Error updating worker:", error);
      flashError("Failed to update worker.");
    }
  };

  const handleDelete = async (workerId) => {
    const workerToDelete = workers.find((worker) => worker.id === workerId);
    const workerName = workerToDelete ? workerToDelete.name : "Employee";

    const isConfirmed = window.confirm(
      `Are you sure you want to delete ${workerName}? This action cannot be undone.`
    );

    if (!isConfirmed) {
      return;
    }
    try {
      await deleteCompanyWorker(workerId);
      setWorkers(workers.filter((worker) => worker.id !== workerId));
      flashSuccess(`${workerName} successfully deleted from employees.`);
      cancelEdit();
    } catch (error) {
      console.error("Error deleting worker:", error);
      flashError(`Error deleting worker: ${error}`);
    }
  };

  return (
    <>
      <div className="card p-3 mt-3 mb-3 z-depth-2">
        <h3 className="panel-title mb-2">Manage employees</h3>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <p className="panel-context">
            Add, edit or delete part-time, full-time or contract employees.
          </p>
          <button className="btn btn-sm btn-primary" onClick={handleShowModal}>
            + Add employee
          </button>
        </div>
        <table className="settings-table">
          <thead>
            <tr style={{ backgroundColor: "#F8FCFF" }}>
              <th>Name</th>
              <th>Job Title</th>
              <th>Department</th>
              <th>Employment Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {workers.map((worker) => (
              <tr key={worker.id}>
                {editingWorkerId === worker.id ? (
                  <td>
                    <input
                      type="text"
                      name="name"
                      placeholder="Employee Name"
                      className="inline-edit"
                      value={editedWorker.name}
                      onChange={handleEditInputChange}
                    />
                  </td>
                ) : (
                  <td> {worker.name}</td>
                )}
                {editingWorkerId === worker.id ? (
                  <td>
                    <input
                      type="text"
                      name="job_title"
                      placeholder="Job Title"
                      className="inline-edit"
                      value={editedWorker.job_title}
                      onChange={handleEditInputChange}
                    />
                  </td>
                ) : (
                  <td> {worker.job_title}</td>
                )}
                {editingWorkerId === worker.id ? (
                  <td>
                    <input
                      type="text"
                      placeholder="Department Name"
                      name="department_name"
                      className="inline-edit"
                      value={editedWorker.department_name}
                      onChange={handleEditInputChange}
                    />
                  </td>
                ) : (
                  <td> {worker.department_name}</td>
                )}
                {editingWorkerId === worker.id ? (
                  <td>
                    <input
                      type="text"
                      placeholder="Employment Type"
                      name="employment_type"
                      className="inline-edit"
                      value={editedWorker.employment_type}
                      onChange={handleEditInputChange}
                    />
                  </td>
                ) : (
                  <td> {worker.employment_type}</td>
                )}
                <td>
                  {editingWorkerId === worker.id ? (
                    <>
                      <button
                        onClick={() => cancelEdit()}
                        className="btn btn-sm btn-muted"
                      >
                        <i
                          className="bi bi-x"
                          style={{ fontSize: "20px", color: "#6D757D" }}
                        ></i>
                      </button>
                      <button
                        onClick={saveEditedWorker}
                        className="btn btn-sm btn-primary"
                      >
                        <i
                          className="bi bi-check"
                          style={{ fontSize: "20px", color: "#6D757D" }}
                        ></i>
                      </button>
                      <button
                        onClick={() => handleDelete(worker.id)}
                        className="btn btn-muted inline-icon-button"
                      >
                        <i
                          className="bi bi-trash"
                          style={{ fontSize: "20px", color: "#6D757D" }}
                        ></i>
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => startEdit(worker.id)}
                      className="btn btn-muted inline-icon-button"
                    >
                      <i
                        className="bi bi-pencil-square"
                        style={{ fontSize: "20px", color: "#6D757D" }}
                      ></i>
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <UIPagination
          page={currentPage}
          perPage={workersPerPage}
          total={totalWorkers}
          changePage={({ selected }) => paginate(selected + 1)}
        />

        <AddWorkerModal
          show={showModal}
          onHide={handleCloseModal}
          onWorkerAdded={onWorkerAdded}
        />
      </div>
    </>
  );
};

export default CompanyWorkers;
