import React, { useState, useEffect } from "react";
import { createOnboardingAttachments } from "modules/user/onboarding";
import { flashSuccess, flashError } from "lib/flash";
import { humanFileSize } from 'lib/string';
import ProgressBar from 'react-bootstrap/ProgressBar';
import moment from "moment";

const UploadingInProgress = (props) => {
  const acceptedFileTypes = props.fileTypes
  const file = props.file;

  const [ currentProgressBar, setcurrentProgressBar ] = useState(0);
  const [ uploadFileStatus, setUploadFileStatus ] = useState({ status: 'uploading' });
  const [ properFileUploaded, setProperFileUploaded ] = useState(false);

  const progressBarHash = {
    'success': { text: 'Success', class: 'success'},
    'uploading': { text: 'Uploading', class: 'info'},
    'failure': { text: 'Error', class: 'danger'}
  }

  const params = { attachment_attributes: { file: file }, "section_key": props.section_key, "step_key": props.step_key }

  const options = {
    onUploadProgress: (progressEvent) => {
      let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
      setcurrentProgressBar(percentCompleted);
    }
  }

  const arrayIntersection = (a, b) => {
    return a.filter(x => b.includes(x));
  }

  const isFileTypeSupported = (fileType) => {
    const defaultAcceptedFileTypes = [
      'pdf',
      'image',
      'csv',
      'rtf',
      'msword',
      'doc',
      'docx',
      'plain',
      'txt',
      'vnd.ms-excel',
      'vnd.ms-powerpoint',
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'vnd.openxmlformats-officedocument.wordprocessingml.document',
      'vnd.openxmlformats-officedocument.presentationml.presentation',
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ]

    const acceptedFileTypes = props.fileTypes ? props.fileTypes : defaultAcceptedFileTypes;

    return arrayIntersection(fileType.split('/'), acceptedFileTypes).length > 0;
  }

  useEffect(() => {
    if (isFileTypeSupported(file.type)) {
      setProperFileUploaded(true);

      createOnboardingAttachments(params, options)
        .then((data) => {
          props.reuploadFile(false);
          setUploadFileStatus({...uploadFileStatus, status: data.status});
          setcurrentProgressBar(100);
          props.attachments(data.file_resources);
          flashSuccess("File Added.");
        })
        .catch((error) => {
          props.reuploadFile(true);
          flashError("Something Went Wrong.", error);
        })
    }
    else {
      setProperFileUploaded(false);
      props.reuploadFile(true);
    }
  }, [])

  const progressBarText = () => {
    if (currentProgressBar == 100 && uploadFileStatus.status == 'uploading') { return 'Processing'; }

    return progressBarHash[uploadFileStatus.status].text;
  }

  const progressBarProcessing = () => {
    if (currentProgressBar == 100 && uploadFileStatus.status == 'uploading') { return true; }

    return false;
  }

  return(
    <div key={`company-file-resources-row-${props.index}`} className="row">
      { (props.index != 0 && props.index != props.filesLength) && <hr className="mt-2 mb-2" /> }
      <div className="col-md-6">
        <i className={`bi bi-filetype-${file.type}`}></i>
        {file.name}
        <p className="text-muted fs-6">{ humanFileSize(file.size) }</p>
      </div>
      <div className="col-md-6">
        { properFileUploaded &&
          <div>
            <ProgressBar
              striped={progressBarProcessing()}
              animated={progressBarProcessing()}
              variant={progressBarHash[uploadFileStatus.status].class}
              now={currentProgressBar}
              key={props.index}
            />
            <p className={`text-${progressBarHash[uploadFileStatus.status].class}`}>
              {progressBarText()}
            </p>
          </div>
        }
        { !properFileUploaded && <div><p>Binary files cannot be uploaded.</p></div> }
      </div>
    </div>
  );
};

export default UploadingInProgress;
