import React, { useState, useEffect } from "react";
import {
  fetchCompanyAggregateMetrics,
  fetchMetricsByReferralSource,
  fetchMetricsByPrimaryState,
  fetchMetricsByQualifiedIncentive,
  fetchMetricsByAppliedIncentive,
} from "modules/companies";
import { UIBigNumberCard } from "components/ui";
import BatchEdit from "./batch-edit";
import AdminStatsCompanyTable from "./admin-stats-company-table.jsx";
import AdminStatsUsersTable from "./admin-stats-users-table.jsx";
import AdminStatsIncentivesTable from "./admin-stats-incentives-table.jsx";
import AdminStatsApplicationsTable from "./admin-stats-applications-table.jsx";

const InternalAdminStats = () => {
  const [aggregateCompanyMetrics, setAggregateCompanyMetrics] = useState({
    metrics: {
      active_companies_count: 0,
      active_companies_with_incentives_count: 0,
      active_companies_with_applications_count: 0,
      active_companies_created_past_28_days: 0,
      application_dollar_sum: 0,
      total_incentive_company_qualifications: 0,
    },
  });
  const [metricsByReferralSource, setMetricsByReferralSource] = useState([]);
  const [metricsByPrimaryState, setMetricsByPrimaryState] = useState([]);
  const [metricsByQualifiedIncentive, setMetricsByQualifiedIncentive] =
    useState([]);
  const [metricsByAppliedIncentive, setMetricsByAppliedIncentive] = useState(
    []
  );
  const [activeCard, setActiveCard] = useState("ActiveCompanies");

  useEffect(() => {
    fetchCompanyAggregateMetrics()
      .then((data) => {
        setAggregateCompanyMetrics(data);
      })
      .catch((error) => {
        console.error("Failed to fetch company metrics:", error);
      });

    fetchMetricsByReferralSource()
      .then((response) => {
        setMetricsByReferralSource(response.metrics_by_referral_source);
      })
      .catch((error) => {
        console.log("Complete error:", error);
      });

    fetchMetricsByPrimaryState()
      .then((response) => {
        setMetricsByPrimaryState(response.metrics_by_primary_state);
      })
      .catch((error) => {
        console.log("Error fetching metrics by primary state:", error);
      });

    fetchMetricsByQualifiedIncentive()
      .then((response) => {
        setMetricsByQualifiedIncentive(response.metrics_by_qualified_incentive);
      })
      .catch((error) => {
        console.log("Error fetching metrics by qualified incentive:", error);
      });

    fetchMetricsByAppliedIncentive()
      .then((response) => {
        setMetricsByAppliedIncentive(response.metrics_by_applied_incentive);
      })
      .catch((error) => {
        console.log("Error fetching metrics by applied incentive:", error);
      });
  }, []);

  return (
    <>
      <div className="panel">
        <header className="panel-header">
          <div className="row">
            <div className="col-md-8 px-3 mt-3">
              <h3 className="admin-gradient-text">Subcity Stats</h3>
            </div>
          </div>
        </header>
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-around">
              <div className="col-md-3">
                <UIBigNumberCard
                  title="Active Companies"
                  number={
                    aggregateCompanyMetrics.metrics.active_companies_count
                  }
                  contextNumber={
                    (aggregateCompanyMetrics.metrics
                      .active_companies_created_past_28_days /
                      aggregateCompanyMetrics.metrics.active_companies_count) *
                    100
                  }
                  contextSuffix="% created in past 28 days"
                  tooltip="All companies set as active in admin tools."
                  isActive={activeCard === "ActiveCompanies"}
                  onClick={() => {
                    console.log("Clicked ActiveCompanies Card");
                    setActiveCard("ActiveCompanies");
                  }}
                />
              </div>
              <div className="col-md-3">
                <UIBigNumberCard
                  title="Users from active companies"
                  number={
                    aggregateCompanyMetrics.metrics
                      .active_companies_user_company_count
                  }
                  contextNumber={
                    aggregateCompanyMetrics.metrics
                      .active_companies_user_company_count /
                    aggregateCompanyMetrics.metrics.active_companies_count
                  }
                  contextNumberPrecision="1"
                  contextSuffix=" average users per company"
                  isActive={activeCard === "Users"}
                  onClick={() => setActiveCard("Users")}
                />
              </div>
              <div className="col-md-3">
                <UIBigNumberCard
                  suffix="%"
                  title="with Qualified Incentives"
                  number={
                    (aggregateCompanyMetrics.metrics
                      .active_companies_with_incentives_count /
                      aggregateCompanyMetrics.metrics.active_companies_count) *
                    100
                  }
                  contextNumber={
                    aggregateCompanyMetrics.metrics
                      .total_incentive_company_qualifications /
                    aggregateCompanyMetrics.metrics
                      .active_companies_with_incentives_count
                  }
                  contextNumberPrecision="2"
                  contextSuffix=" average incentives per company"
                  tooltip="Number is based on the incentive and companies pair where is_qualified = true even in the case of manual overrides."
                  isActive={activeCard === "Incentives"}
                  onClick={() => setActiveCard("Incentives")}
                />
              </div>
              <div className="col-md-3">
                <UIBigNumberCard
                  prefix="$"
                  title="Total Application Dollars"
                  number={
                    aggregateCompanyMetrics.metrics.application_dollar_sum
                  }
                  contextNumber={
                    aggregateCompanyMetrics.metrics
                      .active_companies_with_applications_count
                  }
                  contextSuffix=" active companies with applications"
                  isActive={activeCard === "Applications"}
                  onClick={() => setActiveCard("Applications")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-3">
          <div className="row">
            <div className="col-md-12">
              {activeCard === "ActiveCompanies" && (
                <AdminStatsCompanyTable
                  metricsByReferralSource={metricsByReferralSource}
                />
              )}
              {activeCard === "Users" && (
                <AdminStatsUsersTable
                  metricsByPrimaryState={metricsByPrimaryState}
                />
              )}
              {activeCard === "Incentives" && (
                <AdminStatsIncentivesTable
                  metricsByQualifiedIncentive={metricsByQualifiedIncentive}
                />
              )}
              {activeCard === "Applications" && (
                <AdminStatsApplicationsTable
                  metricsByAppliedIncentive={metricsByAppliedIncentive}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <BatchEdit></BatchEdit>
        </div>
      </div>
    </>
  );
};

export default InternalAdminStats;
