import React, { Component } from "react";

import blankProfile from "images/blank-profile.jpg";

import {
  withUINavTabs,
  UINavPanel,
  UINavSection,
  UINavHorizontalInternal,
} from "components/ui";

import EditUserBasicInfo from "./edit-user-basic-info";
import EditUserSecurity from "./edit-user-security";
import EditCompanies from "./edit-companies";
import EditUserContextProvider from "./edit-user-context";
import Referrals from "../../../common/referrals";

export class EditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
    };
  }

  componentDidMount() {
    this.setState({
      user: this.props.user,
    });
  }

  render() {
    return (
      <EditUserContextProvider user={this.props.user}>
        <div className="ps-4 pe-4 pb-4 admin-company-form">
          <div className="row">
            <div className="col-md-12">
              <h4 className="">
                {this.state.user.first_name} &nbsp;
                {this.state.user.last_name}
              </h4>
              <UINavHorizontalInternal
                style="tabs"
                tabs={[
                  {
                    name: "Basic Info",
                    link: "/basic-info",
                  },
                  {
                    name: "Companies",
                    link: "/companies",
                  },
                  {
                    name: "Referrals",
                    link: "/referrals",
                  },
                  {
                    name: "Security",
                    link: "/security",
                  },
                ]}
              />
            </div>
            <div className="col-md-12">
              <UINavPanel>
                <UINavSection tab="/basic-info">
                  <EditUserBasicInfo user={this.state.user} />
                </UINavSection>
                <UINavSection tab="/companies">
                  <EditCompanies
                    user={this.state.user}
                    roles={this.props.roles}
                  />
                </UINavSection>
                <UINavSection tab="/referrals">
                  <Referrals
                    referrable={this.state.user}
                    baseUrl="/admin/users/"
                  />
                </UINavSection>
                <UINavSection tab="/security">
                  <EditUserSecurity user={this.state.user} />
                </UINavSection>
              </UINavPanel>
            </div>
          </div>
        </div>
      </EditUserContextProvider>
    );
  }
}

export default withUINavTabs(EditUser);
