import React from 'react';

import {
  UIPaginationTotalSelector,
  UIPagination,
} from "components/ui";

const TableFooter = ({ pagination, updatePagination }) => {
  if(pagination?.total === 0) {
    return null;
  }

  return (
    <tfoot>
      <tr>
        <td colSpan={3}>
          <div className='mt-2'>
            {pagination.total > 0 ? (
              <UIPaginationTotalSelector
                perPage={pagination.per_page}
                changePerPage={(value) => {
                  updatePagination({
                    per_page: value,
                    first_load: false,
                  })
                }}
              />
            ) : null }
          </div>
        </td>

        <td colSpan={10}>
          <span className="float-end mt-2">
            {pagination.total > 0 ? (
              <UIPagination
                page={pagination.page}
                perPage={pagination.per_page}
                changePage={(page) => {
                  updatePagination({
                    page: page.selected + 1,
                    first_load: false,
                  });
                }}
                total={pagination.total}
              />
            ) : null }
          </span>
        </td>
      </tr>
    </tfoot>
  )
}

export default TableFooter;
