import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DocumentUploadPanel from "../applications/common/documents/document-upload-panel";

const CsvUploadModal = ({ show, handleClose, handleUpload, title }) => {
  const [file, setFile] = useState(null);

  const uploadFileResource = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const handleSubmit = () => {
    if (file) {
      handleUpload(file);
      handleClose();
    }
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DocumentUploadPanel uploadFileResource={uploadFileResource} />
        {file && <p>Selected file: {file.name}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!file}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CsvUploadModal;
