import React, { useState, useEffect } from "react";
import { FormControl, DropdownButton, Dropdown } from "react-bootstrap";
import filter from "lodash/filter";
import find from "lodash/find";
import { fetchAdminOrganizationsSelector } from "modules/incentives";
import { match } from "lib/string";

const OrganizationSelector = ({
  organizations: orgsProp,
  currentOrganizationId,
  selectOrganization,
}) => {
  const [q, setQ] = useState("");
  const [organizations, setOrganizations] = useState(orgsProp || []);
  const [currentOrganization, setCurrentOrganization] = useState(null);

  useEffect(() => {
    if (organizations.length > 0) return;

    fetchAdminOrganizationsSelector({ count: "all" }).then((data) => {
      if (currentOrganizationId) {
        setCurrentOrganization(
          find(data.organizations, (o) => o.id === currentOrganizationId)
        );
      }
      setOrganizations(data.organizations);
    });
  }, [organizations, currentOrganizationId]);

  const filterOrganizations = (orgs) => {
    return filter(orgs, (organization) => match(organization.name, q));
  };

  const handleSelectOrganization = (id) => {
    selectOrganization(id);
    setCurrentOrganization(find(organizations, (o) => o.id === id));
  };

  const getTitle = () => {
    return currentOrganization
      ? currentOrganization.name
      : "Select an Organization";
  };

  const resources = filterOrganizations(organizations);

  return (
    <DropdownButton
      id="dropdown-basic-button"
      className="incentive-selector"
      title={getTitle()}
      variant="light"
      onSelect={handleSelectOrganization}
    >
      <div className="px-3">
        <FormControl
          autoFocus
          className="my-2"
          placeholder="Type to filter..."
          onChange={(e) => setQ(e.target.value)}
          value={q}
        />
      </div>
      <div
        style={{ overflowY: "scroll", maxHeight: "350px", minWidth: "650px" }}
      >
        {q.length >= 2 &&
          resources.map((resource, index) => (
            <Dropdown.Item eventKey={resource.id} key={`resource-${index}`}>
              {resource.name}
            </Dropdown.Item>
          ))}
      </div>
    </DropdownButton>
  );
};

export default OrganizationSelector;
