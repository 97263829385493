import React, { useState, useContext } from "react";
import { Button, Spinner } from "react-bootstrap";
import { flashError, flashSuccess } from "lib/flash";
import { qualifyCompanyForIncentivesService } from "modules/companies";
import { EditCompanyContext } from "components/common/companies/contexts/admin-edit-company-context";

const RunQualifyJobButton = ({ fetchIncentives = () => {} }) => {
  const { basicInfo } = useContext(EditCompanyContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleQualifyCompanyForIncentives = () => {
    setIsLoading(true);
    qualifyCompanyForIncentivesService(basicInfo.id)
      .then(() => {
        flashSuccess(
          `We are matching Company ${basicInfo.id} with qualified incentives`
        );
        fetchIncentives();
      })
      .catch((error) => {
        flashError(`There was an error: ${error.message} with matching`);
        console.error(error.message || "An error occurred");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Button
      variant="action"
      onClick={handleQualifyCompanyForIncentives}
      disabled={isLoading}
      style={{ marginLeft: "4px" }}
    >
      {isLoading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="me-2"
          />
          Running...
        </>
      ) : (
        "Run Qualify Job"
      )}
    </Button>
  );
};

export default RunQualifyJobButton;
