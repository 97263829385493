import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { prettyDisplayPrice } from 'lib/string'

const ProgressNumericCurrency = (props) => {
  const targetRef = useRef();
  const [number, setNumber] = useState(props.number);
  const [title, setTitle] = useState(props.title);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setNumber(props.number);
    setTitle(props.title);
  }, [props.number, props.title])

  const MAX_WIDTH = 314;
  const MAX_BAR_VALUE = 100;

  function resizeWindow() {
    if (targetRef.current) {
      const width = Math.min(MAX_WIDTH, targetRef.current.getBoundingClientRect().width)
      setWidth(width);
    }
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", resizeWindow);
    resizeWindow()

    return () => {
      window.removeEventListener("resize", resizeWindow)
    }
  }, []);

  function getBarStyle(bar, now) {
    if (bar.title == title) {
      return { "width": `${now}%`, "backgroundColor": bar.color, "border": "2px solid white" }
    } else {
      return { "width": `${now}%`, "backgroundColor": bar.color }
    }
  }

  return (
    <div className='progress-numeric mb-3' ref={targetRef}>
      <span>{title}</span>
      {!!number &&
        <>
          <div className='float-end'>
            {prettyDisplayPrice(number)}
          </div>
        </>
      }
      <div className="progress" style={{ "width": width, "height": "8px", "overflowX": 'hidden' }}>
        {
          props.bars.map((bar) => {
            const now = (bar.number / props.total) * 100;
            return (
              <div
                key={`progress-bar-${bar.title}`}
                style={getBarStyle(bar, now)}
                className="progress-bar"
                role="progressbar"
                aria-label={bar.title}
                aria-valuenow={now}
                aria-valuemin={0}
                aria-valuemax={MAX_BAR_VALUE}
                title={bar.title}
                number={bar.number}
                onMouseOver={
                  (e) => {
                    setNumber(e.target.attributes["number"]["value"]);
                    setTitle(e.target.title);
                  }
                }
                onMouseOut={
                  (e) => {
                    setNumber(props.number);
                    setTitle(props.title);
                  }
                }
              >
              </div>
            );
          })
        }
      </div>


    </div>
  )
}

export default ProgressNumericCurrency;
