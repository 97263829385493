import React, { Component } from 'react';

const shurley = require('shurley');
import { UILabelInput } from 'components/ui'

const IncentiveUrlRow = (props) => {
  const { url } = props;

  return (
    <tr>
      <td width="170px">
        <UILabelInput
          label="Name"
          value={url.label}
          error={''}
          onChange={(value) => {
            props.changeUrl(props.index, 'label', value) 
          }}
        />
      </td>
      <td>
        <UILabelInput
          label="URL"
          value={url.url}
          error={''}
          onChange={(value) => {
            props.changeUrl(props.index, 'url', shurley.parse(value)) 
          }}
        />
      </td>
      <td style={{'verticalAlign': 'bottom', 'width': '50px'}}>
        <button className='btn btn-light' onClick={(e) => {
          e.preventDefault();
          props.removeUrl(props.index)
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash2" viewBox="0 0 16 16">
            <path d="M14 3a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2zM3.215 4.207l1.493 8.957a1 1 0 0 0 .986.836h4.612a1 1 0 0 0 .986-.836l1.493-8.957C11.69 4.689 9.954 5 8 5c-1.954 0-3.69-.311-4.785-.793z"/>
          </svg>
        </button>
      </td>
    </tr>
  )
}

const IncentiveUrls = (props) => {
  const { urls } = props;   
  
  const renderUrls = () => {
    return urls.map((url, index) => {
      return (
        <IncentiveUrlRow 
          index={index}
          key={`incentive-url-row-${index}`}
          url={url}
          changeUrl={props.changeUrl}
          removeUrl={props.removeUrl}
        />
      ) 
    })
  }

  return (
    <table className='table table-borderless table-sm'>
      <tbody>
        { renderUrls() }
        <tr>
          <td colSpan={3}>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default IncentiveUrls;
