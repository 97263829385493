import React, { useEffect } from "react";
import { State } from "country-state-city";
import { useSetState } from "react-hanger";

import { UILabelInput } from "components/ui";

import {
  createOrganizationContact,
  updateOrganizationContact,
} from "modules/organizations";

const blankContact = {
  id: null,
  first_name: "",
  last_name: "",
  email: "",
  title: "",
  office_number: "",
  mobile_number: "",
  fax_number: "",
};

const OrganizationContactForm = ({
  organizationContact = blankContact,
  onCancel,
  onSaveOrganizationContact,
}) => {
  const { state: currentContact, setState: setCurrentContact } =
    useSetState(organizationContact);

  const { state: formErrors, setState: setFormErrors } = useSetState({
    first_name: "",
    last_name: "",
    email: "",
    title: "",
    office_number: "",
    mobile_number: "",
    fax_number: "",
  });

  useEffect(() => {
    setCurrentContact(organizationContact);
  }, [organizationContact]);

  const submitForm = () => {
    onSaveOrganizationContact(currentContact);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            label="First Name"
            value={currentContact.first_name}
            onChange={(value) => {
              setCurrentContact({ first_name: value });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            label="Last Name"
            value={currentContact.last_name}
            onChange={(value) => {
              setCurrentContact({ last_name: value });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            label="Email"
            value={currentContact.email}
            onChange={(value) => {
              setCurrentContact({ email: value });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            label="Title"
            value={currentContact.title}
            onChange={(value) => {
              setCurrentContact({ title: value });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            label="Office number"
            value={currentContact.office_number}
            onChange={(value) => {
              setCurrentContact({ office_number: value });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            label="Mobile number"
            value={currentContact.mobile_number}
            onChange={(value) => {
              setCurrentContact({ mobile_number: value });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            label="Fax number"
            value={currentContact.fax_number}
            onChange={(value) => {
              setCurrentContact({ fax_number: value });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="d-flex justify-content-end pt-5">
          <div className='btn-group'>
            <button className="btn btn-light" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn btn-dark mx-1" onClick={submitForm}>
              Save Contact
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationContactForm;
