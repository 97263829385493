import React, { useEffect } from "react";
import { useSetState } from "react-hanger";
import { Operators, OperatorMapping, findByValue } from "./rule-utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { pick, values, isEmpty } from "lodash";
import { getDate } from "lib/utils";

const SubRule = ({
  variableValue,
  onFieldChange: onValueChange,
  selectedField,
  fieldOptions,
}) => {
  const setDateValue = (value) => {
    if (variableValue.operator === "between")
      return [getDate(value[0]), getDate(value[1])];

    return getDate(value);
  };

  const { state: subVariables, setState: setSubVariables } = useSetState({
    field: variableValue.field,
    operator: variableValue.operator,
    operatorKey: variableValue.operatorKey,
    fieldType: variableValue.fieldType,
    value:
      variableValue.fieldType === "date"
        ? setDateValue(variableValue.value)
        : variableValue.value || "",
  });

  useEffect(() => {
    onValueChange("value", subVariables);
  }, [subVariables]);

  const onVariableChange = (value) => {
    if (value == "") return;

    const fieldObj = JSON.parse(value);
    setSubVariables({
      fieldType: fieldObj.type,
      field: fieldObj.label,
    });
  };

  const onFieldChange = (key, value) => {
    setSubVariables({ [key]: value });
  };

  const onOperatorChange = (value) => {
    setSubVariables({
      operatorKey: value,
      operator: Operators[value].value,
      value: Operators[value].default[subVariables.fieldType],
    });
  };

  const operatorOptions = () => {
    return values(
      pick(Operators, OperatorMapping[subVariables.fieldType].operators)
    );
  };

  const isOperatorNullable = () =>
    ["null", "nnull"].includes(subVariables.operatorKey);

  const subRuleFields = () => {
    if (isEmpty(subVariables.field) || isOperatorNullable()) return null;

    if (subVariables.fieldType === "date") {
      if (subVariables.operator === "between")
        return (
          <div className="row">
            <div className="col-md-6">
              <label>
                <strong>Start</strong>
              </label>
              <DatePicker
                className="form-control"
                selected={subVariables.value[0]}
                scrollableYearDropdown={true}
                showYearDropdown={true}
                yearDropdownItemNumber={100}
                maxDate={new Date()}
                onChange={(date) =>
                  onFieldChange("value", [date, subVariables.value[1]])
                }
              />
            </div>
            <div className="col-md-6">
              <label>
                <strong>End</strong>
              </label>
              <DatePicker
                className="form-control"
                selected={subVariables.value[1]}
                scrollableYearDropdown={true}
                showYearDropdown={true}
                yearDropdownItemNumber={100}
                maxDate={new Date()}
                onChange={(date) =>
                  onFieldChange("value", [subVariables.value[0], date])
                }
              />
            </div>
          </div>
        );

      return (
        <div>
          <label>
            <strong>Value</strong>
          </label>
          <DatePicker
            className="form-control"
            selected={subVariables.value}
            scrollableYearDropdown={true}
            showYearDropdown={true}
            yearDropdownItemNumber={100}
            maxDate={new Date()}
            onChange={(date) => onFieldChange("value", date)}
          />
        </div>
      );
    }

    if (subVariables.operator === "between")
      return (
        <div className="row">
          <div className="col-md-6">
            <label>
              <strong>Start</strong>
            </label>
            <input
              className="form-control"
              value={subVariables.value[0]}
              type="number"
              onChange={(e) =>
                onFieldChange("value", [e.target.value, subVariables.value[1]])
              }
            />
          </div>
          <div className="col-md-6">
            <label>
              <strong>End</strong>
            </label>
            <input
              className="form-control"
              value={subVariables.value[1]}
              type="number"
              onChange={(e) =>
                onFieldChange("value", [subVariables.value[0], e.target.value])
              }
            />
          </div>
        </div>
      );

    return (
      <div>
        <label>
          <strong>Sub value</strong>
        </label>
        <input
          className="form-control"
          value={subVariables.value}
          type="text"
          onChange={(e) => onFieldChange("value", e.target.value)}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="col-md-12">
        <div>
          <select
            className="form-control"
            placeholder="Select field"
            value={JSON.stringify({
              label: subVariables.field,
              type: subVariables.fieldType,
            })}
            onChange={(e) => onVariableChange(e.target.value)}
          >
            <option>Select sub field</option>

            {findByValue(fieldOptions, selectedField).sub_fields.map(
              (field) => {
                return (
                  <option
                    key={field.value}
                    value={JSON.stringify({
                      label: field.value,
                      type: field.field_type,
                    })}
                  >
                    {field.label}
                  </option>
                );
              }
            )}
          </select>
        </div>

        <div>
          {subVariables.field && (
            <>
              <select
                className="form-control mt-3"
                value={subVariables.operatorKey}
                onChange={(e) => onOperatorChange(e.target.value)}
              >
                {operatorOptions().map((field) => {
                  return (
                    <option key={field.label} value={field.key}>
                      {field.label}
                    </option>
                  );
                })}
              </select>
            </>
          )}
        </div>
        {subRuleFields()}
      </div>
    </div>
  );
};

export default SubRule;
