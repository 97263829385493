import React, { useState, useEffect } from "react";
import BasicInfo from "./basic-info";
import Address from "./address";
import UserSection from "./users-section";
import StyleGuideLink from "../../incentives/common/style-guide-link";
import { createAdminCompany } from "modules/companies";
import { flashError, flashSuccessLater } from "../../../../lib/flash";
import { Spinner } from "react-bootstrap";

const NewCompany = ({ businessEntities, onboarding }) => {
  const [variables, setVariables] = useState({
    name: "",
    date_established: "",
    naics_code_ids: [],
    ein: "",
    description: "",
    accounting_software: "",
    duns_number: "",
    users: [],
    addresses: [],
    business_entity_id: "",
    is_active: true,
    company_category: "",
    onboarding_flow: "General",
    referral_source_id: null,
    referral_source_name: "",
  });

  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {}, [variables]);

  const onCreateCompany = () => {
    setIsCreating(true);
    createAdminCompany(variables)
      .then((res) => {
        flashSuccessLater("Company added");
        goToEditCompanyPage(res);
      })
      .catch((res) => {
        if (res.errors && Array.isArray(res.errors)) {
          res.errors.forEach((error) => {
            flashError(error);
          });
        } else {
          flashError("An unknown error occurred");
        }
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  const onFieldChange = (value, type) => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      [type]: value,
    }));
  };

  const onNaicsCodeSelect = (value, type) => {
    if (value) {
      let naicsCodeIds = value.map(function (naics) {
        return naics.value;
      });
      setVariables({
        ...variables,
        [type]: naicsCodeIds,
      });
    }
  };

  const goToEditCompanyPage = (id) => {
    window.location = `/admin/companies/${id}/edit`;
  };

  const goToCompaniesPage = () => (window.location = "/admin/companies");

  return (
    <div className="panel-body admin-company-form">
      <div className="row">
        <div className="col-lg-12">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <i
                className="bi bi-x"
                onClick={goToCompaniesPage}
                style={{
                  cursor: "pointer",
                  marginRight: "14px",
                  fontSize: "28px",
                }}
              ></i>
              <h3>Create New Company</h3>
            </div>
            <StyleGuideLink CTA="How to" guideType="createCompany" />
          </div>
          <BasicInfo
            variables={variables}
            onFieldChange={onFieldChange}
            businessEntities={businessEntities}
            onboarding={onboarding}
            onNaicsCodeSelect={onNaicsCodeSelect}
          />
          <Address variables={variables} onFieldChange={onFieldChange} />
          <UserSection variables={variables} onFieldChange={onFieldChange} />

          <div className="d-flex justify-content-end pt-5">
            <div className="btn-group">
              <button
                className="btn btn-light"
                onClick={goToCompaniesPage}
                disabled={isCreating}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary mx-1"
                onClick={onCreateCompany}
                disabled={isCreating}
              >
                {isCreating ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="ms-2">Creating company...</span>
                  </>
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCompany;
