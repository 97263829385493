import axios from "lib/axios";
import { encodeParams } from 'lib/utils';

export const fetchAdminOrganizations = (params = {}) => {
  const url = `/admin/organizations`;
  params = encodeParams(params);

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateAdminOrganization = (params = {}) => {
  const url = `/admin/organizations/${params.id}`;
  let formData = new FormData();

  for ( var key in params.organization ) {
    if(key === 'address_attributes') {
      for (let addressKey in params.organization[key]) {
        formData.append(`organization[address_attributes][${addressKey}]`, params.organization[key][addressKey]);
      }
    }
    else {
      formData.append(`organization[${key}]`, params.organization[key]);
    }
  }

  return axios
    .put(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const deleteOrganizationContact = (params = {}) => {
  const url = `/admin/organizations/${params.organization_id}/organization_contacts/${params.organization_contact.id}/`;

  return axios
    .delete(url, { data: params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const createOrganizationContact = (params = {}) => {
  const url = `/admin/organizations/${params.organization_id}/organization_contacts/`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateOrganizationContact = (params = {}) => {
  const url = `/admin/organizations/${params.organization_id}/organization_contacts/${params.organization_contact.id}/`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
