import React, { useState } from "react";
import { generalCalculatorV1 } from 'modules/incentive-calculator'
import { v4 as uuidv4 } from 'uuid';

import Question from './question'
import SliderInput from './slider-input'
import Total from './total'

const Index = (props) => {
  const [calculatorState, setCalculatorState] = useState(generalCalculatorV1.defaultState)
  const title = props.title || "Subcity Incentive Calculator";
  const updateValue = (key, value) => {
    setCalculatorState({
      ...calculatorState,
      [key]: {
        ...calculatorState[key],
        value: value,
      }
    })
  }

  const renderDisplayTree = (tree) => {
    if(!tree) return null;
    let nodes = [];

    for(var i = 0; i < tree.length; i++) {
      const leaf = tree[i];
      const currentValue = calculatorState[leaf.key].value;

      let children = [];
      if(leaf.children && leaf.children.length > 0) {
        children = renderDisplayTree(leaf.children)
      }

      if(leaf.component === 'Question') {
        nodes.push(
          React.createElement(
            Question,
            { 
              title: leaf.title,
              options: leaf.options,
              currentAnswer: currentValue,
              key: leaf.title,
              shouldRenderChild: leaf.shouldRenderChild(currentValue),
              updateCurrentAnswer: (value) => {
                updateValue(leaf.key, value)
              }
            },
            children
          )
        )
      } else if (leaf.component === 'SliderInput') { 
        nodes.push(
          React.createElement(
            SliderInput,
            { 
              key: leaf.title,
              title: leaf.title,
              currentValue: currentValue,
              unit: leaf.unit,
              maxValue: leaf.maxValue,
              minValue: leaf.minValue,
              shouldRenderChild: leaf.shouldRenderChild(currentValue),
              updateCurrentValue: (value) => {
                updateValue(leaf.key, value)
              }
            },
            children
          )
        )
      }
    }

    return nodes;
  }
  console.log(title)
  return (
    <>
      <div className='incentive-calculator-wrapper'>
        <h1 className='text-center'>{title}</h1>
        { renderDisplayTree(generalCalculatorV1.tree) }
      </div>
      <Total answers={calculatorState} calculator={generalCalculatorV1} />
    </>
  )
}

export default Index;
