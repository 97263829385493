import React from "react";
import PropTypes from "prop-types";
import { Route, Routes, Navigate } from "react-router-dom";

const UINavPanel = ({ children }) => {
  let defaultTab = children[0].props.tab;
  defaultTab = defaultTab.replace("*", "")

  return (
    <Routes>
      <Route exact path="/" element={<Navigate to={defaultTab} />} />
      {children.map((component, index) => {
        return (
          <Route path={component.props.tab} element={component} key={`route-${index}`} />
        );
      })}
    </Routes>
  );
};

export const UINavSection = (props) => {
  return <div className="ui-nav-section">{props.children}</div>;
};

UINavSection.propTypes = {
  children: PropTypes.node,
  __TYPE: PropTypes.string,
};

UINavSection.defaultProps = {
  __TYPE: "UINavSection",
};

export default UINavPanel;
