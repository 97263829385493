import React from "react";
import AsyncSelect from "react-select/async";
import { fetchNaicsCode } from "modules/user/companies";

const NaicsCodeSelector = (props) => {
  let defaultNaicsOption = [];

  if (props.naicsCodes) {
    for (var i = 0; i < props.naicsCodes.length; i++) {
      const code = props.naicsCodes[i];
      defaultNaicsOption.push({
        value: code === null ? "" : code.id,
        label: code === null ? "" : `${code.code} ${code.title}`,
      });
    }
  }

  const onOptionsLoad = (value, cb) => {
    fetchNaicsCode({ query: value }).then((res) => {
      cb(
        res.map((row) => ({
          value: row.id,
          label: `${row.code} (${row.title})`,
        }))
      );
    });
  };

  const onNaicsCodeSelect = (value) => {
    let naicsCodeIds = value.map(function (naics) {
      return naics.value;
    });
    props.onNaicsCodeSelect(naicsCodeIds);
  };

  //TODO: this is all over the place. figure out how to apply this in CSS.
  const defaultStyles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      padding: "0.75rem",
      border: "1px solid #e0e0e0",
    }),
  };

  const mergedStyles = {
    ...defaultStyles,
    ...props.customStyles,
  };

  return (
    <>
      <AsyncSelect
        styles={mergedStyles}
        isMulti
        defaultValue={defaultNaicsOption}
        loadOptions={onOptionsLoad}
        onChange={onNaicsCodeSelect}
        placeholder="Search NAICs codes..."
      />
    </>
  );
};

export default NaicsCodeSelector;
