import React from "react";

import CompanyPurchases from "./company-purchases";

const CompanyPurchaseRow = ({
  application,
  vendor_letter,
  currentUser,
  onSendVendorLetter,
}) => {
  const statusToClass = {
    pending_approval: "warning",
    delivered: "success",
    processing: "primary",
  };

  const statusToCheck =
    application.status === "applied"
      ? application.details.lob_status
      : application.status;

  const cssClass = statusToClass[statusToCheck] || "light";

  return (
    <>
      <td>{application.seller_name}</td>
      <td>{application.total}</td>
      <td>
        <span
          className={`d-inline px-2 py-1 text-${cssClass}-emphasis bg-${cssClass}-subtle border border-${cssClass}-subtle rounded-1`}
        >
          {application.status == "applied"
            ? application.details.lob_status
            : application.status}
        </span>
      </td>
      <td>
        {vendor_letter ? (
          <a
            className="btn btn-dark btn-sm btn-rounded"
            name={`vendor_letter_${application.id}`}
            href={vendor_letter.url}
            target="_blank"
          >
            <i className="me-1 bi bi-file-earmark-pdf"></i>
            {application.status == "applied" ? "Download" : "Preview"}
          </a>
        ) : (
          "Vendor not in California"
        )}
      </td>
      <td>
        <CompanyPurchases companyEquipments={application.company_purchases} />
      </td>
      <td>
        {currentUser.email.includes("subcity.com") &&
        currentUser.is_superuser ? (
          vendor_letter && application.status != "applied" ? (
            <button
              className="btn btn-action"
              onClick={(e) => {
                e.preventDefault();
                if (
                  window.confirm(
                    "Are you sure you're ready to send a letter to " +
                      application.seller_name +
                      " requesting " +
                      application.total +
                      "?\n\nIt'll take around ten seconds to regenerate the letter and send it, so hold tight!"
                  )
                ) {
                  onSendVendorLetter(application.id);
                }
              }}
            >
              Send letter
            </button>
          ) : (
            ""
          )
        ) : null}
      </td>
      <td>
        {application.details.lob_tracking_number ? (
          <a
            className="btn btn-sm btn-secondary"
            href={
              "https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=" +
              application.details.lob_tracking_number
            }
            target="_blank"
          >
            USPS Tracking
          </a>
        ) : null}
      </td>
    </>
  );
};

export default CompanyPurchaseRow;
