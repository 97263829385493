import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";

import { fetchAdminOrganizations } from "modules/organizations";
import {
  withUIPaginate,
  UIPaginationTotalSelector,
  UIPagination,
  UITableSortable,
} from "components/ui";

import { useSetState } from "react-hanger";
import { flashError, flashSuccess } from "lib/flash";
import AdminPanelHeader from "../../common/admin-panel-header";
import AdminPanelIndexControls from "../../common/admin-panel-index-controls";

const Organizations = (props) => {
  const [organizations, setOrganizations] = useState(props.organizations);
  const [distinctTargetTypes, setDistinctTargetTypes] = useState([]);
  const [distinctStage, setDistinctStage] = useState([]);
  const [distinctStatus, setDistinctStatus] = useState([]);
  const [distinctAssignedTo, setDistinctAssignedTo] = useState([]);
  const [selectedTargetTypes, setSelectedTargetTypes] = useState([]);
  const [selectedStage, setSelectedStage] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [isPriorityFilterActive, setIsPriorityFilterActive] = useState(false);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("ASC");

  const [flash] = useState(props.flash);

  const { state, setState } = useSetState({
    query: "",
    state: "",
    target_type: [],
    stage: [],
    status: [],
    assigned_to: [],
  });

  const { pagination } = props;

  const flashMessages = () => {
    if (flash) {
      flash.map((flashMessage) => {
        if (["notice", "success"].includes(flashMessage[0])) {
          flashSuccess(flashMessage[1]);
        } else {
          flashError(flashMessage[1]);
        }
      });
    }
  };

  const handlePriorityFilter = (e) => {
    e.preventDefault();
    setIsPriorityFilterActive(!isPriorityFilterActive);
  };

  const multiSelectConfigs = [
    {
      onChange: (selectedTargetTypes) => {
        setSelectedTargetTypes(selectedTargetTypes);
        const targetTypeValues = selectedTargetTypes.map((item) => item.value);
        setState({ target_type: targetTypeValues });
      },
      options: distinctTargetTypes.map((type) => ({
        value: type,
        label: type,
      })),
      value: selectedTargetTypes,
      className: "ui-multi-filter",
      placeholder: "Target Type",
    },
    {
      onChange: (selectedStage) => {
        setSelectedStage(selectedStage);
        const stageValues = selectedStage.map((item) => item.value);
        setState({ stage: stageValues });
      },
      options: distinctStage.map((stage) => ({
        value: stage,
        label: stage,
      })),
      value: selectedStage,
      className: "ui-multi-filter",
      placeholder: "Stage",
    },
    {
      onChange: (selectedStatus) => {
        setSelectedStatus(selectedStatus);
        const statusValues = selectedStatus.map((item) => item.value);
        setState({ status: statusValues });
      },
      options: distinctStatus.map((status) => ({
        value: status,
        label: status,
      })),
      value: selectedStatus,
      className: "ui-multi-filter",
      placeholder: "Status",
    },
    {
      onChange: (selectedAssignedTo) => {
        setSelectedAssignedTo(selectedAssignedTo);
        const assignedToValues = selectedAssignedTo.map((item) => item.value);
        setState({ assigned_to: assignedToValues });
      },
      options: distinctAssignedTo.map((assignedTo) => ({
        value: assignedTo[0],
        label: `${assignedTo[1]} ${assignedTo[2]}`,
      })),
      value: selectedAssignedTo,
      className: "ui-multi-filter",
      placeholder: "Assigned To",
    },
  ];

  const buttonGroupConfigs = [
    {
      href: "#",
      className: isPriorityFilterActive ? "btn-subdued-active" : "btn-subdued",
      onClick: (e) => handlePriorityFilter(e),
      label: "Priority",
    },
  ];

  const stateSelectorConfig = {
    value: state.state,
    placeholder: "State",
    onChange: (newState) => {
      if (newState === "All") {
        setState({ state: "" });
      } else {
        setState({ state: newState });
      }
    },
  };

  const handleSort = (newColumn) => {
    const newDirection =
      sortColumn === newColumn && sortDirection === "ASC" ? "DESC" : "ASC";
    setSortColumn(newColumn);
    setSortDirection(newDirection);
  };

  useEffect(() => {
    fetchOrganizations();
  }, [state, sortColumn, sortDirection, isPriorityFilterActive]);

  useEffect(() => {
    flashMessages();
  });

  useEffect(() => {
    if (pagination.first_load) return;

    fetchOrganizations();
  }, [pagination]);

  const fetchOrganizations = () => {
    const params = {
      ...pagination,
      ...state,
      is_priority: isPriorityFilterActive,
      sort_column: sortColumn,
      sort_direction: sortDirection,
    };

    fetchAdminOrganizations(params).then((data) => {
      if (!data.success) {
        return flashError(data.message);
      }
      props.updatePagination({
        total: data.total,
        first_load: true,
      });

      setOrganizations(data.organizations);
      setDistinctTargetTypes(data.distinct_target_types);
      setDistinctStatus(data.distinct_status);
      setDistinctStage(data.distinct_stages);
      setDistinctAssignedTo(data.distinct_assigned_to);
    });
  };

  return (
    <>
      <AdminPanelHeader
        title="Organizations"
        singularTitle="Organization"
        description="An organization is any outside entity affiliated with a program or service or an institution that partners with Subcity to serve clients."
        addNewUrl="/admin/organizations/new"
        exportCsvUrl="/download/organizations.csv"
      />
      <AdminPanelIndexControls
        keywordSearchConfig={{
          state: state,
          setState: setState,
          updatePagination: props.updatePagination,
        }}
        stateSelectorConfig={stateSelectorConfig}
        multiSelectConfigs={multiSelectConfigs}
        buttonGroupConfigs={buttonGroupConfigs}
      />
      <div className="px-3">
        <div className="panel-table">
          <table className="table admin-table">
            <thead>
              <tr>
                <th
                  style={{ width: "3%", cursor: "pointer" }}
                  onClick={() => handleSort("id")}
                >
                  Id
                </th>
                <th style={{ width: "11%" }}></th>
                <th
                  style={{ width: "25%", cursor: "pointer" }}
                  onClick={() => handleSort("name")}
                >
                  Organization Details
                </th>
                <th
                  style={{ width: "5%", cursor: "pointer" }}
                  onClick={() => handleSort("total_incentives")}
                >
                  Programs
                </th>
                <th
                  style={{ width: "10%", cursor: "pointer" }}
                  onClick={() => handleSort("status")}
                >
                  Status
                </th>
                <th
                  style={{ width: "18%", cursor: "pointer" }}
                  onClick={() => handleSort("last_action")}
                >
                  Last Action
                </th>
                <th style={{ width: "9%" }}></th>
                <th style={{ width: "15%" }}>Assigned To</th>
                <th style={{ width: "5%" }}></th>
              </tr>
            </thead>
            <tbody>
              {organizations.map((organization, index) => {
                return (
                  <tr
                    key={`organization-row-${index}`}
                    className="row-with-shadow"
                  >
                    <td className="admin-metadata">{organization.id}</td>
                    <td width="70px">
                      {organization.image && (
                        <img
                          src={organization.image}
                          className="ms-2 py-1"
                          width="60px"
                        />
                      )}
                    </td>
                    <td>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip className="cashflow-tooltip">
                            {organization.index_organization_contacts}
                          </Tooltip>
                        }
                      >
                        <p className="mb-0">
                          {organization.name}{" "}
                          {organization.has_proposal && (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip className="cashflow-tooltip">
                                  Proposal sent
                                </Tooltip>
                              }
                            >
                              <i
                                className="bi bi-file-earmark-text"
                                style={{
                                  color: "#6A7894",
                                  paddingLeft: "4px",
                                }}
                              ></i>
                            </OverlayTrigger>
                          )}
                          {organization.has_signed_mou && (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip className="cashflow-tooltip">
                                  Signed MOU
                                </Tooltip>
                              }
                            >
                              <i
                                className="bi bi-file-earmark-check"
                                style={{
                                  color: "#00A2E9",
                                  paddingLeft: "4px",
                                }}
                              ></i>
                            </OverlayTrigger>
                          )}
                          {organization.target_priority === "High" && (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip className="cashflow-tooltip">
                                  High Priority
                                </Tooltip>
                              }
                            >
                              <i
                                className="bi bi-star-fill"
                                style={{
                                  color: "#E22947",
                                  paddingLeft: "4px",
                                }}
                              ></i>
                            </OverlayTrigger>
                          )}
                        </p>
                      </OverlayTrigger>
                      <span className="admin-metadata mb-0">
                        {organization.entity_name}{" "}
                        {organization.website && (
                          <a
                            href={organization.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={organization.website}
                          >
                            (URL)
                          </a>
                        )}
                      </span>
                    </td>

                    <td>{organization.total_incentives}</td>
                    <td>{organization.status}</td>
                    <td>{organization.last_action_name}</td>
                    <td>
                      {organization.last_action
                        ? moment(organization.last_action).fromNow()
                        : ""}
                    </td>
                    <td>{organization.assigned_to}</td>
                    <td>
                      <a
                        href={`/admin/organizations/${organization.id}/edit`}
                        className="btn btn-sm btn-outline-secondary action-buttons"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td colSpan={2}>
                  <div className="mt-2">
                    <UIPaginationTotalSelector
                      perPage={pagination.per_page}
                      changePerPage={(value) => {
                        props.updatePagination({
                          per_page: value,
                          first_load: false,
                        });
                      }}
                    />
                  </div>
                </td>

                <td colSpan={6}>
                  <span className="float-end mt-2">
                    {pagination.total > 0 ? (
                      <UIPagination
                        page={pagination.page}
                        perPage={pagination.per_page}
                        changePage={(page) => {
                          props.updatePagination({
                            page: page.selected + 1,
                            first_load: false,
                          });
                        }}
                        total={pagination.total}
                      />
                    ) : null}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default withUIPaginate(Organizations);
