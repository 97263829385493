import React, { useState, useEffect, useRef } from "react";
import { useSetState } from "react-hanger";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { map } from "lodash";
import moment from "moment";
import { snakeCase, find } from "lodash";

import {
  withUIPaginate,
  UIPaginationTotalSelector,
  UIPagination,
  UITableSortable,
} from "components/ui";
import AdminPanelHeader from "../../common/admin-panel-header";
import AdminPanelIndexControls from "../../common/admin-panel-index-controls";
import {
  fetchAdminIncentives,
  fetchAdminIncentiveTypes,
  fetchAllTags,
} from "modules/incentives";
import IncentiveIssues from "./common/incentive-issues";
import { setURLParams, getUrlParams } from "lib/utils";

const Incentives = (props) => {
  const statusOptions = [
    { value: "", label: "All" },
    { value: "active", label: "Active" },
    { value: "visible", label: "Visible" },
    { value: "draft", label: "Draft" },
    { value: "paused", label: "Paused" },
    { value: "completed", label: "Completed" },
    { value: "reviewing", label: "Reviewing" },
  ];

  const getStatusPill = (status) => {
    const statusToClass = {
      active: "success",
      visible: "primary",
      draft: "warning",
      paused: "secondary",
      completed: "light",
      reviewing: "danger",
    };

    const cssClass = statusToClass[status] || "light";

    return (
      <div
        className={`d-inline px-2 py-1 text-${cssClass}-emphasis bg-${cssClass}-subtle border border-${cssClass}-subtle rounded-1`}
      >
        <span className="fw-normal">{status}</span>
      </div>
    );
  };

  const { pagination } = props;
  const [incentives, setIncentives] = useState(props.incentives);
  const [typeOptions, setTypeOptions] = useState([]);
  const [regionType, setRegionType] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);

  const url = new URL(window.location);

  const setSelectedTypes = () => {
    return map(props.selectedTypes, (type) => ({
      value: type.id,
      label: type.name,
    }));
  };

  const { state, setState } = useSetState({
    query: getUrlParams(url, "query") || "",
    sortColumn: getUrlParams(url, "sortColumn") || "name",
    sortDirection: getUrlParams(url, "sortDirection") || "ASC",
    status: find(statusOptions, { value: getUrlParams(url, "status") }) || "",
    state: getUrlParams(url, "state") || "",
    types: setSelectedTypes(),
    tags: getUrlParams(url, "tags") ? getUrlParams(url, "tags").split(",") : [],
  });

  const isFirstRun = useRef(true);

  const keywordSearchConfig = {
    state: state,
    setState: setState,
    updatePagination: props.updatePagination,
  };

  const multiSelectConfigs = [
    {
      options: statusOptions,
      value: state.status,
      isClearable: true,
      className: "ui-multi-filter",
      placeholder: "Status",
      onChange: (option) => setState({ status: option }),
    },
    {
      options: typeOptions,
      value: state.types,
      className: "ui-multi-filter",
      placeholder: "Type",
      onChange: (options) => setState({ types: options }),
    },
    {
      options: tagOptions,
      value: selectedTags,
      className: "ui-multi-filter",
      placeholder: "Tags",
      onChange: (options) => setSelectedTags(options),
    },
  ];

  const stateSelectorConfig = {
    value: state.state,
    placeholder: "State",
    onChange: (newState) => {
      if (newState === "All") {
        setState({ state: "" });
      } else {
        setState({ state: newState });
      }
    },
  };

  const buttonGroupConfigs = [
    {
      href: "#",
      className: regionType === "local" ? "btn-subdued-active" : "btn-subdued",
      label: "Local",
      onClick: () => setRegionType(regionType === "local" ? "" : "local"),
    },
    {
      href: "#",
      className: regionType === "state" ? "btn-subdued-active" : "btn-subdued",
      label: "State",
      onClick: () => setRegionType(regionType === "state" ? "" : "state"),
    },
    {
      href: "#",
      className:
        regionType === "federal" ? "btn-subdued-active" : "btn-subdued",
      label: "Federal",
      onClick: () => setRegionType(regionType === "federal" ? "" : "federal"),
    },
  ];

  useEffect(() => {
    callFetchIncentives();
  }, []);

  useEffect(() => {
    fetchAllTags()
      .then((tags) => {
        const formattedTags = tags.map((tag) => ({ value: tag, label: tag }));
        setTagOptions(formattedTags);
      })
      .catch((error) => {
        console.error("Error fetching tags:", error);
      });
  }, []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    callFetchIncentives();

    setURLParams(state, pagination);
  }, [state, regionType, selectedTags]);

  useEffect(() => {
    if (pagination.first_load) return;

    callFetchIncentives();
    setURLParams(state, pagination);
  }, [pagination]);

  useEffect(() => {
    fetchAdminIncentiveTypes({ all: true }).then((data) => {
      let opts = [];
      let selectedTypes = [];
      for (var i = 0; i < data.length; i++) {
        opts.push({
          value: data[i].id,
          label: data[i].name,
        });

        if (state.types.includes(data[i]))
          selectedTypes.push({
            value: data[i].id,
            label: data[i].name,
          });
      }

      setTypeOptions(opts);
    });
  }, []);

  const fetchIncentives = (params = {}) => {
    fetchAdminIncentives(params).then((data) => {
      props.updatePagination({
        total: data.pagination.total,
        first_load: true,
      });

      setIncentives(data.incentives);
    });
  };

  const onSortChange = (direction, column) => {
    setState({
      sortDirection: direction,
      sortColumn: column,
    });
  };

  const callFetchIncentives = () => {
    fetchIncentives({
      ...state,
      region_type: regionType,
      ...pagination,
      sort_column: snakeCase(state.sortColumn),
      sort_direction: state.sortDirection,
      status: state.status?.value || "",
      types: map(state.types, "value"),
      tags: map(selectedTags, "value"),
    });
  };

  return (
    <>
      <AdminPanelHeader
        title="Incentives"
        singularTitle="Incentive"
        addNewUrl="/admin/incentives/new"
        exportCsvUrl="/download/incentives.csv"
      />
      <AdminPanelIndexControls
        keywordSearchConfig={keywordSearchConfig}
        buttonGroupConfigs={buttonGroupConfigs}
        multiSelectConfigs={multiSelectConfigs}
        stateSelectorConfig={stateSelectorConfig}
      />
      <div className="px-3">
        <div className="panel-table">
          <table className="table admin-table">
            <thead>
              <tr>
                <th className="desktop-only">
                  <UITableSortable
                    title="Id"
                    sortColumn="id"
                    sortDirection={state.sortDirection}
                    currentSortColumn={state.sortColumn}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width="600px">
                  <UITableSortable
                    title="Name"
                    sortColumn="name"
                    sortDirection={state.sortDirection}
                    currentSortColumn={state.sortColumn}
                    onSortChange={onSortChange}
                  />
                </th>
                <th className="desktop-only">
                  <UITableSortable
                    title="Issues"
                    sortColumn="broken_rank"
                    sortDirection={state.sortDirection}
                    currentSortColumn={state.sortColumn}
                    onSortChange={onSortChange}
                  />
                </th>
                <th>
                  <UITableSortable
                    title="Status"
                    sortColumn="status"
                    sortDirection={state.sortDirection}
                    currentSortColumn={state.sortColumn}
                    onSortChange={onSortChange}
                  />
                </th>
                <th className="desktop-only">
                  <UITableSortable
                    title="Updated"
                    sortColumn="updated_at"
                    sortDirection={state.sortDirection}
                    currentSortColumn={state.sortColumn}
                    onSortChange={onSortChange}
                  />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {incentives.map((incentive, index) => {
                return (
                  <tr
                    className="row-with-shadow"
                    key={`incentive-row-${index}`}
                  >
                    <td className="admin-metadata desktop-only">
                      {incentive.id}
                    </td>
                    <OverlayTrigger
                      key={`overlay-trigger-${incentive.id}`}
                      placement="right"
                      delay={{ show: 150, hide: 200 }}
                      overlay={
                        <Tooltip
                          id="button-tooltip"
                          className="cashflow-tooltip"
                        >
                          <strong>
                            {incentive.incentive_type
                              ? incentive.incentive_type.name
                              : ""}{" "}
                            ({incentive.sponsoring_organization_name})
                          </strong>
                          <hr />
                          {incentive.short_description}
                        </Tooltip>
                      }
                    >
                      <td>
                        <span
                          style={{
                            color:
                              incentive.status === "completed"
                                ? "#6A7894"
                                : "inherit",
                          }}
                        >
                          {incentive.name}
                        </span>
                        {incentive.has_intake_form && (
                          <OverlayTrigger
                            key={`intake-form-overlay-trigger-${incentive.id}`}
                            placement="bottom"
                            delay={{ show: 150, hide: 200 }}
                            overlay={
                              <Tooltip
                                id="button-tooltip"
                                className="cashflow-tooltip"
                              >
                                Has intake form
                              </Tooltip>
                            }
                          >
                            <i
                              className="bi bi-check-circle-fill"
                              style={{ color: "#00A2E9", marginLeft: "8px" }}
                              title="Has intake form"
                            ></i>
                          </OverlayTrigger>
                        )}
                        {incentive.region_type === "federal" && (
                          <OverlayTrigger
                            key={`fed-icon-overlay-trigger-${incentive.id}`}
                            placement="bottom"
                            delay={{ show: 150, hide: 200 }}
                            overlay={
                              <Tooltip
                                id="button-tooltip"
                                className="cashflow-tooltip"
                              >
                                Federal Program
                              </Tooltip>
                            }
                          >
                            <i
                              className="bi bi-shield-fill-plus"
                              style={{ color: "#6A7894", marginLeft: "8px" }}
                              title="Federal Program"
                            ></i>
                          </OverlayTrigger>
                        )}
                      </td>
                    </OverlayTrigger>
                    <td className="admin-metadata desktop-only">
                      <IncentiveIssues
                        incentive={incentive}
                        key={`indentive-issues-${incentive.id}`}
                      />
                    </td>
                    <td>{getStatusPill(incentive.status)}</td>
                    <td
                      className="desktop-only"
                      style={{
                        color:
                          incentive.status === "completed"
                            ? "#6A7894"
                            : "inherit",
                      }}
                    >
                      {incentive.updated_at
                        ? moment(incentive.updated_at).format("MMM DD, YYYY")
                        : null}
                    </td>
                    <td style={{ whiteSpace: "nowrap", width: "1%" }}>
                      <div className="d-flex justify-content-end">
                        <a
                          href={`/admin/incentives/${incentive.id}/edit#/basic-info`}
                          className="btn btn-sm btn-outline-secondary action-buttons"
                        >
                          Edit
                        </a>
                      </div>
                    </td>
                  </tr>
                );
              })}
              <tr className="pagination-row">
                <td colSpan={2}>
                  <div className="mt-2 pagination-selector">
                    <UIPaginationTotalSelector
                      perPage={pagination.per_page}
                      changePerPage={(value) => {
                        props.updatePagination({
                          per_page: value,
                          first_load: false,
                        });
                      }}
                    />
                  </div>
                </td>

                <td colSpan={5}>
                  <span className="float-end mt-2 pagination-controls">
                    {pagination.total > 0 ? (
                      <UIPagination
                        page={pagination.page}
                        perPage={pagination.per_page}
                        changePage={(page) => {
                          props.updatePagination({
                            page: page.selected + 1,
                            first_load: false,
                          });
                        }}
                        total={pagination.total}
                      />
                    ) : null}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default withUIPaginate(Incentives);
