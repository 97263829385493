import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import Slider from '@mui/material/Slider';

const SliderInput = (props) => {
  const maxValue = props.maxValue || 10000;
  const minValue = props.minValue || 0;

  var nf = new Intl.NumberFormat();
  let value = props.currentValue || 0; 
  value = nf.format(value);

  function calculateValue(value) {
    const b = 100.0 / 3;
    const c = Math.log(16.0);

    return b * (Math.pow(c * value, Math.E) - 1.0);
  }

  const getText = (value) => {
    if(props.unit === 'currency') {
      return `$${value}`
    } else {
      return value;
    }
  }

  return (
    <>
    <div className='question'>
      <div className='row'>
        <div className='col-md-6'>
          <p className='title'>{ props.title }</p>
        </div>

        <div className='col-md-6'>
          {
            props.unit === 'currency' ?
              <CurrencyInput
                id="currency-input"
                className="form-control"
                placeholder="0.00"
                prefix="$"
                defaultValue={props.currentValue || ""}
                value={props.currentValue}
                decimalsLimit={2}
                onValueChange={(value) => {
                  if(!/[0-9]/.test(value)) return;
                  props.updateCurrentValue(parseInt(value))
                }}
              /> : 
              <input 
                type="text" 
                className='form-control' 
                value={value}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(event) => {
                  let value = event.target.value;
                  
                  if(value === "") {
                    props.updateCurrentValue(0)
                    return; 
                  }

                  value = value.replace(/,/g, '');
                  props.updateCurrentValue(parseInt(value))
                }} />
          }
          <div className='mt-3'>
            <Slider
              defaultValue={props.currentValue || 0} 
              value={props.currentValue}
              aria-label="Default" 
              min={minValue}
              max={maxValue}
              getAriaValueText={getText}
              valueLabelDisplay="auto"
              marks={[{
                value: minValue,
                label: nf.format(minValue)
              }, {
                value: maxValue,
                label: nf.format(maxValue)
              }]}
              onChange={(event) => {
                const value = event.target.value;
                if (value > maxValue) {
                  return;
                }

                props.updateCurrentValue(value)
              }}
              valueLabelDisplay="auto"
              sx={{
                color: '#00A2E9',
              }}
            />
          </div>
        </div>
      </div>
    </div>
    { props.shouldRenderChild ? props.children : null }
    </>
  )
}

export default SliderInput;
