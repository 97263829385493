import React from 'react';
import { prettyDisplayPrice } from 'lib/string'

const AllocatedLineItemRow = ({ item, renderDiff }) => {
  return (
    <tr style={{'borderLeft': '5px solid #fff'}}>
      <td>{item.description}</td>
      <td>{item.unit}</td>
      <td>{prettyDisplayPrice(item.unit_amount)}</td>
      <td>{item.quantity}</td>
      <td>{prettyDisplayPrice(item.total_amount)}</td>
      <td>
        { renderDiff(item.total_diff, item.percent_diff) }
      </td>
    </tr>
  )
}

export default AllocatedLineItemRow;