import React, { useState, useEffect } from "react";

import { UIFormLabel } from "components/ui";

import IncentiveUrls from "./index.jsx";
import IncentiveUrlRow from "../common/incentive-urls/incentive-url-row"
import AddNewIncentiveURL from "../common/incentive-urls/new"

import {
  createIncentiveUrl,
  updateIncentiveUrl,
  deleteIncentiveUrl,
} from "modules/incentive-urls";

import { flashSuccess, flashWarning } from "lib/flash";


const OnlineResources = (props)  => {
  const [incentive, setIncentive] = useState(props.incentive)

  const removeURL = (deletedURL) => {
    let deleteIncentive = incentive.incentive_urls.filter(url => {
      return url.id = deletedURL.id
    })
    incentive.incentive_urls = deleteIncentive
    setIncentive(incentive)
  }

  const addIncentiveURL = (props) => {
    let updateIncentive = [...incentive.incentive_urls, props.incentive]
    incentive.incentive_urls = updateIncentive
    setIncentive(incentive)
  }
  
  return (
    <div>
      <AddNewIncentiveURL
        incentiveId={incentive.id}
        addIncentiveURL={addIncentiveURL}
      />
      
      <div className="table-responsive">
        <table className='table'>
          <thead>
            <tr>
              <th>Label</th>
              <th>URL</th>
              <th>Status</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {incentive.incentive_urls.map((url,index) => {
              return (
                <IncentiveUrlRow
                  incentiveId={url.incentive_id}
                  key={`incentive-url-row-${index}-${url.id}`}
                  url={url}
                  removeURL={removeURL}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );

}

export default OnlineResources;
