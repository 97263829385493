import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Sponsors = ({ incentive }) => {
  return (
    <div className='sponsors d-flex'>
      <a
        href={incentive.sponsoring_organization.website}
        target="_blank"
        className='d-flex-column'
      >
        {(incentive.sponsoring_organization.logo && (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="incentive-description-tooltip">
                {incentive.sponsoring_organization.name}
              </Tooltip>
            }
          >
            <img
              data-tip
              width={100}
              data-for="sponsoringTip"
              src={incentive.sponsoring_organization.logo}
              alt={incentive.sponsoring_organization.name}
              className="logo-dimension"
            />
          </OverlayTrigger>
        )) ||
          incentive.sponsoring_organization.name}
      </a>

      {incentive.show_approving_organization && (
        <a
          href={incentive.approving_organization.website}
          target="_blank"
          className='d-flex-column ms-5'
        >
          {(incentive.approving_organization.logo && (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="incentive-description-tooltip">
                  {incentive.approving_organization.name}
                </Tooltip>
              }
            >
              <img
                width={100}
                src={incentive.approving_organization.logo}
                alt={incentive.approving_organization.name}
                className="logo-dimension"
              />
            </OverlayTrigger>
          )) ||
            incentive.approving_organization.name}
        </a>
      )}
    </div>
  )
}

export default Sponsors;