import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";

import { useSetState } from "react-hanger";
import { map, snakeCase, find } from "lodash";

import ReviewPurchase from "./review-purchase";

import {
  UIBigNumberCard,
  withUIPaginate,
  UIPagination,
  UITableSortable,
  KeywordSearch,
} from "components/ui";

import {
  fetchAllEquipments,
  unrejectCompanyPurchase,
} from "modules/company_purchases";

import { prettyDisplayPrice } from "lib/string";
import { setURLParams, getUrlParams } from "lib/utils";

const ListPurchase = (props) => {
  const {
    pagination,
    companyPurchases,
    purchaser,
    currentUser,
    equipmentConstraints,
    incentive,
  } = props;

  const url = new URL(window.location);

  const statusOptions = [
    { value: "0", label: "Eligible" }, // The numbers correspond to the status in the database
    { value: "3", label: "Rejected" },
  ];

  const { state, setState } = useSetState({
    query: getUrlParams(url, "query") || "",
    sortColumn: getUrlParams(url, "sortColumn") || "total_paid",
    sortDirection: getUrlParams(url, "sortDirection") || "DESC",
    status: find(statusOptions, { value: getUrlParams(url, "status") }) || "",
  });

  const AVERAGE_CA_SALES_TAX = 0.0725;
  const REBATE_PROPORTION = 0.039375;

  let [purchaseShow, setPurchaseShow] = useState({});

  const initializePurchasesShow = (purchases) => {
    setPurchaseShow(
      purchases.reduce((result, purchase) => {
        return { ...result, [purchase.id]: false };
      }, {})
    );
  };

  useEffect(() => {
    callFetchCompanyPurchases();

    setURLParams(state, pagination);
  }, [state]);

  useEffect(() => {
    if (pagination.first_load) return;

    callFetchCompanyPurchases();
    setURLParams(state, pagination);
  }, [pagination]);

  let purchaseRows = [];

  const [purchases, setPurchases] = useState(companyPurchases);

  const onSortChange = (direction, column) => {
    setState({
      sortDirection: direction,
      sortColumn: column,
    });
  };

  const callFetchCompanyPurchases = () => {
    console.log("fetching...");
    fetchEquipments({
      ...state,
      ...pagination,
      sort_column: snakeCase(state.sortColumn),
      sort_direction: state.sortDirection,
      status: state.status?.value || 0,
    });
  };

  const onUnreject = (id) => {
    unrejectCompanyPurchase(id)
      .then((res) => {
        location.reload();
      })
      .catch((errors) => {
        errors.map((err) => {
          flashError(`There was an error: ${err}`);
        });
      });
  };

  const fetchEquipments = (params) => {
    console.log(params);

    fetchAllEquipments(params).then((data) => {
      props.updatePagination({
        total: data.total,
        first_load: true,
      });

      setPurchases(data.equipments);
      initializePurchasesShow(data.equipments);
    });
  };

  purchases.forEach((purchase, index) => {
    purchaseRows.push(
      <tr key={`user-row-${purchase.id}-${index}`}>
        <td>{purchase.seller.name}</td>
        <td>{purchase.description}</td>
        <td>{prettyDisplayPrice(parseFloat(purchase.total_paid))}</td>
        <td>
          {prettyDisplayPrice(
            parseFloat(purchase.total_paid * (1 - AVERAGE_CA_SALES_TAX)) *
              REBATE_PROPORTION
          )}
        </td>
        <td>{purchase.acquisition_date || "-"}</td>
        <td>
          {purchase.status == "rejected" ? (
            <button
              className="btn btn-danger btn-sm"
              name={`collpase_purchase_row_${purchase.id}`}
              onClick={(e) => {
                e.preventDefault();

                onUnreject(purchase.id);
              }}
            >
              Restore
            </button>
          ) : (
            <div
              className="chevron-button"
              name={`collpase_purchase_row_${purchase.id}`}
              onClick={(e) => {
                e.preventDefault();
                if (purchaseShow[purchase.id]) {
                  setPurchaseShow({
                    ...purchaseShow,
                    [purchase.id]: false,
                  });
                } else {
                  setPurchaseShow({
                    ...purchaseShow,
                    [purchase.id]: true,
                  });
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <span>
                {!purchaseShow[purchase.id] ? (
                  <i className="bi bi-chevron-down"></i>
                ) : (
                  <i className="bi bi-chevron-up"></i>
                )}
              </span>
            </div>
          )}
        </td>
      </tr>
    );

    if (purchaseShow[purchase.id]) {
      purchaseRows.push(
        <tr key={`user-row-${purchase.id}-${index}-show`}>
          <td colSpan="6" className="p-0">
            <ReviewPurchase
              key={`review-purchase-${purchaseShow[purchase.id]}-${index}`}
              purchaser={purchaser}
              user={currentUser}
              purchase={purchase}
              constraints={equipmentConstraints}
              incentive={incentive}
            />
          </td>
        </tr>
      );
    }
  });

  return (
    <>
      <div className="px-3">
        <div className="row mb-3 align-items-center">
          <div className="col-md-6" style={{ padding: "4px" }}>
            <KeywordSearch
              state={state}
              setState={setState}
              updatePagination={props.updatePagination}
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <div className="ui-multi-filter">
              <div className="me-3">
                <Select
                  options={statusOptions}
                  defaultValue={
                    find(statusOptions, { value: getUrlParams(url, "status") })
                      ?.label || "Eligible"
                  }
                  value={state.status}
                  className="ui-multi-filter"
                  placeholder="Status"
                  onChange={(option) => setState({ status: option })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3">
        <div className="admin-table">
          <table className="table">
            <thead>
              <tr>
                <th width="250px">
                  <UITableSortable
                    title="Vendor"
                    sortColumn="vendor_name"
                    sortDirection={state.sortDirection}
                    currentSortColumn={state.sortColumn}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width="400px">
                  <UITableSortable
                    title="Description"
                    sortColumn="description"
                    sortDirection={state.sortDirection}
                    currentSortColumn={state.sortColumn}
                    onSortChange={onSortChange}
                  />
                </th>
                <th>
                  <UITableSortable
                    title="Total Paid"
                    sortColumn="total_paid"
                    sortDirection={state.sortDirection}
                    currentSortColumn={state.sortColumn}
                    onSortChange={onSortChange}
                  />
                </th>
                <th>
                  <UITableSortable
                    title="Estimated Exemption"
                    sortColumn="sales_tax_paid"
                    sortDirection={state.sortDirection}
                    currentSortColumn={state.sortColumn}
                    onSortChange={onSortChange}
                  />
                </th>
                <th>
                  <UITableSortable
                    title="Date"
                    sortColumn="acquisition_date"
                    sortDirection={state.sortDirection}
                    currentSortColumn={state.sortColumn}
                    onSortChange={onSortChange}
                  />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {purchaseRows}
              <tr>
                <td colSpan={6}>
                  <span className="float-end mt-2">
                    {pagination.total > 0 ? (
                      <UIPagination
                        page={pagination.page}
                        perPage={pagination.per_page}
                        changePage={(page) => {
                          props.updatePagination({
                            page: page.selected + 1,
                            first_load: false,
                          });
                        }}
                        total={pagination.total}
                      />
                    ) : null}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default withUIPaginate(ListPurchase);
