// Valid EIN prefixes from IRS: https://www.irs.gov/businesses/small-businesses-self-employed/how-eins-are-assigned-and-valid-ein-prefixes
const invalid_ein_prefixes = ['00', '07', '08', '09', '17', '18', '19', '28', '29', '49', '69', '70', '78', '79', '89', '96', '97'];

export const isCompanyUpdateValid = (company) => {
  const errors = validateCompanyFields(company);
  for (const error of Object.values(errors)) {
    if (error) {
      return false;
    }
  }

  return true;
};

export const validateCompanyFields = (company, isUserAdmin=false) => {
  let error = {};

  if (isUserAdmin) {
    // Commission Rate range of 0 to 25% was a business decision.
    if (company.commission_rate > 25) {
      error['commission_rate'] = 'Commission rate cannot be higher than 25%';
    } else if (company.commission_rate < 0) {
      error['commission_rate'] = 'Commission rate cannot be negative';
    } else if (isNaN(company.commission_rate)) {
      error['commission_rate'] = 'Commission rate must be a number';
    } else {
      error['commission_rate'] = ""
    }
  }

  if (isNaN(company.number_of_employees)) {
    error['number_of_employees'] = 'Number of employees must be formatted as a number';
  } else if(company.number_of_employees < 0) {
    error['number_of_employees'] = 'Number of employees must be a positive number'
  } else {
    error['number_of_employees'] = ""
  }

  if (isNaN(company.annual_revenue)) {
    error['annual_revenue'] = 'Gross Annual Revenue must be formatted as a number';
  } else {
    error['annual_revenue'] = ""
  }

  return error;
};
