import React from "react";
import EstimateCategoryManager from "./estimate-category-manager";

const AddCategory = (props) => {
  if (props.showCategoryPicker) {
    return (
      <EstimateCategoryManager
        categories={props.categories}
        updateCategories={props.setCategories}
        company_project_id={props.projectId}
        handleCancel={() => {
          props.setCategories([]);
          props.setShowCategoryPicker(false);
        }}
        handleSave={props.saveCategories}
      />
    );
  } else {
    return (
      <button
        className="btn-attach float-end"
        onClick={(e) => {
          e.preventDefault();
          props.setShowCategoryPicker(true);
        }}
      >
        Add Category
      </button>
    );
  }
};

export default AddCategory;
