import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  UILabelInput,
  UIFormLabel,
  UIAddressInputWithSuggestions,
} from "components/ui";
import { State } from "country-state-city";

const EMPTY_ERROR = "This field should not be empty.";

const AddressInput = ({ onFieldChange: onFinalAddress }) => {
  const [address, setAddress] = useState({
    id: null,
    address_line_1: "",
    address_line_2: "",
    city: "",
    county: "",
    state: "",
    postal_code: "",
    country: "",
    is_primary: true,
  });

  const [formErrors, setFormErrors] = useState({});

  const validateForm = useCallback(() => {
    const errors = {};
    const requiredFields = [
      "address_line_1",
      "city",
      "state",
      "postal_code",
      "country",
    ];

    requiredFields.forEach((field) => {
      if (!address[field]) {
        errors[field] = EMPTY_ERROR;
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, [address]);

  const handleFieldChange = useCallback((value, field) => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));
  }, []);

  useEffect(() => {
    if (address.address_line_1 && validateForm()) {
      onFinalAddress([address], "addresses");
    }
  }, [address, validateForm, onFinalAddress]);

  return (
    <div>
      <AddressLine1Input
        value={address.address_line_1}
        onChange={handleFieldChange}
        onSelectSuggestedAddress={setAddress}
        error={formErrors.address_line_1}
      />
      <AddressLine2Input
        value={address.address_line_2}
        onChange={handleFieldChange}
      />
      <CityInput
        value={address.city}
        onChange={handleFieldChange}
        error={formErrors.city}
      />
      <CountyInput value={address.county} onChange={handleFieldChange} />
      <StateAndPostalCodeInputs
        state={address.state}
        postalCode={address.postal_code}
        onChange={handleFieldChange}
        stateError={formErrors.state}
        postalCodeError={formErrors.postal_code}
      />
      <CountryInput
        value={address.country}
        onChange={handleFieldChange}
        error={formErrors.country}
      />
    </div>
  );
};

AddressInput.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
};

export default AddressInput;

// Subcomponents
const AddressLine1Input = ({
  value,
  onChange,
  onSelectSuggestedAddress,
  error,
}) => (
  <div className="row">
    <div className="col-md-12">
      <UIFormLabel label="Address Line 1" />
      <UIAddressInputWithSuggestions
        value={value}
        onChange={(newValue) => onChange(newValue, "address_line_1")}
        onSelectSuggestedAddress={onSelectSuggestedAddress}
      />
      {error && <span className="error">{error}</span>}
    </div>
  </div>
);

const AddressLine2Input = ({ value, onChange }) => (
  <div className="row">
    <div className="col-md-12">
      <UILabelInput
        label="Address Line 2"
        placeholder="Address Line 2"
        value={value}
        onChange={(newValue) => onChange(newValue, "address_line_2")}
      />
    </div>
  </div>
);

const CityInput = ({ value, onChange, error }) => (
  <div className="row">
    <div className="col-md-12">
      <UILabelInput
        label="City"
        placeholder="City"
        error={error}
        value={value}
        onChange={(newValue) => onChange(newValue, "city")}
      />
    </div>
  </div>
);

const CountyInput = ({ value, onChange }) => (
  <div className="row">
    <div className="col-md-12">
      <UILabelInput
        label="County"
        placeholder="County"
        value={value}
        onChange={(newValue) => onChange(newValue, "county")}
      />
    </div>
  </div>
);

const StateAndPostalCodeInputs = ({
  state,
  postalCode,
  onChange,
  stateError,
  postalCodeError,
}) => (
  <div className="row">
    <div className="col-md-8">
      <strong>
        <label htmlFor="state" className="mb-1">
          State
        </label>
      </strong>
      <div className="ui-label-input">
        <select
          name="state"
          id="state"
          className="form-control"
          value={state}
          onChange={(e) => onChange(e.target.value, "state")}
        >
          <option value="" disabled>
            State
          </option>
          {State.getStatesOfCountry("US").map((state) => (
            <option key={state.isoCode} value={state.name}>
              {state.name}
            </option>
          ))}
        </select>
      </div>
      {stateError && <span className="error">{stateError}</span>}
    </div>
    <div className="col-md-4">
      <UILabelInput
        label="Postal Code"
        placeholder="Postal Code"
        value={postalCode}
        error={postalCodeError}
        onChange={(newValue) => onChange(newValue, "postal_code")}
      />
    </div>
  </div>
);

const CountryInput = ({ value, onChange, error }) => (
  <div className="row">
    <div className="col-md-12">
      <UILabelInput
        label="Country"
        placeholder="Country"
        error={error}
        value={value}
        onChange={(newValue) => onChange(newValue, "country")}
      />
    </div>
  </div>
);
