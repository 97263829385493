import React, { useState, useEffect } from "react";
import {
  UILabelInput,
  UIFormLabel,
  UIAddressInputWithSuggestions,
} from "components/ui";
import { State } from "country-state-city";

const AddressInput = ({
  onFieldChange: onFinalAddress,
  address: addressProp,
  onFieldUpdate,
  strong,
}) => {
  //Logic based on https://github.com/leighhalliday/demo-google-places-react/blob/master/src/App.js
  const [address, setAddress] = useState({
    id: addressProp.id || null,
    address_line_1: addressProp.address_line_1 || "",
    address_line_2: addressProp.address_line_2 || "",
    city: addressProp.city || "",
    county: addressProp.county || "",
    state: addressProp.state || "",
    postal_code: addressProp.postal_code || "",
    country: addressProp.country || "",
    is_primary: true,
  });

  const [formErrors, setFormErrors] = useState({
    address_line_1: "",
    city: "",
    state: "",
    postal_code: "",
  });

  const validateForm = () => {
    let valid = true;
    const emptyError = "This field should not be empty.";
    const keys = Object.keys(formErrors);
    let errors = {};

    for (var i = 0; i < keys.length; i++) {
      const field = keys[i];
      if (address[field] === "") {
        errors[field] = emptyError;
        valid = false;
      }
    }

    if (!valid) {
      setFormErrors(errors);
    }

    return valid;
  };

  const onFieldChange = (value, type) => {
    setAddress({
      ...address,
      [type]: value,
    });

    onFieldUpdate({ ...address, [type]: value });
  };

  const onSetAddress = (address) => {
    setAddress(address);
    onFieldUpdate(address);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12 mb-3 mt-2">
          <div className="ui-label-input">
            <UIFormLabel
              label="Address Line 1"
              strong={strong}
              required={true}
            />
            <UIAddressInputWithSuggestions
              value={address.address_line_1}
              onChange={(al1) => onFieldChange(al1, "address_line_1")}
              onSelectSuggestedAddress={onSetAddress}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            strong={false}
            id={"address_line_2"}
            label="Address Line 2"
            value={address.address_line_2}
            required={false}
            onChange={(value) => {
              onFieldChange(value, "address_line_2");
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <UILabelInput
            id={"city"}
            required={true}
            strong={strong}
            label="City"
            error={formErrors.city}
            value={address.city}
            onChange={(value) => {
              onFieldChange(value, "city");
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-8 mb-3 ui-label-input">
          <UIFormLabel
            label="State"
            strong={strong}
            for="state"
            required={true}
          />
          <select
            name="state"
            id="state"
            className="form-control"
            value={address.state}
            onChange={(e) => {
              onFieldChange(e.target.value, "state");
            }}
          >
            <option
              value=""
              defaultValue="Please select a state"
              disabled
              hidden
            >
              Please select a state
            </option>
            {State.getStatesOfCountry("US").map((state) => (
              <option key={state.name} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
          {formErrors.state !== "" && (
            <span className="error">{formErrors.state}</span>
          )}
        </div>
        <div className="col-md-4 mb-3">
          <UILabelInput
            label="Postal Code"
            strong={strong}
            id={"postal_code"}
            required={true}
            value={address.postal_code}
            error={formErrors.postal_code}
            onChange={(value) => {
              onFieldChange(value, "postal_code");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressInput;
