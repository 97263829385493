import React, { useState, useEffect, useRef } from "react";

import CompanyPurchaseRow from "./components/company-purchase-row";
import Header from "../../common/header";

import { getLastFileResourceByName } from "lib/utils";
import { sendVendorLetter } from "modules/applications";
import { PaginatedTable } from "components/ui";

const Review = ({
  claimedApplications,
  currentUser,
  incentive,
  totalEligible,
  totalClaimed,
}) => {
  const onSendVendorLetter = (application_id) => {
    sendVendorLetter(application_id)
      .then((res) => {
        location.reload();
      })
      .catch((errors) => {
        location.reload();
      });
    // TODO: There is a better way of passing these errors from the server to the client
  };

  const mapApplicationsToTableRows = (application, index) => {
    // Here, we can show either:
    // 1. sent_vendor_letter.pdf, which is what Lob has sent, including the barcode, etc.
    // 2. full_vendor_package.pdf, which is what we sent Lob
    // 3. vendor_letter.pdf, which is just the front page of the full package we'll put together for Lob.
    let vendor_letter = getLastFileResourceByName(
      application,
      "full_vendor_package.pdf"
    );

    // Deals with case of no JSONB data whatsoever
    application.details ||= {};

    return (
      <CompanyPurchaseRow
        key={`company-purchase-row-${index}`}
        application={application}
        vendor_letter={vendor_letter}
        currentUser={currentUser}
        onSendVendorLetter={onSendVendorLetter}
      />
    );
  };

  return (
    <>
      <Header
        name={incentive.name}
        eligible={totalEligible}
        claimed={totalClaimed}
      />
      <div className="panel mt-3">
        <div className="col-md-8">
          <div className="px-4 mt-3 mb-3">
            <h2 className="mt-0 font-weight-boldest">
              Your claimed exemptions
            </h2>
            <p className="my-3">
              Here’s a summary of the equipment for which you’ve claimed the
              sales tax exemption. You can always view the status of your claims
              and access documents here.
            </p>
          </div>
        </div>
        <PaginatedTable
          allItems={claimedApplications}
          headers={[
            "Vendor",
            "Total Requested",
            "Status",
            "Letter to Vendor",
            "Form(s)",
            "",
            "",
          ]}
          defaultNoItemsText={"No claimed applications."}
          mapItemsToTableRows={mapApplicationsToTableRows}
        />
      </div>
    </>
  );
};

export default Review;
