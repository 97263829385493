import React from "react";
import { isEmpty } from "lodash-es";
import { useSetState } from "react-hanger";
import { flashErrorLater, flashSuccessLater } from "lib/flash";
import { updatePasswordUser } from "modules/users";
import { flashError, flashSuccess } from "lib/flash";

const ChangePassword = () => {
  const { state: variables, setState: setVariables } = useSetState({
    current_password: "",
    password: "",
    password_confirmation: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    updatePasswordUser({
      user: variables,
    }).then((res) => {
      if (isEmpty(res.errors)) {
        flashSuccessLater("Password Changed");
        location.reload(true);
      } else res.errors.map((error) => flashError(error));
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <h1>Change Password</h1>
          <hr />
        </div>
      </div>
      <div className="row">
        <form onSubmit={handleSubmit}>
          <div className="col-md-8">
            <div className="mb-3">
              <label>
                <strong>Current Password</strong>
              </label>
              <input
                type="password"
                className="form-control"
                value={variables.current_password}
                onChange={(e) => {
                  setVariables({
                    current_password: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <label>
                <strong>Password</strong>
              </label>
              <input
                type="password"
                className="form-control"
                value={variables.password}
                onChange={(e) => {
                  setVariables({
                    password: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <label>
                <strong>Password Confirmation</strong>
              </label>
              <input
                type="password"
                className="form-control"
                value={variables.password_confirmation}
                onChange={(e) => {
                  setVariables({
                    password_confirmation: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-5 btn-group float-end">
              <button className="btn btn-action" type="submit">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
