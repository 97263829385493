import React, { useState, useEffect } from "react";

const Tasks = (props) => {
  const activeTasks = props.tasks.filter((task) => !task.completed);
  const completedTasks = props.tasks.filter((task) => task.completed);
  const [tab, setTab] = useState("activeTasks");

  const renderTasks = tab === "activeTasks" ? activeTasks : completedTasks;

  return (
    <div className="tasks mt-5">
      <h2>Tasks</h2>

      <ul className="nav">
        <li
          className="nav-item"
          onClick={(e) => {
            e.preventDefault();
            setTab("activeTasks");
          }}
        >
          <a
            className={`nav-link ${tab === "activeTasks" ? "active" : ""}`}
            aria-current="page"
            href="#"
          >
            Active
          </a>
        </li>
        <li
          className="nav-item"
          onClick={(e) => {
            e.preventDefault();
            setTab("completedTasks");
          }}
        >
          <a
            className={`nav-link ${tab === "completedTasks" ? "active" : ""}`}
            aria-current="page"
            href="#"
          >
            Completed
          </a>
        </li>
      </ul>

      {renderTasks.map((task, index) => {
        return (
          <div
            className="row task"
            key={`task-${index}`}
            onClick={(e) => {
              e.preventDefault();
              window.location = task.url;
            }}
          >
            <div className="col-md-3">
              <div className="icon-square">
                <i
                  className={`position-absolute top-50 start-50 translate-middle bi ${task.icon}`}
                ></i>
              </div>
            </div>

            <div className="col-md-9">
              <h6>
                <strong>{task.name}</strong>
              </h6>
              <p className="mb-0">{task.description}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Tasks;
