import React, { useState, useEffect } from "react";
import { fetchAdminIncentiveTypes } from "modules/incentives";
import { UIOptsNested } from 'components/ui'

const IncentiveTypeSelector = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [typeOptions, setTypeOptions] = useState(props.incentiveTypes || []);

  useEffect(() => {
    if (typeOptions.length > 0) {
      setLoaded(true);
      return;
    }
    fetchAdminIncentiveTypes().then((data) => {
      setTypeOptions(data);
      setLoaded(true);
    });
  }, []);

  const handleChange = (e) => {
    e.preventDefault();

    const option = e.target.value;
    props.selectIncentiveType(option);
  };

  if (!loaded) return null;

  return (
    <div>
      <select
        className="form-control"
        value={props.incentiveType}
        onChange={handleChange}
      >
        <option disabled selected value>
          {" "}
          -- select an option --{" "}
        </option>
        <UIOptsNested opts={typeOptions} />
      </select>
    </div>
  );
};

export default IncentiveTypeSelector;
