import React, { useState } from "react";
import { answerQualification } from "modules/qualifications";
import { flashSuccess, flashError } from "lib/flash";
import { UIRadioCard } from "components/ui";
import { v4 as uuidv4 } from "uuid";
import { prequalifyIncentiveApplication } from "modules/applications";

const MultipleChoiceQuestion = (props) => {
  const answerOptions = props.answer_options;
  const [answer, setAnswer] = useState(props.answer);
  const name = uuidv4();

  function handleSubmit(answerOption) {
    props.setIsLoading(true);
    const params = {
      incentive_id: props.incentive.slug,
      qualification_id: props.id,
      answer: answerOption.id,
    };

    answerQualification(params)
      .then(() => {
        setAnswer(answerOption.id);
        props.handleUpdateSuccess();
        flashSuccess("Succesfully updated answer");
        prequalifyIncentiveApplication(props.incentive.slug)
          .then((response) => {
            props.handlePrequalifySuccess(response);
            console.log("response", response);
          })
          .catch((error) => {
            console.error("Error during prequalification:", error);
          });
      })
      .catch((error) => {
        flashError(error[0]);
        props.setIsLoading(false);
      });
  }

  const htmlAnswerOptions = props.answer_options.map((answer_option, index) => {
    return (
      <div className="col-md-auto mb-3" key={`answer-option-${index}`}>
        <UIRadioCard
          checked={answer_option.id === answer}
          label={answer_option.option_text}
          id={`answer-option${answer_option.id}`}
          name={name}
          handleClick={() => {
            handleSubmit(answer_option);
          }}
        />
      </div>
    );
  });

  return <div className="row gx-3">{htmlAnswerOptions}</div>;
};

export default MultipleChoiceQuestion;
