import React from "react";
import { useSetState } from "react-hanger";
import { UILabelInput } from "../../../ui";

const OptionForm = ({ answerOption: option, onOptionSave, removeOption }) => {
  const { state: answerOption, setState: setAnswerOption } =
    useSetState(option);
  return (
    <div className="row">
      <div className="col-9">
        <UILabelInput
          label="Option Text"
          className="form-control"
          value={answerOption.option_text}
          onChange={(value) => setAnswerOption({ option_text: value })}
        />
      </div>
      <div className="col-3 align-self-end">
        <div className="btn-group ">
          <button
            className="btn btn-success"
            onClick={() => onOptionSave(answerOption)}
          >
            <i className="bi bi-check" />
          </button>
          <button
            className="btn btn-light"
            onClick={() => removeOption(answerOption)}
          >
            <i className="px-1 bi bi-trash"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default OptionForm;
