import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DocumentManagerModal from "./document-manager-modal";

const DocumentTile = (props) => {
  const [tileState, setTileState] = useState(
    props.completed ? "completed" : "initialized"
  );
  const [displayModal, setDisplayModal] = useState(false);

  function handleClick(e) {
    e.preventDefault();
    setDisplayModal(true);
  }

  function closeModal() {
    setDisplayModal(false);
  }

  let documentTileClassName = "document-tile p-3 ";
  let iconClassName = "bi ";
  let subtitle = "";
  let status = "";

  switch (tileState) {
    case "completed":
      documentTileClassName += "completed";
      iconClassName += "bi-file-richtext-fill";
      const { files } = props;

      if (props.files) {
        subtitle = `${files.length} File${
          files.length === 1 ? "" : "s"
        } uploaded`;
      } else {
        subtitle = "Files uploaded";
      }

      status = "Completed";
      break;
    case "initialized":
      documentTileClassName += "initialized";
      iconClassName += "bi-cloud-arrow-up";
      subtitle = "Upload your files";
      status = props.required ? "Required" : "Optional";
      break;
    case "uploading":
      documentTileClassName += "uploading";
      iconClassName += "bi-file-richtext-fill";
      subtitle = "Uploading in progress";
      status = "Uploading";
      break;
    case "error":
      documentTileClassName += "error";
      iconClassName += "bi-file-richtext-fill";
      subtitle = "Error uploading files";
      status = "Error";
      break;
  }

  return (
    <div className="document-tile-wrapper">
      <DocumentManagerModal show={displayModal} close={closeModal} {...props} />
      <div className="application-document-tile-header">
        <h4>{props.category}</h4>
        {props.application_context && (
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip className="cashflow-tooltip">
                {props.application_context}
              </Tooltip>
            }
          >
            <i className="bi bi-info-circle info-icon"></i>
          </OverlayTrigger>
        )}
      </div>

      <div className={documentTileClassName} onClick={handleClick}>
        <div className="icon">
          <i className={iconClassName} />
        </div>
        <h5>{subtitle}</h5>
        <button className="btn btn-sm btn-muted" onClick={handleClick}>
          Manage
        </button>

        <h6 className="mt-3">{status}</h6>
      </div>
    </div>
  );
};

export default DocumentTile;
