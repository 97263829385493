import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "lib/axios";

import DatePicker from "react-datepicker";
import { DropdownButton, Dropdown, Spinner, Toast } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { UILabelInput } from "components/ui";

const MeetingNotes = ({ meeting, isNewNote, setShowNewMeeting }) => {
  const meetingTypes = [
    {
      name: "Discovery",
    },
    { name: "Application Review" },
    { name: "Company Outreach" },
    { name: "Email" },
    { name: "Informational" },
    { name: "Incentive Review" },
    { name: "Incentive Report Email" },
    { name: "Incentive Report Request" },
    { name: "Partner Intro" },
    { name: "Product Demo" },
    { name: "Resolve Issue" },
    { name: "Non-meeting Note" },
    { name: "Other" },
  ];

  const [showMeetingDetail, setShowMeetingDetail] = useState(isNewNote);
  const [meetingDetail, setMeetingDetail] = useState({
    id: meeting.id,
    company_id: meeting.company_id,
    attendees: meeting.attendees?.toString() || "",
    meeting_date_time: meeting.meeting_date_time,
    meeting_notes: meeting.meeting_notes,
    meeting_type: meeting.meeting_type,
    name: meeting.name,
    subcity_action_items: meeting?.subcity_action_items || "",
    company_action_items: meeting?.company_action_items || "",
  });

  const deleteMeeting = () => {
    if (window.confirm("Are you sure you want to delete this meeting note?")) {
      axios
        .delete(`/admin/company_meetings/${meetingDetail.id}`)
        .then((res) => {
          console.log("Meeting note deleted successfully");
          window.location.reload();
        })
        .catch((err) => {
          console.log("Error deleting meeting note");
        });
    }
  };

  useEffect(() => {
    setMeetingDetail(meeting);
  }, [showMeetingDetail]);
  const saveMeetingDetail = () => {
    const saveMethod = isNewNote ? "post" : "put";
    axios[saveMethod]("/admin/company_meetings", meetingDetail).then(() => {
      location.reload();
    });
  };

  if (showMeetingDetail) {
    return (
      <div className="card p-3 mt-1 mb-3 shadow-sm">
        <div className="d-flex justify-content-between mb-3 mt-3">
          <h4>
            <strong>
              {meetingDetail.name ? meetingDetail.name : "New note"}
            </strong>
          </h4>

          <div className="d-flex">
            <button
              className="btn btn-light mr-2"
              onClick={() => {
                if (isNewNote) {
                  setShowNewMeeting(false);
                } else {
                  setShowMeetingDetail(false);
                }
              }}
            >
              <i className="bi bi-x-lg"></i>
            </button>
            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                <i className="bi bi-three-dots"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={deleteMeeting}>Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="row">
          <div key={`meeting-name-${meetingDetail.id}`} className="col-md-12">
            <UILabelInput
              label="Name"
              value={meetingDetail.name}
              onChange={(value) => {
                setMeetingDetail({ ...meetingDetail, name: value });
              }}
            />
          </div>
        </div>

        <div className="row">
          <div
            key={`meeting-type-${meetingDetail.id}`}
            className="col-md-6 mb-3 ui-label-input"
          >
            <label>
              <strong>
                <label htmlFor="meeting_type" className="mb-1">
                  Type
                </label>
              </strong>
              <div>
                <select
                  name="meeting_type"
                  id="meeting-type"
                  className="form-control"
                  value={meetingDetail.meeting_type}
                  onChange={(e) => {
                    setMeetingDetail({
                      ...meetingDetail,
                      meeting_type: e.target.value,
                    });
                  }}
                >
                  {meetingTypes.map((meetingType, index) => (
                    <option
                      key={`${meetingType.name}-${index}`}
                      value={meetingType.name}
                    >
                      {meetingType.name}
                    </option>
                  ))}
                </select>
              </div>
            </label>
          </div>

          <div
            key={`meeting-time-${meetingDetail.id}`}
            className="col-md-6 mb-3 ui-label-input"
          >
            <strong>
              <label className="mb-1">Time</label>
            </strong>

            <DatePicker
              className="form-control"
              name="Meeting Time"
              selected={new Date(meetingDetail.meeting_date_time)}
              dateFormat="MMMM dd yyyy, h:mm a"
              scrollableYearDropdown={true}
              showYearDropdown={true}
              yearDropdownItemNumber={100}
              showTimeSelect
              timeFormat="p"
              timeIntervals={15}
              maxDate={new Date()}
              onChange={(date) => {
                setMeetingDetail({ ...meetingDetail, meeting_date_time: date });
              }}
            />
          </div>
        </div>

        {meetingDetail.meeting_type != "Non-meeting Note" ? (
          <div className="row">
            <div className="col-md-12">
              <UILabelInput
                label="Attendees"
                value={meetingDetail.attendees}
                onChange={(value) => {
                  setMeetingDetail({
                    ...meetingDetail,
                    attendees: value.split(","),
                  });
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row mb-5">
          <div
            key={`meeting-notes-${meeting.id}`}
            className="col-md-12 mb-3 ui-label-input"
          >
            <strong>
              <label className="mb-1">Notes</label>
            </strong>
            <ReactQuill
              value={meetingDetail.meeting_notes}
              onChange={(content) => {
                setMeetingDetail({
                  ...meetingDetail,
                  meeting_notes: content,
                });
              }}
            />
            {/* <TextareaAutosize
              type="text"
              name="Notes"
              className="form-control"
              placeholder="Enter notes here..."
              value={meetingDetail.meeting_notes}
              onChange={(e) => {
                setMeetingDetail({
                  ...meetingDetail,
                  meeting_notes: e.target.value,
                });
              }}
            /> */}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <UILabelInput
              label="Subcity Action Items"
              value={meetingDetail.subcity_action_items}
              onChange={(value) => {
                setMeetingDetail({
                  ...meetingDetail,
                  subcity_action_items: value.split(","),
                });
              }}
            />
          </div>
          <div className="col-md-6 mb-3">
            <UILabelInput
              label="Company Action Items"
              value={meetingDetail.company_action_items}
              onChange={(value) => {
                setMeetingDetail({
                  ...meetingDetail,
                  company_action_items: value.split(","),
                });
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="d-flex justify-content-end pt-3">
            <div className="btn-group">
              <button className="btn btn-dark mx-1" onClick={saveMeetingDetail}>
                {" "}
                Save{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div key={meeting.id} className="card p-3 mt-1 mb-2 shadow-sm">
        <div className="d-flex justify-content-between align-items-center">
          <div className="flex-grow-1 ms-2">
            {meeting.meeting_type === "Non-meeting Note" ? (
              <div className="d-inline px-2 py-1 text-success-emphasis bg-success-subtle border border-success-subtle rounded-1">
                {meeting.meeting_type}
              </div>
            ) : (
              <div className="d-inline px-2 py-1 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-1">
                {meeting.meeting_type}
              </div>
            )}
            <span className="px-2">{meeting.name}</span>
            <div className="admin-metadata">{meeting.attendees}</div>
          </div>
          <div className="p-3 admin-metadata">
            {moment(meeting.meeting_date_time).format("MM/DD/YYYY hh:mm A")}
          </div>
          <button
            className="btn btn-light ml-2"
            onClick={() => {
              setShowMeetingDetail(true);
            }}
          >
            {" "}
            View{" "}
          </button>
        </div>
      </div>
    );
  }
};

export default MeetingNotes;
