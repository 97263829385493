import React from "react";
import SubRule from "./sub-rule";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const RuleFields = ({ variables, onFieldChange, fieldOptions }) => {
  const isOperatorNullable = () =>
    ["null", "nnull"].includes(variables.operatorKey);

  if (isOperatorNullable()) return <></>;

  if (variables.fieldType === "boolean")
    return (
      <>
        <select
          className="form-control"
          value={variables.value}
          onChange={(e) => onFieldChange("value", e.target.value)}
        >
          <option value="true">true</option>
          <option value="false">false</option>
        </select>
      </>
    );

  if (variables.fieldType === "object")
    return (
      <RuleFields
        variables={{
          ...variables,
          fieldType: variables.subFieldType,
          field: variables.field,
        }}
        onFieldChange={onFieldChange}
        fieldOptions={fieldOptions}
      />
    );

  if (variables.fieldType === "date")
    return (
      <>
        {variables.operator === "between" ? (
          <div className="row">
            <div className="col-md-6">
              <label>
                <strong>Start</strong>
              </label>
              <DatePicker
                className="form-control"
                selected={variables.value[0]}
                scrollableYearDropdown={true}
                showYearDropdown={true}
                yearDropdownItemNumber={100}
                maxDate={new Date()}
                onChange={(date) =>
                  onFieldChange("value", [date, variables.value[1]])
                }
              />
            </div>
            <div className="col-md-6">
              <label>
                <strong>End</strong>
              </label>
              <DatePicker
                className="form-control"
                selected={variables.value[1]}
                scrollableYearDropdown={true}
                showYearDropdown={true}
                yearDropdownItemNumber={100}
                maxDate={new Date()}
                onChange={(date) =>
                  onFieldChange("value", [variables.value[0], date])
                }
              />
            </div>
          </div>
        ) : (
          <>
            <DatePicker
              className="form-control"
              selected={variables.value}
              scrollableYearDropdown={true}
              showYearDropdown={true}
              yearDropdownItemNumber={100}
              maxDate={new Date()}
              onChange={(date) => onFieldChange("value", date)}
            />
          </>
        )}
      </>
    );

  if (variables.operator === "between")
    return (
      <>
        <div className="row">
          <div className="col-md-6">
            <label>
              <strong>Start</strong>
            </label>
            <input
              className="form-control"
              value={variables.value[0]}
              type="number"
              onChange={(e) =>
                onFieldChange("value", [e.target.value, variables.value[1]])
              }
            />
          </div>
          <div className="col-md-6">
            <label>
              <strong>End</strong>
            </label>
            <input
              className="form-control"
              value={variables.value[1]}
              type="number"
              onChange={(e) =>
                onFieldChange("value", [variables.value[0], e.target.value])
              }
            />
          </div>
        </div>
      </>
    );

  if (["all", "some", "none"].includes(variables.operator))
    return (
      <SubRule
        variableValue={variables.value}
        onFieldChange={onFieldChange}
        selectedField={variables.field}
        fieldOptions={fieldOptions}
        key={variables.operatorKey}
      />
    );

  return (
    <>
      <input
        className="form-control"
        name="Rule Field"
        value={variables.value}
        type="text" //{variables.fieldType === "numeric" ? "number" : "text"}
        onChange={(e) => onFieldChange("value", e.target.value)}
      />
    </>
  );
};

export default RuleFields;
