import React, { useState, useRef, useEffect } from "react";
import { useArray, useSetState } from "react-hanger";

import CreatableSelect from "react-select/creatable";
import CreateEstimateCategoryPopover from "./create-estimate-category-popover";

import { flashSuccess, flashError } from "lib/flash";
import {
  getCompanyEstimateCategories,
  createEstimateCategory,
} from "modules/company-estimate-category";

const EstimateCategoryManager = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [values, setValues] = useState([]);
  const options = useArray([]);

  const [showCreatingDropdown, setShowCreatingDropdown] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");

  function handleCancel(e) {
    e.preventDefault();
    props.handleCancel();
  }

  function handleSave(e) {
    e.preventDefault();
    setValues([]);
    props.handleSave();
  }

  function formatProjectCategory(category) {
    return {
      id: category.id,
      company_estimate_category_id: category.company_estimate_category_id,
      value: category.name
        ? category.name.toLowerCase().replace(/\W/g, "")
        : "",
      label: category.name || "",
    };
  }

  function formatEstimateCategory(category) {
    return {
      company_estimate_category_id: category.id,
      value: category.name?.toLowerCase()?.replace(/\W/g, ""),
      label: category.name,
    };
  }

  const target = useRef(null);

  useEffect(() => {
    if (props.categories) {
      setValues(
        props.categories.map((category) => formatProjectCategory(category))
      );
    }

    const params = {
      used: true,
      company_project_id: props.company_project_id,
    };

    getCompanyEstimateCategories(params)
      .then((categories) => {
        for (var i = 0; i < categories.length; i++) {
          options.push(formatEstimateCategory(categories[i]));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    props.updateCategories(values);
  }, [values]);

  function handleCreateNewOption(option) {
    setNewCategoryName(option);
    createCategory(option);
    // setShowCreatingDropdown(true);
  }

  function createCategory(categoryName) {
    const params = {
      company_estimate_category: {
        name: categoryName,
      },
    };

    return createEstimateCategory(params)
      .then((category) => {
        flashSuccess("Estimate Category: " + categoryName + " added.");
        setShowCreatingDropdown(false);
        setNewCategoryName("");

        const newCategory = formatEstimateCategory(category);
        options.push(newCategory);
        setValues([...values, newCategory]);

        return Promise.resolve();
      })
      .catch((error) => {
        flashError(error.errors[0]);

        return Promise.reject();
      });
  }

  return (
    <div className="estimate-category-manager" ref={target}>
      <label>
        <strong>Add Category</strong>
      </label>
      <div className="row">
        <div className="col-md-10 mb-5">
          <CreatableSelect
            isClearable
            isMulti
            isDisabled={isLoading}
            isLoading={isLoading}
            onChange={(newValue) => setValues(newValue)}
            width="100%"
            onCreateOption={handleCreateNewOption}
            options={options.value}
            value={values}
            placeholder="Add categories"
          />
        </div>
        <div className="col-md-2">
          <div className="btn-group">
            <button className="btn btn-secondary" onClick={handleCancel}>
              <i className="bi bi-x"></i>
            </button>
            <button className="btn btn-action" onClick={handleSave}>
              <i className="bi bi-plus-square"></i>
            </button>
          </div>
        </div>
      </div>

      {showCreatingDropdown ? (
        <CreateEstimateCategoryPopover
          target={target}
          show={showCreatingDropdown}
          setShow={setShowCreatingDropdown}
          name={newCategoryName}
          setName={setNewCategoryName}
          createCategory={createCategory}
        />
      ) : null}
    </div>
  );
};

export default EstimateCategoryManager;
