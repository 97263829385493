import React from "react";

const Faqs = ({ faqs }) => {
  return (
    <div className="panel p-4">
      <h1 className="mt-0">Frequently Asked Questions</h1>

      <div className="row">
        <div className="col-md-6">
          <p className="my-3">
            Everything you need to know about the application. Can't find the
            answer you're looking for? Please contact our{" "}
            <a href="mailto:alex@subcity.com">friendly team</a>.
          </p>
        </div>
      </div>

      <div className="row mt-3">
        {faqs.map((entry, index) => {
          return (
            <div className="col-md-6 mb-4" key={entry.id}>
              <h5 className="font-weight-boldest">{entry.question}</h5>
              <p style={{ whiteSpace: "pre-wrap" }}>{entry.answer}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faqs;
