import React, { useState, useEffect } from 'react';

import {
  getAllocatedLineItemGroups
} from 'modules/project'

const AllocatedLineItemGroups = (props) => {
  const [groups, setGroups] = useState([])

  useEffect(() => {
    fetchAllocatedLineItemGroups()
  }, [])

  function fetchAllocatedLineItemGroups() {
    getAllocatedLineItemGroups(props.project.id)
      .then((result) => {
        console.log(result)
      })
  }

  return (
    <div>
    </div>
  )
}

export default AllocatedLineItemGroups;
