import React, { useState, useContext, useEffect } from "react";
import { State } from "country-state-city";
import TextareaAutosize from "react-textarea-autosize";
import {
  UIFormLabel,
  UILabelInput,
  UIAddressInputWithSuggestions,
} from "components/ui";
import { EditOrganizationContext } from "./edit-organization-context";
import ReferralSourceSelector from "../../companies/common/referral-source-selector";

const BasicInfo = ({ entities }) => {
  const { onUpdateOrganization, basicInfo, setBasicInfo } = useContext(
    EditOrganizationContext
  );

  const [organizationName, setOrganizationName] = useState(basicInfo.name);
  const [selectedFile, setSelectedFile] = useState(null);

  const updateOrganization = () => {
    if (selectedFile == null) {
      delete basicInfo["logo"];
    } else {
      basicInfo.logo = selectedFile;
    }

    onUpdateOrganization(basicInfo).then(() => {
      setOrganizationName(basicInfo.name);
    });
  };

  const renderOptions = (options, entity_class) => {
    return options.map((option, index) => {
      return (
        <option
          key={`${option.id}-${index}`}
          value={`${entity_class}:${option.id}`}
        >
          {option.name}
        </option>
      );
    });
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let image_type = e.target.files[0].type.split("/")[0];
      if (image_type == "image") {
        let reader = new FileReader();
        reader.onload = function (e) {
          $("#uploaded-image-preview")
            .html(
              "<img src='" +
                e.target.result +
                "' class='image_preview_tag' width='100' height='100' >"
            )
            .show();
        };
        reader.readAsDataURL(e.target.files[0]);
      }

      setSelectedFile(e.target.files[0]);
    } else {
      $("#uploaded-image-preview")
        .html(
          "<img src='' class='image_preview_tag' width='100' height='100' >"
        )
        .hide();
    }
  };

  const updateAddressAttributes = (hash_key, updated_value) => {
    let address_hash = basicInfo.address_attributes;
    address_hash[hash_key] = updated_value;

    setBasicInfo({ address_attributes: address_hash });
  };

  useEffect(() => {
    setBasicInfo({ entity_string: organization_entity_string.value });
  }, []);

  return (
    <div className="col-md-12">
      <div className="panel-header pb-3 ps-0 pt-0">
        <div className="row">
          <h3>Basic Information for {organizationName}</h3>
          <hr />
        </div>
      </div>

      <div className="col-md-10 mb-4">
        <UILabelInput
          label="Business Name"
          value={basicInfo.name}
          onChange={(value) => {
            setBasicInfo({ name: value });
          }}
        />
      </div>

      <div className="col-md-10 mb-4">
        <UIFormLabel label="Description" />
        <TextareaAutosize
          type="text"
          className="form-control"
          placeholder="Enter description here..."
          value={basicInfo.description || ""}
          onChange={(e) => {
            setBasicInfo({ description: e.target.value });
          }}
        />
      </div>

      <hr className="col-md-10" />

      <div className="col-md-10 mb-4">
        <UIFormLabel label="Address Line 1" />
        <UIAddressInputWithSuggestions
          value={basicInfo.address_attributes.address_line_1}
          onChange={(value) => {
            updateAddressAttributes("address_line_1", value);
          }}
          onSelectSuggestedAddress={(suggestedAddress) => {
            const address = basicInfo.address_attributes;
            for (const field in address) {
              if (field in suggestedAddress) {
                address[field] = suggestedAddress[field];
              }
            }
            setBasicInfo({ addressAttributes: { ...address } });
          }}
        />
      </div>

      <div className="row">
        <div className="col-md-10 mb-3">
          <UILabelInput
            label="Address Line 2"
            value={basicInfo.address_attributes.address_line_2}
            onChange={(value) => {
              updateAddressAttributes("address_line_2", value);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 mb-3">
          <UILabelInput
            label="City"
            value={basicInfo.address_attributes.city}
            onChange={(value) => {
              updateAddressAttributes("city", value);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 mb-3">
          <strong>
            <label htmlFor="state" className="mb-1">
              State
            </label>
          </strong>
          <select
            name="state"
            className="form-control"
            value={basicInfo.address_attributes.state}
            onChange={(e) => {
              updateAddressAttributes("state", e.target.value);
            }}
          >
            {State.getStatesOfCountry("US").map((state) => (
              <option key={state.name} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6 mb-3">
          <UILabelInput
            label="Postal Code"
            value={basicInfo.address_attributes.postal_code}
            onChange={(value) => {
              updateAddressAttributes("postal_code", value);
            }}
          />
        </div>
      </div>

      <hr className="col-md-10" />

      <div className="col-md-10 mb-4">
        <UILabelInput
          label="Website"
          value={basicInfo.website}
          onChange={(value) => {
            setBasicInfo({ website: value });
          }}
        />
      </div>

      <div className="row">
        <div className="col-md-10 mb-4">
          <strong>
            <label htmlFor="organization[entity_string]" className="mb-1">
              Entity
            </label>
          </strong>
          <div>
            <select
              className="form-control"
              name="organization[entity_string]"
              id="organization_entity_string"
              value={basicInfo.entity_string}
              onChange={(e) => {
                setBasicInfo({ entity_string: e.target.value });
              }}
            >
              {Object.keys(entities).map((entity, index) => {
                return (
                  <optgroup key={index} label={entity}>
                    {renderOptions(entities[entity], entity)}
                  </optgroup>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-md-10 mb-4">
          <div className="ui-label-input">
            <UIFormLabel label="Referral Source" />
            <ReferralSourceSelector
              value={basicInfo.referral_source_id}
              label={basicInfo.referral_source_name}
              onSelect={(selectedOption) => {
                setBasicInfo({
                  ...basicInfo,
                  referral_source_id: selectedOption
                    ? selectedOption.value
                    : null,
                  referral_source_name: selectedOption
                    ? selectedOption.label
                    : "",
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="col-md-10 mb-3">
        <strong>
          <label className="me-1">Logo</label>
        </strong>
        <label className="text-muted">
          Valid File Extension: PNG, JPG, JPEG
        </label>
        <div className="image-form-div">
          <div className="mt-3 mb-3" id="uploaded-image-preview">
            <img src={basicInfo.logo} size="100" />
          </div>
          <input
            type="file"
            name="logo"
            className="form-control input_image"
            onChange={onImageChange}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-10 mb-4">
          <div className="mb-5 btn-group float-end">
            <a className="btn btn-light" href="/admin/companies">
              Back
            </a>
            <button className="btn btn-primary" onClick={updateOrganization}>
              Save Organization
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
