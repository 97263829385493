import { find } from "lodash-es";
import { v4 as uuidv4 } from "uuid";

const findByValue = (options, val) => find(options, { value: val });

const Operators = {
  eq: {
    label: "equal to",
    value: "==",
    default: {
      date: "",
      boolean: "true",
      string: "",
      numeric: "",
      array: "",
      object: {
        date: "",
        boolean: "true",
        string: "",
        numeric: "",
        array: "",
      },
    },
    key: "eq",
  },
  neq: {
    label: "not equal to",
    value: "!=",
    default: {
      date: "",
      boolean: "true",
      string: "",
      numeric: "",
      array: "",
      object: {
        date: "",
        boolean: "true",
        string: "",
        numeric: "",
        array: "",
      },
    },
    key: "neq",
  },
  mt: {
    label: "matches",
    value: "mt",
    default: {
      date: "",
      boolean: "true",
      string: "",
      numeric: "",
      array: "",
      object: {
        date: "",
        boolean: "",
        string: "",
        numeric: "",
        array: "",
      },
    },
    key: "mt",
  },
  notmt: {
    label: "not matches",
    value: "notmt",
    default: {
      date: "",
      boolean: "true",
      string: "",
      numeric: "",
      array: "",
      object: {
        date: "",
        boolean: "",
        string: "",
        numeric: "",
        array: "",
      },
    },
    key: "notmt",
  },
  in: {
    label: "in",
    value: "in",
    default: {
      date: "",
      boolean: "true",
      string: "",
      numeric: "",
      array: "",
      object: {
        date: "",
        boolean: "",
        string: "",
        numeric: "",
        array: "",
      },
    },
    key: "in",
  },
  notin: {
    label: "not in",
    value: "in",
    default: {
      date: "",
      boolean: "true",
      string: "",
      numeric: "",
      array: "",
      object: {
        date: "",
        boolean: "",
        string: "",
        numeric: "",
        array: "",
      },
    },
    key: "notin",
  },
  lt: {
    label: "less than",
    value: "<",
    default: {
      date: "",
      boolean: "true",
      string: "",
      numeric: "",
      array: "",
      object: {
        date: "",
        boolean: "",
        string: "",
        numeric: "",
        array: "",
      },
    },
    key: "lt",
  },
  lte: {
    label: "less or equal to",
    value: "<=",
    default: {
      date: "",
      boolean: "true",
      string: "",
      numeric: "",
      array: "",
      object: {
        date: "",
        boolean: "",
        string: "",
        numeric: "",
        array: "",
      },
    },
    key: "lte",
  },
  gt: {
    label: "greater than",
    value: ">",
    default: {
      date: "",
      boolean: "true",
      string: "",
      numeric: "",
      array: "",
      object: {
        date: "",
        boolean: "",
        string: "",
        numeric: "",
        array: "",
      },
    },
    key: "gt",
  },
  gte: {
    label: "greater or equal",
    value: ">=",
    default: {
      date: "",
      boolean: "true",
      string: "",
      numeric: "",
      array: "",
      object: {
        date: "",
        boolean: "",
        string: "",
        numeric: "",
        array: "",
      },
    },
    key: "gte",
  },
  bt: {
    label: "between",
    value: "between",
    default: {
      date: ["", ""],
      boolean: "true",
      string: "",
      numeric: ["", ""],
      array: "",
      object: {
        date: ["", ""],
        boolean: "",
        string: "",
        numeric: ["", ""],
        array: "",
      },
    },
    key: "bt",
  },
  notbt: {
    label: "not between",
    value: "between",
    default: {
      date: ["", ""],
      boolean: "true",
      string: "",
      numeric: ["", ""],
      array: "",
      object: {
        date: ["", ""],
        boolean: "",
        string: "",
        numeric: ["", ""],
        array: "",
      },
    },
    key: "notbt",
  },
  null: {
    label: "is null",
    value: "==",
    default: {
      date: "",
      boolean: "true",
      string: null,
      numeric: null,
      array: null,
      object: {
        date: null,
        boolean: null,
        string: null,
        numeric: null,
        array: null,
      },
    },
    key: "null",
  },
  nnull: {
    label: "is not null",
    value: "!=",
    default: {
      date: "",
      boolean: "true",
      string: null,
      numeric: null,
      array: null,
      object: {
        date: null,
        boolean: null,
        string: null,
        numeric: null,
        array: null,
      },
    },
    key: "nnull",
  },
  all: {
    label: "all",
    value: "all",
    default: {
      date: "",
      boolean: "true",
      string: null,
      numeric: null,
      array: {
        field: "",
        operator: "==",
        operatorKey: "eq",
        fieldType: "",
        value: "",
      },
      object: {
        date: "",
        boolean: "",
        string: "",
        numeric: "",
        array: "",
      },
    },
    key: "all",
  },
  some: {
    label: "some",
    value: "some",
    default: {
      date: "",
      boolean: "true",
      string: null,
      numeric: null,
      array: {
        field: "",
        operator: "==",
        operatorKey: "eq",
        fieldType: "",
        value: "",
      },
      object: {
        date: "",
        boolean: "",
        string: "",
        numeric: "",
        array: "",
      },
    },
    key: "some",
  },
  none: {
    label: "none",
    value: "none",
    default: {
      date: "",
      boolean: "true",
      string: null,
      numeric: null,
      array: {
        field: "",
        operator: "==",
        operatorKey: "eq",
        fieldType: "",
        value: "",
      },
      object: {
        date: "",
        boolean: "",
        string: "",
        numeric: "",
        array: "",
      },
    },
    key: "none",
  },
};

const OperatorMapping = {
  boolean: {
    operators: ["eq", "neq"],
    default: "eq",
  },
  string: {
    operators: ["eq", "neq", "mt", "notmt", "null", "nnull", "in", "notin"],
    default: "eq",
  },
  numeric: {
    operators: [
      "eq",
      "neq",
      "in",
      "notin",
      "null",
      "nnull",
      "gt",
      "gte",
      "lt",
      "lte",
      "bt",
      "notbt",
    ],
    default: "eq",
  },
  array: {
    operators: ["all", "some", "none"],
    default: "all",
  },
  object: {
    operators: [],
    default: "eq",
  },
  date: {
    operators: ["eq", "neq", "gt", "gte", "lt", "lte", "bt", "notbt"],
    default: "eq",
  },
};

const emptyRule = () => ({
  type: "rule",
  id: uuidv4(),
  field: "",
  fieldType: "",
  operator: "",
  operatorKey: "",
  subField: "",
  subFieldType: "",
  value: "",
});

const emptyGroup = () => ({
  type: "group",
  id: uuidv4(),
  condition: "and",
  children: [],
});

export { Operators, OperatorMapping, emptyRule, emptyGroup, findByValue };
