import React, { useState } from "react";
import Select from "react-select";
import TextareaAutosize from "react-textarea-autosize";
import OrganizationSelector from "../../admin/incentives/common/organization-selector";
import IncentiveTypeSelector from "../../admin/incentives/common/incentive-type-selector";
import { UIFormLabel } from "../../ui";

const MissingFieldCard = ({
  incentive,
  missingField,
  updateIncentive,
  incentiveTypes,
  organizations,
}) => {
  const [value, setValue] = useState("");

  const RoutingFieldHash = {
    description: "basic-info",
    economics: "economics",
    slug: "basic-info",
    sponsoring_organization_id: "basic-info",
    incentive_type_id: "basic-info",
    urls: "resources",
    eligibility_rule: "eligibility",
    qualifications: "qualifications",
  };

  const isNonEditableField = () =>
    ["urls", "eligibility_rule", "qualifications"].includes(missingField);

  return (
    <div className="panel p-4 pe-5">
      {!isNonEditableField() && (
        <button
          className="btn btn-dark float-end"
          onClick={() => updateIncentive(incentive.id, value)}
        >
          Save
        </button>
      )}
      <div className="row mb-2">
        <div className="p-0">
          <div style={{ width: "500px" }}>
            <a
              href={`/admin/incentives/${incentive.id}/edit#/${RoutingFieldHash[missingField]}`}
              target="_blank"
              style={{ textDecoration: "none", color: "#212529" }}
            >
              <h5>
                <strong>
                  {incentive.name} <i className="bi bi-link"></i>
                </strong>
              </h5>
            </a>
          </div>
        </div>
      </div>
      {missingField === "description" && (
        <div>
          <UIFormLabel label="Description" />
          <TextareaAutosize
            type="text"
            name="Description"
            className="form-control"
            placeholder="Enter description here..."
            value={value}
            onChange={(e) => {
              e.preventDefault();
              setValue(e.target.value);
            }}
          />
        </div>
      )}
      {missingField === "economics" && (
        <div>
          <UIFormLabel label="Economics" />
          <TextareaAutosize
            type="text"
            name="Economics"
            className="form-control"
            placeholder="Enter description here..."
            value={value}
            onChange={(e) => {
              e.preventDefault();
              setValue(e.target.value);
            }}
          />
        </div>
      )}
      {missingField === "incentive_type_id" && (
        <div>
          <UIFormLabel label="Incentive Type" />
          <IncentiveTypeSelector
            incentiveType={value === "" ? undefined : value}
            incentiveTypes={incentiveTypes}
            selectIncentiveType={(option) => {
              setValue(option);
            }}
          />
        </div>
      )}
      {missingField === "sponsoring_organization_id" && (
        <div>
          <UIFormLabel label="Sponsoring Organization" />
          <OrganizationSelector
            organizations={organizations}
            selectOrganization={(id) => {
              setValue(id);
            }}
          />
        </div>
      )}
      {missingField === "slug" && (
        <div>
          <UIFormLabel label="Slug" />
          <input
            type="text"
            className="form-control"
            placeholder=""
            value={value}
            onChange={(e) => {
              e.preventDefault();
              setValue(e.target.value);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MissingFieldCard;
