import React, { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";

import { createPlaidLinkToken, setupPlaidIntegration } from "modules/integrations";

const PlaidLinkHandler = (props) => {
  const company = props.company || {};
  const [linkToken, setLinkToken] = useState();
  const { children } = props;

  useEffect(() => {
    createPlaidLinkToken({company_id: company.id})
      .then((data) => {
        setLinkToken(data["link_token"]);
      });
  }, []);

  const handleOnSuccess = (public_token, metadata) => {
    let params = {
      public_token: public_token,
      company_id: company.id,
      metadata: metadata,
    };

    setupPlaidIntegration(params)
      .then((data) => {
        props.setLocation()
      });
  };

  const handleOnExit = (error, metadata) => {
    if(props.handleExit) {
      props.handleExit()
    }
    //TODO
  };

  const config = {
    onSuccess: handleOnSuccess,
    onExit: handleOnExit,
    token: linkToken,
    //TODO: required for OAuth; if not using OAuth, set to null or omit:
    // receivedRedirectUri: window.location.href,
  };

  const { open, exit, ready } = usePlaidLink(config);

  if(!!props.openWhenReady && ready) {
    open();
  }

  function handleClick(e) {
    e.preventDefault()
    
    if(props.handleClick) {
      props.handleClick()
    }

    open()
  }

  return (
    <div onClick={handleClick}>
      { children }
    </div>
  )
}

export default PlaidLinkHandler;
