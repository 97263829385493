import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { flashSuccess, flashError, flashWarning } from "lib/flash";

import {
  fetchCompanyMatchedIncentives,
  qualifyCompanyForIncentive,
  disqualifyCompanyForIncentive,
  removeCompanyIncentiveQualification,
  removeCompanyIncentiveDisqualification,
} from "modules/companies";
import { EditCompanyContext } from "components/common/companies/contexts/admin-edit-company-context";
import IncentiveSelector from "../../incentives/common/incentive-selector";
import RunQualifyJobButton from "../common/run-qualify-job-button";
import { prettyDisplayPercent, prettyDisplayPrice } from "lib/string";

const Incentives = (props) => {
  const { onUpdateCompany, basicInfo, setBasicInfo, companyId } =
    useContext(EditCompanyContext);
  const [incentives, setIncentives] = useState([]);
  const [fetchedApplications, setFetchedApplications] = useState([]);
  const [selectedIncentive, setSelectedIncentive] = useState(null);
  const clearSelectedIncentive = () => {
    setSelectedIncentive(null);
  };
  const loggedOutDashboardURL = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const reviewStatusMapping = {
    0: "Draft",
    1: "Review",
    2: "Applied",
    3: "Collected",
  };

  const fetchIncentives = () => {
    setIsLoading(true);
    fetchCompanyMatchedIncentives(basicInfo.id)
      .then((data) => {
        setIncentives(data.incentives);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch incentives:", error);
      });
  };

  console.log("incentives", incentives);

  const fetchApplications = () => {
    setIsLoading(true);
    fetchCompanyMatchedIncentives(basicInfo.id)
      .then((data) => {
        setFetchedApplications(data.fetched_applications);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch applications:", error);
        setIsLoading(false);
      });
  };

  const handleIncentiveSelect = (id) => {
    setSelectedIncentive(id);
  };

  const qualifySelectedIncentive = () => {
    qualifyForIncentive(selectedIncentive);
    setSelectedIncentive(null);
  };

  const disqualifySelectedIncentive = () => {
    disqualifyForIncentive(selectedIncentive);
    setSelectedIncentive(null);
  };

  const qualifyForIncentive = (incentiveId) => {
    const companyId = basicInfo.id;
    const manualOverride = true;

    const notes = window.prompt(
      "Are you sure you want to manually qualify this company? If so, please enter notes for qualification:"
    );

    if (notes === null) {
      flashWarning("Qualification cancelled.");
      return;
    }

    qualifyCompanyForIncentive(companyId, incentiveId, notes, manualOverride)
      .then((response) => {
        console.log("Successfully qualified", response);
        flashSuccess(
          `Company ${companyId} is qualified for Incentive ${incentiveId}`
        );
        fetchIncentives();
      })
      .catch((error) => {
        console.error("Failed to qualify", error);
      });
  };

  const removeQualifyForIncentive = (incentiveId) => {
    const companyId = basicInfo.id;

    const confirmation = window.confirm(
      "Are you sure you want to remove this qualification?"
    );

    if (!confirmation) {
      flashWarning("Removal cancelled.");
      return;
    }

    removeCompanyIncentiveQualification(companyId, incentiveId)
      .then((response) => {
        console.log("Successfully removed qualification", response);
        flashSuccess(
          `Removed qualification for Company ${companyId} from Incentive ${incentiveId}`
        );
        fetchIncentives();
      })
      .catch((error) => {
        console.error("Failed to remove qualification", error);
      });
  };

  const disqualifyForIncentive = (incentiveId) => {
    const companyId = basicInfo.id;
    const manualOverride = true;

    const notes = window.prompt(
      "Are you sure you want to manually disqualify this company? If so, please enter notes for disqualification:"
    );

    if (notes === null) {
      flashWarning("Disqualification cancelled.");
      return;
    }

    disqualifyCompanyForIncentive(companyId, incentiveId, notes, manualOverride)
      .then((response) => {
        console.log("Successfully disqualified", response);
        flashSuccess(
          `Company ${companyId} is disqualified from Incentive ${incentiveId}`
        );
        fetchIncentives();
      })
      .catch((error) => {
        console.error("Failed to disqualify", error);
      });
  };

  const removeDisqualifyForIncentive = (incentiveId) => {
    const companyId = basicInfo.id;

    const confirmation = window.confirm(
      "Are you sure you want to remove this disqualification?"
    );

    if (!confirmation) {
      flashWarning("Removal cancelled.");
      return;
    }

    removeCompanyIncentiveDisqualification(companyId, incentiveId)
      .then((response) => {
        console.log("Successfully removed disqualification", response);
        flashSuccess(
          `Removed disqualification for Company ${companyId} from Incentive ${incentiveId}`
        );
        fetchIncentives();
      })
      .catch((error) => {
        console.error("Failed to remove disqualification", error);
      });
  };

  const disqualifiedIncentives = incentives.filter(
    (incentive) => incentive.disqualified_company_id != null
  );

  const qualifiedIncentives = incentives.filter(
    (incentive) => incentive.qualified_company_id != null
  );

  const eligibleIncentives = incentives.filter(
    (incentive) =>
      incentive.eligible_company_id != null &&
      incentive.qualified_company_id == null &&
      incentive.disqualified_company_id == null
  );

  const combinedQualifiedAndEligible = [
    ...qualifiedIncentives,
    ...eligibleIncentives,
  ];

  const handleCopyUrl = () => {
    loggedOutDashboardURL.current = `subcity.com/incentives/${basicInfo.uuid}/dashboard`;
    navigator.clipboard
      .writeText(loggedOutDashboardURL.current)
      .then(() => {})
      .catch((err) => {
        console.error("Could not copy URL", err);
      });
  };

  const copyIncentiveDetailsToClipboard = (name, description) => {
    const textToCopy = `${name} - ${description}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Incentive details copied to clipboard");
      })
      .catch((err) => {
        console.error("Could not copy incentive details", err);
      });
  };

  const renderFetchedApplicationsTable = (data, title) => (
    <div>
      <h4>
        <strong>{title}</strong>
      </h4>
      <table className="table admin-table mb-3 mt-3">
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Id</th>
            <th style={{ width: "50%" }}>Name</th>
            <th style={{ width: "25%" }}></th>
            <th style={{ width: "15%" }}>Status</th>
            <th style={{ width: "5%" }}>Total</th>
          </tr>
        </thead>
        <tbody>
          {data.map((application) => (
            <tr key={application.incentive_id}>
              <td className="admin-metadata">{application.incentive_id}</td>
              <td>{application.name}</td>
              <td></td>
              <td>{reviewStatusMapping[application.review]}</td>
              <td>{prettyDisplayPrice(application.total)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderIncentiveTable = (data, title, showQualifiedIcon = false) => (
    <div>
      <h4>
        <strong>{title}</strong>
      </h4>
      <table className="table admin-table mb-3 mt-3">
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Id</th>
            <th style={{ width: "43%" }}>Name</th>
            <th style={{ width: "22%" }}>Notes</th>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip className="cashflow-tooltip">
                  'Eligible' incentives are automatically determined by the
                  system based on specific criteria such as company address,
                  NAICS Code, or employee count. On the other hand, 'Qualified'
                  and 'Disqualified' statuses can either be set manually by an
                  admin super user or generated via our question-based
                  framework.
                </Tooltip>
              }
            >
              <th style={{ width: "15%" }}>
                Status <i className="bi bi-question-circle"></i>
              </th>
            </OverlayTrigger>
            <th style={{ width: "10%" }}>Match</th>
            <th style={{ width: "5%" }}></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="6" className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">
                    Loading Matched Incentives
                  </span>
                </Spinner>
              </td>
            </tr>
          ) : (
            data.map((incentive) => (
              <OverlayTrigger
                key={incentive.id}
                placement="top"
                overlay={
                  <Tooltip className="cashflow-tooltip">
                    {incentive.short_description}
                  </Tooltip>
                }
              >
                <tr key={incentive.id}>
                  <td className="admin-metadata">{incentive.id}</td>
                  <td>
                    {incentive.name}{" "}
                    {incentive.region_type === "federal" && (
                      <OverlayTrigger
                        key={`fed-icon-overlay-trigger-${incentive.id}`}
                        placement="bottom"
                        delay={{ show: 150, hide: 200 }}
                        overlay={
                          <Tooltip
                            id="button-tooltip"
                            className="cashflow-tooltip"
                          >
                            Federal Program
                          </Tooltip>
                        }
                      >
                        <i
                          className="bi bi-shield-fill-plus"
                          style={{ color: "#6A7894", marginLeft: "8px" }}
                          title="Federal Program"
                        ></i>
                      </OverlayTrigger>
                    )}
                    {incentive.state && (
                      <div className="d-inline px-2 py-1 text-success-subtle bg-success-subtle border border-success-subtle rounded-1 me-1">
                        <span className="fw-bold">{incentive.state}</span>
                      </div>
                    )}
                  </td>
                  <td>
                    {incentive.disqualified_notes} {incentive.qualified_notes}
                  </td>
                  <td>
                    {incentive.qualified_company_id != null && (
                      <div className="d-inline px-2 py-1 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-1 me-1">
                        <span className="fw-bold">Qualified</span>
                      </div>
                    )}
                    {incentive.disqualified_company_id != null && (
                      <div className="d-inline px-2 py-1 text-danger-emphasis bg-danger-subtle border border-danger-subtle rounded-1 me-1">
                        <span className="fw-bold">Disqualified</span>
                      </div>
                    )}
                    {incentive.eligible_company_id != null && (
                      <div className="d-inline px-2 py-1 text-warning-emphasis bg-warning-subtle border border-warning-subtle rounded-1 me-1">
                        <span className="fw-bold">Eligible</span>
                      </div>
                    )}
                  </td>
                  <td>
                    {prettyDisplayPercent(incentive.qualification_score, 0)}
                  </td>
                  <td style={{ whiteSpace: "nowrap", width: "1%" }}>
                    <div className="d-flex justify-content-end">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="outline-secondary"
                          id="dropdown-basic"
                          size="sm"
                        >
                          <i className="bi bi-three-dots"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href={`/admin/incentives/${incentive.id}/edit#/basic-info`}
                            target="_blank"
                          >
                            View Incentive
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              copyIncentiveDetailsToClipboard(
                                incentive.name,
                                incentive.description
                              )
                            }
                          >
                            Copy Incentive Description
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              copyIncentiveDetailsToClipboard(
                                incentive.name,
                                incentive.short_description
                              )
                            }
                          >
                            Copy Short Description
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => qualifyForIncentive(incentive.id)}
                          >
                            Qualify Company
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              removeQualifyForIncentive(incentive.id)
                            }
                          >
                            Remove Qualification
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => disqualifyForIncentive(incentive.id)}
                          >
                            Disqualify Company
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              removeDisqualifyForIncentive(incentive.id)
                            }
                          >
                            Remove Disqualification
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              </OverlayTrigger>
            ))
          )}
        </tbody>
      </table>
    </div>
  );

  useEffect(() => {
    setIsLoading(true);
    fetchCompanyMatchedIncentives(basicInfo.id)
      .then((data) => {
        setIncentives(data.incentives);
        setFetchedApplications(data.fetched_applications);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch incentives and applications:", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <div className="mb-3 float-end d-flex align-items-center">
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip className="cashflow-tooltip">
              Click here to copy the URL for the company's logged out dashboard.
            </Tooltip>
          }
        >
          <button className="btn btn-outline-dark me-2" onClick={handleCopyUrl}>
            <i className="bi bi-clipboard"></i>
          </button>
        </OverlayTrigger>
        <IncentiveSelector
          onIncentiveSelect={handleIncentiveSelect}
          selectedIncentive={selectedIncentive}
        />
        <RunQualifyJobButton fetchIncentives={fetchIncentives} />

        {selectedIncentive && (
          <>
            <button
              className="btn btn-outline-primary ms-2"
              style={{ width: "90px" }}
              onClick={qualifySelectedIncentive}
            >
              Qualify
            </button>
            <button
              className="btn btn-outline-action ms-2"
              style={{ width: "90px" }}
              onClick={disqualifySelectedIncentive}
            >
              Disqualify
            </button>
            <button
              className="btn btn-light ms-2"
              onClick={clearSelectedIncentive}
            >
              <i className="bi bi-x"></i>
            </button>
          </>
        )}
      </div>
      <div>
        {renderIncentiveTable(
          combinedQualifiedAndEligible,
          "Matched Incentives",
          true
        )}
        {renderIncentiveTable(
          disqualifiedIncentives,
          "Disqualified Incentives"
        )}
        {renderFetchedApplicationsTable(fetchedApplications, "Applications")}
      </div>
    </>
  );
};

export default Incentives;
