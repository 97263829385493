import React from 'react';
import ReactLoading from 'react-loading'

const Loading = (props) => {
  return (
    <div className="d-flex align-items-center justify-content-center" style={{'height': '300px'}}>
      <div className='loading-file'>
        <ReactLoading type={'spin'} color={'#00A2E9'} height={50} width={50} />
      </div>
    </div>
  )
}

export default Loading;