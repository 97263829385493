import React, { useEffect, useRef, useState } from "react";
import { useSetState } from "react-hanger";
import { searchCompanyDropdown } from "modules/user/companies";
import { fetchAdminCompanies } from "modules/companies";
import { flashSuccess } from "lib/flash";

const UINavCompaniesDropdown = (props) => {
  const [currentCompany, setCurrentCompany] = useState(props.currentCompany);
  const [userCompanies, setUserCompanies] = useState(props.userCompanies);
  const [total_pages, setTotalPages] = useState(props.total_pages);
  const [flashNotice] = useState(props.flash_notice);
  const caller = props.onChange;
  const isEdit = props.isEdit;
  const dropdownStyle = props.dropdownStyle;

  const { state, setState } = useSetState({
    query: "",
    page: 1,
    flashNotice: flashNotice,
  });

  const handleClick = (company) => {
    caller(company.id);
    setCurrentCompany(company);
  };

  const handleChange = (e) => {
    let new_state = state;
    new_state.query = e.target.value;
    new_state.page = 1;
    setState(new_state);
  };

  const handlePaginationPrevious = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let new_state = state;
    new_state.page -= 1;
    setState(new_state);
  };

  const handlePaginationNext = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let new_state = state;
    new_state.page += 1;
    setState(new_state);
  };

  const filterCompanies = () => {
    if (isEdit) {
      searchCompanyDropdown(state).then((data) => {
        setUserCompanies(data.companies);
        setTotalPages(data.total_pages);
      });
    } else {
      fetchAdminCompanies($.extend({}, state, { per_page: 10 })).then(
        (data) => {
          setUserCompanies(data.companies);
          setTotalPages(data.total_pages);
        }
      );
    }
  };

  const flashMessage = () => {
    if (state.flashNotice) {
      flashSuccess(state.flashNotice);
    }
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      flashMessage();
      isFirstRun.current = false;
      return;
    }

    filterCompanies();
  }, [state]);

  const dropdownCompanyRow = (company, index) => {
    return (
      <div className="nav-item px-3 py-2" key={`company-${index}`}>
        <div className="d-flex justify-content-between">
          <div className="d-flex me-2">
            <i className="bi bi-briefcase me-2" />
            <div
              className="text-nowrap text-decoration-none company-dropdown-name"
              rel="nofollow"
              onClick={() => handleClick(company)}
            >
              {company.name}
            </div>
          </div>
          {company.has_edit_access && isEdit ? (
            <a
              className="btn btn-muted btn-small py-0"
              rel="nofollow"
              data-method="post"
              href={`/company/current?id=${company.id}&return_to=%2Fcompany%2Fedit`}
            >
              Edit
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="nav-item dropdown">
      <a
        className={`nav-link dropdown-toggle ${dropdownStyle.dropdownButton}`}
        href="#"
        id="navbarDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {isEdit ? (
          <span>
            <i className="bi bi-briefcase me-2" />
            {currentCompany !== null && currentCompany.name}
          </span>
        ) : (
          <span className="overflow-auto">
            {(currentCompany !== null && currentCompany.name) ||
              "Select Company"}
          </span>
        )}
      </a>

      <div
        className="dropdown-menu dropdown-menu-start dropdown-companies pt-3"
        style={{ minWidth: `${dropdownStyle.dropdownWidth}` }}
        aria-labelledby="navbarDropdown"
        data-bs-popper="none"
        aria-expanded="false"
      >
        <div className="pb-3 px-3">
          <div>
            <div className="d-flex">
              <input
                type="text"
                name="search"
                id="search"
                value={state.query}
                placeholder="Search"
                className="form-control search-field w-100"
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>
        </div>

        {userCompanies !== undefined &&
          userCompanies.map((company, index) => {
            return (
              <div key={`company-option-${index}`}>
                {currentCompany != null &&
                  index == 0 &&
                  state.query == "" &&
                  state.page == 1 && (
                    <div className="nav-item px-3 py-2">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex me-2">
                          <i className="bi bi-briefcase me-2" />
                          <div
                            className="text-nowrap text-decoration-none company-dropdown-name"
                            rel="nofollow"
                            onClick={() => handleClick(currentCompany)}
                          >
                            {currentCompany.name}
                          </div>
                        </div>
                        {currentCompany.has_edit_access && isEdit ? (
                          <a
                            className="btn btn-muted btn-small py-0"
                            rel="nofollow"
                            data-method="post"
                            href={`/company/current?id=${currentCompany.id}&return_to=%2Fcompany%2Fedit`}
                          >
                            Edit
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                {((currentCompany != null && company.id != currentCompany.id) ||
                  state.query != "" ||
                  currentCompany == null) &&
                  dropdownCompanyRow(company)}
              </div>
            );
          })}

        {total_pages > 1 ? (
          <div className="d-flex justify-content-between border-top pt-3 px-3">
            <button
              className={`text-decoration-none badge rounded-pill bg-dark align-items-center ${
                state.page === 1 ? "disable-pagination" : "enable-pagination"
              }`}
              disabled={state.page === 1}
              onClick={handlePaginationPrevious}
            >
              Previous
            </button>
            <button
              className={`text-decoration-none badge rounded-pill bg-dark align-items-center ${
                state.page === total_pages
                  ? "disable-pagination"
                  : "enable-pagination"
              }`}
              disabled={state.page === total_pages}
              onClick={handlePaginationNext}
            >
              Next
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UINavCompaniesDropdown;

UINavCompaniesDropdown.defaultProps = {
  isEdit: true,
  dropdownStyle: {
    dropdownWidth: "300px",
    dropdownButton: "",
  },
};
