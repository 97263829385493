import React, { useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import axios from "axios";

import {
  withUINavTabs,
  UINavPanel,
  UINavSection,
  UINavHorizontalInternal,
} from "components/ui";

import { collapseProjectCategories } from "modules/project";

import Overview from "./overview";
import Estimate from "./estimate";
import Transactions from "./transactions";
import Quotes from "./quotes";

import Edit from "./edit";

const Details = (props) => {
  const [show, setShow] = useState(false);
  const { project } = props;

  const [estimateCategories, setEstimateCategories] = useState([]);

  const updateEstimateCategory = (
    id,
    isCollapsed,
    description,
    parent_id,
    markupTotal
  ) => {
    setEstimateCategories((prevEstimates) => {
      const updatedEstimate = {
        id,
        isCollapsed,
        description,
        parent_id,
        markupTotal,
      };
      const updatedEstimates = prevEstimates.map((estimate) =>
        estimate.id === id ? updatedEstimate : estimate
      );
      if (!updatedEstimates.find((estimate) => estimate.id === id)) {
        // If the estimate doesn't exist in the array, add it
        updatedEstimates.push(updatedEstimate);
      }
      return updatedEstimates;
    });
  };

  const handleExport = () => {
    axios({
      url: `/projects/${project.id}/download/estimate_line_items`,
      method: "GET",
      responseType: "blob",
      headers: {
        Accept: "text/csv",
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "project_estimate_line_items.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCollapse = () => {
    collapseProjectCategories(props.project.id, true);
    window.location.reload(true);
  };

  const handleExpand = () => {
    collapseProjectCategories(props.project.id, false);
    window.location.reload(true);
  };

  const handleEditProjectMetadata = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const handleEditProjectTemplates = (e) => {
    window.location.href = "/company/edit#/project-templates";
  };

  return (
    <div className="p-4 mt-4 panel admin-company-form">
      <div className="row">
        <div className="col-md-12">
          <DropdownButton
            className="float-end"
            id="exportMenu"
            title={<i className="bi bi-gear-fill"></i>}
          >
            <Dropdown.Item onClick={handleEditProjectMetadata}>
              <i className="bi bi-pencil pencil-button-stable"></i> Edit Project
              Details
            </Dropdown.Item>
            <Dropdown.Item onClick={handleEditProjectTemplates}>
              <i className="bi bi-layers"></i> Edit Project Templates
            </Dropdown.Item>
            <Dropdown.Item onClick={handleExport}>
              <i className="bi bi-cloud-arrow-down"></i> Export Project to csv
            </Dropdown.Item>
            <Dropdown.Item onClick={handleExpand}>
              <i className="bi bi-chevron-down"></i> Expand all categories
            </Dropdown.Item>
            <Dropdown.Item onClick={handleCollapse}>
              <i className="bi bi-chevron-right"></i> Collapse all categories
            </Dropdown.Item>
          </DropdownButton>
          <h1>{project.name}</h1>
          {project.other_versions ? (
            <div className="d-inline px-2 py-1 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3">
              {project.version === 1
                ? "1 - Initial Estimate"
                : `CO ${project.version}${
                    project.version_name ? ` - ${project.version_name}` : ""
                  }`}
            </div>
          ) : null}
        </div>
      </div>

      <Edit show={show} setShow={setShow} project={project} />

      <div className="row mt-3">
        <div className="col-md-12">
          <UINavHorizontalInternal style="tabs" tabs={props.project.tabs} />
        </div>

        <div className="col-md-12">
          <UINavPanel>
            <UINavSection tab="/overview">
              <Overview {...props} />
            </UINavSection>
            <UINavSection tab="/estimate">
              <Estimate
                {...props}
                estimateCategories={estimateCategories}
                updateEstimateCategory={updateEstimateCategory}
              />
            </UINavSection>
            <UINavSection tab="/quotes">
              <Quotes
                {...props}
                estimateCategories={estimateCategories}
                updateEstimateCategory={updateEstimateCategory}
              />
            </UINavSection>
            <UINavSection tab="/transactions">
              <Transactions {...props} />
            </UINavSection>
          </UINavPanel>
        </div>
      </div>
    </div>
  );
};

export default withUINavTabs(Details);
