import React, { useEffect, useState, useCallback } from "react";
import AsyncSelect from "react-select/async";
import { fetchReferralSources } from "modules/referral-sources";

const ReferralSourceSelector = ({ value, label, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (value && label) {
      setSelectedOption({ value, label });
    }
  }, [value, label]);

  const loadReferralSources = (inputValue, callback) => {
    fetchReferralSources()
      .then((data) => {
        const formattedData = data.map((referral) => ({
          label: referral.referral_source_name,
          value: referral.id,
        }));
        callback(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching referral sources", error);
        callback([]);
      });
  };

  const handleSelectChange = useCallback(
    (selectedOption) => {
      onSelect(selectedOption);
      setSelectedOption(selectedOption);
    },
    [onSelect]
  );

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={loadReferralSources}
      isClearable={true}
      placeholder="Select Referral Source..."
      onChange={handleSelectChange}
      value={selectedOption}
    />
  );
};

export default ReferralSourceSelector;
