import React, { useCallback } from "react";
import PropTypes from "prop-types";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { parseGoogleMapsApiResult } from "lib/google";

const CHROME_AUTOFILL_DISABLE_HACK = "xyz123";

const UIAddressInputWithSuggestions = ({
  value,
  onChange,
  onSelectSuggestedAddress,
}) => {
  const handleSelect = useCallback(
    async (selectedValue) => {
      try {
        const results = await geocodeByAddress(selectedValue);
        const parsedAddress = parseGoogleMapsApiResult(results[0]);
        onChange(parsedAddress.address_line_1);
        onSelectSuggestedAddress(parsedAddress);
      } catch (error) {
        console.error("Error selecting address:", error);
      }
    },
    [onChange, onSelectSuggestedAddress]
  );

  const renderSuggestions = useCallback(
    ({ loading, suggestions, getSuggestionItemProps }) => {
      if (!loading && suggestions.length === 0) return null;

      return (
        <div className="address-suggestion-results card">
          <ul className="list-group list-group-flush">
            {loading && (
              <li className="list-group-item">
                Loading address suggestions...
              </li>
            )}
            {suggestions.map((suggestion) => (
              <li
                className="list-group-item"
                {...getSuggestionItemProps(suggestion, {
                  style: {
                    backgroundColor: suggestion.active ? "#fff3cd" : "#fff",
                  },
                })}
                key={suggestion.placeId}
              >
                {suggestion.description}
              </li>
            ))}
          </ul>
        </div>
      );
    },
    []
  );

  return (
    <div className="ui-label-input position-relative">
      <PlacesAutocomplete
        value={value}
        onChange={onChange}
        onSelect={handleSelect}
        debounce={250}
        searchOptions={{ componentRestrictions: { country: ["US"] } }}
      >
        {({ getInputProps, ...rest }) => (
          <>
            <input
              className="form-control"
              {...getInputProps({
                placeholder: "",
                name: CHROME_AUTOFILL_DISABLE_HACK,
                id: CHROME_AUTOFILL_DISABLE_HACK,
                autoComplete: CHROME_AUTOFILL_DISABLE_HACK,
              })}
            />
            {renderSuggestions(rest)}
          </>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

UIAddressInputWithSuggestions.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelectSuggestedAddress: PropTypes.func.isRequired,
};

export default UIAddressInputWithSuggestions;
