import React from "react";
import AsyncSelect from "react-select/async"
import { some } from "lodash";

import { fetchAdminUsers } from "modules/users";

const UserAutocomplete = ({ currentUsers, onAddUser }) => {

  const onOptionsLoad = (value, cb) => {
    fetchAdminUsers({ query: value }).then((res) => {
      cb(
        res.users.map((row) => ({
          value: row,
          label: `${row.full_name} - ${row.email}`
        }))
      );
    });
  };

  const filterOption = (option) =>
    !some(currentUsers, (c) => c.user_id == option.value.user_id)

  const onUserSelect = (option) => {
    onAddUser(option.value)
  };

  return (
    <div>
      <AsyncSelect
        value={null}
        loadOptions={onOptionsLoad}
        filterOption={filterOption}
        placeholder="Search Users..."
        onChange={onUserSelect}
      />
    </div>
  );
};

export default UserAutocomplete;
