import React, { useEffect, useState } from "react";
import { useSetState, useStateful } from "react-hanger";
import { UILabelInput, UINavDropdown, UIFormLabel } from "components/ui";

import {
  createIncentiveUrl,
  updateIncentiveUrl,
  deleteIncentiveUrl,
} from "modules/incentive-urls";

import { flashSuccess, flashError, flashWarning } from "lib/flash";

const shurley = require("shurley");

const IncentiveUrlRow = (props) => {
  const { url } = props;

  const initializeViewState = () => {
    if (props.add) return "edit";
    return "preview";
  };

  const viewState = useStateful(initializeViewState());

  const [urlState, setUrlState] = useState(url);
  useEffect(() => {
    setUrlState(url);
  }, [url]);

  const handleFieldChange = (field, value) => {
    setUrlState({ ...urlState, [field]: value.target.value });
  };

  const handleEdit = (e) => {
    e.preventDefault();
    viewState.setValue("edit");
  };

  const handleDelete = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure to delete this record?")) {
      deleteIncentiveUrl(urlState).then((response) => {
        window.location.reload(false);
      });
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    viewState.setValue("preview");
  };

  const handleCancelDelete = (e) => {
    e.preventDefault();
    viewState.setValue("edit");
  };

  const handleForceSave = (e) => {
    let props = {
      id: urlState.id,
      proposed_url: null,
    };

    if (e) {
      // check if change was approved or rejected.
      props.url = urlState.proposed_url;
    } else {
      props.url = urlState.url;
    }

    return updateUrl(props);
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (!isValidURL(urlState.url)) return;

    let props = {
      proposed_url: urlState.url,
      id: urlState.id,
    };

    return updateUrl(props);
  };

  const updateUrl = (props) => {
    updateIncentiveUrl(props).then((res) => {
      flashSuccess(`${urlState.url} updated.`);
      if (res) {
        setUrlState(res);
      }
      viewState.setValue("preview");
    });
  };

  const isValidURL = (url) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    if (regex.test(url) == false) {
      flashError("Please ensure this is a valid URL.");
    }
    return regex.test(url);
  };

  const response_code_class_name = () => {
    let class_name;

    if (urlState.is_broken) {
      class_name = "response response-broken";
    } else if (urlState.is_redirect) {
      class_name = "response response-warning";
    } else {
      class_name = "response";
    }

    return class_name;
  };

  const renderResponseCodeBadge = () => {
    if (!urlState || !urlState.response_code) return null;

    if (urlState.is_redirect) {
      return (
        <span class="badge rounded-pill bg-warning text-dark">
          {urlState.response_code}
        </span>
      );
    } else if (urlState.is_broken) {
      return (
        <span class="badge rounded-pill bg-danger">
          {urlState.response_code}
        </span>
      );
    } else {
      return (
        <span class="badge rounded-pill bg-success">
          {urlState.response_code}
        </span>
      );
    }
  };

  switch (viewState.value) {
    case "edit":
      return (
        <tr>
          <td colSpan="4">
            <form className="form p-2">
              <div className="form-group">
                <UIFormLabel label="Label" />
                <input
                  type="text"
                  name="label"
                  className="form-control"
                  defaultValue={urlState.label}
                  onChange={(value) => {
                    handleFieldChange("label", value);
                  }}
                  placeholder="Enter the Label Here"
                />
              </div>

              <div className="form-group mt-3">
                <UIFormLabel label="URL" />
                <textarea
                  type="text"
                  name="url"
                  className="form-control"
                  defaultValue={urlState.url}
                  onChange={(value) => {
                    handleFieldChange("url", value);
                  }}
                  placeholder="Enter the URL Here"
                />
              </div>

              <div className="form-group mt-3">
                <label>
                  <strong>Page Title</strong>
                </label>
                <p>{urlState.title ? urlState.title : "No Page Title"}</p>
              </div>

              <div className="form-group mt-3">
                <UIFormLabel label="Pending Url Changes" />
                <p>
                  {urlState.proposed_url
                    ? urlState.proposed_url
                    : "No Proposed Changes"}
                </p>
              </div>

              <div className="form-group mt-3">
                <UIFormLabel label="Last Crawled At" />
                <p>
                  {urlState.last_crawled_at
                    ? new Date(urlState.last_crawled_at).toLocaleDateString()
                    : null}
                </p>
              </div>

              <div className="form-group mt-3">
                <UIFormLabel label="HTTP Status" />
                <br />
                {renderResponseCodeBadge()}
              </div>

              <div className="favicon mt-3">
                <UIFormLabel label="Favicon" />
                <br />
                <img
                  className="favicon"
                  src={`data:image/jpeg;base64,${urlState.favicon}`}
                />
              </div>

              <div className="float-end">
                <button
                  className="btn btn-light btn-sm me-2"
                  onClick={handleCancel}
                >
                  Cancel
                </button>

                <button
                  className="btn btn-danger btn-sm me-2"
                  onClick={handleDelete}
                >
                  Delete
                </button>
                {urlState.proposed_url ? (
                  <>
                    <button
                      className="btn btn-warning btn-sm me-2"
                      onClick={() => handleForceSave(false)}
                    >
                      Reject
                    </button>

                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleForceSave(true)}
                    >
                      Accept
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleUpdate}
                  >
                    Propose Change
                  </button>
                )}
              </div>
            </form>
          </td>
        </tr>
      );
    case "preview":
      return (
        <tr>
          <td>
            <div className="d-flex">
              <div
                className="d-block me-3"
                style={{ width: "25px", height: "10px" }}
              >
                {urlState.favicon ? (
                  <img
                    style={{ width: "25px" }}
                    src={`data:image/jpeg;base64,${url.favicon}`}
                  />
                ) : (
                  <i className="bi bi-globe"></i>
                )}
              </div>

              {urlState.label ? urlState.label : "No Page Title"}
            </div>
          </td>

          <td>
            <a href={urlState.url} target="_blank">
              {urlState.url}
            </a>
          </td>

          <td>{renderResponseCodeBadge()}</td>
          <td>
            <button className="btn btn-sm btn-muted" onClick={handleEdit}>
              Edit
            </button>
          </td>
        </tr>
      );
    default:
      return null;
  }
};

export default IncentiveUrlRow;
