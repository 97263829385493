import React from "react";
import IncentiveCard from "./incentive-card";

import {
  withUINavTabs,
  UINavHorizontalInternal,
  UINavSection,
  UINavPanel,
} from "components/ui";

const Incentives = (props) => {
  return (
    <div className="mb-3">
      <UINavHorizontalInternal
        tabs={[
          {
            name: "Applications",
            link: "/applications",
          },
          {
            name: "Incentives",
            link: "/incentives",
          },
        ]}
      />

      <UINavPanel>
        <UINavSection tab="/applications">
          <div className="row">
            {props.applications.map((application, index) => {
              return (
                <div className="col-md-4" key={`incentive-${index}`}>
                  <IncentiveCard
                    incentive={application}
                    location={`/applications/${application.slug}`}
                  />
                </div>
              );
            })}
          </div>
        </UINavSection>

        <UINavSection tab="/incentives">
          <div className="row">
            {props.incentives.map((incentive, index) => {
              return (
                <div className="col-md-4" key={`incentive-${index}`}>
                  <IncentiveCard
                    incentive={incentive}
                    location={`/incentives/${incentive.slug}`}
                  />
                </div>
              );
            })}
          </div>
        </UINavSection>
      </UINavPanel>
    </div>
  );
};

export default withUINavTabs(Incentives);
