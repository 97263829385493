import CalculatorTree from 'lib/calculator-tree'

export const generalCalculatorV1 = new CalculatorTree(
  [{
    key: 'california',
    component: 'Question',
    title: 'Are you located in California?',
    options: ['Yes', 'No'],
    defaultAnswer: 'Yes',
    validate: true,
    acceptedValues: ['Yes'],
    errorMessage: "Please reach out to Alex White at Subcity if you have any questions about your specific situation related to economic incentives alex@subcity.com.",
    shouldRenderChild: (value) => {
      return value === 'Yes'
    },
    children: [{
      key: 'manufacturer',
      component: 'Question',
      title: 'Are you a manufacturer?',
      options: ['Yes', 'No'],
      defaultAnswer: 'Yes',
      validate: true,
      acceptedValues: ['Yes'],
      errorMessage: "We are currently only serving manufacturers at this time. Sign up to get the latest when we have more incentives are available for your industry.",
      shouldRenderChild: (value) => {
        return value === 'Yes'
      },
      children: [{
        key: 'employee_count',
        component: 'SliderInput',
        title: "Employee Count",
        defaultValue: 10,
        minValue: 1,
        maxValue: 500,
        unit: 'numeric',
        shouldRenderChild: (value) => {
          return value > 0
        },
      }, {
        key: 'new_employees',
        component: 'SliderInput',
        title: "How many new employees will you be hiring over the next 3-5 years?",
        defaultValue: 10,
        minValue: 0,
        maxValue: 1000,
        unit: 'numeric',
        shouldRenderChild: (value) => {
          return value > 0
        },
        children: [{
          key: 'training_new_employees',
          component: 'Question',
          title: 'Will any new employees require upskilling?',
          options: ['Yes', 'No'],
          defaultAnswer: 'Yes',
          shouldRenderChild: (value) => {
            return true;
          }
        }, {
          key: 'upskilling_existing_employees',
          component: 'Question',
          title: 'Will your current employees require upskilling?',
          options: ['Yes', 'No'],
          defaultAnswer: 'Yes',
          shouldRenderChild: (value) => {
            return true;
          }
        }]
      }, {
        key: 'average_salary',
        component: 'SliderInput',
        title: "What is the average salary you'll be paying these new hires over the next three to five years?",
        defaultValue: 50000,
        minValue: 32000,
        maxValue: 125000,
        unit: 'currency',
        shouldRenderChild: (value) => {
          return true
        }
      }, {
        key: 'targeted_group',
        component: 'SliderInput',
        title: 'How many employees will be hired from a targeted group this year.',
        defaultValue: 1,
        minValue: 1,
        maxValue: 50,
        unit: 'numeric',
        shouldRenderChild: (value) => {
          return true
        }
      }, {
        key: 'develop_new_products',
        component: 'Question',
        title: 'Does your company develop new products and/or improve existing processes?',
        options: ['Yes', 'No'],
        defaultAnswer: 'No',
        shouldRenderChild: (value) => {
          return value === 'Yes'
        },
        children: [{
          key: 'engineer_scientists',
          title: 'Hire US-based engineers, developers, or scientists.',
          component: 'Question',
          options: ['Yes', 'No'],
          defaultAnswer: 'Yes',
          shouldRenderChild: (value) => {
            return true; 
          },
        }, {
          key: 'product_prototype',
          title: 'Design and develops product prototypes and/or software.',
          component: 'Question',
          options: ['Yes', 'No'],
          defaultAnswer: 'Yes',
          shouldRenderChild: (value) => {
            return true; 
          },
        }, {
          key: 'product_experimentation', 
          title: 'Performs experimentation and validation on products, manufacturing processes, and/or software.',
          component: 'Question',
          options: ['Yes', 'No'],
          defaultAnswer: 'Yes',
          shouldRenderChild: (value) => {
            return true; 
          },
        }]
      }, {
        key: 'capital_expenditures',
        component: 'SliderInput',
        title: 'Roughly how much do you plan to spend in purchasing or leasing real estate, equipment & other capital expenditures over the next 5 years?',
        defaultValue: 250000,
        minValue: 50000,
        maxValue: 10000000,
        unit: 'currency',
        shouldRenderChild: (value) => {
          return true
        }
      }, {
        key: '20_percent_decline',
        component: 'Question',
        title: 'Did your business experience a significant decline (20%+) in gross receipts when comparing any quarter in tax year 2019 to the same quarter in tax year 2020/2021?',
        options: ['Yes', 'No'],
        defaultAnswer: 'Yes',
        shouldRenderChild: (value) => {
          return value === 'Yes'
        },
      }, {
        key: 'energy_bill',
        component: 'SliderInput',
        title: 'Monthly energy bill',
        defaultValue: 10000,
        minValue: 5000,
        maxValue: 100000,
        unit: 'currency',
        shouldRenderChild: (value) => {
          return true
        }
      }, {
        key: 'recruited_out_of_state',
        component: 'Question',
        title: 'Have you been recruited by another state to expand or relocate your business?',
        options: ['Yes', 'No'],
        defaultAnswer: 'Yes',
        shouldRenderChild: (value) => {
          return value === 'Yes'
        },
      }]
    }]
  }],
  (answers) => {
    let total = 0;

    // 1. CA Manufacturing and Research & Development Equipment Exemption 
    total += answers['capital_expenditures'].value * 0.039375

    // 2. Employee Retention Tax Credit
    if(answers['20_percent_decline'].value === 'Yes') {
      total += answers['employee_count'].value * 7000; 
    }

    // 3. R&D Tax Credit 
    if(answers['develop_new_products'].value === 'Yes' && (
      answers['engineer_scientists'].value === 'Yes' || 
      answers['product_prototype'].value === 'Yes' || 
      answers['product_experimentation'].value === 'Yes'
    )) {
      total += answers['employee_count'].value * 10; 
    }

    // 4. Employee Training Panel (ETP)
    if(answers['new_employees'].value > 0 && 
        answers['training_new_employees'].value === 'Yes' && 
        answers['training_new_employees'].value === 'Yes') {
      total += answers['new_employees'].value * 6400;
    }

    // 5. NEC/WOTC 
    total += answers['targeted_group'].value * 9600;

    // 6. California Competes Tax Credit
    if (answers['recruited_out_of_state'].value === 'Yes') {
      total += answers['capital_expenditures'].value + (
        answers['average_salary'].value * answers['new_employees'].value * 0.349999
      )
    }

    // 7. Pacific Gas & Electric (PG&E) Economic Development Rate
    if(answers['recruited_out_of_state'].value === 'Yes') {
      total += answers['energy_bill'].value * 0.15;
    }

    return total;
  }
)

export default generalCalculatorV1;
