import axios from 'lib/axios'

export const createIncentiveFormSchemaComponent = (params = {}) => {
    return axios.post('/admin/incentive_form_schema_components', params)
    .then(response => {
      console.log('Component created successfully', response.data);
      return response.data;
    })
    .catch(error => {
      console.error('Error creating component', error.response);
      throw error;
    });

}

export const updateIncentiveFormSchemaComponent = ({ id, name, schema, version }) => {
    return axios.put(`/admin/incentive_form_schema_components/${id}`, {
        incentive_form_schema_component: { name, schema, version }
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    })

    .then(response => {
      console.log('Component updated successfully', response.data);
      return response.data;
    })
    .catch(error => {
      console.error('Error updating component', error.response);
      throw error;
    });

}

export const softDeleteIncentiveFormSchemaComponent = (id) => {
  return axios.patch(`/admin/incentive_form_schema_components/${id}/soft_delete`, {}, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(response => {
    console.log('Component soft deleted successfully', response.data);
    return response.data;
  })
  .catch(error => {
    console.error('Error soft deleting component', error.response);
    throw error;
  });
};
