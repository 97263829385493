import React, { useState, useEffect } from "react";

import { findDeep } from "deepdash-es/standalone";
import { filter, isEmpty } from "lodash";

import { saveRuleAdminIncentive } from "modules/incentives";
import { fetchAdminActiveMetaFields } from "modules/meta-eligibility-fields";
import { flashError, flashSuccessLater } from "lib/flash";

import TextareaAutosize from "react-textarea-autosize";

import IncentiveEligibility from "./rule-builder/incentive-eligibility";
import RuleGroup from "./rule-builder/rule-group";
import TestingRule from "./rule-builder/testing-rule";
import { emptyGroup } from "./rule-builder/rule-utils";

const Eligibility = ({ incentive }) => {
  const [eligibilityRule, setEligibilityRule] = useState(
    incentive.eligibility_rule || emptyGroup()
  );
  const [jsonRule, setJsonRule] = useState({});
  const [fieldOptions, setFieldOptions] = useState([]);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    setEligibilityRule(incentive.eligibility_rule || emptyGroup());
  }, [incentive]);

  useEffect(() => {
    fetchAdminActiveMetaFields().then((res) => setFieldOptions(res));
  }, []);

  const getNestedJson = (id) => {
    let ruleData = findDeep(eligibilityRule, (objId) => objId == id);
    if (ruleData == null) return;

    return ruleData.parent;
  };
  const updateRuleJSON = (id, key, value) => {
    let ruleData = getNestedJson(id);
    ruleData[key] = value;
    setEligibilityRule({ ...eligibilityRule });
  };

  const addRuleJSON = (id, rule) => {
    let ruleData = getNestedJson(id);
    ruleData.children.push(rule);
    setEligibilityRule({ ...eligibilityRule });
  };

  const removeRuleJSON = (id, ruleId) => {
    let ruleData = getNestedJson(id);
    const filteredRules = filter(
      ruleData.children,
      (rule) => rule.id !== ruleId
    );
    ruleData.children = filteredRules;
    setEligibilityRule({ ...eligibilityRule });
  };

  const saveRule = () => {
    saveRuleAdminIncentive({
      incentive: {
        eligibility_rule: eligibilityRule,
        json_rule: jsonRule,
      },
      id: incentive.id,
    })
      .then((res) => {
        flashSuccessLater("Saved eligibility rule");
        location.reload(true);
      })
      .catch((errors) => {
        errors.map((error) => flashError(error));
      });
  };

  const clearAll = () => {
    setEligibilityRule(emptyGroup());
    setReset(!reset);
  };

  return (
    <div className="rule-builder">
      <div className="mt-5">
        <h3>Rules Builder</h3>
        {!isEmpty(fieldOptions) && (
          <RuleGroup
            rule={eligibilityRule}
            fieldOptions={fieldOptions}
            updateRuleJSON={updateRuleJSON}
            addRuleJSON={addRuleJSON}
            removeRuleJSON={removeRuleJSON}
            removeRule={() => {}}
            clearAll={clearAll}
            key={reset}
          />
        )}
        <div className="d-flex justify-content-end">
          <div className="float-end btn-group">
            <button className="btn btn-primary mt-3 mb-3" onClick={saveRule}>
              Save Rule
            </button>
          </div>
        </div>
      </div>

      <div className="mt-5">
        {!isEmpty(fieldOptions) && (
          <TestingRule
            ruleBlock={eligibilityRule}
            incentiveId={incentive.id}
            jsonRule={jsonRule}
            fieldOptions={fieldOptions}
            setJsonRule={setJsonRule}
          />
        )}
      </div>

      <div className="mt-5">
        <IncentiveEligibility incentiveId={incentive.id} />
      </div>
    </div>
  );
};

export default Eligibility;
