import React from "react";
import { prettyDisplayPrice } from "lib/string";

const AdminStatsApplicationsTable = ({ metricsByAppliedIncentive }) => {
  return (
    <div className="mt-0">
      <table className="table admin-table mt-4">
        <thead>
          <tr>
            <th>Incentive Name</th>
            <th>Companies</th>
            <th>Draft</th>
            <th>Reviewed</th>
            <th>Applied</th>
            <th>Collected</th>
            <th>Applications</th>
            <th>Total Dollars</th>
            <th>Total Collected</th>
          </tr>
        </thead>
        <tbody>
          {metricsByAppliedIncentive.length === 0 ? (
            <tr>
              <td colSpan="6">Loading...</td>
            </tr>
          ) : (
            metricsByAppliedIncentive.map((item, index) => (
              <tr key={index}>
                <td>
                  <a href={`/admin/incentives/${item.incentive_id}/edit`}>
                    {item.name}
                  </a>
                </td>
                <td>{item.companies_applied}</td>
                <td style={{ backgroundColor: "#F4F6F6" }}>{item.draft}</td>
                <td style={{ backgroundColor: "#F4F6F6" }}>{item.reviewed}</td>
                <td style={{ backgroundColor: "#F4F6F6" }}>{item.applied}</td>
                <td style={{ backgroundColor: "#F4F6F6" }}>{item.collected}</td>
                <td style={{ backgroundColor: "#EAF2F8", fontWeight: "bold" }}>
                  {item.total_applications}
                </td>
                <td>{prettyDisplayPrice(item.total_application_dollars)}</td>
                <td>
                  {prettyDisplayPrice(
                    parseFloat(item.total_application_dollars_collected)
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminStatsApplicationsTable;
