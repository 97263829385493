import React from "react";

const UIColLabelInput = (props) => {
  const labelWidth = props.labelWidth || 3;
  const formWidth  = props.formWidth || 12 - labelWidth

  return (
    <>
      <label className={`col-sm-${labelWidth} pe-0`}>
        <strong>{props.label}</strong>
        { props.required && <span className='panel-form-required'>*</span> }
      </label>
      <div className={`col-sm-${formWidth}`}>
        <input
          value={props.value || ''}
          name={props.label}
          type={props.type || "text"}
          className="form-control "
          required={!!props.required}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
        />
        {props.error !== "" && <span className="error mt-2">{props.error}</span>}
      </div>
    </>
  );
};

export default UIColLabelInput;
