import axios from "lib/axios";

export const getAvailableIntegrations = (params = {}) => {
  const url = "/integrations";

  return axios
    .get(url, {
      params: params,
    }).then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const disconnectIntegration = (slug, params = {}) => {
  const url = `/integrations/${slug}/disconnect`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const createPlaidLinkToken = (params = {}) => {
  const url = "/integrations/plaid/create_link_token";

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const disconnectPlaidLink = (params) => {
  const url = "/integrations/plaid/disconnect";

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const setupPlaidIntegration = (params = {}) => {
  const url = "/integrations/plaid/setup";

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
