import React from "react";

const AdditionalSettingsSection = (props) => {
  return (
    <div className="row">
      <h3>{props.companyName} Additional Details</h3>
      <hr className="line-adjust" />
      <div className="row mt-3">
        <div className="col-md-12">
          <label> Manufacturing Options </label>
          <div className="card">
            <div className="card-body">
              <div className="card-title">Equipment</div>
              <button className="btn btn-dark">
                Add New Piece of Equipment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalSettingsSection;
