import React, { useState } from 'react';

import { parseAddress, prettyDisplayPrice } from 'lib/string'
import ClaimFacility from "./components/claim-facility";
import Header from '../../common/header'

const EconomicDevelopmentRateDiscountPGE = (props) => {
  const { companyFacilities, incentive, incentiveFAQ } = props;

  let [ facilityShow, setFacilityShow ] = useState({});

  const initializeFacilitiesShow = (facilities) => {
    setFacilityShow(facilities.reduce((result, facility) => { 
      return { ...result, [ facility.id ] : false }; }, {})
    )
  }

  const calculatePotentialSavings = (monthly_estimate) => {
    return monthly_estimate * 12 * 5 * .12
  }

  const renderFacilities = () => {
    let facilityRows = [];

    companyFacilities.forEach((facility, index) => {
      facilityRows.push(
        <tr className="user-tr" key={`user-row-${facility.id}-${index}`}>
          <td>{ parseAddress(facility.address) }</td>
          <td>{ facility.details.electric_monthly_cost_est ? prettyDisplayPrice(facility.details.electric_monthly_cost_est) : 'TBD' }</td>
          <td>{ facility.details.electric_monthly_cost_est ? prettyDisplayPrice(calculatePotentialSavings(facility.details.electric_monthly_cost_est)) : 'TBD' }</td>
          <td>
            <button
              className="btn btn-light btn-sm"
              name={`collpase_facility_row_${facility.id}`}
              onClick={(e) => {
                e.preventDefault();

                if(facilityShow[facility.id]) {
                  setFacilityShow({
                    ...facilityShow,
                    [facility.id]: false
                  })
                } else {
                  setFacilityShow({
                    ...facilityShow,
                    [facility.id]: true
                  })
                }
              }}>
              Review
              <span className='ms-1'>
                {!facilityShow[facility.id] ?
                  <i className="bi bi-chevron-down"></i>
                : <i className="bi bi-chevron-up"></i>
                }
              </span>
            </button>
          </td>
        </tr>
      )

      if(facilityShow[facility.id]) {
        facilityRows.push(
          <tr>
            <td colSpan="6" className="p-0">
              <ClaimFacility
                key={`claim-facility-${facilityShow[facility.id]}-${index}`}
                facility={facility} 
                user={props.currentUser}
                incentive={props.incentive} 
              />
            </td>
          </tr>
        )
      }
    });

    return (
      <div className="panel-table">
        <table className='table'>
          <thead>
            <tr>
              <th>Address</th>
              <th>Monthly Electrical Cost Estimate</th>
              <th>Potential 5-Year Savings</th>
            </tr>
          </thead>
          <tbody>
            { facilityRows } 
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      <Header name={props.incentive.name} eligible={props.totalEligible} claimed={props.totalClaimed} />
      <div className='panel mt-3'>
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-8 p-4">
              <h2 className="mt-0 font-weight-boldest">Your Eligible Facilities</h2>

              <p className="my-3">
                Reviewing the data below helps Subcity complete and submit
                your application(s) for the Economic Development Rate Discount
                through PG&E.
              </p>

              <a href={`/incentives/${incentive.slug}`}>
                Read more about PG&E’s Economic Development Rate Discount.
              </a>
            </div>
            { renderFacilities() }
          </div>
        </div>
      </div>
    </>
  );
};

export default EconomicDevelopmentRateDiscountPGE;
