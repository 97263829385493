import React, { useState } from "react";

const UIHidableText = (props) => {
  const [shouldHideText, setShouldHideText] = useState(props.defaultValue || true);

  return (
    <div className='ui-label-input' onDoubleClick={()=>{ setShouldHideText(!shouldHideText); }}>
      <label className="form-label mb-1"><strong>{props.label}</strong></label>
      <label className="form-label mb-1" style={{ width: "100%" }}>{shouldHideText ? props.hiddenText + " (Double-click to show)" : props.text}</label>
    </div>
  );
};

export default UIHidableText;
