import axios from "lib/axios";

export const getBusinessEntities= () => {
  const url = `/business_entities`;
  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
