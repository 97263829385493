import React from "react";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";
import "react-datepicker/dist/react-datepicker.css";

import { UILabelInput } from "components/ui";
import { fetchNaicsCode } from "modules/user/companies";

const BasicInfo = ({ variables, onFieldChange, businessEntities, onNaicsCodeSelect }) => {
  const onOptionsLoad = (value, cb) => {
    fetchNaicsCode({ query: value }).then((res) => {
      cb(
        res.map((row) => ({
          value: row.id,
          label: `${row.code} (${row.title})`,
        }))
      );
    });
  };

  return (
    <>
      <div className="row mt-1">
        <h3>Basic Info</h3>
        <hr className="line-adjust" />
      </div>
      <div className="row">
        <div className="col-md-8 mb-4">
          <div className="col-md-9">
            <UILabelInput
              label="Business Name"
              value={variables.name}
              onChange={(value) => {
                onFieldChange(value, "name");
              }}
            />
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <strong>
            <label className="mb-1">Founded On</label>
          </strong>
          <DatePicker
            className="form-control"
            selected={variables.date_established}
            scrollableYearDropdown={true}
            showYearDropdown={true}
            yearDropdownItemNumber={100}
            maxDate={new Date()}
            onChange={(date) => onFieldChange(date, "date_established")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 mb-4">
          <div className="col-md-9">
            <strong>
              <label htmlFor="legal_structure" className="mb-1">
                Legal Structure
              </label>
            </strong>
            <div>
              <select
                name="legal_structure"
                className="form-control"
                value={variables.business_entity_id}
                onChange={(e) => {
                  onFieldChange(e.target.value, "business_entity_id");
                }}
              >
                {businessEntities.map((entity, index) => {
                  return (
                    <option key={`${entity.id}-${index}`} value={entity.id}>
                      { entity.name }
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="col-md-9">
            <strong>
              <label className="mb-1">NAICS Code</label>
            </strong>
            <AsyncSelect
              isMulti
              loadOptions={onOptionsLoad}
              onChange={(object) => {
                onNaicsCodeSelect(object, "naics_code_ids");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicInfo;
