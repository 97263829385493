import axios from "lib/axios";
import { v4 as uuidv4 } from "uuid";
import { encodeParams } from 'lib/utils';

export const fetchAdminIncentives = (params = {}) => {
  let url = `/admin/incentives`;
  params = encodeParams(params);

  if (params.simple) {
    url += "/simple";
  }

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchAdminMissingFieldIncentives = (params = {}) => {
  let url = `/admin/incentives/missing_fields`;

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchAdminIncentiveTypes = (params = {}) => {
  let url = `/admin/incentive_types`;

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchAllTags = () => {
  let url = '/admin/incentives/fetch_all_tags';

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data.tags);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const createAdminIncentive = (params = {}) => {
  const url = `/admin/incentives`;
  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const deleteAdminIncentive = (params = {}) => {
  const url = `/admin/incentives/${params.id}`;
  return axios
    .delete(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateAdminIncentive = (params = {}) => {
  const url = `/admin/incentives/${params.id}`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const saveRuleAdminIncentive = (params = {}) => {
  const url = `/admin/incentives/${params.id}/save_eligibility_rule`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const testEligibilityRule = (params = {}) => {
  const url = `/admin/incentives/${params.id}/test_eligibility_rule`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const checkIncentiveEligibility = (params = {}) => {
  const url = `/admin/incentives/${params.id}/incentive_eligibility`;

  return axios
    .get(url, {
      params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateOrCreateOnboardingFlowIncentive = (incentiveId, onboardingFlowIncentiveParams) => {
  const url = `/admin/incentives/${incentiveId}/update_or_create_onboarding_flow_incentive`;

  return axios
    .post(url, { onboarding_flow_incentive: onboardingFlowIncentiveParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response.data));
};

export const uploadIncentiveFileResource = (params = {}) => {
  const url = `/admin/incentives/${params.id}/upload_file_resource`;
  let formData = new FormData();

  for ( var key in params ) {
    if(key === 'incentive') {
      for (let fileKey in params.incentive) {
        formData.append(`incentive[${fileKey}]`, params.incentive[fileKey]);
      }
    }
    else {
      formData.append(`[${key}]`, params[key]);
    }
  }

  return axios
    .post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const deleteIncentiveFileResource = (params = {}) => {
  const url = `/admin/incentives/${params.id}/destroy_file_resource`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchAdminOrganizationsSelector = (params = {}) => {
  let url = `/admin/incentives/organizations_selector`;

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
