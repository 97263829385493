import React, { useState } from "react";
import USAMap from "react-usa-map";
import StateSelector from "components/common/state-selector";

const BookMap = () => {
  const [selectedState, setSelectedState] = useState("");

  const mapHandler = (event) => {
    const stateName = event.target.dataset.name;
    navigateToState(stateName);
  };

  const navigateToState = (stateName) => {
    window.location.href = `/${stateName}`;
  };

  const handleFieldChange = (field, value) => {
    if (field === "state") {
      setSelectedState(value);
      navigateToState(value);
    }
  };

  return (
    <div className="hero-card-large mt-4">
      <div className="row mt-4 mb-4">
        <div className="col-md-4">
          <h4 className="eyebrow-text">US Directory</h4>
          <h3 className="mb-4">What incentives are available in my state?</h3>
          <p>
            Understanding the incentives available for your business starts at
            the local level. Benefits for businesses can vary widely from state
            to state, impacting everything from tax credits to grants. Knowing
            what's available to your business can be a game-changer.
          </p>
          <p>
            Whether you're looking to ramp hiring, launch a new product or
            expand operations, taking advantage of local incentives can make a
            significant difference in your bottom line. Click on your state to
            explore what programs are available for businesses like yours.
          </p>
        </div>
        <div className="col-md-8 d-flex align-items-center">
          {/* Dropdown for mobile users */}
          <div className="d-md-none">
            <p>
              <strong>Select your state</strong>
            </p>
            <StateSelector
              onChange={(newState) => {
                handleFieldChange("state", newState);
              }}
              state={selectedState}
            />
          </div>
          <div className="d-none d-md-block usa-map-container">
            <USAMap onClick={mapHandler} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookMap;
