import React from 'react';

const UIIconInput = (props) => {
  return (
    <div className="input-group">
      <span>
        <i className={`bi bi-envelope`}></i>
      </span>

      <input type="text" className="form-control" 
        placeholder={"Email"} 
      />
    </div>
  )
}

export default UIIconInput;
