import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { prettyDisplayPrice } from "lib/string";
import moment from "moment";
import TagEditor from "components/common/tag-editor";

const InvoiceItem = ({
  index,
  row,
  updateInvoiceTags,
  setInvoice,
  setShowModal,
  editableInvoices,
  offers,
}) => {
  let [atRisk, setAtRisk] = useState(row.at_risk);

  useEffect(() => {
    setAtRisk(row.at_risk);
  }, [row]);

  let [offer, setOffer] = useState(offers[row.id.toString()]);

  useEffect(() => {
    setOffer(offers[row.id.toString()]);
  }, [row, offers]);

  function getCashImpact() {
    if (row.cash_balance_status === "money in") {
      return (
        <b style={{ color: getCashImpactColor() }}>
          {prettyDisplayPrice(row.cash_balance_impact)}
        </b>
      );
    } else {
      return prettyDisplayPrice(row.cash_balance_impact);
    }
  }

  function getCashImpactColor() {
    if (row.cash_balance_impact < 0) {
      return "#6C757D";
    } else {
      return "#1C944C";
    }
  }

  function getDate() {
    if (row.status === "Projected") {
      return (
        <i style={{ color: "#6C757D" }}>
          {moment(row.due_date).format("MMM DD[,] YYYY")}
        </i>
      );
    } else {
      return moment(row.due_date).format("MMM DD[,] YYYY");
    }
  }

  function getRowDescription() {
    let name = row.vendor_name ?? row.expense_account ?? "";
    let tooltipContent = row.risk_notes
      ? row.risk_notes
      : offer
      ? offer.content
      : null;

    if (tooltipContent) {
      return (
        <OverlayTrigger overlay={<Tooltip>{tooltipContent}</Tooltip>}>
          <span>
            {name}
            <i className="bi bi-info-circle ms-1"></i>
          </span>
        </OverlayTrigger>
      );
    } else if (row.status == "Projected") {
      return <i style={{ color: "#6C757D" }}>{name}</i>;
    } else {
      return name;
    }
  }

  function getRowIdentifier() {
    if (row.status == "Projected") {
      return (
        <span className="badge rounded-pill text-primary bg-primary-subtle">
          Projected
        </span>
      );
    } else if (row.cash_balance_status === "money in") {
      return row.order_number;
    } else {
      return row.purchase_order_number;
    }
  }

  function getRowClass() {
    return offer ? "row-offer row-with-shadow" : "row-with-shadow";
  }

  function getRowColor() {
    return atRisk ? "#FFDAC1" : "white";
  }

  function getBorderStyle() {
    return atRisk ? "2px solid #EA6278" : "";
  }

  return (
    <tr
      key={`invoice-list-${index}`}
      style={{
        verticalAlign: "middle",
        // backgroundColor: getRowColor(),
        border: getBorderStyle(),
        className: getRowClass(),
      }}
    >
      <td
        style={{
          paddingLeft: "25px",
          width: "10%",
        }}
      >
        {getDate()}
      </td>
      <td
        style={{
          width: "70%",
        }}
      >
        <>
          {getRowDescription()}
          {/* <TagEditor
            tags={row.tags}
            title={row.vendor_name}
            onChange={(tags) => {
              updateInvoiceTags(row, tags);
            }}
          /> */}
        </>
      </td>

      <td
        style={{
          width: "10%",
        }}
      >
        {getRowIdentifier()}
      </td>
      <td
        style={{
          width: "10%",
        }}
      >
        {getCashImpact()}
      </td>

      <td>
        <>
          <button
            className="btn btn-muted btn-sm"
            onClick={() => {
              row.customer_name = row.vendor_name;
              row.customer_details = row.vendor_details;
              editableInvoices.push(row);
              setShowModal(true);
            }}
          >
            <i className="bi bi-pencil-square pencil-button-stable"></i>
          </button>
          {offer && (
            <a href={offer.cta_link} className="btn btn-sm btn-action">
              Apply
            </a>
          )}
        </>
      </td>
    </tr>
  );
};

export default InvoiceItem;
