import React, { useState } from "react";
import ProjectForm from "./components/project-form";
import { flashError, flashSuccess, flashSuccessLater } from "lib/flash";

import { projectParams, createCompanyProject } from "modules/project";

const New = (props) => {
  function handleSubmit(project) {
    const newProj = newProject(project);
    const params = projectParams(newProj);
    createCompanyProject(params)
      .then((response) => {
        flashSuccessLater("Project created!");
        window.location = `/projects/${projectData.id}#/estimate`;
      })
      .catch((error) => {
        flashError(error);
      });
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <ProjectForm
            new
            handleSubmit={handleSubmit}
            saveText="Create Project"
          />
        </div>
      </div>
    </div>
  );
};

export default New;
