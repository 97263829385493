import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import EstimateCategoryGroup from "./components/estimate-category-group";
import EstimateTotalRow from "./components/estimate-total-row";

import { getEstimates, updateCompanyProject } from "modules/project";

import { AddCategory, withCategories } from "./components/estimate-categories";
import ProjectQuoteMetadata from "./components/project-quote-metadata";
import ManageQuoteFromEstimate from "./components/manage-quote-from-estimate";

const Estimate = (props) => {
  const [estimateCategories, setEstimateCategories] = useState([]);
  const [estimates, setEstimates] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [total, setTotal] = useState({});

  const filteredEstimates = estimates.filter(
    (estimate) => estimate["parent_id"] == null
  );
  const [markupMode, setMarkupMode] = useState(
    props.project.markup_mode || "percent"
  );

  function add(options = {}) {
    return options.markup + options.total;
  }

  function multiply(options = {}) {
    return (1 + options.markup / 100) * options.total;
  }

  // Recursive function to check if parent is collapsed
  const isParentCollapsed = (category) => {
    // If there's no parent and the category itself is collapsed, consider it as collapsed
    if (!category.parent_id && category.isCollapsed) {
      return true;
    }

    const parentCategory = estimateCategories.find(
      (cat) => cat.id === category.parent_id
    );

    // If there's no parent and the category itself is not collapsed, consider it as not collapsed
    if (!parentCategory) {
      return false;
    }

    if (parentCategory.isCollapsed) {
      return true; // If parent is collapsed
    }

    return isParentCollapsed(parentCategory);
  };

  const applyMarkup = markupMode === "total" ? add : multiply;

  function getAllLineItemsForCategory(category, categories) {
    let lineItems = [...category.estimate_line_items];

    if (category.children && category.children.length > 0) {
      for (let childId of category.children) {
        const childCategory = categories.find((cat) => cat.id === childId);
        if (childCategory) {
          lineItems = lineItems.concat(
            getAllLineItemsForCategory(childCategory, categories)
          );
        }
      }
    }

    return lineItems;
  }

  function handleShowQuoteModal() {
    estimateCategories.sort((a, b) => {
      if (a.parent_id === null) return -1;
      if (b.parent_id === null) return 1;
      return a.parent_id - b.parent_id;
    });

    const isValid = estimateCategories.every((category) => {
      const parentCollapsed = isParentCollapsed(category);
      const valid =
        category.isCollapsed ||
        category.estimate_line_items.length === 0 ||
        (parentCollapsed &&
          !(
            category.estimate_line_items.length > 0 &&
            category.parent_id === null
          ));

      return valid;
    });

    if (!isValid) {
      console.log(
        "Some estimate line items are displayed. Calculating markupTotal for parent categories."
      );

      // Recursive function to get all line items for a given category (including its children)
      const getAllLineItemsForCategoryWithChildren = (category, categories) => {
        let lineItems = [...category.estimate_line_items];
        if (category.children) {
          category.children.forEach((childId) => {
            const childCategory = categories.find((cat) => cat.id === childId);
            if (childCategory) {
              lineItems = lineItems.concat(
                getAllLineItemsForCategoryWithChildren(
                  childCategory,
                  categories
                )
              );
            }
          });
        }
        return lineItems;
      };

      setEstimateCategories((prevCategories) => {
        return prevCategories.map((category) => {
          if (category.parent_id === null) {
            const allLineItems = getAllLineItemsForCategoryWithChildren(
              category,
              prevCategories
            );

            const totalForCategory = allLineItems.reduce((sum, item) => {
              const itemTotal = parseFloat(item.total_amount);
              if (isNaN(itemTotal)) return sum;
              return sum + itemTotal;
            }, 0);

            const markupTotalForCategory = allLineItems.reduce((sum, item) => {
              const itemTotal = parseFloat(item.total_amount);
              if (isNaN(itemTotal)) return sum;

              const itemMarkup = parseFloat(item.markup || 0); // Default to 0 if markup is null

              return (
                sum + applyMarkup({ total: itemTotal, markup: itemMarkup })
              );
            }, 0);

            return {
              ...category,
              isCollapsed: true,
              markupTotal: markupTotalForCategory,
            };
          } else {
            return category;
          }
        });
      });
      setShowQuoteModal(true);
      return;
    }

    setShowQuoteModal(true);
  }

  function handleCloseQuoteModal() {
    setShowQuoteModal(false);
  }

  const handleQuoteSuccess = (data) => {
    setShowQuoteModal(false);
  };

  const handleQuoteError = (error) => {
    console.error("Error submitting form:", error);
  };

  const updateEstimateCategory = (
    id,
    isCollapsed,
    category_name,
    description,
    parent_id,
    children,
    estimate_line_items,
    markupTotal,
    quantity,
    features
  ) => {
    setEstimateCategories((prevEstimates) => {
      const updatedEstimate = {
        id,
        collapsed: isCollapsed,
        category_name,
        description,
        parent_id,
        children,
        estimate_line_items,
        markupTotal,
        quantity,
        features,
      };
      const updatedEstimates = prevEstimates.map((estimate) =>
        estimate.id === id ? updatedEstimate : estimate
      );
      if (!updatedEstimates.find((estimate) => estimate.id === id)) {
        // If the estimate doesn't exist in the array, add it
        updatedEstimates.push(updatedEstimate);
      }
      return updatedEstimates;
    });
  };

  useEffect(() => {
    fetchEstimates();
  }, []);

  useEffect(() => {
    if (props.addedLineItems) {
      fetchEstimates();
    }
  }, [props.addedLineItems]);

  useEffect(() => {}, [estimateCategories]);

  function fetchEstimates() {
    setFetching(true);
    return getEstimates(props.project.id)
      .then((result) => {
        setEstimates(result);
        props.setAddedLineItems(false);
        setFetching(false);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  function toggleMarkupMode(e) {
    e.preventDefault();
    if (!props.project.is_markup_active) {
      return;
    }

    const newMarkupMode = markupMode === "total" ? "percent" : "total";

    updateCompanyProject({
      id: props.project.id,
      markup_mode: newMarkupMode,
    }).then(() => {
      setMarkupMode(newMarkupMode);
    });
  }

  let estimateCategoryRows = filteredEstimates.map((estimate, index) => {
    return (
      <div className="mt-3" key={`estimate-category-group-${index}`}>
        <EstimateCategoryGroup
          key={`estimate-category-group-${index}-${markupMode}`}
          keyPrefix={`estimate-category-group-${index}`}
          estimate={estimate}
          setGrandTotal={setTotal}
          project={props.project}
          applyMarkup={applyMarkup}
          markupMode={markupMode}
          estimates={estimates}
          fetchEstimates={fetchEstimates}
          level={0}
          updateEstimateCategory={updateEstimateCategory}
        />
      </div>
    );
  });

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          {props.project.is_markup_active && (
            <>
              <button className="btn btn-light" onClick={toggleMarkupMode}>
                {markupMode === "total" ? (
                  <i className="bi bi-currency-dollar"></i>
                ) : (
                  <i className="bi bi-percent"></i>
                )}
              </button>
              <span style={{ marginLeft: "10px" }}>
                {markupMode === "total"
                  ? "Click to apply markup as a percentage"
                  : "Click to apply markup as a fixed dollar amount"}
              </span>
            </>
          )}
        </div>
        <div className="col-md-6">
          <ManageQuoteFromEstimate
            onGenerateQuote={handleShowQuoteModal}
            setShowQuoteModal={setShowQuoteModal}
            showCategoryPicker={props.showCategoryPicker}
            quoteId={props.project.project_quote_id}
            external_project_id={props.project.external_project_id}
            company_project_id={props.project.id}
            project={props.project}
          >
            <AddCategory
              categories={props.categories}
              setCategories={props.setCategories}
              saveCategories={props.saveCategories}
              projectId={props.project.id}
              showCategoryPicker={props.showCategoryPicker}
              setShowCategoryPicker={props.setShowCategoryPicker}
              estimateCategories={estimateCategories}
              isParentCollapsed={isParentCollapsed}
            />
          </ManageQuoteFromEstimate>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {fetching ? null : estimateCategoryRows}
        </div>
      </div>

      <table className="table table-borderless my-0 mt-3">
        <thead>
          <th className="py-0" style={{ width: "65%" }}>
            {""}
          </th>
          <th className="py-0" style={{ width: "8%" }}>
            {""}
          </th>
          <th className="py-0" style={{ width: "7%" }}>
            {""}
          </th>
          <th className="py-0" style={{ width: "20%" }}>
            {""}
          </th>
        </thead>
        {/* <tbody>
          TODO: There is a bug in the total row
          <EstimateTotalRow estimates={estimates} />
        </tbody> */}
      </table>

      <Modal
        show={showQuoteModal}
        onHide={handleCloseQuoteModal}
        dialogClassName="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Generate Quote - {props.project.name}
            {props.project.version > 1 && `CO ${props.project.version}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectQuoteMetadata
            company_project_id={props.project.id}
            estimate={props.project.estimates}
            quoteCategories={estimateCategories}
            onSuccess={handleQuoteSuccess}
            onError={handleQuoteError}
            fetchEstimates={fetchEstimates}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withCategories(Estimate);
