import axios from 'lib/axios'
import { v4 as uuidv4 } from "uuid";

export const newIncentiveUrl = () => {
  return Promise.resolve({
    _new: true,
    id: uuidv4(),
    incentive_id: null,
    url: '',
    label: ''
  })
}

export const createIncentiveUrl = (params = {}) => {
  const url = `/admin/incentive_urls`

  return axios.post(url, params)
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response.data)
    })
}

export const updateIncentiveUrl = (params = {}) => {
  const url = `/admin/incentive_urls/${params.id}`

  return axios.put(url, params)
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response.data)
    })
}

export const deleteIncentiveUrl = (params = {}) => {
  const url = `/admin/incentive_urls/${params.id}`

  return axios.delete(url)
    .then((response) => {
      return Promise.resolve(params)
    })
    .catch((error) => {
      return Promise.reject(error.response.data)
    })
}
