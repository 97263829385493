import axios from "lib/axios";
import { encodeParams } from 'lib/utils';
import { serialize } from 'object-to-formdata';

export const fetchAdminFunnels = (params = {}) => {
  const url = `/admin/funnels`;
  params = encodeParams(params);

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const formatFunnelIncentive = (incentive) => {
  return {
    id: incentive.id,
    incentive_id: incentive.incentive_id,
    value: incentive.name.toLowerCase().replace(/\W/g, ''),
    label: incentive.name
  }
}

export const createFunnel = (params = {}) => {
  const url = `/admin/funnels`
  const formData = serialize(params, {nullsAsUndefineds: true});

  return axios
    .post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const updateFunnel = (params = {}) => {
  const url = `/admin/funnels/${params.id}`
  const formData = serialize(params, {nullsAsUndefineds: true});

  return axios
    .put(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}
