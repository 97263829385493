import React, { useEffect } from "react";
import { useArray } from "react-hanger";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Flash from "lib/flash";

const FlashMessages = (props) => {
  const messages = useArray(props.messages);
  const toastTimer = 2500;
  const position = "bottom-right";

  const notifyToast = (message) => {
    let text = message.text;
    let style = {};

    if (!Array.isArray(text)) {
      text = [text];
    }

    switch (message.type) {
      case "alert":
        text.map((msg) => {
          Flash.flashAlert(msg);
        });
        return;
      case "notice":
      case "info":
        text.map((msg) => {
          Flash.flashNotice(msg);
        });
        return;
      case "warning":
        text.map((msg) => {
          Flash.flashWarning(msg);
        });
        return;
      case "error":
      case "danger":
      case "recaptcha_error":
        text.map((msg) => {
          Flash.flashError(msg);
        });
        return;
      case "success":
        style = {
          backgroundColor: "#f6f8fa",
          color: "#333",
          borderRadius: "8px",
          border: "1px solid #ccc",
        };
        text.map((msg) => {
          Flash.flashSuccess(msg, { style });
        });
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    const flashMessages = JSON.parse(localStorage.getItem("flash_messages"));

    if (flashMessages)
      flashMessages.map((message) => {
        notifyToast(message);
      });

    messages.value.map((message) => {
      notifyToast(message);
    });

    localStorage.setItem("flash_messages", null);
    messages.clear();
  }, []);

  return <ToastContainer position={position} autoClose={toastTimer} />;
};
export default FlashMessages;
