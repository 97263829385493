import axios from "lib/axios";
import { v4 as uuidv4 } from "uuid";
import { formatSelectField } from 'lib/utils'
import { toBoolean } from 'lib/string'
import React from "react";
import { titleCase } from "lib/string";

export const getCompanyProjects = (params = {}) => {
  const url = `/projects/`

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.error("API Call Error:", error);
      return Promise.reject(error);
    });
}

export const getAdminCompanyProjects = (params = {}) => {
  const url = `/admin/projects/`

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const createCompanyProject = (params = {}) => {
  const url = `/projects/`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateCompanyProject = (params = {}) => {
  const url = `/projects/${params.id}`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const newProjects = (projects) => {
  return projects || [] 
}

export const newProject = (project = {}) => {
  return {
    id: project.id,
    name: project.name || "",
    customer: project.customer || '',
    status: formatSelectField(project.status),
    is_markup_active: toBoolean(project.is_markup_active),
    project_notes: project?.project_notes || "", 
    project_manager: project?.project_manager || "", 
    project_type: project?.project_type || "",
    external_project_id: project?.external_project_id || "",
    priority: project?.priority || "", 
    completed_date: project?.completed_date || null,
    project_document_links: project?.project_document_links || [],
    start_date: project.start_date || '',
    end_date: project.end_date || '',
    categories: project.company_estimate_categories || [],
    version: project.version || 1,
    version_name: project.version_name || "Initial scope of work",
    is_latest: project.is_latest || true
  }
}

export const projectParams = (project) => {
  const project_estimate_categories_attributes = project.categories.map((category) => {
    return { 
      id: category.id,
      company_estimate_category_id: category.company_estimate_category_id,
    }
  })

  return {
    id: project?.id,
    company_project: {
      id: project?.id,
      name: project.name,
      customer: project.customer,
      start_date: project.start_date,
      end_date: project.end_date,
      status: project.status.value,
      markup_mode: project.markup_mode,
      project_manager: project.project_manager,
      project_notes: project.project_notes,
      project_type: project.project_type,
      priority: project.priority,
      external_project_id: project.external_project_id,  
      completed_date: project.completed_date,
      project_document_links: project.project_document_links,
      project_estimate_categories_attributes,
      version: project.version,
      version_name: project.version_name,
      is_latest: project.is_latest,
      deleted_at: project.deleted_at,
    }
  }
}

export const getAllProjectManagers = () => {
  const url = "/projects/all_project_managers";

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data.managers);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const getAllCustomers = () => {
  const url = "/projects/all_customers";

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data.customers);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const getAllProjectTypes = () => {
  const url = "/projects/all_project_types";

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data.types);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const getAllTemplates = () => {
  const url = "/projects/templates";

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data.templates);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const getAllUnitTypes = () => {
  const url = "/estimate_line_items/all_unit_types";

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data.types);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const newEstimateLineItem = () => {
  return {
    _editing: true,
    _new: true,
    id: uuidv4(),
    description: '',
    unit: '',
    unit_amount: 0.00,
    quantity: 0.00,
    total_amount: 0.00
  }
}

export const getEstimates = (id) => {
  const url = `/projects/${id}/estimates`;

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}


export const createEstimateLineItem = (params = {}) => {
  const url = `/estimate_line_items/`

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const updateEstimateLineItem = (id, params = {}) => {
  const url = `/estimate_line_items/${id}`

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const deleteEstimateLineItem = (id) => {
  const url = `/estimate_line_items/${id}`

  return axios
    .delete(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const getEstimateLineItemGroups = (id, params) => {
  const url = `/projects/${id}/estimate_line_item_groups`

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const createAllocatedLineItem = (params) => {
  const url = `/allocated_line_items`

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const deleteAllocatedLineItem = (id, params) => {
  const url = `/allocated_line_items/${id}`

  return axios
    .delete(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const getAllocatedLineItems = (params) => {
  const url = `/allocated_line_items`

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const getCompanyProjectQuote = (id, params) => {
  const url = `/projects/${id}/company_project_quote`

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const createProjectQuoteCategories = (projectId, quoteCategoriesData) => {
  const url = `/projects/${projectId}/project_quote_categories`

  return axios
    .post(url, quoteCategoriesData)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const getProjectQuoteCategories = (projectId) => {
  const url = `/projects/${projectId}/project_quote_categories`

  return axios
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const updateProjectQuoteCategory = (projectId, categoryId, updatedData) => {
  const url = `/projects/${projectId}/project_quote_categories/${categoryId}`

  return axios
    .put(url, updatedData)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const deleteProjectQuoteCategory = (projectId, categoryId) => {
  const url = `/projects/${projectId}/project_quote_categories/${categoryId}`

  return axios
    .delete(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const createCompanyProjectQuote = (id, data) => {
  const url = `/projects/${id}/company_project_quote`;

  return axios
    .post(url, data)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateCompanyProjectQuote = (id, data) => {
  const url = `/projects/${id}/company_project_quote`;

  return axios
    .put(url, data)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateAllocatedLineItem = (id, params) => {
  const url = `/allocated_line_items/${id}`

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const getAllocatedLineItemGroups = (id, params) => {
  const url = `/projects/${id}/allocated_line_item_groups`

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const addEstimateLineItemGroups = (id, params) => {
  const url = `/projects/${id}/add_estimate_line_item_groups`

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const deleteEstimateLineItemGroup = (id, project_estimate_category_id) => {
  const url = `/projects/${id}/delete_estimate_line_item_group/${project_estimate_category_id}`

  return axios
    .delete(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const renderStatus = (status) => {
  let statusClass = "";
  switch (status) {
    case "active":
      statusClass = "success";
      break;
    case "draft":
      statusClass = "dark";
      break;
    case "pending":
      statusClass = "warning";
      break;
    case "rejected":
      statusClass = "danger";
      break;
    case "closed":
      statusClass = "light";
      break;
    default:
      return null;
  }

  return (
    <div
      className={`d-inline px-2 py-1 text-${statusClass}-emphasis bg-${statusClass}-subtle border border-${statusClass}-subtle rounded-1`}
    >
      <span className="fw-bold">{titleCase(status)}</span>
    </div>
  );
};


export const renderPriority = (priority) => {
  let priorityClass = "";
  switch (priority) {
    case "High":
      priorityClass = "success";
      break;
    case "Active":
      priorityClass = "success";
      break;
    case "Approved":
      priorityClass = "primary";
      break;
    case "Awarded":
      priorityClass = "primary";
      break;
    case "Medium":
      priorityClass = "warning";
      break;
    case "Declined":
      priorityClass = "danger";
      break;
    case "Low":
      priorityClass = "light";
      break;
    case "Pending":
      priorityClass = "dark";
      break;
    default:
      return null;
  }

  return (
    <div
      className={`d-inline px-2 py-1 text-${priorityClass}-emphasis bg-${priorityClass}-subtle border border-${priorityClass}-subtle rounded-1`}
    >
      <span className="fw-bold">{titleCase(priority)}</span>
    </div>
  );
};


export const getCompanyProjectVersions = (id) => {
  const url = `/projects/${id}/versions`

  return axios
      .get(url)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
}

export const setCurrentProjectVersion = (id) => {
  const url = `/projects/${id}/set_version`

  return axios
      .put(url)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
}

export const createNewProjectVersion = (id) => {
  const url = `/projects/${id}/create_version`

  return axios
      .post(url)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
}

export const createDuplicateQuote = (quoteId, newProjectId) => {
  const url = `/company_project_quotes/${quoteId}/duplicate`;

  return axios
    .post(url, { new_company_project_id: newProjectId })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const collapseProjectCategories = (id, collapsed) => {
  const url = `/projects/${id}/collapse`

  return axios
      .post(url, { collapsed: collapsed })
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
}

export const getCompanyProjectQuoteDeliveryMethods = () => {
  const url = `/projects/delivery_methods`

  return axios
      .get(url)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
}

export const getCompanyProjectQuotePaymentTerms = () => {
  const url = `/projects/payment_terms`

  return axios
      .get(url)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
}

export const softDeleteCompanyProject = (projectId) => {
  const url = `/projects/${projectId}/soft_delete`;

  return axios
    .post(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};



