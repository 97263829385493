import React, { useEffect, useState } from "react";
import { useSetState } from "react-hanger";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { UIFormLabel, UITags } from "components/ui";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { flashSuccess, flashError } from "lib/flash";

import {
  updateAdminIncentive,
  deleteAdminIncentive,
  updateOrCreateOnboardingFlowIncentive,
} from "modules/incentives";
import { flashSuccessLater } from "lib/flash";

const LaunchDetails = (props = {}) => {
  const { state: incentive, setState: setIncentiveState } = useSetState(
    props.incentive
  );

  const [onboardingFlowIncentive, setOnboardingFlowIncentive] = useState({
    include_password:
      props.incentive.onboarding_flow_incentive?.include_password ?? true,
    include_documents:
      props.incentive.onboarding_flow_incentive?.include_documents ?? false,
    questions_option:
      props.incentive.onboarding_flow_incentive?.questions_option ??
      "all_questions",
    do_not_auto_update:
      props.incentive.onboarding_flow_incentive?.do_not_auto_update ?? false,
    integrations: props.incentive.onboarding_flow_incentive?.integrations ?? [],
  });

  const totalQuestions = props.incentive.incentive_qualifications.length;
  const levelOneQuestions = props.incentive.incentive_qualifications.filter(
    (q) => q.level === 1
  ).length;

  const ALL_INTEGRATIONS = [
    "Accounting",
    "Banking",
    "Payroll",
    "Time Tracking",
  ];
  const QUESTION_OPTIONS = {
    no_questions: "No Questions",
    level_1_questions: "Only Level 1 Questions",
    all_questions: "All Questions",
  };

  useEffect(() => {
    setIncentiveState(props.incentive);
  }, [props.incentive]);

  const onUpdateIncentive = (e) => {
    e.preventDefault();

    if (
      incentive.status === "visible" &&
      (!props.incentive.incentive_qualifications ||
        props.incentive.incentive_qualifications.length === 0)
    ) {
      const isConfirmed = window.confirm(
        "This incentive has no qualifications. The onboarding flow will default to the generic one. Are you sure you want to make it visible?"
      );
      if (!isConfirmed) {
        return;
      }
    }

    updateAdminIncentive(incentive)
      .then(() => {
        const successMessage =
          incentive.status === "visible"
            ? "Incentive updated. You are creating a publicly visible page and onboarding flow with the existing qualification questions."
            : "Incentive updated";

        flashSuccessLater(successMessage);
        location.reload(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFieldChange = (field, value) => {
    setIncentiveState({
      [field]: value,
    });
  };

  const handleDropdownValue = (value) => {
    handleFieldChange("status", value);
  };

  const handleIntegrationChange = (event) => {
    const { value, checked } = event.target;

    setOnboardingFlowIncentive((prev) => {
      // If the checkbox is checked, add its value to the integrations array
      const newIntegrations = checked
        ? [...prev.integrations, value]
        : prev.integrations.filter((integration) => integration !== value); // If unchecked, remove it

      // Prepare the updated state for the API call
      const updatedOnboardingFlowIncentive = {
        ...prev,
        integrations: newIntegrations,
      };

      // Optionally, update the backend immediately upon change
      updateOrCreateOnboardingFlowIncentive(
        incentive.id,
        updatedOnboardingFlowIncentive
      )
        .then((data) => {
          console.log("Integrations updated:", data);
          flashSuccess("Integrations updated for this incentive.");
        })
        .catch((error) => {
          console.error("Error updating integrations:", error);
          // Handle error case here
        });

      return updatedOnboardingFlowIncentive;
    });
  };

  const handleQuestionDepthChange = (event) => {
    const newQuestionsOption = event.target.value;

    // Prepare the updated state for the API call
    const updatedOnboardingFlowIncentive = {
      ...onboardingFlowIncentive,
      questions_option: newQuestionsOption,
    };

    // Call API to update the backend
    updateOrCreateOnboardingFlowIncentive(
      incentive.id,
      updatedOnboardingFlowIncentive
    )
      .then((data) => {
        console.log("Question depth updated:", data);
        flashSuccess("Question depth updated for this incentive.");
        setOnboardingFlowIncentive(updatedOnboardingFlowIncentive);
      })
      .catch((error) => {
        console.error("Error updating question depth:", error);
      });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "include_password" && !checked) {
      const isConfirmed = window.confirm(
        "Turning off 'Require Password Creation' will prevent onboarded users from creating accounts, and they will not be able to log in. Are you sure you want to proceed?"
      );

      if (!isConfirmed) {
        return;
      }
    }

    // Prepare the updated state for the API call
    const updatedOnboardingFlowIncentive = {
      ...onboardingFlowIncentive,
      [name]: checked,
    };

    // Call API to update the backend
    updateOrCreateOnboardingFlowIncentive(
      incentive.id,
      updatedOnboardingFlowIncentive
    )
      .then((data) => {
        console.log("Onboarding flow incentive updated:", data);
        flashSuccess("Onboarding flow updated for this incentive.");
        setOnboardingFlowIncentive(updatedOnboardingFlowIncentive);
      })
      .catch((error) => {
        console.error("Error updating onboarding flow incentive:", error);
        // Optionally revert the checkbox state if the update fails
      });
  };

  const onDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const result = confirm("Are you sure you want to delete this incentive?");
    if (result) {
      deleteAdminIncentive(incentive)
        .then(() => {
          flashSuccessLater("Incentive removed");
          location.href = "/admin/incentives";
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const statuses = [
    { name: "Active", key: "active" },
    { name: "Visible", key: "visible" },
    { name: "Completed", key: "completed" },
    { name: "Draft", key: "draft" },
    { name: "Paused", key: "paused" },
    { name: "Reviewing", key: "reviewing" },
  ];

  //TODO: Put this in the right place
  const toTitleCase = (str) => {
    if (!str) return "";
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <div>
      <form className="form" onSubmit={onUpdateIncentive}>
        <div className="card p-3 mt-1 mb-3 z-depth-2">
          <h3 className="panel-title">Incentive Status</h3>
          <div className="row">
            {/* Start Date Section */}
            <div className="col-5 mb-2">
              <UIFormLabel
                label="Start Date"
                tooltip="When the incentive starts."
              />
              <input
                type="date"
                name="Start Date"
                className="form-control mt-2"
                value={incentive.effective_date || "-"}
                onChange={(e) => {
                  e.preventDefault();
                  handleFieldChange("effective_date", e.target.value);
                }}
              />
            </div>

            {/* End Date Section */}
            <div className="col-5 mb-2">
              <UIFormLabel
                label="End Date"
                tooltip="When the incentive ends (can leave blank if unknown or
                      ongoing)."
              />
              <input
                type="date"
                name="End Date"
                className="form-control mt-2"
                value={incentive.expiration_date || "-"}
                onChange={(e) => {
                  e.preventDefault();
                  handleFieldChange("expiration_date", e.target.value);
                }}
              />
            </div>

            {/* Status Section */}
            <div className="col-2 mb-2">
              <div className="d-flex flex-column align-items-end">
                <div className="w-100 d-flex justify-content-end">
                  {" "}
                  {/* This div ensures the label uses the full width */}
                  <UIFormLabel label="Status" required />
                  <span className="ms-2">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip className="detail-tooltip">
                          What's the status of the incentive:
                          <br />
                          <strong>Draft</strong> - Used to work on building the
                          incentive.
                          <br />
                          <strong>Active</strong> - Users can actively match
                          with this incentive.
                          <br />
                          <strong>Visible</strong> - Same as active, but will
                          also create a publicly available incentives page.
                          <br />
                          <strong>Paused</strong> - Don't allow users to match
                          this incentive.
                          <br />
                          <strong>Completed</strong> - Incentive is complete
                          based on end date.
                        </Tooltip>
                      }
                    >
                      <i className="bi bi-info-circle"></i>
                    </OverlayTrigger>
                  </span>
                </div>
                <DropdownButton
                  id="dropdown-basic-button"
                  variant={incentive.status === "visible" ? "primary" : "dark"}
                  title={
                    <>
                      {incentive.status === "visible" && (
                        <i className="bi bi-eye-fill me-2"></i>
                      )}
                      {toTitleCase(incentive.status || "")}
                    </>
                  }
                  className="mt-2"
                  onSelect={(value) => {
                    handleFieldChange("status", value);
                  }}
                >
                  {statuses.map((status) => (
                    <Dropdown.Item key={status.key} eventKey={status.key}>
                      {status.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5 mb-2 mt-2">
              <UIFormLabel
                label="Fulfillment Partner Link"
                tooltip="This will send end users to an external link to apply. Be sure this is what you want"
              />
              <input
                name="Fulfillment Partner Link"
                className="form-control mt-2"
                value={incentive.fulfillment_affiliate_link || ""}
                onChange={(e) => {
                  e.preventDefault();
                  handleFieldChange(
                    "fulfillment_affiliate_link",
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
          <div className="clearfix row mt-3">
            <div className="col-6"></div>
            <div className="col-6">
              <div className="float-end">
                <a className="btn btn-light me-2" href="/admin/incentives">
                  Cancel
                </a>
                {props.incentive.status !== "active" &&
                  props.incentive.status !== "visible" && (
                    <button className="btn btn-danger me-2" onClick={onDelete}>
                      Delete
                    </button>
                  )}
                <button className="btn btn-primary">Update</button>
              </div>
            </div>
          </div>
        </div>

        <div className="card p-3 mt-1 mb-3 z-depth-2">
          <h3 className="panel-title">
            Onboarding Flow{" "}
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className="detail-tooltip">
                  The Onboarding Flow section allows you to customize how new
                  users experience the onboarding process for this incentive.
                  You can control the depth of questions asked, whether to
                  request document uploads, require account creation, and
                  integrate third-party services. Adjustments here ensure that
                  users receive a tailored onboarding experience that aligns
                  with the incentive's requirements and objectives.
                </Tooltip>
              }
            >
              <i className="bi bi-info-circle"></i>
            </OverlayTrigger>
          </h3>
          {!props.incentive.onboarding_flow_incentive?.id && (
            <div className="alert alert-warning" role="alert">
              You must set this incentive to visible in order to create a new
              onboarding flow.
            </div>
          )}
          {props.incentive.onboarding_flow_incentive?.id && (
            <>
              <div className="row p-2 border-top border-bottom d-flex align-items-center">
                <div className="col-5">
                  <UIFormLabel
                    label="Question Depth"
                    tooltip="Control the depth of questions asked during the onboarding process. 'No Questions' skips the qualification step, 'Level 1 Questions' includes only high-level qualifications, and 'All Questions' covers all associated qualifications in detail."
                  />
                </div>
                <div className="col-5">
                  <select
                    value={onboardingFlowIncentive.questions_option}
                    onChange={handleQuestionDepthChange}
                    className="form-control ui-multi-filter"
                  >
                    {Object.entries(QUESTION_OPTIONS).map(([key, name]) => (
                      <option key={key} value={key}>
                        {name}
                      </option>
                    ))}
                  </select>
                  <small className="text-muted">
                    There is {totalQuestions} total qualifying questions and{" "}
                    {levelOneQuestions} are Level 1{" "}
                  </small>
                </div>
              </div>

              <div className="row p-2 border-bottom d-flex align-items-center">
                <div className="col-5">
                  <UIFormLabel
                    label="Include Documents"
                    tooltip="User will be prompted to upload documents during onboarding"
                  />
                </div>
                <div className="col-5">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="include_documents"
                      checked={onboardingFlowIncentive.include_documents}
                      onChange={handleCheckboxChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="row p-2 border-bottom d-flex align-items-center">
                <div className="col-5">
                  <UIFormLabel label="Request Integrations" />
                </div>
                <div className="col-5">
                  {ALL_INTEGRATIONS.map((integration) => (
                    <div key={integration} className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`integration-${integration}`}
                        value={integration}
                        checked={onboardingFlowIncentive.integrations.includes(
                          integration
                        )}
                        onChange={handleIntegrationChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`integration-${integration}`}
                      >
                        {integration}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row p-2 border-bottom d-flex align-items-center">
                <div className="col-5">
                  <UIFormLabel
                    label="Require Password Creation"
                    tooltip="Turning this off will not prompt the user for a password. They will not be able to log-in"
                  />
                </div>
                <div className="col-5">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="include_password"
                      checked={onboardingFlowIncentive.include_password}
                      onChange={handleCheckboxChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row p-2 border-bottom d-flex align-items-center">
                <div className="col-5">
                  <UIFormLabel
                    label="Do Not Update Onboarding Flow"
                    tooltip="If you turn this on, the onboarding flow will not change from where it is when this is turned on."
                  />
                </div>
                <div className="col-5">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="do_not_auto_update"
                      checked={onboardingFlowIncentive.do_not_auto_update}
                      onChange={handleCheckboxChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default LaunchDetails;
