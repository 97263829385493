import React from "react";

const UIRadioCard = (props) => {
  function handleClick(e) {
    e.preventDefault();
    props.handleClick();
  }

  return (
    <label
      onClick={handleClick}
      className={props.isSwitch ? "ui-switch-card" : "ui-radio-card"}
      style={{ display: "flex", alignItems: "center" }}
    >
      {props.isSwitch ? (
        <>
          <input
            type="checkbox"
            name={props.name}
            id={props.id}
            className="switch-input-element"
            checked={props.checked}
            onChange={handleClick}
          />
          <span className="slider round"></span>
          <span style={{ marginLeft: "4px" }}>{props.label}</span>
        </>
      ) : (
        <>
          <input
            type="radio"
            name={props.name}
            id={props.id}
            className="card-input-element"
            checked={props.checked}
            onChange={handleClick}
          />
          <div className="card card-input">
            <div className="card-body py-2 px-4">{props.label}</div>
          </div>
        </>
      )}
    </label>
  );
};

export default UIRadioCard;
