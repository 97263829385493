import React, { useState, useEffect, useRef } from "react";
import { useSetState } from "react-hanger";
import { filter, find, snakeCase } from "lodash";
import { getUrlParams, setURLParams } from "lib/utils";
import { Modal } from "react-bootstrap";

import statusOptions from "./status-options";
import Select from "react-select";
import ProjectStatusSelect from "./project-status-select";
import New from "../new";

import { UITableSortable, UIMultiSelect, KeywordSearch } from "components/ui";

const TableFilters = (props) => {
  const { pagination } = props;

  const url = new URL(window.location);

  const { state, setState } = useSetState({
    query: getUrlParams(url, "query") || "",
    sortColumn: getUrlParams(url, "sort_column") || "name",
    sortDirection: getUrlParams(url, "sort_direction") || "ASC",
    status: findMany(statusOptions, getUrlParams(url, "status")) || [
      { value: "active", label: "Active" },
      { value: "draft", label: "Draft" },
    ],
  });

  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    console.log("State changed: ", state);
    setURLParams(state, pagination);
    fetchProjects();
  }, [state]);

  useEffect(() => {
    if (pagination.first_load) return;
    setURLParams(state, pagination);
    fetchProjects();
  }, [pagination]);

  function findMany(source, values) {
    if (!values) return [];
    return source.filter((option) => values.includes(option.value));
  }

  function fetchProjects() {
    const params = {
      query: state.query,
      sort_column: state.sortColumn,
      sort_direction: state.sortDirection,
      status: state.status?.map((option) => option.value),
      page: pagination.page,
      per_page: pagination.per_page,
    };

    props
      .getCompanyProjects(params)
      .then((data) => {
        props.setProjects(data.projects);
        props.setAggregateDetails(data.aggregate_details);
        props.updatePagination({
          total: data.total,
          first_load: true,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <div className="px-1">
        <div className="row mb-2">
          <div className="col-md-6">
            <KeywordSearch
              state={state}
              setState={setState}
              updatePagination={props.updatePagination}
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <div className="me-3">
              <ProjectStatusSelect
                isMulti
                filter
                className="ui-multi-filter"
                status={state.status}
                onChange={(option) => {
                  console.log("Status selected: ", option); // Debug log
                  setState({ status: option });
                }}
              />
            </div>
          </div>

          <div className="col-md-2 text-end">
            <button className="btn-attach float-end" onClick={handleModalOpen}>
              <span className="btn-action-plus">
                <i className="bi bi-plus"></i>
              </span>
              Create Project
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-4">
            <New handleModalClose={handleModalClose} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TableFilters;
