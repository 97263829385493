import React from "react";
import { useSetState } from "react-hanger";
import IncentiveDetailBody from "./incentive-detail-body";

const ShowIncentive = ({
  incentive,
  is_eligible,
  is_qualified,
  user_signed_in,
}) => {
  const { state, setState } = useSetState({
    showBanner: true,
  });

  const renderBanner = () => {
    if (!state.showBanner) return "";
    let message = "";
    let className = "";
    let callToAction = "";

    let callMeetingToAction = (
      <span>
        Please contact&nbsp;
        <a href="mailto:alex@subcity.com">alex@subcity.com</a>
        &nbsp; or schedule a{" "}
        <a href="https://calendly.com/alexanderswhite/30min">consultation</a> to
        learn more about applying for this incentive.
      </span>
    );

    if (is_qualified) {
      className = "ui-alert-qualified";
      message = `You are qualified for the ${incentive.name}.`;
      callToAction = callMeetingToAction;
    } else if (is_eligible) {
      className = "ui-alert-eligible";
      message = `You are eligible for the ${incentive.name}.`;
      callToAction = callMeetingToAction;
    }

    if (incentive.qualifications.length > 0) {
      if (user_signed_in) {
        if (is_qualified && is_eligible) {
          className = "ui-alert-qualified";
          callToAction = (
            <a href={`/applications/${incentive.slug}#/apply`}> Apply now.</a>
          );
        } else if (is_qualified) {
          className = "ui-alert-qualified";
          callToAction = (
            <a href={`/applications/${incentive.slug}#/pre-qualify`}>
              {" "}
              See if you're eligible and apply now.
            </a>
          );
        } else if (is_eligible) {
          className = "ui-alert-eligible";
          callToAction = (
            <a href={`/applications/${incentive.slug}#/pre-qualify`}>
              {" "}
              See if you qualify and apply now.
            </a>
          );
        } else {
          className = "ui-alert-eligible";
          callToAction = (
            <a href={`/applications/${incentive.slug}#/pre-qualify`}>
              {" "}
              See if you're eligible and apply now.
            </a>
          );
        }
      } else {
        className = "ui-alert-eligible";
        callToAction = (
          <a href="/register"> Register to see if you're eligible.</a>
        );
      }
    }

    if (message !== "" || callToAction != "") {
      return (
        <div className={`alert ui-alert ${className} mb-3 mt-3 d-flex`}>
          <p className="mb-0 p-0">
            {message} {callToAction}
          </p>
        </div>
      );
    }
  };

  const onGoBack = () => {
    location.href = "/incentives";
  };

  return (
    <div>
      {user_signed_in && (
        <button className="btn btn-light" onClick={onGoBack}>
          <span className="btn-label">
            <i className="bi bi-arrow-left">&nbsp;</i>
          </span>
          Dashboard
        </button>
      )}
      <div className="interrupt-card">{renderBanner()}</div>
      <div className="row">
        <div className="col-md-12">
          <IncentiveDetailBody incentive={incentive}></IncentiveDetailBody>
        </div>
      </div>
    </div>
  );
};

export default ShowIncentive;
