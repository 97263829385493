import React, { useState } from 'react';
import NaicsCodeSelector from 'components/common/naics-code-selector'
import { updateCompany } from 'modules/user/companies'
import { flashSuccess, flashError } from "lib/flash";

const NaicsCodeEditor = (props) => {
  const { company } = props;
  const [naicsCodeIds, setNaicsCodeIds] = useState(company.naics_code_ids || []);

  function handleSelect(ids) {
    updateCompany({ company: {id: company.id, naics_code_ids: ids} })
      .then((result) => {
        flashSuccess('Succesfully updated answer');
        setNaicsCodeIds(ids)

        if(ids.length === 1) {
          props.handleUpdateSuccess()
        } else if (ids.length === 0) {
          props.clearCompleted()
        }
      })
  }

  return (
    <div className='row'>
      <div className='col-md-6'>
        <NaicsCodeSelector
          onNaicsCodeSelect={handleSelect}
          naicsCodes={company.naics_codes}
        /> 
      </div>
    </div>
  )
}

export default NaicsCodeEditor;
