import React, { useContext, useState, useEffect, useRef } from 'react';
import { useSetState } from 'react-hanger';

import { fetchAllEquipments, uploadCompanyPurchaseCSV } from "modules/company_purchases";
import { withUIPaginate, UIPaginationTotalSelector, UIPagination } from 'components/ui';
import { flashError, flashSuccess } from "lib/flash";
import { KeywordSearch } from "components/ui";

const Equipments = () => {
  const [equipments, setEquipments] = useState(null);

  const { state, setState } = useSetState({
    query: "",
  });

  const [ paginationTotal, setPaginationTotal ] = useState(0);
  const [ paginationPerPage, setPaginationPerPage ] = useState(0);
  const [ paginationPage, setPaginationPage ] = useState(1);
  const [ paginationFirstLoad, setPaginationFirstLoad ] = useState(true);

  const isFirstRun = useRef(true);

  useEffect(() => {
    fetchEquipments();
  }, [state]);

  useEffect(() => {
    if (paginationFirstLoad) return;

    fetchEquipments();
  }, [paginationTotal, paginationPerPage, paginationPage, paginationFirstLoad]);

  const fetchEquipments = () => {
    const params = {
      ...{total: paginationTotal},
      ...{per_page: paginationPerPage},
      ...{page: paginationPage},
      ...{first_load: paginationFirstLoad},
      ...state
    }

    fetchAllEquipments(params)
      .then((data) => {
        if (isFirstRun.current) {
          setPaginationPerPage(data.per_page);
          isFirstRun.current = false;
        }

        setEquipments(data.equipments);
        setPaginationTotal(data.total);
        setPaginationFirstLoad(true);
      })
  }

  const uploadCSV = (files) => {
    const params = {
      file: files[0]
    }

    uploadCompanyPurchaseCSV(params)
      .then((data) => {
        setPaginationPerPage(data.per_page);
        setEquipments(data.equipments);
        setPaginationTotal(data.total);
        setPaginationFirstLoad(true);
      })
  }

  const renderEquipments = () => {
    return (
      <div>
        <div className="panel-header">
          <div className="row">
            <div className="col-md-6">
              <KeywordSearch
                state={state}
                setState={setState}
                updatePagination={undefined}
              />
            </div>

            <div className="col-md-6">
              <input
                type="file"
                id="uploadButton"
                name="csv"
                className="form-control d-none"
                accept=".csv"
                onChange={(e) => {
                  e.preventDefault();
                  uploadCSV(e.target.files);
                }}
              />
              <label htmlFor="uploadButton" id="upload_csv" className="btn btn-dark float-end">Upload CSV</label>
            </div>

          </div>
        </div>

        <div className="panel-table">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Invoice</th>
                <th>Price</th>
                <th>Vendor</th>
                <th>Usage</th>
                <th>Acquired</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {equipments && equipments.map((equipment, index) => {
                return (
                  <tr key={`user-row-${index}`}>
                    <td>{equipment.name}</td>
                    <td>{equipment.invoice}</td>
                    <td>${equipment.total_paid}</td>
                    <td>{equipment.vender}</td>
                    <td>{equipment.usage}</td>
                    <td>{equipment.acquisition_date}</td>
                    <td>
                      <a
                        href={`/company/company_purchase/${equipment.id}/edit`}
                        className="btn btn-sm btn-dark"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td colSpan={2}>
                  <div className='mt-2'>
                    <UIPaginationTotalSelector
                      perPage={paginationPerPage}
                      changePerPage={(value) => {
                        setPaginationPerPage(value);
                        setPaginationFirstLoad(false);
                      }}
                    />
                  </div>
                </td>

                <td colSpan={6}>
                  <span className='float-end mt-2'>
                    {paginationTotal > 0 ?
                      <UIPagination
                        page={paginationPage}
                        perPage={paginationPerPage}
                        changePage={(page) => {
                          setPaginationPage(page.selected + 1);
                          setPaginationFirstLoad(false);
                        }}
                        total={paginationTotal}
                      />
                      : null
                    }
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='panel-header pb-3 ps-0 pt-0'>
        <div className='row'>
          <h1>Equipments</h1>
          <hr />
        </div>
      </div>

      { renderEquipments() }
    </div>
  );
};

export default withUIPaginate(Equipments);
