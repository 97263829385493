import React, { useEffect, useState } from "react";
import { displayPrice, prettyDisplayPrice } from "lib/string";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  getProjectQuoteCategories,
  getCompanyProjectQuote,
  updateCompanyProjectQuote,
  updateProjectQuoteCategory,
} from "modules/project";
import { flashSuccess, flashError, flashWarning } from "lib/flash";

const ProjectQuoteDetails = (props) => {
  const [quoteCategories, setQuoteCategories] = useState([]);
  const [editableCategory, setEditableCategory] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [editableIndex, setEditableIndex] = useState(null);

  const [taxableSubtotal, setTaxableSubtotal] = useState(0);
  const [taxExemptSubtotal, setTaxExemptSubtotal] = useState(0);

  const [salesTax, setSalesTax] = useState(0);
  const [salesTaxEditable, setSalesTaxEditable] = useState(false);
  const [nestedQuoteCategories, setNestedQuoteCategories] = useState([]);
  const [companyProjectQuote, setCompanyProjectQuote] = useState(null);
  const [selectedPercentage, setSelectedPercentage] = useState(7.25);

  useEffect(() => {
    getCompanyProjectQuote(props.company_project_id).then((data) => {
      if (data) {
        setCompanyProjectQuote(data);
        if (data.sales_tax_rate) {
          setSelectedPercentage(data.sales_tax_rate);
        }
      }
    });
  }, []);

  // TODO: Make colors general to company
  const primaryColor =
    companyProjectQuote?.company_project_quote_parameter?.primary_color ||
    "#1D3360";
  const accentColor =
    companyProjectQuote?.company_project_quote_parameter?.accent_color ||
    "#E22947";

  useEffect(() => {
    getProjectQuoteCategories(props.company_project_id)
      .then((data) => {
        setQuoteCategories(data);
      })
      .catch((error) =>
        console.error("Failed to fetch Project Quote Categories: ", error)
      );
  }, []);

  console.log("quoteCategories", quoteCategories);

  useEffect(() => {
    setNestedQuoteCategories(buildNestedCategories(null, quoteCategories, 0));
  }, [quoteCategories]);

  const getTaxableTotalForCategory = (category) => {
    if (!!category.estimate_features?.taxable) {
      return parseFloat(category.total_amount || 0);
    }

    if (!category.children?.length) {
      return 0;
    }

    let taxableChildSubtotal =
      category.children?.reduce((total, child) => {
        return total + getTaxableTotalForCategory(child);
      }, 0) || 0;
    return taxableChildSubtotal;
  };

  useEffect(() => {
    let subtotal = nestedQuoteCategories.reduce((total, category) => {
      return total + parseFloat(category.total_amount || 0);
    }, 0);

    let calculatedTaxableSubtotal = nestedQuoteCategories.reduce(
      (total, category) => {
        return total + getTaxableTotalForCategory(category);
      },
      0
    );

    setTaxableSubtotal(calculatedTaxableSubtotal);
    setSalesTax(
      (
        (parseFloat(selectedPercentage) / 100) *
        calculatedTaxableSubtotal
      ).toFixed(2)
    );
    setTaxExemptSubtotal(subtotal - calculatedTaxableSubtotal);
  }, [nestedQuoteCategories, selectedPercentage]);

  const handleEdit = (category) => {
    setEditableIndex(category.id);
    setEditableCategory(category);
  };

  const handleChange = (field, value) => {
    setEditableCategory({
      ...editableCategory,
      [field]: value,
    });
  };

  const validateTotalAmount = () => {
    const calculatedTotal =
      parseFloat(editableCategory.unit_price) *
      parseFloat(editableCategory.quantity);
    const difference = Math.abs(
      calculatedTotal - parseFloat(editableCategory.total_amount)
    );
    const tolerance = 1.0;

    if (difference > tolerance) {
      return false;
    }

    return true;
  };

  const handleSalesTaxUpdate = () => {
    const grandTotal =
      parseFloat(taxableSubtotal) +
      parseFloat(taxExemptSubtotal) +
      parseFloat(salesTax || 0);
    const updatedQuote = {
      ...companyProjectQuote,
      sales_tax_rate: selectedPercentage,
      grand_total: grandTotal.toFixed(2),
    };

    updateCompanyProjectQuote(props.company_project_id, updatedQuote)
      .then(() => {
        flashSuccess("Sales tax and grand total updated successfully!");
        setSalesTaxEditable(false);
        setCompanyProjectQuote(updatedQuote);
      })
      .catch((error) => {
        console.error("Sales tax update failed: ", error);
      });
  };

  const handleUpdate = (categoryId) => {
    const index = quoteCategories.findIndex((cat) => cat.id === categoryId);
    if (!validateTotalAmount()) {
      flashError("Error: Unit Price x Quantity must be equal to Total Amount.");
      return;
      setEditableIndex(null);
    }

    setEditableIndex(null);
    updateProjectQuoteCategory(
      props.company_project_id,
      categoryId,
      editableCategory
    )
      .then(() => {
        flashSuccess("Row updated successfully!");

        setQuoteCategories((prevQuoteCategories) => {
          const updatedQuoteCategories = [...prevQuoteCategories];
          updatedQuoteCategories[index] = editableCategory;
          return updatedQuoteCategories;
        });

        setEditableCategory(null);
      })
      .catch((error) => {
        console.error("Update failed: ", error);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleUpdate(editableIndex);
    }
  };

  const buildNestedCategories = (parentId, categories, level) => {
    let nestedCategories = [];
    const sortedCategories =
      parentId === null
        ? [...categories].sort((a, b) => a.id - b.id)
        : categories;
    for (let category of sortedCategories) {
      if (category.parent_id === parentId) {
        category.level = level;
        let children = buildNestedCategories(
          category.project_estimate_category_id,
          categories,
          level + 1
        );
        if (children.length > 0) {
          category.children = children;
        }
        nestedCategories.push(category);
      }
    }
    return nestedCategories;
  };

  let count = 0;

  const renderRows = (categories, taxedByParent = false) => {
    return categories.flatMap((category, index) => {
      const indentStyle = {
        paddingLeft: `${category.level * 15}px`, // Indentation according to level
      };
      const rowStyle =
        category.parent_id === null
          ? { backgroundColor: "#f2f2f2", color: primaryColor }
          : {};
      const textStyle =
        category.parent_id === null ? { fontWeight: "bold" } : {};
      const taxedCategory =
        !taxedByParent && category.estimate_features?.taxable;

      if (category.parent_id === null) {
        count += 1;
      }
      let rows = [
        <tr
          key={category.id}
          onMouseEnter={() => setHoveredIndex(category.id)}
          onMouseLeave={() => setHoveredIndex(null)}
          onKeyDown={handleKeyDown}
          style={rowStyle}
        >
          <td>{category.parent_id === null ? count : null}</td>
          <td>
            <span style={indentStyle}>
              <>
                <b>
                  <span dangerouslySetInnerHTML={{ __html: category.name }} />
                </b>
                {category.description ? ` - ${category.description}` : ""}
                <br />
                {!category.collapsed && category.details
                  ? category.details.map((detail) => {
                      return (
                        <span style={indentStyle}>
                          {detail.description}
                          <br />
                        </span>
                      );
                    })
                  : null}
              </>
            </span>
          </td>
          <td>
            <span style={textStyle}>{category.quantity}</span>
            <br />
            {!category.collapsed && category.details
              ? category.details.map((detail) => {
                  return (
                    <>
                      {!!detail.quantity && detail.quantity}
                      <br />
                    </>
                  );
                })
              : null}
          </td>
          <td>
            <span style={textStyle}>
              {!!parseFloat(category.unit_price)
                ? displayPrice(category.unit_price, false, true)
                : ""}
            </span>
            <br />
            {!category.collapsed && category.details
              ? category.details.map((detail) => {
                  return (
                    <>
                      {!!detail.price &&
                        displayPrice(detail.price, false, true)}
                      <br />
                    </>
                  );
                })
              : null}
          </td>

          <td
            className="text-end"
            style={{ whiteSpace: "nowrap", color: primaryColor }}
          >
            <span style={textStyle}>
              {!!parseFloat(category.total_amount) &&
                displayPrice(category.total_amount, false, true)}
            </span>
            <br />
            {!category.collapsed && category.details
              ? category.details.map((detail) => {
                  return (
                    <>
                      {!!detail.total_amount &&
                        displayPrice(detail.total_amount, false, true)}
                      <br />
                    </>
                  );
                })
              : null}
          </td>
        </tr>,
      ];

      if (category.children) {
        rows.push(
          renderRows(
            category.children,
            taxedByParent || category.estimate_features?.taxable
          )
        );
      }
      return rows;
    });
  };

  return (
    <table
      className="quote-table"
      style={{ color: primaryColor, borderTop: `2px solid ${primaryColor}` }}
    >
      <thead>
        <tr>
          <th>No.</th>
          <th>Name</th>
          <th>Quantity</th>
          <th>Price</th>
          <th className="text-end">Amount</th>
        </tr>
      </thead>
      <tbody>
        {renderRows(nestedQuoteCategories)}
        <tr>
          <th colSpan={4} className="text-end">
            Subtotal
          </th>
          <td className="text-end">{displayPrice(taxableSubtotal)}</td>
        </tr>
        <tr
          onMouseEnter={() => setHoveredIndex(quoteCategories.length)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="sales-tax-tooltip" className="cashflow-tooltip">
                You can select a sales tax percentage from the dropdown or
                manually enter the sales tax value.
              </Tooltip>
            }
          >
            <th colSpan={4} className="text-end">
              Sales Tax
            </th>
          </OverlayTrigger>
          <td className="text-end" style={{ whiteSpace: "nowrap" }}>
            {salesTaxEditable ? (
              <>
                <div>
                  <select
                    value={parseFloat(selectedPercentage).toFixed(3)}
                    className="inline-edit"
                    style={{ display: "block" }}
                    onChange={(e) => {
                      const selectedPercentage = parseFloat(e.target.value);
                      setSelectedPercentage(selectedPercentage.toFixed(3));
                      const calculatedTax =
                        (selectedPercentage * parseFloat(taxableSubtotal)) /
                        100;
                      setSalesTax(calculatedTax.toFixed(2));
                    }}
                  >
                    {/* Generate options for each tax percentage */}
                    {Array.from({ length: 38 }, (_, i) => 7.25 + i * 0.125).map(
                      (rate) => (
                        <option value={rate.toFixed(3)} key={rate}>
                          {rate.toFixed(3)}%
                        </option>
                      )
                    )}
                  </select>
                  <input
                    type="number"
                    className="inline-edit"
                    value={salesTax}
                    onChange={(e) => setSalesTax(parseFloat(e.target.value))}
                  />
                </div>
                <button
                  className="btn btn-sm btn-primary me-2"
                  onClick={handleSalesTaxUpdate}
                >
                  <i className="bi bi-check"></i>
                </button>
                <button
                  className="btn btn-sm btn-muted"
                  onClick={() => setSalesTaxEditable(false)}
                >
                  <i className="bi bi-x"></i>
                </button>
              </>
            ) : (
              <>
                {displayPrice(salesTax, true, true)}
                {hoveredIndex === quoteCategories.length && (
                  <button
                    onClick={() => setSalesTaxEditable(true)}
                    className="btn btn-sm btn-muted"
                  >
                    <i
                      className="bi bi-pencil-square"
                      style={{ paddingLeft: "8px" }}
                    ></i>
                  </button>
                )}
              </>
            )}
          </td>
        </tr>
        <tr>
          <th colSpan={4} className="text-end">
            Tax-Exempt Total
          </th>
          <td className="text-end">
            {displayPrice(parseFloat(taxExemptSubtotal))}
          </td>
        </tr>
        <tr>
          <th colSpan={4} className="text-end">
            Grand Total
          </th>
          <td className="text-end">
            {displayPrice(
              parseFloat(taxableSubtotal) +
                parseFloat(taxExemptSubtotal) +
                parseFloat(salesTax || 0)
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ProjectQuoteDetails;
