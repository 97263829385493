import React, { useState, useEffect } from "react";

import { displayPrice } from "lib/string";
import { NumericFormat } from "react-number-format";

import TransactionTypeSelect from "./transaction-type-select";

const UnallocatedProjectRow = (props) => {
  const { project, item } = props;
  const [fraction, setFraction] = useState(0.0);
  const [transactionType, setTransactionType] = useState("");

  function allocateProject(e) {
    e.preventDefault();
    props.allocateProject(project, fraction, transactionType);
  }

  return (
    <tr>
      <td>{project.external_project_id}</td>
      <td>{project.name}</td>
      <td>{project.status}</td>
      <td>
        <TransactionTypeSelect
          transactionType={transactionType}
          setTransactionType={setTransactionType}
        />
      </td>
      <td>
        <NumericFormat
          allowNegative={false}
          suffix="%"
          value={fraction * 100}
          className="form-control"
          onValueChange={(values, sourceInfo) => {
            setFraction(values.floatValue / 100);
          }}
          isAllowed={(values) => {
            const { floatValue } = values;
            return (
              floatValue === undefined || (floatValue <= 100 && floatValue >= 0)
            );
          }}
        />
      </td>
      <td>{displayPrice(item.unit_cost * item.quantity * fraction)}</td>
      <td>
        <button className="btn btn-sm btn-action" onClick={allocateProject}>
          Allocate
        </button>
      </td>
    </tr>
  );
};

export default UnallocatedProjectRow;
