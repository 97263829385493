import React, { useState, useEffect } from "react";
import { uploadCompanyFileResource } from "modules/companies";
import { flashSuccess, flashError } from "lib/flash";
import { humanFileSize } from 'lib/string';
import ProgressBar from 'react-bootstrap/ProgressBar';
import moment from "moment";

const UploadingInProgress = (props) => {
  const file = props.file;
  const [ currentProgressBar, setcurrentProgressBar ] = useState(0);
  const [ uploadFileStatus, setUploadFileStatus ] = useState({ status: 'uploading' });
  const [ properFileUploaded, setProperFileUploaded ] = useState(false);

  const progressBarHash = {
    'success': { text: 'Success', class: 'success'},
    'uploading': { text: 'Uploading', class: 'info'},
    'failure': { text: 'Error', class: 'danger'}
  }

  const params = { 
    id: props.company.id, 
    company: {
      file: file 
    } 
  }

  if(props.fileCategoryId && parseInt(props.fileCategoryId) > 0) {
    params.company.file_category_id = props.fileCategoryId 
    console.log(params)
  }

  const options = {
    onUploadProgress: (progressEvent) => {
      let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
      setcurrentProgressBar(percentCompleted);
    }
  }

  const arrayIntersection = (a, b) => {
    return a.filter(x => b.includes(x));
  }

  const isFileTypeSupported = (fileType) => {
    const acceptedFileTypes = [
      'pdf',
      'image',
      'csv',
      'rtf',
      'msword',
      'doc',
      'docx',
      'plain',
      'txt',
      'vnd.ms-excel',
      'vnd.ms-powerpoint',
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'vnd.openxmlformats-officedocument.wordprocessingml.document',
      'vnd.openxmlformats-officedocument.presentationml.presentation',
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ]

    return arrayIntersection(fileType.split('/'), acceptedFileTypes).length > 0;
  }

  useEffect(() => {
    if (isFileTypeSupported(file.type)) {
      setProperFileUploaded(true);

      uploadCompanyFileResource(params, options, props.isAdmin)
        .then((data) => {
          props.onUploadResponse(data.file_resources.file_resources);
          setUploadFileStatus(data.status);
          if (data.status.status == 'success') {
            setcurrentProgressBar(100);
            flashSuccess("File Added.");
          }
        })
        .catch((error) => {
          flashError("Something Went Wrong.", error);
        })
    }
    else {
      setProperFileUploaded(false);
    }
  }, [])

  const progressBarText = () => {
    if (currentProgressBar == 100 && uploadFileStatus.status == 'uploading') { return 'Processing'; }
    return progressBarHash[uploadFileStatus.status].text;
  }

  const progressBarProcessing = () => {
    if (currentProgressBar == 100 && uploadFileStatus.status == 'uploading') { return true; }

    return false;
  }

  return(
    <div key={`company-file-resources-row-${props.index}`} className='card p-3 mb-3 d-block'>
      <div className='row'>
        <div className="col-md-12">
          <i className={`bi bi-filetype-${file.type}`}></i>
          {file.name}
          <p className="text-muted fs-6">{ humanFileSize(file.size) }</p>
        </div>
        <div className="col-md-12">
          { properFileUploaded &&
            <div>
              <ProgressBar
                striped={progressBarProcessing()}
                animated={progressBarProcessing()}
                variant={progressBarHash[uploadFileStatus.status].class}
                now={currentProgressBar}
                key={props.index}
              />
              <p className={`text-${progressBarHash[uploadFileStatus.status].class} mb-0`}>
                {progressBarText()}
              </p>
            </div>
          }
          { !properFileUploaded && <div><p>Binary files cannot be uploaded.</p></div> }
        </div>
      </div>
    </div>
  );
};

export default UploadingInProgress;
