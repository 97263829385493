import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";

import "react-datepicker/dist/react-datepicker.css";
import TextareaAutosize from "react-textarea-autosize";
import { fetchNaicsCode } from "modules/naics-code";

import {
  UIFieldGroup,
  UIFormLabel,
  UILabelInput,
  UIValidationError,
} from "components/ui";
import { EditCompanyContext } from "components/common/companies/contexts/admin-edit-company-context";
import { buildInitialValuesForSchema, deleteUnansweredValues } from "lib/utils";
import ReferralSourceSelector from "../common/referral-source-selector";

import { Formik, Form } from "formik";

import {
  isCompanyUpdateValid,
  validateCompanyFields,
} from "validators/company";

const identifierSchema = {
  type: "object",
  label: "Company Identifiers",
  properties: {
    ein: {
      row: 1,
      type: "string",
      label: "Employer Identification Number",
    },
    registry_id: {
      row: 2,
      type: "string",
      label: "Secretary of State Entity Number",
    },
    state_taxpayer_id: {
      row: 1,
      type: "string",
      label: "California Taxpayer ID",
    },
    duns_number: {
      row: 2,
      type: "string",
      label: "DUNS Number",
    },
  },
};

const BasicInfo = ({ businessEntities, onboarding }) => {
  // Via Codat (June 2022): https://www.codat.io/core-integrations/
  const supportedAcountingSoftware = [
    {
      name: "",
    },
    { name: "ClearBooks" },
    { name: "Exact" },
    { name: "FreeAgent" },
    { name: "FreshBooks" },
    { name: "KashFlow" },
    { name: "Kashoo" },
    { name: "Microsoft Dynamics 365" },
    { name: "MYOB AccountRight" },
    { name: "MYOB Essentials" },
    { name: "Oracle NetSuite" },
    { name: "Pandle" },
    { name: "QuickBooks Desktop" },
    { name: "QuickBooks Online" },
    { name: "Sage 50" },
    { name: "Sage 200cloud" },
    { name: "Sage Business Accounting" },
    { name: "Sage Intacct" },
    { name: "Wave" },
    { name: "Xero" },
    { name: "Zoho Books" },
    { name: "Other (not supported)" },
  ];

  const { onUpdateCompany, basicInfo, setBasicInfo, defaultNaicsOption } =
    useContext(EditCompanyContext);

  const [companyName, setCompanyName] = useState(basicInfo.name);
  const [validationErrors, setValidationErrors] = useState({
    commission_rate: "",
    duns_number: "",
    number_of_employees: "",
    annual_revenue: "",
  });

  const initialIdentifiers = buildInitialValuesForSchema(
    identifierSchema,
    basicInfo
  );

  const updateCompany = (basicInfo) => {
    onUpdateCompany(basicInfo).then(() => {
      setCompanyName(basicInfo.name);
    });
  };

  const onNaicsCodeSelect = (value) => {
    let naicsCodeIds = value.map(function (naics) {
      return naics.value;
    });
    setBasicInfo({ naics_code_ids: naicsCodeIds });
  };

  const onOptionsLoad = (value, cb) => {
    fetchNaicsCode({ query: value }).then((res) => {
      cb(
        res.map((row) => ({
          value: row.id,
          label: `${row.code} (${row.title})`,
        }))
      );
    });
  };

  const setCompanyField = (field, value) => {
    const errors = validateCompanyFields({ ...basicInfo, [field]: value });
    setValidationErrors({ ...validationErrors, ...errors });
    setBasicInfo({ [field]: value });
  };

  //TODO: this is all over the place. figure out how to apply this in CSS.
  const customStyles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "4px",
      border: "1px solid #e0e0e0",
      zIndex: 999,
    }),
  };

  return (
    <Formik initialValues={{ company_identifiers: { ...initialIdentifiers } }}>
      {({ values }) => {
        return (
          <div className="col-md-12">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                updateCompany({
                  ...basicInfo,
                  ...deleteUnansweredValues(values.company_identifiers),
                });
              }}
            >
              <div className="card p-3 mt-1 mb-3 z-depth-2">
                <h3 className="panel-title">Company Information</h3>
                <div className="row">
                  <div className="col-md-6">
                    <UILabelInput
                      label="Business Name"
                      value={basicInfo.name}
                      onChange={(value) => {
                        setBasicInfo({ name: value });
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <UILabelInput
                      label="Website"
                      value={basicInfo.website}
                      onChange={(value) => {
                        setBasicInfo({ website: value });
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <UILabelInput
                      label="Number of Employees"
                      value={basicInfo.number_of_employees}
                      error={validationErrors.number_of_employees}
                      onChange={(value) => {
                        setCompanyField("number_of_employees", value);
                      }}
                    />
                  </div>

                  <div className="col-md-6">
                    <UILabelInput
                      label="Gross Annual Revenue"
                      value={basicInfo.annual_revenue}
                      error={validationErrors.annual_revenue}
                      onChange={(value) => {
                        setCompanyField("annual_revenue", value);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>
                      <label className="mb-1">Founded On</label>
                    </strong>
                    <div className="ui-label-input">
                      <DatePicker
                        className="form-control"
                        name="Founded On"
                        selected={basicInfo.date_established}
                        scrollableYearDropdown={true}
                        showYearDropdown={true}
                        yearDropdownItemNumber={100}
                        maxDate={new Date()}
                        onChange={(date) =>
                          setBasicInfo({ date_established: date })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <strong>
                      <label htmlFor="legal_structure" className="mb-1">
                        Legal Structure
                      </label>
                    </strong>
                    <div className="ui-label-input">
                      <select
                        name="legal_structure"
                        id="legal-structure"
                        className="form-control"
                        value={basicInfo.business_entity_id || ""}
                        onChange={(e) => {
                          setBasicInfo({ business_entity_id: e.target.value });
                        }}
                      >
                        <option value="" disabled>
                          Select Legal Structure
                        </option>
                        {businessEntities.map((entity, index) => {
                          return (
                            <option
                              key={`${entity.id}-${index}`}
                              value={entity.id}
                            >
                              {entity.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <strong>
                      <label htmlFor="accounting_software" className="mb-1">
                        Accounting Software
                      </label>
                    </strong>
                    <div className="ui-label-input">
                      <select
                        name="accounting_software"
                        id="accounting-software"
                        className="form-control"
                        value={basicInfo.accounting_software || ""}
                        onChange={(e) => {
                          setCompanyField(
                            "accounting_software",
                            e.target.value
                          );
                        }}
                      >
                        <option value="" disabled>
                          Select Accounting Software
                        </option>
                        {supportedAcountingSoftware.map(
                          (accountingSoftware, index) => (
                            <option
                              key={`${accountingSoftware.name}-${index}`}
                              value={accountingSoftware.name}
                            >
                              {accountingSoftware.name}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <strong>
                      <label className="mb-1">NAICS Code</label>
                    </strong>
                    <div className="ui-label-input">
                      <AsyncSelect
                        styles={customStyles}
                        isMulti
                        defaultValue={defaultNaicsOption}
                        loadOptions={onOptionsLoad}
                        onChange={onNaicsCodeSelect}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    key={`company-description-${basicInfo.id}`}
                    className="col-md-12 mb-3"
                  >
                    <UIFormLabel
                      label="Company Description"
                      tooltip="Initial value is pulled from the companies website on company creation."
                    />
                    <div className="ui-label-input">
                      <TextareaAutosize
                        type="text"
                        name="Description"
                        className="form-control"
                        placeholder="Enter company bio here..."
                        value={basicInfo.description || ""}
                        onChange={(e) => {
                          setBasicInfo({ description: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card p-3 mt-1 mb-3 z-depth-2">
                <UIFieldGroup
                  prefix="company_identifiers"
                  schema={identifierSchema}
                />
              </div>

              <div className="card p-3 mt-1 mb-3">
                <h3 className="panel-title">Account Management</h3>
                <div className="mb-3">
                  <div>
                    <UIFormLabel label="Set Company Status" />
                    <br />
                  </div>
                  <label htmlFor="is-active">
                    <input
                      type="checkbox"
                      id="is-active"
                      className="company-admin-checkbox me-2"
                      checked={basicInfo.is_active}
                      onChange={() => {
                        setBasicInfo({
                          is_active: basicInfo.is_active ? false : true,
                        });
                      }}
                    />
                    Active
                  </label>
                </div>
                <div className="row">
                  <div className="col-6">
                    <strong>
                      <label htmlFor="onboarding-flow" className="mb-1">
                        Onboarding Flow
                      </label>
                    </strong>
                    <div className="ui-label-input">
                      <select
                        name="onboarding_flow"
                        id="onboarding-flow"
                        className="form-control"
                        value={basicInfo.onboarding_flow_id || ""}
                        onChange={(e) => {
                          setBasicInfo({
                            onboarding_flow_id: parseInt(e.target.value),
                          });
                        }}
                      >
                        <option value="" disabled>
                          Select Onboarding Flow
                        </option>
                        {onboarding.map((flow, index) => {
                          return (
                            <option key={`${flow.id}-${index}`} value={flow.id}>
                              {flow.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <UILabelInput
                      label="Base Commmission Rate"
                      value={basicInfo.commission_rate}
                      error={validationErrors.commission_rate}
                      onChange={(value) => {
                        setCompanyField("commission_rate", value);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <UILabelInput
                      label="Company Category"
                      value={basicInfo.company_category}
                      onChange={(value) => {
                        setCompanyField("company_category", value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="ui-label-input">
                      <UIFormLabel label="Referral Source" />
                      <ReferralSourceSelector
                        value={basicInfo.referral_source_id}
                        label={basicInfo.referral_source_name}
                        onSelect={(selectedOption) => {
                          setCompanyField(
                            "referral_source_id",
                            selectedOption.value
                          );
                          setCompanyField(
                            "referral_source_name",
                            selectedOption.label
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="btn-group mt-3 float-end">
                    <a className="btn btn-light" href="/admin/companies">
                      Back
                    </a>
                    <button
                      className="btn btn-primary"
                      disabled={!isCompanyUpdateValid(basicInfo)}
                      type="submit"
                      formNoValidate
                    >
                      Save
                    </button>
                  </div>
                  {isCompanyUpdateValid(basicInfo)}
                </div>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default BasicInfo;
