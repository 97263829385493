import React from 'react'
import AddressInput from "./address-input";
import { filter, findIndex, cloneDeep, assign } from "lodash";

const Address = (props) => {
  const {
    variables,
    editAddress,
    setEditAddress,
    blankAddress,
    onFieldChange,
  } = props;

  const markAddressPrimary = (primaryAddress) => {
    const newAddresses = variables.addresses.map((address) => {
      if (primaryAddress.id === address.id) {
        address.is_primary = true;
      } else {
        address.is_primary = false;
      }
      return address;
    });
    onFieldChange(newAddresses, "addresses");
  };

  const onDeleteAddress = (address) => {
    const newAddresses = filter(variables.addresses, (o) => {
      return o.id != address.id;
    });
    if (newAddresses.length > 0 && address.is_primary === true) {
      newAddresses[0].primary = true;
    }
    setEditAddress(blankAddress());
    onFieldChange(newAddresses, "addresses");
  };

  const onAddressAdd = (address) => {
    if (variables.addresses.length === 0) {
      address.is_primary = true;
    }
    const newAddresses = cloneDeep(variables.addresses);
    const existingAddressIndex = findIndex(newAddresses, { id: address.id });
    if (existingAddressIndex !== -1) {
      newAddresses[existingAddressIndex] = assign(address, {
        primary: newAddresses[existingAddressIndex].primary,
      });
    } else {
      newAddresses.push(address);
    }
    setEditAddress(blankAddress());
    onFieldChange(newAddresses, "addresses");
  };

  const getAddress = (address) => {
    let addressLine = address.address_line_1;

    if (address.address_line_2 != "")
      addressLine += `, ${address.address_line_2}`;

    addressLine += ` ${address.city}, ${address.state} ${address.postal_code}`;

    return addressLine;
  };

  return (
    <div className="row mt-5">
      <h3>Addresses</h3>
      <hr className="line-adjust" />
      <div className="col-md-6">
        <AddressInput editAddress={editAddress} onAddressAdd={onAddressAdd} />
      </div>
      <div className="col-md-6">
        {variables.addresses.map((address) => {
          return (
            <div key={address.id} className="card p-3 mb-1">
              <div>{getAddress(address)}</div>
              <div className="d-flex justify-content-between align-items-center mt-3 flex-wrap">
                <label htmlFor={`is_primary-${address.id}`}>
                  <input
                    type="checkbox"
                    id={`is_primary-${address.id}`}
                    className="company-admin-checkbox"
                    checked={address.is_primary}
                    onChange={() => {
                      markAddressPrimary(address);
                    }}
                  />
                  Primary
                </label>
                <div className="d-flex">
                  <button
                    className="btn btn-light"
                    onClick={() => setEditAddress(address)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-dark mx-1"
                    onClick={() => onDeleteAddress(address)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Address;
