import axios from 'lib/axios'

export const fetchAdminNotifications = (params = {}) => {
  const url = "/admin/notifications";

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchCompanyNotifications = (params = {}) => {
  const url = "/company_notifications";

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateCompanyNotification = (id, params = {}) => {
  const url = `/company_notifications/${id}`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
