import React, { useState, useEffect } from 'react';
import { useArray} from "react-hanger";

import IncentiveUrlRow from './incentive-url-row'
import { newIncentiveUrl } from 'modules/incentive-urls'

const IncentiveUrls = (props) => {
  const urls = useArray(props.urls)
  const [newUrl, setNewUrl] = useState(null)

  useEffect(() => {
    props.setUrls(urls.value)
  }, [urls]);

  const removeUrl = (index) => {
    urls.removeIndex(index);
  }

  const updateUrl = (url) => {
    urls.modifyById(url.id, url)
  }

  const addUrl = (url) => {
    setNewUrl(null)
    urls.push(url)
  }

  const resetNewUrl = () => {
    setNewUrl(null)
  }

  return (
    <table className='table table-borderless table-sm'>
      <tbody>
        {
          urls.value.map((url, index) => {
            return (
              <IncentiveUrlRow
                incentiveId={props.incentiveId}
                key={`incentive-url-row-${index}-${url.id}`}
                updateUrl={updateUrl}
                removeUrl={removeUrl}
                resetNewUrl={resetNewUrl}
                addUrl={addUrl}
                index={index}
                url={url}
              />
            )
          })
        }
        {newUrl && <IncentiveUrlRow
          incentiveId={props.incentiveId}
          updateUrl={updateUrl}
          removeUrl={removeUrl}
          resetNewUrl={resetNewUrl}
          addUrl={addUrl}
          url={newUrl}
          add={true}
        />}
        <tr>
          <td colSpan={3}>
            <div className="d-grid gap-2">
              <button className='btn btn-outline-light' onClick={(e) => {
                e.preventDefault();
                newIncentiveUrl()
                  .then((newUrl) => {
                    setNewUrl(newUrl)
                  })
              }}>
                <i className="bi bi-plus"></i>
                Add URL
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

IncentiveUrls.defaultProps = {
  urls: [],
  setUrls: () => {}
}

export default IncentiveUrls;