import React from "react";
import ApplicationNav from "./common/application-nav";

import { withUINavTabs, UINavPanel, UINavSection } from "components/ui";

const ShowApplication = (props) => {
  return (
    <div className="application">
      <ApplicationNav navigation={props.navigation} />
      <div className="container">
        <UINavPanel>
          {props.navigation.map((item, index) => {
            const Component = require(`./${item.component}`).default;
            const componentProps = props[item.key];

            return (
              <UINavSection tab={item.link} key={`ui-nav-section-${item.key}`}>
                <Component {...componentProps} />
              </UINavSection>
            );
          })}
        </UINavPanel>
      </div>
    </div>
  );
};

export default withUINavTabs(ShowApplication);
