import axios from "lib/axios";

export const fetchDemographics = (params = {}) => {
  let url = `/demographics`;

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const toggleCompanyDemographic = (demographic, company_id) => {
  let url = `/demographics/${demographic.id}/company_demographics`

  if(demographic.is_checked) {
    return axios.delete(url, { data: { company_id }})
      .then((response) => {
        return Promise.resolve(false)
      })
      .catch((error) => {
        return Promise.reject(error.response.data)
      })
  } else {
    return axios.post(url, { company_id })
      .then((response) => {
        return Promise.resolve(true)
      })
      .catch((error) => {
        return Promise.reject(error.response.data)
      })
  }
}
