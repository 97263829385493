import React, { useState } from "react";

const OptionCard = ({ answerOption, onOptionEdit }) => {
  const [optionHover, setOptionHover] = useState(false);

  return (
    <div
      className="my-1 py-2 px-3 card text-dark bg-light d-flex justify-content-between flex-row align-items-center"
      key={answerOption.id}
      onMouseEnter={() => setOptionHover(true)}
      onMouseLeave={() => setOptionHover(false)}
    >
      <div className="py-2">{answerOption.option_text}</div>

      {optionHover && (
        <button
          className="btn btn-dark float-end"
          onClick={() => onOptionEdit(answerOption)}
        >
          <i className="bi bi-pencil-square"></i>
        </button>
      )}
    </div>
  );
};

export default OptionCard;
