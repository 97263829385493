import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";

import {
  fetchAdminCompanies,
  fetchAdminCompanyIncentiveEligibility,
} from "modules/companies";

import { checkIncentiveEligibility } from "modules/incentives";
import { flashError, flashSuccess } from "lib/flash";
import UINavCompaniesDropdown from "../../../../ui/ui-nav-companies-dropdown";

const IncentiveEligibility = ({ incentiveId }) => {
  const [companyId, setCompanyId] = useState("");
  const [companies, setCompanies] = useState([]);
  const [totalPages, setTotalPages] = useState("");
  const [eligibilityJSON, setEligibilityJSON] = useState("");
  const dropdownStyle = {
    dropdownWidth: "min-content",
    dropdownButton:
      "bg-transparent text-dark form-control eligibility-company-dropdown is-flex",
  };

  useEffect(() => {
    fetchAdminCompanies({ per_page: 10 }).then((res) => {
      setTotalPages(res.total_pages);
      setCompanies(res.companies);
    });
  }, []);

  useEffect(() => {
    if (companyId === "") return;
    fetchAdminCompanyIncentiveEligibility({ id: companyId }).then((res) => {
      setEligibilityJSON(res);
    });
  }, [companyId]);

  const checkEligibility = () => {
    checkIncentiveEligibility({
      id: incentiveId,
      company_id: companyId,
    }).then((res) => {
      if (res.matched) flashSuccess("Rule matched");
      else flashError("Rule did not match");
    });
  };

  return (
    <div className="mt-5">
      <hr />
      <h3 className="mb-4 mt-1">Test Company Eligibility</h3>

      {companies.length > 0 && (
        <UINavCompaniesDropdown
          userCompanies={companies}
          currentCompany={null}
          onChange={(id) => setCompanyId(id)}
          isEdit={false}
          dropdownStyle={dropdownStyle}
          total_pages={totalPages}
        />
      )}

      {eligibilityJSON && eligibilityJSON !== "" && (
        <div className="card card-monokai mt-3">
          <div className="card-body">
            <ReactJson
              src={eligibilityJSON}
              collapsed
              name={false}
              theme="monokai"
            />
          </div>
        </div>
      )}

      <div className="d-flex justify-content-end">
        <div className="float-end btn-group">
          <button
            className="btn btn-secondary mt-3 mb-3"
            onClick={checkEligibility}
          >
            Check Eligibility
          </button>
        </div>
      </div>
    </div>
  );
};

export default IncentiveEligibility;
