import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

import { useSetState } from "react-hanger";

import { UILabelInput, UIDateInput, UIFormLabel } from "components/ui";
import ProjectStatusSelect from "./project-status-select";
import {
  updateCompanyProject,
  createCompanyProject,
  getAllProjectManagers,
  getAllCustomers,
  getAllProjectTypes,
  getAllTemplates,
} from "modules/project";
import { createApplicationProjectResources } from "modules/applications";
import { flashError, flashSuccess, flashSuccessLater } from "lib/flash";

import { newProject } from "modules/project";

const ProjectForm = (props) => {
  const { state: form, setState: setForm } = useSetState(
    newProject(props.project)
  );

  const [projectManagers, setProjectManagers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [templates, setTemplates] = useState([]);

  const { setShowModal } = props;

  const handleCancelClick = () => {
    if (props.application) {
      props.setShowModal(false);
    } else {
      window.location.href = "/projects";
    }
  };

  useEffect(() => {
    fetchProjectManagers();
    fetchCustomers();
    fetchProjectTypes();
    fetchTemplates();
  }, []);

  const fetchProjectManagers = () => {
    getAllProjectManagers()
      .then((managers) => {
        setProjectManagers(managers);
      })
      .catch((error) => {
        console.error("Failed to fetch project managers:", error);
      });
  };

  const fetchCustomers = () => {
    getAllCustomers()
      .then((customers) => {
        setCustomers(customers);
      })
      .catch((error) => {
        console.error("Failed to fetch customers:", error);
      });
  };

  const fetchProjectTypes = () => {
    getAllProjectTypes()
      .then((types) => {
        setProjectTypes(types);
      })
      .catch((error) => {
        console.error("Failed to fetch project types:", error);
      });
  };

  const fetchTemplates = () => {
    getAllTemplates()
      .then((templates) => {
        setTemplates(templates);
      })
      .catch((error) => {
        console.error("Failed to fetch templates: ", error);
      });
  };

  const handleProjectManagerChange = (selected) => {
    if (selected.length > 0) {
      const selectedOption = selected[0];
      const projectManager = selectedOption.customOption
        ? selectedOption.name
        : selectedOption;
      setForm({
        ...form,
        project_manager: projectManager,
      });
    } else {
      setForm({
        ...form,
        project_manager: "",
      });
    }
  };

  const handleCustomerChange = (selected) => {
    if (selected.length > 0) {
      const selectedOption = selected[0];
      const customer = selectedOption.customOption
        ? selectedOption.name
        : selectedOption;
      setForm({
        ...form,
        customer: customer,
      });
    } else {
      setForm({
        ...form,
        customer: "",
      });
    }
  };

  const handleProjectTypeChange = (selected) => {
    if (selected.length > 0) {
      const selectedOption = selected[0];
      const projectType = selectedOption.customOption
        ? selectedOption.name
        : selectedOption;
      setForm({
        ...form,
        project_type: projectType,
      });
    } else {
      setForm({
        ...form,
        project_type: "",
      });
    }
  };

  const handleTemplateChange = (selected) => {
    if (selected.length > 0) {
      const selectedOption = selected[0];
      const template = selectedOption.customOption
        ? selectedOption.name
        : selectedOption;
      setForm({
        ...form,
        template: template,
      });
    } else {
      setForm({
        ...form,
        template: "",
      });
    }
  };

  const filteredProjectManagers = projectManagers.filter(
    (manager) => manager !== null && manager !== ""
  );

  const filteredProjectTypes = projectTypes.filter(
    (type) => type !== null && type !== ""
  );

  const filteredCustomers = customers.filter(
    (customer) => customer !== null && customer !== ""
  );

  function attachProjectToApplication(projectId, applicationId) {
    createApplicationProjectResources(applicationId, [projectId])
      .then(() => {
        if (props.onProjectCreated) {
          props.onProjectCreated();
          props.setShowModal(false);
        }
      })
      .catch((error) => {
        flashError(
          "Failed to attach project to the application: " + error.message
        );
      });
  }

  function handleSubmit(e) {
    e.preventDefault();

    const updatedForm = {
      ...form,
      status: form.status?.value || "active",
    };

    const projectPromise = form.id
      ? updateCompanyProject(updatedForm)
      : createCompanyProject(updatedForm);

    projectPromise
      .then((project) => {
        flashSuccess("Project successfully saved.");
        if (!form.id && props.application) {
          attachProjectToApplication(project.id, props.application);
        } else {
          window.location = `/projects/${newProject.id}`; // Redirect to the new project page
        }
      })
      .catch((error) => {
        flashError("Failed to save project: " + error.message);
      });
  }

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="row mb-3">
        <div className="col-md-6">
          <UILabelInput
            value={form.name}
            label="Project Name"
            onChange={(name) => setForm({ name })}
            required={true}
            className="mb-3"
          />
        </div>
        <div className="col-md-6">
          <UILabelInput
            value={form.external_project_id}
            label="Project Id"
            onChange={(external_project_id) => setForm({ external_project_id })}
            required={false}
            className="mb-3"
            placeholder="Project ID if you have one"
          />
        </div>
      </div>
      {!props.application && (
        <div className="row mb-3">
          <div className="col-md-6">
            <UIFormLabel label="Customer" />
            <div className="ui-label-input">
              <Typeahead
                id="customer-typeahead"
                onChange={handleCustomerChange}
                options={filteredCustomers}
                selected={form.customer ? [form.customer] : []}
                placeholder="Customer"
                allowNew
                labelKey="name"
              />
            </div>
          </div>
          <div className="col-md-6">
            <UIFormLabel label="Template" />
            <div className="ui-label-input">
              <Typeahead
                id="project-template-typeahead"
                onChange={handleTemplateChange}
                options={templates}
                selected={form.template ? [form.template] : []}
                placeholder="Select Project Template"
                labelKey="name"
              />
            </div>
          </div>
        </div>
      )}
      {!props.application && (
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="mb-1">
              <strong>Project Status</strong>
            </label>
            <ProjectStatusSelect
              status={form.status}
              onChange={(status) => setForm({ status })}
              required={false}
              isUpdating={true}
            />
          </div>
          <div className="col-md-6">
            <UIFormLabel label="Priority" />
            <select
              id="priority"
              value={form.priority}
              onChange={(e) => setForm({ ...form, priority: e.target.value })}
              className="form-select ui-label-input mb-3"
              required={false}
            >
              <option value="">Select Priority</option>
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </select>
          </div>
        </div>
      )}
      <div className="row mb-3">
        <div className="col-md-6">
          <UIDateInput
            label="Start Date"
            date={form.start_date}
            handleChange={(start_date) => setForm({ start_date })}
            required={false}
          />
        </div>
        <div className="col-md-6">
          <UIDateInput
            label="End Date"
            date={form.end_date}
            handleChange={(end_date) => setForm({ end_date })}
            required={false}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-6">
          <UIFormLabel label="Project Manager" />
          <div className="ui-label-input">
            <Typeahead
              id="project-manager-typeahead"
              onChange={handleProjectManagerChange}
              options={filteredProjectManagers}
              selected={form.project_manager ? [form.project_manager] : []}
              placeholder="Project Manager"
              allowNew
              labelKey="name"
            />
          </div>
        </div>
        <div className="col-md-6">
          <UIFormLabel label="Project Type" />
          <div className="ui-label-input">
            <Typeahead
              id="project-type-typeahead"
              onChange={handleProjectTypeChange}
              options={filteredProjectTypes}
              selected={form.project_type ? [form.project_type] : []}
              placeholder="Project Type"
              allowNew
              labelKey="name"
            />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <UILabelInput
          value={form.project_notes}
          label="Description"
          onChange={(project_notes) => setForm({ project_notes })}
          required={false}
          className="mb-3"
          placeholder="Provide a brief summary of the project."
        />
      </div>
      {!props.application && (
        <div className="row mb-3">
          <div style={{ display: "flex", alignItems: "center" }}>
            <UIFormLabel label="Enable Markup" />
            <label className="switch" style={{ marginLeft: "20px" }}>
              <input
                type="checkbox"
                checked={form.is_markup_active}
                onChange={() => {
                  setForm({ is_markup_active: !form.is_markup_active });
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>

          <small
            style={{ color: "#777", display: "block", marginBottom: "10px" }}
          >
            {form.is_markup_active ? (
              <>
                <strong>Markup is currently enabled.</strong> This allows you to
                add a margin within your project estimate, which can be used to
                generate a proposal for a customer.
              </>
            ) : (
              <>
                <strong>Markup is currently disabled.</strong> The estimate can
                only be used for budgeting and tracking purposes and you will
                not be able to generate customer proposals.
              </>
            )}
          </small>
        </div>
      )}

      <div className="mt-3 float-end">
        <button
          type="button"
          className="btn btn-light me-3"
          onClick={handleCancelClick}
        >
          Cancel
        </button>
        <button className="btn btn-primary" type="submit">
          {props.saveText}
        </button>
      </div>
    </form>
  );
};

export default ProjectForm;
