import React, { useState, useEffect } from "react";
import { FormControl, DropdownButton, Dropdown } from "react-bootstrap";
import { fetchAdminIncentives } from "modules/incentives";
import filter from "lodash/filter";
import { match } from "lib/string";

const IncentiveSelector = ({ onIncentiveSelect, selectedIncentive }) => {
  const [q, setQ] = useState("");
  const [incentives, setIncentives] = useState([]);
  const [selectedIncentiveName, setSelectedIncentiveName] = useState(
    "Select an Incentive"
  );

  useEffect(() => {
    fetchAdminIncentives({ simple: true }).then((data) => {
      setIncentives(data);
    });
  }, []);

  useEffect(() => {
    const selected = incentives.find(
      (incentive) => incentive.id === selectedIncentive
    );
    if (selected) {
      setSelectedIncentiveName(selected.name);
    }
  }, [selectedIncentive, incentives]);

  const selectIncentive = (id) => {
    const selected = incentives.find(
      (incentive) => String(incentive.id) === String(id)
    );
    if (selected) setSelectedIncentiveName(selected.name);
    if (onIncentiveSelect) {
      onIncentiveSelect(id);
    } else {
      window.location = `/admin/incentives/${id}/edit`;
    }
  };

  const selectIncentives = (incentives) => {
    return filter(incentives, (incentive) => {
      return match(incentive.name, q);
    });
  };

  const filteredIncentives = selectIncentives(incentives);

  return (
    <DropdownButton
      id="dropdown-basic-button"
      className="incentive-selector"
      title={selectedIncentiveName}
      variant="light"
      onSelect={selectIncentive}
    >
      <div className="px-3">
        <FormControl
          autoFocus
          className="my-2"
          placeholder="Type to filter..."
          onChange={(e) => setQ(e.target.value)}
          value={q}
        />
      </div>
      <div
        style={{ overflowY: "scroll", maxHeight: "350px", minWidth: "650px" }}
      >
        {filteredIncentives.map((incentive, index) => (
          <Dropdown.Item eventKey={incentive.id} key={`incentive-${index}`}>
            {incentive.name}
          </Dropdown.Item>
        ))}
      </div>
    </DropdownButton>
  );
};

export default IncentiveSelector;
