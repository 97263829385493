import React, { useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { UITooltipDescription } from "components/ui";

function CategoryDescriptionTooltip(props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);

  const hideTooltip = () => {
    setShowTooltip(false);
  };

  if (!props.description) {
    return null;
  }

  return (
    <div
      className="ms-2 float-end"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div ref={target}>
        <i
          className="bi bi-info-circle-fill"
          style={{ color: "#1D3360", opacity: "0.9" }}
        ></i>
      </div>

      <div>
        <Overlay placement="right" target={target.current} show={showTooltip}>
          <Tooltip
            id="tooltip"
            className="cashflow-tooltip"
            onMouseEnter={() => setShowTooltip(true)}
          >
            <UITooltipDescription
              description={props.description}
              hideTooltip={hideTooltip}
            />
          </Tooltip>
        </Overlay>
      </div>
    </div>
  );
}

export default CategoryDescriptionTooltip;
