import React from "react";

function withUIPaginate(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.updatePagination = this.updatePagination.bind(this);

      this.state = {
        pagination: {
          per_page: props.per_page || 50,
          page: props.page || 1,
          total: this.props.paginationData?.total,
          first_load: true,
        },
      };
    }

    updatePagination(params) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          page: params.per_page === undefined ? this.state.pagination.page : 1,
          ...params,
        },
      });
    }

    render() {
      return (
        <div>
          <WrappedComponent
            {...this.props}
            pagination={this.state.pagination}
            updatePagination={this.updatePagination}
          />
        </div>
      );
    }
  };
}

export default withUIPaginate;
