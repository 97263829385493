import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useSetState } from "react-hanger";
import { snakeCase, isEmpty } from "lodash";

import { fetchAdminQualifications } from "modules/qualifications";
import AdminPanelHeader from "../../common/admin-panel-header";
import AdminPanelIndexControls from "../../common/admin-panel-index-controls";

import {
  withUIPaginate,
  UIPaginationTotalSelector,
  UIPagination,
  UITableSortable,
} from "components/ui";
import IncentivesCollapsable from "./common/incentives-collapsable";

const Qualifications = (props) => {
  const typeHash = {
    "": "All",
    multiple_choice: "Multiple Choice",
    short_text: "Short Text",
  };

  const { pagination } = props;

  const [qualifications, setQualification] = useState(props.qualifications);
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([1, 2, 3]);

  const { state, setState } = useSetState({
    query: "",
    sortColumn: "name",
    sortDirection: "ASC",
    status: "",
    type: "",
  });

  const handleSelect = (e) => {
    let dropdown = e.split(", ");
    let key = dropdown[0];
    let value = dropdown[1];

    let new_state = state;
    new_state[key] = value;

    setState(new_state);
  };

  const typeOptions = Object.keys(typeHash).map((key) => ({
    value: key,
    label: typeHash[key],
  }));

  const categoryOptions = categories.map((category) => ({
    value: category,
    label: category,
  }));

  const levelOptions = levels.map((level) => ({
    value: level.toString(),
    label: `Level ${level}`,
  }));

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    callFetchQualifications();
  }, [state]);

  useEffect(() => {
    if (pagination.first_load) return;

    callFetchQualifications();
  }, [pagination]);

  const fetchQualifications = (params = {}) => {
    fetchAdminQualifications(params).then((data) => {
      props.updatePagination({
        total: data.pagination.total,
        first_load: true,
      });

      setQualification(data.qualifications);
      setCategories(data.categories);
    });
  };

  const onSortChange = (direction, column) => {
    setState({
      sortDirection: direction,
      sortColumn: column,
    });
  };

  const callFetchQualifications = () => {
    fetchQualifications({
      ...state,
      ...pagination,
      sort_column: snakeCase(state.sortColumn),
      sort_direction: state.sortDirection,
    });
  };

  useEffect(() => {
    fetchQualifications();
  }, []);

  return (
    <>
      <AdminPanelHeader
        title="Qualifications"
        singularTitle="Qualifying Question"
        description="Qualifying questions are used to match companies with programs."
        addNewUrl="/admin/qualifications/new"
      />
      <AdminPanelIndexControls
        keywordSearchConfig={{
          state: state,
          setState: setState,
          updatePagination: props.updatePagination,
        }}
        selectConfigs={[
          {
            options: typeOptions,
            value: state.access_granted
              ? {
                  value: state.type,
                  label: typeHash[state.type],
                }
              : null,
            onChange: (option) =>
              handleSelect(`type, ${option ? option.value : ""}`),
            className: "ui-multi-filter",
            placeholder: "Type",
          },
        ]}
        multiSelectConfigs={[
          {
            onChange: (options) =>
              handleSelect(
                `category, ${options.map((option) => option.value).join(",")}`
              ),
            options: categoryOptions,
            value: state.category
              ? state.category.split(",").map((category) => ({
                  value: category,
                  label: category,
                }))
              : [],
            className: "ui-multi-filter",
            placeholder: "Category",
          },
          {
            onChange: (options) =>
              handleSelect(
                `level, ${options.map((option) => option.value).join(",")}`
              ),
            options: levelOptions,
            value: state.level
              ? state.level.split(",").map((level) => ({
                  value: level,
                  label: level,
                }))
              : [],
            className: "ui-multi-filter",
            placeholder: "Specificity Level",
          },
        ]}
      />
      <div className="px-3">
        <div className="panel-table">
          <table className="table admin-table">
            <thead>
              <tr>
                <th>
                  <UITableSortable title="Id" />
                </th>
                <th>
                  <UITableSortable title="Category" />
                </th>
                <th>
                  <UITableSortable title="Level" />
                </th>
                <th style={{ width: "400px" }}>
                  <UITableSortable title="Question" />
                </th>
                <th>
                  <UITableSortable title="Answer Options" />
                </th>
                <th>
                  <UITableSortable title="Incentives" />
                </th>
                <th>
                  <UITableSortable title="Updated At" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {qualifications.map((qualification, index) => {
                return (
                  <tr key={`qualification-row-${index}`}>
                    <td className="admin-metadata">{qualification.id}</td>
                    <td>
                      <span>{qualification.category}</span>
                    </td>
                    <td>
                      <span>{qualification.level}</span>
                    </td>
                    <td>
                      <span>{qualification.question_title}</span>
                    </td>
                    <td>
                      {qualification.answer_type === "short_text" ? (
                        <span className="d-inline px-2 py-1 text-primary-emphasis bg-light-subtle border border-light-subtle rounded-2">
                          Short Text
                        </span>
                      ) : qualification.answer_type === "multiple_choice" ? (
                        <ul className="mb-0">
                          {!isEmpty(qualification.answer_options) &&
                            qualification.answer_options.map(
                              (answerOption, index) => (
                                <li key={index}>{answerOption.option_text}</li>
                              )
                            )}
                        </ul>
                      ) : (
                        <span className="d-inline px-2 py-1 text-danger bg-light-subtle border border-light-subtle rounded-2">
                          Answer type not found
                        </span>
                      )}
                    </td>

                    <td>
                      <IncentivesCollapsable
                        incentives={qualification.incentives}
                      />
                    </td>
                    <td>
                      {qualification.updated_at
                        ? moment(qualification.updated_at).format("MM/DD/YYYY")
                        : null}
                    </td>
                    <td>
                      <a
                        href={`/admin/qualifications/${qualification.id}/edit`}
                        className="btn btn-sm btn-outline-secondary"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td colSpan={2}>
                  <div className="mt-2">
                    <UIPaginationTotalSelector
                      perPage={pagination.per_page}
                      changePerPage={(value) => {
                        props.updatePagination({
                          per_page: value,
                          first_load: false,
                        });
                      }}
                    />
                  </div>
                </td>

                <td colSpan={5}>
                  <span className="float-end mt-2">
                    {pagination.total > 0 ? (
                      <UIPagination
                        page={pagination.page}
                        perPage={pagination.per_page}
                        changePage={(page) => {
                          props.updatePagination({
                            page: page.selected + 1,
                            first_load: false,
                          });
                        }}
                        total={pagination.total}
                      />
                    ) : null}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default withUIPaginate(Qualifications);
