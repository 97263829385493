import React, { useState, useEffect, useRef } from "react";
import { useSetState } from "react-hanger";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { snakeCase, find } from "lodash";
import Select from "react-select";

import {
  UploadingInProgress,
  UIFormLabel,
  withUIPaginate,
  UIPaginationTotalSelector,
  UIPagination,
  UITableSortable,
  KeywordSearch,
  UIMultiSelect,
} from "components/ui";

import { setURLParams, getUrlParams } from "lib/utils";
import InvoiceRow from "./components/invoice-row";

const List = (props) => {
  const { pagination, company, endpoint } = props;
  const [invoices, setInvoices] = useState([]);

  function setEndpoint(a, b) {
    return endpoint === "payables" ? a : b;
  }

  const url = new URL(window.location);

  const statusOptions = [
    { value: "draft", label: "Draft" },
    { value: "open", label: "Open" },
    { value: "paid", label: "Paid" },
    { value: "partially_paid", label: "Partially Paid" },
    { value: "projected", label: "Projected" },
    { value: "submitted", label: "Submitted" },
    { value: "void", label: "Void" },
  ];

  const { state, setState } = useSetState({
    query: "",
    sort_column: getUrlParams(url, "sort_column") || "due_date",
    sort_direction: getUrlParams(url, "sort_direction") || "DESC",
    status: Array.isArray(getUrlParams(url, "status"))
      ? getUrlParams(url, "status").map((status) =>
          find(statusOptions, { value: status })
        )
      : [],
  });

  const isFirstRun = useRef(true);

  const onSortChange = (sort_direction, sort_column) => {
    setState({
      sort_direction,
      sort_column,
    });
  };

  function fetchInvoices(params = {}) {
    props
      .getInvoices({
        ...params,
        statuses: state.status.map((option) => option.value),
        company_id: company.id,
      })
      .then((data) => {
        props.updatePagination({
          total: data.total,
          first_load: true,
        });

        setInvoices(data.invoices);
      });
  }

  useEffect(() => {
    fetchInvoices({ ...state, ...pagination });
  }, []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    fetchInvoices({ ...state, ...pagination });
  }, [state]);

  useEffect(() => {
    if (pagination.first_load) return;
    fetchInvoices({ ...state, ...pagination });
  }, [pagination]);

  return (
    <div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <KeywordSearch
            state={state}
            setState={setState}
            updatePagination={props.updatePagination}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex">
          <div className="me-3">
            <Select
              isMulti
              options={statusOptions}
              value={state.status}
              isClearable
              className="ui-multi-filter"
              placeholder="Filter by status"
              onChange={(selected) => setState({ status: selected })}
            />
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <a
            className="btn-attach float-end"
            href={setEndpoint("#/payables/new", "#/receivables/new")}
          >
            + Create Invoice
          </a>
        </div>
      </div>

      <table className="settings-table align-middle mt-3">
        <thead>
          <tr>
            <th>
              <UITableSortable
                title="Account"
                sortColumn={setEndpoint(
                  "expense_account",
                  "company_accounts_chart_name"
                )}
                sortDirection={state.sort_direction}
                currentSortColumn={state.sort_column}
                onSortChange={onSortChange}
              />
            </th>
            <th>
              <UITableSortable
                title={setEndpoint("Vendor", "Customer")}
                sortColumn={setEndpoint("vendor_name", "customer_name")}
                sortDirection={state.sort_direction}
                currentSortColumn={state.sort_column}
                onSortChange={onSortChange}
              />
            </th>
            <OverlayTrigger
              overlay={
                <Tooltip className="cashflow-tooltip">
                  %-Allocated refers to whether or not a transaction is tied to
                  a specific project or not. This allocations exists only within
                  the Subcity product.
                </Tooltip>
              }
            >
              <th style={{ whiteSpace: "nowrap" }}>% Allocated</th>
            </OverlayTrigger>
            <th>
              <UITableSortable
                title="PO"
                sortColumn="purchase_order_number"
                sortDirection={state.sort_direction}
                currentSortColumn={state.sort_column}
                onSortChange={onSortChange}
              />
            </th>
            <th>
              <UITableSortable
                title="Invoice"
                sortColumn="invoice_number"
                sortDirection={state.sort_direction}
                currentSortColumn={state.sort_column}
                onSortChange={onSortChange}
              />
            </th>
            <th>
              <UITableSortable
                title="Status"
                sortColumn="status"
                sortDirection={state.sort_direction}
                currentSortColumn={state.sort_column}
                onSortChange={onSortChange}
              />
            </th>
            <th>
              <UITableSortable
                title="Amount"
                sortColumn="total_amount"
                sortDirection={state.sort_direction}
                currentSortColumn={state.sort_column}
                onSortChange={onSortChange}
              />
            </th>
            <th>
              <UITableSortable
                title="Balance"
                sortColumn="amount_due"
                sortDirection={state.sort_direction}
                currentSortColumn={state.sort_column}
                onSortChange={onSortChange}
              />
            </th>
            <th>
              <UITableSortable
                title="Issued"
                sortColumn="issue_date"
                sortDirection={state.sort_direction}
                currentSortColumn={state.sort_column}
                onSortChange={onSortChange}
              />
            </th>
            <th>
              <UITableSortable
                title="Due"
                sortColumn="due_date"
                sortDirection={state.sort_direction}
                currentSortColumn={state.sort_column}
                onSortChange={onSortChange}
              />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, index) => {
            return (
              <InvoiceRow
                key={`company-invoice-${index}`}
                endpoint={endpoint}
                {...invoice}
              />
            );
          })}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan={2}>
              <div className="mt-2">
                {pagination.total > 0 ? (
                  <UIPaginationTotalSelector
                    perPage={pagination.per_page}
                    changePerPage={(value) => {
                      props.updatePagination({
                        per_page: value,
                        first_load: false,
                      });
                    }}
                  />
                ) : null}
              </div>
            </td>

            <td colSpan={10}>
              <span className="float-end mt-2">
                {pagination.total > 0 ? (
                  <UIPagination
                    page={pagination.page}
                    perPage={pagination.per_page}
                    changePage={(page) => {
                      props.updatePagination({
                        page: page.selected + 1,
                        first_load: false,
                      });
                    }}
                    total={pagination.total}
                  />
                ) : null}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default withUIPaginate(List);
