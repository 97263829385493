import React, { useEffect, useState } from "react";
import Select from "react-select";

import { UIFormLabel } from "components/ui";
import { prettyDisplayPrice } from "lib/string";

const Status = {
  draft: "draft",
  review: "review",
  applied: "applied",
  collected: "collected",
};

import { PaginatedTable } from "components/ui";

const currencyFormatter = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const Applications = ({ applications, companies, companyId }) => {
  const [companyDropdownOptions, setCompanyDropdownOptions] = useState([]);
  useEffect(() => {
    setCompanyDropdownOptions(
      companies.map((company, index) => {
        return {
          value: company.id,
          label: company.name,
        };
      })
    );
  }, [companies]);

  const [currentCompanyId, setCurrentCompanyId] = useState(companyId);

  const [currentApplications, setCurrentApplications] = useState([]);
  useEffect(() => {
    setCurrentApplications(
      applications.filter((application) => {
        return application.company_id == currentCompanyId;
      })
    );
  }, [currentCompanyId]);

  const mapApplicationsToTableRows = (row, index) => {
    return (
      <>
        <td className="w-100 text-start">
          <h6 className="ms-1 mb-0">{row.name}</h6>
        </td>
        <td>{row.draft}</td>
        <td>{row.review}</td>
        <td>{row.applied}</td>
        <td>{row.collected}</td>
        <td>{prettyDisplayPrice(row.total)}</td>
        <td>
          <a
            href={
              row.company_id == companyId
                ? `/applications/${row.slug}#/apply`
                : `/applications/${row.slug}/${row.company_id}#/apply`
            }
            className="btn btn-sm btn-dark ms-2"
          >
            View
          </a>
        </td>
      </>
    );
  };

  const getCompaniesDropdown = (currentCompanyId, companyDropdownOptions) => {
    if (companyDropdownOptions.length < 2) {
      return null;
    } else {
      return (
        <div className="row mb-4 mt-4 ml-4 mr-4">
          <UIFormLabel label="Company" />
          <Select
            value={companyDropdownOptions.find((companyDropdownOption) => {
              return companyDropdownOption.value == currentCompanyId;
            })}
            options={companyDropdownOptions}
            onChange={(selectedOption) => {
              setCurrentCompanyId(selectedOption.value);
            }}
          />
        </div>
      );
    }
  };

  const getApplicationsTable = (currentApplications) => {
    if (currentApplications.length == 0) {
      return (
        <h5 className="text-center m-3">You have no open applications.</h5>
      );
    } else {
      return (
        <div className="panel">
          <div className="admin-table mt-3 pt-2">
            <PaginatedTable
              allItems={currentApplications}
              headers={[
                "Name",
                "Draft",
                "Review",
                "Applied",
                "Collected",
                "Value",
                "",
              ]}
              defaultNoItemsText={"No open applications."}
              mapItemsToTableRows={mapApplicationsToTableRows}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {getCompaniesDropdown(currentCompanyId, companyDropdownOptions)}
      {getApplicationsTable(currentApplications)}
    </div>
  );
};

export default Applications;
