import React from "react";
import IncentiveUrls from "./incentive-urls";
import Sponsors from "../../common/sponsors";

const IncentiveDetails = ({ incentive }) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-8 offset-md-2 mt-5">
          <h4 className="light-title mb-3" style={{ color: "#92D8FF" }}>
            <strong>{incentive.name}</strong>
          </h4>
          <div className="incentive-details-section">
            <div className="row mb-2">
              <p className="details-description">{incentive.description}</p>
            </div>
          </div>

          <div className="qualification-section mt-4">
            <div className="row mb-4">
              <div className="col-md-6">
                <h4>
                  <strong>Qualification Criteria</strong>
                </h4>
                <p>{incentive.eligibility_description}</p>
                <ul className="qualifications-list">
                  {incentive.qualifications &&
                    incentive.qualifications.map((qualification, index) => (
                      <li key={index}>
                        <i className="bi bi-check-circle-fill"></i>
                        {qualification.name}
                      </li>
                    ))}
                </ul>
              </div>
              {incentive.economics && (
                <div className="col-md-6">
                  <h4>
                    <strong>Economics</strong>
                  </h4>
                  <p>{incentive.economics}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row section-divider">
        <div className="col-md-8 offset-md-2">
          <div className="row mb-4">
            <div className="col-md-6">
              <h4>
                <strong>Sponsors</strong>
              </h4>
              <div className="mb-2">
                <Sponsors incentive={incentive} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {incentive.incentive_urls.length > 0 && (
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="mt-3">
              <h4>
                <strong>Resources</strong>
              </h4>
              <IncentiveUrls
                key={incentive.id}
                incentiveId={incentive.id}
                urls={incentive.incentive_urls}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IncentiveDetails;
