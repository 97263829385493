import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { flashError, flashSuccessLater } from "lib/flash";
import { UILabelInput, UIMoneyLabelInput } from "components/ui";
import { createCompanyFinancialAccount } from "modules/company_financial_accounts";


const AddBankAccount = () => {
  const [ showForm, setShowForm ] = useState(false);
  const [ newAccount, setNewAccount ] = useState({});

  function updateBankName(value) {
    setNewAccount({...newAccount, bankName: value});
  };

  function updateAccountName(value) {
    setNewAccount({...newAccount, accountName: value});
  };

  function updateAccountNumber(value) {
    setNewAccount({...newAccount, accountNumber: value});
  };

  function updateRoutingNumber(value) {
    setNewAccount({...newAccount, routingNumber: value});
  };

  function updateAvailableBalance(value) {
    setNewAccount({...newAccount, availableBalance: value});
  };

  function updateCurrentBalance(value) {
    setNewAccount({...newAccount, currentBalance: value});
  };

  return (
    <>
      <button
        className="btn btn-secondary"
        style={{ width: "350px" }}
        onClick={
          (e) => {
            e.preventDefault();
            setShowForm(true);
          }
        }
      >
        Manually Add Bank Account
      </button>
      { showForm &&
        <Modal 
          centered 
          show={showForm} 
          onHide={() => setShowForm(false)}
          dialogClassName="modal-xl"
          >
          <Modal.Body className="p-3">
            <h3 className="panel-title">Manually Add Bank Account</h3>
            <div className="row  p-3 mt-1">
              <div className="col" key="account-name">
                <div className="row mb-4">
                  <UILabelInput
                    label="Bank Name"
                    value={newAccount.bankName}
                    onChange={updateBankName}
                    required
                  />
                </div>
              </div>
              <div className="col">
                <div></div>
              </div>
            </div>
            <div className="row  p-3 mt-1">
              <div className="col" key="account-name">
                <div className="row mb-4">
                  <UILabelInput
                    label="Account Name"
                    value={newAccount.accountName}
                    onChange={updateAccountName}
                    required
                  />
                </div>
              </div>
              <div className="col">
                <div></div>
              </div>
            </div>
            <div className="row  p-3 mt-1 mb-3 z-depth-2">
              <div className="col" key="account-details-numbers">
                <div className="row mb-4">
                  <UILabelInput
                    label="Account Number"
                    value={newAccount.accountNumber}
                    onChange={updateAccountNumber}
                  />
                </div>
                <div className="row mb-4">
                  <UILabelInput
                    label="Routing Number"
                    value={newAccount.routingNumber}
                    onChange={updateRoutingNumber}
                  />
                </div>
              </div>
              <div className="col" key="account-details-balances">
                <div className="col md-6"><div></div></div>
                <div className="row mb-4">
                  <UIMoneyLabelInput
                    label="Available Balance"
                    defaultValue={newAccount.availableBalance}
                    onValueChange={updateAvailableBalance}
                  />
                </div>
                <div className="row mb-4">
                  <UIMoneyLabelInput
                    label="Current Balance"
                    defaultValue={newAccount.currentBalance}
                    onValueChange={updateCurrentBalance}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="btn-group float-end">
                  <button
                    className="btn btn-light"
                    style={{width: "100px"}}
                    onClick={() => setShowForm(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    style={{width: "100px"}}
                    onClick={
                      (e) => {
                        e.preventDefault();

                        if(!newAccount.bankName) {
                          flashError("Must provide Bank Name!");
                          return;
                        } else if(!newAccount.accountName) {
                          flashError("Must provide Account Name!");
                          return;
                        } else if(!newAccount.currentBalance) {
                          flashError("Must provide Current Balance!");
                          return;
                        }

                        let params = {
                          "account_details": {
                            "account_number": newAccount.accountNumber,
                            "routing_number": newAccount.routingNumber,
                            "institution_name": newAccount.bankName,
                            "name": newAccount.accountName,
                            // "subtype": "" // TODO: add radio for checking/savings
                          },
                          "balances": {
                            "available": newAccount.availableBalance,
                            "current": newAccount.currentBalance
                          },
                          "account_type": "depository" // TODO: update once support other types manually
                        };

                        createCompanyFinancialAccount(params)
                          .then(() => {
                            location.reload(true);
                            flashSuccessLater("Successfully added bank!"); }
                          ).catch((error) => {
                            flashError("Failed to add bank!");
                          });
                      }
                    }
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  );
};

export default AddBankAccount;
