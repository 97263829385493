import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom";

import List from './list'
import Edit from './edit'
import New from './new'

const Invoices = (props) => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<List {...props} />} />
        <Route exact path="/new" element={<New {...props} />} />
        <Route path=":id" element={<Edit {...props} />} />
      </Routes>
    </div>
  )
}

export default Invoices;
