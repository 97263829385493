import React, { useState } from "react";
import FAQEntryEdit from "./faq-entry-edit";

const FAQNewEntry = () => {
  const [formVisible, setFormVisible] = useState(false);

  return (
    <div className="d-block mb-3">
      {!formVisible && (
        <div className="clearfix">
          <button
            className="float-end btn-attach"
            onClick={() => {
              setFormVisible(true);
            }}
          >
            Add FAQ
          </button>
        </div>
      )}

      {formVisible && (
        <FAQEntryEdit
          onDoneEditing={() => {
            setFormVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default FAQNewEntry;
