import React from "react";

import NewPurchase from "./components/new-purchase";
import ListPurchase from "./components/list-purchase";

import Header from "../../common/header";

const Apply = (props) => {
  const { incentive, totalEligible, totalClaimed } = props;
  return (
    <>
      <Header
        name={incentive.name}
        eligible={totalEligible}
        claimed={totalClaimed}
      />
      <div className="panel mt-3">
        <div className="col-md-8 p-4">
          <h2 className="mt-0 font-weight-boldest">Your Purchases</h2>

          <p className="my-3">
            Filling and checking the data below helps Subcity determine which
            items are eligible for this exemption. When you’ve clicked Claim,
            we’ll generate the documents required. You’ll then receive checks
            from vendors!
          </p>

          <a href={`/incentives/${incentive.slug}`}>
            Read more about California’s partial exemption from sales and use
            tax for manufacturers.
          </a>
        </div>
        <div className="admin-table">
          <NewPurchase
            user={props.currentUser}
            constraints={props.equipmentConstraints}
          />
          <ListPurchase
            incentive={incentive}
            purchaser={props.purchaser}
            equipmentConstraints={props.equipmentConstraints}
            currentUser={props.currentUser}
            companyPurchases={props.companyEquipments}
          />
        </div>
      </div>
    </>
  );
};

export default Apply;
