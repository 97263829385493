import React, { useState } from 'react';
import Loading from 'components/common/loading'
import { Overlay, Popover, PopoverHeader, PopoverBody } from 'react-bootstrap';

// import {
//   fetchPayablePurchases
// } from 'modules/'

const AddLineItemPopover = ({ target, show, setShow }) => {
  function handleCancel(e) {
    e.preventDefault()
    setShow(false)
  }

  function renderLoading() {
    return (
      <Loading height={100} />
    )
  }

  function renderForm() {
    return (
      <form>
      </form>
    )
  }

  return (
    <Overlay target={target.current} show={show} placement="bottom-start" rootClose>
      {({ style, outOfBoundaries, scheduleUpdate, ...props }) => (
        <Popover id='popover-positioned-bottom' {...props} style={{
          ...style,
          minWidth: '300px'
        }}>
          <PopoverHeader as="h3">Search Invoices</PopoverHeader>
          <PopoverBody>
            { isCreating ? renderLoading() : renderForm() }
          </PopoverBody>
        </Popover>
      )}
    </Overlay>
  )
}

export default AddLineItemPopover;
