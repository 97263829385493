import React, { useState } from "react";
import EditEligibilityField from "./edit-eligibility-field";
import EditQualificationField from "./edit-qualification-field";
import { Spinner } from "react-bootstrap";

const PreQualify = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bannerMessage, setBannerMessage] = useState(props.bannerMessage);
  const [bannerState, setBannerState] = useState(props.bannerState);
  const [bannerSubtext, setBannerSubtext] = useState(props.bannerSubtext);

  function renderBanner() {
    if (!bannerMessage) return;

    let textClassName = "";
    textClassName += "text-dark fw-bolder fst-italic";

    return (
      <div className={`panel p-3 bg-light panel-${bannerState}`}>
        <h4 className={textClassName}>
          {isLoading ? <Spinner animation="border" size="sm" /> : null}{" "}
          {bannerMessage}
        </h4>
        <p
          className="fw-semibold mb-0"
          dangerouslySetInnerHTML={{ __html: bannerSubtext }}
        ></p>
      </div>
    );
  }

  const updateBanner = (newBannerMessage, newBannerState, newBannerSubtext) => {
    setBannerMessage(newBannerMessage);
    setBannerState(newBannerState);
    setBannerSubtext(newBannerSubtext);
  };

  return (
    <>
      {renderBanner()}
      <div className="panel p-4">
        <h3>
          Pre-Qualify:{" "}
          <span style={{ color: "#6A7894" }}>{props.incentive.name}</span>
        </h3>
        <p className="mb-3">
          Please fill out any pre-qualifiying questions before continuing to the
          rest of the application. These will help you see if your company
          qualifies for the program or not.
        </p>
        {props.incentive.eligibility_description &&
          props.incentive.eligibility_description.trim() !== "" && (
            <div className="light-call-out-banner mb-6">
              <p>
                <strong>{props.incentive.eligibility_description}</strong>
              </p>
            </div>
          )}
        {props.eligibility.map((eligibility, index) => {
          const key = `edit-eligibility-${eligibility.table}-${eligibility.column}-${index}`;
          return (
            <>
              <EditEligibilityField
                {...props}
                eligibility={eligibility}
                key={key}
              />
            </>
          );
        })}

        {props.qualifications.map((qualification) => {
          return (
            <EditQualificationField
              {...qualification}
              incentive={props.incentive}
              key={`edit-qualification-${qualification.id}`}
              updateBanner={updateBanner}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          );
        })}
      </div>
    </>
  );
};

export default PreQualify;
