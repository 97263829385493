import axios from "lib/axios";

export const createCompany = (params = {}) => {
  const url = `/company`;

  return axios
    .post(url, { company: params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateCompany = (params = {}) => {
  const url = '/company';

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const searchCompanyDropdown = (params = {}) => {
  const url = '/company/search';

  return axios
    .get(url, {
      params: params,
    }).then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const deleteCompanyAddress = (params = {}) => {
  const url = `/company_addresses/${params.address.id}/`;

  return axios
    .delete(url, { data: params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const createCompanyAddress = (params = {}) => {
  const url = `/company_addresses/`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateCompanyAddress = (params = {}) => {
  const url = `/company_addresses/${params.address.id}/`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const removeUserCompany = (params = {}) => {
  const url = `/user_companies/${params.user_company.id}/`;

  return axios
    .delete(url, { data: params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const addUserCompany = (params = {}) => {
  const url = `/user_companies/`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateUserCompany = (params = {}) => {
  const url = `/user_companies/${params.user_company.id}/`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchUsers = (params = {}) => {
  const url = `/company/users`

  return axios.get(url, {
    params: params
  })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchNaicsCode = (params = {}) => {
  const url = `/company/naics_codes`;

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const loadNaicsCodeOptions = (value, cb) => {
  fetchNaicsCode({ query: value }).then((res) => {
    cb(
      res.map((row) => ({
        value: row.id,
        label: `${row.code} (${row.title})`,
      }))
    );
  });
};

export const uploadCompanyFileResource = (params = {}, options) => {
  const url = `/company/upload_file_resource`;
  let formData = new FormData();

  for ( var key in params ) {
    if(key === 'company') {
      for (let fileKey in params.company) {
        formData.append(`company[${fileKey}]`, params.company[fileKey]);
      }
    }
    else {
      formData.append(`[${key}]`, params[key]);
    }
  }

  return axios
    .post(url, formData, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const deleteCompanyFileResource = (params = {}) => {
  const url = `/company/destroy_file_resource`;

  return axios
    .delete(url, { data: params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const searchCompanyFileResource = (params = {}) => {
  const url = `/company/search_file_resource`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateUserCompanyNewRole = (params = {}) => {
  const url = `/user_companies/${params.user_company.id}/update_role`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
