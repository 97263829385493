import React, { Component, PropTypes, useState, useEffect } from "react";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { useSetState } from "react-hanger";
import Loading from "components/common/loading";
import CashPartnerInvoiceView from "./cash-partner-invoice-view";

import moment from "moment";

import {
  KeywordSearch,
  withUIPaginate,
  UIPaginationFooter,
} from "components/ui";
import EditInvoice from "./edit-invoice";

import { flashError } from "lib/flash";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    className="btn btn-sm btn-muted float-end"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const InvoiceListRollup = ({
  items: externalItems,
  sortColumn,
  onSortChange,
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState(externalItems || []);
  const { pagination } = props;
  const { state, setState } = useSetState({
    query: "",
  });

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    props.updatePagination({ per_page: 500 });
    fetchItems(true);
  }, []);

  useEffect(() => {
    if (pagination.first_load) return;
    fetchItems(true);
  }, [pagination]);

  useEffect(() => {
    fetchItems(true);
  }, [state]);

  useEffect(() => {
    fetchItems();
  }, [sortColumn, props.sortDirection]);

  useEffect(() => {
    // console.log("editableInvoices:", props.invoices);

    if (externalItems) {
      setItems(externalItems);
    }
  }, [externalItems]);

  function renderHeader() {
    return (
      <div>
        <div
          className="col d-flex align-items-center justify-content-between mb-3"
          style={{
            borderTop: "1px solid #ccc",
            padding: "10px",
          }}
        >
          <div className="d-flex align-items-center">
            <button
              className="btn-custom-gridview me-2"
              onClick={handleOpenModal}
            >
              <i className="bi bi-grid-3x3-gap btn-custom-gridview-icon"></i>{" "}
              Grid View
            </button>
          </div>
          <div className="d-flex align-items-center">
            {props.actionButton}
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-toggle">
                <i className="bi bi-gear fs-5"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>{props.dropdownMenu}</Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <Modal show={modalOpen} onHide={handleCloseModal} fullscreen>
          <Modal.Header closeButton>
            <Modal.Title>Cash Flow Forecast AP/AR View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CashPartnerInvoiceView invoices={props.invoices} />
          </Modal.Body>
        </Modal>

        <div className="row">
          <div className="col-md-12 mt-1">
            <h5 className="mb-0">{props.title}</h5>
            <div className="d-flex justify-content-between align-items-center mb-1">
              <p className="finance-context">
                Your projected cash flow, combines imported A/R and A/P with
                recurring payments and individual forecasts per account.
              </p>
              <DropdownButton
                id="dropdown-basic-button"
                title="Sort by"
                className="sort-button"
                variant="light"
                menuVariant="dark"
                size="sm"
              >
                <Dropdown.Item onClick={() => onSortChange("ASC", "due_date")}>
                  Due Date
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => onSortChange("ASC", "vendor_name")}
                >
                  Vendor/Customer
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => onSortChange("DESC", "total_amount")}
                >
                  Amount
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderBody() {
    if (items.length === 0 && !props.codat_connected) {
      // User hasn't connected accounting software
      return (
        <>
          <div className="py-3 px-4">
            <p>
              Please connect your{" "}
              <a href="/company/edit#/integrations">accounting software</a> to
              see a list of invoices.
            </p>

            <a
              href="/company/edit#/integrations"
              className="btn btn-primary mb-0"
            >
              Connect
            </a>
          </div>
        </>
      );
    } else if (items.length === 0 && !props.first_accounts_processing_done) {
      // User has connected accounting software but invoices haven't yet been processed.
      return (
        <div className="text-center">
          <i
            className="bi bi-arrow-repeat"
            style={{
              color: "#00A2E9",
              fontSize: "40px",
            }}
          ></i>
          <h4>Syncing and processing data...</h4>
          <p>
            Please wait. This may take a few hours.
            <br />
            We'll email you when your data is synchronized and processed.
          </p>
        </div>
      );
    } else if (items.length === 0) {
      // User has connected accounting software, the data has all been processed, but there are no invoices
      return (
        <div className="text-center">
          <i
            className="bi bi-arrow-repeat"
            style={{
              color: "#00A2E9",
              fontSize: "40px",
            }}
          ></i>
          <h4>No upcoming invoices</h4>
          <p>
            Add new invoices or take a look at your old ones{" "}
            <a href="/invoices">here</a>.
          </p>
        </div>
      );
    } else {
      // User has connected accounting software, data processed, and invoices listed!
      return (
        <table className="table mb-0">
          <thead className="table-light">{props.thead}</thead>
          <tbody>
            {items.map(props.renderItem)}
            {!props.displayAllWeeks ? (
              <tr
                onClick={() => props.setDisplayAllWeeks(true)}
                className="chevron-row"
              >
                <td colSpan={14} className="admin-metadata">
                  <i
                    className="bi bi-chevron-down"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Show 9 more weeks
                </td>
              </tr>
            ) : (
              <tr
                onClick={() => props.setDisplayAllWeeks(false)}
                className="chevron-row"
              >
                <td colSpan={14} className="admin-metadata">
                  <i
                    className="bi bi-chevron-up"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Hide 9 weeks
                </td>
              </tr>
            )}
          </tbody>
          {/* <UIPaginationFooter
              pagination={pagination}
              updatePagination={props.updatePagination}
              colSpan={5}
            /> */}
        </table>
      );
    }
  }

  function fetchItems(updateDashboard = false, updateCashProjections = false) {
    const params = {
      ...pagination,
      ...state,
      min_due_date: props.minDate,
      max_due_date: props.maxDate,
      sort_column: sortColumn,
      sort_direction: props.sortDirection,
    };

    props
      .fetchItems(params, updateDashboard, updateCashProjections)
      .then((data) => {
        setIsLoaded(true);

        if (!data.success) {
          return flashError(data.message);
        }

        props.updatePagination({
          total: data.total,
          first_load: true,
        });
        console.log("Data", data.items);
        setItems(data.items);
      });
  }

  if (!isLoaded) {
    return (
      <div className="panel invoice-list p-4">
        <Loading height={113} />
      </div>
    );
  }

  return (
    <div>
      {renderHeader()}
      <div className="panel invoice-list py-0">
        {renderBody()}
        <EditInvoice
          invoices={props.editableInvoices}
          show={props.showModal}
          setShow={props.setShowModal}
          onSuccess={fetchItems.bind(null, true, true)}
        />
      </div>
    </div>
  );
};

export default withUIPaginate(InvoiceListRollup);
