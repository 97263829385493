import React, { useEffect, useState } from 'react';
import Loading from '../loading'

const TxtPreview = (props) => {
  const [ isLoading, setIsLoading ] = useState(true)
  const [ text, setText ] = useState('')

  useEffect(() => {
    fetch(props.url).then((response)=>{
      response.text()
        .then((data) => {
          setIsLoading(false)
          setText(data)
        })
    })
  }, [])

  return (
    <div className='txt-wrapper'>
      { isLoading ? <Loading /> : null } 
      
      <div style={{'white-space': 'pre-wrap'}}>
        {text}
      </div>
    </div>
  )
}

export default TxtPreview;
