import React from "react";
import { Modal } from "react-bootstrap";
import ShowIncentive from "../show/index";

const IncentiveModal = ({ incentive, isOpen, onClose, user_signed_in }) => {
  //TODO: Combine this with ShowIncentive
  return (
    <Modal size="xl" show={isOpen} onHide={onClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {incentive && (
          <ShowIncentive
            incentive={incentive}
            is_eligible={incentive.is_eligible}
            is_qualified={incentive.is_qualified}
            user_signed_in={user_signed_in}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default IncentiveModal;
