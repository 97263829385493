import React, { useState, useEffect } from "react";
import {
  getCompanyProjectQuote,
  updateCompanyProjectQuote,
} from "modules/project";
import moment from "moment";
import {
  UILabelInput,
  UIAddressInputWithSuggestions,
  UIFormLabel,
} from "components/ui";

const AddressDisplay = ({
  address,
  title,
  editMode,
  onAddressChange,
  handleEditClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleSuggestedAddress = (parsedAddress) => {
    onAddressChange({
      ...address,
      address_line_1: parsedAddress.address_line_1,
      address_line_2: parsedAddress.address_line_2,
      city: parsedAddress.city,
      state: parsedAddress.state,
      postal_code: parsedAddress.postal_code,
    });
  };

  return (
    <div
      className="address-column"
      style={{ position: "relative" }} // Adding relative positioning
    >
      <p>
        <strong>{title}:</strong>
      </p>
      {editMode ? (
        <>
          <UILabelInput
            label="Description"
            value={address?.description || ""}
            required={false}
            className="address-column"
            style={{ position: "relative" }}
            onChange={(value) => onAddressChange("description", value)}
          />
          <UIFormLabel label="Address Line 1" />
          <UIAddressInputWithSuggestions
            value={address?.address_line_1}
            className="address-column"
            required={false}
            onChange={(value) => onAddressChange("address_line_1", value)}
            onSelectSuggestedAddress={handleSuggestedAddress}
          />

          <UILabelInput
            label="Address Line 2"
            value={address?.address_line_2 || ""}
            required={false}
            className="address-column"
            style={{ position: "relative" }}
            onChange={(value) => onAddressChange("address_line_2", value)}
          />
          <UILabelInput
            label="City"
            value={address?.city || ""}
            required={false}
            className="address-column"
            style={{ position: "relative" }}
            onChange={(value) => onAddressChange("city", value)}
          />
          <UILabelInput
            label="State"
            value={address?.state || ""}
            required={false}
            className="address-column"
            style={{ position: "relative" }}
            onChange={(value) => onAddressChange("state", value)}
          />
          <UILabelInput
            label="Postal Code"
            value={address?.postal_code || ""}
            required={false}
            className="address-column"
            style={{ position: "relative" }}
            onChange={(value) => onAddressChange("postal_code", value)}
          />
        </>
      ) : (
        <>
          {address?.description && (
            <p className="mb-0">{address?.description}</p>
          )}
          {address?.address_line_1 && (
            <p className="mb-0">{address.address_line_1}</p>
          )}
          {address?.address_line_2 && (
            <p className="mb-0">{address.address_line_2}</p>
          )}
          {(address?.city || address?.state || address?.postal_code) && (
            <p className="mb-2">
              {address?.city}, {address?.state} {address?.postal_code}
            </p>
          )}
        </>
      )}
    </div>
  );
};

const ProjectQuoteHeader = (props) => {
  const [companyProjectQuote, setCompanyProjectQuote] = useState(null);
  const [tempCompanyProjectQuote, setTempCompanyProjectQuote] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleAddressChange = (type, field, value) => {
    setTempCompanyProjectQuote((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [field]: value,
      },
    }));
  };

  useEffect(() => {
    getCompanyProjectQuote(props.company_project_id).then((data) => {
      if (data) {
        setCompanyProjectQuote(data);
      }
    });
  }, []);

  useEffect(() => {
    setTempCompanyProjectQuote({ ...companyProjectQuote });
  }, [companyProjectQuote]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    setEditMode(false);
    updateCompanyProjectQuote(
      props.company_project_id,
      tempCompanyProjectQuote
    ).then((data) => {
      if (data) {
        setCompanyProjectQuote(data);
      }
    });
  };

  const handleCancel = () => {
    setTempCompanyProjectQuote({ ...companyProjectQuote });
    setEditMode(false);
  };

  const primaryColor =
    companyProjectQuote?.company_project_quote_parameter?.primary_color ||
    "#1D3360";
  const accentColor =
    companyProjectQuote?.company_project_quote_parameter?.accent_color ||
    "#E22947";

  return (
    <div className="quote-container" style={{ color: primaryColor }}>
      <div style={{ position: "relative", width: "100%" }}>
        {companyProjectQuote && (
          <>
            <div className="info-row">
              <p className="mb-1">
                <strong>Created Date:</strong>{" "}
                {moment(companyProjectQuote.created_at).format("MM/DD/YYYY")}
              </p>
              <div>
                {editMode ? (
                  <input
                    className="inline-edit"
                    value={tempCompanyProjectQuote.payment_terms || ""}
                    placeholder="Edit project payment terms"
                    onChange={(e) =>
                      setTempCompanyProjectQuote({
                        ...tempCompanyProjectQuote,
                        payment_terms: e.target.value,
                      })
                    }
                  />
                ) : (
                  <>
                    {" "}
                    <p className="mb-1">
                      <strong>Payment Terms:</strong>{" "}
                      {companyProjectQuote.payment_terms ||
                        companyProjectQuote.company_project_quote_parameter
                          ?.payment_terms}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div
              className="description-box"
              style={{ backgroundColor: accentColor }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div style={{ position: "relative" }}>
                {editMode ? (
                  <div className="float-end">
                    <button
                      className="btn btn-sm btn-primary me-2"
                      onClick={handleSave}
                    >
                      <i className="bi bi-check"></i>
                    </button>
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={handleCancel}
                    >
                      <i className="bi bi-x"></i>
                    </button>
                  </div>
                ) : null}
                {!editMode && isHovered && (
                  <div>
                    <button
                      className="btn btn-muted"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                      onClick={handleEditClick}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </button>
                  </div>
                )}
              </div>
              {editMode ? (
                <input
                  className="inline-edit"
                  value={tempCompanyProjectQuote.project_description || ""}
                  placeholder="Edit project description"
                  onChange={(e) =>
                    setTempCompanyProjectQuote({
                      ...tempCompanyProjectQuote,
                      project_description: e.target.value,
                    })
                  }
                />
              ) : (
                <>DESCRIPTION: {companyProjectQuote.project_description}</>
              )}
            </div>
            <div className="address-row">
              <div className="address-column">
                <AddressDisplay
                  title="Bill To"
                  address={tempCompanyProjectQuote.bill_to_address}
                  editMode={editMode}
                  onAddressChange={(field, value) =>
                    handleAddressChange("bill_to_address", field, value)
                  }
                  handleEditClick={handleEditClick}
                />
                {editMode ? (
                  <>
                    <label htmlFor="requestedByNameInput">Requested by:</label>
                    <input
                      id="requestedByNameInput"
                      className="inline-edit"
                      value={tempCompanyProjectQuote.requested_by.name || ""}
                      placeholder="Requested By Name"
                      onChange={(e) =>
                        setTempCompanyProjectQuote((prev) => ({
                          ...prev,
                          requested_by: {
                            ...prev.requested_by,
                            name: e.target.value,
                          },
                        }))
                      }
                    />

                    <label htmlFor="requestedByEmailInput">Email:</label>
                    <input
                      id="requestedByEmailInput"
                      className="inline-edit"
                      value={tempCompanyProjectQuote.requested_by.email || ""}
                      placeholder="Requested By Email"
                      onChange={(e) =>
                        setTempCompanyProjectQuote((prev) => ({
                          ...prev,
                          requested_by: {
                            ...prev.requested_by,
                            email: e.target.value,
                          },
                        }))
                      }
                    />

                    <label htmlFor="requestedByPhoneInput">Phone:</label>
                    <input
                      id="requestedByPhoneInput"
                      className="inline-edit"
                      value={tempCompanyProjectQuote.requested_by.phone || ""}
                      placeholder="Requested By Phone"
                      onChange={(e) =>
                        setTempCompanyProjectQuote((prev) => ({
                          ...prev,
                          requested_by: {
                            ...prev.requested_by,
                            phone: e.target.value,
                          },
                        }))
                      }
                    />
                  </>
                ) : (
                  <p className="mb-0">
                    Requested By: {companyProjectQuote.requested_by.name}{" "}
                    {(companyProjectQuote.requested_by.email ||
                      companyProjectQuote.requested_by.phone) &&
                      "(" +
                        [
                          companyProjectQuote.requested_by.email,
                          companyProjectQuote.requested_by.phone,
                        ]
                          .filter(Boolean)
                          .join(" / ") + // I replaced the space separator with " / " for better clarity between email and phone, but you can revert it if you prefer.
                        ")"}
                  </p>
                )}
              </div>
              <div className="address-column">
                <AddressDisplay
                  title={tempCompanyProjectQuote.delivery_method}
                  address={tempCompanyProjectQuote.install_info_address}
                  editMode={editMode}
                  onAddressChange={(field, value) =>
                    handleAddressChange("install_info_address", field, value)
                  }
                  handleEditClick={handleEditClick}
                />
                {editMode ? (
                  <>
                    <label htmlFor="salespersonInput">Salesperson:</label>
                    <input
                      id="salespersonInput"
                      className="inline-edit"
                      value={tempCompanyProjectQuote.salesperson || ""}
                      placeholder="Salesperson"
                      onChange={(e) =>
                        setTempCompanyProjectQuote({
                          ...tempCompanyProjectQuote,
                          salesperson: e.target.value,
                        })
                      }
                    />
                  </>
                ) : (
                  <p className="mb-0">
                    Salesperson: {companyProjectQuote.salesperson}
                  </p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectQuoteHeader;
