import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { UIPaginationTotalSelector, UIPagination } from "components/ui";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

const generateEntityLink = (type, id) => {
  let pluralType;
  if (type === "Company") {
    pluralType = "companies";
  } else {
    pluralType = `${type.toLowerCase()}s`;
  }
  let url = `/admin/${pluralType}/${id}/edit`;
  return url;
};

const Referrals = ({ referrable, baseUrl }) => {
  const [referrals, setReferrals] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 25,
    total: 0,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (referrable && referrable.id) {
      fetchReferrals();
    } else {
      setError("Invalid referrable object");
    }
  }, [referrable, baseUrl, pagination.page, pagination.per_page]);

  const fetchReferrals = () => {
    axios
      .get(`${baseUrl}${referrable.id}/show_referrals`, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
        },
      })
      .then((response) => {
        if (Array.isArray(response.data.referrals)) {
          setReferrals(response.data.referrals);
          setPagination((prevPagination) => ({
            ...prevPagination,
            total: response.data.meta.total_count,
          }));
          setError(null);
        } else {
          setReferrals([]);
          setError("Unexpected data structure received from the server");
        }
      })
      .catch((error) => {
        console.error("There was an error fetching referrals:", error);
        setError("Failed to fetch referrals. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatePagination = (newPaginationData) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      ...newPaginationData,
    }));
  };

  const renderEntityName = (entity) => {
    switch (entity.type) {
      case "User":
        return `${entity.first_name} ${entity.last_name} (${entity.email})`;
      case "Company":
        return (
          <>
            {entity.name}{" "}
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className="detail-tooltip">
                  {entity.description}
                </Tooltip>
              }
            >
              {entity.is_active ? (
                <i
                  className="bi bi-check-circle"
                  style={{ color: "#00A2E9" }}
                ></i>
              ) : (
                <i className="bi bi-x-circle" style={{ color: "#E22947" }}></i>
              )}
            </OverlayTrigger>
          </>
        );
      case "Organization":
        return entity.name;
      default:
        return "Unknown";
    }
  };

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div>
      <h3>
        Referrals
        {loading && <Spinner animation="border" size="sm" className="ms-2" />}
      </h3>
      <table className="table admin-table mb-3 mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>ID</th>
            <th>Name</th>
            <th>Entity Type</th>
            <th>Qualified Incentives</th>
          </tr>
        </thead>
        <tbody>
          {referrals.map((entity, index) => (
            <tr key={entity.id}>
              <td>{(pagination.page - 1) * pagination.per_page + index + 1}</td>
              <td>
                <a href={generateEntityLink(entity.type, entity.id)}>
                  {entity.id}
                </a>
              </td>
              <td>{renderEntityName(entity)}</td>
              <td>{entity.type}</td>
              <td>
                {entity.type === "Company"
                  ? entity.qualified_incentives_count
                  : "N/A"}
              </td>
            </tr>
          ))}
          <tr className="pagination-row">
            <td colSpan={2}>
              <div className="mt-2 pagination-selector">
                <UIPaginationTotalSelector
                  perPage={pagination.per_page}
                  changePerPage={(value) => {
                    updatePagination({
                      per_page: value,
                      page: 1,
                    });
                  }}
                />
              </div>
            </td>
            <td colSpan={3}>
              <span className="float-end mt-2 pagination-controls">
                {pagination.total > 0 ? (
                  <UIPagination
                    page={pagination.page}
                    perPage={pagination.per_page}
                    changePage={(page) => {
                      updatePagination({
                        page: page.selected + 1,
                      });
                    }}
                    total={pagination.total}
                  />
                ) : null}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

Referrals.propTypes = {
  referrable: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default Referrals;
