import React, { useState } from "react";
import { prettyDisplayPercent, prettyDisplayPrice } from "lib/string";
import { renderStatus, renderPriority } from "modules/project";

const TableBody = ({ projects, company }) => {
  return (
    <>
      {projects.length === 0 ? (
        <div className="pt-4 px-4 text-center">
          <p>No projects found.</p>
        </div>
      ) : (
        <tbody>
          {projects.map((project, index) => {
            const {
              external_project_id,
              name,
              customer,
              status,
              project_manager,
              revenue,
              non_labor_costs,
              labor_costs,
              gross_margin,
              net_profit,
              id,
            } = project;

            return (
              <tr key={`project-${index}`} className="row-with-shadow">
                <td className="desktop-only">{external_project_id}</td>
                <td>{name}</td>
                <td className="desktop-only">{customer}</td>
                <td className="desktop-only">{renderStatus(status)}</td>
                <td className="desktop-only">{project_manager}</td>
                <td>{prettyDisplayPrice(revenue, true)}</td>
                <td>
                  {prettyDisplayPrice(
                    parseFloat(non_labor_costs) + parseFloat(labor_costs)
                  )}
                </td>
                <td>{prettyDisplayPercent(gross_margin, 0)}</td>
                <td className="desktop-only">
                  {prettyDisplayPercent(net_profit, 0)}
                </td>
                <td className="position-relative actions-column">
                  <div className="button-container desktop-only">
                    <a className="details-button" href={`/projects/${id}`}>
                      Details <span className="details-arrow">&gt;</span>
                    </a>
                  </div>
                  <div className="mobile-only dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bi bi-three-dots"></i>
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li>
                        <a className="dropdown-item" href={`/projects/${id}`}>
                          Details
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      )}
    </>
  );
};

export default TableBody;
