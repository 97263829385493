import React from "react";
import { Link, useResolvedPath, useMatch } from "react-router-dom";

const UINavHorizontalInternal = (props) => {
  const { tabs, style } = props;

  function renderDropdown(tabItem, index) {
    const { items } = tabItem;

    return (
      <li className="tabnav-item dropdown" key={`nav-tab-ui-${index}`}>
        <a
          className="nav-link dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
          data-bs-toggle="dropdown"
          href="#"
          role="button"
        >
          {tabItem.name}
        </a>

        <ul className="dropdown-menu">
          {items.map((item, jindex) => (
            <li key={`dropdown-tab-item-${jindex}-${index}`}>
              <Link className="dropdown-item" to={item.link}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </li>
    );
  }

  function renderNavItem(tabItem, index) {
    let navLinkClassName = "nav-link";
    let navItemClass = "tabnav-item";

    const resolved = useResolvedPath(tabItem.nestedLink || tabItem.link);
    const match = useMatch({ path: resolved.pathname });

    if (match) {
      navItemClass += " active";
      navLinkClassName += " active";
    }

    if (style === "pill") {
      navLinkClassName += " btn";
    }

    return (
      <li key={`nav-tab-ui-${index}`} className={navItemClass}>
        <Link className={navLinkClassName} to={tabItem.link}>
          {tabItem.name}
        </Link>
      </li>
    );
  }

  const tabElements = tabs.map((tabItem, index) =>
    tabItem.type === "dropdown"
      ? renderDropdown(tabItem, index)
      : renderNavItem(tabItem, index)
  );

  const navStyleClassName = style === "pill" ? "nav-pills" : "nav-tabs";

  return (
    <div className="mb-2">
      <ul className={`nav tabnav ${navStyleClassName}`}>{tabElements}</ul>
    </div>
  );
};

export default UINavHorizontalInternal;
