import React, { useState, useEffect } from "react";

import { getAvailableIntegrations, disconnectIntegration } from "modules/integrations";
import { flashError, flashSuccess } from "lib/flash";

const TimeIntegrations = ({ company }) => {
  const [authenticated, setAuthenticated] = useState(
    company.integrated_time_software
  );
  const [integrations, setIntegrations] = useState([]);

  useEffect(() => {
    setAuthenticated(company.integrated_time_software);
  }, [company]);

  useEffect(() => {
    getAvailableIntegrations({ integration_type: "time" })
      .then((integrations) => {
        setIntegrations(integrations);
      })
  }, []);

  const onDisconnect = () => {
    let slug = "";

    if (company.integrated_time_via == "Gusto") {
      slug = "gusto";
    } else if (company.integrated_time_via == "QuickBooks Time") {
      slug = "qbtime";
    }

    disconnectIntegration(slug)
      .then(() => {
        flashSuccess("Successfully disconnected.");
        setAuthenticated(false);
      })
      .catch((res) => {
        flashError(res.error);
      });
  };

  return (
    <div>
      <div className="d-flex row home-dashboard">
        {!authenticated && (
          <>
            {integrations.map((integration, index) => {
              return (
                <div
                  className="col-md-4"
                  key={`integration-${index}`}
                >
                  <div
                    className="card mb-4 integration-card"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location = `/integrations/${integration.slug}/authenticate`;
                    }}
                  >
                    <div
                      className="card-body d-flex"
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <img
                        src={integration.image}
                        alt={integration.name}
                        width="100px"
                        unselectable="on"
                        className="align-middle"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
        {
          <div className="ms-auto">
            {authenticated ? (
              <div>
                {`You're connected via ${company.integrated_time_via}!`}
                <br />
                <br />
                <button
                  className="btn btn-light"
                  rel="nofollow"
                  onClick={onDisconnect}
                >
                  Click here to disconnect
                </button>
              </div>
            ) : null}
          </div>
        }
      </div>
    </div>
  );
};

export default TimeIntegrations;
