import React from 'react';

const UIRadioButton = (props) => {
  const handleClick = (e) => {
    props.handleClick(props.label) 
  }

  return (
    <label className="ui-radio-button">
      <input name={ props.name } type="radio" checked={props.checked} onChange={handleClick} />
      <span>{ props.label }</span>
    </label>
  )
}

export default UIRadioButton;
