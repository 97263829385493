import React from "react";
import Select, { components } from "react-select";
import MoreSelectedBadge from "./ui-more-selected-badge";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="bi bi-chevron-down"></i>
    </components.DropdownIndicator>
  );
};

const UIMultiSelect = ({ onChange, options, value, placeholder }) => {
  return (
    <Select
      isMulti
      options={options}
      hideSelectedOptions={false}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      classNamePrefix="select"
      components={{
        MultiValue: () => null,
        DropdownIndicator: DropdownIndicator,
        IndicatorSeparator: (props) => (
          <components.IndicatorSeparator
            {...props}
            isDisabled={value.length == 0}
          />
        ),
      }}
    />
  );
};

export default UIMultiSelect;
