import React from "react";
import { UIFormLabel, UIRadioCard } from "components/ui";

import FileCategorySelector from "components/common/file-category-selector";

const Form = ({ form, setForm, fileCategories }) => {
  return (
    <div className="row">
      <div className="col-md-4">
        <div className="form-group">
          <UIFormLabel label="Category" />
          <FileCategorySelector
            file_category={form.categoryId}
            selectFileCategory={(categoryId) => {
              setForm({ categoryId });
            }}
          />
        </div>
      </div>

      <div className="col-md-2">
        <UIFormLabel label="Limit (0 for unlimited)" />
        <input
          type="text"
          className="form-control"
          value={form.count}
          onChange={(e) => {
            setForm({ count: e.target.value });
          }}
        />
      </div>

      <div className="col-md-2">
        <div className="form-group">
          <UIFormLabel label="Required" />
          <br />
          <UIRadioCard
            name="Required"
            checked={form.required}
            id={"required"}
            label="Required"
            handleClick={() => {
              setForm({ required: !form.required });
            }}
          />
        </div>
      </div>
      <div className="col-md-2">
        <UIFormLabel label="Application Context" />
        <input
          type="text"
          className="form-control"
          value={form.application_context}
          onChange={(e) => {
            setForm({ application_context: e.target.value });
          }}
        />
      </div>
    </div>
  );
};

export default Form;
