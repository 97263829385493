import React, { useEffect, useState } from 'react';
import mammoth from 'mammoth'
import Loading from '../loading'

const DocxPreview = ({ url }) => {
  const [ isLoading, setIsLoading ] = useState(true)
  const [ src, setSrc ] = useState('')

  useEffect(() => {
    const jsonFile = new XMLHttpRequest();
    jsonFile.open('GET', url, true);
    jsonFile.send();
    jsonFile.responseType = 'arraybuffer';
    jsonFile.onreadystatechange = () => {
      if (jsonFile.readyState === 4 && jsonFile.status === 200) {
        mammoth.convertToHtml(
          { arrayBuffer: jsonFile.response },
          { includeDefaultStyleMap: true },
        )
        .then((result) => {
          const docEl = document.createElement('div');
          docEl.className = 'document-container';
          docEl.innerHTML = result.value;
          setIsLoading(false)
          document.getElementById('docx').innerHTML = docEl.outerHTML;
        })
        .catch((a) => {
          console.error('something went wrong', a);
        })
        .done();
      }
    };
  }, [])

  return (
    <div>
      { isLoading ? <Loading /> : 
        <div className='docs-wrapper'>
          <div id="docx">
          </div>
        </div>
       }  
    </div>
  );
}

export default DocxPreview;
