import React, { createContext } from "react";
import { useArray, useSetState } from "react-hanger";
import { find } from "lodash";

import {
  updateAdminOrganization,
  deleteOrganizationContact,
  createOrganizationContact,
  updateOrganizationContact,
} from "modules/organizations";

import { getDate } from "lib/utils";
import { flashError, flashWarning, flashSuccess } from "lib/flash";

export const EditOrganizationContext = createContext({
  organizationContacts: null,
  onDeleteOrganizationContact: null,
  onOrganizationContactUpdate: null,
  onSaveOrganizationContact: null,
  onUpdateOrganization: null,
  basicInfo: null,
  setBasicInfo: null,
});

const EditOrganizationContextProvider = ({ children, organization }) => {
  const organizationContacts = useArray(organization.organization_contacts);
  const { state: basicInfo, setState: setBasicInfo } = useSetState({
    id: organization.id,
    name: organization.name,
    description: organization.description,
    website: organization.website,
    entity_string: organization.entity_string,
    referral_source_id: organization.referral_source_id,
    referral_source_name: organization.referral_source_name,
    address_attributes: organization.address ? organization.address : {},
    logo: organization.image,
  });

  const onUpdateOrganization = (currentBasicInfo) => {
    const { referral_source_name, ...updateData } = currentBasicInfo;

    return updateAdminOrganization({
      organization: updateData,
      id: organization.id,
    })
      .then((res) => {
        flashSuccess("Organization Updated");
        return Promise.resolve();
      })
      .catch((res) => {
        if (res.errors) {
          res.errors.forEach((error) => {
            flashError(error);
          });
        } else {
          flashError("An error occurred while updating the organization.");
        }
        return Promise.reject();
      });
  };

  const onOrganizationContactUpdate = (organization_contact) => {
    const existing = find(organizationContacts.value, {
      id: organization_contact.id,
    });
    if (existing) {
      organizationContacts.modifyById(
        organization_contact.id,
        organization_contact
      );
    } else {
      organizationContacts.push(organization_contact);
    }

    flashSuccess("Contact saved");
  };

  const onSaveOrganizationContact = (organization_contact) => {
    if (organization_contact.id === null) {
      return createOrganizationContact({
        organization_contact: organization_contact,
        organization_id: organization.id,
      })
        .then((resp) => {
          onOrganizationContactUpdate(resp);
          return Promise.resolve();
        })
        .catch((resp) => {
          resp.errors.map((error) => {
            flashError(error);
          });
          return Promise.resolve();
        });
    } else {
      return updateOrganizationContact({
        organization_contact: organization_contact,
        organization_id: organization.id,
      })
        .then((resp) => {
          onOrganizationContactUpdate(organization_contact);
          return Promise.resolve();
        })
        .catch((resp) => {
          resp.errors.map((error) => {
            flashError(error);
          });
          return Promise.resolve();
        });
    }
  };

  const onDeleteOrganizationContact = (organization_contact) => {
    deleteOrganizationContact({
      organization_contact: organization_contact,
      organization_id: organization.id,
    })
      .then(() => {
        flashWarning("Contact removed");
        organizationContacts.removeById(organization_contact.id);
        return Promise.resolve();
      })
      .catch((resp) => {
        resp.errors.map((error) => {
          flashError(error);
        });
        return Promise.resolve();
      });
  };

  const context = {
    organizationContacts,
    onDeleteOrganizationContact,
    onOrganizationContactUpdate,
    onSaveOrganizationContact,
    onUpdateOrganization,
    basicInfo,
    setBasicInfo,
  };

  return (
    <EditOrganizationContext.Provider value={context}>
      {children}
    </EditOrganizationContext.Provider>
  );
};

export default EditOrganizationContextProvider;
