import React from "react";
import Select from "react-select";

import statusOptionsData from "./status-options";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    fontSize: "1rem",
    backgroundColor: state.isFocused ? "#fff" : "#fff",
    transition:
      "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out",
    borderColor: state.isFocused ? "#00A2E9" : "#e0e0e0",
    boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,162,233,.25)" : null,
    outline: "0",
  }),
};

const ProjectStatusSelect = (props) => {
  const statusOptions = props.filter
    ? statusOptionsData
    : statusOptionsData.slice(1);

  return (
    <Select
      options={statusOptions}
      value={
        props.isMulti
          ? props.status.filter((s) => statusOptions.includes(s))
          : statusOptions.includes(props.status)
          ? props.status
          : null
      }
      isClearable
      isMulti={props.isMulti}
      className={props.className}
      placeholder={props.filter ? "Filter by status" : "Select status"}
      onChange={props.onChange}
      styles={customStyles}
    />
  );
};

export default ProjectStatusSelect;
