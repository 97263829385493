import React from "react";

const StepItem = ({ step, index, isComplete, isActive }) => {
  const itemClass = isActive ? "active" : isComplete ? "completed" : "";

  return (
    <li className={`step-item ${itemClass}`}>
      <div className="line" />
      <p className="mt-1">{step.name}</p>
    </li>
  );
};

export default StepItem;
