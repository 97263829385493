import axios from "lib/axios";

export const getCompanyEstimateCategories = (params = {}) => {
  const url = `/company_estimate_categories`

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const createEstimateCategory = (params = {}) => {
  const url = `/company_estimate_categories`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}

export const updateProjectEstimateCategory = (params = {}) => {
  const url = `/project_estimate_categories/${params.id}`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}
