import React from "react"
import { Swiper, SwiperSlide } from "swiper/react";
import logo1 from 'images/t-drsquatch-square.png'
import logo2 from 'images/t-justice-design-square.png'
import logo3 from 'images/t-superior-lithographics-square.png'
import logo4 from 'images/t-everytable-square.png'
import logo5 from 'images/t-versa-square.png'

// import "swiper/swiper.scss";
// import "swiper/css/pagination.scss";
// import "swiper/css/navigation.scss";
// import "swiper/swiper.min.css";
// import "swiper/modules/navigation/navigation.min.css"
// import "swiper/modules/pagination/pagination.min.css"

// import required modules
import { Pagination, Navigation } from "swiper";

export default function Home() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        grabCursor={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className='testimonial'>
            <div className="testimonial-content">
              <div className="slide">
                <img src={logo1} alt='Dr. Squatch logo' />

                <blockquote>The tax credit application process was overwhelming but Subcity made it simple. The $10M tax-credit they gave us access to will go directly into supporting our business operations.
                  Subcity is leveling the playing field and placing government dollars back into the hands of companies that represent the backbone of American economic growth.
                </blockquote>
                <div className="testimonial-details">
                  <span className="name">Tom Ramhorst</span>
                  <span className="position">COO, Dr. Squatch</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='testimonial'>
            <div className="testimonial-content">
              <div className="slide">
                <img src={logo2} alt='Artemis logo' />
                <blockquote>Working with the Subcity team was a great experience and very successful. They took a complicated process in dealing with a utility and were able to coach us through the process with all their experience and knowledge.
                  Ultimately, they were able to save us 12% per year on our electricity bill for the next 5 years.

                </blockquote>
                <div className="testimonial-details">
                  <span className="name">Brandon Levin</span>
                  <span className="position">CEO, Justice Design Group</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='testimonial'>
            <div className="testimonial-content">
              <div className="slide">
                <img src={logo3} alt='Superior Lithographics logo' />

                <blockquote>I had an incredible experience with Sub-City. Professional, knowledgeable, and attentive to every detail as they walked us through a fairly complicated process.
                  They are a first class organization which I can highly recommend.
                </blockquote>
                <div className="testimonial-details">
                  <span className="name">Doug Rawson</span>
                  <span className="position">Founder, Superior Lithographics</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='testimonial'>
            <div className="testimonial-content">
              <div className="slide">
                <img src={logo4} alt='Everytable logo' />
                <blockquote>The team at Subcity just saved us $25,000 of basically found money. I'm actually not 100% sure how they did it, but my accounting team knows!
                  We are at least $25,000 grateful for their support.
                </blockquote>
                <div className="testimonial-details">
                  <span className="name">Sam Polk</span>
                  <span className="position">CEO, Everytable</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='testimonial'>
            <div className="testimonial-content">
              <div className="slide">
                <img src={logo5} alt='Versa logo' />

                <blockquote>Subcity uncovered $1.2 million in tax credits that we were completely unaware of. They made the process easy and affordable and this cash helped contribute to our bottom line.

                </blockquote>
                <div className="testimonial-details">
                  <span className="name">Chris Laudadio</span>
                  <span className="position">CEO, Versa Products</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
