import React, { useState } from "react";

import { UIAccordionCompleted } from "components/ui";
import ShortTextQuestion from "./short-text-question";
import MultipleChoiceQuestion from "./multiple-choice-question";

const EditQualificationField = (props) => {
  const [completed, setCompleted] = useState(props.completed);
  const [acceptableAnswer, setAcceptableAnswer] = useState(
    props.acceptable_answer
  );

  function handleUpdateSuccess() {
    if (!completed) {
      setCompleted(true);
    }
  }

  function handlePrequalifySuccess(response) {
    const updatedQualification = response.qualifications.find(
      (q) => q.id === props.id
    );
    if (updatedQualification) {
      setAcceptableAnswer(updatedQualification.acceptable_answer);
    }

    const { bannerMessage, bannerState, bannerSubtext } = response;
    props.updateBanner(bannerMessage, bannerState, bannerSubtext);
    props.setIsLoading(false);
  }

  function renderQuestion() {
    switch (props.answer_type) {
      case "short_text":
        return (
          <ShortTextQuestion
            {...props}
            handleUpdateSuccess={handleUpdateSuccess}
            handleChange={(e) => {
              e.preventDefault();
            }}
          />
        );
      case "multiple_choice":
        return (
          <MultipleChoiceQuestion
            {...props}
            handleUpdateSuccess={handleUpdateSuccess}
            handlePrequalifySuccess={handlePrequalifySuccess}
            setIsLoading={props.setIsLoading}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className="mt-5">
      <UIAccordionCompleted
        completed={completed}
        title={props.question_title}
        context={props.qualification_context}
        acceptable={acceptableAnswer}
        isLoading={props.isLoading}
      >
        <div className="row">
          <div className="col-md-6">{renderQuestion()}</div>
        </div>
      </UIAccordionCompleted>
    </div>
  );
};

export default EditQualificationField;
