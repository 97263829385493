import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { flashSuccess, flashError } from "lib/flash";

import { createCompanyWorker } from "modules/company_workers";

const AddWorkerModal = ({ show, onHide, onWorkerAdded }) => {
  const [newWorker, setNewWorker] = useState({
    name: "",
    employment_type: "",
    job_title: "",
    department_name: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewWorker({ ...newWorker, [name]: value });
  };

  const addWorker = async () => {
    try {
      const response = await createCompanyWorker(newWorker);
      onWorkerAdded(response);
      onHide();
    } catch (error) {
      console.error("Error adding worker:", error);
      flashError("Error adding working");
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          type="text"
          name="name"
          className="inline-edit mb-2"
          placeholder="Name"
          value={newWorker.name}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="employment_type"
          className="inline-edit mb-2"
          placeholder="Employment Type"
          value={newWorker.employment_type}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="job_title"
          className="inline-edit mb-2"
          placeholder="Job Title"
          value={newWorker.job_title}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="department_name"
          className="inline-edit mb-2"
          placeholder="Department"
          value={newWorker.department_name}
          onChange={handleInputChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={addWorker}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddWorkerModal;
