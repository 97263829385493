import React, { createContext } from "react";
import { useArray} from "react-hanger";

import FAQEntry from "./faq-entry";
import FAQNewEntry from "./faq-new-entry";

const FAQContext = createContext();

const FAQ = ({incentive})  => {
  const faqEntries = useArray(incentive.incentive_faq_entries);
  
  return (
    <div>
      <FAQContext.Provider value={{incentive, faqEntries}}>
        <FAQNewEntry/>

        <div className='table-responsive'>
          <table className='table'>
            <thead></thead>
            <tbody>
              {faqEntries.value.map((faqEntry) => {
                return (
                  <FAQEntry 
                    entry={faqEntry} 
                    key={faqEntry.id}/>
                )
              })}
            </tbody>
          </table>
        </div>
      </FAQContext.Provider>
    </div>
  );

}

export default FAQ;
export { FAQContext };
