import React, { useState, useEffect } from 'react';
import Loading from '../loading'

const ImagePreview = (props) => {
  const [ isLoading, setIsLoading ] = useState(true)

  function handleLoad() {
    setIsLoading(false)
  }

  return (
    <div>
      { isLoading ? <Loading /> : null }  
      <img id='img-id' src={props.url} onLoad={handleLoad} />
    </div>
  )
}

export default ImagePreview;
