import { toast } from "react-toastify";

export const flashNotice = (text) => {
  toast.info(text);
};

export const flashError = (text) => {
  toast.error(text);
};

export const flashAlert = (text) => {
  toast(text);
};

export const flashWarning = (text) => {
  toast.warning(text);
};

export const flashSuccess = (text, options = {}) => {
  toast.success(text, {
    style: options.style  // Apply inline styles
  });
};


export const flashNoticeLater = (text) => {
  pushToStorage(text, "notice");
};

export const flashErrorLater = (text) => {
  pushToStorage(text, "error");
};

export const flashAlertLater = (text) => {
  pushToStorage(text, "alert");
};

export const flashWarningLater = (text) => {
  pushToStorage(text, "warning");
};

export const flashSuccessLater = (text) => {
   pushToStorage(text, "success");
};


const pushToStorage = (text, type) => {
  let flashMessages = JSON.parse(localStorage.getItem("flash_messages"));

  if (flashMessages)
     flashMessages.push({ text, type });
   else
     flashMessages = [{ text, type }];

   localStorage.setItem("flash_messages", JSON.stringify(flashMessages));
}
