import axios from "lib/axios";
import { encodeParams } from 'lib/utils';

export const fetchAdminIps = (params = {}) => {
  const url = `/admin/ip_blocklist`;
  params = encodeParams(params);

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
