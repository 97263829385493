import React, { useState, useEffect } from "react";
import { prettyDisplayPrice } from "lib/string";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
import { getDate } from "lib/utils";
import { fetchChartOfAccounts } from "modules/company_accounts_chart";
import { flashError, flashSuccess, flashSuccessLater } from "lib/flash";

const RecurringPaymentRow = ({
  payment,
  createRecurringPayment,
  updateRecurringPayment,
  deleteRecurringPayment,
}) => {
  const [isEditing, setIsEditing] = useState(payment.isNew || false);
  const [editedPayment, setEditedPayment] = useState(payment);
  const [isSaving, setIsSaving] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedRecurrenceType, setSelectedRecurrenceType] = useState(
    editedPayment.recurrence_type || "monthly"
  );

  useEffect(() => {
    fetchChartOfAccounts().then((response) => {
      setAccounts(
        response.accounts.map((account) => {
          return {
            value: account.id,
            label: account.name,
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    // Check if we are starting to edit
    if (isEditing) {
      // Set the recurrence type
      setSelectedRecurrenceType(payment.recurrence_type || "monthly");

      // Set the edited payment fields. Optional becuase of new payment handling.
      let newEditedPayment = { ...payment };
      if (payment.recurrence_type === "bimonthly") {
        newEditedPayment.first_date = payment.recurrence_dates?.[0];
        newEditedPayment.second_date = payment.recurrence_dates?.[1];
      } else if (payment.recurrence_type === "monthly") {
        newEditedPayment.day_of_month = payment.recurrence_dates?.[0];
      } else if (payment.recurrence_type === "weekly") {
        newEditedPayment.day_of_week = payment.recurrence_dates?.[0];
      }

      // convert pretty price back to number for editing
      if (newEditedPayment.amount !== null) {
        newEditedPayment.amount = Number(
          newEditedPayment.amount.replace(/[^0-9.-]+/g, "")
        );
      }

      setEditedPayment(newEditedPayment);
    }
  }, [isEditing, payment]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    setIsSaving(true);

    if (!editedPayment.account_id) {
      alert("Account selection required.");
      setIsSaving(false);
      return;
    }

    try {
      let separationCount = null;
      let firstDate = null;
      let dayOfWeek = null;
      let dayOfMonth = null;

      if (selectedRecurrenceType === "bimonthly") {
        firstDate = parseInt(editedPayment.first_date) || 1;
        const secondDate = parseInt(editedPayment.second_date) || 15;
        separationCount = secondDate - firstDate;
      } else if (selectedRecurrenceType === "weekly") {
        dayOfWeek = parseInt(editedPayment.day_of_week) || 0; // Default to Sunday (end of Subcity week)
      } else if (selectedRecurrenceType === "monthly") {
        dayOfMonth = editedPayment.day_of_month || 1; // Default to 1st day
      }

      const updatedPayment = {
        ...editedPayment,
        start_date: editedPayment.start_date || new Date().toISOString(), // default to now if not set
        expense_account_id: editedPayment.account_id,
        recurrence_type: selectedRecurrenceType,
        day_of_month:
          selectedRecurrenceType === "monthly"
            ? dayOfMonth
            : selectedRecurrenceType === "bimonthly"
            ? firstDate
            : null,
        day_of_week: selectedRecurrenceType === "weekly" ? dayOfWeek : null,
        separation_count:
          selectedRecurrenceType === "bimonthly" ? separationCount : null,
        details: { name: editedPayment.payment_name },
      };

      if (payment.id) {
        // Existing payment - update
        await updateRecurringPayment(payment.id, updatedPayment);
      } else {
        // New payment - create
        await createRecurringPayment(updatedPayment);
      }

      setIsEditing(false);
    } catch (error) {
      console.error("Error updating recurring payment:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setEditedPayment(payment);
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (
      name === "second_date" &&
      parseInt(value) < parseInt(editedPayment.first_date)
    ) {
      alert("Second date must be after first date.");
      return;
    }

    if (name === "first_date" && selectedRecurrenceType === "bimonthly") {
      const selectedFirstDate = parseInt(value);
      setEditedPayment((prevPayment) => ({
        ...prevPayment,
        day_of_month: selectedFirstDate,
        [name]: value,
      }));
    } else {
      setEditedPayment((prevPayment) => ({
        ...prevPayment,
        [name]: value,
      }));
    }
  };

  const handleDelete = async () => {
    try {
      await deleteRecurringPayment(payment.id);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting recurring payment:", error);
    }
  };

  const handleStartDateChange = (date) => {
    const formattedDate = getDate(date);
    setEditedPayment((prevPayment) => ({
      ...prevPayment,
      start_date: formattedDate ? formattedDate.toISOString() : null,
    }));
  };

  const getRecurrenceDescription = (recurrence_type, recurrence_dates) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    if (
      recurrence_type === "weekly" &&
      recurrence_dates &&
      recurrence_dates.length > 0
    ) {
      const dayOfWeek = recurrence_dates[0];
      if (dayOfWeek >= 0 && dayOfWeek < daysOfWeek.length) {
        return `weekly on ${daysOfWeek[dayOfWeek]}`;
      }
    } else if (
      recurrence_type === "monthly" &&
      recurrence_dates &&
      recurrence_dates.length > 0
    ) {
      const dayOfMonth = recurrence_dates[0];
      if (dayOfMonth >= 1 && dayOfMonth <= 31) {
        const getDaySuffix = (day) =>
          day % 10 === 1 && day !== 11
            ? "st"
            : day % 10 === 2 && day !== 12
            ? "nd"
            : day % 10 === 3 && day !== 13
            ? "rd"
            : "th";
        return `monthly on the ${dayOfMonth}${getDaySuffix(dayOfMonth)}`;
      }
    } else if (
      recurrence_type === "bimonthly" &&
      recurrence_dates &&
      recurrence_dates.length > 1
    ) {
      const firstDate = recurrence_dates[0];
      const secondDate = recurrence_dates[1];
      if (
        firstDate >= 1 &&
        firstDate <= 31 &&
        secondDate >= 1 &&
        secondDate <= 31
      ) {
        const getDaySuffix = (day) =>
          day % 10 === 1 && day !== 11
            ? "st"
            : day % 10 === 2 && day !== 12
            ? "nd"
            : day % 10 === 3 && day !== 13
            ? "rd"
            : "th";
        return `bimonthly on the ${firstDate}${getDaySuffix(
          firstDate
        )} and ${secondDate}${getDaySuffix(secondDate)}`;
      }
    }

    return;
  };
  return (
    <tr>
      <td>
        {isEditing ? (
          <div>
            <select
              className="inline-dropdown"
              value={editedPayment.account_id}
              onChange={(e) =>
                setEditedPayment((prevPayment) => ({
                  ...prevPayment,
                  account_id: e.target.value,
                }))
              }
            >
              <option value="">Please select an account</option>
              {accounts.map((account) => (
                <option key={account.value} value={account.value}>
                  {account.label}
                </option>
              ))}
            </select>
          </div>
        ) : (
          payment.account
        )}
      </td>

      <td>
        {isEditing ? (
          <input
            type="text"
            className="inline-edit"
            name="payment_name"
            value={editedPayment.payment_name ?? ""}
            onChange={handleChange}
          />
        ) : (
          payment.payment_name
        )}
      </td>
      <td>
        {isEditing ? (
          <input
            type="number"
            className="inline-edit"
            name="amount"
            value={editedPayment.amount !== null ? editedPayment.amount : ""} //Allow for nullable field
            onChange={handleChange}
          />
        ) : (
          prettyDisplayPrice(payment.amount)
        )}
      </td>
      <td>
        {isEditing ? (
          <div>
            <select
              name="recurrence_type"
              className="inline-dropdown"
              value={selectedRecurrenceType}
              onChange={(e) => setSelectedRecurrenceType(e.target.value)}
            >
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="bimonthly">Bimonthly</option>
            </select>
            {selectedRecurrenceType === "weekly" && (
              <>
                <label>&nbsp; on &nbsp;</label>
                <select
                  name="day_of_week"
                  className="inline-dropdown"
                  value={editedPayment.day_of_week || ""}
                  onChange={(e) => {
                    const selectedDayOfWeek = parseInt(e.target.value);
                    handleChange({
                      target: { name: "day_of_week", value: selectedDayOfWeek },
                    });
                  }}
                >
                  <option value="0">Sunday</option>
                  <option value="1">Monday</option>
                  <option value="2">Tuesday</option>
                  <option value="3">Wednesday</option>
                  <option value="4">Thursday</option>
                  <option value="5">Friday</option>
                  <option value="6">Saturday</option>
                </select>
              </>
            )}
            {selectedRecurrenceType === "bimonthly" && (
              <>
                <label> &nbsp; on the &nbsp;</label>
                <input
                  type="number"
                  className="inline-edit"
                  name="first_date"
                  value={editedPayment.first_date || ""}
                  onChange={handleChange}
                  min={1}
                  max={30}
                />
                <label> &nbsp; and &nbsp;</label>
                <input
                  type="number"
                  className="inline-edit"
                  name="second_date"
                  value={editedPayment.second_date || ""}
                  onChange={handleChange}
                  min={editedPayment.first_date || 1}
                  max={30}
                />
              </>
            )}
            {selectedRecurrenceType === "monthly" && (
              <>
                <label>&nbsp; on the &nbsp;</label>
                <input
                  type="number"
                  className="inline-edit"
                  name="day_of_month"
                  value={editedPayment.day_of_month || ""}
                  onChange={handleChange}
                  min={1}
                  max={30}
                />
                {editedPayment.day_of_month > 30 && (
                  <p className="error-message">Invalid day of the month</p>
                )}
              </>
            )}
          </div>
        ) : (
          getRecurrenceDescription(
            payment.recurrence_type,
            payment.recurrence_dates
          )
        )}
      </td>
      <td>
        {isEditing ? (
          <DatePicker
            selected={
              editedPayment.start_date
                ? parseISO(editedPayment.start_date)
                : null
            }
            onChange={handleStartDateChange}
            dateFormat="yyyy-MM-dd"
            className="inline-dropdown"
          />
        ) : (
          payment.start_date
        )}
      </td>

      <td>
        {isEditing ? (
          <>
            <button
              className="btn btn-sm btn-light me-2"
              onClick={handleDelete}
            >
              <i className="bi bi-trash2"></i>
            </button>
            <button
              className="btn btn-sm btn-primary me-2"
              onClick={handleSave}
            >
              <i className="bi bi-check"></i>
            </button>
            <button className="btn btn-sm btn-light" onClick={handleCancel}>
              <i className="bi bi-x"></i>
            </button>
          </>
        ) : (
          <button className="btn btn-sm btn-muted" onClick={handleEdit}>
            Edit
          </button>
        )}
      </td>
    </tr>
  );
};

export default RecurringPaymentRow;
