import React, { useState } from "react";
import FAQEntryEdit from "./faq-entry-edit";

const MODE_DISPLAY = "display";
const MODE_EDIT = "edit";

const FAQEntry = ({ entry }) => {
  const [viewMode, setViewMode] = useState(MODE_DISPLAY);

  switch (viewMode) {
    case MODE_DISPLAY:
      return (
        <tr>
          <td className="w-100">
            <h5>{entry.question}</h5>
            <p className="mb-0" style={{ whiteSpace: "pre-wrap" }}>
              {entry.answer}
            </p>
          </td>
          <td className="text-nowrap">
            <button
              className="btn btn-sm btn-muted"
              aria-label="Edit"
              onClick={() => {
                setViewMode(MODE_EDIT);
              }}
            >
              Edit
            </button>
          </td>
        </tr>
      );
    case MODE_EDIT:
      return (
        <tr>
          <td className="w-100" colSpan={2}>
            <FAQEntryEdit
              prefilledFAQEntry={entry}
              onDoneEditing={() => {
                setViewMode(MODE_DISPLAY);
              }}
            />
          </td>
        </tr>
      );
    default:
      return null;
  }
};

export default FAQEntry;
