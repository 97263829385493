import React, { useState, useEffect, useContext } from "react";
import Checkbox from "rc-checkbox";
import { EditUserContext } from "./edit-user-context";
import { UIFormLabel } from "components/ui";

import { fetchSystemPermissions } from "modules/users";

const EditUserBasicInfo = () => {
  const { onUpdateUser, basicInfo, setBasicInfo } = useContext(EditUserContext);
  const [systemPermissions, setSystemPermissions] = useState([]);

  useEffect(() => {
    fetchSystemPermissions().then((data) => {
      setSystemPermissions(data.permissions);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdateUser();
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <h3>{`Edit ${basicInfo.first_name}'s Basic Info`}</h3>
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <form className="form" onSubmit={handleSubmit}>
            <div className="mb-3">
              <label>
                <strong>First Name</strong>
              </label>
              <input
                type="text"
                name="First Name"
                className="form-control"
                value={basicInfo.first_name}
                onChange={(e) => {
                  e.preventDefault();
                  setBasicInfo({
                    first_name: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <label>
                <strong>Last Name</strong>
              </label>
              <input
                type="text"
                name="Last Name"
                className="form-control"
                value={basicInfo.last_name}
                onChange={(e) => {
                  e.preventDefault();
                  setBasicInfo({
                    last_name: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <label>
                <strong>Email</strong>
              </label>
              <input
                type="text"
                name="Email"
                className="form-control"
                value={basicInfo.email}
                onChange={(e) => {
                  e.preventDefault();
                  setBasicInfo({
                    email: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <label>
                <strong>Phone Number</strong>
              </label>
              <input
                type="text"
                name="Phone Number"
                className="form-control"
                value={basicInfo.phone_number}
                onChange={(e) => {
                  e.preventDefault();
                  setBasicInfo({
                    phone_number: e.target.value,
                  });
                }}
              />
            </div>

            <hr />

            <div className="mb-3">
              <div>
                <UIFormLabel label="System Permission Level" />
                <br />
              </div>

              <select
                className="form-control"
                value={basicInfo.system_permission_id}
                defaultValue={basicInfo.system_permission_id}
                onChange={(e) => {
                  e.preventDefault();
                  setBasicInfo({
                    system_permission_id: e.target.value,
                  });
                }}
              >
                <option value="">none</option>
                {systemPermissions.map((permission, index) => {
                  return (
                    <option
                      value={permission.id}
                      key={`permission-${permission.id}`}
                    >
                      {permission.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="mb-3">
              <div>
                <UIFormLabel label="Block Access" />
                <br />
              </div>

              <label htmlFor="is-locked">
                <input
                  type="checkbox"
                  id="is-locked"
                  className="company-admin-checkbox me-2"
                  checked={basicInfo.is_locked}
                  onChange={(e) => {
                    e.preventDefault();
                    setBasicInfo({
                      is_locked: basicInfo.is_locked ? false : true,
                    });
                  }}
                />
                Locked
              </label>
            </div>

            <div className="mb-5 btn-group float-end">
              <a className="btn btn-light" href="/admin/users">
                Cancel
              </a>

              <button className="btn btn-primary" type="submit">
                Save User
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditUserBasicInfo;
