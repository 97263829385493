import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import moment from "moment";

import { claimCompanyFacility, updateCompanyFacility } from "modules/company_facilities";

import { flashError, flashSuccessLater } from "lib/flash";
import { useDebouncedEffect } from 'lib/utils'

import { UIColLabelInput, UIFormLabel, UILabelInput, UIMoneyLabelInput } from 'components/ui'

const ClaimFacility = ({ facility, user, incentive }) => {
  const AUTOSAVE_TIMEOUT = 500;

  const [ currentFacility, setCurrentFacility ] = useState(facility);
  const [ submitAttempted, setSubmitAttempted ] = useState(false);

  useDebouncedEffect(() => {
    const params = constructRequestParams(currentFacility);
    // This update is best-effort, hence no success or failure notification.

    updateCompanyFacility(params)
  }, [currentFacility], AUTOSAVE_TIMEOUT);

  const requiredFieldText = () => {
    if (submitAttempted) return <span className='text-danger'>This is a required field.</span>
  }

  const isFormValid = () => {
    return currentFacility.details.contact_email &&
      currentFacility.details.contact_name &&
      currentFacility.details.contact_phone &&
      currentFacility.details.contact_title &&
      currentFacility.details.decision_factor_1 &&
      currentFacility.details.decision_factor_2 &&
      currentFacility.details.decision_factor_3 &&
      currentFacility.details.electric_monthly_cost_est &&
      currentFacility.details.electric_said &&
      currentFacility.details.exec_name &&
      currentFacility.details.exec_title &&
      currentFacility.details.geographic_markets &&
      currentFacility.details.industry &&
      currentFacility.details.non_ca_advantages &&
      currentFacility.details.non_ca_city &&
      currentFacility.details.non_ca_disadvantages &&
      currentFacility.details.non_ca_edo &&
      currentFacility.details.non_ca_edo_contact_name &&
      currentFacility.details.non_ca_edo_contact_email_phone &&
      currentFacility.details.non_ca_incentives &&
      currentFacility.details.non_ca_state &&
      currentFacility.details.primary_products_services &&
      currentFacility.details.projected_new_jobs &&
      currentFacility.details.projected_retained_jobs
  }

  const constructRequestParams = (facility) => {
    return {
      id: facility.id,
      details: facility.details,
      incentive_id: incentive.id,
    }
  }

  const onClaim = (facility) => {
    const params = constructRequestParams(facility);

    claimCompanyFacility(params).then((res) => {
      location.reload();
      flashSuccessLater(`Success! Check the Claimed tab for forms.`);
    }).catch((errors) => {
      flashError(`There was an error.`);
    });
  };

  return (
    <div className="application-form-container">
      <form 
        noValidate
        className='form' 
        onSubmit={(e) => {
          e.preventDefault()
          setSubmitAttempted(true);
          if (isFormValid()) {
            onClaim(currentFacility);
          } else {
            flashError(`Something's not right! Please review your input and resolve any errors.`);
          }
        }
      }>
        <div className="row mt-5">
          <div className="col-md-6">
            <h3 className="panel-title mt-0">Contacts</h3>
            <div className='row mb-4'>
              <UILabelInput
                required
                label="Name"
                error={!currentFacility.details.contact_name ? requiredFieldText() : "" }
                value={currentFacility.details.contact_name}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, contact_name: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="Title"
                error={!currentFacility.details.contact_title ? requiredFieldText() : "" }
                value={currentFacility.details.contact_title}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, contact_title: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="Telephone"
                error={!currentFacility.details.contact_phone ? requiredFieldText() : "" }
                value={currentFacility.details.contact_phone}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, contact_phone: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="Email"
                error={!currentFacility.details.contact_email ? requiredFieldText() : "" }
                value={currentFacility.details.contact_email}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, contact_email: value },
                  });
                }}
              />
            </div>
            <div className='row mb-4'>
              <UILabelInput
                required
                label="Executive Name"
                error={!currentFacility.details.exec_name ? requiredFieldText() : "" }
                value={currentFacility.details.exec_name}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, exec_name: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="Executive Title"
                error={!currentFacility.details.exec_title ? requiredFieldText() : "" }
                value={currentFacility.details.exec_title}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, exec_title: value },
                  });
                }}
              />
            </div>
          </div>

          <div className="col-md-6">
            <h3 className="panel-title mt-0">Company Details</h3>
            <div className='row mb-4'>
              <UILabelInput
                required
                label="Industry"
                error={!currentFacility.details.industry? requiredFieldText() : "" }
                value={currentFacility.details.industry}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, industry: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="Primary Products/Services"
                error={!currentFacility.details.primary_products_services ? requiredFieldText() : "" }
                value={currentFacility.details.primary_products_services }
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, primary_products_services: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="Major Geographic Markets"
                error={!currentFacility.details.geographic_markets ? requiredFieldText() : "" }
                value={currentFacility.details.geographic_markets }
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, geographic_markets: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="Retained Jobs"
                error={!currentFacility.details.projected_retained_jobs ? requiredFieldText() : "" }
                value={currentFacility.details.projected_retained_jobs}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, projected_retained_jobs: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="New Jobs"
                error={!currentFacility.details.projected_new_jobs ? requiredFieldText() : "" }
                value={currentFacility.details.projected_new_jobs}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, projected_new_jobs: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                label="Local Economic Development Organization"
                value={currentFacility.details.local_edo}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, local_edo: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                label="GoBIZ Project #"
                value={currentFacility.details.gobiz}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, gobiz: value },
                  });
                }}
              />
            </div>

          </div>
        </div>

        <div className="row mb-4">
          <h3 className="panel-title mt-0">Electricity Usage</h3>
          <div className="col-md-6">

            <div className='row mb-4'>
              <UIMoneyLabelInput
                label="Monthly Cost Estimate"
                required
                error={!currentFacility.details.electric_monthly_cost_est ? requiredFieldText() : "" }
                id="electric-monthly-cost"
                defaultValue={currentFacility.details.electric_monthly_cost_est}
                onValueChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, electric_monthly_cost_est: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="Service Agreement ID #"
                error={!currentFacility.details.electric_said ? requiredFieldText() : "" }
                value={currentFacility.details.electric_said}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, electric_said: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="Estimated Demand (kW)"
                error={!currentFacility.details.electric_demand_est ? requiredFieldText() : "" }
                value={currentFacility.details.electric_demand_est}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, electric_demand_est: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                label="Last 12m Usage (kW)"
                value={currentFacility.details.electric_last_12m_kw}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, electric_last_12m_kw: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                label="Electric Load (kW)"
                value={currentFacility.details.electric_load}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, electric_load: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                label="Monthly Usage (kWh)"
                value={currentFacility.details.electric_monthly_usage_est}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, electric_monthly_usage_est: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                label="Service Voltage (V)"
                value={currentFacility.details.electric_service_voltage}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, electric_service_voltage: value },
                  });
                }}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className='row mb-4'>
              <UILabelInput
                label="Load Factor"
                value={currentFacility.details.electric_load_factor}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, electric_load_factor: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                label="Power Factor"
                value={currentFacility.details.electric_power_factor}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, electric_power_factor: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                label="Operating Days"
                value={currentFacility.details.electric_operating_days}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, electric_operating_days: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                label="Operating Hours"
                value={currentFacility.details.electric_operating_hours}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, electric_operating_hours: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                label="Peak Month"
                value={currentFacility.details.electric_peak_month_est}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, electric_peak_month_est: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                label="Peak Time"
                value={currentFacility.details.electric_peak_time_est}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, electric_peak_time_est: value },
                  });
                }}
              />
            </div>
          </div>
        </div>

          <div className="row">
            <div className="col">
              <div>
                Were your operations terminated or paused due to a California Governor declared state of emergency or public safety power shutoff (PSPS)?
              </div>
            </div>
            <div className="col">
              <div role="group" aria-labelledby="electric_operations_terminated">
                <label className="me-4">
                  Yes
                  <input type="radio"
                         className="ms-2"
                         checked={currentFacility.details.electric_operations_terminated  === true} 
                         onChange={(e) => {
                           setCurrentFacility({
                             ...currentFacility,
                             details: { ...currentFacility.details, electric_operations_terminated: true },
                           });
                         } }/>
                </label>
                <label>
                  No
                  <input type="radio" 
                         className="ms-2"
                         checked={!currentFacility.details.electric_operations_terminated} 
                         onChange={(e) => {
                           setCurrentFacility({
                             ...currentFacility,
                             details: { ...currentFacility.details, electric_operations_terminated: false },
                           });
                         } }/>
                </label>
              </div>
            </div>
          </div>

        <div className="row mt-5">
          <h3 className="panel-title mt-0">Decision Factors</h3>

          <p>What are the top three decision factors used to determine the location of your facility?</p>

          <div className='row mb-4'>
            <UIColLabelInput
              required
              label="1."
              labelWidth={1}
              error={!currentFacility.details.decision_factor_1 ? requiredFieldText() : "" }
              value={currentFacility.details.decision_factor_1}
              onChange={(value) => {
                setCurrentFacility({
                  ...currentFacility,
                  details: { ...currentFacility.details, decision_factor_1: value },
                });
              }}
            />
          </div>

          <div className='row mb-4'>
            <UIColLabelInput
              required
              label="2."
              labelWidth={1}
              error={!currentFacility.details.decision_factor_2 ? requiredFieldText() : "" }
              value={currentFacility.details.decision_factor_2}
              onChange={(value) => {
                setCurrentFacility({
                  ...currentFacility,
                  details: { ...currentFacility.details, decision_factor_2: value },
                });
              }}
            />
          </div>

          <div className='row mb-4'>
            <UIColLabelInput
              required
              label="3."
              labelWidth={1}
              error={!currentFacility.details.decision_factor_3 ? requiredFieldText() : "" }
              value={currentFacility.details.decision_factor_3}
              onChange={(value) => {
                setCurrentFacility({
                  ...currentFacility,
                  details: { ...currentFacility.details, decision_factor_3: value },
                });
              }}
            />
          </div>

        </div>

        <div className="row mt-5">
          <h3 className="panel-title mt-0">Out-of-state Opportunity</h3>

          <div className="col-md-6">
            <div className='row mb-4'>
              <UILabelInput
                required
                label="City"
                error={!currentFacility.details.non_ca_city ? requiredFieldText() : "" }
                value={currentFacility.details.non_ca_city}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, non_ca_city: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="State"
                error={!currentFacility.details.non_ca_state ? requiredFieldText() : "" }
                value={currentFacility.details.non_ca_state}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, non_ca_state: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="Advantages"
                error={!currentFacility.details.non_ca_advantages ? requiredFieldText() : "" }
                value={currentFacility.details.non_ca_advantages}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, non_ca_advantages: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="Disadvantages"
                error={!currentFacility.details.non_ca_disadvantages ? requiredFieldText() : "" }
                value={currentFacility.details.non_ca_disadvantages}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, non_ca_disadvantages: value },
                  });
                }}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className='row mb-4'>
              <UILabelInput
                required
                label="Economic Development Organization"
                error={!currentFacility.details.non_ca_edo ? requiredFieldText() : "" }
                value={currentFacility.details.non_ca_edo}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, non_ca_edo: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="Contact Name"
                error={!currentFacility.details.non_ca_edo_contact_name ? requiredFieldText() : "" }
                value={currentFacility.details.non_ca_edo_contact_name}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, non_ca_edo_contact_name: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="Contact Email/Telephone"
                error={!currentFacility.details.non_ca_edo_contact_email_phone ? requiredFieldText() : "" }
                value={currentFacility.details.non_ca_edo_contact_email_phone}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, non_ca_edo_contact_email_phone: value },
                  });
                }}
              />
            </div>

            <div className='row mb-4'>
              <UILabelInput
                required
                label="Incentives"
                error={!currentFacility.details.non_ca_incentives ? requiredFieldText() : "" }
                value={currentFacility.details.non_ca_incentives}
                onChange={(value) => {
                  setCurrentFacility({
                    ...currentFacility,
                    details: { ...currentFacility.details, non_ca_incentives: value },
                  });
                }}
              />
            </div>

          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-12">
            <div className="btn-group float-end">
              <button className="btn btn-action" type="submit">
                Claim
              </button>
            </div>
          </div>
        </div>

      </form>
    </div>
  );
};

export default ClaimFacility;
