import React from "react";
import ReactPaginate from "react-paginate";

const UIPagination = (props) => {
  const { page, perPage, total } = props;

  const pageCount = Math.ceil(total / perPage);

  const currentPage = page;
  let boundaryRight =
    perPage >= total || page >= total
      ? total
      : parseInt(perPage) * parseInt(currentPage);
  boundaryRight = boundaryRight >= total ? total : boundaryRight;

  const boundaryLeft =
    perPage >= total || page == total
      ? currentPage
      : (parseInt(currentPage) - 1) * parseInt(perPage) + 1;

  const pageInfo = `${boundaryLeft}-${boundaryRight} of ${total}`;

  let selectors = null;

  return (
    <div>
      <span className="font-weight-bolder me-3 pagination-text">
        {pageInfo}
      </span>

      {perPage < total ? (
        <span className="d-inline-block">
          <ReactPaginate
            onPageChange={props.changePage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< "
            nextLabel=" >"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination justify-content-center"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </span>
      ) : null}
    </div>
  );
};

export default UIPagination;
