import React from 'react';
import { 
  UISelect, 
  UIColLabelInput, 
  UIDateInput, 
  UITextareaLabelInput, 
} from "components/ui";
import Form from "react-bootstrap/Form";

import AsyncSelect from "react-select/async";

import { loadCompanyAccountsCharts } from 'modules/company_accounts_charts'

const InvoiceDetails = ({ company, invoice, setInvoice, setEndpoint }) => {
  const loader = loadCompanyAccountsCharts({id: company.id})
  const defaultExpenseAccount = { value: invoice.expense_account_id, label: invoice.expense_account_name }

  const invoiceStatus = [{
    name: 'Draft',
    value: 'draft'
  }, {
    name: 'Submitted',
    value: 'submitted'
  }, {
    name: 'PartiallyPaid',
    value: 'partially_paid'
  }, {
    name: 'Paid',
    value: 'paid'
  }, {
    name: 'Void',
    value: 'void'
  }]

  return (
    <>
      <div className='row mt-5'>
        <div className='col-md-6'>
          <div className="row mb-4">
            <UIColLabelInput
              labelWidth={4}
              formWidth={5}
              label="Invoice Number"
              value={invoice.invoice_number || ""}
              onChange={(value) => {
                setInvoice({
                  ...invoice,
                  invoice_number: value,
                });
              }}
            />
          </div>
          <div className="row mb-4">
            <UIColLabelInput
              labelWidth={4}
              formWidth={5}
              label="PO Number"
              value={invoice.purchase_order_number || ""}
              onChange={(value) => {
                setInvoice({
                  ...invoice,
                  purchase_order_number: value,
                });
              }}
            />
          </div>
          <div className='row mb-4'>
            <Form.Label htmlFor="date" className="col-sm-4 pe-0">
              <strong>Invoice Status</strong>
            </Form.Label>           

            <div className='col-md-5'>
              <UISelect
                name={'invoice-status'}
                currentOption={(invoice.status || "").toLowerCase()}
                options={invoiceStatus}
                handleChange={(status) => {
                  setInvoice({
                    ...invoice,
                    status
                  });
                }}
              />
            </div>
          </div>
          {
          (setEndpoint(() => {
              return (
                <div className="row mb-4">
                  <div className='col-md-4'>
                    <label htmlFor="expenseAccount">
                      <strong>Expense Account</strong>
                    </label>
                  </div>

                  <div className='col-md-5'>
                    <AsyncSelect
                      isMulti={false}
                      loadOptions={loader}
                      defaultValue={defaultExpenseAccount}
                      defaultOptions
                      name={`expenseAccount`}
                      onChange={(account) => {
                        setInvoice({ ...invoice, expense_account_id: account.value})
                      }}
                    />
                  </div>
                </div>
              )}, () => { return null }
            ))()
          }
        </div>

        <div className='col-md-6'>
          <div className="row mb-4">
            <Form.Label htmlFor="date" className="col-sm-4 pe-0">
              <strong>Issue Date</strong>
            </Form.Label>
            <div className={`col-sm-5`}>
              <UIDateInput
                date={invoice.issue_date}
                required={false}
                handleChange={(value) => {
                  setInvoice({ ...invoice, issue_date: value })
                }}
              />
            </div>
          </div>
          <div className="row mb-4">
            <Form.Label htmlFor="date" className="col-sm-4 pe-0">
              <strong>Preferred Payment Date</strong>
            </Form.Label>
            <div className={`col-sm-5`}>
              <UIDateInput
                date={invoice.company_preferred_payment_date}
                required={false}
                handleChange={(value) => {
                  setInvoice({ ...invoice, company_preferred_payment_date: value })
                }}
              />
            </div>
          </div>
          <div className="row mb-4">
            <UIColLabelInput
              labelWidth={4}
              formWidth={5}
              label="Terms"
              value={invoice.terms || ""}
              onChange={(value) => {
                setInvoice({
                  ...invoice,
                  terms: value,
                });
              }}
            />
          </div>
          <div className="row mb-4">
            <Form.Label htmlFor="date" className="col-sm-4 pe-0">
              <strong>Due Date</strong>
            </Form.Label>
            <div className={`col-sm-5`}>
              <UIDateInput
                date={invoice.due_date}
                required={false}
                handleChange={(value) => {
                  setInvoice({ ...invoice, due_date: value })
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvoiceDetails;