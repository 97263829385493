import React from 'react';
import { humanFileSize } from 'lib/string'

const DocumentRow = (props) => {
  return (
    <div className='card p-3 mb-3 d-block'>
      {
        props.canDelete &&
          <button className='btn btn-sm btn-muted float-end' onClick={props.handleDelete}>
            <i className="bi bi-x-lg"></i>
          </button>
      }
      <a href={props.url}>{ props.name }</a>
      <p className='text-muted fs-6'>{humanFileSize(props.size)}</p>
    </div>
  )
}

export default DocumentRow;