import React from "react";
import { prettyDisplayPrice } from "lib/string";
import moment from "moment";

const InvoiceWeekRow = ({
  index,
  total,
  displayMap,
  setDisplayMap,
  week,
  hasNotes,
  projectedMoneyInForWeek,
  actualBalancesInForWeek,
  projectedMoneyOutForWeek,
  actualBalancesOutForWeek,
}) => {
  let displayWeek;
  let weekDescription;
  let weekStart;
  let weekEnd;

  const currentWeek = setToMondayMoment(moment());

  if (week === "Past Due") {
    displayWeek = weekStart = "";
    weekDescription = "Past Due";
  } else if (moment(week).isSame(currentWeek, "week")) {
    weekStart = moment(currentWeek).format("MM/DD/YYYY");
    weekEnd = moment(week).weekday(7).format("MM/DD/YYYY");

    displayWeek = `${weekStart} - ${weekEnd}`;
    weekDescription = <strong>This week</strong>;
  } else {
    weekStart = moment(week).weekday(1).format("MM/DD/YYYY");
    weekEnd = moment(week).weekday(7).format("MM/DD/YYYY");

    const weekDiff = moment(week).diff(currentWeek, "weeks") + 1;
    const weekDiffAbs = Math.abs(weekDiff);

    displayWeek = `${weekStart} - ${weekEnd}`;
    weekDescription = `${
      weekDiff === 1
        ? "Next week"
        : `${weekDiffAbs} week${weekDiffAbs > 1 ? "s" : ""} from now`
    }`;
  }

  return (
    <tr className="table-summary-row">
      <td
        style={{
          paddingLeft: "25px",
          width: "54%",
        }}
      >
        <div className="d-flex flex-column align-items-start">
          <div>
            {weekDescription}
            {hasNotes && (
              <i
                className="bi bi-bell"
                style={{
                  paddingLeft: "10px",
                  color: "#CBCBCB",
                }}
              ></i>
            )}
          </div>
          <div className="admin-metadata">{displayWeek}</div>
        </div>
      </td>
      <td>
        {!displayMap[week] && (
          <div className="d-flex flex-column align-items-start d-none d-sm-block">
            <div>{prettyDisplayPrice(projectedMoneyInForWeek)}</div>
            {projectedMoneyInForWeek && actualBalancesInForWeek ? (
              <small className="text-success-emphasis bg-success-subtle border border-success-subtle badge">
                {(
                  (actualBalancesInForWeek / projectedMoneyInForWeek) *
                  100
                ).toFixed(0)}
                % from A/R
              </small>
            ) : (
              <small className="text-success-emphasis bg-success-subtle border border-success-subtle badge">
                0% from A/R
              </small>
            )}
          </div>
        )}
      </td>

      <td>
        {!displayMap[week] && (
          <div className="d-flex flex-column align-items-start d-none d-sm-block">
            <div>-{prettyDisplayPrice(projectedMoneyOutForWeek)}</div>
            {projectedMoneyOutForWeek && actualBalancesOutForWeek ? (
              <small className="text-danger-emphasis bg-danger-subtle border border-danger-subtle badge">
                {(
                  (actualBalancesOutForWeek / projectedMoneyOutForWeek) *
                  100
                ).toFixed(0)}
                % from A/P
              </small>
            ) : (
              <small className="text-danger-emphasis bg-danger-subtle border border-danger-subtle badge">
                0% from A/P
              </small>
            )}
          </div>
        )}
      </td>
      <td>
        <div className="d-flex flex-column align-items-start">
          <div>
            <strong>{prettyDisplayPrice(total)}</strong>
          </div>
          <p className="finance-context" style={{ whiteSpace: "nowrap" }}>
            net impact
          </p>
        </div>
      </td>

      <td colSpan={2}>
        {total !== 0 && (
          <button
            className="btn btn-muted btn-sm chevron-button"
            onClick={(e) => {
              e.preventDefault();
              setDisplayMap((prevDisplayMap) => {
                return {
                  ...prevDisplayMap,
                  [week]: !prevDisplayMap[week],
                };
              });
            }}
          >
            <i
              className={`bi bi-chevron-${
                !displayMap[week] ? "right" : "down"
              }`}
            ></i>
          </button>
        )}
      </td>
    </tr>
  );
};

export default InvoiceWeekRow;

function setToMondayMoment(date) {
  return moment(date).add(-1, "days").weekday(1);
}
