import React from 'react';

import UIPagination from './ui-pagination'

const UIPaginationFooter = (props) => {
  const { pagination, colSpan, updatePagination } = props;

  if(pagination.total == 0) {
    return null;
  }

  return (
    <tfoot>
      <tr style={{'borderBottom' : '0px!important'}}>
        <td colSpan={colSpan}>
          <div className='float-end mt-2'>
            <UIPagination
              page={pagination.page}
              perPage={pagination.per_page}
              changePage={(page) => {
                updatePagination({
                  page: page.selected + 1,
                  first_load: false
                })
              }}
              total={pagination.total}
            />
          </div>
        </td>
      </tr>
    </tfoot>
  )
}

export default UIPaginationFooter;
