import axios from "lib/axios";
import { encodeParams } from 'lib/utils';

export const fetchAdminUsers = (params = {}) => {
  const url = `/admin/users`;
  params = encodeParams(params);

  return axios
    .get(url, {
      params: params,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const fetchSystemPermissions = () => {
  const url = `/admin/system_permissions`;

  return axios.get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    })
} 

export const updateAdminUser = (params = {}) => {
  const url = `/admin/users/${params.id}`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const resetPasswordAdminUser = (id) => {
  const url = `/admin/users/${id}/reset_password`;

  return axios
    .put(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
export const confirmUserEmail = (id) => {
  const url = `/admin/users/${id}/confirm_email`;

  return axios
    .put(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const resetInvitationAdminUser = (id) => {
  const url = `/admin/users/${id}/resend_invitation`;

  return axios
    .put(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const toggleLockAdminUser = (id) => {
  const url = `/admin/users/${id}/toggle_user_lock`;

  return axios
    .put(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const settingsUser = (params = {}) => {
  const url = `/accounts/settings_update`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updatePasswordUser = (params = {}) => {
  const url = `/accounts/change_password`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const inviteUser = (params = {}) => {
  const url = `/users/invitation?owner=true`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
