import React from "react";
import AdminPanelHeader from "../../common/admin-panel-header";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const IncentiveFormSchemaComponents = (props) => {
  const { incentive_form_schema_components } = props;

  return (
    <>
      <AdminPanelHeader
        title="Intake Form Schema Components"
        singularTitle="Form Component"
        addNewUrl="/admin/incentive_form_schema_components/new"
      />

      <div className="px-3">
        <div className="panel-table">
          <table className="table admin-table">
            <thead>
              <tr>
                <th>id</th>
                <th>Name</th>
                <th>Field Count</th>
                <th>Version</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {incentive_form_schema_components.map((component, index) => (
                <tr
                  className="row-with-shadow"
                  key={`form-schema-component-${index}`}
                >
                  <td>{component.id}</td>

                  <td>
                    {component.name}
                    <button
                      className="btn btn-link p-0 ml-2"
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            JSON.stringify({
                              $ref: `component:${component.name}`,
                            })
                          )
                          .then(() => {
                            alert("Copied to clipboard!");
                          })
                          .catch((err) => {
                            console.error("Failed to copy text: ", err);
                          });
                      }}
                      title="Copy standalone JSON reference to clipboard"
                    >
                      <i
                        className="bi bi-clipboard"
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          fontSize: "18px",
                        }}
                      ></i>
                    </button>
                    <button
                      className="btn btn-link p-0 ml-2"
                      onClick={() => {
                        const inlineJson = `"${component.name}": {\n    "$ref": "component:${component.name}"\n  },`;
                        navigator.clipboard
                          .writeText(inlineJson)
                          .then(() => {
                            alert("Copied inline JSON reference to clipboard!");
                          })
                          .catch((err) => {
                            console.error("Failed to copy text: ", err);
                          });
                      }}
                      title="Copy inline JSON reference to clipboard"
                    >
                      <i
                        className="bi bi-clipboard-plus"
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          fontSize: "18px",
                        }}
                      ></i>
                    </button>
                  </td>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip className="detail-tooltip">
                        {component.schema_field_names.join(", ")}
                      </Tooltip>
                    }
                  >
                    <td style={{ width: "5%" }}>
                      {component.schema_field_count}
                    </td>
                  </OverlayTrigger>
                  <td style={{ width: "5%" }}>{component.version}</td>
                  <td style={{ whiteSpace: "nowrap", width: "1%" }}>
                    <div className="d-flex justify-content-end">
                      <a
                        href={`/admin/incentive_form_schema_components/${component.id}/edit#`}
                        className="btn btn-sm btn-outline-secondary action-buttons"
                      >
                        Edit
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default IncentiveFormSchemaComponents;
