import React, { useState, useContext } from "react";
import { useBoolean } from "react-hanger";

import AddressForm from "../../common/address-form";
import { EditCompanyContext } from "components/common/companies/contexts/edit-company-context";

const Locations = () => {
  const { addresses, onDeleteAddress, onSaveAddress, basicInfo } =
    useContext(EditCompanyContext);
  const showAddressForm = useBoolean(false);

  const [editAddress, setEditAddress] = useState(null);

  const makeAddress = (address) => {
    let addressLine = address.address_line_1;

    if (!["", null].includes(address.address_line_2))
      addressLine += `, ${address.address_line_2}`;

    addressLine += ` ${address.city}, ${address.state} ${address.postal_code}`;

    return addressLine;
  };

  const afterAddressSave = () => {
    setEditAddress(null);
    showAddressForm.setFalse();
  };

  const saveAddress = (address) => {
    onSaveAddress(address).then(afterAddressSave);
  };

  const cancelEdit = () => {
    setEditAddress(null);
    showAddressForm.setFalse();
  };

  return (
    <div>
      <div className="col-md-12">
        {addresses.value.map((address) => {
          return (
            <div key={address.id} className="card p-3 mb-3">
              {editAddress !== null && editAddress.id === address.id ? (
                <AddressForm
                  address={editAddress}
                  onSaveAddress={saveAddress}
                  onCancel={cancelEdit}
                />
              ) : (
                <div className="d-flex">
                  <div>
                    <span>{makeAddress(address)}</span>

                    {address.is_primary && (
                      <span className="badge rounded-pill bg-dark ms-2">
                        Primary
                      </span>
                    )}
                  </div>
                  <div className="ms-auto d-flex align-self-start flex-wrap">
                    <div className="btn-group">
                      <button
                        className="btn btn-light"
                        onClick={() => setEditAddress(address)}
                      >
                        Edit
                      </button>
                      {!address.is_primary && (
                        <button
                          className="btn btn-dark mx-1"
                          onClick={() => onDeleteAddress(address)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {showAddressForm.value ? (
        <div className="col-md-12">
          <div className="card mb-3 p-3">
            <AddressForm onSaveAddress={saveAddress} onCancel={cancelEdit} />
          </div>
        </div>
      ) : (
        <button className="btn-attach mb-3" onClick={showAddressForm.setTrue}>
          Add Location
        </button>
      )}
    </div>
  );
};

export default Locations;
