import React, { useState, useEffect } from "react";
import { getCompanyProjectQuote } from "modules/project";

const ProjectQuoteLogo = (props) => {
  const [companyProjectQuote, setCompanyProjectQuote] = useState(null);

  useEffect(() => {
    getCompanyProjectQuote(props.company_project_id).then((data) => {
      if (data) {
        setCompanyProjectQuote(data);
      }
    });
  }, [props.company_project_id]);

  const primaryColor =
    companyProjectQuote?.company_project_quote_parameter?.primary_color ||
    "#1D3360";
  const accentColor =
    companyProjectQuote?.company_project_quote_parameter?.accent_color ||
    "#E22947";

  return (
    <div className="row mt-4 mb-4" style={{ color: primaryColor }}>
      <div className="col-md-3">
        {props.project.company_details.logo ? (
          <img
            src={props.project.company_details.logo}
            alt={`${props.project.company_details.name} Logo`}
          />
        ) : (
          <h2>{props.project.company_details.name}</h2>
        )}
        <p className="mt-0 mb-5" style={{ color: accentColor }}>
          {props.project.company_details.website}
        </p>
      </div>
      <div className="col-md-3" style={{ fontSize: "0.9rem" }}>
        {props.project.company_details.address
          .split(/,(.+)/)
          .map((line, index) => (
            <p key={index} className="mb-0">
              {line.trim()}
            </p>
          ))}
        <p className="mb-0">{props.project.company_details.phone}</p>
      </div>

      <div className="col-md-6 text-end">
        <h1>ESTIMATE</h1>
        <h2>#{props.external_project_id || props.company_project_id}</h2>
      </div>
    </div>
  );
};

export default ProjectQuoteLogo;
