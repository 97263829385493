import { map, snakeCase } from "lodash";
import { useEffect } from "react";
import moment from 'moment'
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import { titleCase } from './string'

export const DATETIME_LONG_FORMAT = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  timeZone: 'UTC',
});

export const formatSelectField = (value) => {
  return {
    value: value ? value.toLowerCase().replace(/\W/g, '') : '',
    label: titleCase(value)
  }
}

export const getUrlParams = (url, key, array = false) => {
  if (array) return url.searchParams.getAll(key);
  else return url.searchParams.get(key);
};

export const getDate = (date) => {
  const newDate = new Date(date);
  if (newDate == "Invalid Date") return;
  return newDate;
};

export const weeklyDisplayMap = (weekTotals) => {
  const weeks = Object.keys(weekTotals);
  let displayMap = {}

  displayMap["Past Due"] = false;

  for (var i = 0; i < weeks.length; i++) {
    const week = weeks[i];
    displayMap[week] = false;
  }

  return displayMap;
}

export const mergeRowsByWeek = (weekTotals, items, itemKey) => {
  const currentDate = moment();
  const weeks = Object.keys(weekTotals);

  let result = [];
  let pastDue = ["Past Due", 0, [], true];

  for (var i = 0; i < weeks.length; i++) {
    const startWeek = weeks[i];
    const endWeek = moment(startWeek).add(7, "days");

    let filtered = []
    for (var j = 0; j < items.length; j++) {
      const item = items[j];

      const dueDate = moment(item[itemKey]);
      if (startWeek === setToMonday(dueDate)) {
        filtered.push(item);
      }
    }

    const isPastDue = endWeek.isBefore(currentDate);
    if (isPastDue) {
      pastDue[1] = parseFloat(pastDue[1]) + parseFloat(weekTotals[startWeek])
      pastDue[2] = pastDue[2].concat(filtered)
    } else {
      result.push(
        [startWeek, weekTotals[startWeek], filtered, isPastDue]
      )
    }
  }

  if (pastDue[2].length) {
    return [pastDue].concat(result);
  } else {
    return result;
  }
}

function setToMonday(date) {
  return moment(date).add(-1, "days").weekday(1).format('YYYY-MM-DD');
}

export const setURLParams = (state, pagination) => {
  const url = new URL(window.location);

  url.searchParams.set("query", state.query);
  url.searchParams.set("sort_column", snakeCase(state.sortColumn));
  url.searchParams.set("sort_direction", state.sortDirection);
  url.searchParams.set("status", state.status?.value || "");
  url.searchParams.set("state", state.state || "");
  url.searchParams.set("per_page", pagination.per_page);
  url.searchParams.set("page", pagination.page);
  url.searchParams.delete("types[]");

  map(state.types, (type) => url.searchParams.append("types[]", type.value))

  window.history.pushState(null, "", url.toString());
};

// From https://stackoverflow.com/a/61127960:
export const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps || [], delay]);
}

export const encodeParams = (params) => {
  let paramsHash = params;

  Object.keys(paramsHash).forEach(function (key) {
    if (typeof (paramsHash[key]) === 'string') {
      paramsHash[key] = encodeURIComponent(paramsHash[key]);
    } else if (typeof (paramsHash[key]) === 'object') {
      paramsHash[key] = encodeParams(paramsHash[key]);
    }
  })
  return paramsHash;
}

// Make sure the controller orders the files correctly by using something like object.file_resources.order(created_at: :asc).map...
export const getLastFileResourceByName = (object, filename) => {
  let files = object.file_resources.filter(file => file.name == filename);
  return files ? files[files.length - 1] : null
}

export const isEmpty = (value) => {
  return value === undefined ||
    value === null ||
    value === ''
}

export const toPermalink = (string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

// vvv Formik Utils vvv
export const buildInitialValuesForSchema = (schema, defaults, unanswered = "") => {
  if (!defaults) { defaults = {}; }
  let initialValues = {};

  Object.entries(schema.properties).map((field) => {
    let fieldName = field[0];
    let fieldSchema = field[1];

    if (fieldSchema.type === "object") {
      initialValues[fieldName] = buildInitialValuesForSchema(fieldSchema, defaults[fieldName] ? defaults[fieldName] : {}, unanswered);
    } else if (defaults[fieldName] !== undefined) {
      initialValues[fieldName] = defaults[fieldName];
    } else {
      initialValues[fieldName] = unanswered;
    }
  });

  return initialValues;
}

export const deleteUnansweredValues = (values, unanswered = "") => {
  let filteredValues = {}

  Object.entries(values).map((field) => {
    let fieldName = field[0];
    let fieldValue = field[1];

    if (!fieldValue) {
      filteredValues[fieldName] = null;
    } else if (typeof fieldValue === "object") {
      filteredValues[fieldName] = deleteUnansweredValues(fieldValue, unanswered);
    } else if (fieldValue != unanswered) {
      filteredValues[fieldName] = fieldValue;
    }
  });

  return filteredValues;
}

export const exportToPdf = async (elementId, fileName) => {
  const input = document.getElementById(elementId);

  const canvas = await html2canvas(input, {
    scale: 2,
    scrollY: -window.scrollY,
    useCORS: true,
  });

  const imgData = canvas.toDataURL("image/png");

  const pdf = new jsPDF("p", "mm", "a4");
  const imgProps = pdf.getImageProperties(imgData);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  const pageHeight = pdf.internal.pageSize.getHeight();
  const pageCount = Math.ceil(pdfHeight / pageHeight);

  for (let i = 0; i < pageCount; i++) {
    const offsetY = -(i * pageHeight);

    pdf.addImage(
      imgData,
      "PNG",
      0,
      offsetY,
      pdfWidth,
      pdfHeight,
      undefined,
      "FAST"
    );

    if (i < pageCount - 1) {
      pdf.addPage();
    }
  }

  pdf.save(fileName);
};

export const setFormErrorsAsTouched = (errors, setFieldTouched, prefix = "") => {
  Object.entries(errors).forEach((entry, index) => {
    if (typeof entry[1] === "object") {
      setFormErrorsAsTouched(entry[1], setFieldTouched, prefix + entry[0] + ".");
    } else if (prefix) {
      setFieldTouched(`${prefix}${entry[0]}`, true, true);
    } else {
      setFieldTouched(entry[0], true, true);
    }
  });
}

export const hasTouchedError = (touched, errors) => {
  if (!touched || !errors) { return false; }

  let touchedEntries = Object.entries(touched);

  for (let index in touchedEntries) {
    let touchedName = touchedEntries[index][0];
    let touchedValue = touchedEntries[index][1];

    if (!errors[touchedName]) {
      // No errors for this field/section - thus no touched errors
      continue;
    }

    if (typeof touchedValue === "object"
      && hasTouchedError(touchedValue, errors[touchedName])) {
      // if subsection has touched errors - return true
      return true;
    }
    else if (touchedValue) {
      // field present in both touched and errors
      return true;
    }
  }

  return false;
}
// ^^^ Formik Utils ^^^
