import React, { useState } from "react";
import ProjectForm from "./components/project-form";
import { flashError, flashSuccess } from "lib/flash";

import { Modal } from "react-bootstrap";
import { projectParams, updateCompanyProject } from "modules/project";

const Edit = (props) => {
  function handleSubmit(project) {
    const params = projectParams(project);
    updateCompanyProject(params)
      .then((response) => {
        flashSuccess("Project updated!");
        props.setShow(false);
      })
      .catch((error) => {
        flashError(error);
      });
  }

  if (!props.show) return null;

  return (
    <Modal
      centered
      show={props.show}
      onHide={() => props.setShow(false)}
      dialogClassName="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit - {props.project.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <ProjectForm
            edit={true}
            project={props.project}
            handleSubmit={handleSubmit}
            saveText="Update Project"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Edit;
