import React, { useState } from "react";

import { UIAccordionCompleted } from "components/ui";
import NaicsCodeEditor from "./naics-code-editor";
import AddressEditor from "./address-editor";
import CompanyFieldEditor from "./company-field-editor";

import { titleCase } from "lib/string";

const EditEligibilityField = (props) => {
  const { eligibility } = props;
  const [completed, setCompleted] = useState(eligibility.completed);

  function clearCompleted() {
    setCompleted(false);
  }

  function handleUpdateSuccess() {
    if (!completed) {
      setCompleted(true);
    }
    window.location.reload(true);
    //TODO: Update banner properly using props
  }

  switch (eligibility.table) {
    case "companies":
      return (
        <div className="mb-5">
          <UIAccordionCompleted
            completed={completed}
            title={titleCase(eligibility.column)}
          >
            <CompanyFieldEditor
              {...props}
              handleUpdateSuccess={handleUpdateSuccess}
            />
          </UIAccordionCompleted>
        </div>
      );
    case "addresses":
      return (
        <div className="mb-5">
          <UIAccordionCompleted completed={completed} title="Address">
            <AddressEditor
              {...props}
              handleUpdateSuccess={handleUpdateSuccess}
            />
          </UIAccordionCompleted>
        </div>
      );
    case "naics_codes":
      return (
        <div className="mb-5">
          <UIAccordionCompleted completed={completed} title="Naics Code">
            <NaicsCodeEditor
              {...props}
              handleUpdateSuccess={handleUpdateSuccess}
              clearCompleted={clearCompleted}
            />
          </UIAccordionCompleted>
        </div>
      );
    default:
      return null;
  }
};

export default EditEligibilityField;
