import React from "react";
import Select from "react-select";

const ProjectManagerSelect = (props) => {
  const { projectManagers, filter, isUpdating, value, onChange } = props;

  const handleManagerChange = (selectedOption) => {
    if (isUpdating) {
      // Update the project's manager
      const updatedManager = selectedOption ? selectedOption.value : null;
      onChange(updatedManager);
    } else {
      // Handle the filter behavior
      onChange(selectedOption);
    }
  };

  return (
    <Select
      options={projectManagers}
      value={value}
      isClearable
      className="ui-multi-filter"
      placeholder="Project manager"
      onChange={handleManagerChange}
    />
  );
};

export default ProjectManagerSelect;
