import React from 'react';

const IncentiveOffer = ({ offer }) => {
  return (
    <div className='panel'>
      <div className='p-4'>
        <div dangerouslySetInnerHTML={{__html: offer.content}} />
      </div>
    </div>
  )
}

export default IncentiveOffer;
