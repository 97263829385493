import axios from 'lib/axios'

export const fetchAdminFileCategories = (params = {}) => {
  let url = `/admin/file_categories`;

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const createIncentiveFileCategory = (params = {}) => {
  let url = '/admin/incentive_file_categories'

  return axios
    .post(url, params )
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });     
}

export const updateIncentiveFileCategory = (params = {}) => {
  let url = `/admin/incentive_file_categories/${params.id}`

  return axios
    .put(url, params )
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });     
}

export const deleteIncentiveFileCategory = (id) => {
  let url = `/admin/incentive_file_categories/${id}`

  return axios
    .delete(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });     
}
