import React, { useEffect } from "react";
import { useSetState, useStateful } from "react-hanger";
import { UILabelInput } from "components/ui";

import {
  createIncentiveUrl,
  updateIncentiveUrl,
  deleteIncentiveUrl,
} from "modules/incentive-urls";

import { flashSuccess, flashWarning } from "lib/flash";

const shurley = require("shurley");

const IncentiveUrlRow = (props) => {
  const { url } = props;

  const initializeViewState = () => {
    if (props.add) return "edit";
    return "preview";
  };

  const viewState = useStateful(initializeViewState());
  const { state: urlState, setState: setUrlState } = useSetState(url);

  useEffect(() => {
    setUrlState(url);
  }, [url]);

  const handleFieldChange = (field, value) => {
    setUrlState({
      [field]: value,
    });
  };

  const handleMouseEnter = (e) => {
    viewState.setValue("hover");
  };

  const handleMouseLeave = (e) => {
    viewState.setValue("preview");
  };

  const handleEdit = (e) => {
    e.preventDefault();
    viewState.setValue("edit");
  };

  const handleDeleteRequest = (e) => {
    e.preventDefault();

    if (props.add) {
      viewState.setValue("preview");
      return props.resetNewUrl();
    }

    if (!url._new && props.incentiveId) {
      viewState.setValue("delete");
    } else {
      props.removeUrl(props.index);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    deleteIncentiveUrl(urlState).then((response) => {
      flashWarning(`${urlState.url} deleted.`);
      viewState.setValue("preview");
      props.removeUrl(props.index);
    });
  };

  const handleCancelDelete = (e) => {
    e.preventDefault();
    viewState.setValue("edit");
  };

  const handleSave = (e) => {
    e.preventDefault();

    // No url provided to save
    // TODO: provide proper validation
    if (!urlState.url || urlState.url === "") return;

    if (props.incentiveId) {
      // Need to persist to backend first
      if (urlState._new || props.add) {
        const params = {
          incentive_url: {
            ...urlState,
          },
          incentive_id: props.incentiveId,
        };

        delete params.incentive_url._new;

        createIncentiveUrl(params).then((res) => {
          props.addUrl(res);
          flashSuccess(`${urlState.url} added to incentive.`);
          viewState.setValue("preview");
        });
      } else {
        updateIncentiveUrl(urlState).then(() => {
          props.updateUrl(urlState);
          flashSuccess(`${urlState.url} updated.`);

          viewState.setValue("preview");
        });
      }
    } else {
      if (props.add) {
        props.addUrl(urlState);
      } else {
        props.updateUrl(urlState);
      }

      viewState.setValue("preview");
    }
  };

  const cardClassName = () => {
    let cardClassName = "";

    if (urlState.is_broken) {
      cardClassName = "text-light bg-danger";
    } else if (urlState.title_updated) {
      cardClassName = "text-dark bg-warning";
    } else {
      cardClassName = "text-dark bg-light";
    }

    return `py-2 px-3 card ${cardClassName}`;
  };

  switch (viewState.value) {
    case "edit":
      return (
        <tr>
          <td width="170px">
            <UILabelInput
              label="UrlName"
              value={urlState.label}
              error={""}
              onChange={(value) => {
                handleFieldChange("label", value);
              }}
            />
          </td>
          <td>
            <UILabelInput
              label="UrlLink"
              value={urlState.url}
              error={""}
              onChange={(value) => {
                handleFieldChange("url", shurley.parse(value));
              }}
            />
          </td>
          <td
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "40px",
              width: "100px",
            }}
          >
            <div className="btn-group">
              <button
                className="btn btn-primary create-url"
                onClick={handleSave}
              >
                <i className="bi bi-check"></i>
              </button>

              <button className="btn btn-light" onClick={handleDeleteRequest}>
                <i className="bi bi-trash"></i>
              </button>
            </div>
          </td>
        </tr>
      );
    case "preview":
      return (
        <tr onMouseEnter={handleMouseEnter}>
          <td colSpan={3}>
            <div className={cardClassName()} style={{ height: "56px" }}>
              <table style={{ height: "56px" }} cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td className="align-middle" width="230px">
                      {urlState.label ? urlState.label : urlState.url}
                    </td>

                    <td>
                      {urlState.title_updated
                        ? " Title Recently Changed"
                        : null}
                      {url.is_broken ? " This URL needs to be updated." : null}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      );
    case "hover":
      return (
        <tr onMouseLeave={handleMouseLeave}>
          <td colSpan={3}>
            <div className={cardClassName()} style={{ height: "56px" }}>
              <table style={{ height: "56px" }} cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td className="align-middle" width="230px">
                      <a
                        href={urlState.url}
                        className={
                          urlState.is_broken ? "link-light" : "link-dark"
                        }
                        target="_blank"
                      >
                        {urlState.label ? urlState.label : urlState.url}
                      </a>
                    </td>

                    <td>
                      {urlState.title_updated
                        ? " Title Recently Changed"
                        : null}
                      {url.is_broken ? " This URL needs to be updated." : null}
                    </td>

                    <td>
                      <button
                        className="btn btn-dark float-end"
                        onClick={handleEdit}
                      >
                        <i className="bi bi-pencil-square"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      );
    case "delete":
      return (
        <tr className="table-warning">
          <td colSpan={2} className="align-middle">
            <h6 className="px-2 m-0">
              Delete {` ${urlState.label ? urlState.label : urlState.url} url?`}
            </h6>
          </td>
          <td>
            <div className="btn-group float-end p-2">
              <button className="btn btn-light" onClick={handleCancelDelete}>
                No
              </button>
              <button className="btn btn-dark" onClick={handleDelete}>
                Yes
              </button>
            </div>
          </td>
        </tr>
      );
    default:
      return null;
  }
};

export default IncentiveUrlRow;
