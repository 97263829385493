import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { exportToPdf as exportPdfUtil } from "lib/utils";

import ViewQuoteModal from "./view-quote-modal";

const ManageQuoteFromEstimate = (props) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const exportToPdf = () => {
    exportPdfUtil("modal-body", `ES-${props.project.name}.pdf`);
  };

  return (
    <>
      {!props.showCategoryPicker &&
        (props.quoteId ? (
          <>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 150, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" className="cashflow-tooltip">
                  View Quote
                </Tooltip>
              }
            >
              <button
                className="btn-attach float-end"
                onClick={handleShow}
                style={{ marginLeft: "4px" }}
              >
                <i className="bi bi-search"></i>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 150, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" className="cashflow-tooltip">
                  Click to update the quote with any recent estimate changes.
                </Tooltip>
              }
            >
              <button
                className="btn btn-action float-end"
                onClick={props.onGenerateQuote}
                style={{ marginLeft: "4px" }}
              >
                Refresh Quote
              </button>
            </OverlayTrigger>
          </>
        ) : (
          <button
            className="btn-attach float-end"
            onClick={props.onGenerateQuote}
            style={{ marginLeft: "4px" }}
          >
            Create Quote
          </button>
        ))}
      {props.children}

      <ViewQuoteModal
        show={show}
        handleClose={handleClose}
        exportToPdf={exportToPdf}
        project={props.project}
      />
    </>
  );
};

export default ManageQuoteFromEstimate;
