import React, { useState, useEffect, useRef } from "react";

// Third-party library
import { useSetState } from "react-hanger";
import { map, filter, snakeCase } from "lodash";

// Local components
import SearchBar from "./search-bar";
import FilterAssistant from "./filter-assistant";
import PersonalizedRecommendations from "./personalized-recommendations";
import IncentiveList from "./incentive-list";
import IncentiveModal from "./incentive-modal";
import {
  withUIPaginate,
  UIPaginationTotalSelector,
  UIPagination,
  UIMultiSelect,
} from "components/ui";
import StateSelector from "components/common/state-selector";

// Local utility/helper
import { fetchActiveIncentives } from "modules/dashboard/incentives";
import { flashError, flashSuccess } from "lib/flash";
import { setURLParams, getUrlParams } from "lib/utils";

const Incentives = (props) => {
  // Derived states from props
  const typeOptions = props.incentiveTypes.map((type) => {
    return { value: type.id, label: type.name };
  });
  const tagOptions = props.tags.map((tag) => {
    return { value: tag, label: tag };
  });
  const { pagination } = props;
  let categories = { 0: "All" };
  props.categories.map((category) => {
    categories[category.id] = category.name;
  });

  // URL Handling
  const url = new URL(window.location);

  // Refs
  const loggedOutDashboardURL = useRef(null);
  const isFirstRun = useRef(true);

  // State
  const [incentives, setIncentives] = useState(props.incentives);
  const [isLoading, setIsLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showIncentiveDetail, setShowIncentiveDetail] = useState(false);
  const [selectedIncentive, setSelectedIncentive] = useState(null);
  const [searchActivated, setSearchActivated] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("location");
  const [filtersVisible, setFiltersVisible] = useState(true);

  // Utility functions
  const setSelectedTypes = (types) => {
    if (!types || types.includes("undefined")) return [];
    let parsedTypes = types.map((v) => parseInt(v));
    return filter(typeOptions, (type) => parsedTypes.includes(type.value));
  };

  // Complex state management
  const { state, setState } = useSetState({
    query: getUrlParams(url, "query") || "",
    sortColumn: getUrlParams(url, "sortColumn") || "name",
    sortDirection: getUrlParams(url, "sortDirection") || "ASC",
    state: getUrlParams(url, "state")
      ? decodeURIComponent(getUrlParams(url, "state")).split(",")
      : props.default_region,
    types: setSelectedTypes(getUrlParams(url, "types[]", true)),
    tags: getUrlParams(url, "tags[]", true) || [],
    category_id: 0,
    company_dashboard_uuid: props.company_dashboard_uuid || null,
  });

  // Event handlers
  const handleSearchActivate = (query) => {
    setSearchActivated(query.length >= 2);
    setFiltersVisible(false);
  };

  const handleClose = () => setShowIncentiveDetail(false);
  const handleShow = (incentive) => {
    setSelectedIncentive(incentive);
    setShowIncentiveDetail(true);
  };

  const handleSelectState = (selectedState) => {
    setState((prevState) => {
      const currentStates = prevState.state.includes(selectedState)
        ? prevState.state.filter((state) => state !== selectedState)
        : [...prevState.state, selectedState];
      return { ...prevState, state: currentStates };
    });
    setSearchActivated(true);
  };

  const handleSelectType = (selectedType) => {
    setState((prevState) => {
      const currentTypes = Array.isArray(prevState.types)
        ? prevState.types
        : [];
      const newTypes = currentTypes.includes(selectedType.value)
        ? currentTypes.filter((type) => type !== selectedType.value)
        : [...currentTypes, selectedType.value];
      return { ...prevState, types: newTypes };
    });
    setSearchActivated(true);
  };

  const handleSelectPurpose = (selectedPurpose) => {
    setState((prevState) => ({
      ...prevState,
      query: prevState.query === selectedPurpose ? "" : selectedPurpose,
    }));
    setSearchActivated(true);
  };

  //TODO: Determine interaction model for AND queries
  const resetFilters = (activeFilter) => {
    setState((prevState) => {
      const newState = { ...prevState };
      if (activeFilter !== "state") {
        newState.state = props.user_state
          ? [props.user_state]
          : props.default_region;
      }
      if (activeFilter !== "type") {
        newState.types = [];
      }
      if (activeFilter !== "purpose") {
        newState.query = "";
      }
      return newState;
    });
  };

  //TODO: Make this into a helper function
  const handleCopyUrl = () => {
    const baseUrl = "subcity.com/incentives";
    loggedOutDashboardURL.current = props.company_dashboard_uuid
      ? `${baseUrl}/${props.company_dashboard_uuid}/dashboard`
      : baseUrl;

    navigator.clipboard
      .writeText(loggedOutDashboardURL.current)
      .then(() => {
        flashSuccess("URL copied to clipboard");
      })
      .catch((err) => {
        console.error("Could not copy URL", err);
      });
  };

  // Data fetching
  const fetchIncentives = (params = {}) => {
    fetchActiveIncentives(params).then((data) => {
      props.updatePagination({
        total: data.pagination.total,
        first_load: true,
      });

      setIncentives(data.incentives);
    });
  };

  const callFetchIncentives = () => {
    const requestParams = {
      ...state,
      ...pagination,
      sort_column: snakeCase(state.sortColumn),
      sort_direction: state.sortDirection,
      types: Array.isArray(state.types)
        ? state.types.filter((t) => t !== undefined)
        : [],
      tags: state.tags,
    };

    if (state.company_dashboard_uuid) {
      requestParams.company_dashboard_uuid = state.company_dashboard_uuid;
    }

    // Properly encode state parameters
    if (requestParams.state) {
      requestParams.state = Array.isArray(requestParams.state)
        ? requestParams.state
        : [requestParams.state];
      requestParams.state = requestParams.state.map(encodeURIComponent);
    } else {
      delete requestParams.state;
    }

    fetchIncentives(requestParams);
  };

  // Use effects
  useEffect(() => {
    if (!getUrlParams(url, "state")) {
      const newState = props.user_state
        ? [props.user_state]
        : props.default_region;

      setState((prevState) => ({
        ...prevState,
        state: newState,
      }));
    }
    callFetchIncentives();
  }, []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    callFetchIncentives();
    setURLParams(state, pagination);
  }, [state, props.user_state]);

  useEffect(() => {
    if (pagination.first_load) return;
    callFetchIncentives();
    setURLParams(state, pagination);
  }, [pagination]);

  return (
    <>
      <div className="container">
        <div className="row justify-content-between align-items-center mt-3">
          {props.company_dashboard_uuid ? (
            <SearchBar
              state={state}
              setState={setState}
              updatePagination={props.updatePagination}
              onSearchActivate={handleSearchActivate}
              placeholder="Search by name or description"
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              handleCopyUrl={handleCopyUrl}
              isDashboard={true}
            />
          ) : (
            <div className="d-flex flex-column align-items-center">
              <SearchBar
                state={state}
                setState={setState}
                updatePagination={props.updatePagination}
                onSearchActivate={handleSearchActivate}
                placeholder="Search by name, description or eligibility criteria"
                background="white"
                size="large"
                isDashboard={false}
              />
              <FilterAssistant
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setFiltersVisible={setFiltersVisible}
                programPurposes={props.program_purposes}
                defaultRegion={props.default_region}
                user_state={props.user_state}
                typeOptions={typeOptions}
                onSelectState={handleSelectState}
                onSelectType={handleSelectType}
                onSelectPurpose={handleSelectPurpose}
                selectedState={state.state}
                selectedType={Array.isArray(state.types) ? state.types : []}
                selectedPurpose={state.query}
                resetFilters={resetFilters}
              />
            </div>
          )}
        </div>

        {showFilters && (
          <div className="row mt-2">
            <div className="col-md-12 d-flex mb-4">
              <StateSelector
                state={state.state}
                placeholder="State"
                onChange={(newState) => {
                  if (newState === "Filter by state") {
                    setState({ state: "" });
                  } else {
                    setState({ state: newState });
                  }
                }}
              />
              <div>
                <UIMultiSelect
                  isMulti={true}
                  options={typeOptions}
                  value={state.types}
                  onChange={(options) => setState({ types: options })}
                  className="ui-multi-filter"
                  placeholder="Type"
                />
              </div>
            </div>
            <hr />
          </div>
        )}
        {props.company_dashboard_uuid && (
          <PersonalizedRecommendations
            incentives={incentives}
            userSignedIn={props.user_signed_in}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )}
        <>
          {props.company_dashboard_uuid && (
            <div className="mb-3 mt-5">
              <h4>
                <strong>Browse additional programs</strong>
              </h4>
            </div>
          )}
          <IncentiveList incentives={incentives} handleShow={handleShow} />
          <div className="incentive-table">
            <div className="row">
              <div className="col-md-4">
                <div className="mt-4">
                  <UIPaginationTotalSelector
                    perPage={pagination.per_page}
                    changePerPage={(value) => {
                      props.updatePagination({
                        per_page: value,
                        first_load: false,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="col-md-8">
                <span className="float-end mt-2">
                  {pagination.total > 0 ? (
                    <UIPagination
                      page={pagination.page}
                      perPage={pagination.per_page}
                      changePage={(page) => {
                        props.updatePagination({
                          page: page.selected + 1,
                          first_load: false,
                        });
                      }}
                      total={pagination.total}
                    />
                  ) : null}
                </span>
              </div>
            </div>
          </div>
        </>
        <IncentiveModal
          incentive={selectedIncentive}
          isOpen={showIncentiveDetail}
          onClose={handleClose}
          user_signed_in={props.user_signed_in}
        />
      </div>
    </>
  );
};

export default withUIPaginate(Incentives);
