import React, { useState } from 'react';

import { UIRadioButton } from 'components/ui'

const Question = (props) => {
  return (
    <>
    <div className='question'>
      <div className='row'>
        <div className='col-md-6'>
          <p className='title'>{ props.title }</p>
        </div>

        <div className='col-md-6'>
          <UIRadioButton 
            label="Yes" 
            name={props.title} 
            checked={props.currentAnswer === 'Yes'} 
            handleClick={props.updateCurrentAnswer} 
          />
          <UIRadioButton 
            label="No"
            name={props.title} 
            checked={props.currentAnswer === 'No'} 
            handleClick={props.updateCurrentAnswer} 
          />
        </div>
      </div>
    </div>
    { props.shouldRenderChild ? props.children : null }
    </>
  )
}

export default Question;
