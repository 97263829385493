import React, { useState, useEffect } from "react";
import Form from "./form";
import StyleGuideLink from "../incentives/common/style-guide-link";

import { createFunnel, formatFunnelIncentive } from "modules/funnels";

const New = (props) => {
  const [onboarding, setOnboarding] = useState([]);
  const [funnel, setFunnel] = useState({
    title: "",
    content: "",
    summary: "",
    meta_description: "",
    onboarding_flow_id: null,
    layout: "",
    permalink: "",
    status: "draft",
    funnel_incentives_attributes: [],
  });

  useEffect(() => {
    setOnboarding(
      props.onboarding.map((flow) => {
        return {
          value: flow.id,
          name: flow.name,
        };
      })
    );
  }, []);

  function updateFunnel(field, value) {
    setFunnel({
      ...funnel,
      [field]: value,
    });
  }

  function handleCreateFunnel(e) {
    e.preventDefault();
    createFunnel({ funnel })
      .then((response) => {
        window.location = "/admin/funnels";
      })
      .catch((error) => {});
  }

  return (
    <div className="panel-body">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Create New Funnel</h3>
        <StyleGuideLink CTA="How to" guideType="microSite" />
      </div>

      <div className="row">
        <div className="col-lg-12">
          <Form
            funnel={funnel}
            updateFunnel={updateFunnel}
            setFunnel={setFunnel}
            handleSubmit={handleCreateFunnel}
            layouts={props.layouts}
            onboarding={onboarding}
            saveText="Create"
            incentives={props.incentives}
            isNew={true}
          />
        </div>
      </div>
    </div>
  );
};

export default New;
