import React, { useEffect } from 'react'
import { useSetState } from 'react-hanger';
import UILabelInput from 'components/ui/ui-label-input';

const MetaFieldForm = ({ fieldTypes, metaEligibilityField, onSaveField, onRemoveField, showAddButton, tableSchema }) => {
  const { state: formVariables, setState: setFormVariables } = useSetState({
    id: metaEligibilityField.id,
    label: metaEligibilityField.label,
    parent_id: metaEligibilityField.parent_id,
    value: metaEligibilityField.value,
    field_type: metaEligibilityField.type || "string",
    is_active: metaEligibilityField.is_active,
    is_derived: metaEligibilityField.is_derived,
    table: metaEligibilityField.table,
    column: metaEligibilityField.column
  })

  const tables = tableSchema.map((obj) => obj.table )
  let columns = {}

  for(var i = 0; i < tableSchema.length; i++) {
    const entry = tableSchema[i];
    columns[entry.table] = entry.columns
  }

  function renderColumnSelect() {
    return (
      <div className="row">
        <div className="field mb-3">
          <strong>
            <label htmlFor="column" className="mb-1">
              Column
            </label>
          </strong>
          <div>
            <select
              id="column"
              className="form-control"
              name='Column Select'
              value={formVariables.column}
              onChange={(e) => {
                setFormVariables({ column: e.target.value });
              }}
            >
              <option disabled value> -- select an option -- </option>
              {columns[formVariables.table].map((column) => {
                return (
                  <option key={column} value={column}>
                    { column }
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if(!showAddButton) return

    if (["object", "array"].includes(formVariables.field_type))
      showAddButton.setTrue()
    else
      showAddButton.setFalse()

  }, [formVariables])

  return (
    <>
      <div className="field mb-3">
        <UILabelInput
          label="Label"
          name='Label'
          value={formVariables.label}
          onChange={(value) => {
            setFormVariables({ label: value });
          }}
        />
      </div>

      <div className="field mb-3">
        <UILabelInput
          label="Value"
          name='Value'
          value={formVariables.value}
          onChange={(value) => {
            setFormVariables({ value: value });
          }}
        />
      </div>

      <div className="row">
        <div className="field mb-3">
          <strong>
            <label htmlFor="legal_structure" className="mb-1">
              Field Type
            </label>
          </strong>
          <div>
            <select
              className="form-control"
              name='Type Select'
              value={formVariables.field_type}
              onChange={(e) => {
                setFormVariables({ field_type: e.target.value });
              }}
            >
              <option disabled value> -- select an option -- </option>
              {fieldTypes.map((fieldType) => {
                return (
                  <option key={fieldType} value={fieldType}>
                    {fieldType}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="field mb-3">
          <strong>
            <label htmlFor="table" className="mb-1">
              Table
            </label>
          </strong>
          <div>
            <select
              id="table"
              className="form-control"
              name='Table Select'
              value={formVariables.table}
              onChange={(e) => {
                setFormVariables({ table: e.target.value });
              }}
            >
              <option disabled selected value> -- Select an option -- </option>
              {tables.map((table) => {
                return (
                  <option key={table} value={table}>
                    {table}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      { formVariables.table ? renderColumnSelect() : null }

      <div className="mb-2">
        <label htmlFor={`is-active-${metaEligibilityField.id}`}>
          <input
            type="checkbox"
            id={`is-active-${metaEligibilityField.id}`}
            className="me-2"
            checked={formVariables.is_active}
            onChange={(e) => {
              setFormVariables({
                is_active: formVariables.is_active ? false : true
              });
            }}
          />
          <strong>Is active</strong>
        </label>
      </div>

      <div className="d-flex justify-content-end">
        { metaEligibilityField.parent_id &&
          <>
            <button
              className="btn btn-light mx-1 my-3"
              onClick={() => onRemoveField(metaEligibilityField.id)}>
              Remove
            </button>
          </> }
          <button className="btn btn-dark mx-1 my-3" onClick={() => onSaveField(formVariables)}>
            Save
          </button>
      </div>
    </>
  )
}

export default MetaFieldForm
