import React, { createContext } from "react";
import { useArray, useSetState } from "react-hanger";
import { find } from "lodash";

import {
  updateAdminCompany,
  removeUserCompany,
  addUserCompany,
  updateUserCompany,
  updateUserCompanyNewRole,
  deleteCompanyAddress,
  createCompanyAddress,
  updateCompanyAddress,
} from "modules/companies";

import { getDate } from "lib/utils";
import { flashError, flashWarning, flashSuccess } from "lib/flash";

export const EditCompanyContext = createContext({
  addresses: null,
  onDeleteAddress: null,
  onAddressUpdate: null,
  onAddUser: null,
  onRemoveUser: null,
  toggleUserAdmin: null,
  onSaveAddress: null,
  onUpdateCompany: null,
  basicInfo: null,
  setBasicInfo: null,
  defaultNaicsOption: null,
  users: null,
});

const EditCompanyContextProvider = ({ children, company }) => {
  const addresses = useArray(company.addresses);
  const users = useArray(
    company.users.map((user) => ({ ...user, job_title: user.job_title }))
  );
  const { state: basicInfo, setState: setBasicInfo } = useSetState({
    id: company.id,
    name: company.name,
    date_established: getDate(company.date_established),
    naics_code: company.naics_code,
    ein: company.ein,
    number_of_employees: company.number_of_employees,
    annual_revenue: company.annual_revenue,
    description: company.description,
    duns_number: company.duns_number,
    business_entity_id: company.business_entity_id,
    naics_code_ids: company.naics_code_ids,
    minority_owned: company.minority_owned,
    woman_owned: company.woman_owned,
    veteran_owned: company.veteran_owned,
    commission_rate: company.commission_rate,
    website: company.website,
    accounting_software: company.accounting_software,
    is_active: company.is_active,
    company_category: company.company_category,
    onboarding_flow_id: company.onboarding_flow_id,
    state_taxpayer_id: company.state_taxpayer_id,
    registry_id: company.registry_id,
    narrative: company.narrative,
    uuid: company.uuid,
    referral_source_id: company.referral_source_id,
    referral_source_name: company.referral_source_name,
  });

  let defaultNaicsOption = [];
  {
    company.naics_codes &&
      company.naics_codes.map((naics_code) => {
        defaultNaicsOption.push({
          value: naics_code === null ? "" : naics_code.id,
          label:
            naics_code === null ? "" : `${naics_code.code} ${naics_code.title}`,
        });
      });
  }

  const onUpdateCompany = (currentBasicInfo) => {
    const { referral_source_name, ...updateData } = currentBasicInfo;

    return updateAdminCompany({ company: updateData, id: company.id })
      .then((res) => {
        flashSuccess("Info saved");
        return Promise.resolve();
      })
      .catch((res) => {
        if (res.errors) {
          res.errors.forEach((error) => {
            flashError(error);
          });
        } else {
          flashError("An error occurred while updating the company.");
        }
        return Promise.reject();
      });
  };

  const onAddressUpdate = (address) => {
    const existing = find(addresses.value, { id: address.id });
    if (existing) {
      addresses.modifyById(address.id, address);
    } else {
      addresses.push(address);
    }
    if (address.is_primary) {
      addresses.value.map((address) => {
        addresses.modifyById(address.id, { ...address, is_primary: false });
      });
      addresses.modifyById(address.id, {
        ...address,
        is_primary: true,
      });
    }
    flashSuccess("Address saved");
  };

  const onSaveAddress = (address) => {
    if (address.id === null) {
      return createCompanyAddress({
        address: address,
        company_id: company.id,
      })
        .then((resp) => {
          onAddressUpdate(resp);
          return Promise.resolve();
        })
        .catch((resp) => {
          resp.errors.map((error) => {
            flashError(error);
          });
          return Promise.resolve();
        });
    } else {
      return updateCompanyAddress({
        address: address,
        company_id: company.id,
      })
        .then((resp) => {
          onAddressUpdate(address);
          return Promise.resolve();
        })
        .catch((resp) => {
          resp.errors.map((error) => {
            flashError(error);
          });
          return Promise.resolve();
        });
    }
  };

  const onAddUser = (user) => {
    addUserCompany({
      user_company: { ...user, user_id: user.id, role_id: 2 },
      company_id: company.id,
    })
      .then((resp) => {
        users.push(resp);
        flashSuccess("User Added");
      })
      .catch((resp) => {
        resp.errors.map((error) => {
          flashError(error);
        });
        return Promise.resolve();
      });
  };

  const updateUserCompanyRole = (user, role) => {
    updateUserCompanyNewRole({
      user_company: {
        ...user,
        user_id: user.user_id,
      },
      role_id: role.id,
      company_id: company.id,
    })
      .then((resp) => {
        users.modifyById(user.id, {
          ...user,
          role: role.name,
          role_id: role.id,
        });
        flashSuccess("User role updated");
      })
      .catch((resp) => {
        resp.errors.map((error) => {
          flashError(error);
        });
        return Promise.resolve();
      });
  };

  const updateUserCompanyJobTitle = (user, newJobTitle, onSuccess) => {
    const userCompanyParams = {
      user_company: {
        id: user.id,
        user_id: user.user_id,
        company_id: company.id,
        job_title: newJobTitle,
      },
    };

    updateUserCompany(userCompanyParams)
      .then((updatedUserCompany) => {
        users.modifyById(user.id, { ...user, job_title: newJobTitle });
        if (onSuccess) {
          onSuccess(updatedUserCompany);
        }
        flashSuccess("Job title updated successfully");
      })
      .catch((error) => {
        flashError("Failed to update job title");
      });
  };

  const onRemoveUser = (user) => {
    removeUserCompany({
      user_company: { ...user, user_id: user.id },
      company_id: company.id,
    })
      .then(() => {
        users.removeById(user.id);
        flashWarning("User removed");
      })
      .catch((resp) => {
        resp.errors.map((error) => {
          flashError(error);
        });
        return Promise.resolve();
      });
  };

  const onDeleteAddress = (address) => {
    deleteCompanyAddress({
      address: address,
      company_id: company.id,
    })
      .then(() => {
        flashWarning("Address removed");
        addresses.removeById(address.id);
        return Promise.resolve();
      })
      .catch((resp) => {
        resp.errors.map((error) => {
          flashError(error);
        });
        return Promise.resolve();
      });
  };

  const context = {
    addresses,
    onDeleteAddress,
    onAddressUpdate,
    onAddUser,
    onRemoveUser,
    users,
    onSaveAddress,
    updateUserCompanyRole,
    updateUserCompanyJobTitle,
    onUpdateCompany,
    basicInfo,
    setBasicInfo,
    defaultNaicsOption,
  };

  return (
    <EditCompanyContext.Provider value={context}>
      {children}
    </EditCompanyContext.Provider>
  );
};

export default EditCompanyContextProvider;
