import React, { useState, useEffect } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import moment from "moment";
import AccountCard from "./account-card";
import CashSummary from "./cash-summary";

const Accounts = (props) => {
  const { offers } = props;
  const [accounts, setAccounts] = useState(props.accounts);
  const [offer, setOffer] = useState({});

  const VALID_OFFERS = ["Loan Financing"];
  const accountHoverText = props.plaid_connected
    ? "Account balances come from your connected banks. Add additional bank connections in settings."
    : `Account balances are imported from accounting software. For real-time updates, connect your bank using the link below.`;

  const renderTooltip = (props) => (
    <Tooltip id="accounts-tooltip" {...props} className="cashflow-tooltip">
      {accountHoverText}
    </Tooltip>
  );

  if (
    // !props.plaid_connected && // TODO: Previously, we used to check if Plaid connected, but, now that we're getting bank account information from Codat too, we should check whether there are any accounts at all.
    props.accounts.depository.accounts.length == 0 &&
    props.accounts.credit.accounts.length == 0 &&
    props.accounts.loan.accounts.length == 0
  ) {
    return (
      <div className="panel">
        <div className="custom-padding px-4">
          <h5>
            <span title={accountHoverText}>Account Balances</span>
          </h5>

          <p>
            Please connect your{" "}
            <a href="/company/edit#/bank_accounts">bank account</a> and/or{" "}
            <a href="/company/edit#/integrations">accounting software</a> to see{" "}
            a list of available bank balances.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="panel ">
      <div className="custom-padding px-4">
        <h5 className="accounts-header">
          <>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <span>Account Balances</span>
            </OverlayTrigger>
            <span className="float-end">
              <a href="/company/edit#/bank_accounts">
                <i className="bi bi-gear"></i>
              </a>
            </span>
          </>
        </h5>
        {props.last_synced && (
          <p className="secondary-text">
            last updated: {moment(props.last_synced).fromNow()}
          </p>
        )}
      </div>
      <div className="px-4">
        <AccountCard
          title="Cash"
          total={accounts.depository.total}
          accounts={accounts.depository.accounts}
          offer={null}
        />
        <AccountCard
          title="Credit Lines"
          total={accounts.credit.total}
          accounts={accounts.credit.accounts}
          offer={null}
        />
        <AccountCard
          title="Loans"
          total={accounts.loan.total}
          accounts={accounts.loan.accounts}
          offer={offers["Loan Financing"]}
          className="border-bottom-custom"
        />
        <div className="d-flex justify-content-start mt-4 mb-5">
          <div className="ms-4">
            <a
              href={
                props.plaid_connected
                  ? "/company/edit#/bank_accounts"
                  : "/company/edit#/bank_accounts_direct"
              }
              className="add-account-link"
            >
              {props.plaid_connected
                ? "Add a bank account or credit line"
                : "Securely link your bank account for more up-to-date balances"}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accounts;
