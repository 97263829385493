import React from 'react';
import { prettyDisplayPrice, truncateString } from "lib/string";

const AccountItem = (props) => {
  let institution = props.institution || ""
  institution = institution.split(" - ")[0]

  return (
    <div className='account-item row'>
      <div className='col-md-8'>
        <div className='account-name'>
          { props.name }
        </div>

        <div className='account-institution'>
          { institution }
        </div>
      </div>

      <div className='col-md-4'>
        <div className='float-end'>
          { prettyDisplayPrice(props.total) }
        </div>
      </div>
    </div>
  )
}

export default AccountItem;
