import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

const NavigationButtons = ({ previousRoute, nextRoute, allowSkip }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    if (nextRoute === "/onboarding/complete") {
      event.preventDefault();
      setLoading(true);
      document.querySelector("form").submit();
    }
  };

  return (
    <div className="form-group d-flex justify-content-end">
      <div className="row mt-3">
        <div className="col">
          {previousRoute && (
            <a
              href={previousRoute}
              className="btn btn-light btn-large my-3 mb-4 px-4 me-2"
              dangerouslySetInnerHTML={{
                __html: '<i class="bi bi-chevron-left"></i> Back',
              }}
            />
          )}
          {allowSkip && (
            <a
              href={nextRoute}
              className="btn btn-light btn-large my-3 mb-4 px-4 me-2"
            >
              Skip
            </a>
          )}
          <button
            className="btn btn-large btn-action my-3 mb-4 px-4 next-button"
            type="submit"
            onClick={handleClick}
            disabled={loading}
          >
            {loading ? (
              <>
                Generating Report (may take several seconds)
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  className="ms-2"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </>
            ) : (
              <>
                {nextRoute === "/onboarding/complete" ? "Complete" : "Next"}
                <span className="bi bi-caret-right-fill icon-next"></span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

NavigationButtons.propTypes = {
  previousRoute: PropTypes.string,
  nextRoute: PropTypes.string.isRequired,
  allowSkip: PropTypes.bool,
};

export default NavigationButtons;
