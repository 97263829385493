import React from 'react';
import FillableAddressSection from 'components/common/fillable-address-section'
import { UIRadioCard } from 'components/ui'

const BillingAndShippingDetails = ({ 
  invoice, 
  setInvoice, 
  setShowBillingDetails, 
  showBillingDetails, 
  billingShippingEqual,
  setBillingShippingEqual
}) => {
  const vendor_details = invoice.vendor_details || {};
  const customer_details = invoice.customer_details || {}

  let shipping = null
  if (billingShippingEqual) {
    shipping = customer_details 
  } else {
    shipping = invoice.shipping_details
  }

  function requiredFieldText() {
    return null;
  }

  function toggleBillingDetails(e) {
    e.preventDefault()
    setShowBillingDetails(!showBillingDetails)
  }

  if(!showBillingDetails) {
    return (
      <>
        <div className='row mt-3'>
          <div className='col-md-12'>
            <button className='float-end btn btn-muted' onClick={toggleBillingDetails}>
              <i className="bi bi-pencil-square"></i>
            </button>
          </div>
        </div>
        <div className='row mt-1 mb-3'>
          <div className='col-md-4'>
            <h5><strong>From</strong></h5>
            <hr/>
            {
              vendor_details.name && vendor_details.address_line_1 ?
                <address>  
                  <strong>{vendor_details.name}</strong><br/>
                  {vendor_details.address_line_1}<br/>
                  {vendor_details.address_line_2 ? ( <>{vendor_details.address_line_2} <br/></> ) : null}
                  {vendor_details.city}, {vendor_details.state} { vendor_details.postal_code }<br/>
                  {vendor_details.country}
                </address> : 
                <a href="" className='link' onClick={toggleBillingDetails}>
                  Add Vendor Details
                </a>
            }
          </div>
          <div className='col-md-4'>
            <h5><strong>Bill To</strong></h5>
            <hr/>

            {
              customer_details.name && customer_details.address_line_1 ?
                <address>  
                  <strong>{ customer_details.name}</strong><br/>
                  { customer_details.address_line_1}<br/>
                  { customer_details.address_line_2 ? ( <>{ customer_details.address_line_2} <br/></> ) : null}
                  { customer_details.city}, { customer_details.state } {  customer_details.postal_code }<br/>
                  { customer_details.country }
                </address> :
               <a href="" className='link' onClick={toggleBillingDetails}>
                 Add Customer Details
               </a> 
            }

          </div>

          <div className='col-md-4'>
            <h5><strong>Ship To</strong></h5>
            <hr/>

            {
              shipping.name && shipping.address_line_1 ?
                <address>  
                  <strong>{ shipping.name}</strong><br/>
                  { shipping.address_line_1}<br/>
                  { shipping.address_line_2 ? ( <>{ shipping.address_line_2} <br/></> ) : null}
                  { shipping.city}, { shipping.state } {  customer_details.postal_code }<br/>
                  { shipping.country }
                </address> :
               <a href="" className='link' onClick={toggleBillingDetails}>
                 Add Customer Details
               </a> 
            }
          </div>
        </div>

        <hr/>
      </>
    )
  }

  return (
    <>
      <div className='row mt-3'>
        <div className='col-md-12'>
          <button className='float-end btn btn-muted' onClick={toggleBillingDetails}>
            <i className="bi bi-x-circle"></i>
          </button>
        </div>
      </div>

      <div className='row mt-1 mb-5'>
        <div className='col-md-6'>
          <FillableAddressSection
            title="From"
            field="vendor_details"
            purchase={invoice}
            updatePurchase={setInvoice}
            requiredFieldText={requiredFieldText}
          />
        </div>

        <div className='col-md-6'>
          <FillableAddressSection
            title="Bill To"
            field="customer_details"
            purchase={invoice}
            updatePurchase={setInvoice}
            requiredFieldText={requiredFieldText}
          />

          { !billingShippingEqual &&
              <FillableAddressSection
                title="Ship To"
                field="shipping_details"
                purchase={invoice}
                updatePurchase={setInvoice}
                requiredFieldText={requiredFieldText}
              />
          }

          <UIRadioCard
            checked={billingShippingEqual}
            label='Billing and shipping the same'
            id='billing-shipping-equal'
            name='Billing and shipping the same'
            handleClick={() => {
              const newState = !billingShippingEqual;
              setBillingShippingEqual(newState)
              if(!newState) {
                setInvoice({ ...invoice, shipping:  vendor_details })
              }
            }}
          />
        </div>
      </div>
      <hr/>
    </>
  )
}

export default BillingAndShippingDetails;