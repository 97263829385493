import React, { useState, useEffect } from "react";
import { updateAdminIncentive } from "modules/incentives";
import { flashSuccessLater } from "../../../../lib/flash";
import TextareaAutosize from "react-textarea-autosize";
import { UIFormLabel } from "components/ui";

const Compliance = ({ incentive }) => {
  const [compliance, setCompliance] = useState(incentive.compliance);

  useEffect(() => {
    setCompliance(incentive.compliance);
  }, [incentive]);

  const onUpdate = () => {
    updateAdminIncentive({ incentive: { compliance }, id: incentive.id })
      .then(() => {
        flashSuccessLater("incentive updated");
        location.reload(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="col-md-12 mb-4">
      <div className="mb-3">
        <UIFormLabel label="Compliance" />
        <TextareaAutosize
          type="text"
          name="Compliance"
          className="form-control"
          placeholder="Enter description here..."
          value={compliance || ""}
          onChange={(e) => {
            e.preventDefault();
            setCompliance(e.target.value);
          }}
        />
      </div>
      <div className="float-end mt-3 btn-group">
        <button className="btn btn-dark" onClick={onUpdate}>
          Update
        </button>
      </div>
    </div>
  );
};

export default Compliance;