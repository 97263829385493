import React from 'react';

import { UISelect } from 'components/ui'

const TransactionTypeSelect = (props) => {
  const options = [{
    name: 'Revenue',
    value: 'revenue'
  }, {
    name: 'Direct Cost',
    value: 'direct_cost'
  }, {
    name: 'Indirect Cost',
    value: 'indirect_cost'
  }, {
    name: 'Pass Through Cost',
    value: 'pass_through_cost'
  }]

  return (
    <UISelect
      options={options}
      name="Transaction Type"
      currentOption={props.transactionType}
      handleChange={props.setTransactionType}
    />
  )
}

export default TransactionTypeSelect;
