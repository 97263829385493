import React, { useState } from "react";
import { capitalize, without, words } from "lodash";

const QualificationCard = ({
  incentive_qualification,
  removeQualification,
  updateAnswerOptions,
  updateIncentiveQualification,
}) => {
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  const onAcceptAnswer = (qualificationAnswerOptionId, checked) => {
    const acceptableAnswerOptionIds =
      incentive_qualification.acceptable_answer_option_ids || [];
    let newOptionIds;

    if (checked)
      newOptionIds = [
        ...acceptableAnswerOptionIds,
        qualificationAnswerOptionId,
      ];
    else
      newOptionIds = without(
        incentive_qualification.acceptable_answer_option_ids,
        qualificationAnswerOptionId
      );

    updateAnswerOptions(incentive_qualification.id, newOptionIds);
  };

  const setIsDisqualfying = (incentive_qualification, checked) => {
    updateIncentiveQualification(incentive_qualification, {
      is_disqualifying: checked,
    });
  };

  return (
    <div
      className="card p-3 mb-3"
      onMouseEnter={() => setShowDeleteIcon(true)}
      onMouseLeave={() => setShowDeleteIcon(false)}
    >
      <div className="row">
        <div className="col-1">
          <h1>
            <i className="bi bi-grip-vertical text-muted qualification-handle"></i>
          </h1>
        </div>

        <div className="col">
          <div className="row">
            <div className="col">
              <strong>
                Question Title (Level:
                {incentive_qualification.qualification.level})
              </strong>
              <div>{incentive_qualification.qualification.question_title}</div>
            </div>
            <div className="col-md-4">
              <strong>Answer Type</strong>
              <div>
                {capitalize(
                  words(incentive_qualification.qualification.answer_type).join(
                    " "
                  )
                )}
              </div>
            </div>

            <div className="py-2">
              {incentive_qualification.qualification_answer_options.map(
                (qualification_answer_option, index) => (
                  <div key={index}>
                    <label
                      className="card p-2 my-2 d-flex flex-row align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="checkbox"
                        checked={
                          incentive_qualification.acceptable_answer_option_ids?.includes(
                            qualification_answer_option.id
                          ) || false
                        }
                        onChange={(e) => {
                          onAcceptAnswer(
                            qualification_answer_option.id,
                            e.target.checked
                          );
                        }}
                      />
                      <div className="ms-2">
                        {qualification_answer_option.option_text}
                      </div>
                    </label>
                  </div>
                )
              )}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <strong>Branching Logic</strong>
              <br />
              <div
                key={`incentive-qual-${incentive_qualification.id}-${incentive_qualification.is_disqualifying}`}
              >
                <input
                  type="checkbox"
                  checked={incentive_qualification.is_disqualifying || false}
                  onChange={(e) => {
                    setIsDisqualfying(
                      incentive_qualification,
                      e.target.checked
                    );
                  }}
                />
                <label className="ms-2">Wrong answer disqualifies</label>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-1 col-md-2 align-self-center p-0">
          {showDeleteIcon && (
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-danger"
                style={{ borderRadius: "50%" }}
                onClick={() => removeQualification(incentive_qualification.id)}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QualificationCard;
