import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';

import { UIFormLabel, UILabelInputFormik, UIMoneyLabelInputFormik, UIAddressInputWithSuggestions, UIValidationError, UIYesNoInput } from 'components/ui';
import { updateIncentiveApplication, submitIncentiveApplication } from 'modules/applications';
import { flashError, flashSuccess } from "lib/flash";

const EmployeeRetentionTaxCredit = ({ companyAddress = {}, application, incentive }) => {
  const details = application.details || {}
  const [isReadonly, setIsReadonly] = useState(application.status != 'draft');
  const formRef = useRef();

  const { buildYup } = require("schema-to-yup");
  const jsonSchema = require('../../../../../models/schemas/application/employee_retention_tax_credit_application_details.json')
  const yupSchema = buildYup(jsonSchema);

  const AutoSave = ({ debounceMs = 500 }) => {
    const debouncedSubmit = useCallback(
      _.debounce((values) => {
        updateIncentiveApplication({ id: application.id, details: values}, incentive.slug)
      }, debounceMs),
      [debounceMs],
    );
  
    const { values } = useFormikContext();
    useEffect(() => debouncedSubmit(values), [debouncedSubmit, values]);
  
    return null;
  };

  return (
    <div className="panel p-3">
      <Formik
        initialValues={details}
        validationSchema={yupSchema}
        onSubmit={(values) => {
          setIsReadonly(true);

          submitIncentiveApplication({ id: application.id, details: values}, incentive.slug)
            .then(() => {
              flashSuccess("Success!");
            })
            .catch((error) => {
              setIsReadonly(false);
              console.error(error);
              if (Array.isArray(error)) {
                error = error.join("\n\n");
              }
              flashError("Error:\n" + error);
            });
        }}
      >
        {({
          errors,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          touched,
          validateForm,
          values,
        }) => {
          return (
            <div className="application-form-container">
              <Form ref={formRef} onSubmit={ e => { 
                e.preventDefault();

                validateForm().then((errors) => {
                  let keys = Object.keys(errors);

                  if (keys.length === 0) {
                    handleSubmit(values);
                  } else {
                    keys.forEach((key, index) => {
                      setFieldTouched(key, true, true);
                    });
                    flashError("Something's not right! Please review your input and resolve any errors.");
                  }
                });
              }} >
                <fieldset disabled={isReadonly}>
                  <div className="row">
                    <h3 className='panel-title'>Quarterly Gross Receipts</h3>
                    <p>
                      Please provide quarterly gross receipts net of returns and allowances
                      for each of the following quarters:
                    </p>
                    <table className="table">
                      <thead>
                        <tr>
                          <th></th>
                          {[...Array(4).keys()].map((quarterIndex) => 
                            <th key={`quarterly-receipts-table-header-${quarterIndex+1}`}>Q{quarterIndex+1}</th>)
                          }
                        </tr> 
                      </thead>
                      <tbody>
                        {
                          [2019,2020,2021].map((year) => {
                            return (
                              <tr key={`quarterly-receipts-year-${year}`}>
                                <td><UIFormLabel label={year} required/></td>
                                {[...Array(4).keys()].map((quarterIndex) => {
                                  return (
                                    <td key={`quarterly-receipts-${year}-${quarterIndex+1}`}>
                                      <UIMoneyLabelInputFormik
                                        name={`quarterly_receipts_q${quarterIndex+1}_${year}`}
                                      />
                                    </td>
                                  );
                                })}
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="row mt-5">
                    <h3 className='panel-title'>Business Address</h3>
                    <div className='ui-label-input'>
                      <UIFormLabel
                        label="Address Line 1"
                        required
                      />
                      <UIAddressInputWithSuggestions 
                        value={values.address_line_1}
                        onChange={(newAddressLine1) => setFieldValue('address_line_1', newAddressLine1)}
                        onSelectSuggestedAddress={(newAddress) => {
                          ["address_line_1", "address_line_2", "city", "state", "postal_code"].forEach((field) => {
                            if(field in newAddress) {
                              setFieldValue(`${field}`, newAddress[field]);
                            }
                          });
                        }}
                      />
                      {errors['address_line_1'] && touched['address_line_1'] && 
                        <UIValidationError message={errors['address_line_1'].replace('address_line_1', "This")} />
                      }
                    </div>
                    <UILabelInputFormik 
                      label="Address Line 2"
                      name="address_line_2"
                    />
                    <UILabelInputFormik 
                      label="City"
                      name="city"
                      required
                    />
                    <UILabelInputFormik 
                      label="State"
                      name="state"
                      required
                    />
                    <UILabelInputFormik 
                      label="Postal Code"
                      name="postal_code"
                      required
                    />
                  </div>
                  <div className="row mt-5">
                    <div className="col">
                      <h3 className="panel-title">Staff Size</h3>
                      <p>
                        Please provide the estimated number of employees you had (or <i>will</i> have) 
                        in each of the following years: 
                      </p>
                      <div className="row">
                        {[2019,2020,2021,2022].map((year) => {
                          return (
                            <div className="col" key={`num-employees-${year}`}>
                              <UILabelInputFormik 
                                label={year}
                                name={`num_employees_${year}`}
                                required
                              />
                            </div>
                          )}
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col">
                      <h3 className="panel-title">Paycheck Protection Program (PPP)</h3>
                      <UIYesNoInput
                        label="Did you receive a PPP1?"
                        name="ppp1_received"
                        required
                      />
                      {values.ppp1_received && 
                        <UIYesNoInput
                          label="Was your PPP1 forgiven?"
                          name="ppp1_forgiven"
                          required
                        />
                      }
                      <hr/>

                      <UIYesNoInput
                        label="Did you receive a PPP2?"
                        name="ppp2_received"
                        required
                      />
                      {values.ppp2_received && 
                        <UIYesNoInput
                          label="Was your PPP2 forgiven?"
                          name="ppp2_forgiven"
                          required
                        />
                      }
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col">
                      <h3 className="panel-title">Additional Information</h3>
                      <UIYesNoInput
                        label="Was your business impacted by the pandemic?"
                        name="was_impacted_by_pandemic"
                        required
                      />
                      <hr/>

                      <UIYesNoInput
                        label="Did your business shut down?"
                        name="did_shut_down"
                        required
                      />
                      {values.did_shut_down &&
                        <div className="row">
                          <div className="col">
                            <UILabelInputFormik 
                              label="Start"
                              type="date"
                              name="shut_down_start"
                              required
                            />
                          </div>
                          <div className="col">
                            <UILabelInputFormik 
                              label="End"
                              type="date"
                              name="shut_down_end"
                              required
                            />
                          </div>
                        </div>
                      }
                      <hr/>

                      <UIYesNoInput
                        label="Did your costs increase during the pandemic?"
                        name="did_costs_increase"
                        required
                      />
                      {values.did_costs_increase &&
                        [ <label><strong>Did any of the following costs increase?</strong></label>,
                          <UIYesNoInput
                            label="Supplies"
                            name="supplies_cost_increase"
                            required
                          />,
                          <UIYesNoInput
                            label="Payroll"
                            name="payroll_cost_increase"
                            required
                          />,
                          <UIYesNoInput
                            label="Technology"
                            name="technology_cost_increase"
                            required
                          />,
                          <div className="row">
                            <div className="col">
                              <UIFormLabel label="Other"/>
                            </div>
                            <div className="col">
                              <Field name="other_cost_increase" className="form-control" />,
                            </div>
                          </div>
                        ]
                      }
                      <hr/>

                      <UIYesNoInput 
                        label="Were you unable to perform the services/sell product in the same manner as pre pandemic?" 
                        name="was_unable_to_perform_services"
                        required
                      />
                      <hr/>

                      <UIYesNoInput 
                        label="Did you abandon any previous lines of business?" 
                        name="abandoned_previous_business"
                        required
                      />
                      {values.abandoned_previous_business &&
                        <div className="row">
                          <div className="col">
                            <UIFormLabel
                              label="What percentage of your business was this service/product line?"
                              required
                            />
                            {errors['percentage_of_business_abandoned'] && touched['percentage_of_business_abandoned'] && 
                              <UIValidationError message={errors['percentage_of_business_abandoned'].replace('percentage_of_business_abandoned', "This")} />
                            }
                          </div>
                          <div className="col">
                            <Field name="percentage_of_business_abandoned" className="form-control"/>
                          </div>
                        </div>
                      }
                      <hr/>

                      <UIYesNoInput 
                        label="Did you have to modify a product/service line to accommodate pandemic restrictions?"
                        name="modify_to_accomodate"
                        required
                      />
                      {values.modify_to_accomodate && 
                        <UIYesNoInput 
                          label="Was the replacement product/service line less profitable?"
                          name="was_less_profitable"
                          required
                        />
                      }
                      <hr/>

                      <UIYesNoInput 
                        label="Did you pick up any new lines of business?"
                        name="did_pick_up_new_lines_of_business"
                        required
                      />
                      {values.did_pick_up_new_lines_of_business &&
                        <div className="row">
                          <div className="col">
                            <UIFormLabel
                              label="What percentage of your business is the new product / service line?"
                              required
                            />
                            {errors['new_lines_percentage_of_business'] && touched['new_lines_percentage_of_business'] && 
                              <UIValidationError message={errors['new_lines_percentage_of_business'].replace('new_lines_percentage_of_business', "This")} />
                            }
                          </div>
                          <div className="col">
                            <Field name="new_lines_percentage_of_business" className="form-control"/>
                          </div>
                        </div>
                      }
                      <hr/>

                      <div className="row">
                        <div id="payroll_provider" className="col">
                          <UIFormLabel
                            label="What is your payroll provider?"
                          />
                          {errors['payroll_provider'] && touched['payroll_provider'] && 
                            <UIValidationError message={errors['payroll_provider'].replace('payroll_provider', "This")} />
                          }
                        </div>
                        <div className="col">
                          <Field 
                            name="payroll_provider"
                            className="form-control"
                            aria-labelledby="payroll_provider"
                          />
                        </div>
                      </div>
                      <hr/>

                      <UIYesNoInput 
                        label="For all 50% or greater business owners - do you have a 50% or greater interest in any other businesses?"
                        name="majority_owner_of_other_business"
                      />
                      {values.majority_owner_of_other_business && 
                        <div>If you would like to claim the Employee Retention Credit for your other business, please log in to the Subcity profile and complete this application again.</div>
                      }
                    </div>
                  </div>
                  {!isReadonly &&
                    <div className="row mt-5">
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="btn btn-action float-end"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  }
                </fieldset>
                <AutoSave/>
              </Form>
            </div>
          )
        }}
      </Formik>
    </div>
  );
};

export default EmployeeRetentionTaxCredit;
