import React from "react";

const TableHeader = (props) => {
  return (
    <thead>
      <tr>
        <th className="desktop-only">ID</th>
        <th>Name</th>
        <th className="desktop-only">Customer</th>
        <th className="desktop-only">Status</th>
        <th className="desktop-only">Project Manager</th>
        <th>Revenue</th>
        <th>Costs</th>
        <th>Gross Margin</th>
        <th className="desktop-only">Net Profit</th>
        <th></th>
      </tr>
    </thead>
  );
};

export default TableHeader;
