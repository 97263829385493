import React from "react";

const UITableSortable = ({
  title,
  sortColumn,
  onSortChange,
  sortDirection,
  currentSortColumn,
}) => {
  const changeSort = (column) => {
    let direction = "ASC";
    if (currentSortColumn === column) {
      direction = sortDirection === "ASC" ? "DESC" : "ASC";
    }

    onSortChange(direction, column);
  };

  const sortColumnClass = (column) => {
    if (column === currentSortColumn) {
      return sortDirection === "ASC" ? " bi-sort-up" : " bi-sort-down";
    }

    return "bi-arrow-down-up";
  };

  const iconStyle = {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: "5px", // create a small space between the icon and the text
  };

  return (
    <div
      // className='btn btn-sm btn-muted'
      onClick={() => onSortChange && changeSort(sortColumn)}
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
    >
      {currentSortColumn && (
        <i
          style={iconStyle}
          className={`bi ${sortColumnClass(sortColumn)}`}
        ></i>
      )}
      {title}
    </div>
  );
};

export default UITableSortable;
