import React, { useState, useContext } from "react";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";

import "react-datepicker/dist/react-datepicker.css";
import { fetchNaicsCode } from "modules/user/companies";

import { UIFieldGroup, UILabelInput, UIValidationError } from "components/ui";
import { EditCompanyContext } from "components/common/companies/contexts/edit-company-context";
import { buildInitialValuesForSchema, deleteUnansweredValues } from 'lib/utils'

import { Formik, Form } from 'formik';

import {
  isCompanyUpdateValid,
  validateCompanyFields
} from 'validators/company'

const identifierSchema = {
  "type": "object",
  "label": "Company Identifiers",
  "properties": {
    "ein": {
      "row": 1,
      "type": "string",
      "label": "Employer Identification Number",
    },
    "registry_id": {
      "row": 2,
      "type": "string",
      "label": "Secretary of State Entity Number",
    },
    "state_taxpayer_id": {
      "row": 1,
      "type": "string",
      "label": "California Taxpayer ID",
    },
    "duns_number": {
      "row": 2,
      "type": "string",
      "label": "DUNS Number",
    }
  }
};

const BasicInfo = ({ businessEntities }) => {
  const { onUpdateCompany, basicInfo, setBasicInfo, defaultNaicsOption } =
    useContext(EditCompanyContext);

  const [companyName, setCompanyName] = useState(basicInfo.name);
  const [validationErrors, setValidationErrors] = useState({
    duns_number: "",
    number_of_employees: "",
    annual_revenue: ""
  });

  const initialIdentifiers = buildInitialValuesForSchema(identifierSchema, basicInfo);

  const updateCompany = (basicInfo) => {
    onUpdateCompany(basicInfo).then(() => {
      setCompanyName(basicInfo.name);
    });
  };

  const onNaicsCodeSelect = (value) => {
    let naicsCodeIds = value.map(function (naics) { return naics.value; });
    setBasicInfo({ naics_code_ids: naicsCodeIds });
  };

  const onOptionsLoad = (value, cb) => {
    fetchNaicsCode({ query: value }).then((res) => {
      cb(
        res.map((row) => ({
          value: row.id,
          label: `${row.code} (${row.title})`,
        }))
      );
    });
  };

  const setCompanyField = (field, value) => {
    const errors = validateCompanyFields({...basicInfo, [field]:value});
    setValidationErrors({...validationErrors, ...errors});
    setBasicInfo({[field]:value});
  }

  return (
    <Formik
      initialValues={{company_identifiers: { ...initialIdentifiers }}}
    >
      {({ 
        values,
      }) => {
        return (
          <div className="col-md-12">
            <Form onSubmit={ e => {
              e.preventDefault();

              updateCompany({...basicInfo, ...deleteUnansweredValues(values.company_identifiers)});
            }}>
              <div className="card p-3 mt-1 mb-3 z-depth-2">
                <h3 className="panel-title">Company Information</h3>
                <div className="col-md-10 mb-4">
                  <UILabelInput
                    label="Business Name"
                    value={basicInfo.name}
                    onChange={(value) => {
                      setBasicInfo({ name: value });
                    }}
                  />
                </div>

                <div className="col-md-10 mb-4">
                  <strong>
                    <label className="mb-1">Founded On</label>
                  </strong>
                  <DatePicker
                    className="form-control"
                    selected={basicInfo.date_established}
                    scrollableYearDropdown={true}
                    showYearDropdown={true}
                    yearDropdownItemNumber={100}
                    maxDate={new Date()}
                    onChange={(date) => setBasicInfo({ date_established: date })}
                  />
                </div>

                <div className="col-md-10 mb-4">
                  <UILabelInput
                    label="Number of Employees"
                    value={basicInfo.number_of_employees}
                    error={validationErrors.number_of_employees}
                    onChange={(value) => {
                      setCompanyField("number_of_employees", value)
                    }}
                  />
                </div>

                <div className="col-md-10 mb-4">
                  <UILabelInput
                    label="Gross Annual Revenue"
                    value={basicInfo.annual_revenue}
                    error={validationErrors.annual_revenue}
                    onChange={(value) => {
                      setCompanyField("annual_revenue", value)
                    }}
                  />
                </div>

                <div className="row">
                  <div className="col-md-10 mb-4">
                    <strong>
                      <label htmlFor="legal_structure" className="mb-1">
                        Legal Structure
                      </label>
                    </strong>
                    <div>
                      <select
                        name="legal_structure"
                        id="legal-structure"
                        className="form-control"
                        value={basicInfo.business_entity_id}
                        onChange={(e) => {
                          setBasicInfo({ business_entity_id: e.target.value });
                        }}
                      >
                        {businessEntities.map((entity, index) => {
                          return (
                            <option key={`${entity.id}-${index}`} value={entity.id}>
                              {entity.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-10 mb-4  ">
                  <strong>
                    <label className="mb-1">NAICS Code</label>
                  </strong>
                  <AsyncSelect
                    isMulti
                    defaultValue={defaultNaicsOption}
                    loadOptions={onOptionsLoad}
                    onChange={onNaicsCodeSelect}
                  />
                </div>

                <div className="col-md-10 mb-4">
                  <UILabelInput
                    label="Website"
                    value={basicInfo.website}
                    onChange={(value) => {
                      setBasicInfo({ website: value });
                    }}
                  />
                </div>
              </div>

              <div className="card p-3 mt-1 mb-3 z-depth-2">
                <UIFieldGroup
                  prefix="company_identifiers"
                  schema={identifierSchema}
                />
              </div>

              <div className="row mb-4">
                <div className="col-md-10 mb-4">
                  <div className="mb-5 btn-group float-end">
                    <a className="btn btn-light" href="/">
                      Back
                    </a>
                    <button className="btn btn-dark" 
                      disabled={!isCompanyUpdateValid(basicInfo)}
                      type="submit"
                      formNoValidate
                    >
                      Save Company
                    </button>
                  </div>
                  {(isCompanyUpdateValid(basicInfo))}
                </div>
              </div>
            </Form>
          </div>
        )
      }}
    </Formik>
  );
};

export default BasicInfo;
