import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

const Table = ({ items, headers, defaultNoItemsText, mapItemsToTableRows }) => {
  return (
    <div className="px-3">
      <div className="admin-table">
        <table className="table admin-table">
          <thead>
            <tr>
              {headers.map((header, index) => {
                return <th key={`header-${index}`}>{header}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {items.length == 0 ? (
              <tr>
                <td colSpan={6} className="w-100 align-middle">
                  <h5 className="text-center m-3">{defaultNoItemsText}</h5>
                </td>
              </tr>
            ) : (
              items.map((item, index) => {
                return (
                  <tr
                    className="application-tr"
                    key={`application-row-${index}`}
                  >
                    {mapItemsToTableRows(item, index)}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PaginatedTable = ({
  allItems,
  headers,
  itemsPerPage = 20,
  defaultNoItemsText = "No items.",
  mapItemsToTableRows,
}) => {
  const [currentItems, setCurrentItems] = useState(allItems); // This was null before
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(allItems.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(allItems.length / itemsPerPage));
  }, [allItems, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allItems.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Table
        items={currentItems}
        headers={headers}
        defaultNoItemsText={defaultNoItemsText}
        mapItemsToTableRows={mapItemsToTableRows}
      />
      <ReactPaginate
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< "
        nextLabel=" >"
        pageClassName="page-item"
        pageLinkClassName="page-link page-link-button"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination justify-content-center"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
};

export default PaginatedTable;
