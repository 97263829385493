import React, { useContext } from "react";
import { UIFormLabel } from "components/ui";

import { flashSuccess, flashError } from "lib/flash";
import { FAQContext } from "./faq-index";

import {
  createIncentiveFaqEntry,
  deleteIncentiveFaqEntry,
  updateIncentiveFaqEntry,
} from "modules/incentive-faq";

const FAQEntryEdit = ({prefilledFAQEntry, onDoneEditing}) => {
  const {incentive, faqEntries} = useContext(FAQContext);

  const handleSubmit = (e) => {
    e.preventDefault()
    let question = e.target.elements.question.value
    let answer = e.target.elements.answer.value

    if (!question) {
      flashError("Question can't be blank.")
      return;
    }

    if (!answer) {
      flashError("Answer can't be blank.")
      return
    }

    const params = {
      incentive_faq_entry: {
        question: question,
        answer: answer,
      },
      incentive_id: incentive.id,
    };

    if (prefilledFAQEntry) {
      params.incentive_faq_entry.id = prefilledFAQEntry.id;
      updateIncentiveFaqEntry(params).then((updatedFaqEntry) => {
        flashSuccess("FAQ updated.");
        faqEntries.modifyById(updatedFaqEntry.id, updatedFaqEntry);
        onDoneEditing();
      }).catch((err) => {
        flashError(`There was an error: ${err}`)
      });
    } else {
      createIncentiveFaqEntry(params).then((newlyCreatedFaqEntry) => {
        flashSuccess("FAQ added to incentive.");
        faqEntries.push(newlyCreatedFaqEntry);
        onDoneEditing();
      }).catch((err) => {
        flashError(`There was an error: ${err}`)
      });
    }
  }

  const handleDelete = (e) => {
    e.preventDefault();

    const params = {
      id: prefilledFAQEntry.id,
      incentive_id: incentive.id,
    };

    if (window.confirm('Are you sure you want to delete this FAQ entry?')) {
      deleteIncentiveFaqEntry(params).then(() => {
        flashSuccess("Deleted FAQ entry.");
        faqEntries.removeById(prefilledFAQEntry.id);
        onDoneEditing();
      }).catch((err) => {
        flashError(`There was an error: ${err}`)
      });
    }
  }

  return (
    <form className="my-3 form d-flex flex-column" onSubmit={handleSubmit}>
      <h5>{prefilledFAQEntry ? "Update FAQ" : "Add new FAQ"}</h5>

      <div className='form-group'>
        <UIFormLabel label='Question' />
        <textarea
            type='text'
            name='question'
            className='form-control'
            placeholder='Enter question here'
            defaultValue={prefilledFAQEntry && prefilledFAQEntry.question}
        />
        </div>

        <div className='mt-3'>
        <UIFormLabel label='Answer' />
        <textarea
            type='text'
            name='answer'
            className='form-control'
            placeholder='Enter answer here'
            defaultValue={prefilledFAQEntry && prefilledFAQEntry.answer}
        />
        </div>

        { prefilledFAQEntry ?  
          <div className='align-self-end mt-3'>
            <button className="btn btn-sm btn-light me-2" onClick={onDoneEditing}>Cancel</button>
            <button className="btn btn-sm btn-action me-2" onClick={handleDelete}>Delete</button>
            <button type='submit' className="btn btn-sm btn-dark">Update</button>
          </div>
          :
          <div className='align-self-end mt-3'>
            <button className="btn btn-sm btn-light me-2" onClick={onDoneEditing}>Cancel</button>
            <button type='submit' className="btn btn-sm btn-action">Add</button>
          </div> 
        }
    </form>
  )
}

export default FAQEntryEdit;