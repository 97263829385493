import React, { useEffect, useState } from 'react';
import UTIF from 'utif'
import Loading from '../loading'

const TiffPreview = (props) => {
  const [ isLoading, setIsLoading ] = useState(true)
  const [ src, setSrc ] = useState('')

  useEffect(() => {
    const xhr = new XMLHttpRequest();

    xhr.responseType = 'arraybuffer';
    xhr.open('GET', props.url);

    xhr.onload = function(e) {
      // Decode image into canvas.
      const pages = UTIF.decode(e.target.response);
      UTIF.decodeImage(e.target.response, pages[0]);
      const rgba = UTIF.toRGBA8(pages[0]); // Uint8Array with RGBA pixels

      if (rgba) {
        const canvas = document.createElement('canvas');
        canvas.width = pages[0].width;
        canvas.height = pages[0].height;
        const ctx = canvas.getContext('2d');
        const imageData = ctx.createImageData(pages[0].width, pages[0].height);
        for (let i = 0; i < rgba.length; i++) {
          imageData.data[i] = rgba[i];
        }
        ctx.putImageData(imageData, 0, 0);

        // Convert canvas to img; scale img; and add img to DOM.
        const imgObj = document.createElement('img');
        imgObj.src = canvas.toDataURL('image/png');
        imgObj.setAttribute('width', canvas.width * 0.25);
        imgObj.setAttribute('height', canvas.height * 0.25);

        setIsLoading(false)
        document.getElementById('tiff-canvas-wrapper').append(imgObj);
      }
    }
    xhr.send()
  }, [])

  return (
    <div className='tiff-wrapper'>
      { isLoading ? <Loading /> : null }  
      <div id='tiff-canvas-wrapper'>
      </div>
    </div>
  )
}

export default TiffPreview;
