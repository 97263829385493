import React, { useState, useEffect } from "react";

const ProgramPurposeChips = ({
  purposes,
  selectedPurpose,
  onSelectPurpose,
}) => (
  <div className="chips-container">
    {purposes.map((purpose) => (
      <span
        key={purpose}
        className={`chip ${selectedPurpose === purpose ? "active" : ""}`}
        onClick={() => onSelectPurpose(purpose)}
      >
        {purpose}
      </span>
    ))}
  </div>
);

const LocationChips = ({
  defaultRegion,
  selectedState,
  onSelectState,
  user_state,
}) => {
  const statesWithUserState = user_state
    ? [user_state, ...defaultRegion.filter((state) => state !== user_state)]
    : defaultRegion;

  return (
    <div className="chips-container">
      {statesWithUserState.map((state) => (
        <span
          key={state}
          className={`chip ${selectedState.includes(state) ? "active" : ""}`}
          onClick={() => onSelectState(state)}
        >
          {state}
        </span>
      ))}
    </div>
  );
};

const ProgramTypeChips = ({ typeOptions, selectedType, onSelectType }) => (
  <div className="chips-container">
    {typeOptions.map((type) => (
      <span
        key={type.value}
        className={`chip ${
          Array.isArray(selectedType) && selectedType.includes(type.value)
            ? "active"
            : ""
        }`}
        onClick={() => onSelectType(type)}
      >
        {type.label}
      </span>
    ))}
  </div>
);

const FilterAssistant = ({
  selectedCategory,
  setSelectedCategory,
  setFiltersVisible,
  programPurposes,
  defaultRegion,
  user_state,
  typeOptions,
  onSelectState,
  onSelectType,
  onSelectPurpose,
  selectedState,
  selectedType,
  selectedPurpose,
  resetFilters,
}) => {
  const handleSelectState = (state) => {
    resetFilters("state");
    onSelectState(state === "All" ? defaultRegion : state);
  };

  const handleSelectType = (type) => {
    resetFilters("type");
    onSelectType(type);
  };

  const handleSelectPurpose = (purpose) => {
    resetFilters("purpose");
    onSelectPurpose(purpose);
  };

  const renderChips = () => {
    switch (selectedCategory) {
      case "program-purpose":
        return (
          <ProgramPurposeChips
            purposes={programPurposes}
            selectedPurpose={selectedPurpose}
            onSelectPurpose={handleSelectPurpose}
          />
        );
      case "location":
        return (
          <LocationChips
            defaultRegion={defaultRegion}
            selectedState={selectedState}
            onSelectState={handleSelectState}
            user_state={user_state}
          />
        );
      case "program-type":
        return (
          <ProgramTypeChips
            typeOptions={typeOptions}
            selectedType={selectedType}
            onSelectType={handleSelectType}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="thin-tabnav-browse-container">
      <ul className="tabnav justify-content-center">
        {["location", "program-type", "program-purpose"].map((category) => (
          <li
            key={category}
            className={`tabnav-item ${
              selectedCategory === category ? "active" : ""
            }`}
            onClick={() => {
              if (selectedCategory !== category) {
                resetFilters(category);
              }
              if (selectedCategory === category) {
                setFiltersVisible((prev) => !prev);
              } else {
                setSelectedCategory(category);
                setFiltersVisible(true);
              }
            }}
          >
            {category.replace("-", " ").charAt(0).toUpperCase() +
              category.replace("-", " ").slice(1)}
          </li>
        ))}
      </ul>
      {setFiltersVisible && renderChips()}
    </div>
  );
};

export default FilterAssistant;
