import React from "react";
import { State } from "country-state-city";

const StateSelector = (props) => {
  return (
    <select
      name="state"
      id="state"
      className="form-control ui-multi-filter"
      placeholder={props.placeholder}
      value={props.state}
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
    >
      <option value="">{props.placeholder || "-----"}</option>
      {State.getStatesOfCountry("US").map((state) => (
        <option key={state.name} value={state.name}>
          {state.name}
        </option>
      ))}
    </select>
  );
};

export default StateSelector;
