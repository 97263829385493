import React from "react";
import { ReactSortable, Sortable } from "react-sortablejs";

const UIListSortable = (props) => {
  const { children, ...sortableProps } = props;

  return (
    <ReactSortable
      setList={() => {}}
      animation={150}
      sort={true}
      forceFallback={true}
      onStart={() => {
        Sortable.ghost.style.opacity = 0.5;
      }}
      {...sortableProps}
    >
      {children}
    </ReactSortable>
  );
};

export default UIListSortable;
