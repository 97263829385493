import React from "react";

import { UINavHorizontalInternal } from "components/ui";

const ApplicationNav = ({ navigation }) => {
  return (
    <div className="application-nav h-100">
      <div className="container">
        <UINavHorizontalInternal tabs={navigation} style="link" />
      </div>
    </div>
  );
};

export default ApplicationNav;
