import React, { useState } from "react";
import { useArray, useBoolean } from "react-hanger";
import { flashError, flashSuccess } from "../../../../lib/flash";
import {
  createAdminMetaField,
  updateAdminMetaField,
  removeAdminMetaField,
} from "modules/meta-eligibility-fields";
import MetaFieldForm from "../common/meta-field-form";
import { filter } from "lodash";

const Edit = ({ meta_eligibility_field, fieldTypes, tableSchema }) => {
  const subFiledTypes = filter(fieldTypes, (ft) => {
    if (ft === "object" || ft === "array") {
      return false;
    }
    return true;
  });
  const subFields = useArray(meta_eligibility_field.sub_fields);
  const [newField, setNewField] = useState(null);
  const showAddButton = useBoolean(false);

  const onSaveField = (variables) => {
    if (variables.id) {
      updateAdminMetaField({
        meta_eligibility_field: variables,
        id: variables.id,
      }).then((errors) => {
        if (errors) errors.map((err) => flashError(err));
        else {
          flashSuccess("Meta Field Updated");
        }
      });
    } else {
      createAdminMetaField({
        meta_eligibility_field: variables,
      }).then((res) => {
        if (res.errors) res.errors.map((err) => flashError(err));
        else {
          flashSuccess("Meta Field Updated");
          subFields.push(res);
        }
      });
    }
    setNewField(null);
  };

  const onRemoveField = (id) => {
    if (newField) {
      setNewField(null);
      return;
    }

    removeAdminMetaField({ id }).then((errors) => {
      if (errors) errors.map((err) => flashError(err));
      else {
        flashSuccess("Meta Field Removed");
        subFields.removeById(id);
      }
    });
  };

  const addNewField = (id) => {
    setNewField({
      field_type: "string",
      parent_id: meta_eligibility_field.id,
    });
  };

  return (
    <div className="col-md-12">
      <div className="row">
        <h3 className="my-0">Edit Meta Field</h3>
      </div>
      <div className="col-lg-6">
        <MetaFieldForm
          fieldTypes={fieldTypes}
          metaEligibilityField={meta_eligibility_field}
          onSaveField={onSaveField}
          showAddButton={showAddButton}
          tableSchema={tableSchema}
        />
      </div>
      {showAddButton.value && (
        <div className="row">
          {subFields.value.map((subField) => {
            return (
              <div className="col-lg-6" key={subField.id}>
                <hr className="mt-4" />
                <h5 className="mt-2">
                  <strong>Sub Field</strong>
                </h5>
                <div>
                  <MetaFieldForm
                    key={subField.id}
                    fieldTypes={subFiledTypes}
                    metaEligibilityField={subField}
                    onSaveField={onSaveField}
                    tableSchema={tableSchema}
                    onRemoveField={onRemoveField}
                  />
                </div>
              </div>
            );
          })}

          {newField && (
            <div className="col-lg-6">
              <hr className="mt-4" />
              <h5 className="mt-2">
                <strong>Sub Field</strong>
              </h5>
              <div>
                <MetaFieldForm
                  fieldTypes={subFiledTypes}
                  metaEligibilityField={newField}
                  onSaveField={onSaveField}
                  tableSchema={tableSchema}
                  onRemoveField={onRemoveField}
                />
              </div>
            </div>
          )}
          <div>
            <button className="btn btn-dark" onClick={addNewField}>
              Add Sub Field
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Edit;
