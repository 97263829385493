import React from "react";
import IncentiveRow from "./incentive-row";

import {
  withUINavTabs,
  UINavHorizontalInternal,
  UINavSection,
  UINavPanel,
} from "components/ui";

const Offers = (props) => {
  const tabs = [
    {
      name: "Incentives",
      link: "/incentives",
    },
  ];

  function renderRow(row, slug, index) {
    return (
      <IncentiveRow
        incentive={row}
        location={`/${slug}/${row.slug}`}
        key={`incentive-${slug}-${index}`}
      />
    );
  }

  if (props.applications && props.applications.length > 0) {
    tabs.unshift({
      name: "Applications",
      link: "/applications",
    });
  } else if (!window.location.hash.includes("incentives")) {
    window.location = "/#/incentives";
  }

  return (
    <div className="panel px-4 py-3">
      <UINavHorizontalInternal tabs={tabs} />
      <UINavPanel>
        <UINavSection tab="/applications">
          <table className="table offers">
            <tbody>
              {props.applications.map((application, index) => {
                return renderRow(application, "applications", index);
              })}
            </tbody>
          </table>
        </UINavSection>
        <UINavSection tab="/incentives">
          <table className="table offers">
            <tbody>
              {props.incentives.map((incentives, index) => {
                return renderRow(incentives, "incentives", index);
              })}
            </tbody>
          </table>
        </UINavSection>
      </UINavPanel>
    </div>
  );
};

export default withUINavTabs(Offers);
