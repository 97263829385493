import React, { useState, useEffect, useRef } from "react";

import CategoryGroupCompare from "./components/category-group";

import { getAllocatedLineItemGroups } from "modules/project";

const Transactions = (props) => {
  console.log("Transactions", props);
  const [transactions, setTransactions] = useState([]);
  const [displayMode, setDisplayMode] = useState("total");
  const [showEstimates, setShowEstimates] = useState(false);

  function toggleDisplayMode() {
    if (displayMode === "total") {
      setDisplayMode("percent");
    } else {
      setDisplayMode("total");
    }
  }

  function toggleShowEstimates() {
    if (showEstimates) {
      setShowEstimates(false);
    } else {
      setShowEstimates(true);
    }
  }

  function fetchTransactions() {
    getAllocatedLineItemGroups(props.project.id)
      .then((result) => {
        setTransactions(result);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    fetchTransactions();
  }, []);

  let groups = transactions
    .filter((transaction) => transaction["parent_id"] == null)
    .map((transaction, index) => {
      return (
        <CategoryGroupCompare
          key={`category-group-${index}`}
          keyPrefix={`category-group-${index}`}
          {...transaction}
          project={props.project}
          displayMode={displayMode}
          showEstimates={showEstimates}
          transactions={transactions}
          fetchTransactions={fetchTransactions}
        />
      );
    });

  return (
    <div>
      <div className="float-end mb-3">
        <button
          className="btn btn-light me-2"
          onClick={(e) => {
            e.preventDefault();
            toggleShowEstimates();
          }}
        >
          Estimate
          {showEstimates ? (
            <i className="ms-1 bi bi-eye-fill"></i>
          ) : (
            <i className="ms-1 bi bi-eye-slash-fill"></i>
          )}
        </button>
        <button
          className="btn btn-light"
          onClick={(e) => {
            e.preventDefault();
            toggleDisplayMode();
          }}
        >
          {displayMode === "total" ? (
            <i className="bi bi-currency-dollar"></i>
          ) : (
            <i className="bi bi-percent"></i>
          )}
        </button>
      </div>
      {groups}
    </div>
  );
};

export default Transactions;
