import axios from "lib/axios";

export const createAdminIncentiveQualification = (params = {}) => {
  const url = `/admin/incentive_qualifications/`;

  return axios
    .post(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const updateAdminIncentiveQualification = (params = {}) => {
  const url = `/admin/incentive_qualifications/${params.id}/`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const removeAdminIncentiveQualification = (params = {}) => {
  const url = `/admin/incentive_qualifications/${params.id}/`;

  return axios
    .delete(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};

export const setAdminIncentiveQualificationPosition = (params = {}) => {
  const url = `/admin/incentive_qualifications/${params.id}/set_position`;

  return axios
    .put(url, params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
};
