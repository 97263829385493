import React from "react";
import UIFormLabel from "../ui-form-label";
import UIRadioCard from "../ui-radio-card";
import UIValidationError from "../ui-validation-error";

import { useField } from "formik";

const UIBooleanRadioInput = (props) => {
  const [_field, meta, helpers] = useField({ name: props.name });

  return (
    <div className="row">
      <div id={props.name} className="col">
        <UIFormLabel
          label={props.label}
          required={props.required}
          tooltip={props.tooltip}
        />
        {!!props.description && <p>{props.description}</p>}
        {meta.error && meta.touched && (
          <UIValidationError message={meta.error.replace(props.name, "This")} />
        )}
      </div>
      <div className="col">
        <div className="row">
          <div className="col-md-auto mb-3">
            <UIRadioCard
              name={`${props.name}_true`}
              id={`${props.name}_true`}
              label={props.trueLabel}
              checked={meta.value === true}
              handleClick={() => {
                helpers.setValue(true);
              }}
            />
          </div>
          <div className="col-md-auto mb-3">
            <UIRadioCard
              name={`${props.name}_false`}
              id={`${props.name}_false`}
              label={props.falseLabel}
              checked={meta.value === false}
              handleClick={() => {
                helpers.setValue(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UIBooleanRadioInput;
