import React, { useState, useEffect } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { flashSuccessLater } from "lib/flash";
import moment from "moment";

import { exportToPdf as exportPdfUtil } from "lib/utils";
import { prettyDisplayPrice } from "lib/string";
import {
  createDuplicateQuote,
  createNewProjectVersion,
  getCompanyProjectQuote,
  getCompanyProjectVersions,
  renderPriority,
  setCurrentProjectVersion,
  updateCompanyProject,
  updateCompanyProjectQuote,
} from "modules/project";

import ViewQuoteModal from "./components/view-quote-modal";

const Quotes = (props) => {
  const [projects, setProjects] = useState([]);
  const [show, setShow] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [companyProjectQuote, setCompanyProjectQuote] = useState(null);
  const [editingProjectId, setEditingProjectId] = useState(null);
  const [tempVersionName, setTempVersionName] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [tempRequestedByName, setTempRequestedByName] = useState("");
  const [tempQuoteStatus, setTempQuoteStatus] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);

  const grandTotal = projects
    .filter((project) => project.deleted_at === null)
    .reduce(
      (sum, project) => sum + parseFloat(project.project_quote_subtotal),
      0
    );

  const exportToPdf = () => {
    exportPdfUtil("modal-body", `ES-${props.project.name}.pdf`);
  };

  useEffect(() => {
    getCompanyProjectVersions(props.project.id).then((data) => {
      setProjects(data);
    });
  }, [refreshKey]);

  useEffect(() => {
    getCompanyProjectQuote(props.project.id).then((data) => {
      if (data) {
        setCompanyProjectQuote(data);
      }
    });
  }, []);

  const handleClose = () => setShow(false);

  const handleViewQuote = (project) => {
    setSelectedProject(project);
    setShow(true);
  };

  function handleSetCurrentVersion(project) {
    setCurrentProjectVersion(project.id)
      .then((newProject) => {
        flashSuccessLater("Project set to version: " + newProject.version);
        window.location = `/projects/${newProject.id}#/quotes`;
      })
      .catch((error) => {
        flashErrorLater(
          "An error occurred while setting the project version. Please try again."
        );
      });
  }

  function handleEditClick(project) {
    setCurrentProjectVersion(project.id)
      .then(() => {
        window.location = `/projects/${project.id}#/estimate`;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleStartEditing = (project) => {
    setEditingProjectId(project.id);
    setTempQuoteStatus(project.project_quote_status);
    setTempRequestedByName(project.requested_by_name);
    setTempVersionName(project.version_name);
  };

  async function handleStopEditing(save, project) {
    if (save) {
      const paramsProject = {
        id: project.id,
        version_name: tempVersionName,
      };

      try {
        await updateCompanyProject(paramsProject);
        if (project.project_quote_status) {
          const paramsQuote = {
            status: tempQuoteStatus,
            requested_by: {
              name: tempRequestedByName,
            },
          };
          await updateCompanyProjectQuote(project.id, paramsQuote);
        } else {
          // TODO: This is probably not the best UX.
          alert(
            "A quote has not been generated for this project. Please navigate to the Estimates tab to create one."
          );
        }

        flashSuccessLater("Updated successfully!");
        setRefreshKey(refreshKey + 1);

        setProjects(
          projects.map((p) =>
            p.id === project.id
              ? {
                  ...p,
                  version_name: tempVersionName,
                  company_project_quote: {
                    ...p.company_project_quote,
                    status: tempQuoteStatus,
                    requested_by:
                      p.company_project_quote &&
                      p.company_project_quote.requested_by
                        ? {
                            ...p.company_project_quote.requested_by,
                            name: tempRequestedByName,
                          }
                        : {
                            name: tempRequestedByName,
                          },
                  },
                }
              : p
          )
        );
      } catch (error) {
        console.error("Error updating:", error);
      }
    }

    setEditingProjectId(null);
  }

  async function handleDeleteProject(project) {
    if (window.confirm("Are you sure you want to delete this change order?")) {
      const params = {
        id: project.id,
        deleted_at: new Date().toISOString(),
      };

      try {
        await updateCompanyProject(params);
        setProjects(projects.filter((p) => p.id !== project.id));
      } catch (error) {
        console.error("Error deleting project:", error);
      }
    }
  }

  function handleNewVersion() {
    const projectId = currentProject?.id || props.project.id;
    const quoteId = companyProjectQuote?.id;

    createNewProjectVersion(projectId)
      .then((newProject) => {
        const newProjectId = newProject.id;

        return setCurrentProjectVersion(newProjectId).then(() => {
          if (quoteId) {
            return createDuplicateQuote(quoteId, newProjectId);
          }
          return Promise.resolve();
        });
      })
      .then((newProject) => {
        flashSuccessLater("New Change Order created");
        window.location = `/projects/${newProject.company_project_id}#/quotes`;
      })
      .catch((error) => {
        console.error("Error creating new version:", error);
      });
  }

  const TooltipButton = ({ placement, delay, tooltip, children, onClick }) => (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 150, hide: 200 }}
      overlay={
        <Tooltip id="button-tooltip" className="cashflow-tooltip">
          {tooltip}
        </Tooltip>
      }
    >
      <button
        className="btn btn-muted chevron-button inline-icon-button"
        onClick={onClick}
      >
        {children}
      </button>
    </OverlayTrigger>
  );

  function renderSetCurrentVersionButton(project) {
    if (project.is_latest) {
      return (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 150, hide: 200 }}
          overlay={
            <Tooltip id="button-tooltip" className="cashflow-tooltip">
              Active Estimate
            </Tooltip>
          }
        >
          <i
            className="bi bi-check-circle action-buttons float-end"
            style={{ color: "#00A2E9", fontSize: "20px" }}
            title="Active Estimate"
          ></i>
        </OverlayTrigger>
      );
    }

    return (
      <button
        className="btn btn-sm btn-outline-secondary action-buttons float-end"
        onClick={(e) => {
          e.preventDefault();
          handleSetCurrentVersion(project);
        }}
      >
        Set Active
      </button>
    );
  }

  return (
    <>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip" className="cashflow-tooltip">
            Create a change order to duplicate the active project. This will
            generate a new estimate template and quote.
          </Tooltip>
        }
      >
        <button
          className="btn-attach float-end mb-3"
          onClick={(e) => {
            e.preventDefault();
            handleNewVersion();
          }}
        >
          + Create Change Order
        </button>
      </OverlayTrigger>
      <table className="table admin-table">
        <thead>
          <tr>
            <th style={{ width: "4%" }}>CO #</th>
            <th style={{ width: "9%" }}>Created</th>
            <th style={{ width: "5%" }}>Status</th>
            <th style={{ width: "14%" }}>Requested By</th>
            <th style={{ width: "27%" }}>Description</th>
            <th style={{ width: "9%" }}></th>
            <th style={{ width: "9%" }}></th>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 150, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" className="cashflow-tooltip">
                  Excluding Sales Tax
                </Tooltip>
              }
            >
              <th style={{ width: "9%" }}>Cost Estimate</th>
            </OverlayTrigger>
            <th style={{ width: "9%" }}>Quote Amount</th>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 150, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" className="cashflow-tooltip">
                  View, edit and export to pdf
                </Tooltip>
              }
            >
              <th style={{ width: "5%" }}>Quote</th>
            </OverlayTrigger>
          </tr>
        </thead>
        <tbody>
          {projects
            .filter((project) => project.deleted_at === null)
            .map((project, index) => {
              let quote_total = null;
              if (project.id === companyProjectQuote?.company_project_id) {
                quote_total = companyProjectQuote.subtotal;
              }
              return (
                <tr
                  className={
                    project.is_latest
                      ? "table-warning row-with-shadow"
                      : "row-with-shadow"
                  }
                  key={`project-${index}`}
                >
                  <td className="admin-metadata">{project.version}</td>
                  <td>
                    {moment().diff(moment(project.created_at), "days") < 7
                      ? moment(project.created_at).fromNow()
                      : moment(project.created_at).format("MM/DD/YY")}
                  </td>
                  <td>
                    {project.id === editingProjectId ? (
                      <select
                        type="text"
                        value={tempQuoteStatus}
                        className="inline-dropdown"
                        onChange={(e) => setTempQuoteStatus(e.target.value)}
                      >
                        <option value="Awarded">Awarded</option>
                        <option value="Declined">Declined</option>
                        <option value="Pending">Pending</option>
                      </select>
                    ) : (
                      renderPriority(project.project_quote_status)
                    )}
                  </td>

                  <td>
                    {project.id === editingProjectId ? (
                      <input
                        type="text"
                        value={tempRequestedByName}
                        className="inline-edit"
                        onChange={(e) => setTempRequestedByName(e.target.value)}
                      />
                    ) : (
                      project.requested_by_name
                    )}
                  </td>

                  <td>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {project.id === editingProjectId ? (
                        <>
                          <input
                            type="text"
                            value={tempVersionName}
                            className="inline-edit"
                            onChange={(e) => setTempVersionName(e.target.value)}
                          />
                          <button
                            className="btn btn-sm btn-primary me-2"
                            onClick={() => handleStopEditing(true, project)}
                          >
                            <i className="bi bi-check"></i>
                          </button>
                          <button
                            className="btn btn-sm btn-muted"
                            onClick={() => handleStopEditing(false)}
                          >
                            <i className="bi bi-x"></i>
                          </button>
                        </>
                      ) : (
                        project.version_name
                      )}
                    </span>
                  </td>

                  <td>{renderSetCurrentVersionButton(project)}</td>
                  <td>
                    <span
                      className="action-buttons"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <TooltipButton
                        tooltip="Go to Estimate"
                        onClick={(e) => {
                          e.preventDefault();
                          handleEditClick(project);
                        }}
                      >
                        <i
                          className="bi bi-calculator"
                          style={{ fontSize: "20px", color: "#6D757D" }}
                        ></i>
                      </TooltipButton>
                      {project.version !== 1 && (
                        <TooltipButton
                          tooltip="Delete Change Order"
                          onClick={() => handleDeleteProject(project)}
                        >
                          <i
                            className="bi bi-trash"
                            style={{ fontSize: "20px", color: "#6D757D" }}
                          ></i>
                        </TooltipButton>
                      )}
                      <TooltipButton
                        tooltip="Edit"
                        onClick={() => handleStartEditing(project)}
                      >
                        <i
                          className="bi bi-pencil-square"
                          style={{ fontSize: "20px", color: "#6D757D" }}
                        ></i>
                      </TooltipButton>
                    </span>
                  </td>
                  <td>{prettyDisplayPrice(project.project_quote_subtotal)}</td>
                  <td>
                    {quote_total !== null
                      ? prettyDisplayPrice(quote_total)
                      : "N/A"}
                  </td>
                  <td>
                    <TooltipButton
                      tooltip="View Quote"
                      onClick={(e) => {
                        e.preventDefault();
                        handleViewQuote(project);
                      }}
                    >
                      <i
                        className="bi bi-search"
                        style={{ fontSize: "20px", color: "#6D757D" }}
                      ></i>
                    </TooltipButton>
                  </td>
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          {projects.filter((project) => project.deleted_at === null).length >
            1 && (
            <tr>
              <td colSpan="7">Grand Total</td>
              <td>{prettyDisplayPrice(grandTotal)}</td>
              <td colSpan="2"></td>
            </tr>
          )}
        </tfoot>
      </table>
      {/* TODO: When user clicks magnifying glass show correct quote */}
      <ViewQuoteModal
        show={show}
        handleClose={handleClose}
        exportToPdf={exportToPdf}
        project={props.project}
        external_project_id={
          selectedProject ? selectedProject.id : props.project.id
        }
      />
    </>
  );
};
export default Quotes;
