import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { prettyDisplayPrice } from "lib/string";

const CashPartnerInvoiceView = ({
  weeks,
  invoices,
  beginningBalance,
  cashReceipts,
  cashPayments,
  netCashChange,
  endingBalance,
}) => {
  const [isReceiptsCollapsed, setIsReceiptsCollapsed] = useState(false);
  const [isPaymentsCollapsed, setIsPaymentsCollapsed] = useState(false);

  // Get unique weeks
  const uniqueWeeks = Array.from(
    new Set(invoices.map((invoice) => invoice[0]))
  );

  // Get unique vendors for money in
  const uniqueVendorsIn = Array.from(
    new Set(
      invoices.flatMap((invoice) =>
        invoice[2]
          .filter((item) => item.cash_balance_status === "money in")
          .map((item) => item.vendor_name || item.expense_account)
      )
    )
  ).sort();

  // Get unique vendors for money out
  const uniqueVendorsOut = Array.from(
    new Set(
      invoices.flatMap((invoice) =>
        invoice[2]
          .filter((item) => item.cash_balance_status === "money out")
          .map((item) => item.vendor_name || item.expense_account)
      )
    )
  ).sort();

  // Create an empty object to hold the aggregated data for money in
  const aggregatedDataIn = {};
  uniqueVendorsIn.forEach((vendor) => {
    aggregatedDataIn[vendor] = {};
    uniqueWeeks.forEach((week) => {
      aggregatedDataIn[vendor][week] = 0;
    });
  });

  // Create an empty object to hold the aggregated data for money out
  const aggregatedDataOut = {};
  uniqueVendorsOut.forEach((vendor) => {
    aggregatedDataOut[vendor] = {};
    uniqueWeeks.forEach((week) => {
      aggregatedDataOut[vendor][week] = 0;
    });
  });

  // Aggregate the data for each vendor and week for money in
  invoices.forEach((invoice) => {
    const week = invoice[0];
    const vendorInvoices = invoice[2].filter(
      (item) => item.cash_balance_status === "money in"
    );
    vendorInvoices.forEach((vendorInvoice) => {
      const vendorName =
        vendorInvoice.vendor_name || vendorInvoice.expense_account;
      const amountDue = Number(vendorInvoice.amount_due);
      aggregatedDataIn[vendorName][week] += amountDue;
    });
  });

  // Aggregate the data for each vendor and week for money out
  invoices.forEach((invoice) => {
    const week = invoice[0];
    const vendorInvoices = invoice[2].filter(
      (item) => item.cash_balance_status === "money out"
    );
    vendorInvoices.forEach((vendorInvoice) => {
      const vendorName =
        vendorInvoice.vendor_name || vendorInvoice.expense_account;
      const amountDue = Number(vendorInvoice.amount_due);
      aggregatedDataOut[vendorName][week] += amountDue;
    });
  });

  // Generate the table rows for money in
  const tableRowsIn = uniqueVendorsIn.map((vendor) => {
    return (
      <tr key={vendor}>
        <td>{vendor}</td>
        {uniqueWeeks.map((week) => {
          const amountDue = aggregatedDataIn[vendor][week];
          return <td key={week}>{prettyDisplayPrice(amountDue)}</td>;
        })}
      </tr>
    );
  });

  // Generate the table rows for money out
  const tableRowsOut = uniqueVendorsOut.map((vendor) => {
    return (
      <tr key={vendor}>
        <td>{vendor}</td>
        {uniqueWeeks.map((week) => {
          const amountDue = aggregatedDataOut[vendor][week];
          return <td key={week}>{prettyDisplayPrice(amountDue)}</td>;
        })}
      </tr>
    );
  });

  // Calculate the net cash impact
  const netCashImpact = invoices.reduce((sum, invoice) => {
    const totalAmountIn = invoice[2]
      .filter((item) => item.cash_balance_status === "money in")
      .reduce((subSum, item) => subSum + Number(item.amount_due), 0);
    const totalAmountOut = invoice[2]
      .filter((item) => item.cash_balance_status === "money out")
      .reduce((subSum, item) => subSum + Number(item.amount_due), 0);
    return sum + (totalAmountIn - totalAmountOut);
  }, 0);

  return (
    <div>
      <table className="table admin-table">
        <thead>
          <tr>
            <th>Start</th>
            {invoices.map((invoice, index) => (
              <th key={index}>{invoice[0]}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {(beginningBalance || []).map((row, index) => (
            <tr key={index} className="summary-row">
              <td>
                <strong>Beginning</strong>
              </td>
              {row.weeks.map((week, weekIndex) => (
                <td key={weekIndex}>{week}</td>
              ))}
            </tr>
          ))}

          <tr
            className="align-items-center pointer"
            onClick={() => setIsReceiptsCollapsed(!isReceiptsCollapsed)}
          >
            <td colSpan={14}>
              <div className="d-flex align-items-center">
                <strong>Cash Receipts</strong>
                <button className="btn btn-muted btn-sm chevron-button">
                  <i
                    className={`bi bi-chevron-${
                      isReceiptsCollapsed ? "right" : "down"
                    }`}
                  ></i>
                </button>
              </div>
            </td>
          </tr>

          {isReceiptsCollapsed ? (
            <tr className="summary-row">
              <td style={{ backgroundColor: "#F3F7FA" }}>
                Total Cash Receipts
              </td>
              {invoices.map((invoice, index) => {
                const totalAmount = invoice[2]
                  .filter((item) => item.cash_balance_status === "money in")
                  .reduce((sum, item) => sum + Number(item.amount_due), 0);
                return (
                  <td style={{ backgroundColor: "#F3F7FA" }} key={index}>
                    {prettyDisplayPrice(totalAmount)}
                  </td>
                );
              })}
            </tr>
          ) : (
            <>
              {tableRowsIn}
              <tr className="summary-row">
                <td style={{ backgroundColor: "#F3F7FA" }}>
                  Total Cash Receipts
                </td>
                {invoices.map((invoice, index) => {
                  const totalAmount = invoice[2]
                    .filter((item) => item.cash_balance_status === "money in")
                    .reduce((sum, item) => sum + Number(item.amount_due), 0);
                  return (
                    <td style={{ backgroundColor: "#F3F7FA" }} key={index}>
                      {prettyDisplayPrice(totalAmount)}
                    </td>
                  );
                })}
              </tr>
            </>
          )}

          <tr
            className="align-items-center pointer"
            onClick={() => setIsPaymentsCollapsed(!isPaymentsCollapsed)}
          >
            <td colSpan={14}>
              <div className="d-flex align-items-center">
                <strong>Cash Payments</strong>
                <button className="btn btn-muted btn-sm chevron-button">
                  <i
                    className={`bi bi-chevron-${
                      isPaymentsCollapsed ? "right" : "down"
                    }`}
                  ></i>
                </button>
              </div>
            </td>
          </tr>

          {isPaymentsCollapsed ? (
            <tr className="summary-row">
              <td style={{ backgroundColor: "#F3F7FA" }}>
                Total Cash Payments
              </td>
              {invoices.map((invoice, index) => {
                const totalAmount = invoice[2]
                  .filter((item) => item.cash_balance_status === "money out")
                  .reduce((sum, item) => sum + Number(item.amount_due), 0);
                return (
                  <td style={{ backgroundColor: "#F3F7FA" }} key={index}>
                    {prettyDisplayPrice(totalAmount)}
                  </td>
                );
              })}
            </tr>
          ) : (
            <>
              {tableRowsOut}
              <tr className="summary-row">
                <td style={{ backgroundColor: "#F3F7FA" }}>
                  Total Cash Payments
                </td>
                {invoices.map((invoice, index) => {
                  const totalAmount = invoice[2]
                    .filter((item) => item.cash_balance_status === "money out")
                    .reduce((sum, item) => sum + Number(item.amount_due), 0);
                  return (
                    <td style={{ backgroundColor: "#F3F7FA" }} key={index}>
                      {prettyDisplayPrice(totalAmount)}
                    </td>
                  );
                })}
              </tr>
            </>
          )}
          <tr className="summary-row">
            <td style={{ backgroundColor: "#6A7894", color: "#fff" }}>
              Net Cash Impact
            </td>
            {invoices.map((invoice, index) => {
              const totalAmountIn = invoice[2]
                .filter((item) => item.cash_balance_status === "money in")
                .reduce((sum, item) => sum + Number(item.amount_due), 0);
              const totalAmountOut = invoice[2]
                .filter((item) => item.cash_balance_status === "money out")
                .reduce((sum, item) => sum + Number(item.amount_due), 0);
              const netCash = totalAmountIn - totalAmountOut;
              return (
                <td
                  style={{ backgroundColor: "#6A7894", color: "#fff" }}
                  key={index}
                >
                  {prettyDisplayPrice(netCash)}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CashPartnerInvoiceView;
