import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from 'react-bootstrap';
import { flashSuccess, flashError, flashWarning } from "lib/flash";

import { fetchCompanyNotifications, updateCompanyNotification } from 'modules/notifications'

const CompanyNotifications = () => {
  const [companyNotifications, setCompanyNotifications] = useState([]);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);

  const [notificationName, setNotificationName] = useState('');
  const [recurrenceType, setRecurrenceType] = useState('hourly');
  const [recurrenceInterval, setRecurrenceInterval] = useState(1);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    fetch()
  }, [])

  function fetch() {
    fetchCompanyNotifications()
      .then((companyNotifications) => {
        setCompanyNotifications(companyNotifications);
        if (companyNotifications.length > 0) {
          setSelectedNotificationId(companyNotifications[0].notification_id);
          setFormState(companyNotifications[0]);
        }
      })
  }

  const handleRecurrenceTypeChange = (event) => {
    setRecurrenceType(event.target.value);
    setRecurrenceInterval(1); // Reset interval when changing type
  };

  const handleRecurrenceIntervalChange = (event) => {
    setRecurrenceInterval(Number(event.target.value));
  };

  const handleIsActiveChange = (event) => {
    setIsActive(event.target.checked);
  };

  const setFormState = (companyNotification) => {
    setRecurrenceType(companyNotification.recurrence_type);
    setRecurrenceInterval(companyNotification.recurrence_interval);
    setNotificationName(companyNotification.notification.name)
    setIsActive(companyNotification.status === 'active');
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const params = {
      company_notification: {
        recurrence_interval: recurrenceInterval,
        recurrence_type: recurrenceType,
        status: isActive ? 'active' : 'inactive'
      }
    }

    updateCompanyNotification(selectedNotificationId, params)
      .then(() => {
        fetch()
        flashSuccess('Notification Updated!')
      })
      .catch((errors) => {
        flashError(errors)
      })
  };

  const handleNotificationChange = (event) => {
    const notificationId = event.target.value;
    setSelectedNotificationId(notificationId);
    const companyNotification = companyNotifications.find(cn => cn.notification_id === notificationId);
    if (companyNotification) {
      setFormState(companyNotification);
    }
  };

  const getIntervalOptions = () => {
    let maxInterval;
    switch (recurrenceType) {
      case 'hourly': maxInterval = 23; break;
      case 'daily': maxInterval = 6; break;
      case 'weekly': maxInterval = 3; break;
      case 'monthly': maxInterval = 12; break;
      default: maxInterval = 1;
    }

    let options = [];
    for (let i = 1; i <= maxInterval; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }

    return options;
  };

  const getSummary = () => {
    if(!selectedNotificationId || !isActive) return "";

    // Handle plurals/singulars
    let recurrencePhrase;
    switch (recurrenceType) {
      case 'hourly':
        recurrencePhrase = recurrenceInterval > 1 ? `${recurrenceInterval} hours` : 'hour';
        break;
      case 'daily':
        recurrencePhrase = recurrenceInterval > 1 ? `${recurrenceInterval} days` : 'day';
        break;
      case 'weekly':
        recurrencePhrase = recurrenceInterval > 1 ? `${recurrenceInterval} weeks` : 'week';
        break;
      case 'monthly':
        recurrencePhrase = recurrenceInterval > 1 ? `${recurrenceInterval} months` : 'month';
        break;
      default:
        recurrencePhrase = '';
    }

    return ( 
      <div className="card">
        <div className="card-body">
          {`${notificationName} will be sent every ${recurrencePhrase}.` }
        </div>
      </div>
    )
  };

  return (
    <div className='row'>
      <div className='col-md-6'>
        <Form.Group controlId="formCampaignSelect" className='mb-3'>
          <Form.Label><strong>Notification</strong></Form.Label>
          <Form.Control as="select" value={selectedNotificationId} onChange={handleNotificationChange}>
            {companyNotifications.map(cn => 
              <option key={cn.notification_id} value={cn.notification_id}>{cn.notification.name}</option>
            )}
          </Form.Control>
        </Form.Group>

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formRecurrenceType" className='mb-3'>
            <Form.Label><strong>Recurrence Type</strong></Form.Label>
            <Form.Control as="select" value={recurrenceType} onChange={handleRecurrenceTypeChange}>
              <option value="hourly">Hourly</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formRecurrenceInterval" className='mb-3'>
            <Form.Label><strong>Recurrence Interval</strong></Form.Label>
            <Form.Control as="select" value={recurrenceInterval} onChange={handleRecurrenceIntervalChange}>
              {getIntervalOptions()}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formIsActive" className='mb-3'>
            <Form.Label><strong>Receive Emails</strong></Form.Label>
            <Form.Check type="checkbox" label="Active" checked={isActive} onChange={handleIsActiveChange} />
          </Form.Group>

          <Button variant="primary" type="submit">
            Save
          </Button>
        </Form>
      </div>

      <div className='col-md-6'>
        { getSummary() }
      </div>
    </div>
  );
}

export default CompanyNotifications;
