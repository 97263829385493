import React from "react";

const MultipleChoiceQuestion = ({
  question,
  selectedAnswer,
  onAnswerSelect,
}) => {
  // Check if answer_options is present and is an array
  const isOptionsArray = Array.isArray(question.answer_options);

  return (
    <div>
      <h3>{question.question_title}</h3>
      {isOptionsArray && (
        <ul style={{ listStyleType: "none" }}>
          {question.answer_options.map((option) => (
            <li
              key={option.id}
              className={`my-1 py-2 px-3 card read-only-card text-dark d-flex justify-content-between flex-row align-items-center ${
                selectedAnswer === option.id ? "selected-answer" : "bg-light"
              }`}
              style={{ maxWidth: "400px", cursor: "pointer" }}
              onClick={() => onAnswerSelect(option.id)}
            >
              {option.option_text}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultipleChoiceQuestion;
