import React, { useState, useEffect } from "react";
import { useSetState } from "react-hanger";
import {
  deleteIncentiveFileCategory,
  updateIncentiveFileCategory,
} from "modules/files";
import { flashSuccess, flashError, flashSuccessLater } from "lib/flash";

import Form from "./form";

const IncentiveFileCategoryRow = (props) => {
  const [viewState, setViewState] = useState("preview");
  const { state: form, setState: setForm } = useSetState({
    categoryName: props.category,
    categoryId: props.file_category_id,
    count: props.count,
    required: props.required,
    application_context: props.application_context,
  });

  const [fileCategories, setFileCategories] = useState(props.fileCategories);

  function handleClickDelete(e) {
    e.preventDefault();
    deleteIncentiveFileCategory(props.id)
      .then(() => {
        flashSuccessLater("Succesfully deleted File Category.");
        window.location.reload(true);
      })
      .catch((error) => {
        flashError(error);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();

    const params = {
      id: props.id,
      incentive_file_category: {
        incentive_id: props.incentive.id,
        file_category_id: parseInt(form.categoryId),
        count: form.count,
        required: form.required,
        application_context: form.application_context,
      },
    };

    updateIncentiveFileCategory(params)
      .then((response) => {
        flashSuccessLater("Succesfully updated File Category.");
        window.location.reload(true);
      })
      .catch((error) => {
        console.error(error);
        flashError(error);
      });
  }

  useEffect(() => {
    setFileCategories([
      { id: props.file_category_id, name: props.category },
      ...props.fileCategories,
    ]);
  }, [props.fileCategories]);

  function renderPreview() {
    return (
      <tr>
        <td>{form.categoryName}</td>
        <td>{form.application_context}</td>
        <td>{form.count === 0 ? "Unlimited" : form.count}</td>
        <td>{form.required ? "Required" : "Optional"}</td>
        <td>
          <button
            className="btn btn-sm btn-muted"
            onClick={(e) => {
              e.preventDefault();
              setViewState("edit");
            }}
          >
            Edit
          </button>
        </td>
      </tr>
    );
  }

  function renderEdit() {
    return (
      <tr>
        <td colSpan={4}>
          <form
            className="my-3 form d-flex flex-column"
            onSubmit={handleSubmit}
          >
            <Form
              form={form}
              setForm={setForm}
              fileCategories={fileCategories}
            />
            <div className="align-self-end mt-3">
              <button
                className="btn btn-light me-2"
                onClick={(e) => {
                  e.preventDefault();
                  setViewState("preview");
                }}
              >
                Cancel
              </button>

              <button className="btn btn-dark me-2" onClick={handleClickDelete}>
                Delete
              </button>
              <button type="submit" className="btn btn-action">
                Update
              </button>
            </div>
          </form>
        </td>
      </tr>
    );
  }

  switch (viewState) {
    case "preview":
      return renderPreview();
    case "edit":
      return renderEdit();
    default:
      return null;
  }
};

export default IncentiveFileCategoryRow;
