import React from 'react';

const Tags = (props) => {
  const { tags } = props;

  function addTag(e) {
    e.preventDefault()
    props.addTag()
  }

  function toggleShowTags(e) {
    e.preventDefault()
    props.setShowTags(!props.showTags)
  }

  if(!props.showTags) {
    return (
      <div className='row mt-3'>
        <div className='col-md-12'>
          <button className='float-end btn btn-muted' onClick={toggleShowTags}>
            <i className="bi bi-pencil-square"></i>
          </button>
          <label><strong>Tags</strong></label>
          <br/>

          <table className='table table-bordered'>
            <tbody>
              { tags.map((tag, index) => {
                  return (
                    <tr key={`order-field-preview-${index}`}>
                      <td><strong>{tag.key}</strong></td>
                      <td>{tag.value}</td>
                    </tr>
                  )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  return (
    <div className='row mt-3'>
      <div className='col-md-12'>
        <div className='card'>
          <div className='card-body'>
            <button className='float-end btn btn-muted' onClick={toggleShowTags}>
              <i className="bi bi-x-circle"></i>
            </button>
            <label><strong>Tags</strong></label>
            <br/>

            <table className='table table-borderless '>
              <tbody>
                {
                  tags.map((tag, index) => {
                    return (
                      <tr key={`order-field-edit-${index}`} className='align-middle'>
                        <td style={{'width': '5%'}}>
                          {""}<br/>
                          <button className='btn btn-sm btn-muted' 
                            onClick={(e) => {
                              e.preventDefault()
                              props.removeTag(tag.id)
                            }}
                          >
                            <i className="bi bi-x-lg"></i>
                          </button>
                        </td>
                        <td>
                          <label>Key</label>
                          <input 
                            type="text" 
                            placeholder="Key" 
                            className='form-control'
                            value={tag.key || ""}
                            onChange={(e) => {
                              e.preventDefault();
                              props.updateTag(tag, 'key', e.target.value)
                            }}/>
                        </td>
                        <td>
                          <label>Value</label>
                          <input 
                            type="text" 
                            placeholder="Value" 
                            className='form-control'
                            value={tag.value || ""}
                            onChange={(e) => {
                              e.preventDefault();
                              props.updateTag(tag, 'value', e.target.value)
                            }}/>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    <button className='btn btn-sm btn-outline-dark' onClick={addTag}>
                      Add Tag 
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tags;
