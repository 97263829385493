import React from "react";

const IncentiveCard = ({ incentive, handleShow }) => (
  <div className="incentive-card">
    <div className="incentive-card-header">
      <div className="organization-badge">
        <img
          src={incentive.sponsoring_organization.logo}
          alt={incentive.sponsoring_organization.name}
          className="organization-logo"
        />
      </div>
      <div className="incentive-type-badge">
        {incentive.incentive_type?.name || "Other"}
      </div>
    </div>
    <h5>{incentive.name}</h5>
    <p className="incentive-description">{incentive.short_description}</p>
    <div className="incentive-footer">
      <span className="incentive-organization">
        {incentive.sponsoring_organization?.name || "Not Specified"}
      </span>
      <button
        className="btn btn-sm btn-outline-primary"
        onClick={() => handleShow(incentive)}
      >
        Details
      </button>
    </div>
  </div>
);

const IncentiveList = ({ incentives, handleShow }) => {
  return (
    <div className="incentive-list">
      {incentives.map((incentive) => (
        <div key={incentive.id} className="incentive-card-wrapper">
          <IncentiveCard incentive={incentive} handleShow={handleShow} />
        </div>
      ))}
    </div>
  );
};

export default IncentiveList;
