import React from "react";
import QualifiedIncentiveCard from "./qualified-incentive-card";

const PersonalizedRecommendations = ({
  incentives,
  userSignedIn,
  isLoading,
  setIsLoading,
}) => {
  const renderQualifiedIncentiveCards = (incentives, userSignedIn) => {
    return incentives.map((incentive) => {
      if (incentive.is_qualified === true) {
        return (
          <QualifiedIncentiveCard
            key={incentive.id}
            incentive={incentive}
            user_signed_in={userSignedIn}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        );
      }
      return null;
    });
  };

  return (
    <div className="row align-items-center">
      <div className="col-md-12">
        <div className="personalized-incentives-banner d-flex justify-content-between align-items-center">
          <h4>
            <strong>Top programs for you</strong>
          </h4>
        </div>
        <div>
          <p className="header-context mt-0">
            Using the data from your company profile, we've searched our
            database of hundreds of tax credits, grants and economic incentives
            and floated the most relevant ones for your business to the top.
          </p>
        </div>
      </div>
      {renderQualifiedIncentiveCards(incentives, userSignedIn)}
    </div>
  );
};

export default PersonalizedRecommendations;
