import React, { useRef, useState, useEffect } from "react";

const CuratedShelves = ({ header, ctaLink, ctaText, featuredIncentives }) => {
  const carouselRef = useRef(null);
  const [currentSection, setCurrentSection] = useState(0);
  const [itemsPerSection, setItemsPerSection] = useState(3);

  // Calculate the number of sections based on the number of items and items per section
  const sections = Math.ceil(featuredIncentives.length / itemsPerSection);

  const updateItemsPerSection = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 480) {
      setItemsPerSection(1);
    } else if (screenWidth <= 768) {
      setItemsPerSection(2);
    } else {
      setItemsPerSection(3);
    }
  };

  useEffect(() => {
    updateItemsPerSection();
    window.addEventListener("resize", updateItemsPerSection);
    return () => {
      window.removeEventListener("resize", updateItemsPerSection);
    };
  }, []);

  const scroll = (direction) => {
    if (carouselRef.current) {
      const { scrollLeft, clientWidth } = carouselRef.current;
      const scrollTo =
        direction === "left"
          ? scrollLeft - clientWidth
          : scrollLeft + clientWidth;

      carouselRef.current.scrollTo({ left: scrollTo, behavior: "smooth" });

      const newSection =
        direction === "left" ? currentSection - 1 : currentSection + 1;
      setCurrentSection(newSection);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (carouselRef.current) {
        const { scrollLeft, clientWidth } = carouselRef.current;
        const newSection = Math.round(scrollLeft / clientWidth);
        setCurrentSection(newSection);
      }
    };

    carouselRef.current.addEventListener("scroll", handleScroll);
    return () => {
      carouselRef.current.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="curated-shelves">
      <div className="carousel-shelf-title">
        <h3>{header}</h3>
      </div>
      <div className="carousel-container">
        <div className="carousel" ref={carouselRef}>
          {featuredIncentives.length > 0 ? (
            featuredIncentives.map((incentive) => (
              <div className="carousel-shelf-item" key={incentive.id}>
                <div className="basic-card">
                  <div className="icon-container">
                    <a
                      href={`${incentive.sponsoring_organization.website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={incentive.sponsoring_organization.logo_url}
                        alt={incentive.sponsoring_organization.name}
                      />
                    </a>
                  </div>
                  <p>
                    <strong>
                      {incentive.status === "visible" ? (
                        <a
                          href={`/incentive/${incentive.slug}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {incentive.name}
                        </a>
                      ) : (
                        incentive.name
                      )}
                    </strong>
                  </p>
                  <p className="secondary-text">
                    {incentive.short_description}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p>No items to display. Please use the search box.</p>
          )}
        </div>
        <button
          className="carousel-button left"
          onClick={() => scroll("left")}
          disabled={currentSection === 0}
        >
          <i className="bi bi-chevron-left"></i>
        </button>
        <button
          className="carousel-button right"
          onClick={() => scroll("right")}
          disabled={currentSection === sections - 1}
        >
          <i className="bi bi-chevron-right"></i>
        </button>
      </div>
      <div className="carousel-bottom">
        <div className="dots-container">
          {Array.from({ length: sections }).map((_, index) => (
            <span
              key={index}
              className={`dot ${currentSection === index ? "active" : ""}`}
            />
          ))}
        </div>
        {ctaLink && ctaText && (
          <a href={ctaLink} className="carousel-bottom-cta-link">
            {ctaText}
          </a>
        )}
      </div>
    </div>
  );
};

export default CuratedShelves;
