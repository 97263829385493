import axios from "lib/axios";

export const getCompanyAttachments = (params = {}) => {
  const url = `/admin/attachments`;

  return axios
    .get(url, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response.data) 
    })
}

export const loadCompanyAttachments = (params = {}) => {
  return function (value, cb) {
    getCompanyAttachments({ ...params, query: value }).then((res) => {
      cb(
        res.map((row) => ({
          value: row.id,
          label: `${row.file_name}`,
        }))
      );
    });
  }
};
