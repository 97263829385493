import React from 'react';

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-textmate";

const CssEditor = ({onChange, value}) => {
  return (
    <AceEditor
      mode="css"
      theme="textmate"
      width="100%"
      fontSize={16}
      wrapEnabled={true}
      tabSize={2}
      onChange={onChange}
      name="css-editor"
      value={value}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true
      }}
    />
  )
}

export default CssEditor;
