import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { flashError } from "lib/flash";

import EquipmentForm from "./equipment-form";
import { createCompanyPurchase } from "modules/company_purchases";

const NewEquipment = ({ user, constraints }) => {
  const [equipment, setEquipment] = useState({
    purchaser: {
      name: user.name,
      email: user.email,
      phone_number: user.phone_number,
      title: user.title,
    },
  });
  const [showForm, setShowForm] = useState(false);

  const submit = () => {
    createCompanyPurchase({ company_purchase: equipment })
      .then((res) => {
        location.reload();
      })
      .catch((errors) => {
        errors.map((err) => {
          flashError(`There was an error: ${err}`);
        });
      });
  };

  const buttons = (
    <>
      <button className="btn btn-light" onClick={() => setShowForm(false)}>
        Cancel
      </button>
      <button type="submit" className="btn btn-action">
        Add
      </button>
    </>
  );

  return (
    <div>
      {!showForm && (
        <button
          className="btn-attach float-end me-4"
          onClick={() => setShowForm(true)}
        >
          Add Purchase
        </button>
      )}
      {showForm && (
        <Modal
          centered
          show={showForm}
          onHide={() => setShowForm(false)}
          dialogClassName="modal-xl"
        >
          <Modal.Body className="p-0">
            <EquipmentForm
              currentEquipment={equipment}
              setCurrentEquipment={setEquipment}
              buttons={buttons}
              onSubmit={submit}
              constraints={constraints}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default NewEquipment;
