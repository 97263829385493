import React from 'react';

const ApplicationNextBackButton = ({ 
  currentPropertyIndex,
  properties,
  isReadOnly,
  handleTabChange
}) => {
  return (
    <div className='row'>
      <div className='col-md-2'>
        {
          currentPropertyIndex > 0 ?
            <button className='btn btn-secondary' onClick={(e) => {
              e.preventDefault();
              handleTabChange(-1);
            }}>Back</button> : null
        }
      </div>
      <div className='col-md-2 offset-md-8'>
        {
          currentPropertyIndex < properties.length - 1 ?
            <button className='btn btn-primary float-end' onClick={(e) => {
              e.preventDefault();
              handleTabChange(1);
            }}>Next</button> : 
            <button type='submit' disabled={isReadOnly} className='btn btn-action float-end'>
              Submit
            </button>
        }
      </div>
    </div>
  )
}

export default ApplicationNextBackButton;
