import React from 'react';
import UINavCompaniesDropdown from './ui-nav-companies-dropdown';

const UiCompanies = (props) => {

  const setCurrentCompany = (id) => {
    let url = `/company/current?id=${id}`;
    let form = $('<form action="' + url + '" method="post">' + '<input type="text" name="api_url" value="' + '" />' + '</form>');
    $('body').append(form);

    form.submit();
  }

  return (
    <UINavCompaniesDropdown currentCompany={props.current_company} userCompanies={props.user_companies} total_pages={props.total_pages} onChange={(id) => { setCurrentCompany(id) }} />
  );
};

export default UiCompanies;
