import React, { useState } from "react";
import { useSetState } from "react-hanger";
import { UIFormLabel, UIRadioCard } from "components/ui";
import { flashSuccess, flashError, flashSuccessLater } from "lib/flash";

import Form from "./form";

import {
  createIncentiveFileCategory,
  updateIncentiveFileCategory,
} from "modules/files";

const NewIncentiveFileCategory = (props) => {
  const { state, setState } = useSetState({ formVisible: false, error: null });
  const { state: form, setState: setForm } = useSetState({
    categoryName: "",
    categoryId: null,
    count: 0,
    required: false,
  });

  function toggleForm(e) {
    e.preventDefault();
    setState({ formVisible: !state.formVisible });
  }

  function addNewIncentiveFileCategory(e) {
    e.preventDefault();

    const params = {
      incentive_id: props.incentive.id,
      incentive_file_category: {
        file_category_id: parseInt(form.categoryId),
        count: form.count,
        required: form.required,
        application_context: form.application_context,
      },
    };

    createIncentiveFileCategory(params)
      .then((response) => {
        flashSuccessLater("Succesfully added new File Category to incentive");
        window.location.reload(true);
      })
      .catch((errors) => {
        errors.map((error) => {
          flashError(error);
        });
        console.error(error);
      });
  }

  function renderButton() {
    return (
      <div className="clearfix">
        <button className="float-end btn-attach" onClick={toggleForm}>
          Add File Category
        </button>
      </div>
    );
  }

  function renderForm() {
    return (
      <div className="my-3">
        <form
          className="my-3 form d-flex flex-column"
          onSubmit={addNewIncentiveFileCategory}
        >
          <h5>Add New File Category for Incentive</h5>

          <Form
            form={form}
            setForm={setForm}
            fileCategories={props.fileCategories}
          />

          <div className="align-self-end mt-3">
            <button className="btn btn-light me-2" onClick={toggleForm}>
              Cancel
            </button>
            <button type="submit" className="btn btn-action">
              Create
            </button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="d-block mb-3">
      {state.formVisible ? renderForm() : renderButton()}
    </div>
  );
};

export default NewIncentiveFileCategory;
