import React, { Component, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import UIFormLabel from "./ui-form-label";
import UIValidationError from "./ui-validation-error";

import { useField } from "formik";

const UIAsyncSelectInput = (props) => {
  const [field, meta, helpers] = useField({
    name: props.name,
    multiple: !!props.isMulti,
  });

  const onChange = (value) => {
    helpers.setValue(value);
  };

  let defaultNaicsOption = [];

  if (field.value) {
    for (var i = 0; i < field.value.length; i++) {
      const code = field.value[i];
      defaultNaicsOption.push({
        value: code === null ? "" : code.id,
        label: code === null ? "" : `${code.code} ${code.title}`,
      });
    }
  }

  //TODO: Generalize
  const customStyles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      border: "1px solid #e0e0e0",
    }),
  };

  return (
    <div className="ui-label-input">
      {!!props.label && (
        <UIFormLabel
          label={props.label}
          required={props.required}
          tooltip={props.tooltip}
        />
      )}
      {!!props.description && <p>{props.description}</p>}
      <AsyncSelect
        styles={customStyles}
        isMulti={props.isMulti}
        defaultValue={defaultNaicsOption}
        loadOptions={props.loadOptions}
        onChange={onChange}
      />
      {meta.error && meta.touched && (
        <UIValidationError message={meta.error.replace(props.name, "This")} />
      )}
    </div>
  );
};

export default UIAsyncSelectInput;
