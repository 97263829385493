import React, { useState, useEffect } from 'react';

import AccountItem from './account-item'
import { prettyDisplayPrice } from 'lib/string'

const AccountCard = (props) => {
  const [ isCollapsed, setIsCollapsed ] = useState(true);
  const [ canExpand, setCanExpand ] = useState(false)
  const { offer } = props;

  useEffect(() => {
    if(props.accounts.length > 0) {
      setCanExpand(true)
    }
  }, [])


  function toggleView(e) {
    e.preventDefault()
    setIsCollapsed(!isCollapsed)
  }

  function renderTitle() {
    return (
      <h4>
        <i className={`bi bi-${props.icon} me-2`}></i>
        <span>{ props.title }</span>
        <span className="float-end">
          <strong>{prettyDisplayPrice(props.total)}</strong>
        </span>
      </h4>
    );
  }

  function renderBody() {
    const accounts = props.accounts.map((account, index) => {
      return (
        <AccountItem 
          institution={account.details.institution_name} 
          total={account.balances.current} 
          name={account.name} 
          key={`account-card-${index}`} 
        />
      )
    });

    return (
      <div>
        { accounts }
        { offer ? 
          <div>
            <hr/>
            <div dangerouslySetInnerHTML={{__html: offer.content}} /> 
          </div>
          : null }
      </div>
    )
  }

  return (
    <div className={`account-card ${props.className || ''}`}>
      <div className='row clickable' onClick={toggleView}>
        <div className='col-md-1'>
          { canExpand && <i className={`bi bi-chevron-${isCollapsed ? 'right' : 'down'}`}></i> }
        </div>
        <div className='col-md-11'>
          { renderTitle() }
        </div>
      </div>
      <div className='row'>
        <div className='col-md-11 offset-md-1'>
          { isCollapsed ? null : renderBody() }
        </div>
      </div>
    </div>
  )
}

export default AccountCard;
