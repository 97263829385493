import React from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { DropdownButton, Dropdown } from 'react-bootstrap'

const UINavDropdown = (props) => {

  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        <BsThreeDots />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item as="button" onClick={props.updateUrl}>Edit</Dropdown.Item>
        <Dropdown.Item as="button" onClick={props.deleteUrl}>Delete</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UINavDropdown;
