import React, { useState, useEffect } from "react";

import HtmlEditor from "components/common/html-editor";
import CssEditor from "components/common/css-editor";
import TextareaAutosize from "react-textarea-autosize";
import Select, { components } from "react-select";

import { UISelect } from "components/ui";
import { isEmpty, toPermalink } from "lib/utils";

const Form = (props) => {
  const {
    funnel,
    setFunnel,
    updateFunnel,
    handleSubmit,
    layouts,
    onboarding,
    saveText,
  } = props;
  const [incentives, setIncentives] = useState([]);

  const status = [
    {
      name: "Draft",
      value: "draft",
    },
    {
      name: "Published",
      value: "published",
    },
    {
      name: "Trash",
      value: "trash",
    },
  ];

  function formatIncentive(incentive) {
    return {
      incentive_id: incentive.id,
      value: incentive.name.toLowerCase().replace(/\W/g, ""),
      label: incentive.name,
    };
  }

  useEffect(() => {
    setIncentives(
      props.incentives.map((incentive) => formatIncentive(incentive))
    );
  }, []);

  useEffect(() => {
    if (!isEmpty(funnel.title) && isEmpty(funnel.permalink)) {
      const permalink = toPermalink(funnel.title);
      updateFunnel("permalink", permalink);
    }
  }, [funnel.title]);

  function renderIncentiveSelect() {
    return (
      <div
        className="field mb-3 z-3 position-relative"
        style={{ zIndex: "100" }}
      >
        <label>
          <strong>Incentives</strong>
        </label>
        <Select
          isMulti
          options={incentives}
          hideSelectedOptions={false}
          onChange={(options) => {
            updateFunnel("funnel_incentives_attributes", options);
          }}
          value={funnel.funnel_incentives_attributes}
          placeholder="Select Incentives"
          className="ui-multi-filter w-100"
        />
      </div>
    );
  }

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="field mb-3">
        <label>
          <strong>Title</strong>
        </label>
        <input
          type="text"
          className="form-control"
          value={funnel.title}
          placeholder="Enter title here..."
          onChange={(e) => {
            e.preventDefault();
            updateFunnel("title", e.target.value);
          }}
        />
      </div>

      <div className="field mb-3">
        <label>
          <strong>Summary</strong>
        </label>
        <TextareaAutosize
          type="text"
          name="Summary"
          className="form-control"
          placeholder="Enter summary here..."
          value={funnel.summary || ""}
          onChange={(e) => {
            e.preventDefault();
            updateFunnel("summary", e.target.value);
          }}
        />
      </div>

      <div className="field mb-3">
        <label>
          <strong>Meta Description</strong>
        </label>
        <TextareaAutosize
          type="text"
          name="Meta Description"
          className="form-control"
          placeholder="Enter Meta Description here..."
          value={funnel.meta_description || ""}
          onChange={(e) => {
            e.preventDefault();
            updateFunnel("meta_description", e.target.value);
          }}
        />
      </div>

      <div className="field mb-3">
        <label>
          <strong>Permalink</strong>
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter permalink here..."
          value={funnel.permalink}
          onChange={(e) => {
            e.preventDefault();
            updateFunnel("permalink", e.target.value);
          }}
        />
      </div>

      <div className="field mb-3">
        <label>
          <strong>Status</strong>
        </label>
        <UISelect
          name="Status"
          currentOption={funnel.status}
          options={status}
          handleChange={(value) => {
            updateFunnel("status", value);
          }}
        />
      </div>

      <div className="field mb-3 row">
        <div className="col-md-6">
          <label>
            <strong>Layouts</strong>
          </label>
          <UISelect
            name="Layouts"
            currentOption={funnel.layout}
            options={layouts}
            handleChange={(value) => {
              updateFunnel("layout", value);
            }}
          />
        </div>
        <div className="col-md-6">
          <label>
            <strong>Onboarding</strong>
          </label>
          <UISelect
            name="Onboarding"
            currentOption={funnel.onboarding_flow_id}
            options={onboarding}
            handleChange={(value) => {
              updateFunnel("onboarding_flow_id", value);
            }}
          />
        </div>
      </div>

      {funnel.layout === "incentives" ? renderIncentiveSelect() : null}

      <div className="field mb-3">
        <label>
          <strong>Content</strong>
        </label>
        <HtmlEditor
          value={funnel.content}
          onChange={(value) => {
            updateFunnel("content", value);
          }}
        />
      </div>

      <div className="field mb-3">
        <label>
          <strong>Style</strong>
        </label>
        <CssEditor
          value={funnel.style}
          onChange={(value) => {
            updateFunnel("style", value);
          }}
        />
      </div>

      <div className="field mb-3">
        <label>
          <strong>Cover Photo</strong>
        </label>
        <label className="text-muted ms-1">
          Valid File Extension: PNG, JPG, JPEG
        </label>
        <img
          src={funnel.cover_photo_preview}
          className="img-thumbnail d-block mb-2"
          alt="preview"
          width="100%"
        />

        <input
          as="file"
          label="Cover Photo"
          className="form-control input_image"
          type="file"
          name="funnel[cover_photo]"
          id="article_cover_photo"
          onChange={(e) => {
            e.preventDefault();
            const file = e.target.files[0];
            const preview = URL.createObjectURL(file);

            setFunnel({
              ...funnel,
              cover_photo: file,
              cover_photo_preview: preview,
            });
          }}
        />
      </div>

      <div className="btn-group float-end">
        <a className="btn btn-light" href="/admin/funnels">
          Cancel
        </a>
        <button className="btn btn-primary" type="submit">
          {saveText}
        </button>
      </div>
    </form>
  );
};

export default Form;
