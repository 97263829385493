import React, { useState, useEffect } from "react";
import { sumBy, isEmpty } from "lodash";

import { prettyDisplayPrice } from "lib/string";

import EstimateLineItemRow from "./estimate-line-item-row";
import AllocatedLineItemRow from "./allocated-line-item-row";
import MatchLineItemModal from "./match-line-item-modal";

const CategoryGroupCompare = (props) => {
  const { displayMode, name, showEstimates, total_diff, percent_diff, total } =
    props;
  const [items, setItems] = useState(props.line_items);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [allocatedLineItem, setAllocatedLineItem] = useState({});

  useEffect(() => {
    setItems(props.line_items);
  }, [props.line_items]);

  function closeModal() {
    props.fetchTransactions();
    setShowModal(false);
  }

  function renderItems() {
    return isCollapsed ? null : renderExpanded();
  }

  function renderEmpty() {
    return null;
    if (isCollapsed) return null;

    return (
      <tr className="table-light mb-3">
        <td colSpan={3}>
          <div className="p-4 text-center">
            <h6 className="mb-3">
              <strong>Start by adding a line item.</strong>
            </h6>
            <p>
              Adding line items to this estimate category makes it easier to
              compare your invoices and bills for this project.
            </p>
            <button className="btn btn-action" onClick={addItem}>
              Add Line Item
            </button>
          </div>
        </td>
      </tr>
    );
  }

  function toggleDisplay(e) {
    e.preventDefault();
    setIsCollapsed(!isCollapsed);
  }

  function renderTransactionsForEstimate(rows, transactions) {
    transactions.forEach((allocation, index) => {
      rows.push(
        <tr key={`allocated-${index}`}>
          <td>{allocation.transaction_type}</td>
          <td>{allocation.description}</td>
          <td>{prettyDisplayPrice(allocation.unit_cost, true, true)}</td>
          <td>{allocation.quantity}</td>
          <td>{prettyDisplayPrice(allocation.total_amount, true, true)}</td>
          {props.id === null ? (
            <td>
              <button
                className="btn btn-sm btn-action"
                onClick={(e) => {
                  e.preventDefault();
                  setAllocatedLineItem(allocation);
                  setShowModal(true);
                }}
              >
                Match
              </button>
            </td>
          ) : (
            <td></td>
          )}
        </tr>
      );
    });
  }

  function renderExpanded() {
    return (
      <tr>
        <td colSpan={4}>
          <table className="table table-hover">
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Type</th>
                <th style={{ width: "60%" }}>Description</th>
                <th style={{ width: "10%" }}>Unit Cost</th>
                <th style={{ width: "10%" }}>Quantity</th>
                <th style={{ width: "10%" }}>Total</th>
                <th style={{ width: "10%" }}></th>
              </tr>
            </thead>
            <tbody>
              <>
                {items.map((item, index) => {
                  let rows = [];

                  if (showEstimates && item.estimate_line_item != null) {
                    rows.push(
                      <tr
                        key={`estimate-${index}`}
                        style={{ borderBottom: "1pt", color: "#6A7894" }}
                      >
                        <td>
                          <i>Estimate</i>
                        </td>
                        <td>
                          <i>{item.estimate_line_item.description}</i>
                        </td>
                        <td>
                          <i>
                            {prettyDisplayPrice(
                              item.estimate_line_item.unit_amount,
                              true,
                              true
                            )}
                          </i>
                        </td>
                        <td>
                          <i>{item.estimate_line_item.quantity}</i>
                        </td>
                        <td>
                          <i>
                            {prettyDisplayPrice(
                              item.estimate_line_item.total_amount,
                              true,
                              true
                            )}
                          </i>
                        </td>
                      </tr>
                    );

                    renderTransactionsForEstimate(rows, item.transactions);

                    rows.push(
                      <tr key={`diff-${index}`}>
                        <td colSpan={4}>
                          <i className="float-end">Total</i>
                        </td>
                        <td colSpan={2}>
                          <i>
                            {prettyDisplayPrice(
                              item.total_allocated,
                              true,
                              true
                            )}
                          </i>
                          <i className="float-end">
                            {renderDiff(item.total_diff, item.percent_diff)}
                          </i>
                        </td>
                      </tr>
                    );
                  } else {
                    renderTransactionsForEstimate(rows, item.transactions);
                  }

                  return rows;
                })}
                {showEstimates && (
                  <tr style={{ color: "#6A7894" }}>
                    <td colSpan={4}>
                      <i className="float-end">Total Estimated</i>
                    </td>
                    <td colSpan={2}>
                      <i>
                        {prettyDisplayPrice(props.total_estimated, true, true)}
                      </i>
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={4}>
                    <strong className="float-end">Total</strong>
                  </td>
                  <td colSpan={2}>
                    <strong>
                      {prettyDisplayPrice(total, true, true)}
                      <i className="float-end">
                        {renderDiff(total_diff, percent_diff)}
                      </i>
                    </strong>
                  </td>
                </tr>
                {props.children &&
                  props.children.map((child_id, index) => {
                    let child = props.transactions.find(
                      (transaction) => transaction["id"] == [child_id]
                    );
                    if (!child) {
                      return;
                    }

                    let key = props.keyPrefix + `-child-${index}`;

                    return (
                      <tr key={key}>
                        <td colSpan={5}>
                          <CategoryGroupCompare
                            {...props}
                            key={key}
                            keyPrefix={key}
                            {...child}
                          />
                        </td>
                      </tr>
                    );
                  })}
                {props.children && (
                  <>
                    {showEstimates && (
                      <tr style={{ color: "#6A7894" }}>
                        <td colSpan={4}>
                          <i className="float-end">Total Estimated</i>
                        </td>
                        <td colSpan={2}>
                          <i>
                            {prettyDisplayPrice(
                              props.aggregated_total_estimated,
                              true,
                              true
                            )}
                          </i>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={4}>
                        <strong className="float-end">Total</strong>
                      </td>
                      <td colSpan={2}>
                        <strong>
                          {prettyDisplayPrice(
                            props.aggregated_total,
                            true,
                            true
                          )}
                          <i className="float-end">
                            {renderDiff(
                              props.aggregated_total_diff,
                              props.aggregated_percent_diff
                            )}
                          </i>
                        </strong>
                      </td>
                    </tr>
                  </>
                )}
              </>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }

  function renderDiff(total, percent) {
    let className;
    if (name == "Income") {
      className = parseFloat(total) <= 0 ? "text-success" : "text-danger";
    } else {
      className = parseFloat(total) >= 0 ? "text-success" : "text-danger";
    }

    if (displayMode === "total") {
      return (
        <span className={className}>
          {" "}
          {prettyDisplayPrice(Math.abs(total), true, true)}
        </span>
      );
    } else {
      let percentValue;
      if (percent === null) {
        percentValue = "N/A";
      } else {
        percentValue = `${parseFloat(percent).toFixed(2)}%`;
      }

      return <span className={className}>{percentValue}</span>;
    }
  }

  function renderTotal() {
    return (
      <td>
        <div className="float-end">
          <strong>
            {prettyDisplayPrice(props.aggregated_total, true, true)}
            <i className="ms-5">
              {renderDiff(
                props.aggregated_total_diff,
                props.aggregated_percent_diff
              )}
            </i>
          </strong>
        </div>
      </td>
    );
  }

  return (
    <div className="estimate-category-group">
      {props.id === null && (
        <MatchLineItemModal
          allocatedLineItem={allocatedLineItem}
          show={showModal}
          setShow={setShowModal}
          closeModal={closeModal}
          project={props.project}
        />
      )}
      <table className="table table-borderless mb-3">
        <tbody>
          <tr
            style={{ backgroundColor: "#E2F5FF" }}
            className=" row-with-shadow align-middle"
          >
            <td width="10px">
              <button
                className="btn btn-sm btn-muted chevron-button"
                onClick={toggleDisplay}
              >
                {isCollapsed ? (
                  <i className="bi bi-chevron-right"></i>
                ) : (
                  <i className="bi bi-chevron-down"></i>
                )}
              </button>
            </td>
            <td>
              <h5 className="m-0 p-0">{name}</h5>
            </td>
            {isCollapsed ? renderTotal() : <td></td>}
          </tr>
          {isEmpty(items) ? renderEmpty() : renderItems()}
        </tbody>
      </table>
    </div>
  );
};

export default CategoryGroupCompare;
