import React from "react";
import UIFormLabel from "../ui-form-label";
import UIValidationError from "../ui-validation-error";
import DatePicker from "react-datepicker";

import { useField } from "formik";

const UIDatetimeInputFormik = (props) => {
  const [field, meta, helpers] = useField(props);

  return (
    <div className="ui-label-input">
      <UIFormLabel
        label={props.label}
        required={props.required}
        tooltip={props.tooltip}
      />
      {!!props.description && <p>{props.description}</p>}
      <DatePicker
        className="form-control"
        name={props.name}
        selected={!!meta.value ? new Date(meta.value) : null}
        dateFormat={props.dateFormat}
        minDate={props.minDate}
        maxDate={props.maxDate}
        showYearPicker={props.showYearPicker}
        onChange={(date) => {
          helpers.setValue(date);
        }}
      />
      {meta.error && meta.touched && (
        <UIValidationError
          message={meta.error.replace(`${props.name}`, "This")}
        />
      )}
    </div>
  );
};

export default UIDatetimeInputFormik;
