import React, { useState, useEffect } from "react";
import { fetchAdminFileCategories } from 'modules/files'
import { UIOptsNested } from 'components/ui'

const FileCategorySelector = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState(props.fileCategories || []);

  useEffect(() => {
    if (categoryOptions.length > 0) {
      setLoaded(true);
      return;
    }

    fetchAdminFileCategories().then((data) => {
      setCategoryOptions(data);
      setLoaded(true);
    });
  }, []);

  const handleChange = (e) => {
    e.preventDefault();

    const option = e.target.value;
    props.selectFileCategory(option);
  };

  if (!loaded) return null;

  return (
    <div>
      <select
        className="form-control"
        value={props.file_category}
        onChange={handleChange}
      >
        <option disabled selected value>
          {" "}
          -- select an option --{" "}
        </option>
        <UIOptsNested opts={categoryOptions} />
      </select>
    </div>
  );
};

export default FileCategorySelector;
